import * as React from "react";
import { uploadFile } from "./util";
import {
  useCreateMediaItemMutation,
  useGenerateMediaUploadUrlMutation,
} from "api/graphql";

export type FileUpload = {
  file: File;
  project: string;
  folder: string | null;
  id?: string;
};

type UploadContextProps = {
  pending: FileUpload[];
  working: FileUpload[];
  complete: FileUpload[];
  enqueue: (files: FileUpload[]) => Promise<FileUpload[]>;
  empty: boolean;
  progress: number | null;
};

const UploadContext = React.createContext<UploadContextProps>({
  pending: [],
  working: [],
  complete: [],
  enqueue: () => Promise.resolve([]),
  empty: true,
  progress: null,
});

// const batchSize = 5;

type UploadState = {
  pending: FileUpload[];
  working: FileUpload | null;
  complete: FileUpload[];
  progress: number | null;
};

type UploadAction = {
  type: "enqueue" | "complete" | "progress";
  files: FileUpload[];
  progress?: number;
};

const reducer: React.Reducer<UploadState, UploadAction> = (
  prevState,
  action
): UploadState => {
  switch (action.type) {
    case "enqueue": {
      const working = prevState.working || action.files[0];
      return {
        ...prevState,
        pending: [...prevState.pending, ...action.files].filter(
          (ent) => ent !== working
        ),
        working,
      };
    }
    case "complete": {
      const working =
        prevState.pending.length > 0 ? prevState.pending[0] : null;
      return {
        ...prevState,
        working,
        pending: prevState.pending.filter((ent) => ent !== working),
        complete: [...prevState.complete, ...action.files].filter(
          (_, idx) => idx < 5
        ),
      };
    }
    case "progress": {
      return { ...prevState, progress: action.progress || null };
    }
  }
};

export const UploadProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    pending: [],
    working: null,
    complete: [],
    progress: null,
  });
  const [create] = useCreateMediaItemMutation();
  const [mediaUrl] = useGenerateMediaUploadUrlMutation();

  const createItem = async (
    file: File,
    project: string,
    folder?: string
  ): Promise<string> => {
    const item = await create({
      variables: {
        input: {
          contentType: file.type,
          folderId: folder,
          name: file.name,
          projectId: project,
          size: file.size,
          storage: {
            path: project,
            provider: "S3",
            public: `https://cdn.pokko.io/${project}`,
          },
        },
      },
    });

    return item.data?.create?.entity?.id!;
  };

  const processUpload = React.useCallback(
    async (upload: FileUpload): Promise<void> => {
      const { project, file } = upload;

      dispatch({ type: "progress", files: [], progress: 0 });

      const uploadUrl = await mediaUrl({
        variables: {
          input: {
            contentType: file.type,
            fileName: file.name,
            mediaItem: upload.id!,
            project,
          },
        },
      });

      await uploadFile(uploadUrl.data?.mediaUploadUrl!, upload, (progress) =>
        dispatch({ type: "progress", progress, files: [] })
      );
    },
    [mediaUrl]
  );

  React.useEffect(() => {
    if (state.working) {
      processUpload(state.working).then(() => {
        dispatch({
          type: "complete",
          files: state.working ? [state.working] : [],
        });
      });
    }
  }, [state.working, processUpload]);

  const enqueue = async (files: FileUpload[]): Promise<FileUpload[]> => {
    const entries = await Promise.all(
      files.map(async (ent) => {
        if (ent.id) {
          return ent;
        }

        return {
          ...ent,
          id: await createItem(ent.file, ent.project, ent.folder || undefined),
        };
      })
    );

    dispatch({ type: "enqueue", files: entries });

    return entries;
  };

  const value: UploadContextProps = {
    pending: state.pending,
    working: state.working ? [state.working] : [],
    complete: state.complete,
    progress: state.progress,
    enqueue,
    empty:
      state.pending.length === 0 &&
      !state.working &&
      state.complete.length === 0,
  };

  return React.createElement(UploadContext.Provider, {
    value,
    children,
  });
};

export const useUpload = () =>
  React.useContext<UploadContextProps>(UploadContext);
