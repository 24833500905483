var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import cx from "classnames";
import { DownIcon } from "../../../icons/ui/down";
import { UpIcon } from "../../../icons/ui/up";
import { LoadingIcon } from "../../../icons/ui/uploads";
import { useDetectOutsideClick } from "../../../hooks";
import { Link } from "react-router-dom";
export var SplitButton = function (_a) {
    var _b;
    var icons = _a.icons, children = _a.children, renderContent = _a.renderContent, onClick = _a.onClick, kind = _a.kind, hover = _a.hover, focus = _a.focus, loading = _a.loading, disabled = _a.disabled, rest = __rest(_a, ["icons", "children", "renderContent", "onClick", "kind", "hover", "focus", "loading", "disabled"]);
    var containerRef = React.useRef(null);
    var _c = useDetectOutsideClick(containerRef, false), active = _c[0], setActive = _c[1];
    var handleClick = function (ev) {
        setActive(false);
        onClick === null || onClick === void 0 ? void 0 : onClick(ev);
    };
    return (_jsxs("div", { className: cx("split-button dropdown__container", (_b = {},
            _b["--".concat(kind)] = !!kind,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--disabled"] = disabled,
            _b["--loading"] = loading,
            _b)), ref: containerRef, children: [_jsxs("div", { className: "split-button__triggers", children: [_jsxs("button", __assign({ className: cx("split-button__action", {
                            "--loading": loading,
                            "--text-only": !((icons === null || icons === void 0 ? void 0 : icons.right) || (icons === null || icons === void 0 ? void 0 : icons.left) || loading),
                            "--has-icon-left": icons === null || icons === void 0 ? void 0 : icons.left,
                            "--has-icon-right": loading || (icons === null || icons === void 0 ? void 0 : icons.right),
                            "--has-icon-both": (loading || (icons === null || icons === void 0 ? void 0 : icons.right)) && (icons === null || icons === void 0 ? void 0 : icons.left),
                        }), onClick: handleClick }, rest, { children: [(icons === null || icons === void 0 ? void 0 : icons.left) ? (_jsx("div", { className: "split-button__icon--left", children: icons.left })) : null, _jsx("div", { className: "split-button__content", children: children }), loading ? (_jsx("div", { className: "split-button__icon--loading", children: _jsx(LoadingIcon, {}) })) : (icons === null || icons === void 0 ? void 0 : icons.right) ? (_jsx("div", { className: "split-button__icon--right", children: icons.right })) : null] })), _jsx("button", { className: "split-button__toggle dropdown__toggle", onClick: function () { return setActive(function (prev) { return !prev; }); }, children: active ? _jsx(UpIcon, {}) : _jsx(DownIcon, {}) })] }), active ? (_jsx("div", { className: "dropdown__content", children: renderContent === null || renderContent === void 0 ? void 0 : renderContent() })) : null] }));
};
export var SplitLinkButton = function (_a) {
    var _b;
    var icons = _a.icons, children = _a.children, renderContent = _a.renderContent, kind = _a.kind, hover = _a.hover, focus = _a.focus, loading = _a.loading, rest = __rest(_a, ["icons", "children", "renderContent", "kind", "hover", "focus", "loading"]);
    var containerRef = React.useRef(null);
    var _c = useDetectOutsideClick(containerRef, false), active = _c[0], setActive = _c[1];
    return (_jsxs("div", { className: cx("split-button dropdown__container", (_b = {},
            _b["--".concat(kind)] = !!kind,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--loading"] = loading,
            _b)), ref: containerRef, children: [_jsxs("div", { className: "split-button__triggers", children: [_jsxs(Link, __assign({ className: cx("split-button__action", {
                            "--loading": loading,
                            "--text-only": !((icons === null || icons === void 0 ? void 0 : icons.right) || (icons === null || icons === void 0 ? void 0 : icons.left) || loading),
                            "--has-icon-left": icons === null || icons === void 0 ? void 0 : icons.left,
                            "--has-icon-right": loading || (icons === null || icons === void 0 ? void 0 : icons.right),
                            "--has-icon-both": (loading || (icons === null || icons === void 0 ? void 0 : icons.right)) && (icons === null || icons === void 0 ? void 0 : icons.left),
                        }) }, rest, { children: [(icons === null || icons === void 0 ? void 0 : icons.left) ? (_jsx("div", { className: "split-button__icon--left", children: icons.left })) : null, _jsx("div", { className: "split-button__content", children: children }), loading ? (_jsx("div", { className: "split-button__icon--loading", children: _jsx(LoadingIcon, {}) })) : (icons === null || icons === void 0 ? void 0 : icons.right) ? (_jsx("div", { className: "split-button__icon--right", children: icons.right })) : null] })), _jsx("button", { className: "split-button__toggle dropdown__toggle", onClick: function () { return setActive(function (prev) { return !prev; }); }, children: active ? _jsx(UpIcon, {}) : _jsx(DownIcon, {}) })] }), active ? (_jsx("div", { className: "dropdown__content", children: renderContent === null || renderContent === void 0 ? void 0 : renderContent() })) : null] }));
};
