import * as React from "react";
import { NavLink } from "react-router-dom";
import { Icons } from "pokko-shared";
import { DateDisplay } from "components/elements/date/DateDisplay";

export type PublishTarget = {
  id: string;
  name: string;
  date: Date;
};

export type EntryVersion = {
  id: string;
  created: Date;
  modified: Date;
  url: string;
  editing: boolean;
  value: string;
  publishTargets: PublishTarget[];
};

export type EntryVersionsProps = {
  list: EntryVersion[];
  create: [() => void, { loading: boolean }];
  copy2: [() => void, { loading: boolean }];
  archive: [(id: string) => Promise<void>, { loading: boolean }];
};

export const EntryVersions: React.FC<EntryVersionsProps> = (props) => {
  const {
    list,
    archive: [archive, archiveStatus],
  } = props;
  const [expanded, setExpanded] = React.useState(true);

  return (
    <div className="entry-detail__versions">
      <button
        className="entry-detail__versions-header"
        onClick={() => setExpanded(!expanded)}
      >
        <strong>Versions</strong>
        <Icons.DownIcon />
      </button>
      {expanded ? (
        <>
          <div className="entry-detail__versions-list">
            {list.map((ent) => (
              <NavLink
                key={ent.id}
                className="entry-detail__version"
                to={ent.url}
              >
                <span>
                  <DateDisplay value={ent.created} />
                </span>
                <span className="--status">
                  {ent.publishTargets.length === 0 ? null : ent.publishTargets
                      .length === 1 ? (
                    <span className="--status --published">
                      {ent.publishTargets[0].name}
                    </span>
                  ) : (
                    <span
                      className="--status --published"
                      title={ent.publishTargets
                        .map((pub) => pub.name)
                        .join(", ")}
                    >
                      Published
                    </span>
                  )}
                </span>
                <button
                  className="--delete"
                  onClick={() => archive(ent.id)}
                  disabled={
                    ent.publishTargets.length > 0 ||
                    list.length === 1 ||
                    archiveStatus.loading
                  }
                >
                  <Icons.TrashIcon />
                </button>
              </NavLink>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
