import * as React from "react";
import { ProjectLayout } from "components/layout/project/ProjectLayout";
import { Navigate, Route, Routes } from "react-router";
import { EnvChangeLog } from "../changes";
import { useEnvironment } from "..";
import { EnvDiff } from "../diff";
import { EnvModels } from "../models";
import { Helmet } from "react-helmet";
import { EnvEntries } from "../entries";
import { EnvTaxonomy } from "../taxonomy";
import { EnvExportPage } from "../export";
import { EnvImportPage } from "../import";

export const EnvironmentHome: React.FC = () => {
  const { project, environment, models } = useEnvironment();
  return (
    <>
      <Helmet title={[environment.name, project.name].join(" - ")} />
      <Routes>
        <Route
          path="changes"
          element={
            <ProjectLayout>
              <EnvChangeLog />
            </ProjectLayout>
          }
        />
        <Route
          path="diff"
          element={
            <ProjectLayout>
              <EnvDiff />
            </ProjectLayout>
          }
        />
        <Route
          path="models/*"
          element={
            <ProjectLayout>
              <EnvModels />
            </ProjectLayout>
          }
        />
        <Route
          path="entries/*"
          element={
            <ProjectLayout gapless>
              <EnvEntries />
            </ProjectLayout>
          }
        />
        <Route
          path="taxonomy/*"
          element={
            <ProjectLayout>
              <EnvTaxonomy />
            </ProjectLayout>
          }
        />
        <Route
          path="export"
          element={
            <ProjectLayout>
              <EnvExportPage />
            </ProjectLayout>
          }
        />
        <Route
          path="import"
          element={
            <ProjectLayout>
              <EnvImportPage />
            </ProjectLayout>
          }
        />
        <Route
          path=""
          element={
            <Navigate
              to={
                models.length + environment.models.totalCount === 0
                  ? "models"
                  : "entries"
              }
            />
          }
        />
      </Routes>
    </>
  );
};
