import * as React from "react";
import { FieldConfigProps } from "components/components/fields/types";
import { ModelSelector } from "components/components/models/selector/ModelSelector";
import { Config } from ".";
import { ModelUsage } from "api/graphql";

export const ModulesConfig: React.FC<FieldConfigProps<Config>> = ({
  config,
  onChange,
}) => {
  const allowed: string[] = config?.allowed ?? [];

  return (
    <>
      <label className="label">Allowed models</label>
      <ModelSelector
        value={allowed}
        usage={ModelUsage.Module}
        onChange={(allowed) => onChange({ ...config, allowed })}
      />
    </>
  );
};
