import { Field } from "components/components/fields/types";
import { ModulesConfig } from "./config";
import { ModulesInput } from "./input";

export type Config = {
  allowed: string[];
};

export const Modules: Field = {
  name: "Modular content",
  config: ModulesConfig,
  input: ModulesInput,
  displayName: (config: Config, models) => {
    if (config?.allowed && config?.allowed.length > 0) {
      return `Modular content: ${config.allowed
        .map((id) => models.find((ent) => ent.id === id))
        .map((ent) => ent?.name)
        .join(", ")}`;
    }
    return "Modular content";
  },
};
