import * as React from "react";
import { InputField, Icons } from "pokko-shared";
import { useEnvironment } from "routes/accounts/projects/environments";
import { DateDisplay } from "components/elements/date/DateDisplay";
import { Tag } from "components/elements/tag/Tag";
import { EntryDetailProps } from "../EntryDetail";

export const EntrySettings: React.FC<EntryDetailProps> = (props) => {
  const { entry, links } = props;

  type State = "normal" | "actions" | "editing" | "deleting";
  const [state, setState] = React.useState<State>("normal");

  const [name, setName] = React.useState(entry.value.name);
  const { models } = useEnvironment();
  const model = models.find((ent) => ent.id === entry.value.model);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleEdit = () => {
    setState("editing");
    inputRef.current?.focus();
  };

  const handleConfirm = async () => {
    switch (state) {
      case "editing":
        await entry.onSave({ ...entry.value, name });
        break;
      case "deleting":
        await entry.onDelete();
        break;
    }
    setState("normal");
  };

  const handleCancel = () => {
    if (state === "editing") {
      setName(entry.value.name);
    }

    setState("normal");
  };

  const confirming = (
    <div className="entry-detail__settings-actions">
      <button key="confirm" onClick={handleConfirm}>
        <Icons.SuccessIcon />
      </button>
      <button key="cancel" onClick={handleCancel}>
        <Icons.CloseIcon />
      </button>
    </div>
  );

  const addOn: { [state: string]: React.ReactNode } = {
    normal: (
      <button key="actions" onClick={() => setState("actions")}>
        <Icons.MoreIcon />
      </button>
    ),
    actions: (
      <div className="entry-detail__settings-actions">
        <button key="edit" onClick={handleEdit}>
          <Icons.EditIcon />
        </button>
        <button key="delete" onClick={() => setState("deleting")}>
          <Icons.DeleteAltIcon />
        </button>
        <button key="cancel" onClick={() => setState("normal")}>
          <Icons.MoreIcon />
        </button>
      </div>
    ),
    editing: confirming,
    deleting: confirming,
  };

  return (
    <div className="entry-detail__settings">
      <div className="entry-detail__settings-name">
        <InputField
          label="Name"
          value={name}
          onChangeText={setName}
          readOnly={state !== "editing"}
          ref={inputRef}
          addOn={{
            right: addOn[state],
          }}
        />
      </div>
      <div className="entry-detail__settings-model">
        <strong>Model</strong>
        <Tag link={`../../../../models/${model?.alias}`}>{model?.name}</Tag>
      </div>
      <div className="entry-detail__settings-dates">
        <DateDisplay label="Created" value={entry.value.created} />
        {entry.value.createdBy ? (
          <div className="display-date">
            <strong>Created by</strong>
            <span>{entry.value.createdBy}</span>
          </div>
        ) : null}
        <DateDisplay label="Modified" value={entry.value.modified} />
        {entry.value.modifiedBy ? (
          <div className="display-date">
            <strong>Modified by</strong>
            <span>{entry.value.modifiedBy}</span>
          </div>
        ) : null}
        {entry.value.deleted ? (
          <DateDisplay label="Deleted" value={entry.value.deleted} />
        ) : null}
      </div>
      {links.length > 0 ? (
        <div className="entry-detail__settings-links">
          <strong>Links</strong>
          <ul>
            {links.map((ent, index) => (
              <li key={index}>
                <a href={ent.url} target="_blank" rel="noopener noreferrer">
                  {ent.website}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
