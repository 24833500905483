import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { sentenceCase } from "sentence-case";

import { ModelUsage, useGetEntrySummaryQuery } from "api/graphql";
import { EntrySearch } from "components/components/fields/entry/components/EntrySearch";
import {
  Button,
  InlineRadioButtons,
  InputField,
  LinkButton,
  SelectField,
} from "pokko-shared";
import { useEnvironment } from "routes/accounts/projects/environments";
import { strings } from "strings";
import { EntryDetailCardDisplay } from "../card/EntryDetailCard";
import { TreeNodeType } from "./tree";

export type EntryMenuItemInput = {
  label: string;
  type: TreeNodeType;
  entry?: string;
  model?: string;
};

export type EntryMenuItemEditorProps = {
  creating: boolean;
  value: EntryMenuItemInput;
  save: [(value: EntryMenuItemInput) => void, { loading: boolean }];
  archive: [() => void, { loading: boolean }];
};

export const EntryMenuItemEditor: React.FC<EntryMenuItemEditorProps> = ({
  creating,
  value,
  save: [save, saveStatus],
  archive: [archive, archiveStatus],
}) => {
  const { models, project, environment } = useEnvironment();
  const form = useForm<EntryMenuItemInput>({ defaultValues: value });

  React.useEffect(() => {
    form.reset(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [type, entry] = form.watch(["type", "entry"]);

  const entryQuery = useGetEntrySummaryQuery({
    variables: {
      id: entry,
      project: project.id,
      environment: environment.id,
    },
    skip: !entry || type !== "entry",
  });

  const selectedEntry = entryQuery.data?.entity;

  return (
    <form
      className="entries-menu-item-editor__container"
      onSubmit={form.handleSubmit(save)}
    >
      <InputField label="Label" {...form.register("label")} />
      <Controller
        name="type"
        control={form.control}
        render={({ field: { value, onChange } }) => (
          <InlineRadioButtons
            values={["entry", "view", "folder"]}
            value={value}
            onChange={onChange}
            render={sentenceCase}
          />
        )}
      />

      {type === "entry" ? (
        !entry ? (
          <Controller
            name="entry"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <EntrySearch
                value={value || ""}
                onChange={(val) => onChange(val)}
              />
            )}
          />
        ) : entry ? (
          <EntryDetailCardDisplay
            link={`../../${selectedEntry?.id}`}
            model={
              models.find((mod) => mod.id === selectedEntry?.modelId)?.name!
            }
            modified={new Date(selectedEntry?.modifiedAt + "Z")}
            name={selectedEntry?.name!}
            published={(selectedEntry?.entryPublishes?.totalCount ?? 0) > 0}
            onRemove={() => {
              form.setValue("entry", "");
            }}
          />
        ) : null
      ) : null}

      {type === "view" ? (
        <SelectField label="Model" {...form.register("model")}>
          <option />
          {models
            .filter((mod) => !Boolean(mod.deletedAt))
            .filter((mod) => mod.usage === ModelUsage.Entry)
            .map((mod) => (
              <option key={mod.id} value={mod.id}>
                {mod.name}
              </option>
            ))}
        </SelectField>
      ) : null}

      <div className="entries-menu-item-editor__actions">
        <Button type="submit" kind="primary" loading={saveStatus.loading}>
          {creating ? strings.create : strings.save}
        </Button>
        {creating ? (
          <LinkButton kind="tertiary" to="..">
            {strings.cancel}
          </LinkButton>
        ) : (
          <Button
            type="button"
            kind="tertiary"
            onClick={archive}
            loading={archiveStatus.loading}
          >
            {strings.delete}
          </Button>
        )}
      </div>
    </form>
  );
};
