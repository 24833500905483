import * as React from "react";
import { EntriesOrderBy } from "api/graphql";
import { Pagination } from "components/elements/pagination/Pagination";
import { EntryListProps } from "../EntryList";
import { EntryDetailCardLink } from "../../card/EntryDetailCard";
import {
  Select,
  LinkButton,
  Input,
  Icons,
  SplitLinkButton,
} from "pokko-shared";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const itemHeight = 80 + 4;
const minItems = 5;
const maxItems = 20;

export const EntryListResults: React.FC<EntryListProps> = ({
  results,
  search,
  models,
}) => {
  const containerRef = React.createRef<HTMLDivElement>();
  const [visibleItemCount, setVisibleItemCount] = React.useState(5);
  const { pathname } = useLocation();

  React.useLayoutEffect(() => {
    const el = containerRef.current;

    const measure = () => {
      setVisibleItemCount(Math.floor((el?.clientHeight ?? 0) / itemHeight));
    };

    el?.addEventListener("resize", measure);
    window.addEventListener("resize", measure);
    measure();

    return () => {
      el?.removeEventListener("resize", measure);
      window.removeEventListener("resize", measure);
    };
  }, [containerRef]);

  React.useEffect(() => {
    if (results && visibleItemCount > 1) {
      if (visibleItemCount < minItems) {
        results.setPageSize(minItems);
      } else if (visibleItemCount > maxItems) {
        results.setPageSize(maxItems);
      } else {
        results.setPageSize(visibleItemCount);
      }
    }
  }, [results, visibleItemCount]);

  if (results) {
    return (
      <div className="entry-list__results">
        <div className="entry-list__results-header">
          <h2 className="h2">Entries</h2>
          <div className="entry-list__results-header-actions">
            <div className="entry-list__results-sort">
              <strong>Sort by</strong>
              <Select
                value={results.sort.value}
                onChangeText={(val: string) =>
                  results.sort.onChange(val as EntriesOrderBy)
                }
              >
                <option value={EntriesOrderBy.ModifiedAtDesc}>
                  Modified: Recent - Old
                </option>
                <option value={EntriesOrderBy.ModifiedAtAsc}>
                  Modified: Old - Recent
                </option>
                <option value={EntriesOrderBy.CreatedAtAsc}>
                  Created: Old - Recent
                </option>
                <option value={EntriesOrderBy.CreatedAtDesc}>
                  Created: Recent - Old
                </option>
                <option value={EntriesOrderBy.NameAsc}>Name: A - Z</option>
                <option value={EntriesOrderBy.NameDesc}>Name: Z - A</option>
              </Select>
            </div>
            <CreateEntryButton models={models} />
          </div>
        </div>
        <div className="entry-list__results-search">
          <Input
            placeholder="Search..."
            type="search"
            value={search.value}
            onChangeText={search.onChange}
          />
        </div>

        {results.list.length === 0 &&
        (search.value || models.value.length > 0) ? (
          <div className="entry-list__results-no-results" ref={containerRef}>
            <Icons.SearchIcon />
            <p>
              <strong>
                {search.value ? (
                  <>No results for “{search.value}”</>
                ) : (
                  <>
                    No results for the selected model
                    {models.value.length === 1 ? "" : "s"}
                  </>
                )}
              </strong>
            </p>
            {search.value ? (
              <p>
                You may want to try using different keywords or checking for
                typos.
              </p>
            ) : null}
          </div>
        ) : (
          <div className="entry-list__results-items" ref={containerRef}>
            {results.list.map((ent) => (
              <EntryDetailCardLink
                key={ent.id}
                link={{ to: `${ent.url}?listingUrl=${pathname}` }}
                entry={{
                  model: ent.model.name,
                  modified: ent.modified,
                  name: ent.name,
                  published: ent.published,
                  selectable: true,
                }}
              />
            ))}
          </div>
        )}
        <div className="entry-list__results-actions">
          <Pagination {...results.pagination} />
        </div>
      </div>
    );
  }

  return null;
};

const CreateEntryButton: React.FC<Pick<EntryListProps, "models">> = ({
  models,
}) => {
  if (models.value.length === 0) {
    return (
      <LinkButton to={`create`} kind="primary">
        New entry
      </LinkButton>
    );
  } else if (models.value.length === 1) {
    return (
      <LinkButton to={`../../create?model=${models.value[0]}`} kind="primary">
        {`New ${models.list.find((mod) => mod.id === models.value[0])?.name}`}
      </LinkButton>
    );
  } else {
    return (
      <SplitLinkButton
        kind="primary"
        to={`../../create?model=${models.value[0]}`}
        renderContent={() => (
          <ul className="dropdown__menu">
            {models.value.slice(1).map((mod) => (
              <li className="dropdown__menu--item">
                <Link to={`../../create?model=${mod}`}>{`New ${
                  models.list.find((mod2) => mod2.id === mod)?.name
                }`}</Link>
              </li>
            ))}
          </ul>
        )}
      >
        {`New ${models.list.find((mod) => mod.id === models.value[0])?.name}`}
      </SplitLinkButton>
    );
  }
};
