import axios from "axios";
import { FileUpload } from "./upload";
import { MediaUploadUrlOutput } from "api/graphql";

export const uploadFile = async (
  { url, headers }: MediaUploadUrlOutput,
  { file }: FileUpload,
  onProgress: (progress: number) => void
) => {
  await axios.put(url, file, {
    headers,
    onUploadProgress: (ev) =>
      onProgress(Math.round((ev.loaded * 100) / (ev.total ?? 0))),
  });
};
