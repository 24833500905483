import * as React from "react";
import { Field, Label, Select } from "pokko-shared";
import {
  ListEnvDiffSummaryQuery,
  useListEnvDiffSummaryQuery,
} from "api/graphql";
import { useEnvironment } from "..";

function uniq<T>(ent: T, idx: number, arr: T[]) {
  return arr.indexOf(ent) === idx;
}

const calculateDiff = (
  environments: string[],
  input?: ListEnvDiffSummaryQuery
): any => {
  if (!input) {
    return null;
  }

  const [source, dest] = environments;

  const models = input.models?.nodes
    .map((ent) => ent?.id!)
    .filter(uniq)
    .map((id) => ({
      id,
      source: input.models?.nodes.find(
        (ent) => ent?.environmentId === source && ent.id === id
      ),
      dest: input.models?.nodes.find(
        (ent) => ent?.environmentId === dest && ent.id === id
      ),
    }))
    .map((diff) => ({
      ...diff,
      changed: diff.source?.modifiedAt !== diff.dest?.modifiedAt,
    }));

  const modelFields = input.modelFields?.nodes
    .map((ent) => ent?.id!)
    .filter(uniq)
    .map((id) => ({
      id,
      source: input.modelFields?.nodes.find(
        (ent) => ent?.environmentId === source && ent.id === id
      ),
      dest: input.modelFields?.nodes.find(
        (ent) => ent?.environmentId === dest && ent.id === id
      ),
    }))
    .map((diff) => ({
      ...diff,
      changed: diff.source?.modifiedAt !== diff.dest?.modifiedAt,
    }));

  return {
    models,
    modelFields,
  };
};

export const EnvDiff: React.FC = () => {
  const [against, setAgainst] = React.useState<null | string>(null);
  const { project, environment } = useEnvironment();
  const environments = [environment.id, against];
  const query = useListEnvDiffSummaryQuery({
    skip: !against || against === environment.id,
    variables: {
      project: project.id,
      environments,
    },
  });

  const againstEnv = against
    ? project.projectEnvironments.nodes.find((ent) => ent?.id === against)
    : null;

  const diff = calculateDiff(environments, query.data);

  return (
    <>
      <Field>
        <Label>
          Compare '{environment.name}' to {againstEnv ? againstEnv.name : "..."}
        </Label>
        <Select onChangeText={setAgainst} value={against || ""}>
          <option />
          {project.projectEnvironments.nodes
            .filter((ent) => ent?.id !== environment.id)
            .map((ent) => (
              <option key={ent?.id} value={ent?.id}>
                {ent?.name}
              </option>
            ))}
        </Select>
      </Field>

      <span className="header">Models</span>
      <table className="table">
        <thead>
          <tr>
            <th>{environment.name}</th>
            <th>{againstEnv?.name}</th>
          </tr>
        </thead>
        <tbody>
          {diff?.models.map((ent: any) => (
            <tr key={ent.id}>
              <td>
                {ent.source ? (
                  <>
                    {ent.source.name} <small>{ent.source.alias}</small>
                  </>
                ) : (
                  <em>does not exist</em>
                )}
              </td>
              <td>
                {ent.dest ? (
                  <>
                    {ent.dest.name} <small>{ent.dest.alias}</small>
                  </>
                ) : (
                  <em>does not exist</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <span className="header">Model fields</span>
      <table className="table">
        <thead>
          <tr>
            <th>{environment.name}</th>
            <th>{againstEnv?.name}</th>
          </tr>
        </thead>
        <tbody>
          {diff?.modelFields.map((ent: any) => (
            <tr key={ent.id}>
              <td>
                {ent.source ? (
                  <>
                    {ent.source.name} <small>{ent.source.alias}</small>
                  </>
                ) : (
                  <em>does not exist</em>
                )}
              </td>
              <td>
                {ent.dest ? (
                  <>
                    {ent.dest.name} <small>{ent.dest.alias}</small>
                  </>
                ) : (
                  <em>does not exist</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
