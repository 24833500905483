import * as React from "react";
import { Link } from "react-router-dom";
import { IconLinkButton, Icons, Button } from "pokko-shared";
import { WebhookRunStatus } from "api/graphql";
import { DateDisplay } from "components/elements/date/DateDisplay";
import { Tag } from "components/elements/tag/Tag";
import { strings } from "strings";

export type WebhookDetailProps = {
  name: string;
  runs: {
    id: string;
    created: Date;
    status: WebhookRunStatus;
  }[];
  trigger: [() => void, { loading: boolean }];
};

export const WebhookDetail: React.FC<WebhookDetailProps> = ({
  name,
  runs,
  trigger: [trigger, triggerStatus],
}) => (
  <div className="webhook-detail__container">
    <div className="webhook-detail__header">
      <h2 className="h2">{name}</h2>
      <div className="webhook-detail__header-actions">
        <IconLinkButton kind="tertiary" to="settings">
          <Icons.SettingsIcon />
        </IconLinkButton>
        <Button
          kind="primary"
          onClick={trigger}
          loading={triggerStatus.loading}
        >
          Trigger
        </Button>
      </div>
    </div>
    <div className="webhook-detail__history">
      {runs.length > 0 ? (
        <div className="webhook-detail__log">
          <strong>History</strong>
          {runs.map((ent) => (
            <Link
              to={`runs/${ent.id}`}
              key={ent.id}
              className="webhook-detail__log-item"
            >
              <DateDisplay value={ent.created} />
              <Tag>{strings.webhooks.status[ent.status]}</Tag>
            </Link>
          ))}
        </div>
      ) : (
        <div className="webhook-detail__log-empty">
          <p>This webhook has not yet run.</p>
        </div>
      )}
    </div>
  </div>
);
