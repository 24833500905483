import * as React from "react";
import { useGetWebhookQuery, useListWebhookLogsQuery } from "api/graphql";
import { WebhookRun } from "components/components/webhooks/run/WebhookRun";
import { useParams } from "react-router";

export const WebhookRunController: React.FC = () => {
  const { webhook, run } = useParams();
  const query = useGetWebhookQuery({ variables: { id: webhook } });
  const queryLog = useListWebhookLogsQuery({ variables: { id: run } });

  if (!query.data?.entity) {
    return null;
  }

  const { entity } = query.data;

  return (
    <WebhookRun
      name={entity.name!}
      log={
        queryLog.data?.list?.nodes.map((ent) => ({
          id: ent?.id!,
          created: new Date(ent?.createdAt + "Z"),
          level: ent?.level!,
          payload: ent?.payload!,
        })) ?? []
      }
    />
  );
};
