import { Field } from "components/components/fields/types";
import { ScalarConfig } from "./config";
import { ScalarInput } from "./input";

export type Config = {
  type: "text" | "number" | "boolean" | "date" | "richtext";
  multiline?: boolean;
  markdown?: boolean;
  validation?: string;
};

export const Scalar: Field = {
  name: "Scalar value",
  config: ScalarConfig,
  input: ScalarInput,
  disableMulti: (config: Config) =>
    config?.type === "boolean" || config?.type === "richtext",
  displayName: (config: Config) => {
    switch (config.type) {
      case "date":
        return "Date";
      case "number":
        return "Numeric";
      case "boolean":
        return "Boolean (yes/no, true/false)";
      case "richtext":
        return "Rich text";
      case "text":
      default:
        if (config.multiline) {
          return "Multiline text";
        }

        return "Plain text";
    }
  },
};
