import * as React from "react";
import { Route, Routes, useParams } from "react-router";

import { Account, useGetAccountQuery } from "api/graphql";
import { AccountProject } from "./projects";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

export type AccountContextProps = {
  account: Account;
  reload: () => void;
  loading: boolean;
};

export const AccountContext = React.createContext<AccountContextProps>({
  account: {} as any,
  reload: () => {},
  loading: false,
});

export const useAccount = (): AccountContextProps =>
  React.useContext<AccountContextProps>(AccountContext);

export const AccountPage: React.FC = () => {
  const { account } = useParams();
  const query = useGetAccountQuery({
    variables: { key: account! },
    skip: !account,
  });

  if (query.loading) {
    return null;
  }

  if (!query.data?.account) {
    return (
      <SimpleLayout>
        <p>The requested account cannot be found.</p>
      </SimpleLayout>
    );
  }

  const props: AccountContextProps = {
    account: query.data.account as Account,
    reload: () => query.refetch(),
    loading: query.loading,
  };

  return (
    <AccountContext.Provider value={props}>
      <Routes>
        <Route path="projects/:project/*" element={<AccountProject />} />
      </Routes>
    </AccountContext.Provider>
  );
};
