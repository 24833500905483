var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
export var IconButton = React.forwardRef(function (_a, ref) {
    var _b;
    var children = _a.children, kind = _a.kind, loading = _a.loading, hover = _a.hover, focus = _a.focus, rest = __rest(_a, ["children", "kind", "loading", "hover", "focus"]);
    return (_jsx("button", __assign({ className: cx("icon-button", (_b = {},
            _b["--".concat(kind)] = !!kind,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--loading"] = loading,
            _b)) }, rest, { ref: ref, children: children })));
});
export var IconLinkButton = function (_a) {
    var _b;
    var children = _a.children, kind = _a.kind, loading = _a.loading, hover = _a.hover, focus = _a.focus, rest = __rest(_a, ["children", "kind", "loading", "hover", "focus"]);
    return (_jsx(NavLink, __assign({ className: cx("icon-button", (_b = {},
            _b["--".concat(kind)] = !!kind,
            _b["--hover"] = hover,
            _b["--focus"] = focus,
            _b["--loading"] = loading,
            _b)) }, rest, { children: children })));
};
