import * as React from "react";
import { FieldMap } from "components/components/fields/types";
import { Fields } from "components/components/fields";

type FieldContextProps = {
  fields: FieldMap;
};

const FieldContext = React.createContext<FieldContextProps>({
  fields: Fields,
});

export const FieldProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const value: FieldContextProps = {
    fields: Fields,
  };

  return React.createElement(FieldContext.Provider, { value, children });
};

export const useFields = () =>
  React.useContext<FieldContextProps>(FieldContext);
