import * as React from "react";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
} from "react-select";
import {
  EntriesOrderBy,
  ModelFieldsInherited,
  useListEntriesQuery,
  ValueField,
} from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";

export type InlineEntrySelectorProps = {
  field: ModelFieldsInherited;
  value: ValueField[];
  onChange: (value: ValueField[]) => void;
};

type OptionType = {
  value: string;
  label: string;
};

type ItemSortContextProps = any;
const ItemSortContext = React.createContext<ItemSortContextProps>(null);

const SortableMultiValue: React.FC<MultiValueProps<OptionType>> = (props) => {
  const sortable = useSortable({ id: props.data.value });

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const style: React.CSSProperties = {
    transform: sortable.transform
      ? CSS.Transform.toString({
          x: sortable.transform.x,
          y: sortable.transform.y,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition: sortable.transition || undefined,
  };
  const innerProps = { ...props.innerProps, onMouseDown };

  return (
    <ItemSortContext.Provider value={sortable}>
      <div ref={sortable.setNodeRef} style={style}>
        <components.MultiValue {...props} innerProps={innerProps} />
      </div>
    </ItemSortContext.Provider>
  );
};

const SortableMultiValueLabel: React.FC<MultiValueGenericProps<OptionType>> = (
  props
) => {
  const context = React.useContext<ItemSortContextProps>(ItemSortContext);

  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        ...props.innerProps,
        ...context.attributes,
        ...context.listeners,
      }}
    />
  );
};

export const InlineEntrySelector: React.FC<InlineEntrySelectorProps> = ({
  field,
  value,
  onChange,
}) => {
  const [input, setInput] = React.useState("");
  const { project, environment } = useEnvironment();
  const query = useListEntriesQuery({
    skip: !input,
    variables: {
      project: project.id,
      environment: environment.id,
      filter: {
        and: [
          { name: { likeInsensitive: `%${input}%` } },
          ...(field.config?.allowed
            ? [{ modelId: { in: field.config.allowed } }]
            : []),
        ],
      },
      skip: 0,
      take: 10,
      sort: EntriesOrderBy.CreatedAtDesc,
    },
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = value.findIndex((ent) => ent.valueEntryId === active.id);
      const newIndex = value.findIndex((ent) => ent.valueEntryId === over.id);
      const newValue = arrayMove(value, oldIndex, newIndex).map(
        (ent, index) => ({
          ...ent,
          index,
        })
      );
      onChange(newValue);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={value.map((ent) => ent.valueEntryId)}>
        <Select
          components={{
            MultiValue: SortableMultiValue,
            MultiValueLabel: SortableMultiValueLabel,
          }}
          isMulti={field.multi ?? false}
          placeholder=""
          onInputChange={setInput}
          value={value.map((ent) => ({
            value: ent?.valueEntryId!,
            label: ent?.valueEntryProjectEnvironment!.name!,
          }))}
          onChange={(newValue: any) => {
            const val = field.multi ? newValue : [newValue];

            onChange(
              val.map((ent: any, index: number) => ({
                modelFieldId: field.id,
                valueEntryId: ent.value,
                valueEntryProjectEnvironment: {
                  name: ent.label,
                },
                index,
              }))
            );
          }}
          options={
            query.data?.list?.nodes.map((ent) => ({
              value: ent!.id!,
              label: ent!.name!,
            })) ?? []
          }
          menuPosition="fixed"
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "var(--input-background-colour)",
              borderColor: "var(--input-border-colour)",
              borderRadius: "var(--input-radius)",
              fontSize: "var(--font-size-base)",
              lineHeight: "1.4;",
              minHeight: 44,

              ":hover": {
                borderColor: "var(--input-border-colour-hover)",
              },

              ...(state.isFocused
                ? {
                    outline: "none",
                    borderColor: "var(--input-border-colour-focussed)",
                    backgroundColor: "var(--input-background-colour-focussed)",
                    boxShadow:
                      "0px 0px 0px 2px var(--colour-neutral-dark-cloud)",
                  }
                : undefined),

              ...(state.isDisabled
                ? {
                    backgroundColor: "var(--input-background-colour-disabled)",
                    boxShadow: "none",
                    borderColor: "var(--input-border-colour-disabled)",
                  }
                : undefined),
            }),
          }}
        />
      </SortableContext>
    </DndContext>
  );
};
