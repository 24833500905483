import * as React from "react";
import { Button, Icons } from "pokko-shared";
import {
  MediaFolderListItem,
  MediaFolderListItemNode,
} from "./components/MediaFolderListItem";
import { TreeHook } from "components/components/taxonomy/tree/types";

export type MediaFolderListProps = {
  rootUrl: string;
  tree: TreeHook<MediaFolderListItemNode>;
  onCreate: () => void;
  onDropFiles: (target: string, ids: string[]) => void;
  onDropFolder: (target: string, id: string) => void;
  onRename: (id: string, value: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
};

export const MediaFolderList: React.FC<MediaFolderListProps> = ({
  tree,
  onCreate,
  onDropFiles,
  onDropFolder,
  onRename,
  onDelete,
}) => {
  return (
    <div className="media-folder-list__container">
      <div className="media-folder-list__header">
        <h2>Folders</h2>
        <Button
          kind="tertiary"
          onClick={onCreate}
          icons={{ left: <Icons.FolderNewIcon /> }}
        >
          New folder
        </Button>
      </div>
      <ul className="media-folder-list__body media-folder-list__items">
        <MediaFolderListItem
          id=""
          name="Uncategorised"
          parent={null}
          onDropFiles={onDropFiles}
          onDropFolder={onDropFolder}
          canCreateChildren={false}
          editable={false}
        />
        {tree.nodesFlat.map((ent) => (
          <MediaFolderListItem
            key={ent.id}
            {...ent}
            name={ent.data.name}
            canCreateChildren={true}
            onDropFiles={onDropFiles}
            onDropFolder={onDropFolder}
            editable={true}
            expanded={ent.expanded}
            onToggle={
              ent.childCount > 0 ? () => tree.toggle(ent.id) : undefined
            }
            level={ent.level}
            path={ent.path}
            onRename={(val) => onRename(ent.id, val)}
            onDelete={() => onDelete(ent.id)}
          />
        ))}
      </ul>
      <MediaFolderListItem
        id="trash"
        name="Trash"
        parent={null}
        onDropFiles={onDropFiles}
        onDropFolder={onDropFolder}
        canCreateChildren={false}
        editable={false}
      />
    </div>
  );
};
