import * as React from "react";
import { useEnvironment } from "routes/accounts/projects/environments";
import { ModelSettings, ModelSettingsProps } from "../settings/ModelSettings";

export const ModelCreate: React.FC<ModelSettingsProps> = (props) => {
  const { models } = useEnvironment();
  return (
    <div className="model-create__container">
      <div className="model-create__header">
        <h1 className="h2">Models</h1>
        {models.length === 0 ? (
          <p>There are currently no models, create one to get started.</p>
        ) : null}
      </div>
      <ModelSettings {...props} />
    </div>
  );
};
