import * as React from "react";
import {
  useListMediaItemsQuery,
  useGetMediaFolderQuery,
  useUpdateMediaItemMutation,
  useWatchMediaSubscription,
} from "api/graphql";
import { MediaItemList } from "components/components/media/items/list/MediaItemList";
import { useDebounce } from "use-debounce";
import { useUpload } from "providers/content/upload";
import { useProject } from "..";
import { MediaItemInput } from "components/components/media/items/list/components/MediaItemEditor";
import { buildPaginationProps } from "components/elements/pagination/Pagination";
import { useParams } from "react-router";

export type MediaItemListControllerProps = {
  selection: string[];
  setSelection: React.Dispatch<React.SetStateAction<string[]>>;
};

export const MediaItemListController: React.FC<
  MediaItemListControllerProps
> = ({ selection, setSelection }) => {
  const { id } = useParams();
  const { project } = useProject();
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState("");
  const [expanded, setExpanded] = React.useState("");
  const [searchDeb] = useDebounce(search, 250);
  const [skip, setSkip] = React.useState(0);
  const [take /*, setTake*/] = React.useState(10);
  const query = useListMediaItemsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      project: project.id,
      folder: id === "trash" ? undefined : searchDeb ? undefined : id || null,
      filter: {
        name: { likeInsensitive: `%${searchDeb}%` },
        deletedAt: { isNull: id !== "trash" },
      },
      skip,
      take,
    },
  });

  const folderQuery = useGetMediaFolderQuery({
    variables: {
      id,
    },
    skip: !id || id === "trash",
  });

  useWatchMediaSubscription({
    variables: {
      project: project.id,
    },
    onSubscriptionData: (x) => {
      query.refetch();
    },
  });

  const [update, updateStatus] = useUpdateMediaItemMutation();
  const upload = useUpload();

  React.useEffect(() => {
    setSelected("");
    setSearch("");
  }, [id]);

  const handleSave = (id: string) => async (value: MediaItemInput) => {
    await update({
      variables: {
        id: id,
        patch: {
          name: value.name,
          mediaType: value.type,
        },
      },
    });

    setSelected("");
  };

  const handleDelete = async (id: string) => {
    await update({
      variables: { id, patch: { deletedAt: new Date().toISOString() } },
    });

    query.refetch();
  };

  const handleDrop = (files: File[]) => {
    upload.enqueue(
      files.map((file) => ({
        file,
        folder: id!,
        project: project.id,
      }))
    );
  };

  if (id && id !== "trash" && !folderQuery.data?.entity) {
    return null;
  }

  return (
    <MediaItemList
      folder={{
        id: id!,
        name: id ? folderQuery.data?.entity?.name! : "Uncategorised",
      }}
      onDrop={searchDeb ? undefined : handleDrop}
      loading={query.loading}
      items={
        query.data?.list?.nodes.map((ent) => ({
          id: ent?.id!,
          project: project.id,
          name: ent?.name!,
          size: ent?.size!,
          mediaInfo: ent?.mediaInfo!,
          mediaType: ent?.mediaType!,
          selected: selection.includes(ent?.id!),
          expanded: expanded === ent?.id!,
          uploaded: !!ent?.uploadedAt,
          onReload: () => {
            query.refetch();
            setSelection([]);
          },
          onSelect: () => {
            setSelection((prev) =>
              prev.includes(ent!.id!)
                ? prev.filter((id) => id !== ent!.id!)
                : [...prev, ent!.id!]
            );
          },
          onClick: () =>
            setExpanded((prev) => (prev === ent?.id! ? null : ent?.id!)),
          detail:
            selected === ent?.id
              ? {
                  id: ent!.id!,
                  value: {
                    name: ent?.name!,
                    type: ent?.mediaType!,
                  },
                  onDelete: () => handleDelete(ent.id),
                  onSave: handleSave(ent.id),
                  saving: updateStatus.loading,
                  downloadUrl: `https://cdn.pokko.io/${project.id}/${ent.id}`,
                  thumbnailUrl: ent.contentType.startsWith("image")
                    ? `https://cdn.pokko.io/${project.id}/${ent.id}/$/360/360/cover/centre`
                    : undefined,
                }
              : undefined,
        })) ?? []
      }
      search={{
        value: search,
        onChange: setSearch,
      }}
      pagination={buildPaginationProps(
        skip,
        query.data?.list?.nodes.length ?? 0,
        query.data?.list?.totalCount ?? 0,
        () => setSkip(skip + take),
        () => setSkip(skip - take)
      )}
    />
  );
};
