import * as React from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import {
  InputField,
  SelectField,
  Button,
  Select,
  IconButton,
  Icons,
  LinkButton,
} from "pokko-shared";
import { Model, TaxonomyType, Website } from "api/graphql";
import { strings } from "strings";
import { EntryInputController } from "../../entries/selector/EntryInputController";
import { TaxonomyLevelEditor, TaxonomyLevelInput } from "./TaxonomyLevelEditor";

export type TaxonomyInput = {
  alias: string;
  entry?: string;
  type: TaxonomyType;
  levels: TaxonomyLevelInput[];
  websites: { id: string; name: string }[];
};

export type TaxonomyEditorProps = {
  path: string[];
  value: TaxonomyInput;
  save: [(value: TaxonomyInput) => void, { loading: boolean }];
  delete: [() => void, { loading: boolean }];
  models: Model[];
  websites: Website[];
  onModelDefault: (taxonomyLevel: string, model: string) => void;
};

export const TaxonomyEditor: React.FC<TaxonomyEditorProps> = ({
  value,
  path,
  models,
  websites,
  save: [onSave, { loading: saving }],
  delete: [onDelete, { loading: deleting }],
  onModelDefault,
}) => {
  const [selectedWebsite, setSelectedWebsite] = React.useState("");

  const { register, handleSubmit, control, watch } = useForm<TaxonomyInput>({
    defaultValues: value,
  });

  const levels = useFieldArray<TaxonomyInput, "levels", "id">({
    control,
    name: "levels",
  });

  const fieldWebsites = useFieldArray<TaxonomyInput, "websites", "id">({
    control,
    name: "websites",
  });

  const [type] = watch(["type"]);
  return (
    <div className="taxonomy-editor__container">
      <div>
        <LinkButton
          small
          kind="tertiary"
          to={`../?path=${path.join("/")}`}
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to structure
        </LinkButton>
      </div>

      <form onSubmit={handleSubmit(onSave)} className="taxonomy-editor__detail">
        <InputField label="Alias" {...register("alias")} />

        <div className="taxonomy-editor__details">
          <SelectField label="Node type" key="type" {...register("type")}>
            <option value={TaxonomyType.Entry}>Entry</option>
            <option value={TaxonomyType.Dynamic}>Dynamic</option>
            <option value={TaxonomyType.External}>External</option>
            <option value={TaxonomyType.Redirect}>Redirect</option>
          </SelectField>

          {[TaxonomyType.Entry, TaxonomyType.Dynamic].includes(type) ? (
            <Controller
              control={control}
              name="entry"
              render={({ field: { value, onChange } }) => (
                <>
                  <strong>Node entry</strong>
                  <EntryInputController
                    value={value!}
                    onChange={onChange}
                    onRemove={() => onChange(null)}
                  />
                </>
              )}
            />
          ) : null}
        </div>

        {[TaxonomyType.Dynamic].includes(type) ? (
          <ul className="taxonomy-levels__container">
            <li>
              <strong>Levels</strong>
            </li>
            {levels.fields.map((ent, idx) => (
              <li key={ent.levelId}>
                <TaxonomyLevelEditor
                  isNew={!Boolean(ent.levelId)}
                  models={models}
                  control={control}
                  register={register}
                  watch={watch}
                  index={idx}
                  value={ent as TaxonomyLevelInput}
                  modelsByDefault={[]}
                  onModelDefault={() =>
                    onModelDefault(ent.levelId!, ent.model!)
                  }
                  onRemove={() => levels.remove(idx)}
                />
              </li>
            ))}
            <li>
              <Button
                type="button"
                onClick={() => levels.append({} as any)}
                kind="secondary"
              >
                Add level
              </Button>
            </li>
          </ul>
        ) : null}

        {websites.length > 0 ? (
          <div className="taxonomy-websites__container">
            <strong>Associated websites</strong>
            {fieldWebsites.fields.length === 0 ? (
              <p>There are no websites associated with this node.</p>
            ) : (
              <ul className="taxonomy-websites__list">
                {fieldWebsites.fields.map((ent, index) => (
                  <li key={ent.id}>
                    <input
                      type="hidden"
                      {...register(`websites.${index}.id` as const)}
                    />
                    <span>{ent.name}</span>
                    <IconButton
                      kind="tertiary"
                      onClick={() => fieldWebsites.remove(index)}
                    >
                      <Icons.DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            )}
            <div className="taxonomy-websites__actions">
              <Select value={selectedWebsite} onChangeText={setSelectedWebsite}>
                <option />
                {websites.map((web) => (
                  <option
                    key={web.id}
                    value={web.id}
                    disabled={Boolean(
                      fieldWebsites.fields.find((x) => x.id === web.id)
                    )}
                  >
                    {web.name}
                  </option>
                ))}
              </Select>
              <Button
                type="button"
                kind="secondary"
                onClick={() => {
                  fieldWebsites.append({
                    id: selectedWebsite,
                    name: websites.find((x) => x.id === selectedWebsite)?.name!,
                  });
                  setSelectedWebsite("");
                }}
              >
                Associate website
              </Button>
            </div>
          </div>
        ) : null}

        <div className="taxonomy-editor__actions">
          <Button kind="primary" type="submit" loading={saving}>
            {strings.save}
          </Button>
          <Button
            kind="tertiary"
            type="button"
            loading={deleting}
            onClick={onDelete}
          >
            {strings.delete}
          </Button>
        </div>
      </form>
    </div>
  );
};
