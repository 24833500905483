import * as React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

import {
  ModelFieldsInherited,
  useCreateModelFieldMutation,
  usePutModelMutation,
  useUpdateModelFieldMutation,
  useUpdateModelMutation,
  useUpdateModelSnapshotMutation,
} from "api/graphql";

import { ModelFieldInput } from "components/components/models/detail/components/ModelFieldEditor";
import { ModelDetail } from "components/components/models/detail/ModelDetail";
import { ModelSettingsInput } from "components/components/models/settings/ModelSettings";

import { useModel } from "..";
import { useEnvironment } from "../../..";
import { ValueEditorContextProvider } from "components/components/values/editor/ValueEditorContext";
import { GraphQLErrorExt } from "api/type";
import { FieldErrors } from "react-hook-form";

const sanitiseFieldValues = (input: ModelFieldInput): ModelFieldInput => {
  const valueStart = parseInt(input.valueCount?.start?.value as any, 10);
  const valueEnd = parseInt(input.valueCount?.end?.value as any, 10);

  return {
    ...input,
    valueCount: {
      start: isNaN(valueStart)
        ? null
        : {
            value: valueStart,
            inclusive: true,
          },
      end: isNaN(valueEnd)
        ? null
        : {
            value: valueEnd,
            inclusive: true,
          },
    },
  };
};

export const handleFieldErrors = (ex: any) => {
  if (ex.graphQLErrors) {
    return (ex.graphQLErrors as GraphQLErrorExt[])
      .filter((ent) => ent.field)
      .map(({ message, code, field }) => {
        return {
          message,
          code,
          field: field!,
        };
      })
      .reduce((p, c) => ({ [c.field]: c, ...p }), {});
  }

  return {};
};

export const ModelHome: React.FC = () => {
  const { model, environment, project, reload } = useModel();
  const { models } = useEnvironment();
  const navigate = useNavigate();

  const [put, putStatus] = usePutModelMutation();
  const [update, updateStatus] = useUpdateModelMutation();
  const [updateField, updateFieldStatus] = useUpdateModelFieldMutation();
  const [createField, createFieldStatus] = useCreateModelFieldMutation();
  const [updateModelSnapshot] = useUpdateModelSnapshotMutation();
  const [fieldSaveErrors, setFieldSaveErrors] = React.useState<
    FieldErrors<ModelFieldInput>
  >({});
  const [modelSaveErrors, setModelSaveErrors] = React.useState<
    FieldErrors<ModelSettingsInput>
  >({});

  const handleSave = async (values: ModelSettingsInput) => {
    try {
      await put({
        variables: {
          model: {
            id: model.id,
            environmentId: environment.id,
            projectId: project.id,
            name: values.name,
            alias: values.alias,
          },
          usage: values.usage,
          parents: values.inheritance?.map((ent) => ent.id),
          fieldOrder: values.fields?.map((ent) => ent.id),
        },
      });

      navigate(".");

      reload();
    } catch (ex) {
      setModelSaveErrors(handleFieldErrors(ex));
    }
  };

  const handleDelete = async () => {
    await update({
      variables: {
        id: model.id,
        environment: environment.id,
        project: project.id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    navigate("../");
  };

  const handleRestore = async () => {
    await update({
      variables: {
        id: model.id,
        environment: environment.id,
        project: project.id,
        patch: {
          deletedAt: null,
        },
      },
    });
  };

  const handleFieldSave = async (
    field: ModelFieldsInherited | null,
    values: ModelFieldInput
  ) => {
    setFieldSaveErrors({});
    try {
      if (field) {
        await updateField({
          variables: {
            id: field.id,
            project: project.id,
            environment: environment.id,
            patch: sanitiseFieldValues(values),
          },
        });
      } else {
        await createField({
          variables: {
            input: {
              projectId: project.id,
              environmentId: environment.id,
              ...sanitiseFieldValues(values),
              modelId: model.id,
              index:
                (model.fieldsAll.nodes.filter(
                  (ent) => ent?.sourceModel?.id === model.id
                ).length ?? -1) + 1,
            },
          },
        });
      }
      navigate(".");
    } catch (ex) {
      setFieldSaveErrors(handleFieldErrors(ex));
    }
  };

  const handleFieldDelete = async (field: ModelFieldsInherited) => {
    await updateField({
      variables: {
        id: field.id,
        project: project.id,
        environment: environment.id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });
    navigate(".");
  };
  const handleFieldRestore = async (field: ModelFieldsInherited) => {
    await updateField({
      variables: {
        id: field.id,
        project: project.id,
        environment: environment.id,
        patch: {
          deletedAt: null,
        },
      },
    });
  };

  const handleAfterBaseSave = async () => {
    await updateModelSnapshot({
      variables: {
        model: model.id,
        project: project.id,
        environment: environment.id,
      },
    });
  };

  return (
    <>
      <Helmet
        title={[model.name, environment.name, project.name].join(" - ")}
      />
      <ValueEditorContextProvider>
        <ModelDetail
          model={model}
          settings={{
            isNew: false,
            loading: putStatus.loading || updateStatus.loading,
            onDelete: handleDelete,
            onRestore: handleRestore,
            onSubmit: handleSave,
            errors: modelSaveErrors,
            models,
            deleted: !!model.deletedAt,
            restoring: updateStatus.loading,
          }}
          values={{
            id: model.valueBaseId,
          }}
          fields={{
            errors: fieldSaveErrors,
            saving: updateFieldStatus.loading || createFieldStatus.loading,
            onSave: handleFieldSave,
            onDelete: handleFieldDelete,
            onRestore: handleFieldRestore,
            deleting: updateStatus.loading,
            restoring: updateStatus.loading,
          }}
          onAfterBaseSave={handleAfterBaseSave}
        />
      </ValueEditorContextProvider>
    </>
  );
};
