import * as React from "react";
import cx from "classnames";
import { Icons } from "pokko-shared";
import { NavLink } from "react-router-dom";
import { useSortable } from "@dnd-kit/sortable";

export type MediaFolderListItemNode = {
  id: string;
  name: string;
};

export type MediaFolderListItemProps = MediaFolderListItemNode & {
  canCreateChildren: boolean;
  editable: boolean;
  expanded?: boolean;
  level?: number;
  parent: string | null;
  path?: string[];
  payload?: { [key: string]: any };
  onDelete?: () => Promise<void>;
  onDropFiles?: (target: string, ids: string[]) => void;
  onDropFolder?: (target: string, id: string) => void;
  onRename?: (value: string) => Promise<void>;
  onToggle?: () => void;
};

export const MediaFolderListItem: React.FC<MediaFolderListItemProps> = ({
  id,
  name,
  editable,
  expanded,
  level,
  path,
  onToggle,
  onDelete,
  onRename,
}) => {
  const sortable = useSortable({
    id: `folder-${id}`,
    data: { type: "folder", id, path },
  });

  const [more, setMore] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [nameEditing, setName] = React.useState(name);

  const handleConfirm = async () => {
    if (editing) {
      await onRename?.(nameEditing);
      setEditing(false);
      setMore(false);
    } else if (deleting) {
      await onDelete?.();
      setDeleting(false);
      setMore(false);
    }
  };

  const isOver =
    sortable.over?.data.current?.type === "folder" &&
    sortable.over?.data.current?.id === id;

  return (
    <li
      style={level ? { marginLeft: `${level}rem` } : {}}
      className={cx("media-folder-list__item", {
        "--expandable": onToggle,
        "--deleting": deleting,
        "--editing": editing,
        "--dragover": isOver,
      })}
      ref={sortable.setNodeRef}
      {...sortable.attributes}
    >
      {!editing && onToggle ? (
        <button className="media-folder-list__item-chevron" onClick={onToggle}>
          {expanded ? <Icons.DownIcon /> : <Icons.NextIcon />}
        </button>
      ) : null}
      {editing ? null : (
        <div className="media-folder-list__item-icon" {...sortable.listeners}>
          {expanded ? <Icons.FolderOpenIcon /> : <Icons.FolderIcon />}
        </div>
      )}
      <NavLink to={id} className="media-folder-list__item-details" draggable>
        {editing ? (
          <input
            value={nameEditing}
            onChange={(ev) => setName(ev.currentTarget.value)}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                handleConfirm();
              }
            }}
            autoFocus
          />
        ) : (
          <div className="media-folder-list__item-name">{name}</div>
        )}
      </NavLink>
      <div className="media-folder-list__item-actions">
        {more && (editing || deleting) ? (
          <>
            <button key="confirm" type="button" onClick={handleConfirm}>
              <Icons.SuccessIcon />
            </button>
            <button
              key="cancel"
              type="button"
              onClick={() => {
                setDeleting(false);
                setEditing(false);
                setMore(false);
              }}
            >
              <Icons.CloseIcon />
            </button>
          </>
        ) : more ? (
          <>
            <button
              key="edit"
              type="button"
              onClick={() => {
                setName(name);
                setEditing(true);
              }}
            >
              <Icons.EditIcon />
            </button>
            <button
              key="delete"
              type="button"
              onClick={() => setDeleting(true)}
            >
              <Icons.DeleteAltIcon />
            </button>
            <button key="more" type="button" onClick={() => setMore(false)}>
              <Icons.MoreIcon />
            </button>
          </>
        ) : editable ? (
          <button
            key="more"
            type="button"
            onClick={() => {
              setMore(true);
              setEditing(false);
              setDeleting(false);
            }}
          >
            <Icons.MoreIcon />
          </button>
        ) : null}
      </div>
    </li>
  );
};
