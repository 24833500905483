import * as React from "react";
import { MediaItem } from "api/graphql";
import { MediaCard } from "components/components/media/card/MediaCard";

export type MediaSearchItemProps = {
  item: MediaItem;
  selected: boolean;
  onClick: () => void;
  onDoubleClick?: () => void;
};

export const MediaSearchItem: React.FC<MediaSearchItemProps> = ({
  item,
  selected,
  onClick,
  onDoubleClick,
}) => (
  <MediaCard
    project={item.projectId}
    selected={selected}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    id={item.id}
    mediaInfo={item.mediaInfo}
    mediaType={item.mediaType}
    name={item.name}
    size={item.size}
    uploaded={Boolean(item.uploadedAt)}
  />
);
