import * as React from "react";
import { matchPath } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";
import {
  Input,
  InlineRadioButtons,
  Icons,
  LinkButton,
  Button,
} from "pokko-shared";
import { ModelUsage } from "api/graphql";
import { Tag } from "components/elements/tag/Tag";
import { strings } from "strings";

type Model = {
  id: string;
  name: string;
  alias: string;
  url: string;
  usage: ModelUsage;
  deleted: boolean;
};

export type ModelListProps = {
  models: Model[];
  createUrl?: string;
  search?: {
    value: string;
    onChange: (value: string) => void;
  };
  usage?: {
    value: ModelUsage | null;
    onChange: (value: ModelUsage | null) => void;
  };
  showDeleted?: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
};

const ModelListItem: React.FC<{ ent: Model }> = ({ ent }) => {
  const ref = React.createRef<HTMLAnchorElement>();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (matchPath(pathname, ent.url)) {
      ref.current?.scrollIntoView();
    }
  }, [pathname, ent, ref]);

  return (
    <NavLink
      key={ent.id}
      to={ent.url}
      className={cx("model-list__model-detail", { "--deleted": ent.deleted })}
      ref={ref}
      end
    >
      <div className="model-list__model-detail-name">
        <p>
          {ent.name}
          {ent.deleted ? <small>Archived</small> : null}
        </p>
        <code>{ent.alias}</code>
      </div>
      <div className="model-list__model-detail-usage">
        <Tag>{strings.models.usage[ent.usage]}</Tag>
      </div>
    </NavLink>
  );
};

export const ModelList: React.FC<ModelListProps> = ({
  models,
  createUrl,
  search,
  usage,
  showDeleted,
}) => (
  <div className="model-list">
    {search ? (
      <div className="model-list__header">
        <Input
          type="search"
          placeholder="Search..."
          value={search.value}
          onChangeText={search.onChange}
        />
        {usage ? (
          <InlineRadioButtons
            onChange={usage.onChange}
            value={usage.value}
            values={[null, ...Object.values(ModelUsage)]}
            render={(val) => (val ? strings.models.usage[val] : "All")}
          />
        ) : null}
      </div>
    ) : null}
    <div className="model-list__body">
      <div className="model-list__models">
        {search?.value && models.length === 0 ? (
          <div className="model-list__models-no-results">
            <Icons.SearchIcon />
            <p>
              <strong>No results for “{search.value}”</strong>
            </p>
            <p>
              You may want to try using different keywords or checking for
              typos.
            </p>
          </div>
        ) : (
          models.map((ent) => <ModelListItem key={ent.id} ent={ent} />)
        )}
      </div>
    </div>
    <div className="model-list__footer">
      <div className="model-list__footer-actions">
        {createUrl ? (
          <LinkButton kind="primary" to={createUrl}>
            New model
          </LinkButton>
        ) : null}
      </div>
      {showDeleted ? (
        <p>
          <Button
            small
            kind="tertiary"
            onClick={() => showDeleted.onChange(!showDeleted.value)}
          >
            {`${showDeleted.value ? "Hide" : "Show"}  archived models`}
          </Button>
        </p>
      ) : null}
    </div>
  </div>
);
