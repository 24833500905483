import { jsx as _jsx } from "react/jsx-runtime";
import cx from "classnames";
export var Message = function (_a) {
    var _b;
    var colour = _a.colour, size = _a.size, children = _a.children;
    return (_jsx("div", { className: cx("message", (_b = {},
            _b["is-".concat(colour)] = colour,
            _b["is-".concat(size)] = size,
            _b)), children: children }));
};
