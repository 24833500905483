import * as React from "react";
import {
  useListMediaFoldersQuery,
  useCreateMediaFolderMutation,
  useUpdateMediaItemMutation,
  useUpdateMediaFolderMutation,
  ListMediaFoldersQuery,
  ListMediaFoldersQueryVariables,
  ListMediaFoldersDocument,
} from "api/graphql";
import { MediaFolderList } from "components/components/media/folders/list/MediaFolderList";
import { useProject } from "..";
import { useTree } from "components/components/taxonomy/tree/hook";
import { useApolloClient } from "@apollo/client";
import { MediaFolderListItemNode } from "components/components/media/folders/list/components/MediaFolderListItem";
import { LoadChildrenResult } from "components/components/taxonomy/tree/types";

type MediaFolderListControllerProps = {};

export const MediaFolderListController: React.FC<MediaFolderListControllerProps> =
  () => {
    const { project } = useProject();

    const query = useListMediaFoldersQuery({
      variables: {
        project: project.id,
        parent: null,
      },
    });

    const client = useApolloClient();
    const pageSize = 25;

    const [update] = useUpdateMediaItemMutation();
    const [updateFolder] = useUpdateMediaFolderMutation();

    const [create /*, createStatus*/] = useCreateMediaFolderMutation();

    const handleCreate = async () => {
      const name = window.prompt("Folder name");
      if (name) {
        await create({
          variables: {
            input: {
              name,
              projectId: project.id,
              parentId: null,
            },
          },
        });

        query.refetch();
      }
    };

    const handleDropFiles = async (
      target: string,
      ids: string[]
    ): Promise<void> => {
      const folderId =
        target === "uncategorised"
          ? null
          : target === "trash"
          ? undefined
          : target;

      const deletedAt =
        target === "trash" ? new Date().toISOString() : undefined;

      await Promise.all(
        ids.map(async (id) => {
          await update({
            variables: {
              id,
              patch: {
                folderId,
                deletedAt,
              },
            },
          });
        })
      );
    };

    const handleDropFolder = async (target: string, id: string) => {
      if (target === id) {
        return;
      }

      const parentId =
        target === "uncategorised"
          ? null
          : target === "trash"
          ? undefined
          : target;

      const deletedAt =
        target === "trash" ? new Date().toISOString() : undefined;

      await updateFolder({
        variables: {
          id,
          patch: {
            parentId,
            deletedAt,
          },
        },
      });

      query.refetch();
    };

    const handleDelete = React.useCallback(
      async (id: string) => {
        await updateFolder({
          variables: {
            id,
            patch: {
              deletedAt: new Date().toISOString(),
            },
          },
        });
        await query.refetch();
      },
      [query, updateFolder]
    );

    const handleRename = React.useCallback(
      async (id: string, name: string) => {
        await updateFolder({
          variables: {
            id,
            patch: {
              name,
            },
          },
        });
      },
      [updateFolder]
    );

    const loadChildren = React.useCallback(
      async (
        parent: string | null,
        skip: number
      ): Promise<LoadChildrenResult<MediaFolderListItemNode>> => {
        const res = await client.query<
          ListMediaFoldersQuery,
          ListMediaFoldersQueryVariables
        >({
          fetchPolicy: "network-only",
          query: ListMediaFoldersDocument,
          variables: {
            project: project.id,
            parent,
            skip,
            take: pageSize,
          },
        });

        if (!res.data.list) {
          throw new Error("query failed.");
        }

        return {
          hasMore: res.data.list.pageInfo.hasNextPage,
          nodes: res.data.list.nodes.map((ent) => ({
            id: ent!.id!,
            name: ent!.name!,
            parent: ent!.parentId || null,
            path: ent!.path!,
            canCreateChildren: true,
            onDelete: async () => await handleDelete(ent!.id!),
            onRename: (value: string) => handleRename(ent!.id!, value),
            editable: true,
            childCount: ent?.children.totalCount ?? 0,
          })),
        };
      },
      [client, handleDelete, handleRename, project.id]
    );

    const tree = useTree<MediaFolderListItemNode>({
      loadChildren,
      pageSize,
    });

    return (
      <MediaFolderList
        rootUrl="."
        onCreate={handleCreate}
        onDropFiles={handleDropFiles}
        onDropFolder={handleDropFolder}
        onRename={handleRename}
        onDelete={handleDelete}
        tree={tree}
      />
    );
  };
