import * as React from "react";
import { Route, Routes } from "react-router";
import { Helmet } from "react-helmet";
import { ProjectLayout } from "components/layout/project/ProjectLayout";
import { useProject } from "..";
import { TwoColumnLayout } from "components/layout/twocol/TwoColumnLayout";
import { WebhookListController } from "./WebhookListController";
import { WebhookDetailController } from "./webhook/WebhookDetailController";

export const ProjectWebhooksPage: React.FC = () => {
  const { project } = useProject();
  return (
    <ProjectLayout>
      <Helmet title={["Webhooks", project.name].join(" - ")} />
      <TwoColumnLayout
        left={<WebhookListController />}
        right={
          <Routes>
            <Route path=":webhook/*" element={<WebhookDetailController />} />
          </Routes>
        }
      />
    </ProjectLayout>
  );
};
