import * as React from "react";
import { EntrySettings } from "./components/EntrySettings";
import { EntryEditor } from "./components/EntryEditor";
import {
  EntryVersion,
  EntryVersions,
  EntryVersionsProps,
  PublishTarget,
} from "./components/EntryVersions";
import { EntryWatchers } from "./components/EntryWatchers";

export type EntryDetailInput = {
  name: string;
  model: string;
  created: Date;
  createdBy?: string | null;
  modified: Date;
  modifiedBy?: string | null;
  deleted: Date | null;
};

export type EntryLink = {
  website: string;
  url: string;
};

export type EntryWatcher = {
  name: string;
  session: string;
  user: string;
};

export type EntryDetailProps = {
  project: string;
  environment: string;
  entry: {
    path?: string[];
    value: EntryDetailInput;
    onSave: (value: EntryDetailInput) => Promise<void>;
    saving: boolean;
    onDelete: () => Promise<void>;
    deleting: boolean;
    onRestore: () => void;
    restoring: boolean;
    onPublish2: (target: string) => Promise<void>;
    publishing2: boolean;
    onRetract2: (target: string) => Promise<void>;
    retracting2: boolean;
  };
  version: EntryVersion;
  versions: EntryVersionsProps;
  publishTargets: PublishTarget[];
  links: EntryLink[];
  watchers: EntryWatcher[];
};

export const EntryDetail: React.FC<EntryDetailProps> = (props) => (
  <div className="entry-detail__container">
    <EntryEditor {...props} />
    <aside className="entry-detail__meta">
      <EntrySettings {...props} />
      <EntryVersions {...props.versions} />
      <EntryWatchers list={props.watchers} />
    </aside>
  </div>
);
