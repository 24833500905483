import * as React from "react";
import { TextArea } from "pokko-shared";
import { FieldInputProps } from "../../types";

export const Multiline: React.FC<FieldInputProps> = ({
  value,
  onChange,
  disabled,
  readOnly,
}) => (
  <TextArea
    value={value.valueScalar?.text ?? ""}
    onChangeText={(text: string) =>
      onChange({
        ...value,
        valueScalar: { text },
      })
    }
    disabled={disabled}
    readOnly={readOnly}
  />
);
