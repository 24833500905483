import * as React from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

export const ApiProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const headers = {};

  const httpLink = new BatchHttpLink({
    uri: "/graphql",
    headers,
    credentials: "include",
    fetchOptions: {
      credentials: "include",
    },
  });

  const wsLinkUri =
    window.location.hostname === "localhost"
      ? "ws://localhost:5010/graphql"
      : `wss://${window.location.host}/graphql`;

  const wsLink = new WebSocketLink({
    uri: wsLinkUri,

    options: {
      reconnect: true,
    },
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const cache = new InMemoryCache();
  const client = new ApolloClient<any>({
    link,
    cache,
  });

  return React.createElement(ApolloProvider, { client, children });
};
