import * as React from "react";

export type DateDisplayProps = {
  label?: string;
  value?: Date | null;
  fallback?: string;
};

export const DateDisplay: React.FC<DateDisplayProps> = ({
  label,
  value,
  fallback,
}) => (
  <time className="display-date">
    {label ? <strong>{label}</strong> : null}
    {value ? (
      <>
        <span>{value.toLocaleDateString(navigator.language)}</span>
        <span>-</span>
        <span>{value.toLocaleTimeString(navigator.language)}</span>
      </>
    ) : (
      <span>{fallback}</span>
    )}
  </time>
);
