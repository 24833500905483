import * as React from "react";
import { FieldEditor } from "./FieldEditor";
import { useValueEditor } from "./hook";

export type ValueEditorProps = {
  id: string;
  title?: string;
};

export const ValueEditor: React.FC<ValueEditorProps> = ({ id, title }) => {
  const { fieldEditors, loading, models, value } = useValueEditor(id);

  if (loading) {
    return null;
  }

  if (!value) {
    return <pre>Invalid value</pre>;
  }

  return (
    <div className="value-editor">
      {title ? <h3 className="h3">{title}</h3> : null}

      {models.map((mod) =>
        fieldEditors.length > 0 ? (
          fieldEditors
            .filter((ent) => ent.model === mod.alias)
            .map((ent) => (
              <FieldEditor key={`${id}.${ent.field.id}`} {...ent} />
            ))
        ) : (
          <p>This model ({mod.name}) does not contain any fields</p>
        )
      )}
    </div>
  );
};
