import * as React from "react";
import { MediaItemDetail } from "./MediaItemEditor";
import { MediaCard, MediaCardProps } from "../../../card/MediaCard";

export type MediaItemListItemProps = {
  detail?: MediaItemDetail;
} & MediaCardProps;

export const MediaItemListItem: React.FC<MediaItemListItemProps> = ({
  detail,
  ...rest
}) => <MediaCard {...rest} />;
