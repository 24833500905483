import * as React from "react";
import { SimpleNavbar } from "components/elements/navbar/simple/SimpleNavbar";
import cx from "classnames";
import { Helmet } from "react-helmet";

export type SimpleLayoutProps = React.PropsWithChildren<{
  white?: boolean;
}>;

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({
  children,
  white,
}) => (
  <div className={cx("simple-layout")}>
    <Helmet bodyAttributes={{ class: cx({ "is-white": white }) }} />
    <SimpleNavbar />
    <main>{children}</main>
  </div>
);
