import * as React from "react";
import { Icons } from "pokko-shared";
import { EntryWatcher } from "../EntryDetail";

export type PublishTarget = {
  id: string;
  name: string;
  date: Date;
};

export type EntryWatchersProps = {
  list: EntryWatcher[];
};

export const EntryWatchers: React.FC<EntryWatchersProps> = (props) => {
  const { list } = props;
  const [expanded, setExpanded] = React.useState(true);

  return (
    <div className="entry-detail__watchers">
      <button
        className="entry-detail__watchers-header"
        onClick={() => setExpanded(!expanded)}
      >
        <strong>Users looking at this entry</strong>
        <Icons.DownIcon />
      </button>
      {expanded ? (
        <>
          <ul className="entry-detail__watchers-list">
            {list.map((ent) => (
              <li className="entry-detail__watcher" key={ent.session}>
                {ent.name}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
};
