import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import cx from "classnames";
import { Icons } from "pokko-shared";
import { Project } from "api/graphql";
import { useEnvironmentOpt } from "routes/accounts/projects/environments";
import { useAccount } from "routes/accounts";

const buildPath = (
  account: string,
  project: string,
  environment?: string
): string => {
  // TODO: i don't like this.  but useRouteMatch() doesn't return the URL i'd like.
  // as the route match is much farther down the tree. ideally i could just replace
  // :project with the id somehow, but alas, a problem for another day.
  const path = window.location.pathname.split("/");

  if (path[1] === "accounts") {
    path[2] = account;
  }
  if (path[3] === "projects") {
    path[4] = project;
  }
  if (environment) {
    path[5] = "environments";
    path[6] = environment;
  }

  return path.join("/");
};

export const ProjectItem: React.FC<{ ent: Project }> = ({ ent }) => {
  const { account } = useAccount();
  const env = useEnvironmentOpt();

  return (
    <li key={ent.id}>
      <div className="navbar__project-selector-project-title">
        <strong>{ent.name}</strong>
        <NavLink to={`/accounts/${account.key}/projects/${ent.key}/settings`}>
          <Icons.SettingsIcon />
        </NavLink>
      </div>
      <ul>
        {ent.projectEnvironments?.nodes
          .filter((env2) => !Boolean(env2?.deletedAt))
          .map((env2) => (
            <li
              key={env2?.id}
              className={cx({ "is-active": env2?.id === env?.environment?.id })}
            >
              <Link to={buildPath(account.key!, ent.key!, env2?.key)}>
                {env2?.name}
              </Link>
            </li>
          ))}
      </ul>
    </li>
  );
};
