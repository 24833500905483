import * as React from "react";
import cx from "classnames";
import { Icons } from "pokko-shared";

import { MediaType } from "api/graphql";

export type MediaState = "pending" | "complete" | "active" | "paused";

export type MediaUploadWidgetItemProps = {
  id: string;
  name: string;
  type: MediaType;
  state: MediaState;
  progress: number;
  onPause: () => void;
  onResume: () => void;
  onCancel: () => void;
};

export const MediaUploadWidgetItem: React.FC<MediaUploadWidgetItemProps> = ({
  progress,
  name,
  type,
  state,
  onPause,
  onResume,
  onCancel,
}) => (
  <div
    className={cx("media-upload-widget-item__container", {
      [`--${state}`]: state,
    })}
  >
    <progress
      className="media-upload-widget-item__progress"
      max={1}
      value={progress}
    />
    <div className="media-upload-widget-item__body">
      <Icons.MediaOther />
      <span>{name}</span>
      <div className="media-upload-widget-item__actions">
        {state === "complete" ? (
          <div className="media-upload-widget-item__icon media-upload-widget-item__icon--complete">
            <Icons.CompleteIcon />
          </div>
        ) : state === "active" ? (
          <>
            <button
              className="media-upload-widget-item__icon media-upload-widget-item__icon--pause"
              onClick={onPause}
            >
              <Icons.PauseIcon />
            </button>
            <button
              className="media-upload-widget-item__icon media-upload-widget-item__icon--cancel"
              onClick={onCancel}
            >
              <Icons.StopIcon />
            </button>
          </>
        ) : state === "paused" ? (
          <>
            <button
              className="media-upload-widget-item__icon media-upload-widget-item__icon--resume"
              onClick={onResume}
            >
              <Icons.PlayIcon />
            </button>
            <button
              className="media-upload-widget-item__icon media-upload-widget-item__icon--cancel"
              onClick={onCancel}
            >
              <Icons.StopIcon />
            </button>
          </>
        ) : null}
      </div>
    </div>
  </div>
);
