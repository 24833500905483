import * as React from "react";
import cx from "classnames";
import { useDebounce } from "use-debounce";
import { Input, IconButton, Button, Icons } from "pokko-shared";
import { Breadcrumb } from "components/elements/breadcrumb/Breadcrumb";
import {
  MediaItem,
  useGetMediaFolderQuery,
  useListMediaItemsQuery,
} from "api/graphql";
import { useProject } from "routes/accounts/projects";
import { MediaSearchItem } from "./MediaSearchItem";
import { strings } from "strings";
import { FolderSelector } from "./FolderSelector";

export type MediaSearchProps = {
  folderStart?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  onCancel: () => void;
};

export const MediaSearch: React.FC<MediaSearchProps> = ({
  folderStart,
  value,
  onCancel,
  onChange,
}) => {
  const [folder, setFolder] = React.useState(folderStart);
  const [selected, setSelected] = React.useState(value);
  const [search, setSearch] = React.useState("");
  const [folderSelecting, setFolderSelecting] = React.useState(false);
  const [searchDeb] = useDebounce(search, 250);
  const { project } = useProject();
  const query = useListMediaItemsQuery({
    variables: {
      project: project.id,
      folder: searchDeb ? undefined : folder ?? null,
      filter: searchDeb
        ? {
            name: { likeInsensitive: `%${searchDeb}%` },
            deletedAt: { isNull: true },
          }
        : { deletedAt: { isNull: true } },
    },
  });
  const folderQuery = useGetMediaFolderQuery({
    variables: { id: folder },
    skip: !folder,
  });

  const handleSave = () => {
    onChange(selected);
  };

  const handleChangeFolder = (value?: string) => {
    setFolder(value);
    setFolderSelecting(false);
  };

  return (
    <div className="media-search__container">
      <Input
        type="search"
        value={search}
        onChangeText={setSearch}
        placeholder="Search media"
      />
      <div className="media-search__body">
        <div className="media-search__nav">
          {searchDeb ? null : folderQuery.data?.entity ? (
            <Breadcrumb
              size="small"
              path={[
                { label: folderQuery.data.entity.parent?.name ?? "Media" },
                { label: folderQuery.data.entity.name! },
              ]}
            />
          ) : null}
        </div>
        <div
          className={cx("media-search__results", { "--searching": searchDeb })}
        >
          {searchDeb ? null : (
            <div className="media-search__folders">
              <IconButton onClick={() => setFolderSelecting(true)}>
                <Icons.FolderIcon />
              </IconButton>
              {folderSelecting ? (
                <FolderSelector
                  value={folder}
                  onChange={handleChangeFolder}
                  onClose={() => setFolderSelecting(false)}
                />
              ) : null}
            </div>
          )}
          <div className="media-search__items">
            {query.data?.list?.nodes.map((ent) => (
              <MediaSearchItem
                key={ent?.id!}
                item={ent as MediaItem}
                selected={ent?.id === selected}
                onClick={() =>
                  ent?.id === selected
                    ? setSelected(null)
                    : setSelected(ent?.id)
                }
                onDoubleClick={() => onChange(ent?.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="media-search__footer">
        <Button kind="primary" onClick={handleSave}>
          Select media
        </Button>
        <Button onClick={onCancel}>{strings.cancel}</Button>
      </div>
    </div>
  );
};
