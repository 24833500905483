import * as React from "react";
import { Helmet } from "react-helmet";
import {
  EnvironmentInput,
  ProjectSettings,
  PublishTarget,
  PublishTargetInput,
  Website,
  WebsiteInput,
} from "components/components/projects/settings/ProjectSettings";
import { useProject } from "..";
import {
  useBackgroundTaskMutation,
  useCreateEnvironmentMutation,
  useCreateProjectTokenMutation,
  useCreatePublishTargetMutation,
  useCreateWebsiteMutation,
  useDeleteProjectTokenMutation,
  useListProjectTokensQuery,
  useUpdateEnvironmentMutation,
  useUpdateProjectDefaultEnvironmentMutation,
  useUpdateProjectTokenMutation,
  useUpdatePublishTargetMutation,
  useUpdateWebsiteMutation,
} from "api/graphql";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

export const ProjectSettingsPage: React.FC = () => {
  const { project, account, reload } = useProject();
  const tokenQuery = useListProjectTokensQuery({
    variables: {
      project: project.id,
    },
  });

  const [
    updateProjectDefEnv,
    updateProjectDefEnvStatus,
  ] = useUpdateProjectDefaultEnvironmentMutation();
  const [
    updateEnvironment,
    updateEnvironmentStatus,
  ] = useUpdateEnvironmentMutation();
  const [createToken, createTokenStatus] = useCreateProjectTokenMutation();
  const [deleteToken, deleteTokenStatus] = useDeleteProjectTokenMutation();
  const [updateToken, updateTokenStatus] = useUpdateProjectTokenMutation();
  const [
    createEnvironment,
    createEnvironmentStatus,
  ] = useCreateEnvironmentMutation();

  const [createTarget, createTargetStatus] = useCreatePublishTargetMutation();
  const [updateTarget, updateTargetStatus] = useUpdatePublishTargetMutation();
  const [republish, republishStatus] = useBackgroundTaskMutation();
  const [updatePaths, updatePathsStatus] = useBackgroundTaskMutation();
  const [clearCache, clearCacheStatus] = useBackgroundTaskMutation();
  const [createWebsite, createWebsiteStatus] = useCreateWebsiteMutation();
  const [updateWebsite, updateWebsiteStatus] = useUpdateWebsiteMutation();

  const handleCreateToken = async () => {
    await createToken({
      variables: {
        project: project.id,
        target: project.publishTargets.nodes[0]?.id,
      },
    });

    tokenQuery.refetch();
  };

  const handleRevokeToken = async (id: string) => {
    await deleteToken({
      variables: {
        id,
      },
    });

    tokenQuery.refetch();
  };

  const handleUpdateToken = async (id: string, target: string) => {
    await updateToken({
      variables: {
        id,
        patch: {
          targetId: target,
        },
      },
    });

    tokenQuery.refetch();
  };

  const handleSaveEnvironment = async (
    id: string,
    values: EnvironmentInput
  ) => {
    await updateEnvironment({
      variables: {
        id,
        patch: {
          name: values.name,
        },
      },
    });
  };

  const handleDefaultEnvironment = async (id: string) => {
    await updateProjectDefEnv({
      variables: {
        project: project.id,
        environment: id,
      },
    });

    reload();
  };

  const handleArchiveEnvironment = async (id: string) => {
    await updateEnvironment({
      context: {
        source: "archive",
      },
      variables: {
        id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    reload();
  };

  const handleCreateEnvironment = async () => {
    await createEnvironment({
      variables: {
        input: {
          projectId: project.id,
          name: `Environment ${project.projectEnvironments.nodes.length + 1}`,
          alias: `environment${project.projectEnvironments.nodes.length + 1}`,
        },
      },
    });

    reload();
  };

  const handleCreatePublishTarget = async () => {
    await createTarget({
      variables: {
        input: {
          projectId: project.id,
          name: `Target ${project.projectEnvironments.nodes.length + 1}`,
        },
      },
    });

    reload();
  };

  const handleSavePublishTarget = async (
    id: string,
    values: PublishTargetInput
  ) => {
    await updateTarget({
      variables: {
        id,
        project: project.id,
        patch: {
          name: values.name,
        },
      },
    });
  };

  const handleRepublish = async (id: string) => {
    await republish({
      variables: { project: project.id, target: id, task: "republish" },
    });
  };

  const handleUpdatePaths = async (id: string) => {
    await updatePaths({
      variables: { project: project.id, target: id, task: "update_paths" },
    });
  };

  const handleClearCache = async (id: string) => {
    await clearCache({
      variables: { project: project.id, environment: id, task: "clear_cache" },
    });
  };

  const handleCreateWebsite = async (environment: string) => {
    await createWebsite({
      variables: {
        input: {
          environmentId: environment,
          projectId: project.id,
          name: "New website",
        },
      },
    });
  };
  const handleUpdateWebsite = async (id: string, values: WebsiteInput) => {
    await updateWebsite({
      variables: {
        id,
        project: project.id,
        patch: {
          name: values.name,
          targetId: values.target,
          urlFormat: values.urlFormat,
        },
      },
    });
  };

  return (
    <>
      <Helmet title={["Settings", project.name].join(" - ")} />
      <SimpleLayout>
        <ProjectSettings
          project={project.id}
          projectKey={project.key!}
          accountKey={account.key!}
          environment={project.defaultEnvironment?.id}
          name={project.name!}
          archived={Boolean(project.deletedAt)}
          apiTokens={
            tokenQuery.data?.list?.nodes.map((ent) => ({
              id: ent?.id,
              token: ent?.token!,
              target: ent?.targetId!,
              created: new Date(ent?.createdAt + "Z"),
            })) ?? []
          }
          environments={
            project.projectEnvironments.nodes
              .filter((ent) => !Boolean(ent?.deletedAt))
              .map((ent) => ({
                id: ent?.id!,
                key: ent?.key!,
                name: ent?.name!,
                isDefault: project.defaultEnvironment?.id === ent?.id,
                save: [
                  (values) => handleSaveEnvironment(ent?.id, values),
                  updateEnvironmentStatus,
                ],
                makeDefault: [
                  () => handleDefaultEnvironment(ent?.id),
                  updateProjectDefEnvStatus,
                ],
                archive: [
                  () => handleArchiveEnvironment(ent?.id),
                  updateEnvironmentStatus,
                ],
                clearCache: [() => handleClearCache(ent?.id), clearCacheStatus],
                value: {
                  name: ent?.name!,
                },
                createWebsite: [
                  () => handleCreateWebsite(ent?.id!),
                  createWebsiteStatus,
                ],
                websites:
                  ent?.websites.nodes.map(
                    (web) =>
                      ({
                        id: web?.id!,
                        name: web?.name!,
                        environment: ent.key,
                        path: web?.projectEnvironmentTaxonomy?.path?.pathAlias,
                        value: {
                          name: web?.name!,
                          target: web?.targetId,
                          urlFormat: web?.urlFormat,
                        },
                        save: [
                          (values) => handleUpdateWebsite(web?.id, values),
                          updateWebsiteStatus,
                        ],
                      } as Website)
                  ) ?? [],
              })) ?? []
          }
          publishTargets={project.publishTargets.nodes.map(
            (ent) =>
              ({
                id: ent?.id!,
                key: ent?.key!,
                name: ent?.name!,
                save: [
                  (values) => handleSavePublishTarget(ent?.id!, values),
                  updateTargetStatus,
                ],
                value: {
                  name: ent?.name!,
                },
                updatePaths: [
                  () => handleUpdatePaths(ent?.id!),
                  {
                    loading: updatePathsStatus.loading,
                  },
                ],
                republish: [
                  () => handleRepublish(ent?.id!),
                  {
                    loading: republishStatus.loading,
                  },
                ],
              } as PublishTarget)
          )}
          createEnvironment={[handleCreateEnvironment, createEnvironmentStatus]}
          createPublishTarget={[handleCreatePublishTarget, createTargetStatus]}
          createToken={[handleCreateToken, createTokenStatus]}
          revokeToken={[handleRevokeToken, deleteTokenStatus]}
          updateToken={[handleUpdateToken, updateTokenStatus]}
        />
      </SimpleLayout>
    </>
  );
};
