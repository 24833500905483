import * as React from "react";
import cx from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { ButtonProps } from "pokko-shared";
import { Tag } from "components/elements/tag/Tag";
import { DateDisplay } from "components/elements/date/DateDisplay";

export type EntryDetailCardProps = {
  active?: boolean;
  selected?: boolean;
  selectable?: boolean;
  name: string;
  model: string;
  link?: string;
  modified: Date;
  published: boolean;
  onRemove?: () => void;
};

export type EntryDetailCardButtonProps = {
  entry: EntryDetailCardProps;
  button: ButtonProps;
};
export type EntryDetailCardLinkProps = {
  entry: EntryDetailCardProps;
  link: LinkProps;
};

const containerClasses = (entry: EntryDetailCardProps): string =>
  cx("entry-card__container", {
    "--active": entry.active,
    "--selected": entry.selected,
    "--selectable": entry.selectable,
  });

const EntryDetailCard: React.FC<EntryDetailCardProps> = (entry) => (
  <>
    <div className="entry-card__name">
      <strong>
        {entry.link ? <Link to={entry.link}>{entry.name}</Link> : entry.name}
      </strong>
      <Tag>{entry.model}</Tag>
    </div>
    <div className="entry-card__detail">
      <div className="entry-card__meta">
        <DateDisplay label="Last modified" value={entry.modified} />
        <div className="entry-card__published">
          {entry.published ? "Published" : "Not published"}
        </div>
      </div>
      {entry.onRemove ? (
        <div className="entry-card__actions">
          <button onClick={entry.onRemove}>Remove</button>
        </div>
      ) : null}
    </div>
  </>
);

export const EntryDetailCardButton: React.FC<EntryDetailCardButtonProps> = ({
  button,
  entry,
}) => (
  <button className={containerClasses(entry)} type="button" {...button}>
    <EntryDetailCard {...entry} />
  </button>
);

export const EntryDetailCardLink: React.FC<EntryDetailCardLinkProps> = ({
  entry,
  link,
}) => (
  <Link className={containerClasses(entry)} {...link}>
    <EntryDetailCard {...entry} />
  </Link>
);

export const EntryDetailCardDisplay: React.FC<EntryDetailCardProps> = (
  entry
) => (
  <div className={containerClasses(entry)}>
    <EntryDetailCard {...entry} />
  </div>
);
