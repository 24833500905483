import * as React from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { Icons } from "pokko-shared";

// import { Input } from "components/form/input/Input";
import { useEnvironmentOpt } from "routes/accounts/projects/environments";
import { useProject } from "routes/accounts/projects";
import { Project } from "api/graphql";
import { useDetectOutsideClick } from "./hooks";
import { DropdownItem } from "./components/DropdownItem";
import { ProjectItem } from "./components/ProjectItem";
import { WebhooksDropdown } from "./components/WebhooksDropdown";
import { authUrl } from "providers/user";

export const ProjectNavbar: React.FC = () => {
  // TODO: move the logic out of the component
  const { project, account } = useProject();
  const env = useEnvironmentOpt();
  const selectorRef = React.useRef<HTMLDivElement>(null);

  const [selecting, setSelecting] = useDetectOutsideClick(selectorRef, false);

  return (
    <nav className="navbar-project">
      <div className="navbar__container">
        <NavLink
          className="navbar__brand"
          to={`/accounts/${account.key}/projects/${project.key}/environments/${
            env?.environment.key ?? project.defaultEnvironment?.key
          }/entries`}
        >
          <Icons.PokkoSymbol />
        </NavLink>
        <div className="navbar__left">
          <div className="navbar__project-selector" ref={selectorRef}>
            <button
              className={cx("navbar__project-selector-button", {
                "--active": selecting,
              })}
              onClick={() => setSelecting(!selecting)}
            >
              <strong>{project.name}</strong>
              {env?.environment.name ? (
                <span>{env?.environment.name}</span>
              ) : null}
              <Icons.DownIcon />
            </button>
            {selecting ? (
              <div className="navbar__project-selector-dropdown">
                <ul className="navbar__project-selector-projects">
                  {account.projects.nodes
                    .filter((ent) => !ent?.deletedAt)
                    .map((ent) => (
                      <ProjectItem ent={ent as Project} key={ent?.id} />
                    ))}
                </ul>
              </div>
            ) : null}
          </div>
          <DropdownItem label="Content">
            <ul className="navbar__dropdown-links">
              <li>
                <NavLink
                  to={`/accounts/${account.key}/projects/${
                    project.key
                  }/environments/${
                    env?.environment.key ?? project.defaultEnvironment?.key
                  }/entries`}
                >
                  Entries
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/accounts/${account.key}/projects/${
                    project.key
                  }/environments/${
                    env?.environment.key ?? project.defaultEnvironment?.key
                  }/taxonomy`}
                >
                  Structure
                </NavLink>
              </li>
              <li className="navbar__dropdown-separator" />
              <li>
                <NavLink
                  to={`/accounts/${account.key}/projects/${
                    project.key
                  }/environments/${
                    env?.environment.key ?? project.defaultEnvironment?.key
                  }/models`}
                >
                  Models
                </NavLink>
              </li>
            </ul>
          </DropdownItem>
          <NavLink
            to={`/accounts/${account.key}/projects/${project.key}/media`}
            className="navbar__item"
          >
            Media
          </NavLink>
          <DropdownItem
            label="Webhooks"
            link={`/accounts/${account.key}/projects/${project.key}/webhooks`}
          >
            <WebhooksDropdown />
          </DropdownItem>
        </div>
        <div className="navbar__right">
          <a
            href="https://docs.pokko.io/"
            className="navbar__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            Documentation
          </a>
          {/* <div className="navbar__search">
            <Input type="search" placeholder="Search (/)" />
          </div> */}
          <DropdownItem
            right
            hideArrow
            label={
              <div className="navbar__profile">
                <Icons.ProfileIcon />
              </div>
            }
          >
            <ul className="navbar__dropdown-links">
              <li>
                <a
                  href={`/${project.id}/${
                    env?.environment.id ?? project.defaultEnvironment?.id
                  }/graphql`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Query playground
                </a>
              </li>
              <li className="navbar__dropdown-separator" />
              <li>
                <NavLink
                  to={`/accounts/${account.key}/projects/${project.key}/settings`}
                >
                  Project settings
                </NavLink>
              </li>
              <li>
                <a
                  href={`${authUrl}/accounts/${account.key}/settings`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Account settings
                </a>
              </li>
              <li className="navbar__dropdown-separator" />
              <li>
                <a href={`${authUrl}/accounts`}>Switch account</a>
              </li>
              <li className="navbar__dropdown-separator" />
              <li>
                <NavLink to={`/logout`}>Logout</NavLink>
              </li>
            </ul>
          </DropdownItem>
        </div>
      </div>
    </nav>
  );
};
