import * as React from "react";
import { EnvironmentExport } from "components/components/environments/export/EnvironmentExport";
import { saveAs } from "utils/FileSaver";
import { useEnvironment } from "..";

export const EnvExportPage: React.FC = () => {
  const { models, project, environment } = useEnvironment();

  const handleSave = (payload: any) => {
    const blob = new Blob([JSON.stringify(payload)]);

    const now = new Date();
    const date = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join(
      "-"
    );

    saveAs(blob, `${project.name}_${environment.name}_${date}_export.json`);
  };

  return <EnvironmentExport models={models} onSave={handleSave} />;
};
