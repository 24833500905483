import * as React from "react";
import {
  useGetTaxonomyQuery,
  useUpdateModelMutation,
  useUpdateTaxonomyMutation,
  useUpdateWebsiteMutation,
  Website,
} from "api/graphql";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useEnvironment } from "../..";
import {
  TaxonomyEditor,
  TaxonomyInput,
} from "components/components/taxonomy/editor/TaxonomyEditor";
import { TaxonomyLevelInput } from "components/components/taxonomy/editor/TaxonomyLevelEditor";

export const TaxonomyEditorController: React.FC = () => {
  const { project, environment, models } = useEnvironment();
  const { node } = useParams();
  const navigate = useNavigate();
  const query = useGetTaxonomyQuery({
    fetchPolicy: "no-cache",
    variables: {
      id: node,
      environment: environment.id,
      project: project.id,
    },
  });
  const [update, updateStatus] = useUpdateTaxonomyMutation();
  const [updateModel] = useUpdateModelMutation();
  const [updateWebsite] = useUpdateWebsiteMutation();

  const handleDelete = async () => {
    await update({
      variables: {
        id: node,
        environment: environment.id,
        project: project.id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    navigate("..");
  };

  const handleSave = async (values: TaxonomyInput) => {
    const currentWebsites =
      query.data?.entity?.websitesByProjectIdAndEnvironmentIdAndTaxonomyId
        .nodes ?? [];

    const websitesCreate =
      values.websites.filter(
        (x) => !currentWebsites.find((y) => x.id === y?.id)
      ) ?? [];
    const websitesDelete =
      currentWebsites.filter(
        (x) => !values.websites.find((y) => x?.id === y.id)
      ) ?? [];

    await Promise.all(
      websitesCreate.map((x) =>
        updateWebsite({
          variables: {
            id: x.id,
            project: project.id,
            patch: { taxonomyId: node },
          },
        })
      )
    );

    await Promise.all(
      websitesDelete.map((x) =>
        updateWebsite({
          variables: {
            id: x!.id,
            project: project.id,
            patch: { taxonomyId: null },
          },
        })
      )
    );

    const levelsCreate = values.levels?.filter((ent) => !ent.levelId) ?? [];
    const levelsUpdate =
      values.levels?.filter((ent) =>
        Boolean(
          query.data?.entity?.levels.nodes.find(
            (x) => x?.levelId === ent.levelId
          )
        )
      ) ?? [];
    const levelsDelete =
      query.data?.entity?.levels.nodes.filter(
        (ent) =>
          ent?.levelId &&
          !Boolean(values.levels?.find((x) => x?.levelId === ent.levelId))
      ) ?? [];

    await update({
      variables: {
        id: node,
        environment: environment.id,
        project: project.id,
        patch: {
          alias: values.alias,
          entryId: values.entry,
          type: values.type,
          websitesUsingProjectIdAndEnvironmentIdAndId: {
            updateByProjectIdAndId:
              websitesDelete.length + websitesCreate.length === 0
                ? undefined
                : websitesCreate.map((ent) => ({
                    id: ent.id,
                    projectId: project.id,
                    patch: {
                      taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId: {
                        connectByIdAndProjectIdAndEnvironmentId: {
                          projectId: project.id,
                          environmentId: environment.id,
                          id: node,
                        },
                      },
                    },
                  })),
          },
          levels: {
            create:
              levelsCreate.length === 0
                ? undefined
                : levelsCreate.map((ent) => ({
                    aliasFieldId: ent.aliasField || null,
                    fragmentFieldId: ent.fragmentType
                      ? ent.fragmentField || null
                      : null,
                    fragmentType: ent.fragmentType,
                    isNode: ent.isNode,
                    inherit: ent.inherit,
                    modelId: ent.model,
                  })),
            updateByIdAndProjectIdAndEnvironmentId:
              levelsUpdate.length === 0
                ? undefined
                : levelsUpdate.map((ent) => ({
                    id: ent.levelId,
                    environmentId: environment.id,
                    projectId: project.id,
                    patch: {
                      aliasFieldId: ent.aliasField || null,
                      fragmentFieldId: ent.fragmentType
                        ? ent.fragmentField || null
                        : null,
                      fragmentType: ent.fragmentType,
                      isNode: ent.isNode,
                      inherit: ent.inherit,
                      modelId: ent.model,
                    },
                  })),
            deleteByIdAndProjectIdAndEnvironmentId:
              levelsDelete.length === 0
                ? undefined
                : levelsDelete.map((ent) => ({
                    id: ent!.levelId,
                    environmentId: environment.id,
                    projectId: project.id,
                  })),
          },
        },
      },
    });

    navigate("..");
  };

  const handleModelDefault = async (taxonomyLevel: string, model: string) => {
    await updateModel({
      variables: {
        id: model,
        project: project.id,
        environment: environment.id,
        patch: {
          defaultTaxonomyLevelId: taxonomyLevel,
        },
      },
    });
  };

  if (!query.data?.entity) {
    return null;
  }

  const { entity } = query.data;

  if (entity.deletedAt) {
    return <Navigate to=".." />;
  }

  return (
    <TaxonomyEditor
      path={(entity.path?.pathAlias as string[]) || []}
      models={models.filter((ent) => !Boolean(ent.deletedAt))}
      websites={environment.websites.nodes as Website[]}
      value={{
        alias: entity.alias!,
        type: entity.type!,
        entry: entity.entry!,
        levels: entity.levels.nodes as TaxonomyLevelInput[],
        websites:
          entity.websitesByProjectIdAndEnvironmentIdAndTaxonomyId.nodes.map(
            (ent) => ({
              id: ent?.id!,
              name: ent?.name!,
            })
          ) ?? [],
      }}
      save={[handleSave, updateStatus]}
      delete={[handleDelete, updateStatus]}
      onModelDefault={handleModelDefault}
    />
  );
};
