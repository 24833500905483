import * as React from "react";
import { Icons, LinkButton } from "pokko-shared";
import { TreeNode, useEntryMenuTree } from "./tree";
import cx from "classnames";
import {
  TreeNodeState,
  TreeHook,
} from "components/components/taxonomy/tree/types";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
  DragMoveEvent,
  DragOverEvent,
  Modifier,
  MeasuringStrategy,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMoveMenuItemMutation } from "api/graphql";
import { NavLink } from "react-router-dom";
import { useEnvironment } from "routes/accounts/projects/environments";

export const EntryMenuSettings: React.FC = () => {
  const { project, environment } = useEnvironment();
  const tree = useEntryMenuTree();
  const [update, updateStatus] = useMoveMenuItemMutation();

  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = React.useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = React.useState(0);

  const nodesFlat = React.useMemo(
    () => removeChildrenOf(tree.nodesFlat, activeId ? [activeId] : []),
    [activeId, tree.nodesFlat]
  );

  const projection =
    activeId && overId
      ? getProjection(nodesFlat, activeId, overId, offsetLeft, 16 /* 1rem */)
      : null;

  const handleDragStart = (event: DragStartEvent) => {
    const activeId = event.active.id;

    setActiveId(activeId);
    setOverId(activeId);

    document.body.style.setProperty("cursor", "grabbing");
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    resetState();

    const activeNode = event.active.id
      ? tree.state.nodes2.find((ent) => ent.id === event.active.id)
      : null;

    if (!activeNode) {
      return;
    }

    const nextNode = projection?.next
      ? tree.state.nodes2.find((ent) => ent.id === projection.next)
      : null;
    const parentNode = projection?.parent
      ? tree.state.nodes2.find((ent) => ent.id === projection?.parent)
      : null;

    const nextNodeId =
      nextNode && nextNode.parent === (parentNode?.id ?? null)
        ? nextNode.id
        : null;

    await update({
      variables: {
        environment: environment.id,
        project: project.id,
        id: activeNode.id,
        before: nextNodeId,
        parent: projection?.parent,
      },
    });

    // update tree state
    tree.nodeUpdated(activeNode, {
      ...activeNode.data,
      index: nextNode?.data.index ?? 0,
    });

    tree.nodeMoved(activeNode.id, projection?.parent ?? null);

    console.log(
      `move ${activeNode?.id} under ${parentNode?.id} before ${nextNodeId}`
    );
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty("cursor", "");
  };

  const handleDragCancel = resetState;

  const sensors = useSensors(useSensor(PointerSensor));

  return (
    <div className="entries-settings__container">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        modifiers={[adjustTranslate]}
        cancelDrop={() => updateStatus.loading}
      >
        <SortableContext
          items={nodesFlat}
          strategy={verticalListSortingStrategy}
        >
          {nodesFlat.map((nod) => (
            <Node
              key={nod.id}
              active={activeId}
              node={nod}
              over={overId}
              projection={projection}
              tree={tree}
            />
          ))}
          {/* TODO: need to bring back the parent node logic of loading more, REF: `renderChildren` */}
        </SortableContext>
        <DragOverlay>
          <div />
          {/* TODO: without this here the Node becomes freely draggable, with this here it snaps to position */}
        </DragOverlay>
      </DndContext>
      <hr />
      <LinkButton kind="primary" to="../create">
        New menu item
      </LinkButton>
    </div>
  );
};

// const renderChildren = (
//   parent: string | null,
//   tree: TreeHook<TreeNode>,
//   active: string | null,
//   over: string | null,
//   projection: Projection | null
// ): React.ReactElement | null => {
//   const parentNode = parent
//     ? tree.state.nodes2.find((ent) => ent.id === parent)
//     : null;
//   const nodes = tree.state.nodes2.filter((ent) => ent.parent === parent);

//   const indent: React.CSSProperties = {
//     marginLeft: `${parentNode?.level ?? 0}rem`,
//   };

//   return !parentNode || parentNode.expanded ? (
//     <>
//       {nodes.map((nod, idx) => (
//         <Node
//           key={idx}
//           node={nod}
//           tree={tree}
//           active={active}
//           over={over}
//           projection={projection}
//         />
//       ))}

//       {parentNode?.hasMore || parentNode?.loading ? (
//         <button
//           style={indent}
//           onClick={() => tree.loadMore(parent)}
//           disabled={parentNode.loading}
//           className={cx("entries-settings__more", {
//             "--loading": parentNode.loading,
//           })}
//         >
//           {parentNode.loading ? (
//             <span>Loading...</span>
//           ) : (
//             <>
//               <span>Load more</span>
//               <Icons.ContentIcon />
//               <small>
//                 Showing {nodes.length} of {parentNode.childCount}
//               </small>
//             </>
//           )}
//         </button>
//       ) : null}
//     </>
//   ) : null;
// };

const Node: React.FC<{
  node: TreeNodeState<TreeNode>;
  tree: TreeHook<TreeNode>;
  active: UniqueIdentifier | null;
  over: UniqueIdentifier | null;
  projection: Projection | null;
}> = ({ node, tree, active, projection }) => {
  const sortable = useSortable({ id: node.id });

  const indent: React.CSSProperties = {
    marginLeft: `${
      node.id === active && projection ? projection.level : node.level ?? 0
    }rem`,
  };

  const style: React.CSSProperties = {
    ...indent,
    transform: CSS.Transform.toString(sortable.transform),
    transition: sortable.transition || undefined,
  };

  const isActive = active === node.id;

  return (
    <>
      <NodeRaw
        ref={sortable.setNodeRef}
        style={style}
        type={node.data.type}
        expandable={!isActive && node.childCount > 0}
        expanded={node.expanded}
        handleProps={{ ...sortable.attributes, ...sortable.listeners }}
        onToggle={() => tree.toggle(node.id)}
        label={node.data.label}
        id={node.id}
      />
    </>
  );
};

const NodeRaw = React.forwardRef<
  HTMLDivElement,
  {
    style?: React.CSSProperties;
    type: string;
    expandable: boolean;
    expanded: boolean;
    ghost?: boolean;
    clone?: boolean;
    handleProps?: any;
    label: string;
    id?: string;
    onToggle?: () => void;
  }
>((props, ref) => {
  const {
    style,
    type,
    expandable,
    expanded,
    handleProps,
    label,
    onToggle,
    ghost,
    clone,
    id,
  } = props;
  return (
    <div
      ref={ref}
      style={style}
      className={cx("entries-settings-item__body", `--${type}`, {
        "--expandable": expandable,
        "--ghost": ghost,
        "--clone": clone,
      })}
    >
      <button className="entries-settings-item__handle" {...handleProps}>
        <Icons.MoveIcon />
      </button>

      {type === "entry" ? (
        <Icons.MediaOther />
      ) : type === "view" ? (
        <Icons.FolderUncategorisedIcon />
      ) : expanded ? (
        <Icons.FolderOpenIcon />
      ) : (
        <Icons.FolderIcon />
      )}

      {expandable ? (
        <button onClick={onToggle}>
          {expanded ? <Icons.DownIcon /> : <Icons.NextIcon />}
        </button>
      ) : null}

      {id ? <NavLink to={id}>{label}</NavLink> : <span>{label}</span>}
    </div>
  );
});

function getDragLevel(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

type Projection = {
  level: number;
  maxLevel: number;
  minLevel: number;
  parent: string | null;
  next: string | null;
  prev: string | null;
};
const getProjection = (
  items: TreeNodeState<unknown>[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number
): Projection => {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragLevel = getDragLevel(dragOffset, indentationWidth);
  const projectedLevel = activeItem.level + dragLevel;
  const maxLevel = getMaxLevel({
    previousItem,
  });
  const minLevel = getMinLevel({ nextItem });
  let level = projectedLevel;

  if (projectedLevel >= maxLevel) {
    level = maxLevel;
  } else if (projectedLevel < minLevel) {
    level = minLevel;
  }

  return {
    level,
    maxLevel,
    minLevel,
    parent: getParent(),
    next: nextItem?.id,
    prev: previousItem?.id,
  };

  function getParent() {
    if (level === 0 || !previousItem) {
      return null;
    }

    if (level === previousItem.level) {
      return previousItem.parent;
    }

    if (level > previousItem.level) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.level === level)?.parent;

    return newParent ?? null;
  }
};

function getMaxLevel({
  previousItem,
}: {
  previousItem: TreeNodeState<unknown>;
}) {
  if (previousItem) {
    return previousItem.level + 1;
  }

  return 0;
}

function getMinLevel({ nextItem }: { nextItem: TreeNodeState<unknown> }) {
  if (nextItem) {
    return nextItem.level;
  }

  return 0;
}

function removeChildrenOf(
  items: TreeNodeState<TreeNode>[],
  ids: UniqueIdentifier[]
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parent && excludeParentIds.includes(item.parent)) {
      return false;
    }

    return true;
  });
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y,
  };
};
