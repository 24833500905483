import * as React from "react";
import { SelectField } from "pokko-shared";
import { FieldConfigProps } from "components/components/fields/types";
import { useListMediaFoldersQuery } from "api/graphql";
import { Config } from ".";
import { useProject } from "routes/accounts/projects";

export const MediaConfig: React.FC<FieldConfigProps<Config>> = ({
  config,
  onChange,
}) => {
  const { project } = useProject();
  const query = useListMediaFoldersQuery({
    variables: { project: project.id },
  });
  return (
    <div className="field__media-editor">
      <SelectField
        label="Starting folder"
        value={config?.startingFolder ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
          onChange({
            ...config,
            startingFolder: ev.currentTarget.value || null,
          })
        }
      >
        <option>Uncategorised</option>
        {query.data?.list?.nodes.map((ent) => (
          <option key={ent?.id} value={ent?.id}>
            {ent?.name}
          </option>
        ))}
      </SelectField>
    </div>
  );
};
