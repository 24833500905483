import * as React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router";
import { useEnvironment } from "..";
import { TaxonomyEditorController } from "./components/TaxonomyEditorController";
import { TaxonomyTreeController } from "./components/TaxonomyTreeController";

export const EnvTaxonomy: React.FC = () => {
  const { project, environment } = useEnvironment();
  return (
    <>
      <Helmet
        title={["Structure", environment.name, project.name].join(" - ")}
      />
      <Routes>
        <Route path="" element={<TaxonomyTreeController />} />
        <Route path=":node/*" element={<TaxonomyEditorController />} />
      </Routes>
    </>
  );
};
