import * as React from "react";
import type { Reducer } from "react";
import {
  TreeState,
  TreeHookOptions,
  TreeHook,
  TreeDispatch,
  LoadChildrenRequest,
  TreeStateInitialiser,
  TreeNodeState,
  TreeNodeBase,
  TreeAction,
} from "./types";
// import { useLocation } from "react-router";
import { reducer } from "./reducer";

const loadChildrenInner = async <TNode>(
  dispatch: TreeDispatch<TNode>,
  loadChildren: LoadChildrenRequest<TNode>,
  id: string | null,
  skip: number,
  level: number
) => {
  dispatch({ type: "load_children", id, level });
  try {
    const result = await loadChildren(id, skip);
    dispatch({ type: "load_children_success", id, result });
  } catch (error) {
    dispatch({ type: "load_children_error", id, error });
  }
};

const initialiser = <TNode>(): TreeState<TNode> => {
  const fallbackState: TreeState<TNode> = {
    nodes2: [],
  };

  return fallbackState;
};

export const useTree = <TNode>(
  options: TreeHookOptions<TNode>
): TreeHook<TNode> => {
  const { loadChildren } = options;

  // const { search } = useLocation();
  // const qs = new URLSearchParams(search);

  const initialiserArg: TreeStateInitialiser = {};
  // const initialPath = qs.get("path");

  const [state, dispatch] = React.useReducer<
    Reducer<TreeState<TNode>, TreeAction<TNode>>,
    TreeStateInitialiser
  >(reducer, initialiserArg, initialiser);

  const reset = React.useCallback(() => {
    dispatch({ type: "reset", nodes2: [] });
    loadChildrenInner(dispatch, loadChildren, null, 0, 0);
  }, [loadChildren, dispatch]);

  React.useEffect(() => {
    (async () => {
      // if (initialPath) {
      //   // TODO: move this out of hook to consumer
      //   const segments = initialPath.split("/");
      //   const nodes = await Promise.all(
      //     segments.map(async (_, idx, arr) => {
      //       const path = arr.slice(0, idx);
      //       const result = await loadChildren(path.join("."), 0);
      //       return {
      //         key: path.join("."),
      //         path,
      //         result,
      //       };
      //     })
      //   );

      //   dispatch({
      //     type: "reset",
      //     nodes2: [],
      //     // nodes2: nodes.map(
      //     //   (ent) =>
      //     //     ({
      //     //       expanded: true,
      //     //       level: ent.path.length,
      //     //       hasMore: false,
      //     //       childCount: ent.result.nodes.length,
      //     //       ...ent.result,
      //     //     } as TreeNodeState<TNode>)
      //     // ),
      //   });
      // } else {
      reset();
      // }
    })();
  }, [reset]);

  const toggle = async (id: string) => {
    const node = state.nodes2.find((ent) => ent.id === id)!;

    if (node.expanded) {
      dispatch({ type: "collapse", id });
    } else {
      if (node.loaded) {
        dispatch({ type: "expand", id });
      } else {
        loadChildrenInner(dispatch, loadChildren, id, 0, node.level + 1);
      }
    }
  };

  const loadMore = async (id: string | null) => {
    const node = state.nodes2.find((ent) => ent.id === id)!;
    const loadedChildren = state.nodes2.filter((ent) => ent.parent === id);

    loadChildrenInner(
      dispatch,
      loadChildren,
      id,
      loadedChildren.length,
      node.level
    );
  };

  const deleteNode = async (node: TreeNodeState<TNode>) => {
    if (options.deleteNode) {
      await options.deleteNode(node);
      dispatch({ type: "node_deleted", id: node.id });
    }
  };
  const createChild = React.useCallback(
    async (parent: TreeNodeState<TNode> | null, alias: string) => {
      if (options.createNode) {
        const node = await options.createNode(parent, alias);
        dispatch({
          type: "node_created",
          parent: parent ? parent.id : null,
          id: node.id,
          node,
        });
      }
    },
    [options]
  );

  const nodeUpdated = (node: TreeNodeState<TNode>, data: TNode) =>
    dispatch({ type: "node_updated", id: node.id, data });
  const nodeDeleted = (node: TreeNodeState<TNode>) =>
    dispatch({ type: "node_deleted", id: node.id });
  const nodeCreated = (
    parent: TreeNodeState<TNode> | null,
    node: TreeNodeBase<TNode>
  ) =>
    dispatch({
      type: "node_created",
      parent: parent ? parent.id : null,
      id: node.id,
      node,
    });
  const nodeMoved = (id: string, parent: string | null) =>
    dispatch({
      type: "node_moved",
      parent,
      id,
    });

  const orderUpdated = (parent: string | null) =>
    options.nodeOrderComparer &&
    dispatch({
      type: "order_updated",
      parent,
      comparer: options.nodeOrderComparer,
    });

  const nodesFlat = flatten(null, state.nodes2);

  return {
    state: state as TreeState<TNode>,
    toggle,
    loadMore,
    nodeMoved,
    nodeUpdated,
    nodeDeleted,
    nodeCreated,
    deleteNode,
    createChild,
    orderUpdated,
    nodesFlat,
    reset,
  };
};

const flatten = <TNode>(
  parent: string | null,
  source: TreeNodeState<TNode>[]
): TreeNodeState<TNode>[] => {
  return source
    .filter((ent) => ent.parent === parent)
    .map((ent) => [ent, ...(ent.expanded ? flatten(ent.id, source) : [])])
    .reduce((p, c) => [...p, ...c], []);
};
