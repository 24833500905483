import * as React from "react";
import { Link } from "react-router-dom";
import { InputField, LinkButton, Icons } from "pokko-shared";
import { useEnvironment } from "..";
import { useListChangesQuery } from "api/graphql";

const date = (input?: string | null): React.ReactNode =>
  input
    ? new Date(input + "Z").toLocaleString(navigator.language, {
        hour12: true,
        hour: "2-digit",
        minute: "numeric",
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : null;

export const EnvChangeLog: React.FC = () => {
  const { environment, project } = useEnvironment();

  const [dateFrom, setDateFrom] = React.useState(new Date().toISOString());
  const [dateTo, setDateTo] = React.useState(new Date().toISOString());

  const query = useListChangesQuery({
    variables: {
      environment: environment.id,
      project: project.id,
      dateFrom,
      dateTo,
    },
  });

  return (
    <>
      <LinkButton
        small
        kind="tertiary"
        to=".."
        icons={{ left: <Icons.BackIcon /> }}
      >
        Back to environment
      </LinkButton>
      <h3 className="h3">Change log</h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "1rem",
        }}
      >
        <InputField
          label="Date from"
          type="datetime"
          value={dateFrom}
          onChangeText={setDateFrom}
        />

        <InputField
          label="Date to"
          type="datetime"
          value={dateTo}
          onChangeText={setDateTo}
        />
      </div>
      <hr />
      <span className="header">
        Models (showing {query.data?.models?.nodes.length} of{" "}
        {query.data?.models?.totalCount})
      </span>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Deleted</th>
          </tr>
        </thead>
        <tbody>
          {query.data?.models?.nodes.map((ent) => (
            <tr key={ent?.id}>
              <td>
                <Link to={`../models/${ent?.id}`}>{ent?.name}</Link>
              </td>
              <td>{date(ent?.createdAt)}</td>
              <td>{date(ent?.modifiedAt)}</td>
              <td>{date(ent?.deletedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <span className="header">
        Model fields (showing {query.data?.modelFields?.nodes.length} of{" "}
        {query.data?.modelFields?.totalCount})
      </span>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Model</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Deleted</th>
          </tr>
        </thead>
        <tbody>
          {query.data?.modelFields?.nodes.map((ent) => (
            <tr key={ent?.id}>
              <td>
                <Link to={`../models/${ent?.model?.id}/fields/${ent?.id}`}>
                  {ent?.name}
                </Link>
              </td>
              <td>
                <Link to={`../models/${ent?.model?.id}`}>
                  {ent?.model?.name}
                </Link>
              </td>
              <td>{date(ent?.createdAt)}</td>
              <td>{date(ent?.modifiedAt)}</td>
              <td>{date(ent?.deletedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <span className="header">
        Entries (showing {query.data?.entries?.nodes.length} of{" "}
        {query.data?.entries?.totalCount})
      </span>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Deleted</th>
          </tr>
        </thead>
        <tbody>
          {query.data?.entries?.nodes.map((ent) => (
            <tr key={ent?.id}>
              <td>
                <Link to={`../entries/${ent?.id}`}>{ent?.name}</Link>
              </td>
              <td>{date(ent?.createdAt)}</td>
              <td>{date(ent?.modifiedAt)}</td>
              <td>{date(ent?.deletedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
