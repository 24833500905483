import * as React from "react";
import { Route, Routes } from "react-router";
import { authUrl } from "providers/user";
import { AccountPage } from "./accounts";
import { Components } from "./dev";

const Logout: React.FC = () => {
  window.location.href = `${authUrl}/logout`;

  return null;
};

const Fallback: React.FC = () => {
  window.location.href = authUrl;

  return null;
};

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="accounts/:account/*" element={<AccountPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/dev" element={<Components />} />
      <Route path="*" element={<Fallback />} />
    </Routes>
  );
};
