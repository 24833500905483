import * as React from "react";
import { Input } from "pokko-shared";
import { FieldInputProps } from "../../types";

export const Single: React.FC<FieldInputProps> = ({
  disabled,
  error,
  help,
  readOnly,
  value,
  onChange,
}) => (
  <>
    <Input
      value={value.valueScalar?.text ?? ""}
      onChangeText={(text: string) =>
        onChange({
          ...value,
          valueScalar: { text },
        })
      }
      disabled={disabled}
      readOnly={readOnly}
      help={help}
      state={error ? "danger" : undefined}
    />
  </>
);
