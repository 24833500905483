import * as React from "react";
import { LinkButton } from "pokko-shared";
import { WebhookLogLevel } from "api/graphql";
import { DateDisplay } from "components/elements/date/DateDisplay";
import { Tag } from "components/elements/tag/Tag";
import { strings } from "strings";

export type WebhookRunLog = {
  id: string;
  level: WebhookLogLevel;
  payload: string | null;
  created: Date;
};

export type WebhookRunProps = {
  name: string;
  log: WebhookRunLog[];
};

export const WebhookRun: React.FC<WebhookRunProps> = ({ name, log }) => (
  <div className="webhook-run__container">
    <div className="webhook-run__header">
      <h2 className="h2">{name}</h2>
      <div className="webhook-run__header-actions">
        <LinkButton to="../..">Back</LinkButton>
      </div>
    </div>
    {log.length > 0 ? (
      <div className="webhook-run__log">
        {log.map((ent) => (
          <div key={ent.id} className="webhook-run__log-item">
            <div className="webhook-run__log-item-header">
              <DateDisplay value={ent.created} />
              <Tag>{strings.webhooks.log.level[ent.level]}</Tag>
            </div>
            <pre>{ent.payload}</pre>
          </div>
        ))}
      </div>
    ) : (
      <p>There have been no logs written for this webhook yet.</p>
    )}
  </div>
);
