import * as React from "react";
import { useDebounce } from "use-debounce";
import {
  ModelUsage,
  useListModelsQuery,
  useWatchModelsSubscription,
} from "api/graphql";
import { useEnvironment } from "../..";
import { ModelList } from "components/components/models/list/ModelList";
import { useLocation } from "react-router";

export type ModelListingModuleProps = {};

export const ModelListingModule: React.FC<ModelListingModuleProps> = () => {
  const [search, setSearch] = React.useState("");

  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const defaultUsage = qs.get("usage") as ModelUsage;

  const [usage, setUsage] = React.useState<ModelUsage | null>(
    defaultUsage || null
  );
  // const [showDeleted, setShowDeleted] = React.useState(false);

  const showDeleted = false;

  const [searchDeb] = useDebounce(search, 250);

  const { project, environment } = useEnvironment();
  const query = useListModelsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      project: project.id,
      environment: environment.id,
      filter: {
        ...(usage ? { usage: { equalTo: usage } } : null),
        name: {
          likeInsensitive: `%${searchDeb}%`,
        },
        deletedAt: showDeleted
          ? {}
          : {
              isNull: true,
            },
      },
    },
  });

  useWatchModelsSubscription({
    variables: {
      project: project.id,
    },
    onSubscriptionData: () => query.refetch(),
  });

  return (
    <ModelList
      createUrl={usage ? `create?usage=${usage}` : `create`}
      models={
        query.data?.list?.nodes.map((ent) => ({
          id: ent?.id!,
          name: ent?.name!,
          alias: ent?.alias!,
          url: ent?.alias!,
          usage: ent?.usage!,
          deleted: Boolean(ent?.deletedAt),
        })) ?? []
      }
      search={{
        value: search,
        onChange: setSearch,
      }}
      usage={{
        value: usage,
        onChange: setUsage,
      }}
      // showDeleted={{
      //   value: showDeleted,
      //   onChange: setShowDeleted,
      // }}
    />
  );
};
