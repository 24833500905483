var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import cx from "classnames";
import { Input } from "../input/Input";
import { Label } from "../label/Label";
import { Select } from "../select/Select";
import { TextArea } from "../input/TextArea";
import { Message } from "../../elements/message/Message";
export var Field = function (_a) {
    var children = _a.children;
    return (_jsx("div", { className: "field", children: children }));
};
export var Control = function (_a) {
    var children = _a.children, iconRight = _a.iconRight, iconLeft = _a.iconLeft;
    return (_jsxs("div", { className: cx("control", {
            "has-icon-right": iconRight,
            "has-icon-left": iconLeft,
        }), children: [iconLeft ? _jsx("span", { className: "icon is-left", children: iconLeft }) : null, children, iconRight ? _jsx("span", { className: "icon is-right", children: iconRight }) : null] }));
};
export var InputField = React.forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, rest = __rest(_a, ["label", "error"]);
    return (_jsxs(Field, { children: [label ? _jsx(Label, { children: label }) : null, _jsx(Input, __assign({ ref: ref }, rest, { state: error ? "danger" : undefined })), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(Message, { size: "small", colour: "danger", children: error.message })) : null] }));
});
export var SelectField = React.forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, rest = __rest(_a, ["label", "error"]);
    return (_jsxs(Field, { children: [label ? _jsx(Label, { children: label }) : null, _jsx(Select, __assign({ ref: ref }, rest, { state: error ? "danger" : undefined })), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(Message, { size: "small", colour: "danger", children: error.message })) : null] }));
});
export var TextAreaField = function (_a) {
    var label = _a.label, error = _a.error, rest = __rest(_a, ["label", "error"]);
    return (_jsxs(Field, { children: [label ? _jsx(Label, { children: label }) : null, _jsx(TextArea, __assign({}, rest, { state: error ? "danger" : undefined })), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(Message, { size: "small", colour: "danger", children: error.message })) : null] }));
};
export var CheckboxField = React.forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, error = _a.error, rest = __rest(_a, ["id", "label", "error"]);
    return (_jsxs(Field, { children: [_jsx("input", __assign({ className: "checkbox", type: "checkbox", id: "cb-".concat(id), ref: ref }, rest)), _jsx("label", { htmlFor: "cb-".concat(id), children: label }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(Message, { size: "small", colour: "danger", children: error.message })) : null] }));
});
