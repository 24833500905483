import * as React from "react";
import cx from "classnames";
import { Button, Icons, LinkButton } from "pokko-shared";
import {
  RenderChildren,
  TreeHook,
  TreeNodeState,
} from "components/components/taxonomy/tree/types";
import { Link, NavLink, useLocation } from "react-router-dom";
import { TreeNode, useEntryMenuTree } from "./tree";
import { useWatchMenuSubscription } from "api/graphql";
import { useProject } from "routes/accounts/projects";

export type EntryMenuProps = {};

export const EntryMenu: React.FC<EntryMenuProps> = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [changePending, setChangePending] = React.useState(false);
  const { project } = useProject();

  useWatchMenuSubscription({
    variables: { project: project.id },
    onSubscriptionData: () => setChangePending(true),
  });

  const tree = useEntryMenuTree();

  const handleReset = () => {
    tree.reset();
    setChangePending(false);
  };

  const renderChildren = (parent: string | null): React.ReactElement | null => {
    const parentNode = parent
      ? tree.state.nodes2.find((ent) => ent.id === parent)
      : null;
    const nodes = tree.state.nodes2.filter((ent) => ent.parent === parent);

    const indent: React.CSSProperties = {
      marginLeft: `${parentNode?.level ?? 0}rem`,
      marginRight: `-${parentNode?.level ?? 0}rem`,
    };

    return !parentNode || parentNode.expanded ? (
      <>
        {nodes.map((nod, idx) => (
          <Node
            key={idx}
            node={nod}
            renderChildren={renderChildren}
            tree={tree}
          />
        ))}

        {parentNode?.hasMore || parentNode?.loading ? (
          <button
            style={indent}
            onClick={() => tree.loadMore(parent)}
            disabled={parentNode.loading}
            className={cx("entries-tree__more", {
              "--loading": parentNode.loading,
            })}
          >
            {parentNode.loading ? (
              <span>Loading...</span>
            ) : (
              <>
                <span>Load more</span>
                <Icons.ContentIcon />
                <small>
                  Showing {nodes.length} of {parentNode.childCount}
                </small>
              </>
            )}
          </button>
        ) : null}
      </>
    ) : null;
  };

  return (
    <>
      <aside
        className={cx("entries-tree__container", { "--collapsed": collapsed })}
      >
        <div className="entries-tree__body">{renderChildren(null)}</div>
        <div className="entries-tree__footer">
          {changePending ? <Button onClick={handleReset}>Reload</Button> : null}
          <LinkButton to="menu/settings" kind="tertiary">
            Configure menu
          </LinkButton>
        </div>
      </aside>
      <button
        className={cx("entries-collapse", { "--collapsed": collapsed })}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <ExpandIcon />
        <CollapseIcon />
      </button>
    </>
  );
};

const Node: React.FC<{
  node: TreeNodeState<TreeNode>;
  renderChildren: RenderChildren;
  tree: TreeHook<TreeNode>;
}> = ({ node, tree, renderChildren }) => {
  const link =
    node.data.type === "entry"
      ? `./${node.data.entry}`
      : node.data.type === "view"
      ? `./views/${node.data.view}`
      : null;
  const location = useLocation();
  const isActive = link ? location.pathname.includes(link.substr(1)) : false; // TODO: use `matchPath`

  const indent: React.CSSProperties = {
    marginLeft: `${node.level ?? 0}rem`,
    marginRight: `-${node.level ?? 0}rem`,
  };

  return (
    <div className="entries-tree-item__container">
      <div
        style={indent}
        className={cx("entries-tree-item__body", {
          [`--${node.data.type}`]: true,
          "--expandable": node.childCount > 0,
          "--active": isActive,
        })}
      >
        <div className="entries-tree-item__handle">
          {node.data.type === "entry" ? (
            <Icons.MediaOther />
          ) : node.data.type === "view" ? (
            <Icons.FolderUncategorisedIcon />
          ) : node.expanded ? (
            <Icons.FolderOpenIcon />
          ) : (
            <Icons.FolderIcon />
          )}
        </div>

        {node.childCount > 0 ? (
          <button onClick={() => tree.toggle(node.id)}>
            {node.expanded ? <Icons.DownIcon /> : <Icons.NextIcon />}
            {node.data.type === "folder" ? (
              <span>{node.data.label}</span>
            ) : null}
          </button>
        ) : node.data.type === "folder" ? (
          <span>{node.data.label}</span>
        ) : null}

        {link ? (
          <NavLink to={link}>
            {node.data.label}
          </NavLink>
        ) : null}
        <Link to={`./menu/settings/${node.id}`}>
          <Icons.MoreIcon />
        </Link>
      </div>

      {renderChildren(node.id)}
    </div>
  );
};

const ExpandIcon: React.FC = () => (
  <svg
    width="4"
    height="69"
    viewBox="0 0 4 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2.5L2 66.5" strokeWidth="4" strokeLinecap="round" />
  </svg>
);

const CollapseIcon: React.FC = () => (
  <svg
    width="10"
    height="69"
    viewBox="0 0 10 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2.5L2.55286 31.5514C2.18747 33.5001 2.18747 35.4999 2.55286 37.4486L8 66.5"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
