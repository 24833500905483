import * as React from "react";
import cx from "classnames";
import { Input, Message, Icons } from "pokko-shared";
import {
  MediaItemListItem,
  MediaItemListItemProps,
} from "./components/MediaItemListItem";
import {
  Pagination,
  PaginationProps,
} from "components/elements/pagination/Pagination";

export type MediItemListProps = {
  items: MediaItemListItemProps[];
  loading: boolean;
  search?: {
    value: string;
    onChange: (value: string) => void;
  };
  folder: { name: string; id: string };
  pagination: PaginationProps;
  onDrop?: (files: File[]) => void;
  // setPageSize: (value: number) => void;
};

export const MediaItemList: React.FC<MediItemListProps> = ({
  folder,
  items,
  search,
  pagination,
  loading,
  onDrop,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();
    if (ev.currentTarget.files) {
      const files = Array.from(ev.currentTarget.files);

      onDrop!(files);
    }
    ev.currentTarget.value = "";
  };

  const [dropActive, setDropActive] = React.useState(false);

  const handleSwallow: React.DragEventHandler = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const handleDragEnter: React.DragEventHandler = (ev) => {
    handleSwallow(ev);

    if (ev.dataTransfer.types.includes("Files")) {
      setDropActive(true);
    }
  };

  const handleDragLeave: React.DragEventHandler = (ev) => {
    handleSwallow(ev);

    setDropActive(false);
  };

  const handleDrop: React.DragEventHandler = (ev) => {
    handleDragLeave(ev);

    const files = Array.from(ev.dataTransfer.files);
    if (files.length > 0) {
      onDrop!(files);
    }
  };

  return (
    <div className="media-item-list__container">
      {search ? (
        <Input
          type="search"
          placeholder="Search in media"
          value={search.value}
          onChangeText={search.onChange}
        />
      ) : null}
      {onDrop ? (
        <div className="media-item-list__nav">
          <h3 className="h3">{folder.name}</h3>
          <div className="media-item-list__actions">
            <label htmlFor="file_upload" className="button --secondary">
              <div className="button__content">Upload</div>
            </label>
            <input
              type="file"
              id="file_upload"
              multiple
              onChange={handleChange}
            />
          </div>
        </div>
      ) : null}
      <div
        className={cx("media-item-list__items", {
          "--drop-active": dropActive,
          "--empty": items.length === 0 && !loading,
        })}
        onDragEnter={onDrop ? handleDragEnter : undefined}
        onDragOver={onDrop ? handleDragEnter : undefined}
        onDragLeave={onDrop ? handleDragLeave : undefined}
        onDrop={onDrop ? handleDrop : undefined}
      >
        {items.length === 0 && !loading ? (
          <div className="media-item-list__empty">
            <Icons.MediaEmptyIcon />
            <p>
              Drop files here
              <br />
              or use the “Upload” button
            </p>
          </div>
        ) : (
          items.map((ent) => <MediaItemListItem key={ent.id} {...ent} />)
        )}
      </div>
      {dropActive ? (
        <Message colour="success" size="small">
          Drop files to upload them to <strong>{folder.name}</strong>
        </Message>
      ) : null}
      <Pagination {...pagination} />
    </div>
  );
};
