import * as React from "react";
import { EntryMenu } from "../menu/EntryMenu";

export const EntrySectionWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="entries__container">
      <EntryMenu />
      {children}
    </div>
  );
};
