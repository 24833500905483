var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from "classnames";
import { Control } from "../field/Field";
export var TextArea = function (_a) {
    var _b;
    var state = _a.state, className = _a.className, help = _a.help, onChangeText = _a.onChangeText, rest = __rest(_a, ["state", "className", "help", "onChangeText"]);
    var handleChange = function (ev) {
        rest.onChange && rest.onChange(ev);
        onChangeText && onChangeText(ev.currentTarget.value);
    };
    return (_jsxs(Control, { children: [_jsx("textarea", __assign({ className: cx("textarea", className, (_b = {},
                    _b["is-".concat(state)] = !!state,
                    _b)), onChange: handleChange }, rest)), help ? _jsx("small", { className: "helper", children: help }) : null] }));
};
