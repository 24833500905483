import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, InputField, SelectField } from "pokko-shared";
import { Website, WebsiteInput, Environment } from "../ProjectSettings";
import { strings } from "strings";
import { useProject } from "routes/accounts/projects";
import { Link } from "react-router-dom";

const WebsiteDetail: React.FC<Website> = ({
  value,
  path,
  environment,
  save: [save, saveStatus],
}) => {
  const { project } = useProject();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WebsiteInput>({
    defaultValues: value,
  });

  return (
    <div className="project-settings__website-detail">
      <form className="project-settings__name" onSubmit={handleSubmit(save)}>
        <InputField
          label="Website name"
          error={errors.name}
          {...register("name", { required: strings.required })}
        />
        <InputField
          label="URL format"
          error={errors.urlFormat}
          help={
            <>
              Use <code>{"{path}"}</code>, <code>{"{id}"}</code> as placeholders
              to be replaced with actual values
            </>
          }
          {...register("urlFormat")}
        />
        <SelectField label="Publish target" {...register("target")}>
          <option />
          {project.publishTargets.nodes.map((ent) => (
            <option key={ent?.id} value={ent?.id}>
              {ent?.name}
            </option>
          ))}
        </SelectField>
        <InputField
          label="Path"
          disabled
          value={
            path
              ? ["", ...path].join("/")
              : "This website is not associated with a structure node"
          }
          help={
            path ? null : (
              <>
                Choose a node in{" "}
                <Link to={`../environments/${environment}/taxonomy`}>
                  Structure
                </Link>{" "}
                to associate with this website
              </>
            )
          }
        />
        <Button kind="secondary" type="submit" loading={saveStatus.loading}>
          Save changes
        </Button>
      </form>
    </div>
  );
};

export const Websites: React.FC<Environment> = ({
  websites,
  createWebsite: [createWebsite, createWebsiteStatus],
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleToggle = (id: string) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((ent) => ent !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };
  return (
    <div className="project-settings__websites">
      <div className="project-settings__websites-header">
        <h4 className="header">Websites</h4>
      </div>
      <ul className="project-settings__website-list">
        {websites.map((ent) => (
          <li key={ent.id}>
            <button
              className="project-settings__website-name"
              onClick={() => handleToggle(ent.id)}
            >
              <strong>{ent.name}</strong>
            </button>
            {expanded.includes(ent.id) ? <WebsiteDetail {...ent} /> : null}
          </li>
        ))}
      </ul>
      <div className="project-settings__website-actions">
        <Button
          type="button"
          onClick={createWebsite}
          loading={createWebsiteStatus.loading}
          kind="primary"
        >
          New website
        </Button>
      </div>
    </div>
  );
};
