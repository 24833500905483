import * as React from "react";
import { Icons } from "pokko-shared";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

const SortableItem: React.FC<React.PropsWithChildren<{ id: string }>> = ({
  id,
  children,
}) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id,
  });

  return (
    <div
      className="model-settings__field-order-container"
      {...attributes}
      ref={setNodeRef}
    >
      <div className="model-settings__field-order-grab-handle" {...listeners}>
        <Icons.MoveIcon />
      </div>
      {children}
    </div>
  );
};

// TODO: figure out how to give feedback so items rearrange while dragging
export const FieldOrder: React.FC<any> = ({ onChange, value }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!active) {
      return;
    }
    setActiveId(active.id);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over?.id && active.id !== over.id) {
      const oldIndex = parseInt(active.id.toString(), 10);
      const newIndex = parseInt(over.id.toString(), 10);
      onChange(
        arrayMove(value, oldIndex, newIndex).map((ent: any, index: number) => ({
          ...ent,
          index,
        }))
      );
    }
    setActiveId(null);
  };

  return (
    <div className="model-settings__field-order">
      <strong>Field order</strong>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext items={value} strategy={verticalListSortingStrategy}>
          {value
            .sort((a: any, b: any) => a.index - b.index)
            .map((ent: any, idx: number) => (
              <SortableItem id={idx.toString(10)} key={ent.id}>
                {ent.name}
              </SortableItem>
            ))}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <div className="model-settings__field-order-container">
              <div className="model-settings__field-order-grab-handle">
                <Icons.MoveIcon />
              </div>
              {value[activeId].name}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
