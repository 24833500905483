import * as React from "react";
import { Icons } from "pokko-shared";

export type PaginationProps = {
  range: {
    from: number;
    to: number;
  };
  total: number;
  next: {
    onClick: () => void;
    disabled: boolean;
  };
  prev: {
    onClick: () => void;
    disabled: boolean;
  };
};

export const buildPaginationProps = (
  skip: number,
  currentPageCount: number,
  totalCount: number,
  onNext: () => void,
  onPrev: () => void
): PaginationProps => ({
  range: {
    from: skip + 1,
    to: skip + currentPageCount,
  },
  total: totalCount,
  prev: {
    disabled: skip === 0,
    onClick: onPrev,
  },
  next: {
    disabled: skip + currentPageCount === totalCount,
    onClick: onNext,
  },
});

export const Pagination: React.FC<PaginationProps> = ({
  range,
  total,
  next,
  prev,
}) => (
  <div className="pagination__container">
    <span className="pagination__display">
      {`${range.from.toLocaleString(
        navigator.language
      )}-${range.to.toLocaleString(
        navigator.language
      )} of ${total.toLocaleString(navigator.language)}`}
    </span>

    <div className="pagination__actions">
      <button disabled={prev.disabled} onClick={prev.onClick}>
        <Icons.BackIcon />
      </button>
      <button disabled={next.disabled} onClick={next.onClick}>
        <Icons.NextIcon />
      </button>
    </div>
  </div>
);
