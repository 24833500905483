import * as React from "react";
import { FieldInputProps } from "../../types";

export const Boolean: React.FC<FieldInputProps> = ({
  value,
  onChange,
  disabled,
  readOnly,
}) => (
  <div className="control">
    <input
      className="checkbox"
      type="checkbox"
      id={`cb-${value.modelFieldId}-${value.index}`}
      onChange={(ev) =>
        onChange({
          ...value,
          valueScalar: {
            bool: ev.currentTarget.checked,
          },
        })
      }
      checked={value.valueScalar?.bool}
      disabled={disabled}
      readOnly={readOnly}
    />
    <label htmlFor={`cb-${value.modelFieldId}-${value.index}`}>
      <span />
    </label>
  </div>
);
