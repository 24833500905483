import * as React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "pokko-shared";
import { DateDisplay } from "components/elements/date/DateDisplay";

type WebhookSumamry = {
  id: string;
  name: string;
  url: string;
  lastRun: Date | null;
};

export type WebhookListProps = {
  webhooks: WebhookSumamry[];
  create: [() => void, { loading: boolean }];
};

export const WebhookList: React.FC<WebhookListProps> = ({
  webhooks,
  create: [create, createStatus],
}) => (
  <div className="webhook-list__container">
    <div className="webhook-list__body">
      {webhooks.map((ent) => (
        <NavLink key={ent.id} className="webhook-list__item" to={ent.url}>
          <strong className="webhook-list__item-name">{ent.name}</strong>
          <small>
            <DateDisplay
              label="Last run"
              value={ent.lastRun}
              fallback="Never"
            />
          </small>
        </NavLink>
      ))}
    </div>
    <div className="webhook-list__actions">
      <Button kind="primary" onClick={create} loading={createStatus.loading}>
        New webhook
      </Button>
    </div>
  </div>
);
