import * as React from "react";
import {
  CheckboxField,
  LinkButton,
  InlineRadioButtons,
  TextAreaField,
  Button,
  Icons,
} from "pokko-shared";
import {
  Entry,
  Model,
  useListEntriesExportQuery,
  useListEntriesQuery,
  useListValuesHierarchyQuery,
  useListValuesQuery,
  Value,
} from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";
import { EntryDetailCardButton } from "../../entries/card/EntryDetailCard";

export type EnvironmentExportProps = {
  models: Model[];
  onSave: (payload: any) => void;
};

export type ExportPayload = {
  models: {
    id: string;
    alias: string;
    name: string;
    usage: string;
    parents: string[];
    fields: {
      id: string;
      name: string;
      alias: string;
      index: number;
      multi: boolean;
      required: boolean;
      config: any;
      type: string;
      valueCount?: {
        start?: { inclusive: boolean; value: number };
        end?: { inclusive: boolean; value: number };
      };
    }[];
  }[];
  entries: Entry[];
  values: Value[];
};

const ModelSelector: React.FC<
  Pick<EnvironmentExportProps, "models"> & {
    selectedModels: string[];
    setSelectedModels: (value: string[]) => void;
  }
> = ({ models, selectedModels, setSelectedModels }) => {
  return (
    <ul>
      {models
        .filter((ent) => !Boolean(ent.deletedAt))
        .map((ent) => (
          <li key={ent.id}>
            <CheckboxField
              id={ent.id}
              label={ent.name}
              checked={selectedModels.includes(ent.id)}
              onChange={() =>
                selectedModels.includes(ent.id)
                  ? setSelectedModels(
                      selectedModels.filter((sel) => sel !== ent.id)
                    )
                  : setSelectedModels([...selectedModels, ent.id])
              }
            />
          </li>
        ))}
    </ul>
  );
};

const EntrySelector: React.FC<{
  selectedModels: string[];
  selection: string[];
  onChange: (value: string[]) => void;
  models: Model[];
}> = ({ selectedModels, models, selection, onChange }) => {
  const { project, environment } = useEnvironment();
  const query = useListEntriesQuery({
    variables: {
      environment: environment.id,
      project: project.id,
      filter: {
        modelId: { in: selectedModels },
      },
    },
    skip: selectedModels.length === 0,
  });

  return (
    <div className="env-export__config--entries">
      {query.data?.list?.nodes.map((ent) => (
        <EntryDetailCardButton
          key={ent?.id!}
          button={{
            children: "",
            onClick: () => {
              if (selection.includes(ent?.id)) {
                onChange(selection.filter((x) => x !== ent?.id));
              } else {
                onChange([...selection, ent?.id]);
              }
            },
          }}
          entry={{
            name: ent?.name!,
            model: models.find((mod) => mod.id === ent?.modelId)?.name!,
            modified: new Date(ent?.modifiedAt + "Z"),
            published: (ent?.entryPublishes.totalCount ?? 0) > 0,
            selectable: true,
            active: selection.includes(ent?.id),
          }}
        />
      ))}
    </div>
  );
};

const buildPayload = (
  selectedModels: string[],
  models: Model[],
  entries: Entry[],
  values: Value[]
): ExportPayload => {
  const payload = {
    models: selectedModels.map((sel) => {
      const mod = models.find((ent) => ent.id === sel)!;

      const { id, alias, name, usage, parents, fieldsAll } = mod;

      return {
        id,
        alias,
        name,
        usage,
        parents: parents.nodes.map((p) => p?.parentId!),
        fields: fieldsAll.nodes
          .filter((fld) => fld?.sourceModel?.id === mod.id)
          .map((fld) => {
            const {
              id,
              name,
              alias,
              index,
              multi,
              required,
              config,
              type,
              valueCount,
            } = fld!;
            return {
              id,
              name,
              alias,
              index,
              multi,
              required,
              config,
              type,
              valueCount: valueCount
                ? {
                    start: valueCount.start
                      ? {
                          inclusive: valueCount.start.inclusive,
                          value: valueCount.start.value,
                        }
                      : undefined,
                    end: valueCount.end
                      ? {
                          inclusive: valueCount.end.inclusive,
                          value: valueCount.end.value,
                        }
                      : undefined,
                  }
                : undefined,
            };
          }),
      };
    }),
    entries,
    values,
  } as ExportPayload;

  return payload;
};

export const EnvironmentExport: React.FC<EnvironmentExportProps> = (props) => {
  const { models, onSave } = props;
  const [selecting, setSelecting] = React.useState<string>("Models");

  const [selectedModels, setSelectedModels] = React.useState<string[]>([]);
  const [selectedEntries, setSelectedEntries] = React.useState<string[]>([]);

  const { project, environment } = useEnvironment();

  const queryEntries = useListEntriesExportQuery({
    variables: {
      environment: environment.id,
      project: project.id,
      ids: selectedEntries,
    },
    skip: selectedEntries.length === 0,
  });

  const valueTopLevelIds = (queryEntries.data?.list?.nodes ?? [])
    .map((ent) => (ent?.versions.nodes ?? []).map((ver) => ver?.valueId!))
    .reduce((p, c) => [...p, ...c], []);

  const queryValueHierarchy = useListValuesHierarchyQuery({
    variables: {
      environment: environment.id,
      project: project.id,
      ids: valueTopLevelIds,
    },
    skip: valueTopLevelIds.length === 0,
  });

  const valueIds = (queryValueHierarchy.data?.list?.nodes ?? []).map(
    (ent) => ent?.id!
  );

  const queryValues = useListValuesQuery({
    variables: {
      environment: environment.id,
      project: project.id,
      ids: valueIds,
    },
  });

  const payload = buildPayload(
    selectedModels,
    models,
    (queryEntries.data?.list?.nodes as Entry[]) ?? [],
    (queryValues.data?.list?.nodes as Value[]) ?? []
  );

  const handleSave: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.preventDefault();

    onSave(payload);
  };

  return (
    <div className="env-export__container">
      <div className="env-export__header">
        <h3 className="h3">Export</h3>
        <LinkButton
          small
          kind="tertiary"
          to=".."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to project
        </LinkButton>
      </div>
      <div className="env-export__body">
        <div className="env-export__config">
          <InlineRadioButtons
            onChange={setSelecting}
            value={selecting}
            values={["Models", "Entries", "Taxonomy"]}
          />
          <div className="env-export__config--picker">
            {selecting === "Models" ? (
              <ModelSelector
                {...props}
                selectedModels={selectedModels}
                setSelectedModels={setSelectedModels}
              />
            ) : null}
            {selecting === "Entries" ? (
              <EntrySelector
                {...props}
                selectedModels={selectedModels}
                selection={selectedEntries}
                onChange={setSelectedEntries}
              />
            ) : null}
          </div>
        </div>
        <div className="env-export__package">
          <TextAreaField
            label="Package"
            readOnly
            value={JSON.stringify(payload, null, 2)}
          />
          <div className="env-export__package--actions">
            <Button kind="primary" type="button" onClick={handleSave}>
              Download
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
