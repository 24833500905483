import {
  Account,
  Model,
  Project,
  ProjectEnvironment,
  useGetModelByAliasQuery,
} from "api/graphql";
import * as React from "react";
import { Navigate, useParams } from "react-router";
import { useEnvironment } from "../..";
import { ModelHome } from "./home";

export type ModelContextProps = {
  model: Model;
  environment: ProjectEnvironment;
  project: Project;
  account: Account;
  reload: () => void;
};

export const ModelContext = React.createContext<ModelContextProps>({
  model: {} as any,
  environment: {} as any,
  project: {} as any,
  account: {} as any,
  reload: () => {},
});

export const useModel = (): ModelContextProps =>
  React.useContext<ModelContextProps>(ModelContext);

export const ModelPage: React.FC = () => {
  const { environment, project, account } = useEnvironment();
  const { model } = useParams();
  const query = useGetModelByAliasQuery({
    variables: {
      project: project.id,
      environment: environment.id,
      alias: model!,
    },
    skip: !model,
  });

  const props: ModelContextProps = React.useMemo(
    () => ({
      model: query.data?.entity as Model,
      environment,
      account,
      project,
      reload: () => query.refetch(),
    }),
    [account, environment, project, query]
  );

  if (query.loading) {
    return null;
  }

  if (!query.data?.entity) {
    return (
      <Navigate
        to={`/accounts/${account.key}/projects/${project.key}/environments/${environment.key}/models`}
      />
    );
  }

  return (
    <ModelContext.Provider value={props}>
      <ModelHome />
    </ModelContext.Provider>
  );
};
