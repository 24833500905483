import * as React from "react";
import { CheckboxField, InputField } from "pokko-shared";
import { FieldConfigProps } from "components/components/fields/types";
import { Config } from ".";

export const ScalarConfig: React.FC<FieldConfigProps<Config>> = ({
  config,
  onChange,
}) => (
  <>
    {(config?.type ?? "text") === "text" ? (
      <>
        <InputField
          label="Validation"
          help="Regular expressions only"
          placeholder="^[A-Z]+$"
          value={config?.validation}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...config, validation: ev.currentTarget.value })
          }
        />
        <CheckboxField
          id="multiline"
          label="Multiline"
          checked={config?.multiline ?? false}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...config, multiline: ev.currentTarget.checked })
          }
        />
        <CheckboxField
          id="markdown"
          label="Markdown"
          checked={config?.markdown ?? false}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...config, markdown: ev.currentTarget.checked })
          }
        />
      </>
    ) : null}
  </>
);
