import * as React from "react";
import { useSearchParams } from "react-router-dom";
import {
  DropdownButton,
  Button,
  SplitButton,
  Icons,
  Message,
  LinkButton,
} from "pokko-shared";
import { ValueEditor } from "components/components/values/editor/ValueEditor";
import {
  ValueEditorContext,
  ValueEditorContextProps,
  ValueEditorContextProvider,
} from "components/components/values/editor/ValueEditorContext";
import { strings } from "strings";
import { EntryDetailProps } from "../EntryDetail";

export const EntryEditor: React.FC<
  Pick<EntryDetailProps, "entry" | "version" | "publishTargets" | "versions">
> = ({
  entry,
  version,
  publishTargets,
  versions: {
    create: [create, createStatus],
    copy2: [copy2, copy2Status],
  },
}) => {
  const [params] = useSearchParams();
  const listingUrlSource = params.get("listingUrl");

  const [listingUrl, setlistingUrl] = React.useState(listingUrlSource);

  // TODO: this might not be necessary?
  React.useEffect(() => {
    listingUrlSource && setlistingUrl(listingUrlSource);
  }, [listingUrlSource]);

  const pendingPublishes = version.publishTargets.filter(
    (pub) => pub.date < version.modified
  );

  const handlePublish = async (
    context: ValueEditorContextProps,
    target: string
  ) => {
    if (!context?.validate()) {
      return;
    }

    if (context?.state === "changed") {
      await context.save();
    }

    await entry.onPublish2(target);
  };

  return (
    <ValueEditorContextProvider>
      <ValueEditorContext.Consumer>
        {(context) => (
          <div className="entry-detail__editor">
            {listingUrl ? (
              <div>
                <LinkButton
                  small
                  kind="tertiary"
                  to={listingUrl}
                  icons={{ left: <Icons.BackIcon /> }}
                >
                  Back to listing
                </LinkButton>
              </div>
            ) : null}

            <div className="entry-detail__editor-header">
              <h2 className="h2">{entry.value.name}</h2>

              <div className="entry-detail__editor-header-actions">
                <DropdownButton
                  renderContent={() => (
                    <div className="entry-detail__editor-publishing">
                      <h4 className="header">Publish targets</h4>
                      <ul className="entry-detail__editor-publishing--targets">
                        {publishTargets.map((ent) => {
                          const published = version.publishTargets.find(
                            (tar) => tar.id === ent.id
                          );
                          return (
                            <li key={ent.id}>
                              <span>{ent.name}</span>
                              <small>
                                {published ? "Published" : "Not published"}
                              </small>
                              <Button
                                kind="primary"
                                small
                                onClick={async () => {
                                  if (published) {
                                    await entry.onRetract2(ent.id);
                                  } else {
                                    await handlePublish(context!, ent.id);
                                  }
                                }}
                              >
                                {published ? "Retract" : "Publish"}
                              </Button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                >
                  Publish
                </DropdownButton>
                <SplitButton
                  kind="primary"
                  disabled={context?.saving}
                  icons={{
                    left: context?.saving ? null : context?.state ===
                      "saved" ? (
                      <Icons.SavedIcon />
                    ) : null,
                  }}
                  onClick={() => {
                    if (context?.state !== "changed") {
                      return;
                    }

                    if (version.publishTargets.length > 0) {
                      if (!context?.validate()) {
                        return;
                      }
                    }

                    context?.save();
                  }}
                  renderContent={() => (
                    <ul className="dropdown__menu">
                      <li className="dropdown__menu--item">
                        <button
                          onClick={create}
                          disabled={
                            createStatus.loading ||
                            copy2Status.loading ||
                            context?.saving
                          }
                        >
                          Save as new version
                        </button>
                      </li>
                      <li className="dropdown__menu--item">
                        <button
                          onClick={copy2}
                          disabled={
                            createStatus.loading ||
                            copy2Status.loading ||
                            context?.saving
                          }
                        >
                          Save as new entry
                        </button>
                      </li>
                      <li className="dropdown__menu--spacer" />
                      <li className="dropdown__menu--item">
                        <button>{strings.discard}</button>
                      </li>
                    </ul>
                  )}
                >
                  {context?.state === "saved" ? "Saved!" : "Save"}
                </SplitButton>
              </div>
            </div>
            {pendingPublishes.length > 0 ? (
              <Message size="small">
                <div className="entry-detail__editor-publish">
                  <p>
                    This version of the entry is currently published, you will
                    need to republish it for changes to be available.
                  </p>
                  <div className="entry-detail__editor-publish__actions">
                    {pendingPublishes.map((x) => (
                      <Button
                        key={x.id}
                        kind="tertiary"
                        small
                        onClick={async () =>
                          await handlePublish(context!, x.id)
                        }
                        loading={entry.publishing2}
                      >
                        {`Republish to ${x.name}`}
                      </Button>
                    ))}
                  </div>
                </div>
              </Message>
            ) : null}
            {context?.invalid ? (
              <Message colour="danger" size="small">
                Cannot publish, some required fields are blank or not meeting
                validation settings.
              </Message>
            ) : null}
            <div className="entry-detail__editor-body">
              <ValueEditor id={version.value} />
            </div>
          </div>
        )}
      </ValueEditorContext.Consumer>
    </ValueEditorContextProvider>
  );
};
