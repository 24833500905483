import * as React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { Icons } from "pokko-shared";

export const DropdownItem: React.FC<
  React.PropsWithChildren<{
    label: React.ReactNode;
    link?: string;
    right?: boolean;
    hideArrow?: boolean;
  }>
> = ({ label, link, children, right, hideArrow }) => (
  <div className={cx("navbar__dropdown", { "--right": right })}>
    {link ? (
      <NavLink to={link} className="navbar__dropdown-button">
        <span>{label}</span>
        {!hideArrow ? <Icons.DownIcon /> : null}
      </NavLink>
    ) : (
      <div className="navbar__dropdown-button">
        <span>{label}</span>
        {!hideArrow ? <Icons.DownIcon /> : null}
      </div>
    )}
    <div className="navbar__dropdown-content">{children}</div>
  </div>
);
