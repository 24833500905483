import { ModelUsage, WebhookLogLevel, WebhookRunStatus } from "api/graphql";

export const strings = {
  activate: "Activate",
  cancel: "Cancel",
  clear: "Clear",
  confirm: "Confirm",
  create: "Create",
  delete: "Delete",
  discard: "Discard changes",
  errorGeneric: "Something went wrong",
  invalid: "This field contains an invalid value",
  publish: "Publish",
  required: "This field is required",
  restore: "Restore",
  reset: "Reset",
  save: "Save changes",
  unpublish: "Retract",
  unsavedChanges: "You have unsaved changes. Are you sure?",
  models: {
    dataModelWarning: "Warning: this will change your API data model",
    usage: {
      [ModelUsage.Entry]: "Entry",
      [ModelUsage.Module]: "Module",
    },
  },
  webhooks: {
    status: {
      [WebhookRunStatus.Failure]: "Failure",
      [WebhookRunStatus.Pending]: "Pending",
      [WebhookRunStatus.Success]: "Success",
    },
    log: {
      level: {
        [WebhookLogLevel.Error]: "Error",
        [WebhookLogLevel.Info]: "Information",
        [WebhookLogLevel.Warning]: "Warning",
      },
    },
  },
};
