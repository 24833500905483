import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { ModelUsage, usePutModelMutation } from "api/graphql";
import { ModelCreate } from "components/components/models/create/ModelCreate";
import { ModelSettingsInput } from "components/components/models/settings/ModelSettings";
import { useEnvironment } from "../..";
import { FieldErrors } from "react-hook-form";
import { handleFieldErrors } from "../model/home";

export const ModelCreatePage: React.FC = () => {
  const { account, project, environment, models, reload } = useEnvironment();
  const [modelSaveErrors, setModelSaveErrors] = React.useState<
    FieldErrors<ModelSettingsInput>
  >({});

  const [create, createStatus] = usePutModelMutation();
  const navigate = useNavigate();

  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const defaultUsage = (qs.get("usage") as ModelUsage) || ModelUsage.Entry;

  const handleSubmit = async (values: ModelSettingsInput) => {
    try {
      const res = await create({
        variables: {
          model: {
            name: values.name,
            alias: values.alias,
            projectId: project.id,
            environmentId: environment.id,
          },
          usage: values.usage,
          parents: values.inheritance?.map((ent) => ent.id),
        },
      });

      reload();

      if (res.data?.putModel?.model?.alias) {
        navigate(
          `/accounts/${account.key}/projects/${project.key}/environments/${environment.key}/models/${res.data?.putModel?.model?.alias}`
        );
      }
    } catch (ex) {
      setModelSaveErrors(handleFieldErrors(ex));
    }
  };

  return (
    <ModelCreate
      isNew
      onSubmit={handleSubmit}
      loading={createStatus.loading}
      errors={modelSaveErrors}
      models={models}
      deleted={false}
      restoring={false}
      defaultValues={{
        name: "",
        alias: "",
        fields: [],
        usage: defaultUsage,
      }}
    />
  );
};
