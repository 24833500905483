import * as React from "react";
import {
  Account,
  MediaType,
  Project,
  useGetProjectByKeyQuery,
} from "api/graphql";
import { Navigate, Route, Routes, useParams } from "react-router";
import { useAccount } from "..";
import { ProjectHome } from "./home";
import { EnvironmentPage } from "./environments";
// import { ProjectSettingsPage } from "./settings/index.tsx";
import { ProjectMediaPage } from "./media";
import { WebhooksProvider } from "providers/webhooks";
import { ProjectWebhooksPage } from "./webhooks";
import { MediaUploadWidget } from "components/components/media/uploads/widget/MediaUploadWidget";
import { useUpload } from "providers/content/upload";
import { MediaState } from "components/components/media/uploads/widget/components/MediaUploadWidgetItem";
import { ProjectSettingsPage } from "./settings";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";

export const MediaUploadWidgetController: React.FC = () => {
  const [visible, setVisible] = React.useState(true);
  const uploads = useUpload();

  if (!visible) {
    return null;
  }
  return (
    <MediaUploadWidget
      closable={uploads.working.length + uploads.pending.length === 0}
      onClose={() => setVisible(false)}
      items={uploads.working
        .map((ent, idx) => ({
          id: `working.${idx}`,
          name: ent.file.name!,
          type: MediaType.Image,
          state: "active" as MediaState,
          progress: uploads.progress!,
          onPause: () => {},
          onResume: () => {},
          onCancel: () => {},
        }))
        .concat(
          uploads.pending.map((ent, idx) => ({
            id: `pending.${idx}`,
            name: ent.file.name,
            type: "image" as MediaType,
            state: "pending" as MediaState,
            progress: 0,
            onPause: () => {},
            onResume: () => {},
            onCancel: () => {},
          }))
        )
        .concat(
          uploads.complete.map((ent, idx) => ({
            id: `complete.${idx}`,
            name: ent.file.name,
            type: "image" as MediaType,
            state: "complete" as MediaState,
            progress: 0,
            onPause: () => {},
            onResume: () => {},
            onCancel: () => {},
          }))
        )}
    />
  );
};

export type ProjectContextProps = {
  project: Project;
  account: Account;
  reload: () => void;
  loading: boolean;
};

export const ProjectContext = React.createContext<ProjectContextProps>({
  project: {} as any,
  account: {} as any,
  reload: () => {},
  loading: false,
});

export const useProject = (): ProjectContextProps =>
  React.useContext<ProjectContextProps>(ProjectContext);

export const AccountProject: React.FC = () => {
  const { project } = useParams();
  const { account } = useAccount();

  const query = useGetProjectByKeyQuery({
    variables: { account: account.id, key: project! },
    skip: !project,
  });

  if (query.loading) {
    return null;
  }

  if (!query.data?.project) {
    return <Navigate to={`/accounts/${account.key}`} />;
  }

  const props: ProjectContextProps = {
    account,
    project: query.data.project as Project,
    reload: query.refetch,
    loading: query.loading,
  };

  if (Boolean(props.project.deletedAt)) {
    return (
      <SimpleLayout>
        <p>This project is archived.</p>
      </SimpleLayout>
    );
  }

  return (
    <ProjectContext.Provider value={props}>
      <WebhooksProvider>
        <Routes>
          <Route path="" element={<ProjectHome />} />
          <Route
            path="environments/:environment/*"
            element={<EnvironmentPage />}
          />
          <Route path="settings/*" element={<ProjectSettingsPage />} />
          <Route path="media/*" element={<ProjectMediaPage />} />
          <Route path="webhooks/*" element={<ProjectWebhooksPage />} />
        </Routes>
      </WebhooksProvider>
      <MediaUploadWidgetController />
    </ProjectContext.Provider>
  );
};
