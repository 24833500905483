import * as React from "react";
import { Controller } from "react-hook-form";
import { SelectField, CheckboxField, Button } from "pokko-shared";
import { Model } from "api/graphql";

export type TaxonomyLevelInput = {
  levelId?: string;
  parent: string | null;
  aliasField?: string;
  fragmentField?: string;
  fragmentType?: string;
  isNode: boolean;
  inherit: boolean;
  model: string;
};

export type TaxonomyLevelEditorProps = {
  isNew: boolean;
  index: number;
  register: any;
  control: any;
  value: TaxonomyLevelInput;
  models: Model[];
  modelsByDefault: Model[];
  onRemove: () => void;
  onModelDefault: () => void;
  watch(field: string | string[]): any;
};

const fragmentTypes = [
  { alias: "value", label: "Fragment field value (i.e., [fragment]/[alias])" },
  { alias: "date_year", label: "Year only (i.e., yyyy/[alias])" },
  { alias: "date_yearmonth", label: "Year and month (i.e., yyyy/mm/[alias])" },
  {
    alias: "date_yearmonthday",
    label: "Year, month and day (i.e., yyyy/mm/dd/[alias])",
  },
];

const ModelFieldSelector: React.FC<{
  label: string;
  model: Model;
  value?: string;
  onChange: (value: string) => void;
}> = ({ label, model, value, onChange }) => (
  <SelectField label={label} value={value} onChangeText={onChange}>
    <option />
    {model.fieldsAll.nodes.map((ent) => (
      <option key={ent?.id} value={ent?.id}>
        {ent?.name}
      </option>
    ))}
  </SelectField>
);

export const TaxonomyLevelEditor: React.FC<TaxonomyLevelEditorProps> = ({
  control,
  index,
  isNew,
  models,
  register,
  value,
  watch,
  onRemove,
  onModelDefault,
}) => {
  const fieldName = (input: keyof TaxonomyLevelInput) =>
    `levels.${index}.${input}`;
  const model = watch(fieldName("model"));
  const fragmentField = watch(fieldName("fragmentField"));
  const modelObj = models.find((ent) => ent.id === model);

  return (
    <div className="taxonomy-level__container">
      <input
        type="hidden"
        defaultValue={value.levelId}
        {...register(fieldName("levelId"))}
      />
      <SelectField
        label="Model"
        defaultValue={value.model}
        {...register(fieldName("model"))}
      >
        <option />
        {models.map((ent) => (
          <option key={ent.id} value={ent.id}>
            {ent.name}
          </option>
        ))}
      </SelectField>
      {modelObj ? (
        <Controller
          control={control}
          name={fieldName("aliasField")}
          defaultValue={value.aliasField}
          render={({ field: { value, onChange } }) => (
            <ModelFieldSelector
              model={modelObj}
              label="Alias field"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ) : null}
      {modelObj ? (
        <Controller
          control={control}
          name={fieldName("fragmentField")}
          defaultValue={value.fragmentField}
          render={({ field: { value, onChange } }) => (
            <ModelFieldSelector
              model={modelObj}
              label="Fragment field"
              value={value || ""}
              onChange={onChange}
            />
          )}
        />
      ) : null}
      {fragmentField ? (
        <SelectField
          label="Fragment type"
          defaultValue={value.fragmentType || ""}
          {...register(fieldName("fragmentType"))}
        >
          <option />
          {fragmentTypes.map((ent) => (
            <option key={ent.alias} value={ent.alias}>
              {ent.label}
            </option>
          ))}
        </SelectField>
      ) : null}
      {modelObj ? (
        <CheckboxField
          id={fieldName("inherit")}
          label="Include inherited models"
          defaultChecked={value.inherit}
          {...register(fieldName("inherit"))}
        />
      ) : null}
      <CheckboxField
        id={fieldName("isNode")}
        label="Create node at this level"
        defaultChecked={value.isNode}
        {...register(fieldName("isNode"))}
      />
      <div className="taxonomy-level__actions">
        <Button type="button" onClick={onRemove} kind="tertiary">
          Remove
        </Button>
        {isNew ? null : (
          <Button
            kind="tertiary"
            type="button"
            onClick={onModelDefault}
            disabled={modelObj?.defaultTaxonomyLevelId === value.levelId}
          >
            Set as default for model
          </Button>
        )}
      </div>
    </div>
  );
};
