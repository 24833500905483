import * as React from "react";
import { useForm } from "react-hook-form";
import { Field, Label, Input, Button, Message } from "pokko-shared";
import {
  PublishTarget,
  PublishTargetInput,
  ProjectSettingsProps,
} from "../ProjectSettings";
import { strings } from "strings";

const PublishTargetDetail: React.FC<PublishTarget> = ({
  value,
  save: [save, saveStatus],
  republish: [republish, republishStatus],
  updatePaths: [updatePaths, updatePathsStatus],
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PublishTargetInput>({
    defaultValues: value,
  });

  return (
    <div className="project-settings__target-detail">
      <form className="project-settings__name" onSubmit={handleSubmit(save)}>
        <Field>
          <Label>Target name</Label>
          <div className="project-settings__field-inline">
            <Input {...register("name", { required: strings.required })} />
            <Button kind="secondary" type="submit" loading={saveStatus.loading}>
              Rename
            </Button>
          </div>
          {errors.name?.message ? (
            <Message size="small" colour="danger">
              {errors.name.message}
            </Message>
          ) : null}
        </Field>
      </form>
      <div className="project-settings__target-actions">
        <Button
          kind="secondary"
          onClick={republish}
          loading={republishStatus.loading}
        >
          Republish
        </Button>
        <Button
          kind="secondary"
          onClick={updatePaths}
          loading={updatePathsStatus.loading}
        >
          Update paths
        </Button>
      </div>
      <p>
        <small>
          <strong>Republish</strong> if you have made a significant number of
          changes to your model that affects published content.{" "}
          <strong>Update paths</strong> if you have made changes to dynamic
          structure nodes.
        </small>
      </p>
      <p>
        <small>
          These processes will run in the background and may take some time if
          there are a large number of entries in your project.
        </small>
      </p>
    </div>
  );
};

export const PublishTargets: React.FC<ProjectSettingsProps> = ({
  publishTargets,
  createPublishTarget: [createPublishTarget, createPublishTargetStatus],
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleToggle = (id: string) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((ent) => ent !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };
  return (
    <div className="project-settings__targets">
      <div className="project-settings__targets-header">
        <h4 className="header">Publish Targets</h4>
      </div>
      <ul className="project-settings__target-list">
        {publishTargets.map((ent) => (
          <li key={ent.id}>
            <button
              className="project-settings__target-name"
              onClick={() => handleToggle(ent.id)}
            >
              <strong>{ent.name}</strong>
            </button>
            {expanded.includes(ent.id) ? (
              <PublishTargetDetail {...ent} />
            ) : null}
          </li>
        ))}
      </ul>
      <div className="project-settings__target-actions">
        <Button
          type="button"
          onClick={createPublishTarget}
          loading={createPublishTargetStatus.loading}
          kind="primary"
        >
          New target
        </Button>
      </div>
    </div>
  );
};
