import * as React from "react";
import { Navigate, Route, Routes } from "react-router";

import { ModelPage } from "./model";
import { ModelCreatePage } from "./create";
import { ModelListingModule } from "./components/ModelListingModule";
import { Helmet } from "react-helmet";
import { useEnvironment } from "..";
import { TwoColumnLayout } from "components/layout/twocol/TwoColumnLayout";
// import { ModelImport } from "./import";
// import { ModelExport } from "./export";

const EmptyModelsRedirect: React.FC = () => {
  const { account, project, environment, models } = useEnvironment();

  if (models.length === 0) {
    return (
      <Navigate
        to={`/accounts/${account.key}/projects/${project.key}/environments/${environment.key}/models/create`}
      />
    );
  }

  return null;
};

export const EnvModels: React.FC = () => {
  const { project, environment } = useEnvironment();

  return (
    <>
      <Helmet title={["Models", environment.name, project.name].join(" - ")} />
      <TwoColumnLayout
        left={<ModelListingModule />}
        right={
          <Routes>
            <Route path="create" element={<ModelCreatePage />} />
            {/* <Route path="import" element={<ModelImport />} />
            <Route path="export" element={<ModelExport />} /> */}
            <Route path=":model/*" element={<ModelPage />} />
            <Route path="" element={<EmptyModelsRedirect />} />
          </Routes>
        }
      />
    </>
  );
};
