import * as React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router";
import { useEnvironment } from "..";
import { EntryPage } from "./entry";
import { EntryCreatePage } from "./create";
import { EntrySectionWrapper } from "components/components/entries/wrapper/EntrySectionWrapper";
import { EntryView } from "./view";
import { EntryMenuPage } from "./menu";
import { EntryListPage } from "./list";
import { EntryMenuSettingsPage } from "./settings/EntryMenuSettings";

export type EnvEntriesProps = {};

export const EnvEntries: React.FC<EnvEntriesProps> = () => {
  const { project, environment } = useEnvironment();

  return (
    <>
      <Helmet title={["Entries", environment.name, project.name].join(" - ")} />

      <EntrySectionWrapper>
        <Routes>
          <Route path="" element={<EntryListPage />} />
          <Route path="create" element={<EntryCreatePage />} />
          <Route path=":entry/*" element={<EntryPage />} />
          <Route path="views/:view" element={<EntryView />} />
          <Route path="menu/create" element={<EntryMenuPage />} />
          <Route path="menu/settings" element={<EntryMenuSettingsPage />} />
          <Route path="menu/settings/:item" element={<EntryMenuPage />} />
        </Routes>
      </EntrySectionWrapper>
    </>
  );
};
