import { Field } from "components/components/fields/types";
import { EntryConfig } from "./config";
import { EntryInput } from "./input";

export type Config = {
  allowed: string[];
  simple?: boolean;
};

export const Entry: Field = {
  name: "Linked content",
  config: EntryConfig,
  input: EntryInput,
  displayName: (config: Config, models) => {
    if (config?.allowed && config?.allowed.length > 0) {
      return `Linked content: ${config.allowed
        .map((id) => models.find((ent) => ent.id === id))
        .map((ent) => ent?.name)
        .join(", ")}`;
    }
    return "Linked content";
  },
};
