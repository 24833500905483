import * as React from "react";
import { Icons } from "pokko-shared";
import { ValueFieldValue } from "components/components/fields/types";
import { ValueEditor } from "../../../values/editor/ValueEditor";
import { useEnvironment } from "routes/accounts/projects/environments";

type ModuleEditContextProps = {
  push: (value: ValueFieldValue) => void;
  pop: (index: number) => void;
  stack: ValueFieldValue[];
  level: number;
  parent: ModuleEditContextProps | null;
  root: ModuleEditContextProps | null;
};
const ModuleEditContext = React.createContext<ModuleEditContextProps>({
  push: () => null,
  pop: () => null,
  stack: [],
  level: -1,
  parent: null,
  root: null,
});

export const useModuleEditContext = () =>
  React.useContext<ModuleEditContextProps>(ModuleEditContext);

const Editor: React.FC<ModuleEditContextProps> = (props) => {
  const { models } = useEnvironment();

  const display = (input: ValueFieldValue): string => {
    const model = models.find((mod) => mod.id === input.valueValue?.modelId);

    if (input.hint) {
      return `${input.hint} (${model?.name})`;
    }

    return model?.name!;
  };

  return (
    <div className="module-editor__stack-container">
      <div className="module-editor__stack-crumbs">
        <button onClick={() => props.pop(-1)}>
          <Icons.BackIcon />
          Back
        </button>
        {props.stack
          .filter((_, idx, arr) => idx < arr.length - 1)
          .map((ent, idx) => (
            <button key={idx} onClick={() => props.pop(idx)}>
              {display(ent)}
            </button>
          ))}
        <button disabled>{display(props.stack[props.stack.length - 1])}</button>
      </div>
      <ValueEditor id={props.stack[props.stack.length - 1].valueValueId} />
    </div>
  );
};

export const ModuleEditContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const parentContext = useModuleEditContext();
  const [stack, setStack] = React.useState<ValueFieldValue[]>([]);

  const props: ModuleEditContextProps = {
    push: (value) =>
      parentContext.level === -1
        ? setStack([...stack, value])
        : parentContext.root?.push(value),
    pop: (index) =>
      parentContext.level === -1
        ? setStack(stack.filter((_, idx) => idx <= index))
        : parentContext.root?.pop(index),
    stack,
    level: parentContext.level + 1,
    parent: parentContext,
    root: null,
  };

  props.root = parentContext.root ?? props;

  if (props.level === 0 && props.stack.length > 0) {
    return (
      <ModuleEditContext.Provider value={props}>
        <Editor {...props} />
      </ModuleEditContext.Provider>
    );
  }

  return (
    <ModuleEditContext.Provider value={props}>
      {children}
    </ModuleEditContext.Provider>
  );
};
