import * as React from "react";
import {
  LinkButton,
  TextAreaField,
  InlineRadioButtons,
  Button,
  Icons,
} from "pokko-shared";
import { Model } from "api/graphql";
import { ExportPayload } from "../export/EnvironmentExport";

export type EnvironmentImportProps = {
  models: Model[];
  onImport: (payload: ExportPayload) => Promise<void>;
};

const parsePayload = (input: string): ExportPayload | null => {
  try {
    return JSON.parse(input);
  } catch {
    return null;
  }
};

export const EnvironmentImport: React.FC<EnvironmentImportProps> = (props) => {
  const { models, onImport } = props;
  const [selecting, setSelecting] = React.useState<string>("Models");

  const [payloadStr, setPayloadStr] = React.useState("");
  const payload = parsePayload(payloadStr);

  const handleImport: React.MouseEventHandler<HTMLButtonElement> = async (
    ev
  ) => {
    ev.preventDefault();

    if (payload) {
      await onImport(payload);
    }
  };

  return (
    <div className="env-import__container">
      <div className="env-import__header">
        <h3 className="h3">Import</h3>
        <LinkButton
          small
          kind="tertiary"
          to=".."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to project
        </LinkButton>
      </div>
      <div className="env-import__body">
        <div className="env-import__package">
          <TextAreaField
            label="Package"
            onChangeText={setPayloadStr}
            value={payloadStr}
          />
          {/* <div className="env-import__package--actions">
            <Button kind="primary" type="button" onClick={handleImport}>
              Upload
            </Button>
          </div> */}
        </div>
        <div className="env-import__config">
          {payload ? (
            <>
              <InlineRadioButtons
                onChange={setSelecting}
                value={selecting}
                values={["Models", "Entries", "Taxonomy"]}
              />
              <div className="env-import__config--picker">
                {selecting === "Models" ? (
                  <ul>
                    {payload.models.map((ent) => (
                      <li key={ent.id}>
                        <span>{ent.name}</span>
                        {models.find((mod) => mod.id === ent.id) ? (
                          <small>Skipping (exists)</small>
                        ) : models.find((mod) => mod.alias === ent.alias) ? (
                          <small>Skipping (conflict)</small>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                ) : null}
                {selecting === "Entries" ? (
                  <ul>
                    {payload.entries.map((ent) => (
                      <li key={ent.id}>
                        <span>{ent.name}</span>
                        <small>
                          {models.find((mod) => mod.id === ent.modelId)?.name}
                        </small>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <div className="env-import__config--actions">
                <Button
                  kind="primary"
                  type="button"
                  onClick={handleImport}
                  disabled={!payload}
                >
                  Import
                </Button>
              </div>
            </>
          ) : (
            <p>Invalid payload</p>
          )}
        </div>
      </div>
    </div>
  );
};
