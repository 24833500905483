import * as React from "react";
import { Icons } from "pokko-shared";
import cx from "classnames";
import { MediaFolder, useListMediaFoldersQuery } from "api/graphql";
import { useProject } from "routes/accounts/projects";

export type FolderSelectorProps = {
  value?: string;
  onChange: (value?: string) => void;
  onClose: () => void;
};

export const FolderSelector: React.FC<FolderSelectorProps> = ({
  onClose,
  onChange,
}) => {
  const { project } = useProject();
  const query = useListMediaFoldersQuery({
    variables: {
      project: project.id,
    },
  });

  const [expanded, setExpanded] = React.useState<string[]>([]);

  const renderChildren = (parent: string | null, level: number) => {
    return (
      <>
        {query.data?.list?.nodes
          .filter((ent) => ent?.parentId === parent)
          .sort((a, b) => a!.name.localeCompare(b!.name))
          .map((ent) => (
            <Node
              key={ent?.id}
              item={ent as MediaFolder}
              onClick={() => onChange(ent?.id)}
              onToggle={() =>
                setExpanded((prev) =>
                  prev.includes(ent?.id!)
                    ? prev.filter((id) => id !== ent?.id)
                    : [...prev, ent?.id!]
                )
              }
              expanded={expanded.includes(ent?.id)}
              level={level}
              renderChildren={renderChildren}
            />
          ))}
      </>
    );
  };

  return (
    <div className="folder-selector__container">
      <div className="folder-selector__header">
        <button onClick={onClose}>
          <Icons.CloseIcon />
        </button>
      </div>
      <div className="folder-selector__items">
        <div className="folder-selector-item">
          <button
            onClick={() => onChange(undefined)}
            className="folder-selector-item__action"
          >
            <Icons.FolderUncategorisedIcon />
            <span>Uncategorised</span>
          </button>
        </div>
        {renderChildren(null, 0)}
      </div>
    </div>
  );
};

type NodeProps = {
  item: MediaFolder;
  level: number;
  expanded: boolean;
  onClick: () => void;
  onToggle: () => void;
  renderChildren: (parent: string, level: number) => React.ReactNode;
};

const Node: React.FC<NodeProps> = ({
  item,
  level,
  expanded,
  renderChildren,
  onClick,
  onToggle,
}) => (
  <>
    <div
      key={item?.id}
      className={cx("folder-selector-item", {
        "--expandable": (item?.children.totalCount ?? 0) > 0,
      })}
      style={{ marginLeft: `calc(${level} * 1rem)` }}
    >
      {(item.children.totalCount ?? 0) > 0 ? (
        <button onClick={onToggle}>
          {expanded ? <Icons.DownIcon /> : <Icons.NextIcon />}
        </button>
      ) : null}
      <button className="folder-selector-item__action" onClick={onClick}>
        <Icons.FolderIcon />
        <span>{item.name}</span>
      </button>
    </div>
    {expanded ? renderChildren(item.id, level + 1) : null}
  </>
);
