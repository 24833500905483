import * as React from "react";
import { Icons, Button, Message } from "pokko-shared";
import { snakeCase, kebabCase, camelCase, pascalCase } from "change-case";

import { ModelFieldsInherited, ModelFieldType, ValueField } from "api/graphql";
import { useFields } from "providers/content/fields";
import { strings } from "strings";
import { ModuleEditContextProvider } from "../../fields/value/components/ModuleEditContextProvider";
import { FieldInputProps } from "../../fields/types";
import { useValueEditorContext } from "./ValueEditorContext";
import { useEnvironment } from "routes/accounts/projects/environments";
import { useEntry } from "routes/accounts/projects/environments/entries/entry";
import { InlineEntrySelector } from "../../fields/entry/InlineEntrySelector";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

export type FieldEditorProps = {
  field: ModelFieldsInherited;
  label: string;
  model: string;
  saving: boolean;
  valueId: string;
  value: ValueField[];
  valueAll: ValueField[];
  valueBase: ValueField[];
  onChange: (value: ValueField[]) => void;
  onClear?: () => void;
};

export const FieldEditor: React.FC<FieldEditorProps> = ({
  field,
  label,
  model,
  saving,
  valueId,
  value,
  valueAll,
  valueBase,
  onClear,
  onChange,
}) => {
  const [clearing, setClearing] = React.useState(false);
  const [base /*, setBase*/] = React.useState(false);

  const { fields } = useFields();
  const { errors } = useValueEditorContext();
  const { models } = useEnvironment();
  const entryContext = useEntry();

  const autoHelp = React.useCallback((): string | undefined => {
    if (field.autoSource) {
      switch (field.autoSource) {
        case "name":
          return "This field is automatically populated with the entry name";
        case "field":
          const fld = models
            .find((ent) => ent.alias === model)
            ?.fieldsAll.nodes.find((ent) => ent?.id === field.autoFieldId);

          return `This field is automatically populated with the '${fld?.name!}' field`;
      }
    }
  }, [field.autoFieldId, field.autoSource, model, models]);

  const autoValue = React.useCallback((): ValueField[] | undefined => {
    /*  when 'kebab' then inflection.dashed(f_input)
    when 'camel' then inflection.camel(f_input)
    when 'pascal' then inflection.pascal(f_input)
    when 'snake' then inflection.underscore(f_input)
    when 'upper' then upper(f_input)
    when 'lower' then lower(f_input)*/
    const transform = (input: string): string => {
      if (!input) {
        return "";
      }
      switch (field.autoTransform) {
        case "kebab":
          return kebabCase(input);
        case "camel":
          return camelCase(input);
        case "pascal":
          return pascalCase(input);
        case "snake":
          return snakeCase(input);
        case "upper":
          return input.toUpperCase();
        case "lower":
          return input.toLowerCase();
        default:
          return input;
      }
    };
    if (field.autoSource) {
      switch (field.autoSource) {
        case "name":
          return [
            {
              index: 0,
              valueScalar: { text: transform(entryContext?.entry?.name) },
              modelFieldId: field.id,
              id: "-1",
            },
          ] as any[];
        case "field":
          return valueAll
            .filter((ent) => ent.modelFieldId === field.autoFieldId)
            .map((ent) => ({
              ...ent,
              valueScalar:
                ent.valueScalar && ent.valueScalar.text
                  ? {
                      ...ent.valueScalar,
                      text: transform(ent.valueScalar.text),
                    }
                  : undefined,
            }));
      }
      return [];
    }
  }, [
    entryContext?.entry?.name,
    field.autoFieldId,
    field.autoSource,
    field.autoTransform,
    field.id,
    valueAll,
  ]);

  const fieldEditor = React.useCallback(
    (field: ModelFieldsInherited, fieldValue: ValueField): React.ReactNode => {
      const fld = fields[field.type as ModelFieldType];
      if (fld) {
        const props: FieldInputProps<any> = {
          config: field.config,
          disabled: saving || !!field.autoSource,
          readOnly: base,
          field,
          help: autoHelp(),
          value: fieldValue,
          onRemove: field.multi
            ? () => onChange(value.filter((ent) => ent !== fieldValue))
            : undefined,
          onChange: (val) =>
            base
              ? {}
              : onChange(
                  value.includes(fieldValue)
                    ? value.map(
                        (ent) => (ent === fieldValue ? val : ent) as ValueField
                      )
                    : value
                        .concat([val as ValueField])
                        .map((ent, index) => ({ ...ent, index }))
                ),
        };

        return React.createElement(fld.input, props);
      } else {
        return <pre>Unhandled field type: {field.type}</pre>;
      }
    },
    [autoHelp, base, fields, onChange, saving, value]
  );

  const displayValues = (base ? valueBase : autoValue() ?? value).sort(
    (a, b) => a.index - b.index
  );
  const error = errors.find(
    (ent) => ent.value === valueId && ent.field === field.id
  );

  const [active, setActive] = React.useState<UniqueIdentifier | null>(null);
  const handleDragStart = (ev: DragStartEvent) => {
    setActive(ev.active.id);
  };
  const handleDragEnd = (ev: DragEndEvent) => {
    setActive(null);
    if (ev.over !== null) {
      const oldIndex = value.findIndex((ent) => ent.id === ev.active.id);
      const newIndex = value.findIndex((ent) => ent.id === ev.over!.id);

      if (oldIndex !== newIndex) {
        onChange(
          arrayMove(value, oldIndex, newIndex).map((ent, index) => ({
            ...ent,
            index,
          }))
        );
      }
    }
  };

  const sensors = useSensors(useSensor(PointerSensor));

  return (
    <div className="value-editor__field">
      <div className="value-editor__field-header">
        <span>{label}</span>
        <div className="value-editor__field-header-actions">
          {onClear ? (
            clearing ? (
              <>
                <Button
                  key="clear.confirm"
                  kind="tertiary"
                  small
                  onClick={() => {
                    onClear();
                    setClearing(false);
                  }}
                >
                  {strings.confirm}
                </Button>
                <Button
                  key="clear.cancel"
                  kind="tertiary"
                  small
                  onClick={() => setClearing(false)}
                >
                  {strings.cancel}
                </Button>
              </>
            ) : (
              <>
                {/* <Button
                    key="base.toggle"
                    kind="tertiary"
                    small
                    onClick={() => setBase(!base)}
                    tabIndex={-1}
                  >
                    {base ? "Show entry value" : "Show base value"}
                  </Button> */}
                {base ? null : (
                  <>
                    {field.multi && field.type !== ModelFieldType.Entry ? (
                      <Button
                        small
                        key="addvalue"
                        kind="tertiary"
                        tabIndex={-1}
                        onClick={() => {
                          if (
                            value.filter((ent) => ent.modelFieldId === field.id)
                              .length === 0
                          ) {
                            onChange(
                              value
                                .concat([
                                  {
                                    modelFieldId: field.id,
                                  },
                                  {
                                    modelFieldId: field.id,
                                  },
                                ] as ValueField[])
                                .map((ent, index) => ({
                                  ...ent,
                                  index,
                                }))
                            );
                          } else {
                            onChange(
                              value
                                .concat({
                                  modelFieldId: field.id,
                                } as ValueField)
                                .map((ent, index) => ({
                                  ...ent,
                                  index,
                                }))
                            );
                          }
                        }}
                      >
                        Add value
                      </Button>
                    ) : null}
                    <Button
                      small
                      key="clear"
                      kind="tertiary"
                      onClick={() => setClearing(true)}
                      title="Reset to base values"
                      tabIndex={-1}
                    >
                      {strings.clear}
                    </Button>
                  </>
                )}
              </>
            )
          ) : null}
        </div>
      </div>
      {error ? (
        <Message colour="danger" size="small">
          {error.message}
        </Message>
      ) : null}

      {field.type === ModelFieldType.Entry ? (
        <InlineEntrySelector
          field={field}
          value={displayValues}
          onChange={onChange}
        />
      ) : (
        <ModuleEditContextProvider>
          {field.multi && displayValues.length > 1 ? (
            <DndContext
              collisionDetection={closestCenter}
              sensors={sensors}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              measuring={{
                droppable: {
                  strategy: MeasuringStrategy.Always,
                },
              }}
            >
              <SortableContext
                items={displayValues.map((ent, idx) => ent?.id ?? idx)}
                strategy={verticalListSortingStrategy}
              >
                <div className="value-editor__field-content">
                  {displayValues.map((ent) => (
                    <SortableItem key={ent.id} id={ent.id}>
                      {fieldEditor(field, ent)}
                    </SortableItem>
                  ))}
                </div>
              </SortableContext>
              <DragOverlay modifiers={[restrictToVerticalAxis]}>
                {active ? <div /> : null}
              </DragOverlay>
            </DndContext>
          ) : (
            fieldEditor(
              field,
              displayValues[0] ?? { modelFieldId: field.id, index: 0 }
            )
          )}
        </ModuleEditContextProvider>
      )}
    </div>
  );
};

const SortableItem: React.FC<React.PropsWithChildren<{ id: string }>> = ({
  id,
  children,
}) => {
  const sortable = useSortable({ id });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(sortable.transform),
    transition: sortable.transition || undefined,
  };

  return (
    <div
      className="module-editor__module-container"
      ref={sortable.setNodeRef}
      style={style}
    >
      <button
        className="module-editor__module-grab-handle"
        {...sortable.attributes}
        {...sortable.listeners}
      >
        <Icons.MoveIcon />
      </button>
      {children}
    </div>
  );
};
