import { useApolloClient } from "@apollo/client";
import {
  GetValueDocument,
  GetValueQuery,
  GetValueQueryVariables,
  IntRange,
  UpdateValueDocument,
  UpdateValueMutation,
  UpdateValueMutationVariables,
  Value,
  ValueField,
} from "api/graphql";
import * as React from "react";
import { useEnvironment } from "routes/accounts/projects/environments";
import { strings } from "strings";
import { buildDiff, valueEmpty, valueValid } from "./util";

type EditingValue = {
  id: string;
  value: Value | null;
  saving: boolean;
  fieldsClean: ValueField[];
  fields: ValueField[];
  parent?: string;
};

type FieldError = {
  value: string;
  field: string;
  message: string;
};

type ValueEditorContextState = "fresh" | "changed" | "saved"; // fresh = after load, changed = after change, saved = after save

export type ValueEditorContextProps = {
  errors: FieldError[];
  invalid: boolean;
  saving: boolean;
  state: ValueEditorContextState;
  values: EditingValue[];
  edit: (value: string) => void; // load value into context,
  save: () => Promise<void>;
  validate: () => boolean;
  findValue: (id: string) => EditingValue | undefined;
  onChange: (value: string, field: string, values: ValueField[]) => void;
  onClear: (value: string, field: string) => void;
};

export const ValueEditorContext =
  React.createContext<ValueEditorContextProps | null>(null);

// TODO: convert this to use reducer instead of states
export const ValueEditorContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const client = useApolloClient();
  const { project, environment, models } = useEnvironment();

  const [values, setValues] = React.useState<EditingValue[]>([]);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [saving, setSaving] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [state, setState] = React.useState<ValueEditorContextState>("fresh");

  const editValue = React.useCallback(
    (id: string, patch: (value: EditingValue) => EditingValue) =>
      setValues(values.map((ent) => (ent.id === id ? patch(ent) : ent))),
    [values]
  );

  const findValue = React.useCallback(
    (id: string) => values.find((ent) => ent.id === id),
    [values]
  );

  const handleChange = React.useCallback(
    (id: string, field: string, fieldValues: ValueField[]) => {
      editValue(id, (val) => ({
        ...val,
        fields: val.fields
          .filter((ent) => ent.modelFieldId !== field)
          .concat(fieldValues),
      }));
      setState("changed");
    },
    [editValue]
  );

  const edit = React.useCallback(
    async (value: string, parent?: string) => {
      if (!value) {
        return;
      }

      if (values.find((ent) => ent.id === value)) {
        return;
      }

      const res = await client.query<GetValueQuery, GetValueQueryVariables>({
        fetchPolicy: "network-only",
        query: GetValueDocument,
        variables: {
          id: value,
          project: project.id,
          environment: environment.id,
        },
      });

      setState("fresh");
      setValues((prevValues) => [
        ...prevValues,
        {
          id: value,
          saving: false,
          value: res.data.value as Value,
          fieldsClean: res.data.value?.fields.nodes as ValueField[],
          fields: res.data.value?.fields.nodes as ValueField[],
          parent,
        },
      ]);
    },
    [client, environment.id, project.id, values]
  );

  const validateValue = React.useCallback(
    (input: EditingValue): FieldError[] => {
      const model = models.find((mod) => mod.id === input.value?.modelId);
      const fields = model?.fieldsAll.nodes
        .filter((fld) => !Boolean(fld?.autoSource))
        .map((fld): FieldError | null => {
          const fieldValues = input.fields.filter(
            (ent) => ent.modelFieldId === fld?.id
          );

          if (fld?.required) {
            if (valueEmpty(fld, fieldValues)) {
              return {
                value: input.id,
                field: fld.id,
                message: strings.required,
              };
            }
          }

          if (fld?.config?.validation) {
            if (!valueValid(fld!, fieldValues)) {
              return {
                value: input.id,
                field: fld.id,
                message: `Field must respect a specific regular expression format: '${fld.config.validation}'`,
              };
            }
          }

          if (fld?.multi) {
            const formatMultiMessage = (
              range: IntRange,
              _count: number
            ): string => {
              if (range.start?.value && range.end?.value) {
                return `Please provide between ${range.start.value} and ${range.end.value} items`;
              } else if (range.start?.value) {
                return `Please provide at least ${range.start.value} item`;
              } else if (range.end?.value) {
                return `Please provide no more than ${range.end.value} items`;
              }

              return "";
            };
            if (fld.valueCount?.start?.value) {
              if (fieldValues.length < fld.valueCount.start.value) {
                return {
                  value: input.id,
                  field: fld.id,
                  message: formatMultiMessage(
                    fld.valueCount,
                    fieldValues.length
                  ),
                };
              }
            }
            if (fld.valueCount?.end?.value) {
              if (fieldValues.length > fld.valueCount.end.value + 1) {
                return {
                  value: input.id,
                  field: fld.id,
                  message: formatMultiMessage(
                    fld.valueCount,
                    fieldValues.length
                  ),
                };
              }
            }
          }

          return null;
        });

      return fields?.filter((ent) => ent !== null) as FieldError[];
    },
    [models]
  );

  const validate = React.useCallback((): boolean => {
    const validatedErrors = values
      .map(validateValue)
      .reduce((p, c) => [...p, ...c], []);

    setErrors(validatedErrors);
    setInvalid(validatedErrors.length > 0);

    return validatedErrors.length === 0;
  }, [validateValue, values]);

  const save = React.useCallback(async () => {
    setErrors([]);
    setInvalid(false);

    setSaving(true);
    try {
      const res = await Promise.all(
        values.map(async (val) => {
          const initialValue = val.fieldsClean ?? [];
          const { create, updates, deletes } = buildDiff(
            val.fields,
            initialValue,
            project.id,
            environment.id
          );

          if (create.length + updates.length + deletes.length === 0) {
            return;
          }

          const updated = await client.mutate<
            UpdateValueMutation,
            UpdateValueMutationVariables
          >({
            mutation: UpdateValueDocument,
            variables: {
              id: val.id,
              environment: environment.id,
              project: project.id,
              create,
              deletes,
              updates,
            },
          });

          return {
            val,
            updated,
          };
        })
      );

      setValues(
        values.map((val) => {
          const fields =
            (res.find((ent) => ent?.val.id === val.id)?.updated.data
              ?.updateValue?.value?.fields.nodes as ValueField[]) ?? val.fields;

          return {
            ...val,
            fields,
            fieldsClean: fields,
          };
        })
      );
      setState("saved");
    } finally {
      setSaving(false);
    }
  }, [client, environment.id, project.id, values]);

  const value: ValueEditorContextProps = React.useMemo(
    () => ({
      errors,
      invalid,
      saving,
      state,
      values,
      edit,
      findValue,
      onChange: handleChange,
      onClear: (value, field) => handleChange(value, field, []),
      save,
      validate,
    }),
    [
      edit,
      errors,
      findValue,
      handleChange,
      invalid,
      save,
      saving,
      state,
      validate,
      values,
    ]
  );

  return React.createElement(ValueEditorContext.Provider, { value }, children);
};

export const useValueEditorContext = (): ValueEditorContextProps => {
  const context = React.useContext(ValueEditorContext);

  if (context) {
    return context;
  }

  throw new Error("Value editor context missing");
};
