import * as React from "react";
import { FieldInputProps } from "components/components/fields/types";
import { Config } from ".";
import { EntryInputController } from "../../entries/selector/EntryInputController";
import { Entry } from "api/graphql";

export const EntryInput: React.FC<FieldInputProps<Config>> = ({
  config,
  value,
  disabled,
  readOnly,
  onChange,
  onRemove,
}) => (
  <EntryInputController
    allowed={config?.allowed}
    disabled={disabled}
    readOnly={readOnly}
    value={value?.valueEntryId}
    valueEntry={value?.valueEntryProjectEnvironment as Entry}
    onChange={(valueEntryId) => onChange({ ...value, valueEntryId })}
    onRemove={onRemove}
  />
);
