import * as React from "react";
import { EnvironmentImport } from "components/components/environments/import/EnvironmentImport";
import { ExportPayload } from "components/components/environments/export/EnvironmentExport";
import { useEnvironment } from "..";
import {
  EntryInput,
  ModelFieldModelFkeyModelFieldCreateInput,
  ModelInput,
  useCreateEntryMutation,
  useCreateModelMutation,
  useCreateModelParentMutation,
  useCreateValueRawMutation,
} from "api/graphql";

export const EnvImportPage: React.FC = () => {
  const { models, project, environment } = useEnvironment();

  const [createModel] = useCreateModelMutation();
  const [createModelParent] = useCreateModelParentMutation();
  const [createValueRaw] = useCreateValueRawMutation();
  const [createEntry] = useCreateEntryMutation();

  const handleImport = async (payload: ExportPayload) => {
    for (const model of payload.models) {
      await createModel({
        variables: {
          input: {
            projectId: project.id,
            environmentId: environment.id,
            id: model.id,
            alias: model.alias,
            name: model.name,
            usage: model.usage,
            fields: {
              create: model.fields.map(
                (fld) =>
                  ({
                    alias: fld.alias,
                    id: fld.id,
                    name: fld.name,
                    config: fld.config,
                    index: fld.index,
                    multi: fld.multi,
                    required: fld.required,
                    type: fld.type,
                    valueCount: fld.valueCount,
                  } as ModelFieldModelFkeyModelFieldCreateInput)
              ),
            },
          } as ModelInput,
        },
      });
    }

    for (const model of payload.models) {
      if (model.parents.length > 0) {
        for (const modelParent of model.parents) {
          await createModelParent({
            variables: {
              input: {
                projectId: project.id,
                environmentId: environment.id,
                modelId: model.id,
                parentId: modelParent,
              },
            },
          });
        }
      }
    }

    for (const value of payload.values) {
      await createValueRaw({
        variables: {
          input: {
            projectId: project.id,
            environmentId: environment.id,
            id: value.id!,
            modelId: value.modelId,
            fields: {
              create: value.fields.nodes.map((fld) => ({
                index: fld?.index!,
                hint: fld?.hint!,
                id: fld?.id!,
                modelFieldId: fld?.modelFieldId!,
                valueEntryId: fld?.valueEntryId,
                valueMediaId: fld?.valueMediaId,
                valueValueId: fld?.valueValueId,
                valueScalar: fld?.valueScalar,
              })),
            },
          },
        },
      });
    }

    for (const entry of payload.entries) {
      await createEntry({
        variables: {
          input: {
            projectId: project.id,
            environmentId: environment.id,
            id: entry.id!,
            modelId: entry.modelId,
            name: entry.name,
            versions: {
              deleteOthers: true,
              create: entry.versions.nodes.map((ver) => ({
                id: ver?.id!,
                createdAt: ver?.createdAt,
                modifiedAt: ver?.modifiedAt,
                valueId: ver?.valueId,
              })),
            },
          } as EntryInput,
        },
      });
    }
  };

  return <EnvironmentImport models={models} onImport={handleImport} />;
};
