import * as React from "react";
import { useNavigate } from "react-router";
import {
  Webhook,
  useListWebhooksQuery,
  useCreateWebhookRunMutation,
} from "api/graphql";
import { useProject } from "routes/accounts/projects";

type WebhooksContextProps = {
  webhooks: Webhook[];
  loading: boolean;
  reload: () => void;
  trigger: (hook: Webhook, redirect: boolean) => () => void;
};

const WebhooksContext = React.createContext<WebhooksContextProps>({
  webhooks: [],
  loading: false,
  reload: () => null,
  trigger: () => () => null,
});

export const WebhooksProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { project, account } = useProject();
  const navigate = useNavigate();

  const query = useListWebhooksQuery({ variables: { project: project.id } });
  const [createRun, createRunStatus] = useCreateWebhookRunMutation();

  const trigger = (hook: Webhook, redirect: boolean) => async () => {
    const res = await createRun({ variables: { webhook: hook.id } });

    if (redirect) {
      navigate(
        `/accounts/${account.key}/projects/${project.key}/webhooks/${hook.id}/runs/${res.data?.createWebhookRun?.webhook?.id}`
      );
    }
  };

  const value: WebhooksContextProps = {
    webhooks: (query.data?.list?.nodes ?? []) as Webhook[],
    loading: query.loading || createRunStatus.loading,
    reload: () => query.refetch(),
    trigger,
  };

  return React.createElement(WebhooksContext.Provider, {
    value,
    children,
  });
};

export const useWebhooks = () =>
  React.useContext<WebhooksContextProps>(WebhooksContext);
