import * as React from "react";
import cx from "classnames";
import {
  Transforms,
  createEditor,
  Editor,
  Node,
  Range,
  Element as SlateElement,
} from "slate";
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlate,
  withReact,
} from "slate-react";
import { withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import { Select } from "pokko-shared";
import { FieldInputProps } from "../../types";
import { useEnvironment } from "routes/accounts/projects/environments";
import { ValueEditor } from "components/components/values/editor/ValueEditor";
import { ModelUsage, useCreateValueMutation } from "api/graphql";

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const emptyValue: Node[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const Richtext: React.FC<FieldInputProps> = ({
  value,
  onChange,
  disabled,
  readOnly,
}) => {
  const { models, project, environment } = useEnvironment();
  const editor = React.useMemo(
    () => withReact(withHistory(withLinks(createEditor()))),
    []
  );
  const [createValue] = useCreateValueMutation();

  const slateValue: Node[] = value.valueScalar?.richtext || emptyValue;

  const [model, setModel] = React.useState<string | undefined>();

  const handleEmbed = async () => {
    const res = await createValue({
      variables: { model, project: project.id, environment: environment.id },
    });
    if (res.data?.create?.entity?.id) {
      Transforms.insertNodes(editor, [
        {
          type: "module",
          value: res.data?.create?.entity?.id,
          children: [{ text: "" }],
        },
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ]);
    }
  };

  const moduleModels = models.filter(
    (mod) => !Boolean(mod.deletedAt) && mod.usage === ModelUsage.Module
  );

  return (
    <div className="input richtext">
      <Slate
        editor={editor}
        value={slateValue}
        onChange={(richtext) => {
          if (value.valueScalar?.richtext !== richtext) {
            onChange({ ...value, valueScalar: { richtext } });
          }
        }}
      >
        <div className="richtext__toolbar">
          <MarkButton format="bold" icon={icons.format_bold} />
          <MarkButton format="italic" icon={icons.format_italic} />
          <MarkButton format="underline" icon={icons.format_underline} />
          <MarkButton format="code" icon={icons.format_code} />
          <BlockButton format="heading-one" icon={icons.format_h1} />
          <BlockButton format="heading-two" icon={icons.format_h2} />
          <BlockButton format="block-quote" icon={icons.format_blockquote} />
          <BlockButton format="numbered-list" icon={icons.format_ol} />
          <BlockButton format="bulleted-list" icon={icons.format_ul} />
          <BlockButton format="link" icon="Link" />
          {moduleModels.length > 0 ? (
            <div className="richtext__toolbar--embed">
              <Select value={model} onChangeText={setModel}>
                <option />
                {moduleModels.map((mod) => (
                  <option key={mod.id} value={mod.id}>
                    {mod.name}
                  </option>
                ))}
              </Select>
              <button onClick={handleEmbed}>Embed module</button>
            </div>
          ) : null}
        </div>
        <Editable
          className="richtext__content"
          disabled={disabled}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          onKeyDown={(event: any) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};

const renderElement = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link":
      return (
        <a {...attributes} href={element.href as string}>
          {children}
        </a>
      );
    case "paragraph":
      return <p {...attributes}>{children}</p>;
    case "module":
      if (element.value) {
        return (
          <div className="richtext__module" contentEditable={false}>
            <ValueEditor id={element.value as string} />
          </div>
        );
      }

      return <div contentEditable={false}>module: invalid.</div>;
    default:
      return <code>unknown element</code>;
  }
};

const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

type BlockButtonProps = { format: string; icon: React.ReactNode };
const BlockButton: React.FC<BlockButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const handleClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault();
    toggleBlock(editor, format);
  };
  return (
    <button
      className={cx({ "--active": isBlockActive(editor, format) })}
      onClick={handleClick}
    >
      <i>{icon}</i>
    </button>
  );
};

type MarkButtonProps = { format: string; icon: React.ReactNode };
const MarkButton: React.FC<MarkButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const handleClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault();
    toggleMark(editor, format);
  };
  return (
    <button
      className={cx({ "--active": isMarkActive(editor, format) })}
      onClick={handleClick}
    >
      {icon}
    </button>
  );
};

const toggleBlock = (editor: Editor, format: string) => {
  if (format === "link") {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    } else {
      const href = prompt("Link target");
      if (href) {
        insertLink(editor, href);
      }
    }
  } else if (format === "module") {
    Transforms.insertNodes(editor, {
      type: "module",
      children: [{ text: "Embedded module" }],
    });
    return;
  }
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as any
      ),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const isBlockActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  }) as any;

  return !!match;
};

const insertLink = (editor: Editor, href: string) => {
  if (editor.selection) {
    wrapLink(editor, href);
  }
};

const isLinkActive = (editor: Editor) => {
  const [link] = Array.from(
    Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    })
  );
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  const newProperties: Partial<SlateElement> = { type: "paragraph" };
  Transforms.setNodes(editor, newProperties);
};

const wrapLink = (editor: Editor, href: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
    return;
  }

  const { selection } = editor;

  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    href,
    children: isCollapsed ? [{ text: href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const withLinks = (editor: Editor): Editor => {
  const { isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  return editor;
};

const icons = {
  format_bold: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80005 11.5994V2.401C2.80005 1.99966 4.48005 1.99966 5.60005 2.401V11.5994C4.48005 12.0002 2.80005 12.0002 2.80005 11.5994Z"
        fill="black"
      />
      <path
        d="M9.92617 6.27811C10.5033 5.76495 10.8685 5.02473 10.8685 4.2C10.8685 2.65593 9.59555 1.4 8.03168 1.4H2.61589C2.33066 1.4 2.1001 1.62807 2.1001 1.90909C2.1001 2.19011 2.33066 2.41818 2.61589 2.41818H7.0001C7.99557 2.41818 8.80536 3.21746 8.80536 4.2C8.80536 5.18255 7.99557 5.98182 7.0001 5.98182H4.16326C3.87802 5.98182 3.64747 6.20989 3.64747 6.49091C3.64747 6.77193 3.87802 7 4.16326 7H6.48431H7.0001C8.27977 7 9.32115 8.02786 9.32115 9.29091C9.32115 10.554 8.27977 11.5818 7.0001 11.5818H2.61589C2.33066 11.5818 2.1001 11.8099 2.1001 12.0909C2.1001 12.3719 2.33066 12.6 2.61589 12.6H8.54747C10.3961 12.6 11.9001 11.1155 11.9001 9.29091C11.9001 7.95149 11.0882 6.7984 9.92617 6.27811Z"
        fill="black"
      />
    </svg>
  ),
  format_italic: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7101 1.95858L5.3101 11.7586L7.29 12.0414L8.69 2.24142L6.7101 1.95858Z"
        fill="black"
      />
      <path
        d="M4.8999 11.9H7.6999M6.2999 2.1H9.0999"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  format_underline: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80005 13.3H11.2"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2 2.1H9.10005V8.53642C9.10005 9.67322 8.301 10.6902 7.21005 10.8002C5.95792 10.9271 4.90005 9.90624 4.90005 8.63333V2.1H2.80005V7.54444C2.80005 9.81478 4.58977 11.9 7.00005 11.9C9.41032 11.9 11.2 9.81478 11.2 7.54444V2.1Z"
        fill="black"
      />
      <path
        d="M2.80005 2.1H4.90005M9.10005 2.1H11.2H9.10005Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  format_h1: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6665 15.6667H11.6665M1.6665 10.3333H9.6665H1.6665ZM-0.333496 5H3.6665H-0.333496ZM-0.333496 15.6667H3.6665H-0.333496ZM7.6665 5H11.6665H7.6665Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 5.66667V15M1.6665 5.66667V15V5.66667Z"
        stroke="black"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.6258 8.474H16.5031L14.2524 9.994V7.95L16.6258 6.33333H18.9998V15.6667H16.6258V8.474Z"
        fill="black"
      />
    </svg>
  ),
  format_code: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 3.33333L8.66667 16.6667M15.3333 5.99999L18.162 10L15.3333 14M4.82867 14L2 10L4.82867 5.99999"
        stroke="black"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  format_h2: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 14.6667H4M3.33333 10H9.33333H3.33333ZM2 5.33333H4H2Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.66667V14.3333"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.6665 14.6667H10.6665M8.6665 5.33333H10.6665H8.6665Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 5.66667V14.3333"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.9999 6C17.7433 6 18.9093 6.934 18.9093 8.31067C18.9093 9.17467 18.4019 9.94067 17.0199 11.1713L15.7353 12.3533V12.456H19.0173V14H13.0953V12.6773L15.5566 10.3613C16.7013 9.30333 16.9926 8.90933 16.9926 8.45067C16.9926 7.87867 16.5713 7.49533 15.9293 7.49533C15.2599 7.49533 14.7959 7.94867 14.7959 8.59133V8.62933H12.9819V8.59667C12.9826 7.04733 14.1913 6 15.9999 6Z"
        fill="black"
      />
    </svg>
  ),
  format_blockquote: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 12H6.3335M11.3335 8.66666H6.3335M16.6668 5.33333H6.3335M3 5.33333V15.3333M11.3335 15.3333H6.3335"
        stroke="black"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  format_ol: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 15.3333H7.3335M16.6668 4.66667H7.3335H16.6668ZM16.6668 10H7.3335H16.6668Z"
        stroke="black"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64912 2.94466H3.59646L2.63179 3.596V2.72066L3.64912 2.02733H4.66646V6.02733H3.64912V2.94466ZM3.99979 8C4.87179 8 5.45446 8.46666 5.45446 9.15533C5.45446 9.58733 5.20046 9.97066 4.50979 10.586L3.86713 11.1773V11.2287H5.50846V12H2.54779V11.3387L3.77846 10.1807C4.35046 9.65133 4.49646 9.45466 4.49646 9.22533C4.49646 8.93933 4.28579 8.74733 3.96446 8.74733C3.62979 8.74733 3.39779 8.974 3.39779 9.29533V9.314H2.49112V9.298C2.49112 8.52333 3.09579 8 3.99979 8ZM3.50779 14.9587H3.97312C4.29646 14.9587 4.50979 14.7773 4.50979 14.5013C4.50979 14.2253 4.30179 14.054 3.96512 14.054C3.63379 14.054 3.41046 14.2513 3.39979 14.5487H2.52446C2.53779 13.8067 3.10579 13.3333 3.98646 13.3333C4.82779 13.3333 5.40646 13.7567 5.40646 14.3693C5.40646 14.806 5.11446 15.1507 4.68846 15.2293V15.2793C5.21179 15.3347 5.54579 15.6793 5.54579 16.168C5.54579 16.8547 4.89912 17.3333 3.97046 17.3333C3.08446 17.3333 2.48713 16.8493 2.45312 16.1053H3.37379C3.38712 16.3947 3.62112 16.5733 3.98646 16.5733C4.32846 16.5733 4.56512 16.3787 4.56512 16.0947C4.56512 15.8027 4.34179 15.624 3.97312 15.624H3.50779V14.9587Z"
        fill="black"
      />
    </svg>
  ),
  format_ul: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 15.3333H8M16.6667 4.66667H8H16.6667ZM16.6667 10H8H16.6667Z"
        stroke="black"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99984 8.66666C3.64622 8.66666 3.30708 8.80714 3.05703 9.05719C2.80698 9.30723 2.6665 9.64637 2.6665 10C2.6665 10.3536 2.80698 10.6928 3.05703 10.9428C3.30708 11.1929 3.64622 11.3333 3.99984 11.3333C4.35346 11.3333 4.6926 11.1929 4.94265 10.9428C5.19269 10.6928 5.33317 10.3536 5.33317 10C5.33317 9.64637 5.19269 9.30723 4.94265 9.05719C4.6926 8.80714 4.35346 8.66666 3.99984 8.66666ZM3.99984 3.33333C3.64622 3.33333 3.30708 3.4738 3.05703 3.72385C2.80698 3.9739 2.6665 4.31304 2.6665 4.66666C2.6665 5.02028 2.80698 5.35942 3.05703 5.60947C3.30708 5.85952 3.64622 5.99999 3.99984 5.99999C4.35346 5.99999 4.6926 5.85952 4.94265 5.60947C5.19269 5.35942 5.33317 5.02028 5.33317 4.66666C5.33317 4.31304 5.19269 3.9739 4.94265 3.72385C4.6926 3.4738 4.35346 3.33333 3.99984 3.33333ZM3.99984 14C3.64622 14 3.30708 14.1405 3.05703 14.3905C2.80698 14.6406 2.6665 14.9797 2.6665 15.3333C2.6665 15.687 2.80698 16.0261 3.05703 16.2761C3.30708 16.5262 3.64622 16.6667 3.99984 16.6667C4.35346 16.6667 4.6926 16.5262 4.94265 16.2761C5.19269 16.0261 5.33317 15.687 5.33317 15.3333C5.33317 14.9797 5.19269 14.6406 4.94265 14.3905C4.6926 14.1405 4.35346 14 3.99984 14Z"
        fill="black"
      />
    </svg>
  ),
};
