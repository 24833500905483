import * as React from "react";
import { ApiTokens } from "./components/ApiTokens";
import { Environments } from "./components/Environments";
import { Rename } from "./components/Rename";
import { PublishTargets } from "./components/PublishTargets";
import { LinkButton, Icons } from "pokko-shared";

export const endpointStr = (project: string, environment: string) =>
  `${window.location.protocol}//${window.location.host}/${project}/${environment}/graphql`;

export type ApiToken = {
  id: string;
  token: string;
  target: string;
  created: Date;
};

export type EnvironmentInput = {
  name: string;
};

export type Environment = {
  id: string;
  key: string;
  name: string;
  value: EnvironmentInput;
  isDefault: boolean;
  save: [(value: EnvironmentInput) => void, { loading: boolean }];
  makeDefault: [() => void, { loading: boolean }];
  archive: [() => void, { loading: boolean }];
  clearCache: [() => void, { loading: boolean }];

  websites: Website[];
  createWebsite: [() => void, { loading: boolean }];
};

export type PublishTargetInput = {
  name: string;
};

export type PublishTarget = {
  id: string;
  name: string;
  key: string;
  value: PublishTargetInput;
  save: [(value: PublishTargetInput) => void, { loading: boolean }];
  republish: [() => void, { loading: boolean }];
  updatePaths: [() => void, { loading: boolean }];
};

export type WebsiteInput = {
  name: string;
  target: string;
  urlFormat: string;
};

export type Website = {
  environment: string;
  id: string;
  name: string;
  path?: string[];
  value: WebsiteInput;
  save: [(value: WebsiteInput) => void, { loading: boolean }];
};

export type ProjectSettingsProps = {
  name: string;
  projectKey: string;
  accountKey: string;
  archived: boolean;
  apiTokens: ApiToken[];
  environments: Environment[];
  publishTargets: PublishTarget[];
  project: string;
  environment: string;
  revokeToken: [(id: string) => void, { loading: boolean }];
  createToken: [() => void, { loading: boolean }];
  updateToken: [(id: string, target: string) => void, { loading: boolean }];
  createEnvironment: [() => void, { loading: boolean }];
  createPublishTarget: [() => void, { loading: boolean }];
};

export const ProjectSettings: React.FC<ProjectSettingsProps> = (props) => {
  const { name } = props;

  return (
    <div className="project-settings__container">
      <div className="project-settings__header">
        <h3 className="h3">{name} settings</h3>
        <LinkButton
          small
          kind="tertiary"
          to=".."
          icons={{ left: <Icons.BackIcon /> }}
        >
          Back to project
        </LinkButton>
      </div>
      <Rename {...props} />
      <hr />
      <ApiTokens {...props} />
      <hr />
      <Environments {...props} />
      <hr />
      <PublishTargets {...props} />
    </div>
  );
};
