import * as React from "react";
import { useForm } from "react-hook-form";
import { Field, Label, Input, Button, Message, LinkButton } from "pokko-shared";
import {
  Environment,
  EnvironmentInput,
  ProjectSettingsProps,
} from "../ProjectSettings";
import { Tag } from "components/elements/tag/Tag";
import { strings } from "strings";
import { Websites } from "./Websites";

const EnvironmentDetail: React.FC<{ environment: Environment }> = (props) => {
  const {
    environment: {
      isDefault,
      value,
      key,
      save: [save, saveStatus],
      clearCache: [clearCache, clearCacheStatus],
      makeDefault: [makeDefault, makeDefaultStatus],
      archive: [archive, archiveStatus],
    },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EnvironmentInput>({
    defaultValues: value,
  });

  return (
    <div className="project-settings__environment-detail">
      <form className="project-settings__name" onSubmit={handleSubmit(save)}>
        <Field>
          <Label>Environment name</Label>
          <div className="project-settings__field-inline">
            <Input {...register("name", { required: strings.required })} />
            <Button kind="secondary" type="submit" loading={saveStatus.loading}>
              Rename
            </Button>
          </div>
          {errors.name?.message ? (
            <Message size="small" colour="danger">
              {errors.name.message}
            </Message>
          ) : null}
        </Field>
      </form>
      <div className="project-settings__environment-actions">
        <Button
          kind="tertiary"
          type="button"
          onClick={makeDefault}
          loading={makeDefaultStatus.loading}
          disabled={isDefault}
        >
          Make default
        </Button>
        <Button
          kind="secondary"
          type="button"
          onClick={clearCache}
          loading={clearCacheStatus.loading}
        >
          Clear cache
        </Button>
        <LinkButton kind="secondary" to={`../environments/${key}/export`}>
          Export
        </LinkButton>
        <LinkButton kind="secondary" to={`../environments/${key}/import`}>
          Import
        </LinkButton>
        <Button
          type="button"
          onClick={archive}
          loading={archiveStatus.loading}
          disabled={isDefault}
        >
          Archive
        </Button>
      </div>

      <hr />
      <Websites {...props.environment} />
    </div>
  );
};

export const Environments: React.FC<ProjectSettingsProps> = ({
  environments,
  createEnvironment: [createEnvironment, createEnvironmentStatus],
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleToggle = (id: string) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((ent) => ent !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };
  return (
    <div className="project-settings__environments">
      <div className="project-settings__environments-header">
        <h4 className="header">Environments</h4>
      </div>
      <ul className="project-settings__environment-list">
        {environments.map((ent) => (
          <li key={ent.id}>
            <button
              className="project-settings__environment-name"
              onClick={() => handleToggle(ent.id)}
            >
              <strong>{ent.name}</strong>
              {ent.isDefault ? <Tag>Default</Tag> : <span />}
            </button>
            {expanded.includes(ent.id) ? (
              <EnvironmentDetail environment={ent} />
            ) : null}
          </li>
        ))}
      </ul>
      <div className="project-settings__environment-actions">
        <Button
          type="button"
          onClick={createEnvironment}
          loading={createEnvironmentStatus.loading}
          kind="primary"
        >
          New environment
        </Button>
      </div>
    </div>
  );
};
