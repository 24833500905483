import * as React from "react";
import { useNavigate } from "react-router";
import { useCreateWebhookMutation, useListWebhooksQuery } from "api/graphql";
import { WebhookList } from "components/components/webhooks/list/WebhookList";
import { useProject } from "..";

export const WebhookListController: React.FC = () => {
  const { project } = useProject();
  const navigate = useNavigate();
  const query = useListWebhooksQuery({
    variables: {
      project: project.id,
    },
  });

  const [create, createStatus] = useCreateWebhookMutation();

  const handleCreate = async () => {
    const name = window.prompt("Webhook name");
    if (name) {
      const res = await create({
        variables: {
          input: {
            name,
            url: "",
            method: "POST",
            projectId: project.id,
          },
        },
      });

      await query.refetch();

      navigate(res.data?.create?.entity?.id);
    }
  };

  return (
    <WebhookList
      create={[handleCreate, createStatus]}
      webhooks={
        query.data?.list?.nodes.map((ent) => ({
          id: ent?.id!,
          name: ent?.name!,
          lastRun:
            (ent?.webhookRuns.nodes.length ?? 0) > 0
              ? new Date(ent?.webhookRuns.nodes[0]!.createdAt + "Z")
              : null,
          url: ent?.id!,
        })) ?? []
      }
    />
  );
};
