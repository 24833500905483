import * as React from "react";
import { useNavigate } from "react-router";
import { useCreateEntryMutation } from "api/graphql";
import {
  EntryCreate,
  EntryCreateInput,
} from "components/components/entries/create/EntryCreate";
import { useEnvironment } from "../..";
import { useLocation } from "react-router";

export type EntryCreatePageProps = {};

export const EntryCreatePage: React.FC<EntryCreatePageProps> = () => {
  const [create, createStatus] = useCreateEntryMutation();
  const { project, environment, models } = useEnvironment();
  const navigate = useNavigate();
  const { search } = useLocation();
  const qs = new URLSearchParams(search);

  const handleSave = async (value: EntryCreateInput) => {
    const res = await create({
      variables: {
        input: {
          name: value.name,
          modelId: value.model,
          projectId: project.id,
          environmentId: environment.id,
        },
      },
    });

    navigate(`../${res.data?.create?.entity?.id}`);
  };

  return (
    <EntryCreate
      models={models.filter((ent) => !ent.deletedAt)}
      onSave={handleSave}
      loading={createStatus.loading}
      defaultValues={{
        name: "",
        model: qs.get("model") ?? "",
      }}
    />
  );
};
