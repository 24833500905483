import * as React from "react";
import { AppRoutes } from "routes";
import { Providers } from "providers";

import "./components/base.scss";

export const App: React.FC = () => (
  <Providers>
    <React.Suspense fallback={<small>Loading</small>}>
      <AppRoutes />
    </React.Suspense>
  </Providers>
);
