import * as React from "react";
import { Button } from "pokko-shared";
import { endpointStr, ProjectSettingsProps } from "../ProjectSettings";

const usageStr = (project: string, environment: string, token: string) =>
  `
import {
  ApolloClient,
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

const options: ApolloClientOptions<NormalizedCacheObject> = {
  cache: new InMemoryCache(),

  headers: {
    "X-Token": "${token}",
  },

  uri: "${endpointStr(project, environment)}",
};

export const client = new ApolloClient(options);
`.trim();

export const ApiTokens: React.FC<ProjectSettingsProps> = ({
  apiTokens,
  publishTargets,
  createToken: [createToken, createTokenStatus],
  revokeToken: [revokeToken, revokeTokenStatus],
  updateToken: [updateToken, updateTokenStatus],
  project,
  environment,
  environments,
}) => {
  const [usage, setUsage] = React.useState(false);

  return (
    <div className="project-settings__tokens">
      <div className="project-settings__tokens-header">
        <h4 className="header">API Tokens</h4>
        <div className="project-settings__tokens-header-actions">
          <button onClick={() => setUsage(!usage)}>
            {usage ? "Back" : "Usage"}
          </button>
        </div>
      </div>
      {usage ? (
        <div className="project-settings__tokens-usage">
          <p>See also the usage section on each model page.</p>
          <pre>
            {usageStr(
              project,
              environment,
              apiTokens.length > 0 ? apiTokens[0].token : "..."
            )}
          </pre>
        </div>
      ) : (
        <>
          {apiTokens.length === 0 ? (
            <p>There are no active API tokens with access to this project.</p>
          ) : (
            <ul className="project-settings__tokens-list">
              {apiTokens.map((ent) => (
                <li key={ent.id}>
                  <code>{ent.token}</code>
                  <select
                    value={ent.target}
                    disabled={updateTokenStatus.loading}
                    onChange={(ev) =>
                      updateToken(ent.id, ev.currentTarget.value)
                    }
                  >
                    {publishTargets.map((pub) => (
                      <option key={pub.id} value={pub.id}>
                        {pub.name}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() => revokeToken(ent.id)}
                    disabled={revokeTokenStatus.loading}
                  >
                    Revoke
                  </button>
                </li>
              ))}
            </ul>
          )}
          <div className="project-settings__tokens-actions">
            <Button
              type="button"
              onClick={createToken}
              loading={createTokenStatus.loading}
              kind="primary"
            >
              New token
            </Button>
            <a
              className="button --secondary"
              href={endpointStr(project, environment)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="button__content">GraphQL Playground</div>
            </a>
          </div>
        </>
      )}
    </div>
  );
};
