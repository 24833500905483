import * as React from "react";
import { EntriesOrderBy } from "api/graphql";
import { PaginationProps } from "components/elements/pagination/Pagination";
import { EntryListFilters } from "./components/EntryListFilters";
import { EntryListResults } from "./components/EntryListResults";
import { Link } from "react-router-dom";

export type EntryListProps = {
  search: {
    value: string;
    onChange: (value: string) => void;
  };
  models: {
    list: { id: string; name: string }[];
    value: string[];
    onChange: (value: string[]) => void;
  };
  results?: {
    list: {
      id: string;
      name: string;
      published: boolean;
      modified: Date;
      model: { alias: string; name: string };
      url: string;
    }[];
    sort: {
      value: EntriesOrderBy;
      onChange: (value: EntriesOrderBy) => void;
    };
    pagination: PaginationProps;
    setPageSize: (value: number) => void;
  };
};

export const EntryList: React.FC<EntryListProps> = (props) => {
  if (props.models.list.length === 0) {
    return (
      <p>
        There are no models in this project yet,{" "}
        <Link to="../models/create">create one</Link> to get started.
      </p>
    );
  }

  return (
    <div className="entry-list__container">
      <EntryListResults {...props} />
      <EntryListFilters {...props} />
    </div>
  );
};
