import * as React from "react";
import {
  EntriesOrderBy,
  EntryFilter,
  ModelUsage,
  useListEntriesQuery,
} from "api/graphql";
import { useEnvironment } from "../..";
import { EntryList } from "components/components/entries/list/EntryList";
import { buildPaginationProps } from "components/elements/pagination/Pagination";
import { useDebounce } from "use-debounce";

export const buildFilter = (
  search: string,
  selected: string[]
): EntryFilter => {
  const and: EntryFilter[] = [{ model: { deletedAt: { isNull: true } } }];

  if (search) {
    and.push({ name: { likeInsensitive: `%${search}%` } });
  }

  if (selected.length > 0) {
    and.push({
      or: [
        { modelId: { in: selected } },
        { model: { inherits: { overlaps: selected } } },
      ],
    });
  }

  return { and };
};

export const EntryListPage: React.FC = () => {
  const { project, environment, models } = useEnvironment();
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(8);
  const [search, setSearch] = React.useState("");
  const [sort, setSort] = React.useState<EntriesOrderBy>(
    EntriesOrderBy.ModifiedAtDesc
  );
  const [selected, setSelected] = React.useState<string[]>([]);

  const [filter] = useDebounce(buildFilter(search, selected), 250, {
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  const query = useListEntriesQuery({
    variables: {
      project: project.id,
      environment: environment.id,
      skip,
      take,
      filter,
      sort: [sort],
    },
    fetchPolicy: "cache-and-network",
    skip: models.length === 0,
  });

  React.useEffect(() => setSkip(0), [filter]);

  return (
    <EntryList
      search={{ value: search, onChange: setSearch }}
      models={{
        list: models
          .filter((ent) => ent.usage === ModelUsage.Entry)
          .filter((ent) => !ent.deletedAt),
        onChange: setSelected,
        value: selected,
      }}
      results={{
        sort: {
          value: sort,
          onChange: setSort,
        },
        list:
          query.data?.list?.nodes.map((ent) => ({
            id: ent?.id!,
            model: {
              name: models.find((mod) => mod.id === ent?.modelId)?.name!,
              alias: ent?.modelId!,
            },
            modified: new Date(ent?.modifiedAt! + "Z"),
            name: ent?.name!,
            published: (ent?.entryPublishes?.totalCount ?? 0) > 0,
            url: [ent?.id, "versions", ent?.latestVersionId].join("/"),
          })) ?? [],
        pagination: buildPaginationProps(
          skip,
          query.data?.list?.nodes.length ?? 0,
          query.data?.list?.totalCount ?? 0,
          () => setSkip(skip + take),
          () => setSkip(skip - take)
        ),
        setPageSize: (val) => {
          if (val !== take) {
            setTake(val);
          }
        },
      }}
    />
  );
};
