import { TreeState, TreeAction, TreeNodeState } from "./types";

export const reducer = <TNode>(
  state: TreeState<TNode>,
  action: TreeAction<TNode>
): TreeState<TNode> => {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        nodes2: action.nodes2,
      };
    case "load_children": {
      if (action.id) {
        return {
          ...state,
          nodes2: state.nodes2.map((ent) =>
            ent.id === action.id ? { ...ent, loading: true } : ent
          ),
        };
      }
      return state;
    }
    case "load_children_success": {
      const parent = state.nodes2.find((ent) => ent.id === action.id);
      return {
        ...state,
        nodes2: [
          ...state.nodes2
            .filter(
              (ent) =>
                !Boolean(action.result.nodes.find((x) => x.id === ent.id)) // can happen if nodes are moved
            )
            .map((ent) =>
              ent.id === action.id
                ? {
                    ...ent,
                    loaded: true,
                    loading: false,
                    expanded: true,
                    hasMore: action.result.hasMore,
                  }
                : ent
            ),
          ...action.result.nodes.map(
            (ent) =>
              ({
                id: ent.id,
                loaded: false,
                loading: false,
                expanded: false,
                level: parent ? parent.level + 1 : 0,
                data: ent,
                parent: action.id,
                nodes: [],
                childCount: ent.childCount,
                hasMore: false,
                path: [...(parent ? parent.path : []), ent.id],
              } as TreeNodeState<TNode>)
          ),
        ],
      };
    }
    case "expand":
    case "collapse": {
      return {
        ...state,
        nodes2: state.nodes2.map((ent) =>
          ent.id === action.id ? { ...ent, expanded: !ent.expanded } : ent
        ),
      };
    }
    case "node_moved": {
      const node = state.nodes2.find((ent) => ent.id === action.id)!;
      const parent = action.parent
        ? state.nodes2.find((ent) => ent.id === action.parent)
        : null;

      return {
        ...state,
        nodes2: state.nodes2.map((ent) => {
          if (ent.id === action.id) {
            return {
              ...ent,
              parent: action.parent,
              level: parent ? parent.level + 1 : 0,
              path: [...(parent ? parent.path : []), ent.id],
            };
          }

          if (ent.id === action.parent) {
            // update the new parent
            return { ...ent, childCount: ent.childCount + 1 };
          }

          if (ent.id === node.parent) {
            // update the old parent
            return { ...ent, childCount: ent.childCount - 1 };
          }
          return ent;
        }),
      };
    }
    case "node_updated": {
      return {
        ...state,
        nodes2: state.nodes2.map((ent) =>
          ent.id === action.id ? { ...ent, data: action.data } : ent
        ),
      };
    }
    case "node_created": {
      const parent = action.parent
        ? state.nodes2.find((ent) => ent.id === action.parent)
        : null;
      return {
        ...state,
        nodes2: [
          ...state.nodes2.map((ent) =>
            ent.id === action.parent
              ? { ...ent, childCount: ent.childCount + 1 }
              : ent
          ),
          {
            id: action.id,
            parent: action.parent,
            childCount: 0,
            data: action.node,
            expanded: false,
            level: parent ? parent.level + 1 : 0,
            hasMore: false,
            loaded: false,
            loading: false,
            path: [...(parent ? parent.path : []), action.id],
          },
        ],
      };
    }
    case "node_deleted": {
      return {
        ...state,
        nodes2: state.nodes2.filter((ent) => ent.id !== action.id),
      };
    }
    case "order_updated": {
      return {
        ...state,
        nodes2: [
          ...state.nodes2.filter((ent) => ent.parent !== action.parent),
          ...state.nodes2
            .filter((ent) => ent.parent === action.parent)
            .sort(action.comparer),
        ],
      };
    }
  }

  return state;
};
