export const sortBy = <T>(key: keyof T) => (a: T, b: T) =>
  a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;

export const groupBy = <TElement, TKey = any>(
  input: TElement[],
  key: (ent: TElement) => TKey
): Map<TKey, TElement[]> =>
  input.reduce((p, c) => {
    const k = key(c);
    const ext = p.get(k) ?? [];

    return p.set(k, ext.concat([c]));
  }, new Map());

export const fileSize = (input?: number): string | null => {
  if (!input) {
    return null;
  }

  const inputClean = parseInt(input.toString(), 10);

  if (inputClean < 1024) {
    return `${inputClean.toFixed(0)} bytes`;
  } else if (inputClean < 1024 * 1024) {
    return `${(inputClean / 1024).toFixed(0)} kB`;
  } else if (inputClean < 1024 * 1024 * 1024) {
    return `${(inputClean / 1024 / 1024).toFixed(2)} MB`;
  } else {
    return `${Math.floor(inputClean / 1024 / 1024 / 1024)} GB`;
  }
};

export const duration = (input?: string): string | null => {
  if (!input) {
    return null;
  }

  const inputClean = parseInt(input.toString(), 10);

  if (inputClean < 120) {
    return `${inputClean} secs`;
  } else {
    const minutes = Math.floor(inputClean / 60);
    const seconds = inputClean % 60;

    if (seconds > 0) {
      return `${minutes} mins, ${seconds} secs`;
    } else {
      return `${minutes} mins`;
    }
  }
};

export const distinct = <T>(input: T[]): T[] =>
  input.filter((v, idx, arr) => arr.indexOf(v) === idx);
