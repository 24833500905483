import * as React from "react";
import { EntryListResults } from "components/components/entries/list/components/EntryListResults";
import { useParams } from "react-router";
import {
  EntriesOrderBy,
  useGetMenuViewQuery,
  useListEntriesQuery,
} from "api/graphql";
import { useEnvironment } from "../..";
import { buildPaginationProps } from "components/elements/pagination/Pagination";
import { useDebounce } from "use-debounce";
import { buildFilter } from "../list";

export const EntryView: React.FC = () => {
  const { view } = useParams();
  const { project, environment, models } = useEnvironment();
  const [search, setSearch] = React.useState("");
  const [pageSize, setPageSize] = React.useState(9);
  const [skip, setSkip] = React.useState(0);
  const [sort, setSort] = React.useState(EntriesOrderBy.ModifiedAtDesc);

  const viewQuery = useGetMenuViewQuery({
    variables: {
      id: view,
      project: project.id,
      environment: environment.id,
    },
    skip: !view,
  });

  const [filter] = useDebounce(
    buildFilter(search, [viewQuery.data?.entity?.modelId]),
    250,
    {
      equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
    }
  );

  const entriesQuery = useListEntriesQuery({
    variables: {
      project: project.id,
      environment: environment.id,
      skip,
      take: pageSize,
      sort,
      filter,
    },
    skip: !viewQuery.data?.entity?.modelId,
    fetchPolicy: "cache-and-network",
  });

  if (viewQuery.loading) {
    return <p>Loading...</p>;
  }

  if (!viewQuery.data?.entity) {
    return <p>View not found.</p>;
  }

  const model = models.find(
    (mod) => mod.id === viewQuery.data?.entity?.modelId
  );
  const children = model
    ? models.filter((mod) => mod.inherits.includes(model.id))
    : [];

  return (
    <EntryListResults
      models={{
        list: model ? [model].concat(children) : [],
        onChange: () => {},
        value: [model?.id!].concat(children.map((mod) => mod.id)),
      }}
      search={{
        value: search,
        onChange: setSearch,
      }}
      results={{
        list:
          entriesQuery.data?.list?.nodes.map((ent) => {
            const mod = models.find((mod) => mod.id === ent?.modelId);
            return {
              id: ent!.id!,
              model: { alias: mod!.alias!, name: mod!.name! },
              modified: new Date(ent!.modifiedAt! + "Z"),
              created: new Date(ent!.createdAt! + "Z"),
              name: ent!.name!,
              published: (ent?.entryPublishes?.totalCount ?? 0) > 0,
              url: ["..", "..", ent?.id, "versions", ent?.latestVersionId].join(
                "/"
              ),
            };
          }) ?? [],
        pagination: buildPaginationProps(
          skip,
          entriesQuery.data?.list?.nodes.length || 0,
          entriesQuery.data?.list?.totalCount || 0,
          () => setSkip((prev) => prev + pageSize),
          () => setSkip((prev) => prev - pageSize)
        ),
        setPageSize,
        sort: { value: sort, onChange: setSort },
      }}
    />
  );
};
