import * as React from "react";
import { Route, Routes, useNavigate } from "react-router";
import { FieldErrors } from "react-hook-form";
import {
  LinkButton,
  Button,
  IconLinkButton,
  Icons,
  InputField,
} from "pokko-shared";
import { camelCase } from "change-case";
import { Model, ModelFieldsInherited, ModelFieldType } from "api/graphql";

import { ModelSettings, ModelSettingsProps } from "../settings/ModelSettings";
import { ModelField } from "./components/ModelField";
import {
  ModelFieldEditor,
  ModelFieldInput,
} from "./components/ModelFieldEditor";
import { ValueEditor, ValueEditorProps } from "../../values/editor/ValueEditor";
import { useValueEditorContext } from "../../values/editor/ValueEditorContext";

export type ModelDetailProps = {
  model: Model;
  settings?: ModelSettingsProps;
  values?: ValueEditorProps;
  onAfterBaseSave: () => Promise<void>;
  fields?: {
    saving: boolean;
    deleting: boolean;
    restoring: boolean;
    errors: FieldErrors<ModelFieldInput>;
    onSave: (
      field: ModelFieldsInherited | null,
      values: ModelFieldInput
    ) => void;
    onDelete: (field: ModelFieldsInherited) => void;
    onRestore: (field: ModelFieldsInherited) => void;
  };
};

const filterFn = (pattern: string): { test: (input: string) => boolean } => {
  try {
    return new RegExp(pattern, "i");
  } catch (ex) {
    return { test: () => false };
  }
};

export const ModelDetail: React.FC<ModelDetailProps> = ({
  model,
  settings,
  values,
  fields,
  onAfterBaseSave,
}) => {
  const valueEditContext = useValueEditorContext();

  const [filter, setFilter] = React.useState("");
  const navigate = useNavigate();

  const filterRegExp = filterFn(filter);
  const filteredFields = model.fieldsAll.nodes.filter(
    (ent) => filterRegExp.test(ent?.name!) || filterRegExp.test(ent?.alias!)
  );

  const orderedFields = filteredFields
    .filter((fld) => fld?.sourceModel?.id === model.id)
    .concat(filteredFields.filter((fld) => fld?.sourceModel?.id !== model.id));

  const handleSaveValues = async () => {
    await valueEditContext.save();

    await onAfterBaseSave();

    navigate(".");
  };

  return (
    <div className="model-detail__container">
      <div className="model-detail__header">
        <h1 className="h2">{model.name}</h1>
        <div className="model-detail__header-actions">
          <Routes>
            <Route
              path="values"
              element={
                <>
                  <LinkButton kind="tertiary" to="..">
                    Cancel
                  </LinkButton>
                  <Button
                    kind="primary"
                    onClick={handleSaveValues}
                    loading={valueEditContext.saving}
                  >
                    Save
                  </Button>
                </>
              }
            />
            <Route
              path="usage"
              element={
                <>
                  <LinkButton kind="tertiary" to="..">
                    Back
                  </LinkButton>
                </>
              }
            />
            <Route
              path=""
              element={
                <>
                  <LinkButton kind="tertiary" to="usage">
                    Usage
                  </LinkButton>
                  {values ? (
                    <LinkButton kind="tertiary" to="values">
                      Base values
                    </LinkButton>
                  ) : null}
                  <IconLinkButton kind="tertiary" to="settings">
                    <Icons.SettingsIcon />
                  </IconLinkButton>
                </>
              }
            />
          </Routes>
        </div>
      </div>

      <Routes>
        <Route
          path="settings"
          element={
            <ModelSettings
              id={model.id}
              defaultValues={{
                ...model,
                inheritance:
                  model.parents.nodes
                    .map((p) => p?.parentId!)
                    .map((id) => ({ id })) ?? [],
                fields: model.fieldsAll.nodes
                  .filter((ent) => ent?.sourceModel?.id === model.id)
                  .map((ent) => ({
                    id: ent?.id!,
                    name: ent?.name!,
                    alias: ent?.alias!,
                    index: ent?.index!,
                  })),
              }}
              {...settings!}
            />
          }
        />
        {values ? (
          <Route
            path="values"
            element={
              <div>
                <ValueEditor title="Base values" {...values} />
              </div>
            }
          />
        ) : null}

        <Route
          path="usage"
          element={
            <pre className="model-detail__usage">{buildUsage(model)}</pre>
          }
        />

        <Route
          path="fields/create"
          element={
            <ModelFieldEditor
              isNew
              deleted={false}
              restoring={false}
              fields={(model.fieldsAll.nodes ?? []) as ModelFieldsInherited[]}
              errors={fields?.errors!}
              saving={fields?.saving ?? false}
              onSave={(values) => fields?.onSave(null, values)}
              models={settings?.models ?? []}
            />
          }
        />

        {model.fieldsAll.nodes.map(
          (
            field // TODO: fix this.
          ) => (
            <Route
              key={field?.id}
              path={`fields/${field?.alias}`}
              element={
                <ModelFieldEditor
                  fields={
                    (model.fieldsAll.nodes ?? []) as ModelFieldsInherited[]
                  }
                  defaultValues={{
                    alias: field!.alias!,
                    name: field!.name!,
                    type: field!.type as ModelFieldType,
                    config: field!.config!,
                    multi: field!.multi!,
                    valueCount: field!.valueCount!,
                    required: field!.required!,
                    modelId: model.id,
                    autoSource: field!.autoSource!,
                    autoOverride: field!.autoOverride!,
                    autoTransform: field!.autoTransform!,
                    autoFieldId: field!.autoFieldId,
                  }}
                  deleted={Boolean(field!.deletedAt)}
                  errors={fields!.errors!}
                  loading={fields!.saving}
                  models={settings?.models ?? []}
                  restoring={fields!.restoring}
                  saving={fields!.saving}
                  onDelete={() => fields!.onDelete(field!)}
                  onRestore={() => fields!.onRestore(field!)}
                  onSave={(values) => fields!.onSave(field, values)}
                />
              }
            />
          )
        )}

        <Route
          path=""
          element={
            <>
              <div className="model-detail__body">
                <InputField
                  placeholder="Search..."
                  type="search"
                  value={filter}
                  onChangeText={setFilter}
                />
                {filter &&
                orderedFields.length === 0 &&
                model.fieldsAll.nodes.length > 0 ? (
                  <div className="model-detail__body-no-results">
                    <Icons.SearchIcon />
                    <p>
                      <strong>No results for “{filter}”</strong>
                    </p>
                    <p>
                      You may want to try using different keywords or checking
                      for typos.
                    </p>
                  </div>
                ) : orderedFields.length > 0 ? (
                  <ul className="model-detail__fields">
                    {orderedFields.map((fld) => (
                      <ModelField
                        models={settings?.models ?? []}
                        key={fld?.id}
                        field={fld as ModelFieldsInherited}
                        saving={fields?.saving ?? false}
                        inherited={fld?.sourceModel?.id !== model.id}
                        onSave={(values) => fields?.onSave(fld, values)}
                      />
                    ))}
                  </ul>
                ) : (
                  <p>There are no fields in this model.</p>
                )}
              </div>
              <div className="model-detail__footer">
                <LinkButton kind="primary" to="fields/create">
                  New field
                </LinkButton>
                {model.deletedAt && settings ? (
                  <Button
                    kind="tertiary"
                    onClick={settings.onRestore}
                    loading={settings.restoring}
                  >
                    Restore
                  </Button>
                ) : null}
              </div>
            </>
          }
        />
      </Routes>
    </div>
  );
};

const buildUsage = (input: Model): string => {
  const formatField = (fld: ModelFieldsInherited | null): string => {
    if (!fld) {
      return "";
    }

    const suffix =
      fld.sourceModel?.id !== input.id
        ? ` # from ${fld.sourceModel?.alias}`
        : ``;

    switch (fld.type) {
      case ModelFieldType.Scalar:
        return fld.alias! + suffix;
      case ModelFieldType.Entry:
        return `${fld.alias} {\n    __typename\n    ...\n  }` + suffix;
      case ModelFieldType.Media:
        return `${fld.alias} {\n    url\n  }` + suffix;
      case ModelFieldType.Value:
        return `${fld.alias} {\n    __typename\n    ...\n  }` + suffix;
    }

    return "";
  };

  return `# GraphQL queries for ${input.name}

# ${input.alias} fragment

fragment ${input.alias}Fragment on ${input.alias} {
  id
  ${input.fieldsAll.nodes.map(formatField).join("\n  ")}
}

# Query single ${input.name} by ID
query ${input.alias}ById($id: String!) {
  entries {
    ${camelCase(input.alias)}(id: $id) {
      ...${input.alias}Fragment
    }
  }
}

# Query single ${input.name} by path
query ${input.alias}ByPath($path: [String!]!) {
  entry(path: $path) {
    __typename
    ...${input.alias}Fragment
  }
}


# List multiple ${input.name}
query List${input.alias}ById {
  entries {
    all${input.alias}(skip: 0, take: 10) {
      ...${input.alias}Fragment
    }
  }
}
`;
};
