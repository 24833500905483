import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApiProvider } from "./api";
import { UserProvider } from "./user";
import { FieldProvider } from "./content/fields";
import { UploadProvider } from "./content/upload";
import { HelmetProvider } from "./helmet";

const providers = [
  HelmetProvider,
  UploadProvider,
  FieldProvider,
  ApiProvider,
  UserProvider,
  BrowserRouter,
];

export const Providers: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    {providers.reduce(
      (p, c) => React.createElement<any>(c, { children: p }),
      children
    )}
  </>
);
