import * as React from "react";

import { FieldInputProps } from "components/components/fields/types";
import { Module } from "./components/Module";
import { Config } from ".";

export const ModulesInput: React.FC<FieldInputProps<Config>> = ({
  config,
  value,
  field,
  onChange,
  onRemove,
}) => (
  <Module
    field={field}
    value={value}
    onChange={onChange}
    onRemove={onRemove}
    allowed={config?.allowed}
  />
);
