import * as React from "react";

export type TwoColumnLayoutProps = {
  header?: React.ReactNode;
  left: React.ReactNode;
  right: React.ReactNode;
};

export const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({
  header,
  left,
  right,
}) => (
  <div className="two-column__container">
    {header ? <div className="two-column__header">{header}</div> : null}
    <div className="two-column__left">{left}</div>
    <div className="two-column__right">{right}</div>
  </div>
);
