import * as React from "react";
import { Input, IconButton, Icons, Button, Select } from "pokko-shared";
import { ValueFieldValue } from "components/components/fields/types";
import { useModuleEditContext } from "./ModuleEditContextProvider";
import {
  ModelFieldsInherited,
  ModelUsage,
  useCreateValueMutation,
  ValueField,
} from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";
import { Tag } from "components/elements/tag/Tag";
import { ValueEditor } from "components/components/values/editor/ValueEditor";

type ModuleProps = {
  field: ModelFieldsInherited;
  value: ValueFieldValue;
  onChange: (value: ValueFieldValue) => void;
  onRemove?: (value: ValueFieldValue) => void;
  allowed?: string[];
};

export const Module: React.FC<ModuleProps> = ({
  value,
  allowed,
  field,
  onChange,
  onRemove,
}) => {
  const context = useModuleEditContext();
  const [model, setModel] = React.useState("");
  const { project, environment, models } = useEnvironment();
  const [create, createStatus] = useCreateValueMutation();

  const allowedModels = models
    .filter((ent) => ent.usage === ModelUsage.Module)
    .filter((ent) =>
      allowed && allowed.length > 0 ? allowed.indexOf(ent.id) !== -1 : true
    );

  const handlePatch = (patch: any) => {
    onChange({ ...value, ...patch });
  };

  const soloModel = allowedModels.length === 1 ? allowedModels[0] : null;

  const handleCreate = async () => {
    if (!(soloModel?.id ?? model)) {
      return;
    }

    const res = await create({
      variables: {
        project: project.id,
        environment: environment.id,
        model: soloModel?.id ?? model,
      },
    });

    const updatedValue = {
      ...value,
      valueValueId: res.data?.create?.entity?.id,
      valueValue: res.data?.create?.entity,
    } as ValueField;

    onChange(updatedValue);

    context.push(updatedValue);
  };

  if (value.valueValue) {
    if (!field.multi) {
      return <ValueEditor id={value.valueValue.id} />;
    }

    return (
      <div className="module-editor__module-body">
        <div className="module-editor__module-settings">
          <Input
            placeholder="Hint"
            value={value.hint ?? ""}
            onChangeText={(hint: string) => handlePatch({ hint })}
            addOn={{
              right: (
                <Tag>
                  {
                    models.find((mod) => mod.id === value.valueValue?.modelId)
                      ?.name
                  }
                </Tag>
              ),
            }}
          />

          {onRemove ? (
            <IconButton
              type="button"
              kind="tertiary"
              onClick={() => onRemove(value)}
            >
              <Icons.DeleteIcon />
            </IconButton>
          ) : null}
          <Button
            type="button"
            kind="tertiary"
            onClick={() => context.push(value)}
          >
            Edit
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="module-editor__add-module">
        {soloModel ? (
          <Button
            kind="primary"
            onClick={handleCreate}
            loading={createStatus.loading}
          >
            {`Create ${soloModel.name}`}
          </Button>
        ) : (
          <>
            <Select value={model} onChangeText={setModel}>
              <option>Select model</option>
              {allowedModels.map((ent) => (
                <option key={ent.id} value={ent.id}>
                  {ent.name}
                </option>
              ))}
            </Select>
            <Button
              kind="primary"
              onClick={handleCreate}
              loading={createStatus.loading}
            >
              Create value
            </Button>

            {onRemove ? (
              <IconButton
                type="button"
                kind="tertiary"
                onClick={() => onRemove(value)}
              >
                <Icons.DeleteIcon />
              </IconButton>
            ) : null}
          </>
        )}
      </div>
    );
  }
};
