import * as React from "react";
import { IconButton, Icons, Button } from "pokko-shared";
import { useGetMediaFolderQuery, useGetMediaItemQuery } from "api/graphql";
import { FieldInputProps } from "components/components/fields/types";
import { Config } from ".";
import { MediaSearch } from "./components/MediaSearch";
import { useUpload } from "providers/content/upload";
import { MediaCard } from "../../media/card/MediaCard";

export const MediaInput: React.FC<FieldInputProps<Config>> = ({
  config,
  field,
  error,
  value,
  readOnly,
  disabled,
  onChange,
  onRemove,
}) => {
  const [searching, setSearching] = React.useState(false);
  const [dropActive, setDropActive] = React.useState(false);
  const { enqueue } = useUpload();

  const selected = useGetMediaItemQuery({
    variables: { id: value?.valueMediaId || null },
    skip: !value || !value?.valueMediaId,
  });
  const startingFolder = useGetMediaFolderQuery({
    variables: { id: config.startingFolder! },
    skip: !config.startingFolder,
  });

  const handleChange = (valueMediaId: string | null) => {
    onChange({
      ...value,
      valueMediaId,
    });

    setSearching(false);
  };

  const handleSwallow: React.DragEventHandler = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const handleDragEnter: React.DragEventHandler = (ev) => {
    handleSwallow(ev);

    if (ev.dataTransfer.types.includes("Files")) {
      setDropActive(true);
    }
  };

  const handleDragLeave: React.DragEventHandler = (ev) => {
    handleSwallow(ev);

    setDropActive(false);
  };

  const handleDrop: React.DragEventHandler = (ev) => {
    handleDragLeave(ev);

    const { files } = ev.dataTransfer;
    if (files.length === 1) {
      const file = files.item(0);
      if (file) {
        handleUpload(file);
      }
    }
  };

  const handleSelect: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const file = ev.currentTarget.files?.item(0);
    if (file) {
      handleUpload(file);
    }
    ev.currentTarget.value = "";
  };

  const handleUpload = async (file: File) => {
    const res = await enqueue([
      { file, folder: config.startingFolder, project: field.projectId },
    ]);

    onChange({ ...value, valueMediaId: res[0].id! });
  };

  const id = `media_input.${Math.random()}`;

  return (
    <div className="media-input__container">
      {searching ? (
        <MediaSearch
          value={value?.valueMediaId || null}
          onChange={handleChange}
          folderStart={selected.data?.entity?.folderId || config.startingFolder}
          onCancel={() => setSearching(false)}
        />
      ) : value.valueMediaId ? (
        <div className="media-input__value">
          {selected.loading ? (
            <span>Loading...</span>
          ) : (
            <MediaCard
              id={selected.data?.entity?.id}
              mediaInfo={selected.data?.entity?.mediaInfo}
              mediaType={selected.data?.entity?.mediaType!}
              name={selected.data?.entity?.name!}
              project={selected.data?.entity?.projectId}
              size={selected.data?.entity?.size!}
              uploaded={Boolean(selected.data?.entity?.uploadedAt)}
            />
          )}
          {onRemove ? (
            <IconButton
              kind="tertiary"
              onClick={onRemove}
              disabled={disabled || readOnly}
              type="button"
            >
              <Icons.DeleteIcon />
            </IconButton>
          ) : null}
        </div>
      ) : (
        <div className="media-input__display">
          <div
            className="media-input__input"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label htmlFor={id} className="input">
              {dropActive ? (
                <>
                  Upload to{" "}
                  <strong>
                    {startingFolder.data?.entity?.name ?? "Uncategorised"}
                  </strong>
                </>
              ) : (
                "Drop files here to upload..."
              )}
            </label>
            <input
              type="file"
              id={id}
              onChange={handleSelect}
              disabled={disabled || readOnly}
            />
          </div>
          <Button
            kind="tertiary"
            onClick={() => setSearching(true)}
            disabled={disabled || readOnly}
          >
            Select from media
          </Button>
          {onRemove ? (
            <IconButton
              kind="tertiary"
              onClick={onRemove}
              disabled={disabled || readOnly}
              type="button"
            >
              <Icons.DeleteIcon />
            </IconButton>
          ) : null}
        </div>
      )}
    </div>
  );
};
