import * as React from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";
import { ProjectNavbar } from "components/elements/navbar/project/ProjectNavbar";

export type ProjectLayoutProps = React.PropsWithChildren<{
  gapless?: boolean;
}>;

export const ProjectLayout: React.FC<ProjectLayoutProps> = ({
  gapless,
  children,
}) => (
  <>
    <Helmet bodyAttributes={{ class: "--fix-fullheight" }} />
    <div className={cx("project-layout", { "--gapless": gapless })}>
      <ProjectNavbar />
      <main>{children}</main>
    </div>
  </>
);
