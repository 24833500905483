import * as React from "react";
import {
  useCreateMenuItemMutation,
  useGetMenuItemQuery,
  useUpdateMenuItemMutation,
} from "api/graphql";
import {
  EntryMenuItemEditor,
  EntryMenuItemInput,
} from "components/components/entries/menu/EntryMenuItemEditor";
import { useNavigate, useParams } from "react-router";
import { useEnvironment } from "../..";

export const EntryMenuPage: React.FC = () => {
  const { item } = useParams();
  const { project, environment } = useEnvironment();
  const navigate = useNavigate();
  const query = useGetMenuItemQuery({
    variables: {
      project: project.id,
      environment: environment.id,
      id: item,
    },
    fetchPolicy: "network-only",
    skip: !item,
  });
  const [create, createStatus] = useCreateMenuItemMutation();
  const [update, updateStatus] = useUpdateMenuItemMutation();
  const [archive, archiveStatus] = useUpdateMenuItemMutation();

  if (query.loading) {
    return null;
  }

  const entity = query.data?.entity;

  if (item && !entity) {
    return <p>Entry not found</p>;
  }

  const handleSave = async (value: EntryMenuItemInput) => {
    const allocateType = async (id: string) => {
      // TODO: this could be better
      if (value.type === "entry" && value.entry) {
        await update({
          variables: {
            project: project.id,
            environment: environment.id,
            id,
            patch: {
              label: value.label,
              menuItemEntryUsingIdAndProjectIdAndEnvironmentId: {
                create: [
                  {
                    projectId: project.id,
                    environmentId: environment.id,
                    id,
                    entryId: value.entry,
                  },
                ],
              },
            },
          },
        });
      }
      if (value.type === "view" && value.model) {
        await update({
          variables: {
            project: project.id,
            environment: environment.id,
            id,
            patch: {
              label: value.label,
              menuItemViewUsingIdAndProjectIdAndEnvironmentId: {
                create: [
                  {
                    projectId: project.id,
                    environmentId: environment.id,
                    id,
                    modelId: value.model,
                  },
                ],
              },
            },
          },
        });
      }
    };

    if (item) {
      await update({
        variables: {
          project: project.id,
          environment: environment.id,
          id: item,
          patch: {
            label: value.label,
            menuItemEntryUsingIdAndProjectIdAndEnvironmentId: {
              deleteByIdAndProjectIdAndEnvironmentId: {
                projectId: project.id,
                environmentId: environment.id,
                id: item,
              },
            },
            menuItemViewUsingIdAndProjectIdAndEnvironmentId: {
              deleteByIdAndProjectIdAndEnvironmentId: {
                projectId: project.id,
                environmentId: environment.id,
                id: item,
              },
            },
          },
        },
      });

      await allocateType(item);
    } else {
      const res = await create({
        variables: {
          input: {
            projectId: project.id,
            environmentId: environment.id,
            parentId: null,
            label: value.label,
          },
        },
      });

      await allocateType(res.data?.create?.entity?.id!);
    }

    navigate("../..");
  };

  const handleArchive = async () => {
    await archive({
      variables: {
        project: project.id,
        environment: environment.id,
        id: item,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    navigate("../../");
  };

  return (
    <EntryMenuItemEditor
      creating={!item}
      value={
        entity
          ? {
              label: entity.label!,
              type: entity.entry ? "entry" : entity.view ? "view" : "folder",
              entry: entity.entry?.entryId,
              model: entity.view?.modelId,
            }
          : { label: "", type: "folder" }
      }
      save={[handleSave, item ? updateStatus : createStatus]}
      archive={[handleArchive, archiveStatus]}
    />
  );
};
