import { TaxonomyTree } from "components/components/taxonomy/tree/TaxonomyTree";
import * as React from "react";
import { useProject } from "routes/accounts/projects";

type TaxonomyTreeControllerProps = {};

export const TaxonomyTreeController: React.FC<TaxonomyTreeControllerProps> = () => {
  const { project } = useProject();
  return (
    <TaxonomyTree
      targets={(project.publishTargets.nodes ?? []).map((ent) => ({
        id: ent!.id!,
        name: ent!.name!,
      }))}
    />
  );
};
