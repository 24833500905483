import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Icons, InlineRadioButtons } from "pokko-shared";

import { useTree } from "./hook";
import {
  LoadChildrenResult,
  RenderChildren,
  TreeHook,
  TreeNodeState,
} from "./types";
import {
  TaxonomyListNodesDocument,
  TaxonomyListNodesQuery,
  TaxonomyListNodesQueryVariables,
  useCreateTaxonomyMutation,
  useUpdateTaxonomyMutation,
} from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";

export type TreeNode = {
  alias: string;
  type: string;
  entry?: { id: string; name: string };
  taxonomy?: string;
};

export type TaxonomyTreeProps = {
  targets: { id: string; name: string }[];
};

// TODO: the logic here should be hoisted to a higher level component
export const TaxonomyTree: React.FC<TaxonomyTreeProps> = ({ targets }) => {
  const pageSize = 25;
  const client = useApolloClient();
  const [create] = useCreateTaxonomyMutation();
  const [update] = useUpdateTaxonomyMutation();

  const { project, environment } = useEnvironment();
  const [target, setTarget] = React.useState(targets[0].id);

  const loadChildren = React.useCallback(
    async (
      parent: string | null,
      skip: number
    ): Promise<LoadChildrenResult<TreeNode>> => {
      const res = await client.query<
        TaxonomyListNodesQuery,
        TaxonomyListNodesQueryVariables
      >({
        fetchPolicy: "network-only",
        query: TaxonomyListNodesDocument,
        variables: {
          project: project.id,
          environment: environment.id,
          parent: parent
            ? parent.split(".").filter((str: string) => str !== "")
            : [],
          skip,
          take: pageSize,
          target,
        },
      });

      if (!res.data.list) {
        throw new Error("query failed.");
      }

      return {
        hasMore: res.data.list.pageInfo.hasNextPage,
        nodes: res.data.list.nodes.map((ent) => ({
          id: ent!.path!.join("."),
          childCount: 1, // TODO: get this somehow
          alias: [...ent!.path!].pop()!,
          type: ent!.type!,
          taxonomy: ent!.taxonomyId,
          entry: ent!.entry
            ? { id: ent!.entry.id!, name: ent!.entry.name! }
            : undefined,
        })),
      };
    },
    [client, environment.id, project.id, target]
  );

  const tree = useTree<TreeNode>({
    loadChildren,
    pageSize,
    createNode: async (parent, alias) => {
      const res = await create({
        variables: {
          input: {
            projectId: project.id,
            environmentId: environment.id,
            parentId: parent?.data.taxonomy!,
            alias,
          },
        },
      });
      const entity = res.data?.create?.entity!;

      return {
        id: entity.id,
        childCount: 1,
        alias: alias,
        type: "static",
        taxonomy: entity.id,
      };
    },
    deleteNode: async (node) => {
      await update({
        variables: {
          project: project.id,
          environment: environment.id,
          id: node.data.taxonomy!,
          patch: {
            deletedAt: new Date().toISOString(),
          },
        },
      });
    },
  });

  const renderChildren = (parent: string | null): React.ReactElement | null => {
    const parentNode = parent
      ? tree.state.nodes2.find((ent) => ent.id === parent)
      : null;
    const nodes = tree.state.nodes2.filter((ent) => ent.parent === parent);

    const indent: React.CSSProperties = {
      marginLeft: `${parentNode?.level ?? 0}rem`,
    };

    return !parentNode || parentNode.expanded ? (
      <>
        {nodes.map((nod, idx) => {
          return (
            <Node
              key={idx}
              node={nod}
              renderChildren={renderChildren}
              tree={tree}
            />
          );
        })}

        {parentNode?.hasMore || parentNode?.loading ? (
          <button
            style={indent}
            onClick={() => tree.loadMore(parent)}
            disabled={parentNode.loading}
            className={cx("entries-tree__more", {
              "--loading": parentNode.loading,
            })}
          >
            {parentNode.loading ? (
              <span>Loading...</span>
            ) : (
              <>
                <span>Load more</span>
                <Icons.ContentIcon />
                <small>
                  Showing {nodes.length} of {parentNode.childCount}
                </small>
              </>
            )}
          </button>
        ) : null}
      </>
    ) : null;
  };

  return (
    <div className="taxonomy-tree__container">
      {targets.length > 1 ? (
        <div className="taxonomy-tree__targets">
          <small>Publish target</small>
          <InlineRadioButtons
            value={target}
            onChange={setTarget}
            values={targets.map((ent) => ent.id)}
            render={(id) => targets.find((ent) => ent.id === id)?.name}
          />
        </div>
      ) : null}
      <div className="taxonomy-tree__nodes">
        {renderChildren(null)}
        <NewRoot tree={tree} />
      </div>
    </div>
  );
};

// TODO: change to use reducer
const Node: React.FC<{
  node: TreeNodeState<TreeNode>;
  renderChildren: RenderChildren;
  tree: TreeHook<TreeNode>;
}> = ({ node, tree, renderChildren }) => {
  const [actions, setActions] = React.useState(false);
  const [renaming, setRenaming] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [creating, setCreating] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [alias, setAlias] = React.useState(node.data.alias);
  const [createAlias, setCreateAlias] = React.useState("");

  const { project, environment } = useEnvironment();
  const [update] = useUpdateTaxonomyMutation();

  const handleRename = async () => {
    setLoading(true);
    await update({
      variables: {
        id: node.data.taxonomy!,
        project: project.id,
        environment: environment.id,
        patch: {
          alias,
        },
      },
    });
    tree.nodeUpdated(node, { ...node.data, alias });
    setRenaming(false);
    setActions(false);
    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);
    await tree.deleteNode(node);
    setDeleting(false);
    setActions(false);
    setLoading(false);
  };
  const handleCreate = async () => {
    setLoading(true);
    await tree.createChild(node, createAlias);
    setCreating(false);
    setActions(false);
    setLoading(false);
  };

  const indent: React.CSSProperties = {
    marginLeft: `${node.level}rem`,
  };

  return (
    <React.Fragment>
      <div
        style={indent}
        className={cx("taxonomy-tree-item__container", {
          "--expandable": node.data.type !== "dynamic",
        })}
      >
        {node.data.type !== "dynamic" ? (
          <button onClick={() => tree.toggle(node.id)}>
            {node.expanded ? <Icons.DownIcon /> : <Icons.NextIcon />}
          </button>
        ) : null}
        {node.data.type === "dynamic" ? (
          <Icons.MediaOther />
        ) : node.expanded ? (
          <Icons.FolderOpenIcon />
        ) : (
          <Icons.FolderIcon />
        )}
        {renaming ? (
          <input
            value={alias}
            onChange={(ev) => setAlias(ev.currentTarget.value)}
            autoFocus
          />
        ) : (
          <span>{node.data.alias}</span>
        )}
        <div className="taxonomy-tree-item__detail">
          {["dynamic", "dynamic_root"].includes(node.data.type) &&
          node.data.entry ? (
            <>
              <Link to={`../entries/${node.data.entry.id}`}>
                {node.data.entry.name}
              </Link>
            </>
          ) : node.data.type === "static" ? (
            node.data.entry ? (
              <>
                <Link to={`../entries/${node.data.entry.id}`}>
                  {node.data.entry.name}
                </Link>
              </>
            ) : (
              "Static node"
            )
          ) : node.data.type === "fragment" ? (
            "Fragment"
          ) : node.data.type === "dynamic_root" ? (
            "Dynamic"
          ) : (
            "__unknown__"
          )}
          {["static", "dynamic_root"].includes(node.data.type) ? (
            <div className="taxonomy-tree-item__actions">
              {actions ? (
                creating ? (
                  <>
                    <input
                      placeholder="New child node alias..."
                      value={createAlias}
                      onChange={(ev) => setCreateAlias(ev.currentTarget.value)}
                      autoFocus
                    />
                    <button disabled={loading} onClick={handleCreate}>
                      <Icons.SuccessIcon />
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => {
                        setCreating(false);
                        setActions(false);
                      }}
                    >
                      <Icons.CloseIcon />
                    </button>
                  </>
                ) : renaming ? (
                  <>
                    <button disabled={loading} onClick={handleRename}>
                      <Icons.SuccessIcon />
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => {
                        setRenaming(false);
                        setActions(false);
                      }}
                    >
                      <Icons.CloseIcon />
                    </button>
                  </>
                ) : deleting ? (
                  <>
                    <button disabled={loading} onClick={handleDelete}>
                      <Icons.SuccessIcon />
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => {
                        setDeleting(false);
                        setActions(false);
                      }}
                    >
                      <Icons.CloseIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      disabled={loading}
                      onClick={() => setRenaming(true)}
                    >
                      <Icons.EditIcon />
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => setDeleting(true)}
                    >
                      <Icons.TrashIcon />
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => {
                        setCreateAlias("");
                        setCreating(true);
                      }}
                    >
                      New child node
                    </button>
                  </>
                )
              ) : null}
              {renaming || deleting || creating ? null : (
                <>
                  {["static", "dynamic_root"].includes(node.data.type) &&
                  !actions ? (
                    <>
                      <Link to={node.data.taxonomy!}>
                        <Icons.SettingsIcon />
                      </Link>
                    </>
                  ) : null}
                  <button
                    disabled={loading}
                    onClick={() => setActions((prev) => !prev)}
                  >
                    <Icons.MoreIcon />
                  </button>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>

      {renderChildren(node.id)}
    </React.Fragment>
  );
};

const NewRoot: React.FC<{ tree: TreeHook<TreeNode> }> = ({ tree }) => {
  const [creating, setCreating] = React.useState(false);
  const [createAlias, setCreateAlias] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleCreate = async () => {
    setLoading(true);
    await tree.createChild(null, createAlias);
    setCreating(false);
    setLoading(false);
  };

  return (
    <div className="taxonomy-tree-item__container">
      {creating ? (
        <div className="taxonomy-tree-item__actions">
          <input
            placeholder="New child node alias..."
            value={createAlias}
            onChange={(ev) => setCreateAlias(ev.currentTarget.value)}
            autoFocus
          />
          <button disabled={loading} onClick={handleCreate}>
            <Icons.SuccessIcon />
          </button>
          <button
            disabled={loading}
            onClick={() => {
              setCreating(false);
            }}
          >
            <Icons.CloseIcon />
          </button>
        </div>
      ) : (
        <button onClick={() => setCreating(true)}>New root node</button>
      )}
    </div>
  );
};
