import * as React from "react";

import { useTree } from "components/components/taxonomy/tree/hook";
import { useApolloClient } from "@apollo/client";
import {
  MenuListNodesQuery,
  MenuListNodesQueryVariables,
  MenuListNodesDocument,
} from "api/graphql";
import {
  LoadChildrenResult,
  TreeNodeBase,
} from "components/components/taxonomy/tree/types";
import { useEnvironment } from "routes/accounts/projects/environments";

export type TreeNodeType = "entry" | "view" | "folder";
export type TreeNode = TreeNodeBase<{
  label: string;
  type: TreeNodeType;
  entry?: string;
  view?: string;
  childCount: number;
  index: number;
}>;

export const useEntryMenuTree = () => {
  const pageSize = 25;
  const client = useApolloClient();
  const { project, environment } = useEnvironment();

  const loadChildren = React.useCallback(
    async (
      parent: string | null,
      skip: number
    ): Promise<LoadChildrenResult<TreeNode>> => {
      const res = await client.query<
        MenuListNodesQuery,
        MenuListNodesQueryVariables
      >({
        fetchPolicy: "network-only",
        query: MenuListNodesDocument,
        variables: {
          project: project.id,
          environment: environment.id,
          parent,
          skip,
          take: pageSize,
        },
      });

      if (!res.data.list) {
        throw new Error("query failed.");
      }

      return {
        hasMore: res.data.list.pageInfo.hasNextPage,
        nodes: res.data.list.nodes.map((ent) => ({
          label: ent!.label!,
          id: ent!.id!,
          type: ent!.entry?.entryId
            ? "entry"
            : ent!.view?.modelId
            ? "view"
            : "folder",
          entry: ent!.entry?.entryId,
          view: ent!.view?.id,
          childCount: ent!.children.totalCount ?? 0,
          index: ent!.index!,
        })),
      };
    },
    [client, environment.id, project.id]
  );

  const tree = useTree<TreeNode>({
    loadChildren,
    pageSize,
    nodeOrderComparer: (a, b) => a.data.index - b.data.index,
  });

  return tree;
};
