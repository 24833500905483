import * as React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "pokko-shared";
import { useWebhooks } from "providers/webhooks";
import { useProject } from "routes/accounts/projects";

export const WebhooksDropdown: React.FC = () => {
  const { webhooks, trigger } = useWebhooks();
  const { account, project } = useProject();
  return (
    <div className="webhooks-dropdown__container">
      <strong>Available webhook triggers</strong>
      {webhooks.length > 0 ? (
        <ul className="webhooks-dropdown__list">
          {webhooks.map((ent) => (
            <li key={ent.id}>
              <NavLink
                to={`/accounts/${account.key}/projects/${project.key}/webhooks/${ent.id}`}
              >
                {ent.name}
              </NavLink>
              <Button kind="primary" small onClick={trigger(ent, false)}>
                Trigger
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <p>There are no webhooks defined in this project</p>
      )}
    </div>
  );
};
