import * as React from "react";
import {
  useUpdateEntryMutation,
  useCopyVersionMutation,
  useCopyVersionToNewEntryMutation,
  usePublishVersion2Mutation,
  useUnpublishVersion2Mutation,
  useUpdateEntryVersionMutation,
} from "api/graphql";
import {
  EntryDetail,
  EntryDetailInput,
  EntryWatcher,
} from "components/components/entries/detail/EntryDetail";
import { Navigate, useNavigate, useParams } from "react-router";
import { useEntry } from "..";
import { EntryVersion } from "components/components/entries/detail/components/EntryVersions";

export type EntryVersionPageProps = {};

export const EntryVersionPage: React.FC<EntryVersionPageProps> = () => {
  const { entry, project, environment, reload, links } = useEntry();
  const navigate = useNavigate();
  const { version } = useParams();
  const entity = entry.versions.nodes.find((ent) => ent?.id === version);
  const [update, updateStatus] = useUpdateEntryMutation();
  const [archive, archiveStatus] = useUpdateEntryVersionMutation();
  const [publish, publishStatus] = usePublishVersion2Mutation();
  const [retract, retractStatus] = useUnpublishVersion2Mutation();
  const [copy, copyStatus] = useCopyVersionMutation();
  const [copy2, copy2Status] = useCopyVersionToNewEntryMutation();
  const [watchingStatus, setWatchingStatus] = React.useState<EntryWatcher[]>(
    []
  );

  React.useEffect(() => {
    const wsLinkUri =
      window.location.hostname === "localhost"
        ? "ws://localhost:5010/pokko"
        : `wss://${window.location.host}/pokko`;

    const client = new WebSocket(wsLinkUri);

    client.addEventListener("open", () => {
      client.send(
        JSON.stringify({
          type: "content:watching:start",
          id: entry.id,
          project: project.id,
          environment: environment.id,
        })
      );
    });

    client.addEventListener("message", (ev) => {
      const data = JSON.parse(ev.data);

      switch (data.type) {
        case "content:watching:list":
          setWatchingStatus(data.watching);
          break;
      }
    });

    return () => {
      client.close();
    };
  }, [entry.id, environment.id, project.id]);

  const handleSave = async (values: EntryDetailInput) => {
    await update({
      variables: {
        id: entry.id,
        project: project.id,
        environment: environment.id,
        patch: {
          name: values.name,
        },
      },
    });
  };

  const handleDelete = async () => {
    await update({
      variables: {
        id: entry.id,
        project: project.id,
        environment: environment.id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    navigate("../../..");
  };

  const handleRestore = async () => {
    await update({
      variables: {
        id: entry.id,
        project: project.id,
        environment: environment.id,
        patch: {
          deletedAt: null,
        },
      },
    });
    reload();
  };

  const handlePublish2 = async (target: string) => {
    await publish({
      variables: {
        entry: entry.id,
        version: entity!.id,
        project: project.id,
        environment: environment.id,
        target,
      },
    });

    reload();
  };

  const handleRetract2 = async (target: string) => {
    await retract({
      variables: {
        entry: entry.id,
        project: project.id,
        environment: environment.id,
        target,
      },
    });

    reload();
  };

  const handleCopy = async () => {
    const res = await copy({
      variables: {
        id: entity!.id,
        environment: environment.id,
        project: project.id,
      },
    });

    await reload();

    navigate(`../../versions/${res.data?.create?.entity?.id}`);
  };

  const handleCopy2 = async () => {
    const res = await copy2({
      variables: {
        id: entity!.id,
        environment: environment.id,
        project: project.id,
      },
    });

    await reload();

    navigate(
      `../../../${res.data?.create?.entity?.id}/versions/${res.data?.create?.entity?.latestVersionId}`
    );
  };

  const handleArchive = async (id: string): Promise<void> => {
    await archive({
      variables: {
        id,
        project: project.id,
        environment: environment.id,
        patch: {
          deletedAt: new Date().toISOString(),
        },
      },
    });

    reload();
  };

  if (!entity) {
    return <Navigate to="../.." />;
  }

  const versionList = entry.versions.nodes.map(
    (ent) =>
      ({
        id: ent?.id!,
        created: new Date(ent?.createdAt! + "Z"),
        modified: new Date(ent?.modifiedAt! + "Z"),
        editing: entity.id === ent?.id,
        url: `../${ent?.id}`,
        value: ent?.valueId!,
        publishTargets: ent?.entryPublishes.nodes.map((pub) => ({
          id: pub?.target?.id!,
          name: pub?.target?.name!,
          date: new Date(pub?.modifiedAt + "Z"),
        })),
      }) as EntryVersion
  );

  return (
    <EntryDetail
      project={project.id}
      environment={environment.id}
      links={links
        .filter((lnk) => Boolean(lnk.url))
        .map((lnk) => ({ url: linkUrl(lnk), website: lnk.website }))}
      entry={{
        value: {
          model: entry.modelId,
          name: entry.name!,
          created: new Date(entry.createdAt! + "Z"),
          createdBy: entry.createdBy?.name,
          modified: new Date(entry.modifiedAt! + "Z"),
          modifiedBy: entry.modifiedBy?.name,
          deleted: entry.deletedAt ? new Date(entry.deletedAt + "Z") : null,
        },
        path: (entry.taxonomies.nodes[0]?.pathAlias as string[]) ?? undefined,
        onSave: handleSave,
        saving: updateStatus.loading,
        onDelete: handleDelete,
        deleting: updateStatus.loading,
        onRestore: handleRestore,
        restoring: updateStatus.loading,
        onPublish2: handlePublish2,
        publishing2: publishStatus.loading,
        onRetract2: handleRetract2,
        retracting2: retractStatus.loading,
      }}
      version={versionList.find((ent) => ent.id === entity.id)!}
      versions={{
        create: [handleCopy, copyStatus],
        copy2: [handleCopy2, copy2Status],
        archive: [handleArchive, archiveStatus],
        list: versionList,
      }}
      publishTargets={project.publishTargets.nodes.map((ent) => ({
        id: ent?.id!,
        name: ent?.name!,
        date: new Date(ent?.modifiedAt + "Z"),
      }))}
      watchers={watchingStatus}
    />
  );
};

const linkUrl = (input: any): string => {
  const { url, path, entry }: { url: string; path: string[]; entry: string } =
    input;

  const pathUrl = path.length === 0 ? "/" : ["", ...path].join("/");

  return url.replaceAll("{path}", pathUrl).replaceAll("{entry}", entry);
};
