import * as React from "react";
import { Helmet } from "react-helmet";
import { ProjectLayout } from "components/layout/project/ProjectLayout";
import { TwoColumnLayout } from "components/layout/twocol/TwoColumnLayout";
import { useProject } from "..";
import { MediaFolderListController } from "./MediaFolderListController";
import { MediaItemListController } from "./MediaItemListController";
import { Route, Routes } from "react-router";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useUpdateMediaFolderMutation,
  useUpdateMediaItemMutation,
} from "api/graphql";

export type MediaDragObject = {
  type: string;
  id?: string;
  path?: string[];
};

export const ProjectMediaPage: React.FC = () => {
  const { project } = useProject();
  const [selection, setSelection] = React.useState<string[]>([]);

  const [updateItem] = useUpdateMediaItemMutation();
  const [updateFolder] = useUpdateMediaFolderMutation();

  const [active, setActive] = React.useState<MediaDragObject | undefined>();

  const handleDragStart = (ev: DragStartEvent) => {
    setActive(ev.active.data.current as any);
  };
  const handleDragEnd = async (ev: DragEndEvent) => {
    if (ev.active.data.current && ev.over?.data.current) {
      const source = ev.active.data.current;
      const target = ev.over.data.current;
      if (source.type === "selection") {
        if (target.type === "folder") {
          const deletedAt =
            target.id === "trash" ? new Date().toISOString() : null;
          const folderId =
            target.id === ""
              ? null
              : target.id === "trash"
              ? undefined
              : target.id;

          await Promise.all(
            selection.map(async (id) => {
              await updateItem({
                variables: {
                  id,
                  patch: {
                    folderId,
                    deletedAt,
                  },
                },
              });
            })
          );

          setSelection([]);
        }
      } else if (source.type === "folder") {
        if (target.type === "folder") {
          if (target.path.includes(source.id)) {
            // don't allow dropping folder into child of itself
            console.log("cant drop");
            return;
          }

          const deletedAt =
            target.id === "trash" ? new Date().toISOString() : undefined;
          const parentId =
            target.id === "trash"
              ? undefined
              : target.id === ""
              ? null
              : target.id;

          updateFolder({
            variables: {
              id: source.id,
              patch: {
                deletedAt,
                parentId,
              },
            },
          });
        }
      }
    }
    setActive(undefined);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 16 } })
  );

  return (
    <ProjectLayout>
      <Helmet title={["Media", project.name].join(" - ")} />
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <TwoColumnLayout
          left={<MediaFolderListController />}
          right={
            <Routes>
              <Route
                path=":id"
                element={
                  <MediaItemListController
                    selection={selection}
                    setSelection={setSelection}
                  />
                }
              />
              <Route
                path=""
                element={
                  <MediaItemListController
                    selection={selection}
                    setSelection={setSelection}
                  />
                }
              />
            </Routes>
          }
        />

        <DragOverlay>
          {active ? (
            <span id="media_drag_ghost">Moving {active.type}</span>
          ) : null}
        </DragOverlay>
      </DndContext>
    </ProjectLayout>
  );
};
