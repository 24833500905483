import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useDetectOutsideClick } from "../../../hooks";
import { DownIcon } from "../../../icons/ui/down";
import { UpIcon } from "../../../icons/ui/up";
export var DropdownButton = function (_a) {
    var children = _a.children, renderContent = _a.renderContent;
    var containerRef = React.useRef(null);
    var _b = useDetectOutsideClick(containerRef, false), active = _b[0], setActive = _b[1];
    return (_jsxs("div", { className: "dropdown-button__container dropdown__container", ref: containerRef, children: [_jsxs("button", { className: "dropdown-button__trigger", onClick: function () { return setActive(!active); }, children: [_jsx("span", { className: "dropdown-button__trigger--text", children: children }), _jsx("span", { className: "dropdown-button__trigger--icon", children: active ? _jsx(UpIcon, {}) : _jsx(DownIcon, {}) })] }), active ? (_jsx("div", { className: "dropdown__content", children: renderContent === null || renderContent === void 0 ? void 0 : renderContent() })) : null] }));
};
