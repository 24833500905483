import * as React from "react";
import { useParams } from "react-router";
import {
  Account,
  Model,
  Project,
  ProjectEnvironment,
  useListModelsQuery,
  useWatchModelsSubscription,
} from "api/graphql";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import { useProject } from "..";
import { EnvironmentHome } from "./home";

export type EnvironmentContextProps = {
  environment: ProjectEnvironment;
  project: Project;
  account: Account;
  models: Model[];
  reload: () => void;
};

export const EnvironmentContext =
  React.createContext<EnvironmentContextProps | undefined>(undefined);

export const useEnvironmentOpt = (): EnvironmentContextProps | undefined =>
  React.useContext<EnvironmentContextProps | undefined>(EnvironmentContext);

export const useEnvironment = (): EnvironmentContextProps => {
  const context =
    React.useContext<EnvironmentContextProps | undefined>(EnvironmentContext);

  if (context === undefined) {
    throw new Error(
      "useEnvironment must be used within a EnvironmentContext.Provider"
    );
  }

  return context;
};

export const EnvironmentPage: React.FC = () => {
  const { project, account } = useProject();
  const { environment } = useParams();
  const env = project.projectEnvironments.nodes.find(
    (ent) => ent?.key === environment
  );

  const modelsQuery = useListModelsQuery({
    variables: {
      project: project.id,
      environment: env?.id,
    },
    skip: !env,
  });

  useWatchModelsSubscription({
    variables: {
      project: project.id,
    },
    onSubscriptionData: () => {
      modelsQuery.refetch();
    },
  });

  if (!env) {
    return (
      <SimpleLayout>
        <p>The requested environment cannot be found.</p>
      </SimpleLayout>
    );
  }

  const props: EnvironmentContextProps = {
    environment: env!,
    account,
    project,
    models: (modelsQuery.data?.list?.nodes as Model[]) ?? [],
    reload: () => {
      modelsQuery.refetch();
    },
  };

  return (
    <EnvironmentContext.Provider value={props}>
      <EnvironmentHome />
    </EnvironmentContext.Provider>
  );
};
