import * as React from "react";
import cx from "classnames";
import { IconButton, Icons } from "pokko-shared";
import { FieldInputProps } from "components/components/fields/types";
import { Single } from "./components/Single";
import { Multiline } from "./components/Multiline";
import { Markdown } from "./components/Markdown";
import { Date } from "./components/Date";
import { Number } from "./components/Number";
import { Boolean } from "./components/Boolean";
import { Config } from ".";
import { Richtext } from "./components/Richtext";

const InputContainer: React.FC<
  React.PropsWithChildren<{ onRemove?: () => void }>
> = ({ children, onRemove }) => {
  return (
    <div className={cx("scalar-editor", { "--removable": onRemove })}>
      {children}
      {onRemove ? (
        <IconButton type="button" kind="tertiary" onClick={onRemove}>
          <Icons.DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export const ScalarInput: React.FC<FieldInputProps<Config>> = (props) => {
  const { config } = props;

  switch (config.type) {
    case "date":
      return (
        <InputContainer onRemove={props.onRemove}>
          <Date {...props} />
        </InputContainer>
      );
    case "number":
      return (
        <InputContainer onRemove={props.onRemove}>
          <Number {...props} />
        </InputContainer>
      );
    case "boolean":
      return (
        <InputContainer onRemove={props.onRemove}>
          <Boolean {...props} />
        </InputContainer>
      );
    case "richtext":
      return (
        <InputContainer onRemove={props.onRemove}>
          <Richtext {...props} />
        </InputContainer>
      );
    case "text":
    default:
      if (config.multiline) {
        if (config.markdown) {
          return (
            <InputContainer onRemove={props.onRemove}>
              <Markdown {...props} />
            </InputContainer>
          );
        }

        return (
          <InputContainer onRemove={props.onRemove}>
            <Multiline {...props} />
          </InputContainer>
        );
      }

      return (
        <InputContainer onRemove={props.onRemove}>
          <Single {...props} />
        </InputContainer>
      );
  }
};
