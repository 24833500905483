import * as React from "react";
import { ValueField, ModelFieldsInherited } from "api/graphql";
import { FieldEditorProps } from "./FieldEditor";
import { useValueEditorContext } from "./ValueEditorContext";
import { useEnvironment } from "routes/accounts/projects/environments";

export type Model = {
  alias: string;
  name: string;
};

type Field = FieldEditorProps & { model: string };

export const useValueEditor = (id: string) => {
  const { models: allModels } = useEnvironment();
  const context = useValueEditorContext();
  const [model, setModel] = React.useState<string | null>();

  const { edit } = context;

  React.useEffect(() => {
    edit(id);
  }, [edit, id]);

  const valueContext = context.findValue(id);
  const value = valueContext?.value;
  const valueModel = allModels.find((mod) => mod.id === value?.modelId)!;

  const models = React.useMemo(
    () =>
      valueModel
        ? [{ name: valueModel.name, alias: valueModel.alias }]
            .concat(
              valueModel.parentsAll.nodes
                .map((ent) => ent?.parent!)
                .map((ent) => ({ name: ent?.name!, alias: ent?.alias! })) ?? []
            )
            .filter((ent) => !!ent.alias) // TODO: this probably shouldn't be necessary
            .filter((ent) =>
              valueModel?.fieldsAll.nodes.find(
                (fld) => fld?.sourceModel?.alias === ent.alias
              )
            )
        : [],
    [valueModel]
  );

  React.useEffect(() => {
    setModel(models.length > 0 ? models[0].alias : null);
  }, [models]);

  // const fieldValueBase = React.useCallback(
  //   (id: string): ValueFieldValue[] => {
  //     const direct = [
  //       (valueContext?.value?.model?.valueBase?.fields.nodes.filter(
  //         (ent) => ent?.modelFieldId === id
  //       ) ?? []) as ValueFieldValue[],
  //     ];
  //     const inherited =
  //       valueContext?.value?.model?.parentsAll.nodes
  //         .map(
  //           (par) =>
  //             (par?.parent?.valueBase?.fields.nodes.filter(
  //               (ent) => ent?.modelFieldId === id
  //             ) ?? []) as ValueFieldValue[]
  //         )
  //         .filter((ent) => (ent?.length ?? 0) > 0) ?? [];

  //     return (direct.concat(inherited).find((ent) => ent.length > 0) ??
  //       []) as ValueFieldValue[];
  //   },
  //   [
  //     valueContext?.value?.model?.parentsAll.nodes,
  //     valueContext?.value?.model?.valueBase?.fields.nodes,
  //   ]
  // );

  const fieldEditors: Field[] = React.useMemo(
    () =>
      valueModel?.fieldsAll.nodes.map(
        (ent) =>
          ({
            field: ent as ModelFieldsInherited,
            label: ent?.name!,
            model: ent?.sourceModel?.alias!,
            valueId: id,
            value: valueContext?.fields.filter(
              (valFld) => valFld?.modelFieldId === ent?.id
            ) as ValueField[],
            valueAll: valueContext?.fields as ValueField[],
            valueBase: [] as any, //fieldValueBase(ent?.id),
            onClear: () => context.onClear(id, ent?.id),
            onChange: (val) => context.onChange(id, ent?.id, val),
          } as Field)
      ) ?? [],
    [
      context,
      // fieldValueBase,
      id,
      valueContext?.fields,
      valueModel?.fieldsAll.nodes,
    ]
  );

  return React.useMemo(
    () => ({
      loading: !valueContext,
      value,
      saving: false,
      fieldEditors,
      models,
      model,
      setModel,
    }),
    [fieldEditors, model, models, value, valueContext]
  );
};
