import {
  Account,
  Entry,
  Project,
  ProjectEnvironment,
  useGetEntryQuery,
} from "api/graphql";
import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import * as React from "react";
import { Navigate, Route, Routes, useParams } from "react-router";
import { useEnvironment } from "../..";
import { EntryVersionPage } from "./version";

export type EntryContextProps = {
  entry: Entry;
  links: any[];
  environment: ProjectEnvironment;
  project: Project;
  account: Account;
  reload: () => Promise<void>;
};

export const EntryContext = React.createContext<EntryContextProps>({
  entry: {} as any,
  links: [],
  environment: {} as any,
  project: {} as any,
  account: {} as any,
  reload: () => Promise.resolve(),
});

export const useEntry = (): EntryContextProps =>
  React.useContext<EntryContextProps>(EntryContext);

export const EntryPage: React.FC = () => {
  const { environment, project, account } = useEnvironment();
  const { entry } = useParams();
  const query = useGetEntryQuery({
    fetchPolicy: "network-only",
    variables: {
      project: project.id,
      environment: environment.id,
      id: entry,
    },
  });

  if (query.loading) {
    return null;
  }

  if (!query.data?.entity) {
    return (
      <SimpleLayout>
        <p>The requested entry cannot be found.</p>
      </SimpleLayout>
    );
  }

  const props: EntryContextProps = {
    entry: query.data.entity as Entry,
    environment,
    account,
    project,
    links: (query.data.links || []) as any[],
    reload: async () => {
      await query.refetch();
    },
  };

  return (
    <EntryContext.Provider value={props}>
      <Routes>
        <Route
          path=""
          element={
            query.data.entity.versions.nodes.length > 0 ? (
              <Navigate
                to={`versions/${query.data.entity.versions.nodes[0]?.id}`}
              />
            ) : null
          }
        />
        <Route path="versions/:version/*" element={<EntryVersionPage />} />
      </Routes>
    </EntryContext.Provider>
  );
};
