import * as React from "react";
import cx from "classnames";
import { IconButton, Icons } from "pokko-shared";
import { MediaType } from "api/graphql";
import { fileSize, duration } from "helpers";
import { useDraggable } from "@dnd-kit/core";

export type MediaCardProps = React.PropsWithChildren<{
  id: string;
  project: string;
  mediaInfo: any;
  mediaType: MediaType;
  size: number;
  name: string;
  selected?: boolean;
  expanded?: boolean;
  uploaded: boolean;
  onClick?: React.MouseEventHandler;
  onDoubleClick?: React.MouseEventHandler;
  onSelect?: React.ChangeEventHandler<HTMLInputElement>;
  onDelete?: React.MouseEventHandler;
}>;

export const MediaCard: React.FC<MediaCardProps> = (props) => {
  const {
    children,
    mediaInfo,
    mediaType,
    name,
    selected,
    expanded,
    size,
    uploaded,
    onClick,
    onSelect,
    onDelete,
    id,
  } = props;

  const draggable = useDraggable({
    id: `item-${id}`,
    data: { type: "selection" },
    disabled: !selected,
  });

  const [copied, setCopied] = React.useState(false);

  const handleCopyId = (ev: React.MouseEvent) => {
    ev.preventDefault();
    navigator.clipboard.writeText(id);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <button
      className={cx("media-card__item", {
        "--selected": selected,
        "--uploaded": uploaded,
        "--expanded": expanded,
        "--clickable": onClick,
        "--selectable": onSelect,
      })}
      ref={draggable.setNodeRef}
      {...draggable.listeners}
      {...draggable.attributes}
    >
      {onSelect ? (
        <>
          <input
            type="checkbox"
            checked={selected}
            onChange={onSelect}
            className="checkbox"
            id={`item-${id}`}
          />
          <label htmlFor={`item-${id}`} />
        </>
      ) : null}
      <div className="media-card__item-name">
        <MediaCardThumbnail {...props} />
        <div className="media-card__item-meta">
          <span>{name}</span>
          {mediaInfo ? (
            <MediaInfo
              mediaInfo={mediaInfo}
              mediaType={mediaType}
              size={size}
            />
          ) : null}
          {expanded ? (
            <div className="media-card__item-expanded">
              {mediaType === MediaType.Image ? (
                <img
                  src={`https://cdn.pokko.io/${props.project}/${id}/$/800/800/cover/centre`}
                  alt={name}
                />
              ) : null}
              <code onClick={handleCopyId}>
                Media ID: {id} {copied ? <small>copied</small> : null}
              </code>
            </div>
          ) : null}
        </div>
        {onDelete ? (
          <IconButton kind="tertiary" onClick={onDelete}>
            <Icons.TrashIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </div>
      {children}
    </button>
  );
};

export const mediaIconMap: { [type: string]: React.ReactNode } = {
  [MediaType.Image]: <Icons.MediaImage />,
  [MediaType.Video]: <Icons.MediaVideo />,
  [MediaType.Document]: <Icons.MediaOther />,
  [MediaType.Unknown]: <Icons.MediaOther />,
};

const MediaCardThumbnail: React.FC<MediaCardProps> = ({
  id,
  mediaType,
  name,
  project,
  onClick,
  onDoubleClick,
}) => {
  if (mediaType === MediaType.Image) {
    const url = `https://cdn.pokko.io/${project}/${id}/$/72/72/cover/centre`;
    return (
      <img
        src={url}
        alt={name}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      />
    );
  }
  return (
    <div onClick={onClick} onDoubleClick={onDoubleClick}>
      {mediaIconMap[mediaType]}
    </div>
  );
};

const MediaInfo: React.FC<
  Pick<MediaCardProps, "mediaInfo" | "mediaType" | "size">
> = ({ mediaType, mediaInfo, size }) => {
  switch (mediaType) {
    case MediaType.Image:
      return (
        <ul>
          <li>
            {mediaInfo.height}&times;{mediaInfo.width}px
          </li>
          <li>{mediaInfo.format}</li>
          <li>{fileSize(size)}</li>
        </ul>
      );
    case MediaType.Video:
      return (
        <ul>
          <li>
            {mediaInfo.height}&times;{mediaInfo.width}px
          </li>
          <li>{duration(mediaInfo.duration)}</li>
          <li>{mediaInfo.format}</li>
          <li>{fileSize(size)}</li>
        </ul>
      );
  }
  return null;
};
