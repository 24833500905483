import * as React from "react";
import ReactMarkdown from "react-markdown";
import { TextArea } from "pokko-shared";
import { FieldInputProps } from "../../types";

export const Markdown: React.FC<FieldInputProps> = ({
  value,
  onChange,
  disabled,
  readOnly,
}) => {
  const [preview, setPreview] = React.useState(true);

  return (
    <div className="markdown-editor">
      <div className="markdown-editor__input">
        <TextArea
          value={value.valueScalar?.text ?? ""}
          onChangeText={(text: string) =>
            onChange({
              ...value,
              valueScalar: { text },
            })
          }
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
      <div className="markdown-editor__preview">
        <div className="markdown-editor__preview-header">
          <strong>Preview</strong>
          <button onClick={() => setPreview(!preview)}>
            {preview ? "Hide preview" : "Show preview"}
          </button>
        </div>
        {preview ? (
          <div className="markdown-editor__preview-body">
            <ReactMarkdown>{value.valueScalar?.text ?? ""}</ReactMarkdown>
          </div>
        ) : null}
      </div>
    </div>
  );
};
