import * as React from "react";
import { CheckboxField, Input } from "pokko-shared";
import { useEnvironment } from "routes/accounts/projects/environments";
import { ModelUsage } from "api/graphql";

export type ModelSelectorProps = {
  value: string[];
  onChange(value: string[]): void;
  single?: boolean;
  usage?: ModelUsage;
};

const tryCreateRegExp = (pattern: string, flags?: string) => {
  try {
    return new RegExp(pattern, flags);
  } catch {
    return null;
  }
};

export const ModelSelector: React.FC<ModelSelectorProps> = ({
  value,
  onChange,
  single,
  usage,
}) => {
  const { models } = useEnvironment();
  const [filter, setFilter] = React.useState("");

  const handleToggle = (id: string) => () => {
    if (single) {
      if (value.indexOf(id) !== -1) {
        onChange([]);
      } else {
        onChange([id]);
      }
    } else {
      onChange(
        value.indexOf(id) === -1
          ? [...value, id]
          : value.filter((x) => x !== id)
      );
    }
  };

  const availableModels = models.filter((ent) => !ent.deletedAt);

  const filterRe = filter ? tryCreateRegExp(filter, "i") : null;

  const filteredModels = availableModels
    .filter((ent) => !usage || ent.usage === usage)
    .filter((ent) => !filterRe || filterRe.test(ent.name));

  return (
    <div className="model-selector">
      {models.length > 5 ? (
        <Input
          placeholder="Search..."
          onChangeText={setFilter}
          type="search"
          value={filter}
        />
      ) : null}
      {filteredModels.length === 0 ? (
        <p>No models match the criteria.</p>
      ) : (
        filteredModels.map((ent) => (
          <CheckboxField
            id={ent.id}
            key={ent.id}
            label={ent.name}
            checked={value.indexOf(ent.id) !== -1}
            onChange={handleToggle(ent.id)}
          />
        ))
      )}
    </div>
  );
};
