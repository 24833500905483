import * as React from "react";
import { Link } from "react-router-dom";
import { IconLinkButton, Icons } from "pokko-shared";
import { Model, ModelFieldsInherited } from "api/graphql";
import { useFields } from "providers/content/fields";
import { ModelFieldInput } from "./ModelFieldEditor";
import { Tag } from "components/elements/tag/Tag";

type FieldProps = {
  field: ModelFieldsInherited;
  inherited: boolean;
  saving: boolean;
  models: Model[];
  onSave?: (values: ModelFieldInput) => void;
};

export const ModelField: React.FC<FieldProps> = ({
  field,
  inherited,
  models,
}) => {
  const { fields: fieldEditors } = useFields();

  return (
    <li>
      <div className="model-field__container">
        <div className="model-field__detail">
          <h3>{field.name}</h3>
          <code>{field.alias}</code>
          <p>{fieldEditors[field.type!].displayName(field.config, models)}</p>
        </div>
        <div className="model-field__actions">
          {inherited ? (
            <Tag>
              Inherited from{" "}
              <Link
                to={`../../models/${field.sourceModel?.alias}/fields/${field.alias}`}
              >
                {field.sourceModel?.name}
              </Link>
            </Tag>
          ) : (
            <IconLinkButton kind="tertiary" to={`fields/${field.alias!}`}>
              <Icons.SettingsIcon />
            </IconLinkButton>
          )}
        </div>
      </div>
    </li>
  );
};
