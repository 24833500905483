import * as React from "react";
import { InputWithSelect, InlineRadioButtons, Button } from "pokko-shared";
import { useDebounce } from "use-debounce";
import { strings } from "strings";
import { EntriesOrderBy, useListEntriesQuery } from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";
import { EntryDetailCardButton } from "components/components/entries/card/EntryDetailCard";

export type EntrySearchProps = {
  allowedModels?: string[];
  value: string;
  onCancel?: () => void;
  onChange: (value: string) => void;
};

export const EntrySearch: React.FC<EntrySearchProps> = ({
  value,
  allowedModels,
  onChange,
  onCancel,
}) => {
  const [search, setSearch] = React.useState("");
  const [model, setModel] = React.useState("");
  const [selected, setSelected] = React.useState("");
  const [status, setStatus] = React.useState("All");
  const [searchDeb] = useDebounce(search, 250);
  const { project, environment, models } = useEnvironment();

  const query = useListEntriesQuery({
    variables: {
      project: project.id,
      environment: environment.id,
      filter: {
        model: {
          deletedAt: { isNull: true },
        },
        modelId: model
          ? {
              equalTo: model,
            }
          : allowedModels
          ? {
              in: allowedModels,
            }
          : undefined,
        name: searchDeb
          ? {
              likeInsensitive: `%${searchDeb}%`,
            }
          : undefined,
        entryPublishesExist:
          status === "All" ? undefined : status === "Published",
      },
      take: 5,
      sort: [EntriesOrderBy.ModifiedAtDesc],
    },
  });

  const handleSave = () => {
    onChange(selected);
  };

  return (
    <div className="entry-search__container">
      <InputWithSelect
        input={{
          type: "search",
          placeholder: "Search entries",
          value: search,
          onChange: (ev: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(ev.currentTarget.value),
        }}
        select={{
          value: model,
          onChange: (ev: React.ChangeEvent<HTMLSelectElement>) =>
            setModel(ev.currentTarget.value),
          disabled: (allowedModels?.length ?? 0) === 0,
          children: [<option key={-1} children="Model" />].concat(
            allowedModels?.map((ent) => (
              <option key={ent} value={ent}>
                {models.find((mod) => mod.id === ent)!.name}
              </option>
            )) ?? []
          ),
        }}
      />
      <InlineRadioButtons
        values={["All", "Published", "Unpublished"]}
        value={status}
        onChange={setStatus}
      />
      <div className="entry-search__results">
        {query.data?.list?.nodes.map((ent) => (
          <EntryDetailCardButton
            key={ent?.id}
            button={{ children: "", onClick: () => setSelected(ent?.id) }}
            entry={{
              model: models.find((mod) => mod.id === ent?.modelId)?.name!,
              modified: new Date(ent?.modifiedAt + "Z"),
              name: ent?.name!,
              published: (ent?.entryPublishes?.totalCount ?? 0) > 0,
              active: selected === ent?.id,
              selected: value === ent?.id,
              selectable: true,
            }}
          />
        ))}
      </div>
      <div className="entry-search__footer">
        <Button onClick={handleSave} type="button" kind="primary">
          Select entry
        </Button>
        {onCancel ? (
          <Button onClick={onCancel} type="button">
            {strings.cancel}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
