import * as React from "react";
import { Link } from "react-router-dom";

export type TagProps = React.PropsWithChildren<{
  link?: string;
}>;

export const Tag: React.FC<TagProps> = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link} className="tag">
        {children}
      </Link>
    );
  }

  return <div className="tag">{children}</div>;
};
