import * as React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router";
import {
  useCreateWebhookRunMutation,
  useGetWebhookQuery,
  useUpdateWebhookMutation,
} from "api/graphql";
import { WebhookDetail } from "components/components/webhooks/detail/WebhookDetail";
import {
  Header,
  WebhookSettings,
  WebhookSettingsInput,
} from "components/components/webhooks/settings/WebhookSettings";
import { WebhookRunController } from "./WebhookRunController";

const headersToArray = (input: any): Header[] =>
  Object.keys(input || {}).map((key) => ({ key, value: input[key] }));

const headersFromArray = (input?: Header[]): any =>
  (input ?? [])
    .filter((ent) => Boolean(ent.key) && Boolean(ent.value))
    .reduce((p, c) => ({ ...p, [c.key]: c.value }), {});

export const WebhookDetailController: React.FC = () => {
  const { webhook } = useParams();
  const navigate = useNavigate();
  const query = useGetWebhookQuery({ variables: { id: webhook } });
  const [update, updateStatus] = useUpdateWebhookMutation();
  const [createRun, createRunStatus] = useCreateWebhookRunMutation();

  const handleTrigger = async () => {
    await createRun({ variables: { webhook } });
  };

  const handleSave = async (value: WebhookSettingsInput) => {
    const headers = headersFromArray(value.headers);

    await update({
      variables: {
        id: webhook,
        patch: {
          name: value.name,
          headers,
          method: value.method,
          payload: value.payload,
          url: value.url,
        },
      },
    });

    navigate(".");
  };

  if (!query.data?.entity) {
    return null;
  }

  const { entity } = query.data;

  return (
    <Routes>
      <Route
        element={
          <WebhookDetail
            name={entity.name!}
            trigger={[handleTrigger, createRunStatus]}
            runs={
              entity.webhookRuns.nodes.map((ent) => ({
                id: ent?.id!,
                created: new Date(ent?.createdAt + "Z"),
                status: ent?.status!,
              })) ?? []
            }
          />
        }
      />
      <Route
        path="settings"
        element={
          <WebhookSettings
            value={{
              name: entity.name!,
              headers: headersToArray(entity.headers!),
              method: entity.method!,
              payload: entity.payload!,
              url: entity.url!,
            }}
            save={[handleSave, updateStatus]}
          />
        }
      />
      <Route path="runs/:run" element={<WebhookRunController />} />
    </Routes>
  );
};
