import * as React from "react";

export type User = {
  token: string;
};

type UserContextProps = {
  user: User;
};

const authUrlMap: { [hostname: string]: string } = {
  localhost: "http://localhost:3110",
  "app.pokko.io": "https://id.pokko.io",
};

export const authUrl =
  process.env.REACT_APP_AUTH_URL ??
  (typeof window !== "undefined"
    ? authUrlMap[window.location.hostname]
    : undefined) ??
  authUrlMap["app.pokko.io"];

const loadUser = (): User | null => {
  try {
    const token = document.cookie
      .split("; ")
      .map((s) => s.split("="))
      .reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {} as any)["pok_token"];

    if (!token) {
      return null;
    }

    return { token };
  } catch (ex) {
    return null;
  }
};

export const UserContext = React.createContext<UserContextProps>({
  user: { token: "" },
});
export const UserProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const user = loadUser();

  if (!user) {
    window.location.href = `${authUrl}/login?returnUrl=${encodeURIComponent(
      window.location.href
    )}`;

    return null;
  }

  const value: UserContextProps = {
    user,
  };

  return React.createElement(UserContext.Provider, { value, children });
};

export const useUser = () => React.useContext<UserContextProps>(UserContext);
