import * as React from "react";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import {
  InputField,
  SelectField,
  CheckboxField,
  InlineRadioButtons,
  Button,
  IconButton,
  Icons,
  LinkButton,
} from "pokko-shared";
import { camelCase } from "change-case";
import {
  IntRange,
  Model,
  ModelFieldsInherited,
  ModelFieldType,
} from "api/graphql";
import { strings } from "strings";
import { useFields } from "providers/content/fields";

export type ModelFieldInput = {
  name: string;
  alias: string;
  type: ModelFieldType;
  config: any;
  multi: boolean;
  required: boolean;
  valueCount: IntRange;
  modelId: string;
  autoSource?: string;
  autoOverride?: boolean;
  autoTransform?: string;
  autoFieldId?: string;
};

type ModelFieldEditorProps = {
  defaultValues?: ModelFieldInput;
  deleted: boolean;
  errors: FieldErrors<ModelFieldInput>;
  isNew?: boolean;
  loading?: boolean;
  models: Model[];
  restoring: boolean;
  saving: boolean;
  fields: ModelFieldsInherited[];
  onSave?: (values: ModelFieldInput) => void;
  onDelete?: () => void;
  onRestore?: () => void;
};

type FieldType = {
  key: string;
  name: string;
  type: ModelFieldType;
  configPatch: any;
  canAuto?: boolean;
};

const fieldTypes: FieldType[] = [
  {
    key: "text",
    name: "Plain text",
    type: ModelFieldType.Scalar,
    configPatch: { type: "text" },
    canAuto: true,
  },
  {
    key: "richtext",
    name: "Rich text",
    type: ModelFieldType.Scalar,
    configPatch: { type: "richtext" },
  },
  {
    key: "date",
    name: "Date",
    type: ModelFieldType.Scalar,
    configPatch: { type: "date" },
  },
  {
    key: "number",
    name: "Numeric",
    type: ModelFieldType.Scalar,
    configPatch: { type: "number" },
  },
  {
    key: "boolean",
    name: "Boolean (yes/no, true/false)",
    type: ModelFieldType.Scalar,
    configPatch: { type: "boolean" },
  },
  {
    key: "entry",
    name: "Linked content",
    type: ModelFieldType.Entry,
    configPatch: {},
  },
  {
    key: "value",
    name: "Modular content",
    type: ModelFieldType.Value,
    configPatch: {},
  },
  {
    key: "media",
    name: "Media",
    type: ModelFieldType.Media,
    configPatch: {},
  },
];

const parseFieldType = (input?: ModelFieldInput) => {
  if (input?.type) {
    return (
      fieldTypes.find(
        (ent) =>
          ent.type === input.type &&
          (!input.config?.type || ent.configPatch.type === input.config.type)
      ) ?? fieldTypes[0]
    ).key;
  }

  return fieldTypes[0].key;
};

export const ModelFieldEditor: React.FC<ModelFieldEditorProps> = (props) => {
  const {
    defaultValues,
    errors: saveErrors,
    models,
    isNew,
    onSave,
    fields,
  } = props;

  const {
    control,
    formState: { errors, touchedFields, dirtyFields },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<ModelFieldInput & { typeInner: string }>({
    defaultValues: {
      ...defaultValues,
      typeInner: parseFieldType(defaultValues),
    },
  });

  const [config, setConfig] = React.useState(defaultValues?.config);

  const { fields: fieldEditors } = useFields();

  const [type, name, typeInner, valueCount, autoSource] = watch([
    "type",
    "name",
    "typeInner",
    "valueCount",
    "autoSource",
  ]);

  const fieldEditor = fieldEditors[type];
  const editor = fieldEditor?.config;

  React.useEffect(() => {
    if (name && !defaultValues?.alias && (isNew || !touchedFields.alias)) {
      setValue("alias", camelCase(name.replace(/([^\w\s]+)/g, "")), {
        shouldValidate: true,
      });
    }
  }, [name, setValue, touchedFields, defaultValues, isNew]);

  React.useEffect(() => {
    if (typeInner) {
      const { type, configPatch } = fieldTypes.find(
        (ent) => ent.key === typeInner
      )!;

      setConfig({ ...defaultValues?.config, ...configPatch });
      setValue("type", type, {
        shouldValidate: true,
      });
    }
  }, [typeInner, setValue, defaultValues]);

  const canAuto = fieldTypes.find((ent) => ent.key === typeInner)?.canAuto;

  return (
    <form
      onSubmit={
        onSave
          ? handleSubmit(({ typeInner, ...rest }) => {
              onSave({ ...rest, config });
            })
          : undefined
      }
      className="model-field-editor__container"
    >
      <div className="model-field-editor__detail">
        <InputField
          label="Name"
          error={errors.name || saveErrors.name}
          autoFocus
          {...register("name", { required: strings.required })}
        />
        <InputField
          label="Alias"
          error={errors.alias || saveErrors.alias}
          help={
            !isNew && dirtyFields.alias ? strings.models.dataModelWarning : ""
          }
          {...register("alias", {
            required: strings.required,
            pattern: {
              value: /^[a-z][a-zA-Z0-9]{2,}$/,
              message:
                "Alias must start with an lower-case letter, be at least three characters long and only contain letters and numbers",
            },
          })}
        />
      </div>

      <div className="model-field-editor__configuration">
        <SelectField
          label="Type"
          error={errors.typeInner || saveErrors.type}
          help={dirtyFields.typeInner ? strings.models.dataModelWarning : ""}
          {...register("typeInner", { required: strings.required })}
        >
          <option />
          {fieldTypes.map((ent) => (
            <option key={ent.key} value={ent.key}>
              {ent.name}
            </option>
          ))}
        </SelectField>
        <input type="hidden" {...register("type")} />

        {autoSource ? null : (
          <CheckboxField
            id="required"
            label="Required"
            {...register("required")}
          />
        )}

        {editor ? (
          <div className="model-field-editor__configuration-editor">
            {React.createElement(editor, {
              config,
              onChange: (ev) => setConfig(ev),
            })}
          </div>
        ) : null}

        {fieldEditor?.disableMulti?.(config) ? null : (
          <div className="model-field-editor__value-count">
            <Controller
              render={({ field: { value, onChange } }) => (
                <InlineRadioButtons
                  values={[false, true]}
                  value={value}
                  render={(val) => (val ? "Multiple values" : "Single value")}
                  onChange={onChange}
                />
              )}
              control={control}
              name="multi"
              defaultValue={false}
            />
            {watch("multi") ? (
              <div className="model-field-editor__value-count-range">
                <InputField
                  label="Minimum"
                  type="number"
                  min="0"
                  step="1"
                  help="Optional. Specify the minimum valid number of values for this field"
                  // error={errors.valueCount?.start}
                  {...register("valueCount.start.value", {
                    validate: (value) =>
                      !valueCount?.end?.value ||
                      parseInt(value as any, 10) <= valueCount.end?.value,
                  })}
                />
                <InputField
                  label="Maximum"
                  type="number"
                  min="0"
                  step="1"
                  help="Optional. Specify the maximum valid number of values for this field"
                  // error={errors.valueCount?.end}
                  {...register("valueCount.end.value", {
                    validate: (value) =>
                      !valueCount?.start?.value ||
                      parseInt(value as any, 10) >= valueCount.start?.value,
                  })}
                />
              </div>
            ) : null}
          </div>
        )}

        {canAuto ? <hr /> : null}
        {canAuto ? (
          <SelectField
            label="Automatic source"
            help="Optional - specify automatic source for this field"
            {...register("autoSource")}
          >
            <option />
            <option value="name">Entry name</option>
            <option value="created_at">Created date</option>
            <option value="modified_at">Modified date</option>
            <option value="field">Another field</option>
          </SelectField>
        ) : null}

        {canAuto && autoSource === "field" ? (
          <SelectField
            label="Automatic source field"
            {...register("autoFieldId")}
          >
            <option />
            {fields
              .filter((fld) => !fld.autoSource)
              .filter(
                (fld) => fld.type === type && fld.config?.type === config?.type
              )
              .map((fld) => (
                <option key={fld.id} value={fld.id}>
                  {fld.name}
                </option>
              ))}
          </SelectField>
        ) : null}

        {canAuto && autoSource ? (
          <SelectField
            label="Automatic field transform"
            help="Apply a transform to the automatic field"
            {...register("autoTransform")}
          >
            <option />
            <option value="kebab">
              Slugify ("Hello world" becomes "hello-world")
            </option>
            <option value="camel">
              Camel-case ("Hello world" becomes "helloWorld")
            </option>
            <option value="pascal">
              Pascal-case ("Hello world" becomes "HelloWorld")
            </option>
            <option value="snake">
              Underscore ("Hello world" becomes "hello_world")
            </option>
            <option value="upper">
              Upper-case ("Hello world" becomes "HELLO WORLD")
            </option>
            <option value="lower">
              Lower-case ("Hello world" becomes "hello world")
            </option>
          </SelectField>
        ) : null}

        <hr />

        {isNew ? null : (
          <SelectField
            label="Model"
            error={errors.modelId}
            help={
              dirtyFields.modelId
                ? strings.models.dataModelWarning
                : "Relocate this field to another model"
            }
            {...register("modelId", { required: strings.required })}
          >
            {models
              .filter((ent) => !Boolean(ent.deletedAt))
              .map((ent) => (
                <option key={ent.id} value={ent.id}>
                  {ent.name}
                </option>
              ))}
          </SelectField>
        )}
      </div>
      <div className="model-field-editor__actions">
        <Actions {...props} />
      </div>
    </form>
  );
};

const Actions: React.FC<ModelFieldEditorProps> = ({
  deleted,
  loading,
  restoring,
  onDelete,
  onRestore,
}) => {
  const [deleting, setDeleting] = React.useState(false);

  if (deleting) {
    return (
      <>
        <Button
          key="confirm"
          kind="tertiary"
          type="button"
          onClick={onDelete}
          loading={loading}
        >
          {strings.confirm}
        </Button>
        <Button
          key="cancel"
          type="button"
          kind="primary"
          onClick={() => setDeleting(false)}
        >
          {strings.cancel}
        </Button>
        <small>
          Deleting this field will delete all content associated with it.
        </small>
      </>
    );
  }

  if (deleted) {
    return (
      <Button
        key="restore"
        kind="primary"
        onClick={onRestore}
        loading={restoring}
      >
        {strings.restore}
      </Button>
    );
  }

  return (
    <>
      <Button key="save" type="submit" kind="primary" loading={loading}>
        {strings.save}
      </Button>
      {onDelete ? (
        <IconButton
          key="delete"
          type="button"
          kind="tertiary"
          onClick={() => setDeleting(true)}
        >
          <Icons.DeleteIcon />
        </IconButton>
      ) : null}
      <LinkButton to="../.." kind="tertiary">
        Cancel
      </LinkButton>
    </>
  );
};
