import * as React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

export type BreadcrumbPath = { label: string; url?: string }[];

export type BreadcrumbProps = {
  size?: "small" | "regular";
  path: BreadcrumbPath;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ path, size }) => (
  <ul className={cx("breadcrumb", { [`--${size}`]: size })}>
    {path.map((ent, idx) => (
      <li key={idx}>
        {ent.url ? (
          <Link to={ent.url}>{ent.label}</Link>
        ) : (
          <span>{ent.label}</span>
        )}
      </li>
    ))}
  </ul>
);
