import * as React from "react";
import { Icons } from "pokko-shared";
import {
  MediaUploadWidgetItem,
  MediaUploadWidgetItemProps,
} from "./components/MediaUploadWidgetItem";

export type MediaUploadWidgetProps = {
  onClose: () => void;
  closable: boolean;
  items: MediaUploadWidgetItemProps[];
};

export const MediaUploadWidget: React.FC<MediaUploadWidgetProps> = ({
  closable,
  items,
  onClose,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (items.length === 0) {
      setExpanded(false);
    }
  }, [items]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="media-upload-widget__container">
      <div className="media-upload-widget__header">
        <strong>Uploads</strong>
        <div className="media-upload-widget__header-actions">
          <button
            onClick={() => setExpanded(!expanded)}
            disabled={items.length === 0}
          >
            {expanded ? <Icons.DownIcon /> : <Icons.UpIcon />}
          </button>
          <button onClick={onClose} disabled={!closable}>
            <Icons.CloseIcon />
          </button>
        </div>
      </div>
      {expanded ? (
        <div className="media-upload-widget__body">
          {items.map((ent) => (
            <MediaUploadWidgetItem key={ent.id} {...ent} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
