import * as React from "react";
import { EntryListProps } from "../EntryList";

export const EntryListFilters: React.FC<EntryListProps> = ({ models }) => {
  const handleToggle = (id: string) => () => {
    const add = models.value.indexOf(id) === -1;
    if (add) {
      models.onChange([...models.value, id]);
    } else {
      models.onChange(models.value.filter((x) => x !== id));
    }
  };
  return (
    <div className="entry-list__filters">
      <div className="entry-list__filters-body">
        <strong>Model filters</strong>
        <ul className="entry-list__filters-models">
          {models.list.map((ent) => (
            <li key={ent.id}>
              <input
                className="checkbox"
                type="checkbox"
                id={`cb-${ent.id}`}
                onChange={handleToggle(ent.id)}
                checked={models.value.indexOf(ent.id) !== -1}
              />
              <label htmlFor={`cb-${ent.id}`}>{ent.name}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
