import * as React from "react";
import { Entry, useCreateEntryMutation, useGetEntryQuery } from "api/graphql";
import { useEnvironment } from "routes/accounts/projects/environments";
import { EntrySearch } from "../../fields/entry/components/EntrySearch";
import { EntryDetailCardDisplay } from "../card/EntryDetailCard";
import { useForm } from "react-hook-form";
import {
  Button,
  IconButton,
  InputWithSelect,
  Message,
  Icons,
} from "pokko-shared";

export type EntryInputControllerProps = {
  allowed?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  value: string;
  valueEntry?: Entry;
  onChange: (value: string | null) => void;
  onRemove?: () => void;
};

export const EntryInputController: React.FC<EntryInputControllerProps> = ({
  allowed,
  disabled,
  readOnly,
  value,
  valueEntry,
  onChange,
  onRemove,
}) => {
  const [searching, setSearching] = React.useState(false);
  const [creating, setCreating] = React.useState(false);
  const { project, environment, models } = useEnvironment();

  const selected = useGetEntryQuery({
    variables: {
      id: value,
      project: project.id,
      environment: environment.id,
    },
    skip: !value || value === valueEntry?.id,
  });

  const [create, createStatus] = useCreateEntryMutation();

  if (
    !searching &&
    value &&
    (selected.loading || !selected.data?.entity) &&
    value !== valueEntry?.id
  ) {
    return null;
  }

  const handleChange = (id: string | null) => {
    onChange(id);

    setSearching(false);
  };

  const selectedEntry =
    value === valueEntry?.id ? valueEntry : selected.data?.entity;

  if (searching) {
    return (
      <div className="entry-input__container">
        <EntrySearch
          value={value || ""}
          onChange={handleChange}
          allowedModels={allowed}
          onCancel={() => setSearching(false)}
        />
      </div>
    );
  }

  if (creating) {
    const handleCreate = async (value: EntryCreateInput) => {
      try {
        const res = await create({
          variables: {
            input: {
              name: value.name,
              modelId: value.model,
              projectId: project.id,
              environmentId: environment.id,
            },
          },
        });

        onChange(res.data?.create?.entity?.id);

        setCreating(false);
      } catch (ex) {
        console.warn("entry create failed", ex);
      }
    };
    return (
      <div className="entry-input__container">
        <EntryCreate
          onCancel={() => setCreating(false)}
          onCreate={handleCreate}
          allowed={allowed}
          loading={createStatus.loading}
          error={createStatus.error?.message}
        />
      </div>
    );
  }

  if (value) {
    return (
      <div className="entry-input__container">
        <div className="entry-input__value">
          {selected.loading ? (
            <small>Loading...</small>
          ) : (
            <EntryDetailCardDisplay
              link={`../../../${selectedEntry?.id}`}
              model={
                models.find((mod) => mod.id === selectedEntry?.modelId)?.name!
              }
              modified={new Date(selectedEntry?.modifiedAt + "Z")}
              name={selectedEntry?.name!}
              published={(selectedEntry?.entryPublishes?.totalCount ?? 0) > 0}
              onRemove={onRemove}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="entry-input__container">
      <div className="entry-input__input">
        <Button
          kind="primary"
          onClick={() => setSearching(true)}
          disabled={disabled || readOnly}
          type="button"
        >
          Select entry
        </Button>
        <Button
          kind="secondary"
          onClick={() => setCreating(true)}
          disabled={disabled || readOnly}
          type="button"
        >
          Create entry
        </Button>
        {onRemove ? (
          <IconButton
            kind="tertiary"
            onClick={onRemove}
            disabled={disabled || readOnly}
            type="button"
          >
            <Icons.TrashIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

type EntryCreateInput = {
  name: string;
  model: string;
};

type EntryCreateProps = {
  allowed?: string[];
  loading: boolean;
  error?: string;
  onCreate: (value: EntryCreateInput) => void;
  onCancel: () => void;
};

const EntryCreate: React.FC<EntryCreateProps> = ({
  allowed,
  loading,
  error,
  onCreate,
  onCancel,
}) => {
  const { models } = useEnvironment();
  const options = models
    .filter((mod) =>
      (allowed ?? []).length > 0 ? allowed?.includes(mod.id) : true
    )
    .map((mod) => ({ value: mod.id, key: mod.id, children: mod.name }));

  const { register, handleSubmit } = useForm<EntryCreateInput>({
    defaultValues: {
      model: options.length > 0 ? options[0].value : undefined,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onCreate)}
      className="entry-input__create-container"
    >
      <InputWithSelect
        input={{
          placeholder: "Entry name",
          ...register("name", {}),
        }}
        select={{
          children: options.map((opt) => <option {...opt} />),
          ...register("model", {}),
        }}
      />
      {error ? (
        <Message colour="danger" size="small">
          {error}
        </Message>
      ) : null}
      <div className="entry-input__create-actions">
        <Button kind="primary" type="submit" loading={loading}>
          Create entry
        </Button>
        <Button
          kind="tertiary"
          type="button"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
