import * as React from "react";
import { FieldConfigProps } from "components/components/fields/types";
import { Config } from ".";
import { ModelSelector } from "components/components/models/selector/ModelSelector";
import { ModelUsage } from "api/graphql";

export const EntryConfig: React.FC<FieldConfigProps<Config>> = ({
  config,
  onChange,
}) => (
  <>
    <label className="label">Allowed models</label>
    <ModelSelector
      value={config?.allowed ?? []}
      usage={ModelUsage.Entry}
      onChange={(allowed) => onChange({ ...config, allowed })}
    />
  </>
);
