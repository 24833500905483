import * as React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  LinkButton,
  InputField,
  TextAreaField,
  Input,
} from "pokko-shared";
import { strings } from "strings";

export type Header = { key: string; value: string };

export type WebhookSettingsInput = {
  name: string;
  method: string;
  url: string;
  headers: { key: string; value: string }[];
  payload: string;
};

export type WebhookSettingsProps = {
  value: WebhookSettingsInput;
  save: [(value: WebhookSettingsInput) => void, { loading: boolean }];
};

export const WebhookSettings: React.FC<WebhookSettingsProps> = ({
  value,
  save: [save, saveStatus],
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<WebhookSettingsInput>({
    defaultValues: value,
  });
  const headers = useFieldArray({
    control,
    name: "headers",
  });

  return (
    <form onSubmit={handleSubmit(save)} className="webhook-settings__container">
      <div className="webhook-settings__header">
        <h2 className="h2">{value.name}</h2>
        <div className="webhook-settings__header-actions">
          <LinkButton to="..">Cancel</LinkButton>
          <Button kind="primary" type="submit" loading={saveStatus.loading}>
            Save
          </Button>
        </div>
      </div>
      <div className="webhook-settings__form">
        <InputField
          label="Name"
          error={errors.name}
          {...register("name", { required: strings.required })}
        />
        <InputField
          label="Method"
          error={errors.method}
          {...register("method", { required: strings.required })}
        />
        <InputField
          label="URL"
          error={errors.url}
          {...register("url", { required: strings.required })}
        />
        <div className="webhook-settings__headers">
          <strong>Headers</strong>
          <div className="webhook-settings__headers-list">
            {headers.fields.map((ent, idx) => (
              <div className="webhook-settings__headers-item">
                <Input
                  defaultValue={ent.key}
                  {...register(`headers.${idx}.key` as const)}
                />
                <Input
                  defaultValue={ent.value}
                  {...register(`headers.${idx}.value` as const)}
                />
              </div>
            ))}
          </div>
          <div className="webhook-settings__headers-actions">
            <Button
              type="button"
              kind="tertiary"
              onClick={() => headers.append({ key: "", value: "" })}
            >
              Add header
            </Button>
          </div>
        </div>
        <TextAreaField
          label="Payload"
          error={errors.payload}
          {...register("payload")}
        />
      </div>
    </form>
  );
};
