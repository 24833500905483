import { Field } from "components/components/fields/types";
import { MediaConfig } from "./config";
import { MediaInput } from "./input";

export type Config = {
  startingFolder: string | null;
};

export const Media: Field = {
  name: "Media",
  config: MediaConfig,
  input: MediaInput,
  displayName: () => "Media",
};
