import { SimpleLayout } from "components/layout/simple/SimpleLayout";
import * as React from "react";
import { Navigate } from "react-router-dom";

import { useProject } from "..";

export const ProjectHome: React.FC = () => {
  const { project } = useProject();

  if (project.defaultEnvironment) {
    return <Navigate to={`environments/${project.defaultEnvironment.key}`} />;
  }

  return (
    <SimpleLayout>
      <p>This project is still being created.</p>
    </SimpleLayout>
  );
};
