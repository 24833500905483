import * as React from "react";
import { InputField } from "pokko-shared";
import { ProjectSettingsProps } from "../ProjectSettings";
import { authUrl } from "providers/user";

export const Rename: React.FC<ProjectSettingsProps> = ({
  name,
  projectKey,
  accountKey,
}) => (
  <div className="project-settings__name">
    <InputField
      label="Project name"
      value={name}
      disabled
      help={
        <>
          You can rename this project{" "}
          <a
            href={`${authUrl}/accounts/${accountKey}/projects/${projectKey}/settings`}
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </>
      }
    />
  </div>
);
