import { Field } from "./types";
import { Entry } from "./entry";
import { Media } from "./media";
import { Modules } from "./value";
import { Scalar } from "./scalar";
import { ModelFieldType } from "api/graphql";

export const Fields: { [type in ModelFieldType]: Field } = {
  [ModelFieldType.Entry]: Entry,
  [ModelFieldType.Media]: Media,
  [ModelFieldType.Value]: Modules,
  [ModelFieldType.Scalar]: Scalar,
};
