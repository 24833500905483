import * as React from "react";
import {
  Controller,
  FieldErrors,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  InputField,
  InlineRadioButtons,
  Button,
  Select,
  IconButton,
  LinkButton,
  Icons,
} from "pokko-shared";
import { pascalCase } from "change-case";

import { Model, ModelUsage } from "api/graphql";
import { strings } from "strings";
import { FieldOrder } from "./FieldOrder";

export type ModelSettingsProps = {
  id?: string;
  defaultValues?: ModelSettingsInput;
  deleted: boolean;
  errors: FieldErrors<ModelSettingsInput>;
  isNew: boolean;
  loading?: boolean;
  models?: Model[];
  restoring: boolean;
  onDelete?: () => void;
  onRestore?: () => void;
  onSubmit?: (values: ModelSettingsInput) => void;
};

type InheritanceItem = { id: string };

export type ModelSettingsInput = {
  name: string;
  alias: string;
  inheritance?: InheritanceItem[];
  usage: ModelUsage;
  fields: { id: string; name: string; alias: string; index: number }[];
};

export const ModelSettings: React.FC<ModelSettingsProps> = (props) => {
  const { models, onSubmit, defaultValues, isNew } = props;

  const {
    register,
    formState: { errors, touchedFields, dirtyFields },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<ModelSettingsInput>({
    defaultValues,
  });

  const inheritance = useFieldArray<any, string, "key">({
    control,
    name: "inheritance",
    keyName: "key",
  });

  const name = watch("name");

  React.useEffect(() => {
    if (name && !defaultValues?.alias && (isNew || !touchedFields.alias)) {
      setValue("alias", pascalCase(name.replace(/([^\w\s]+)/g, "")), {
        shouldValidate: true,
      });
    }
  }, [name, setValue, touchedFields, defaultValues, isNew]);

  return (
    <form
      onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
      className="model-settings__container"
    >
      <h3 className="h3">Settings</h3>
      <InputField
        label="Name"
        placeholder="Blog post"
        error={errors.name || props.errors.name}
        autoFocus
        {...register("name", {
          required: strings.required,
        })}
      />
      <InputField
        label="Alias"
        placeholder="BlogPost"
        error={errors.alias || props.errors.alias}
        help={
          !isNew && dirtyFields.alias
            ? strings.models.dataModelWarning
            : "This is how this model will be identified in API queries."
        }
        {...register("alias", {
          required: strings.required,
          pattern: {
            value: /^[A-Z][a-zA-Z0-9]{2,}$/,
            message:
              "Alias must start with an upper-case letter, be at least three characters long and only contain letters and numbers",
          },
        })}
      />

      <div className="field">
        <label className="label">Usage</label>
        <div className="control">
          <Controller
            render={({ field: { value, onChange } }) => (
              <InlineRadioButtons
                values={[ModelUsage.Entry, ModelUsage.Module]}
                value={value}
                render={(val) => strings.models.usage[val as ModelUsage]}
                onChange={onChange}
              />
            )}
            control={control}
            name="usage"
            defaultValue={ModelUsage.Entry}
          />
          <small className="help">
            An entry will appear as an entry type when creating content. A
            module can be referenced within an entry or another module.
          </small>
        </div>
      </div>

      {models ? (
        <div className="model-settings__inheritance">
          <div className="model-settings__inheritance-header">
            <strong>Inheritance</strong>
            <Button
              small
              type="button"
              kind="tertiary"
              onClick={() => inheritance.append({ id: null })}
            >
              Add
            </Button>
          </div>
          {inheritance.fields.length === 0 ? (
            <p>This model does not make use of inheritance.</p>
          ) : (
            <ul className="model-settings__inheritance-items">
              {inheritance.fields.map((ent: any, idx) => (
                <li key={ent.id || idx}>
                  <Select
                    defaultValue={ent.id}
                    {...register(`inheritance.${idx}.id` as const)}
                  >
                    <option />
                    {models
                      .filter((mod) => !mod.deletedAt && mod.id !== props.id)
                      .map((mod) => (
                        <option key={mod.id} value={mod.id}>
                          {mod.name}
                        </option>
                      ))}
                  </Select>
                  <IconButton
                    type="button"
                    kind="tertiary"
                    onClick={() => inheritance.remove(idx)}
                  >
                    <Icons.DeleteIcon />
                  </IconButton>
                </li>
              ))}
            </ul>
          )}
          <small className="help">
            To avoid repetition, pick another entry or model to inherit fields
            from.
          </small>
        </div>
      ) : null}

      {(defaultValues?.fields.length ?? 0) > 0 ? (
        <Controller
          control={control}
          name="fields"
          render={({ field: { value, onChange } }) => (
            <FieldOrder value={value} onChange={onChange} />
          )}
        />
      ) : null}

      <div className="model-settings__actions">
        <Actions {...props} />
      </div>
    </form>
  );
};

const Actions: React.FC<ModelSettingsProps> = ({
  deleted,
  loading,
  restoring,
  onDelete,
  onRestore,
}) => {
  const [deleting, setDeleting] = React.useState(false);

  if (deleting) {
    return (
      <>
        <Button
          key="confirm"
          kind="tertiary"
          type="button"
          onClick={onDelete}
          loading={loading}
        >
          {strings.confirm}
        </Button>
        <Button
          key="cancel"
          type="button"
          kind="primary"
          onClick={() => setDeleting(false)}
        >
          {strings.cancel}
        </Button>
        <small>
          Deleting this model will delete all content associated with it.
        </small>
      </>
    );
  }

  if (deleted) {
    return (
      <Button
        key="restore"
        kind="primary"
        onClick={onRestore}
        loading={restoring}
      >
        {strings.restore}
      </Button>
    );
  }

  return (
    <>
      <Button key="save" type="submit" kind="primary" loading={loading}>
        {strings.save}
      </Button>
      {onDelete ? (
        <Button
          key="delete"
          type="button"
          kind="tertiary"
          onClick={() => setDeleting(true)}
        >
          Archive
        </Button>
      ) : null}
      <LinkButton to=".." kind="tertiary">
        Cancel
      </LinkButton>
    </>
  );
};
