import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** A set of key/value pairs, keys are strings, values may be a string or null. Exposed as a JSON object. */
  KeyValueHash: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

/** All input for the `acceptProjectInvite` mutation. */
export type AcceptProjectInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** The output of our `acceptProjectInvite` mutation. */
export type AcceptProjectInvitePayload = {
  __typename?: 'AcceptProjectInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Account = {
  __typename?: 'Account';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection;
};


export type AccountProjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
  condition?: Maybe<ProjectCondition>;
  filter?: Maybe<ProjectFilter>;
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `account` to look up the row to connect. */
export type AccountFakeAppProjectAccountUniqueConnect = {
  key: Scalars['String'];
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `projects` relation. */
  projects?: Maybe<AccountToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AccountFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AccountFilter>>;
  /** Negates the expression. */
  not?: Maybe<AccountFilter>;
};

/** An input for mutations affecting `Account` */
export type AccountInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey0InverseInput>;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnProjectForFakeAppProjectProjectForeignKey0UsingFakeAppProjectAccountUniqueUpdate = {
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnProjectForFakeAppProjectProjectForeignKey0Patch;
  key: Scalars['String'];
};

/** Represents an update to a `Account`. Fields that are set will be updated. */
export type AccountPatch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey0InverseInput>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProjectFilter>;
};

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of `Account` objects. */
  nodes: Array<Maybe<Account>>;
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  edges: Array<AccountsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  node?: Maybe<Account>;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  ProjectsByAccountIdCountAsc = 'PROJECTS_BY_ACCOUNT_ID__COUNT_ASC',
  ProjectsByAccountIdCountDesc = 'PROJECTS_BY_ACCOUNT_ID__COUNT_DESC'
}

/** All input for the `backgroundTask` mutation. */
export type BackgroundTaskInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fTask?: Maybe<Scalars['String']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
  fTargetId?: Maybe<Scalars['UUID']>;
};

/** The output of our `backgroundTask` mutation. */
export type BackgroundTaskPayload = {
  __typename?: 'BackgroundTaskPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** All input for the `changeCase` mutation. */
export type ChangeCaseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fInput?: Maybe<Scalars['String']>;
  fCase?: Maybe<Scalars['String']>;
};

/** The output of our `changeCase` mutation. */
export type ChangeCasePayload = {
  __typename?: 'ChangeCasePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  string?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ChangeTracker = {
  __typename?: 'ChangeTracker';
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

/**
 * A condition to be used against `ChangeTracker` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ChangeTrackerCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `action` field. */
  action?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against `ChangeTracker` object types. All fields are combined with a logical ‘and.’ */
export type ChangeTrackerFilter = {
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `action` field. */
  action?: Maybe<StringFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ChangeTrackerFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ChangeTrackerFilter>>;
  /** Negates the expression. */
  not?: Maybe<ChangeTrackerFilter>;
};

/** A connection to a list of `ChangeTracker` values. */
export type ChangeTrackersConnection = {
  __typename?: 'ChangeTrackersConnection';
  /** A list of `ChangeTracker` objects. */
  nodes: Array<Maybe<ChangeTracker>>;
  /** A list of edges which contains the `ChangeTracker` and cursor to aid in pagination. */
  edges: Array<ChangeTrackersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ChangeTracker` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ChangeTracker` edge in the connection. */
export type ChangeTrackersEdge = {
  __typename?: 'ChangeTrackersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ChangeTracker` at the end of the edge. */
  node?: Maybe<ChangeTracker>;
};

/** Methods to use when ordering `ChangeTracker`. */
export enum ChangeTrackersOrderBy {
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC'
}

/** All input for the `copyEnvironment` mutation. */
export type CopyEnvironmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['UUID']>;
  source?: Maybe<Scalars['UUID']>;
  target?: Maybe<Scalars['UUID']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The output of our `copyEnvironment` mutation. */
export type CopyEnvironmentPayload = {
  __typename?: 'CopyEnvironmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `copyValue` mutation. */
export type CopyValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fValueId: Scalars['UUID'];
  fProjectId: Scalars['UUID'];
  fEnvironmentId: Scalars['UUID'];
};

/** The output of our `copyValue` mutation. */
export type CopyValuePayload = {
  __typename?: 'CopyValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `copyVersion2` mutation. */
export type CopyVersion2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fVersionId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

/** The output of our `copyVersion2` mutation. */
export type CopyVersion2Payload = {
  __typename?: 'CopyVersion2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Entry`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Entry`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `EntryVersion` that is related to this `Entry`. */
  latestVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `Entry`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Entry`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `Entry`. May be used by Relay 1. */
  entryEdge?: Maybe<EntriesEdge>;
};


/** The output of our `copyVersion2` mutation. */
export type CopyVersion2PayloadEntryEdgeArgs = {
  orderBy?: Maybe<Array<EntriesOrderBy>>;
};

/** All input for the `copyVersion` mutation. */
export type CopyVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fVersionId: Scalars['UUID'];
  fProjectId: Scalars['UUID'];
  fEnvironmentId: Scalars['UUID'];
};

/** The output of our `copyVersion` mutation. */
export type CopyVersionPayload = {
  __typename?: 'CopyVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  entryVersion?: Maybe<EntryVersion>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryVersion`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryVersion`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Value` that is related to this `EntryVersion`. */
  value?: Maybe<Value>;
  /** Reads a single `EntryVersion` that is related to this `EntryVersion`. */
  parentProjectEnvironment?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryVersion`. May be used by Relay 1. */
  entryVersionEdge?: Maybe<EntryVersionsEdge>;
};


/** The output of our `copyVersion` mutation. */
export type CopyVersionPayloadEntryVersionEdgeArgs = {
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
};

/** All input for the create `Account` mutation. */
export type CreateAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` to be created by this mutation. */
  account: AccountInput;
};

/** The output of our create `Account` mutation. */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was created by this mutation. */
  account?: Maybe<Account>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our create `Account` mutation. */
export type CreateAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<Array<AccountsOrderBy>>;
};

/** All input for the create `Entry` mutation. */
export type CreateEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Entry` to be created by this mutation. */
  entry: EntryInput;
};

/** The output of our create `Entry` mutation. */
export type CreateEntryPayload = {
  __typename?: 'CreateEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Entry` that was created by this mutation. */
  entry?: Maybe<Entry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Entry`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Entry`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `EntryVersion` that is related to this `Entry`. */
  latestVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `Entry`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Entry`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `Entry`. May be used by Relay 1. */
  entryEdge?: Maybe<EntriesEdge>;
};


/** The output of our create `Entry` mutation. */
export type CreateEntryPayloadEntryEdgeArgs = {
  orderBy?: Maybe<Array<EntriesOrderBy>>;
};

/** All input for the create `EntryPublish` mutation. */
export type CreateEntryPublishInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryPublish` to be created by this mutation. */
  entryPublish: EntryPublishInput;
};

/** The output of our create `EntryPublish` mutation. */
export type CreateEntryPublishPayload = {
  __typename?: 'CreateEntryPublishPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryPublish` that was created by this mutation. */
  entryPublish?: Maybe<EntryPublish>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryPublish`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryPublish`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `PublishTarget` that is related to this `EntryPublish`. */
  target?: Maybe<PublishTarget>;
  /** Reads a single `EntryVersion` that is related to this `EntryPublish`. */
  entryVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryPublish`. May be used by Relay 1. */
  entryPublishEdge?: Maybe<EntryPublishesEdge>;
};


/** The output of our create `EntryPublish` mutation. */
export type CreateEntryPublishPayloadEntryPublishEdgeArgs = {
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
};

/** All input for the create `EntryVersion` mutation. */
export type CreateEntryVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryVersion` to be created by this mutation. */
  entryVersion: EntryVersionInput;
};

/** The output of our create `EntryVersion` mutation. */
export type CreateEntryVersionPayload = {
  __typename?: 'CreateEntryVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryVersion` that was created by this mutation. */
  entryVersion?: Maybe<EntryVersion>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryVersion`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryVersion`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Value` that is related to this `EntryVersion`. */
  value?: Maybe<Value>;
  /** Reads a single `EntryVersion` that is related to this `EntryVersion`. */
  parentProjectEnvironment?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryVersion`. May be used by Relay 1. */
  entryVersionEdge?: Maybe<EntryVersionsEdge>;
};


/** The output of our create `EntryVersion` mutation. */
export type CreateEntryVersionPayloadEntryVersionEdgeArgs = {
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
};

/** All input for the create `MediaFolder` mutation. */
export type CreateMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaFolder` to be created by this mutation. */
  mediaFolder: MediaFolderInput;
};

/** The output of our create `MediaFolder` mutation. */
export type CreateMediaFolderPayload = {
  __typename?: 'CreateMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaFolder` that was created by this mutation. */
  mediaFolder?: Maybe<MediaFolder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaFolder`. */
  parent?: Maybe<MediaFolder>;
  /** An edge for our `MediaFolder`. May be used by Relay 1. */
  mediaFolderEdge?: Maybe<MediaFoldersEdge>;
};


/** The output of our create `MediaFolder` mutation. */
export type CreateMediaFolderPayloadMediaFolderEdgeArgs = {
  orderBy?: Maybe<Array<MediaFoldersOrderBy>>;
};

/** All input for the create `MediaItem` mutation. */
export type CreateMediaItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaItem` to be created by this mutation. */
  mediaItem: MediaItemInput;
};

/** The output of our create `MediaItem` mutation. */
export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaItem` that was created by this mutation. */
  mediaItem?: Maybe<MediaItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaItem`. */
  folder?: Maybe<MediaFolder>;
  /** An edge for our `MediaItem`. May be used by Relay 1. */
  mediaItemEdge?: Maybe<MediaItemsEdge>;
};


/** The output of our create `MediaItem` mutation. */
export type CreateMediaItemPayloadMediaItemEdgeArgs = {
  orderBy?: Maybe<Array<MediaItemsOrderBy>>;
};

/** All input for the create `MenuItemEntry` mutation. */
export type CreateMenuItemEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemEntry` to be created by this mutation. */
  menuItemEntry: MenuItemEntryInput;
};

/** The output of our create `MenuItemEntry` mutation. */
export type CreateMenuItemEntryPayload = {
  __typename?: 'CreateMenuItemEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemEntry` that was created by this mutation. */
  menuItemEntry?: Maybe<MenuItemEntry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemEntry`. */
  entry?: Maybe<MenuItem>;
  /** Reads a single `Entry` that is related to this `MenuItemEntry`. */
  entryProjectEnvironment?: Maybe<Entry>;
  /** An edge for our `MenuItemEntry`. May be used by Relay 1. */
  menuItemEntryEdge?: Maybe<MenuItemEntriesEdge>;
};


/** The output of our create `MenuItemEntry` mutation. */
export type CreateMenuItemEntryPayloadMenuItemEntryEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
};

/** All input for the create `MenuItem` mutation. */
export type CreateMenuItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItem` to be created by this mutation. */
  menuItem: MenuItemInput;
};

/** The output of our create `MenuItem` mutation. */
export type CreateMenuItemPayload = {
  __typename?: 'CreateMenuItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItem` that was created by this mutation. */
  menuItem?: Maybe<MenuItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `MenuItem`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `MenuItem` that is related to this `MenuItem`. */
  parentProjectEnvironment?: Maybe<MenuItem>;
  /** An edge for our `MenuItem`. May be used by Relay 1. */
  menuItemEdge?: Maybe<MenuItemsEdge>;
};


/** The output of our create `MenuItem` mutation. */
export type CreateMenuItemPayloadMenuItemEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
};

/** All input for the create `MenuItemView` mutation. */
export type CreateMenuItemViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemView` to be created by this mutation. */
  menuItemView: MenuItemViewInput;
};

/** The output of our create `MenuItemView` mutation. */
export type CreateMenuItemViewPayload = {
  __typename?: 'CreateMenuItemViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemView` that was created by this mutation. */
  menuItemView?: Maybe<MenuItemView>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemView`. */
  view?: Maybe<MenuItem>;
  /** Reads a single `Model` that is related to this `MenuItemView`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** An edge for our `MenuItemView`. May be used by Relay 1. */
  menuItemViewEdge?: Maybe<MenuItemViewsEdge>;
};


/** The output of our create `MenuItemView` mutation. */
export type CreateMenuItemViewPayloadMenuItemViewEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
};

/** All input for the create `ModelFieldGroup` mutation. */
export type CreateModelFieldGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelFieldGroup` to be created by this mutation. */
  modelFieldGroup: ModelFieldGroupInput;
};

/** The output of our create `ModelFieldGroup` mutation. */
export type CreateModelFieldGroupPayload = {
  __typename?: 'CreateModelFieldGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelFieldGroup` that was created by this mutation. */
  modelFieldGroup?: Maybe<ModelFieldGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelFieldGroup`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `ModelFieldGroup`. */
  model?: Maybe<Model>;
  /** An edge for our `ModelFieldGroup`. May be used by Relay 1. */
  modelFieldGroupEdge?: Maybe<ModelFieldGroupsEdge>;
};


/** The output of our create `ModelFieldGroup` mutation. */
export type CreateModelFieldGroupPayloadModelFieldGroupEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
};

/** All input for the create `ModelField` mutation. */
export type CreateModelFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelField` to be created by this mutation. */
  modelField: ModelFieldInput;
};

/** The output of our create `ModelField` mutation. */
export type CreateModelFieldPayload = {
  __typename?: 'CreateModelFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelField` that was created by this mutation. */
  modelField?: Maybe<ModelField>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelField`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `ModelFieldGroup` that is related to this `ModelField`. */
  group?: Maybe<ModelFieldGroup>;
  /** Reads a single `ModelField` that is related to this `ModelField`. */
  autoFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `ModelField`. May be used by Relay 1. */
  modelFieldEdge?: Maybe<ModelFieldsEdge>;
};


/** The output of our create `ModelField` mutation. */
export type CreateModelFieldPayloadModelFieldEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
};

/** All input for the create `Model` mutation. */
export type CreateModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Model` to be created by this mutation. */
  model: ModelInput;
};

/** All input for the create `ModelParent` mutation. */
export type CreateModelParentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelParent` to be created by this mutation. */
  modelParent: ModelParentInput;
};

/** The output of our create `ModelParent` mutation. */
export type CreateModelParentPayload = {
  __typename?: 'CreateModelParentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelParent` that was created by this mutation. */
  modelParent?: Maybe<ModelParent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  model?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  parent?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelParent`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `ModelParent`. May be used by Relay 1. */
  modelParentEdge?: Maybe<ModelParentsEdge>;
};


/** The output of our create `ModelParent` mutation. */
export type CreateModelParentPayloadModelParentEdgeArgs = {
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
};

/** The output of our create `Model` mutation. */
export type CreateModelPayload = {
  __typename?: 'CreateModelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Model` that was created by this mutation. */
  model?: Maybe<Model>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `Model`. */
  valueBase?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `Model`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `TaxonomyLevel` that is related to this `Model`. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** An edge for our `Model`. May be used by Relay 1. */
  modelEdge?: Maybe<ModelsEdge>;
};


/** The output of our create `Model` mutation. */
export type CreateModelPayloadModelEdgeArgs = {
  orderBy?: Maybe<Array<ModelsOrderBy>>;
};

/** All input for the `createProjectApiKey` mutation. */
export type CreateProjectApiKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['UUID']>;
};

/** The output of our `createProjectApiKey` mutation. */
export type CreateProjectApiKeyPayload = {
  __typename?: 'CreateProjectApiKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  string?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ProjectEnvironment` mutation. */
export type CreateProjectEnvironmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectEnvironment` to be created by this mutation. */
  projectEnvironment: ProjectEnvironmentInput;
};

/** The output of our create `ProjectEnvironment` mutation. */
export type CreateProjectEnvironmentPayload = {
  __typename?: 'CreateProjectEnvironmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectEnvironment` that was created by this mutation. */
  projectEnvironment?: Maybe<ProjectEnvironment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `ProjectEnvironment`. */
  project?: Maybe<Project>;
  /** An edge for our `ProjectEnvironment`. May be used by Relay 1. */
  projectEnvironmentEdge?: Maybe<ProjectEnvironmentsEdge>;
};


/** The output of our create `ProjectEnvironment` mutation. */
export type CreateProjectEnvironmentPayloadProjectEnvironmentEdgeArgs = {
  orderBy?: Maybe<Array<ProjectEnvironmentsOrderBy>>;
};

/** All input for the create `Project` mutation. */
export type CreateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Project` to be created by this mutation. */
  project: ProjectInput;
};

/** All input for the `createProjectInvite` mutation. */
export type CreateProjectInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['UUID']>;
};

/** The output of our `createProjectInvite` mutation. */
export type CreateProjectInvitePayload = {
  __typename?: 'CreateProjectInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  string?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Project` mutation. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Project` that was created by this mutation. */
  project?: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Project`. */
  account?: Maybe<Account>;
  /** Reads a single `ProjectEnvironment` that is related to this `Project`. */
  defaultEnvironment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
};


/** The output of our create `Project` mutation. */
export type CreateProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
};

/** All input for the create `ProjectToken` mutation. */
export type CreateProjectTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectToken` to be created by this mutation. */
  projectToken: ProjectTokenInput;
};

/** The output of our create `ProjectToken` mutation. */
export type CreateProjectTokenPayload = {
  __typename?: 'CreateProjectTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectToken` that was created by this mutation. */
  projectToken?: Maybe<ProjectToken>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PublishTarget` that is related to this `ProjectToken`. */
  target?: Maybe<PublishTarget>;
  /** An edge for our `ProjectToken`. May be used by Relay 1. */
  projectTokenEdge?: Maybe<ProjectTokensEdge>;
};


/** The output of our create `ProjectToken` mutation. */
export type CreateProjectTokenPayloadProjectTokenEdgeArgs = {
  orderBy?: Maybe<Array<ProjectTokensOrderBy>>;
};

/** All input for the create `PublishTarget` mutation. */
export type CreatePublishTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PublishTarget` to be created by this mutation. */
  publishTarget: PublishTargetInput;
};

/** The output of our create `PublishTarget` mutation. */
export type CreatePublishTargetPayload = {
  __typename?: 'CreatePublishTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PublishTarget` that was created by this mutation. */
  publishTarget?: Maybe<PublishTarget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `PublishTarget`. */
  project?: Maybe<Project>;
  /** An edge for our `PublishTarget`. May be used by Relay 1. */
  publishTargetEdge?: Maybe<PublishTargetsEdge>;
};


/** The output of our create `PublishTarget` mutation. */
export type CreatePublishTargetPayloadPublishTargetEdgeArgs = {
  orderBy?: Maybe<Array<PublishTargetsOrderBy>>;
};

/** All input for the create `Taxonomy` mutation. */
export type CreateTaxonomyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Taxonomy` to be created by this mutation. */
  taxonomy: TaxonomyInput;
};

/** All input for the create `TaxonomyLevel` mutation. */
export type CreateTaxonomyLevelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TaxonomyLevel` to be created by this mutation. */
  taxonomyLevel: TaxonomyLevelInput;
};

/** The output of our create `TaxonomyLevel` mutation. */
export type CreateTaxonomyLevelPayload = {
  __typename?: 'CreateTaxonomyLevelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TaxonomyLevel` that was created by this mutation. */
  taxonomyLevel?: Maybe<TaxonomyLevel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `TaxonomyLevel`. */
  taxonomy?: Maybe<Taxonomy>;
  /** Reads a single `ProjectEnvironment` that is related to this `TaxonomyLevel`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `TaxonomyLevel`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  aliasFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  fragmentFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `TaxonomyLevel` that is related to this `TaxonomyLevel`. */
  parentProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  parentReferenceFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `TaxonomyLevel`. May be used by Relay 1. */
  taxonomyLevelEdge?: Maybe<TaxonomyLevelsEdge>;
};


/** The output of our create `TaxonomyLevel` mutation. */
export type CreateTaxonomyLevelPayloadTaxonomyLevelEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
};

/** The output of our create `Taxonomy` mutation. */
export type CreateTaxonomyPayload = {
  __typename?: 'CreateTaxonomyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Taxonomy` that was created by this mutation. */
  taxonomy?: Maybe<Taxonomy>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Taxonomy`. */
  parent?: Maybe<Taxonomy>;
  /** Reads a single `Entry` that is related to this `Taxonomy`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `Taxonomy`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Taxonomy`. May be used by Relay 1. */
  taxonomyEdge?: Maybe<TaxonomiesEdge>;
};


/** The output of our create `Taxonomy` mutation. */
export type CreateTaxonomyPayloadTaxonomyEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `ValueField` mutation. */
export type CreateValueFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ValueField` to be created by this mutation. */
  valueField: ValueFieldInput;
};

/** The output of our create `ValueField` mutation. */
export type CreateValueFieldPayload = {
  __typename?: 'CreateValueFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ValueField` that was created by this mutation. */
  valueField?: Maybe<ValueField>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  value?: Maybe<Value>;
  /** Reads a single `ModelField` that is related to this `ValueField`. */
  modelField?: Maybe<ModelField>;
  /** Reads a single `MediaItem` that is related to this `ValueField`. */
  valueMedia?: Maybe<MediaItem>;
  /** Reads a single `Entry` that is related to this `ValueField`. */
  valueEntryProjectEnvironment?: Maybe<Entry>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  valueValue?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `ValueField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `ValueField`. */
  project?: Maybe<Project>;
  /** An edge for our `ValueField`. May be used by Relay 1. */
  valueFieldEdge?: Maybe<ValueFieldsEdge>;
};


/** The output of our create `ValueField` mutation. */
export type CreateValueFieldPayloadValueFieldEdgeArgs = {
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
};

/** All input for the create `Value` mutation. */
export type CreateValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Value` to be created by this mutation. */
  value: ValueInput;
};

/** The output of our create `Value` mutation. */
export type CreateValuePayload = {
  __typename?: 'CreateValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Value` that was created by this mutation. */
  value?: Maybe<Value>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Value`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Value`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `Value`. */
  project?: Maybe<Project>;
  /** An edge for our `Value`. May be used by Relay 1. */
  valueEdge?: Maybe<ValuesEdge>;
};


/** The output of our create `Value` mutation. */
export type CreateValuePayloadValueEdgeArgs = {
  orderBy?: Maybe<Array<ValuesOrderBy>>;
};

/** All input for the create `Webhook` mutation. */
export type CreateWebhookInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Webhook` to be created by this mutation. */
  webhook: WebhookInput;
};

/** All input for the create `WebhookLog` mutation. */
export type CreateWebhookLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookLog` to be created by this mutation. */
  webhookLog: WebhookLogInput;
};

/** The output of our create `WebhookLog` mutation. */
export type CreateWebhookLogPayload = {
  __typename?: 'CreateWebhookLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookLog` that was created by this mutation. */
  webhookLog?: Maybe<WebhookLog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookLog`. */
  webhook?: Maybe<Webhook>;
  /** Reads a single `WebhookRun` that is related to this `WebhookLog`. */
  webhookRun?: Maybe<WebhookRun>;
  /** An edge for our `WebhookLog`. May be used by Relay 1. */
  webhookLogEdge?: Maybe<WebhookLogsEdge>;
};


/** The output of our create `WebhookLog` mutation. */
export type CreateWebhookLogPayloadWebhookLogEdgeArgs = {
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
};

/** The output of our create `Webhook` mutation. */
export type CreateWebhookPayload = {
  __typename?: 'CreateWebhookPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Webhook` that was created by this mutation. */
  webhook?: Maybe<Webhook>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Webhook`. May be used by Relay 1. */
  webhookEdge?: Maybe<WebhooksEdge>;
};


/** The output of our create `Webhook` mutation. */
export type CreateWebhookPayloadWebhookEdgeArgs = {
  orderBy?: Maybe<Array<WebhooksOrderBy>>;
};

/** All input for the create `WebhookRun` mutation. */
export type CreateWebhookRunInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookRun` to be created by this mutation. */
  webhookRun: WebhookRunInput;
};

/** The output of our create `WebhookRun` mutation. */
export type CreateWebhookRunPayload = {
  __typename?: 'CreateWebhookRunPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookRun` that was created by this mutation. */
  webhookRun?: Maybe<WebhookRun>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookRun`. */
  webhook?: Maybe<Webhook>;
  /** An edge for our `WebhookRun`. May be used by Relay 1. */
  webhookRunEdge?: Maybe<WebhookRunsEdge>;
};


/** The output of our create `WebhookRun` mutation. */
export type CreateWebhookRunPayloadWebhookRunEdgeArgs = {
  orderBy?: Maybe<Array<WebhookRunsOrderBy>>;
};

/** All input for the create `Website` mutation. */
export type CreateWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` to be created by this mutation. */
  website: WebsiteInput;
};

/** The output of our create `Website` mutation. */
export type CreateWebsitePayload = {
  __typename?: 'CreateWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was created by this mutation. */
  website?: Maybe<Website>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Website`. */
  projectEnvironmentTaxonomy?: Maybe<Taxonomy>;
  /** Reads a single `PublishTarget` that is related to this `Website`. */
  projectTarget?: Maybe<PublishTarget>;
  /** Reads a single `ProjectEnvironment` that is related to this `Website`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our create `Website` mutation. */
export type CreateWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};



/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteAccountByKey` mutation. */
export type DeleteAccountByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

/** The output of our delete `Account` mutation. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was deleted by this mutation. */
  account?: Maybe<Account>;
  deletedAccountNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our delete `Account` mutation. */
export type DeleteAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<Array<AccountsOrderBy>>;
};

/** All input for the `deleteEntry` mutation. */
export type DeleteEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `Entry` mutation. */
export type DeleteEntryPayload = {
  __typename?: 'DeleteEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Entry` that was deleted by this mutation. */
  entry?: Maybe<Entry>;
  deletedEntryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Entry`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Entry`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `EntryVersion` that is related to this `Entry`. */
  latestVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `Entry`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Entry`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `Entry`. May be used by Relay 1. */
  entryEdge?: Maybe<EntriesEdge>;
};


/** The output of our delete `Entry` mutation. */
export type DeleteEntryPayloadEntryEdgeArgs = {
  orderBy?: Maybe<Array<EntriesOrderBy>>;
};

/** All input for the `deleteEntryPublish` mutation. */
export type DeleteEntryPublishInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The output of our delete `EntryPublish` mutation. */
export type DeleteEntryPublishPayload = {
  __typename?: 'DeleteEntryPublishPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryPublish` that was deleted by this mutation. */
  entryPublish?: Maybe<EntryPublish>;
  deletedEntryPublishNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryPublish`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryPublish`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `PublishTarget` that is related to this `EntryPublish`. */
  target?: Maybe<PublishTarget>;
  /** Reads a single `EntryVersion` that is related to this `EntryPublish`. */
  entryVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryPublish`. May be used by Relay 1. */
  entryPublishEdge?: Maybe<EntryPublishesEdge>;
};


/** The output of our delete `EntryPublish` mutation. */
export type DeleteEntryPublishPayloadEntryPublishEdgeArgs = {
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
};

/** All input for the `deleteEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndex` mutation. */
export type DeleteEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** All input for the `deleteEntryVersion` mutation. */
export type DeleteEntryVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `EntryVersion` mutation. */
export type DeleteEntryVersionPayload = {
  __typename?: 'DeleteEntryVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryVersion` that was deleted by this mutation. */
  entryVersion?: Maybe<EntryVersion>;
  deletedEntryVersionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryVersion`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryVersion`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Value` that is related to this `EntryVersion`. */
  value?: Maybe<Value>;
  /** Reads a single `EntryVersion` that is related to this `EntryVersion`. */
  parentProjectEnvironment?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryVersion`. May be used by Relay 1. */
  entryVersionEdge?: Maybe<EntryVersionsEdge>;
};


/** The output of our delete `EntryVersion` mutation. */
export type DeleteEntryVersionPayloadEntryVersionEdgeArgs = {
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
};

/** All input for the `deleteMediaFolder` mutation. */
export type DeleteMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `MediaFolder` mutation. */
export type DeleteMediaFolderPayload = {
  __typename?: 'DeleteMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaFolder` that was deleted by this mutation. */
  mediaFolder?: Maybe<MediaFolder>;
  deletedMediaFolderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaFolder`. */
  parent?: Maybe<MediaFolder>;
  /** An edge for our `MediaFolder`. May be used by Relay 1. */
  mediaFolderEdge?: Maybe<MediaFoldersEdge>;
};


/** The output of our delete `MediaFolder` mutation. */
export type DeleteMediaFolderPayloadMediaFolderEdgeArgs = {
  orderBy?: Maybe<Array<MediaFoldersOrderBy>>;
};

/** All input for the `deleteMediaItem` mutation. */
export type DeleteMediaItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `MediaItem` mutation. */
export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaItem` that was deleted by this mutation. */
  mediaItem?: Maybe<MediaItem>;
  deletedMediaItemNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaItem`. */
  folder?: Maybe<MediaFolder>;
  /** An edge for our `MediaItem`. May be used by Relay 1. */
  mediaItemEdge?: Maybe<MediaItemsEdge>;
};


/** The output of our delete `MediaItem` mutation. */
export type DeleteMediaItemPayloadMediaItemEdgeArgs = {
  orderBy?: Maybe<Array<MediaItemsOrderBy>>;
};

/** All input for the `deleteMenuItemEntry` mutation. */
export type DeleteMenuItemEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `MenuItemEntry` mutation. */
export type DeleteMenuItemEntryPayload = {
  __typename?: 'DeleteMenuItemEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemEntry` that was deleted by this mutation. */
  menuItemEntry?: Maybe<MenuItemEntry>;
  deletedMenuItemEntryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemEntry`. */
  entry?: Maybe<MenuItem>;
  /** Reads a single `Entry` that is related to this `MenuItemEntry`. */
  entryProjectEnvironment?: Maybe<Entry>;
  /** An edge for our `MenuItemEntry`. May be used by Relay 1. */
  menuItemEntryEdge?: Maybe<MenuItemEntriesEdge>;
};


/** The output of our delete `MenuItemEntry` mutation. */
export type DeleteMenuItemEntryPayloadMenuItemEntryEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
};

/** All input for the `deleteMenuItem` mutation. */
export type DeleteMenuItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `MenuItem` mutation. */
export type DeleteMenuItemPayload = {
  __typename?: 'DeleteMenuItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItem` that was deleted by this mutation. */
  menuItem?: Maybe<MenuItem>;
  deletedMenuItemNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `MenuItem`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `MenuItem` that is related to this `MenuItem`. */
  parentProjectEnvironment?: Maybe<MenuItem>;
  /** An edge for our `MenuItem`. May be used by Relay 1. */
  menuItemEdge?: Maybe<MenuItemsEdge>;
};


/** The output of our delete `MenuItem` mutation. */
export type DeleteMenuItemPayloadMenuItemEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
};

/** All input for the `deleteMenuItemView` mutation. */
export type DeleteMenuItemViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `MenuItemView` mutation. */
export type DeleteMenuItemViewPayload = {
  __typename?: 'DeleteMenuItemViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemView` that was deleted by this mutation. */
  menuItemView?: Maybe<MenuItemView>;
  deletedMenuItemViewNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemView`. */
  view?: Maybe<MenuItem>;
  /** Reads a single `Model` that is related to this `MenuItemView`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** An edge for our `MenuItemView`. May be used by Relay 1. */
  menuItemViewEdge?: Maybe<MenuItemViewsEdge>;
};


/** The output of our delete `MenuItemView` mutation. */
export type DeleteMenuItemViewPayloadMenuItemViewEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
};

/** All input for the `deleteModelFieldGroup` mutation. */
export type DeleteModelFieldGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `ModelFieldGroup` mutation. */
export type DeleteModelFieldGroupPayload = {
  __typename?: 'DeleteModelFieldGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelFieldGroup` that was deleted by this mutation. */
  modelFieldGroup?: Maybe<ModelFieldGroup>;
  deletedModelFieldGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelFieldGroup`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `ModelFieldGroup`. */
  model?: Maybe<Model>;
  /** An edge for our `ModelFieldGroup`. May be used by Relay 1. */
  modelFieldGroupEdge?: Maybe<ModelFieldGroupsEdge>;
};


/** The output of our delete `ModelFieldGroup` mutation. */
export type DeleteModelFieldGroupPayloadModelFieldGroupEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
};

/** All input for the `deleteModelField` mutation. */
export type DeleteModelFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `ModelField` mutation. */
export type DeleteModelFieldPayload = {
  __typename?: 'DeleteModelFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelField` that was deleted by this mutation. */
  modelField?: Maybe<ModelField>;
  deletedModelFieldNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelField`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `ModelFieldGroup` that is related to this `ModelField`. */
  group?: Maybe<ModelFieldGroup>;
  /** Reads a single `ModelField` that is related to this `ModelField`. */
  autoFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `ModelField`. May be used by Relay 1. */
  modelFieldEdge?: Maybe<ModelFieldsEdge>;
};


/** The output of our delete `ModelField` mutation. */
export type DeleteModelFieldPayloadModelFieldEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
};

/** All input for the `deleteModel` mutation. */
export type DeleteModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** All input for the `deleteModelParent` mutation. */
export type DeleteModelParentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `ModelParent` mutation. */
export type DeleteModelParentPayload = {
  __typename?: 'DeleteModelParentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelParent` that was deleted by this mutation. */
  modelParent?: Maybe<ModelParent>;
  deletedModelParentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  model?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  parent?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelParent`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `ModelParent`. May be used by Relay 1. */
  modelParentEdge?: Maybe<ModelParentsEdge>;
};


/** The output of our delete `ModelParent` mutation. */
export type DeleteModelParentPayloadModelParentEdgeArgs = {
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
};

/** The output of our delete `Model` mutation. */
export type DeleteModelPayload = {
  __typename?: 'DeleteModelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Model` that was deleted by this mutation. */
  model?: Maybe<Model>;
  deletedModelNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `Model`. */
  valueBase?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `Model`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `TaxonomyLevel` that is related to this `Model`. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** An edge for our `Model`. May be used by Relay 1. */
  modelEdge?: Maybe<ModelsEdge>;
};


/** The output of our delete `Model` mutation. */
export type DeleteModelPayloadModelEdgeArgs = {
  orderBy?: Maybe<Array<ModelsOrderBy>>;
};

/** All input for the `deleteProjectByAccountIdAndKey` mutation. */
export type DeleteProjectByAccountIdAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** All input for the `deleteProjectEnvironmentByKeyAndProjectId` mutation. */
export type DeleteProjectEnvironmentByKeyAndProjectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** All input for the `deleteProjectEnvironment` mutation. */
export type DeleteProjectEnvironmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ProjectEnvironment` mutation. */
export type DeleteProjectEnvironmentPayload = {
  __typename?: 'DeleteProjectEnvironmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectEnvironment` that was deleted by this mutation. */
  projectEnvironment?: Maybe<ProjectEnvironment>;
  deletedProjectEnvironmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `ProjectEnvironment`. */
  project?: Maybe<Project>;
  /** An edge for our `ProjectEnvironment`. May be used by Relay 1. */
  projectEnvironmentEdge?: Maybe<ProjectEnvironmentsEdge>;
};


/** The output of our delete `ProjectEnvironment` mutation. */
export type DeleteProjectEnvironmentPayloadProjectEnvironmentEdgeArgs = {
  orderBy?: Maybe<Array<ProjectEnvironmentsOrderBy>>;
};

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Project` that was deleted by this mutation. */
  project?: Maybe<Project>;
  deletedProjectNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Project`. */
  account?: Maybe<Account>;
  /** Reads a single `ProjectEnvironment` that is related to this `Project`. */
  defaultEnvironment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
};


/** The output of our delete `Project` mutation. */
export type DeleteProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
};

/** All input for the `deleteProjectToken` mutation. */
export type DeleteProjectTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ProjectToken` mutation. */
export type DeleteProjectTokenPayload = {
  __typename?: 'DeleteProjectTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectToken` that was deleted by this mutation. */
  projectToken?: Maybe<ProjectToken>;
  deletedProjectTokenNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PublishTarget` that is related to this `ProjectToken`. */
  target?: Maybe<PublishTarget>;
  /** An edge for our `ProjectToken`. May be used by Relay 1. */
  projectTokenEdge?: Maybe<ProjectTokensEdge>;
};


/** The output of our delete `ProjectToken` mutation. */
export type DeleteProjectTokenPayloadProjectTokenEdgeArgs = {
  orderBy?: Maybe<Array<ProjectTokensOrderBy>>;
};

/** All input for the `deletePublishTarget` mutation. */
export type DeletePublishTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The output of our delete `PublishTarget` mutation. */
export type DeletePublishTargetPayload = {
  __typename?: 'DeletePublishTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PublishTarget` that was deleted by this mutation. */
  publishTarget?: Maybe<PublishTarget>;
  deletedPublishTargetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `PublishTarget`. */
  project?: Maybe<Project>;
  /** An edge for our `PublishTarget`. May be used by Relay 1. */
  publishTargetEdge?: Maybe<PublishTargetsEdge>;
};


/** The output of our delete `PublishTarget` mutation. */
export type DeletePublishTargetPayloadPublishTargetEdgeArgs = {
  orderBy?: Maybe<Array<PublishTargetsOrderBy>>;
};

/** All input for the `deleteTaxonomy` mutation. */
export type DeleteTaxonomyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** All input for the `deleteTaxonomyLevel` mutation. */
export type DeleteTaxonomyLevelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `TaxonomyLevel` mutation. */
export type DeleteTaxonomyLevelPayload = {
  __typename?: 'DeleteTaxonomyLevelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TaxonomyLevel` that was deleted by this mutation. */
  taxonomyLevel?: Maybe<TaxonomyLevel>;
  deletedTaxonomyLevelNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `TaxonomyLevel`. */
  taxonomy?: Maybe<Taxonomy>;
  /** Reads a single `ProjectEnvironment` that is related to this `TaxonomyLevel`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `TaxonomyLevel`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  aliasFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  fragmentFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `TaxonomyLevel` that is related to this `TaxonomyLevel`. */
  parentProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  parentReferenceFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `TaxonomyLevel`. May be used by Relay 1. */
  taxonomyLevelEdge?: Maybe<TaxonomyLevelsEdge>;
};


/** The output of our delete `TaxonomyLevel` mutation. */
export type DeleteTaxonomyLevelPayloadTaxonomyLevelEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
};

/** The output of our delete `Taxonomy` mutation. */
export type DeleteTaxonomyPayload = {
  __typename?: 'DeleteTaxonomyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Taxonomy` that was deleted by this mutation. */
  taxonomy?: Maybe<Taxonomy>;
  deletedTaxonomyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Taxonomy`. */
  parent?: Maybe<Taxonomy>;
  /** Reads a single `Entry` that is related to this `Taxonomy`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `Taxonomy`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Taxonomy`. May be used by Relay 1. */
  taxonomyEdge?: Maybe<TaxonomiesEdge>;
};


/** The output of our delete `Taxonomy` mutation. */
export type DeleteTaxonomyPayloadTaxonomyEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
};

/** All input for the `deleteValueField` mutation. */
export type DeleteValueFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `ValueField` mutation. */
export type DeleteValueFieldPayload = {
  __typename?: 'DeleteValueFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ValueField` that was deleted by this mutation. */
  valueField?: Maybe<ValueField>;
  deletedValueFieldNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  value?: Maybe<Value>;
  /** Reads a single `ModelField` that is related to this `ValueField`. */
  modelField?: Maybe<ModelField>;
  /** Reads a single `MediaItem` that is related to this `ValueField`. */
  valueMedia?: Maybe<MediaItem>;
  /** Reads a single `Entry` that is related to this `ValueField`. */
  valueEntryProjectEnvironment?: Maybe<Entry>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  valueValue?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `ValueField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `ValueField`. */
  project?: Maybe<Project>;
  /** An edge for our `ValueField`. May be used by Relay 1. */
  valueFieldEdge?: Maybe<ValueFieldsEdge>;
};


/** The output of our delete `ValueField` mutation. */
export type DeleteValueFieldPayloadValueFieldEdgeArgs = {
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
};

/** All input for the `deleteValue` mutation. */
export type DeleteValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our delete `Value` mutation. */
export type DeleteValuePayload = {
  __typename?: 'DeleteValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Value` that was deleted by this mutation. */
  value?: Maybe<Value>;
  deletedValueNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Value`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Value`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `Value`. */
  project?: Maybe<Project>;
  /** An edge for our `Value`. May be used by Relay 1. */
  valueEdge?: Maybe<ValuesEdge>;
};


/** The output of our delete `Value` mutation. */
export type DeleteValuePayloadValueEdgeArgs = {
  orderBy?: Maybe<Array<ValuesOrderBy>>;
};

/** All input for the `deleteWebhook` mutation. */
export type DeleteWebhookInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteWebhookLog` mutation. */
export type DeleteWebhookLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `WebhookLog` mutation. */
export type DeleteWebhookLogPayload = {
  __typename?: 'DeleteWebhookLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookLog` that was deleted by this mutation. */
  webhookLog?: Maybe<WebhookLog>;
  deletedWebhookLogNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookLog`. */
  webhook?: Maybe<Webhook>;
  /** Reads a single `WebhookRun` that is related to this `WebhookLog`. */
  webhookRun?: Maybe<WebhookRun>;
  /** An edge for our `WebhookLog`. May be used by Relay 1. */
  webhookLogEdge?: Maybe<WebhookLogsEdge>;
};


/** The output of our delete `WebhookLog` mutation. */
export type DeleteWebhookLogPayloadWebhookLogEdgeArgs = {
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
};

/** The output of our delete `Webhook` mutation. */
export type DeleteWebhookPayload = {
  __typename?: 'DeleteWebhookPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Webhook` that was deleted by this mutation. */
  webhook?: Maybe<Webhook>;
  deletedWebhookNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Webhook`. May be used by Relay 1. */
  webhookEdge?: Maybe<WebhooksEdge>;
};


/** The output of our delete `Webhook` mutation. */
export type DeleteWebhookPayloadWebhookEdgeArgs = {
  orderBy?: Maybe<Array<WebhooksOrderBy>>;
};

/** All input for the `deleteWebhookRun` mutation. */
export type DeleteWebhookRunInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `WebhookRun` mutation. */
export type DeleteWebhookRunPayload = {
  __typename?: 'DeleteWebhookRunPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookRun` that was deleted by this mutation. */
  webhookRun?: Maybe<WebhookRun>;
  deletedWebhookRunNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookRun`. */
  webhook?: Maybe<Webhook>;
  /** An edge for our `WebhookRun`. May be used by Relay 1. */
  webhookRunEdge?: Maybe<WebhookRunsEdge>;
};


/** The output of our delete `WebhookRun` mutation. */
export type DeleteWebhookRunPayloadWebhookRunEdgeArgs = {
  orderBy?: Maybe<Array<WebhookRunsOrderBy>>;
};

/** All input for the `deleteWebsite` mutation. */
export type DeleteWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** The output of our delete `Website` mutation. */
export type DeleteWebsitePayload = {
  __typename?: 'DeleteWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was deleted by this mutation. */
  website?: Maybe<Website>;
  deletedWebsiteNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Website`. */
  projectEnvironmentTaxonomy?: Maybe<Taxonomy>;
  /** Reads a single `PublishTarget` that is related to this `Website`. */
  projectTarget?: Maybe<PublishTarget>;
  /** Reads a single `ProjectEnvironment` that is related to this `Website`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our delete `Website` mutation. */
export type DeleteWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};

/** A connection to a list of `Entry` values. */
export type EntriesConnection = {
  __typename?: 'EntriesConnection';
  /** A list of `Entry` objects. */
  nodes: Array<Maybe<Entry>>;
  /** A list of edges which contains the `Entry` and cursor to aid in pagination. */
  edges: Array<EntriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Entry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Entry` edge in the connection. */
export type EntriesEdge = {
  __typename?: 'EntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Entry` at the end of the edge. */
  node?: Maybe<Entry>;
};

/** Methods to use when ordering `Entry`. */
export enum EntriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  LatestVersionIdAsc = 'LATEST_VERSION_ID_ASC',
  LatestVersionIdDesc = 'LATEST_VERSION_ID_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  ModifiedByIdAsc = 'MODIFIED_BY_ID_ASC',
  ModifiedByIdDesc = 'MODIFIED_BY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdValueIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdValueIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdIndexAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdIndexDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdParentIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdParentIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryVersionByLatestVersionIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_VERSION_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  UserByCreatedByIdIdAsc = 'USER_BY_CREATED_BY_ID__ID_ASC',
  UserByCreatedByIdIdDesc = 'USER_BY_CREATED_BY_ID__ID_DESC',
  UserByCreatedByIdNameAsc = 'USER_BY_CREATED_BY_ID__NAME_ASC',
  UserByCreatedByIdNameDesc = 'USER_BY_CREATED_BY_ID__NAME_DESC',
  UserByModifiedByIdIdAsc = 'USER_BY_MODIFIED_BY_ID__ID_ASC',
  UserByModifiedByIdIdDesc = 'USER_BY_MODIFIED_BY_ID__ID_DESC',
  UserByModifiedByIdNameAsc = 'USER_BY_MODIFIED_BY_ID__NAME_ASC',
  UserByModifiedByIdNameDesc = 'USER_BY_MODIFIED_BY_ID__NAME_DESC',
  EntryVersionsByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRY_VERSIONS_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntryVersionsByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRY_VERSIONS_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  EntryPublishesByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRY_PUBLISHES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntryPublishesByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRY_PUBLISHES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomiesByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomiesByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ValueFieldsByValueEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'VALUE_FIELDS_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ValueFieldsByValueEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'VALUE_FIELDS_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  MenuItemEntriesByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'MENU_ITEM_ENTRIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  MenuItemEntriesByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'MENU_ITEM_ENTRIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyCombinedsByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_COMBINEDS_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyCombinedsByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_COMBINEDS_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyDynamicsByEntryIdCountAsc = 'TAXONOMY_DYNAMICS_BY_ENTRY_ID__COUNT_ASC',
  TaxonomyDynamicsByEntryIdCountDesc = 'TAXONOMY_DYNAMICS_BY_ENTRY_ID__COUNT_DESC',
  TaxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LIST_NODES_ENTRIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LIST_NODES_ENTRIES_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

export type Entry = {
  __typename?: 'Entry';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  modelId: Scalars['UUID'];
  name: Scalars['String'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId: Scalars['UUID'];
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  /** Reads a single `Model` that is related to this `Entry`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Entry`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `EntryVersion` that is related to this `Entry`. */
  latestVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `Entry`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Entry`. */
  modifiedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  versions: EntryVersionsConnection;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishes: EntryPublishesConnection;
  /** Reads and enables pagination through a set of `Taxonomy`. */
  taxonomy: TaxonomiesConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueFields: ValueFieldsConnection;
  /** Reads and enables pagination through a set of `MenuItemEntry`. */
  menuItemEntriesByEntryIdAndProjectIdAndEnvironmentId: MenuItemEntriesConnection;
  /** Reads and enables pagination through a set of `TaxonomyCombined`. */
  taxonomies: TaxonomyCombinedsConnection;
  /** Reads and enables pagination through a set of `TaxonomyDynamic`. */
  taxonomyDynamics: TaxonomyDynamicsConnection;
};


export type EntryVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


export type EntryEntryPublishesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


export type EntryTaxonomyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
  condition?: Maybe<TaxonomyCondition>;
  filter?: Maybe<TaxonomyFilter>;
};


export type EntryValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


export type EntryMenuItemEntriesByEntryIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
  condition?: Maybe<MenuItemEntryCondition>;
  filter?: Maybe<MenuItemEntryFilter>;
};


export type EntryTaxonomiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyCombinedsOrderBy>>;
  condition?: Maybe<TaxonomyCombinedCondition>;
  filter?: Maybe<TaxonomyCombinedFilter>;
};


export type EntryTaxonomyDynamicsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyDynamicsOrderBy>>;
  condition?: Maybe<TaxonomyDynamicCondition>;
  filter?: Maybe<TaxonomyDynamicFilter>;
};

/** A condition to be used against `Entry` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EntryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `latestVersionId` field. */
  latestVersionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modifiedById` field. */
  modifiedById?: Maybe<Scalars['UUID']>;
};

/** The fields on `entry` to look up the row to connect. */
export type EntryEntryPkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to delete. */
export type EntryEntryPkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The `entry` to be created by this mutation. */
export type EntryEnvironmentIdFkeyEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `EntryInput` mutation. */
export type EntryEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<EntryEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `entry` in the `ProjectEnvironmentInput` mutation. */
export type EntryEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `entry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyConnect>>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyDelete>>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryOnEntryForEntryEnvironmentIdFkeyUsingEntryPkeyUpdate>>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EntryEnvironmentIdFkeyEntryCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type EntryEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** A filter to be used against `Entry` object types. All fields are combined with a logical ‘and.’ */
export type EntryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `latestVersionId` field. */
  latestVersionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: Maybe<UuidFilter>;
  /** Filter by the object’s `modifiedById` field. */
  modifiedById?: Maybe<UuidFilter>;
  /** Filter by the object’s `versions` relation. */
  versions?: Maybe<EntryToManyEntryVersionFilter>;
  /** Some related `versions` exist. */
  versionsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryPublishes` relation. */
  entryPublishes?: Maybe<EntryToManyEntryPublishFilter>;
  /** Some related `entryPublishes` exist. */
  entryPublishesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomy` relation. */
  taxonomy?: Maybe<EntryToManyTaxonomyFilter>;
  /** Some related `taxonomy` exist. */
  taxonomyExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueFields` relation. */
  valueFields?: Maybe<EntryToManyValueFieldFilter>;
  /** Some related `valueFields` exist. */
  valueFieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `menuItemEntriesByEntryIdAndProjectIdAndEnvironmentId` relation. */
  menuItemEntriesByEntryIdAndProjectIdAndEnvironmentId?: Maybe<EntryToManyMenuItemEntryFilter>;
  /** Some related `menuItemEntriesByEntryIdAndProjectIdAndEnvironmentId` exist. */
  menuItemEntriesByEntryIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomies` relation. */
  taxonomies?: Maybe<EntryToManyTaxonomyCombinedFilter>;
  /** Some related `taxonomies` exist. */
  taxonomiesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyDynamics` relation. */
  taxonomyDynamics?: Maybe<EntryToManyTaxonomyDynamicFilter>;
  /** Some related `taxonomyDynamics` exist. */
  taxonomyDynamicsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentId?: Maybe<EntryToManyTaxonomyListNodesEntryFilter>;
  /** Some related `taxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyListNodesEntriesByEntryIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `latestVersion` relation. */
  latestVersion?: Maybe<EntryVersionFilter>;
  /** A related `latestVersion` exists. */
  latestVersionExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdBy` relation. */
  createdBy?: Maybe<UserFilter>;
  /** A related `createdBy` exists. */
  createdByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiedBy` relation. */
  modifiedBy?: Maybe<UserFilter>;
  /** A related `modifiedBy` exists. */
  modifiedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EntryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EntryFilter>>;
  /** Negates the expression. */
  not?: Maybe<EntryFilter>;
};

/** An input for mutations affecting `Entry` */
export type EntryInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** The `entry` to be created by this mutation. */
export type EntryModelFkeyEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `model` in the `EntryInput` mutation. */
export type EntryModelFkeyInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnEntryForEntryModelFkeyUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<EntryModelFkeyModelCreateInput>;
};

/** Input for the nested mutation of `entry` in the `ModelInput` mutation. */
export type EntryModelFkeyInverseInput = {
  /** Flag indicating whether all other `entry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyConnect>>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyDelete>>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryOnEntryForEntryModelFkeyUsingEntryPkeyUpdate>>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EntryModelFkeyEntryCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type EntryModelFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryForEntryEnvironmentIdFkeyUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryForEntryEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryForEntryModelFkeyUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryForEntryModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryForFakeAppContentEntryForeignKey0UsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryForFakeAppContentEntryForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryForFakeAppContentEntryForeignKey1UsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryForFakeAppContentEntryForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryForFkEntryLatestVersionUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryForFkEntryLatestVersionPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryPublishForFkEntryPublishEntryUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryPublishForFkEntryPublishEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnEntryVersionForFkEntryUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnEntryVersionForFkEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnMenuItemEntryForFkMenuItemEntryEntryUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnMenuItemEntryForFkMenuItemEntryEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey0UsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey0UsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnTaxonomyForTaxonomyEntryFkeyUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnTaxonomyForTaxonomyEntryFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entry` to look up the row to update. */
export type EntryOnValueFieldForValueFieldEntryFkeyUsingEntryPkeyUpdate = {
  /** An object where the defined keys will be set on the `entry` being updated. */
  patch: UpdateEntryOnValueFieldForValueFieldEntryFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `Entry`. Fields that are set will be updated. */
export type EntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** All input for the `entryPaths` mutation. */
export type EntryPathsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fEntryId?: Maybe<Scalars['UUID']>;
  fTargetId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

/** The output of our `entryPaths` mutation. */
export type EntryPathsPayload = {
  __typename?: 'EntryPathsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['JSON']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type EntryPublish = {
  __typename?: 'EntryPublish';
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
  versionId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  /** Reads a single `Entry` that is related to this `EntryPublish`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryPublish`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `PublishTarget` that is related to this `EntryPublish`. */
  target?: Maybe<PublishTarget>;
  /** Reads a single `EntryVersion` that is related to this `EntryPublish`. */
  entryVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  modifiedBy?: Maybe<User>;
};

/**
 * A condition to be used against `EntryPublish` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EntryPublishCondition = {
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `versionId` field. */
  versionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `snapshot` field. */
  snapshot?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `paths` field. */
  paths?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modifiedById` field. */
  modifiedById?: Maybe<Scalars['UUID']>;
};

/** The `entryPublish` to be created by this mutation. */
export type EntryPublishEnvironmentIdFkeyEntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** Input for the nested mutation of `projectEnvironment` in the `EntryPublishInput` mutation. */
export type EntryPublishEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<EntryPublishEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `ProjectEnvironmentInput` mutation. */
export type EntryPublishEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<EntryPublishEnvironmentIdFkeyEntryPublishCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type EntryPublishEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** A filter to be used against `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type EntryPublishFilter = {
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `versionId` field. */
  versionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `snapshot` field. */
  snapshot?: Maybe<JsonFilter>;
  /** Filter by the object’s `paths` field. */
  paths?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: Maybe<UuidFilter>;
  /** Filter by the object’s `modifiedById` field. */
  modifiedById?: Maybe<UuidFilter>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `target` relation. */
  target?: Maybe<PublishTargetFilter>;
  /** Filter by the object’s `entryVersion` relation. */
  entryVersion?: Maybe<EntryVersionFilter>;
  /** Filter by the object’s `createdBy` relation. */
  createdBy?: Maybe<UserFilter>;
  /** A related `createdBy` exists. */
  createdByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiedBy` relation. */
  modifiedBy?: Maybe<UserFilter>;
  /** A related `modifiedBy` exists. */
  modifiedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EntryPublishFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EntryPublishFilter>>;
  /** Negates the expression. */
  not?: Maybe<EntryPublishFilter>;
};

/** An input for mutations affecting `EntryPublish` */
export type EntryPublishInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForEntryPublishEnvironmentIdFkeyPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey0UsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey0Patch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey1UsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey1Patch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForFkEntryPublishEntryUsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForFkEntryPublishEntryPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForFkEntryPublishEntryVersionUsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForFkEntryPublishEntryVersionPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to update. */
export type EntryPublishOnEntryPublishForFkEntryPublishTargetUsingPkEntryPublishEntryTargetUpdate = {
  /** An object where the defined keys will be set on the `entryPublish` being updated. */
  patch: UpdateEntryPublishOnEntryPublishForFkEntryPublishTargetPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** Represents an update to a `EntryPublish`. Fields that are set will be updated. */
export type EntryPublishPatch = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** The fields on `entryPublish` to look up the row to connect. */
export type EntryPublishPkEntryPublishEntryTargetConnect = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The fields on `entryPublish` to look up the row to delete. */
export type EntryPublishPkEntryPublishEntryTargetDelete = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** A connection to a list of `EntryPublish` values. */
export type EntryPublishesConnection = {
  __typename?: 'EntryPublishesConnection';
  /** A list of `EntryPublish` objects. */
  nodes: Array<Maybe<EntryPublish>>;
  /** A list of edges which contains the `EntryPublish` and cursor to aid in pagination. */
  edges: Array<EntryPublishesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntryPublish` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EntryPublish` edge in the connection. */
export type EntryPublishesEdge = {
  __typename?: 'EntryPublishesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EntryPublish` at the end of the edge. */
  node?: Maybe<EntryPublish>;
};

/** Methods to use when ordering `EntryPublish`. */
export enum EntryPublishesOrderBy {
  Natural = 'NATURAL',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  VersionIdAsc = 'VERSION_ID_ASC',
  VersionIdDesc = 'VERSION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  SnapshotAsc = 'SNAPSHOT_ASC',
  SnapshotDesc = 'SNAPSHOT_DESC',
  PathsAsc = 'PATHS_ASC',
  PathsDesc = 'PATHS_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  ModifiedByIdAsc = 'MODIFIED_BY_ID_ASC',
  ModifiedByIdDesc = 'MODIFIED_BY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  PublishTargetByTargetIdAndProjectIdIdAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__ID_ASC',
  PublishTargetByTargetIdAndProjectIdIdDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__ID_DESC',
  PublishTargetByTargetIdAndProjectIdProjectIdAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__PROJECT_ID_ASC',
  PublishTargetByTargetIdAndProjectIdProjectIdDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__PROJECT_ID_DESC',
  PublishTargetByTargetIdAndProjectIdKeyAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__KEY_ASC',
  PublishTargetByTargetIdAndProjectIdKeyDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__KEY_DESC',
  PublishTargetByTargetIdAndProjectIdNameAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__NAME_ASC',
  PublishTargetByTargetIdAndProjectIdNameDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__NAME_DESC',
  PublishTargetByTargetIdAndProjectIdDeletedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__DELETED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdDeletedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__DELETED_AT_DESC',
  PublishTargetByTargetIdAndProjectIdCreatedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__CREATED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdCreatedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__CREATED_AT_DESC',
  PublishTargetByTargetIdAndProjectIdModifiedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__MODIFIED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdModifiedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__MODIFIED_AT_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdValueIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdValueIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdIndexAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdIndexDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdParentIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdParentIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryVersionByVersionIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_VERSION_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  UserByCreatedByIdIdAsc = 'USER_BY_CREATED_BY_ID__ID_ASC',
  UserByCreatedByIdIdDesc = 'USER_BY_CREATED_BY_ID__ID_DESC',
  UserByCreatedByIdNameAsc = 'USER_BY_CREATED_BY_ID__NAME_ASC',
  UserByCreatedByIdNameDesc = 'USER_BY_CREATED_BY_ID__NAME_DESC',
  UserByModifiedByIdIdAsc = 'USER_BY_MODIFIED_BY_ID__ID_ASC',
  UserByModifiedByIdIdDesc = 'USER_BY_MODIFIED_BY_ID__ID_DESC',
  UserByModifiedByIdNameAsc = 'USER_BY_MODIFIED_BY_ID__NAME_ASC',
  UserByModifiedByIdNameDesc = 'USER_BY_MODIFIED_BY_ID__NAME_DESC'
}

/** A filter to be used against many `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyEntryPublishFilter = {
  /** Every related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryPublishFilter>;
  /** Some related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryPublishFilter>;
  /** No related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryPublishFilter>;
};

/** A filter to be used against many `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyEntryVersionFilter = {
  /** Every related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryVersionFilter>;
  /** Some related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryVersionFilter>;
  /** No related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryVersionFilter>;
};

/** A filter to be used against many `MenuItemEntry` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyMenuItemEntryFilter = {
  /** Every related `MenuItemEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuItemEntryFilter>;
  /** Some related `MenuItemEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuItemEntryFilter>;
  /** No related `MenuItemEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuItemEntryFilter>;
};

/** A filter to be used against many `TaxonomyCombined` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyTaxonomyCombinedFilter = {
  /** Every related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyCombinedFilter>;
  /** Some related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyCombinedFilter>;
  /** No related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyCombinedFilter>;
};

/** A filter to be used against many `TaxonomyDynamic` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyTaxonomyDynamicFilter = {
  /** Every related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyDynamicFilter>;
  /** Some related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyDynamicFilter>;
  /** No related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyDynamicFilter>;
};

/** A filter to be used against many `Taxonomy` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyTaxonomyFilter = {
  /** Every related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyFilter>;
  /** Some related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyFilter>;
  /** No related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyFilter>;
};

/** A filter to be used against many `TaxonomyListNodesEntry` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyTaxonomyListNodesEntryFilter = {
  /** Every related `TaxonomyListNodesEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyListNodesEntryFilter>;
  /** Some related `TaxonomyListNodesEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyListNodesEntryFilter>;
  /** No related `TaxonomyListNodesEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyListNodesEntryFilter>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type EntryToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

export type EntryVersion = {
  __typename?: 'EntryVersion';
  id: Scalars['UUID'];
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  valueId: Scalars['UUID'];
  index: Scalars['Int'];
  modifiedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  /** Reads a single `Entry` that is related to this `EntryVersion`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryVersion`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Value` that is related to this `EntryVersion`. */
  value?: Maybe<Value>;
  /** Reads a single `EntryVersion` that is related to this `EntryVersion`. */
  parentProjectEnvironment?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  modifiedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersionsByParentIdAndProjectIdAndEnvironmentId: EntryVersionsConnection;
  /** Reads and enables pagination through a set of `Entry`. */
  entriesByLatest: EntriesConnection;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishes: EntryPublishesConnection;
};


export type EntryVersionEntryVersionsByParentIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


export type EntryVersionEntriesByLatestArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


export type EntryVersionEntryPublishesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};

/**
 * A condition to be used against `EntryVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EntryVersionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `valueId` field. */
  valueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modifiedById` field. */
  modifiedById?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type EntryVersionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `valueId` field. */
  valueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: Maybe<UuidFilter>;
  /** Filter by the object’s `modifiedById` field. */
  modifiedById?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryVersionsByParentIdAndProjectIdAndEnvironmentId` relation. */
  entryVersionsByParentIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionToManyEntryVersionFilter>;
  /** Some related `entryVersionsByParentIdAndProjectIdAndEnvironmentId` exist. */
  entryVersionsByParentIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entriesByLatest` relation. */
  entriesByLatest?: Maybe<EntryVersionToManyEntryFilter>;
  /** Some related `entriesByLatest` exist. */
  entriesByLatestExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryPublishes` relation. */
  entryPublishes?: Maybe<EntryVersionToManyEntryPublishFilter>;
  /** Some related `entryPublishes` exist. */
  entryPublishesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `value` relation. */
  value?: Maybe<ValueFilter>;
  /** Filter by the object’s `parentProjectEnvironment` relation. */
  parentProjectEnvironment?: Maybe<EntryVersionFilter>;
  /** A related `parentProjectEnvironment` exists. */
  parentProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdBy` relation. */
  createdBy?: Maybe<UserFilter>;
  /** A related `createdBy` exists. */
  createdByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modifiedBy` relation. */
  modifiedBy?: Maybe<UserFilter>;
  /** A related `modifiedBy` exists. */
  modifiedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EntryVersionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EntryVersionFilter>>;
  /** Negates the expression. */
  not?: Maybe<EntryVersionFilter>;
};

/** An input for mutations affecting `EntryVersion` */
export type EntryVersionInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryForFkEntryLatestVersionUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryForFkEntryLatestVersionPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryForFkEntryLatestVersionUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryForFkEntryLatestVersionPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryPublishForFkEntryPublishEntryVersionUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryPublishForFkEntryPublishEntryVersionPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryPublishForFkEntryPublishEntryVersionUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryPublishForFkEntryPublishEntryVersionPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0UsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0UsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0Patch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1UsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1UsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1Patch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEntryUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEntryUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEntryPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEntryVersionParentUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEntryVersionParentPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEntryVersionParentUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEntryVersionParentPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEnvironmentUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEnvironmentPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkEnvironmentUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkEnvironmentPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkValueUsingPkEntryVersionUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkValuePatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to update. */
export type EntryVersionOnEntryVersionForFkValueUsingUqIndexUpdate = {
  /** An object where the defined keys will be set on the `entryVersion` being updated. */
  patch: UpdateEntryVersionOnEntryVersionForFkValuePatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** Represents an update to a `EntryVersion`. Fields that are set will be updated. */
export type EntryVersionPatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** The fields on `entryVersion` to look up the row to connect. */
export type EntryVersionPkEntryVersionConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `entryVersion` to look up the row to delete. */
export type EntryVersionPkEntryVersionDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against many `Entry` object types. All fields are combined with a logical ‘and.’ */
export type EntryVersionToManyEntryFilter = {
  /** Every related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryFilter>;
  /** Some related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryFilter>;
  /** No related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryFilter>;
};

/** A filter to be used against many `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type EntryVersionToManyEntryPublishFilter = {
  /** Every related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryPublishFilter>;
  /** Some related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryPublishFilter>;
  /** No related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryPublishFilter>;
};

/** A filter to be used against many `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type EntryVersionToManyEntryVersionFilter = {
  /** Every related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryVersionFilter>;
  /** Some related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryVersionFilter>;
  /** No related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryVersionFilter>;
};

/** The fields on `entryVersion` to look up the row to connect. */
export type EntryVersionUqIndexConnect = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** The fields on `entryVersion` to look up the row to delete. */
export type EntryVersionUqIndexDelete = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** A connection to a list of `EntryVersion` values. */
export type EntryVersionsConnection = {
  __typename?: 'EntryVersionsConnection';
  /** A list of `EntryVersion` objects. */
  nodes: Array<Maybe<EntryVersion>>;
  /** A list of edges which contains the `EntryVersion` and cursor to aid in pagination. */
  edges: Array<EntryVersionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntryVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EntryVersion` edge in the connection. */
export type EntryVersionsEdge = {
  __typename?: 'EntryVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EntryVersion` at the end of the edge. */
  node?: Maybe<EntryVersion>;
};

/** Methods to use when ordering `EntryVersion`. */
export enum EntryVersionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ValueIdAsc = 'VALUE_ID_ASC',
  ValueIdDesc = 'VALUE_ID_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  ModifiedByIdAsc = 'MODIFIED_BY_ID_ASC',
  ModifiedByIdDesc = 'MODIFIED_BY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModelIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModelIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdValueIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdValueIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdIndexAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdIndexDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdParentIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdParentIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryVersionByParentIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_VERSION_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  UserByCreatedByIdIdAsc = 'USER_BY_CREATED_BY_ID__ID_ASC',
  UserByCreatedByIdIdDesc = 'USER_BY_CREATED_BY_ID__ID_DESC',
  UserByCreatedByIdNameAsc = 'USER_BY_CREATED_BY_ID__NAME_ASC',
  UserByCreatedByIdNameDesc = 'USER_BY_CREATED_BY_ID__NAME_DESC',
  UserByModifiedByIdIdAsc = 'USER_BY_MODIFIED_BY_ID__ID_ASC',
  UserByModifiedByIdIdDesc = 'USER_BY_MODIFIED_BY_ID__ID_DESC',
  UserByModifiedByIdNameAsc = 'USER_BY_MODIFIED_BY_ID__NAME_ASC',
  UserByModifiedByIdNameDesc = 'USER_BY_MODIFIED_BY_ID__NAME_DESC',
  EntryVersionsByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRY_VERSIONS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntryVersionsByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRY_VERSIONS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  EntriesByLatestVersionIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRIES_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntriesByLatestVersionIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRIES_BY_LATEST_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  EntryPublishesByVersionIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRY_PUBLISHES_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntryPublishesByVersionIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRY_PUBLISHES_BY_VERSION_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

export type EntryWebsitePath = {
  __typename?: 'EntryWebsitePath';
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  websiteId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  pathRoot?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathRelative?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * A condition to be used against `EntryWebsitePath` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EntryWebsitePathCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `websiteId` field. */
  websiteId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pathRoot` field. */
  pathRoot?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `pathRelative` field. */
  pathRelative?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A filter to be used against `EntryWebsitePath` object types. All fields are combined with a logical ‘and.’ */
export type EntryWebsitePathFilter = {
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `websiteId` field. */
  websiteId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `pathRoot` field. */
  pathRoot?: Maybe<StringListFilter>;
  /** Filter by the object’s `pathRelative` field. */
  pathRelative?: Maybe<StringListFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EntryWebsitePathFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EntryWebsitePathFilter>>;
  /** Negates the expression. */
  not?: Maybe<EntryWebsitePathFilter>;
};

/** A connection to a list of `EntryWebsitePath` values. */
export type EntryWebsitePathsConnection = {
  __typename?: 'EntryWebsitePathsConnection';
  /** A list of `EntryWebsitePath` objects. */
  nodes: Array<Maybe<EntryWebsitePath>>;
  /** A list of edges which contains the `EntryWebsitePath` and cursor to aid in pagination. */
  edges: Array<EntryWebsitePathsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EntryWebsitePath` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EntryWebsitePath` edge in the connection. */
export type EntryWebsitePathsEdge = {
  __typename?: 'EntryWebsitePathsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EntryWebsitePath` at the end of the edge. */
  node?: Maybe<EntryWebsitePath>;
};

/** Methods to use when ordering `EntryWebsitePath`. */
export enum EntryWebsitePathsOrderBy {
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  WebsiteIdAsc = 'WEBSITE_ID_ASC',
  WebsiteIdDesc = 'WEBSITE_ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  PathRootAsc = 'PATH_ROOT_ASC',
  PathRootDesc = 'PATH_ROOT_DESC',
  PathRelativeAsc = 'PATH_RELATIVE_ASC',
  PathRelativeDesc = 'PATH_RELATIVE_DESC'
}

/** All input for the `expireProjectApiKey` mutation. */
export type ExpireProjectApiKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

/** The output of our `expireProjectApiKey` mutation. */
export type ExpireProjectApiKeyPayload = {
  __typename?: 'ExpireProjectApiKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The `entry` to be created by this mutation. */
export type FakeAppContentEntryForeignKey0EntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `user` in the `EntryInput` mutation. */
export type FakeAppContentEntryForeignKey0Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryForeignKey0UserCreateInput>;
};

/** Input for the nested mutation of `entry` in the `UserInput` mutation. */
export type FakeAppContentEntryForeignKey0InverseInput = {
  /** Flag indicating whether all other `entry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyConnect>>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyDelete>>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryOnEntryForFakeAppContentEntryForeignKey0UsingEntryPkeyUpdate>>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryForeignKey0EntryCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryForeignKey0UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** The `entry` to be created by this mutation. */
export type FakeAppContentEntryForeignKey1EntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `user` in the `EntryInput` mutation. */
export type FakeAppContentEntryForeignKey1Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryForeignKey1UserCreateInput>;
};

/** Input for the nested mutation of `entry` in the `UserInput` mutation. */
export type FakeAppContentEntryForeignKey1InverseInput = {
  /** Flag indicating whether all other `entry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyConnect>>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyDelete>>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryOnEntryForFakeAppContentEntryForeignKey1UsingEntryPkeyUpdate>>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryForeignKey1EntryCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryForeignKey1UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** The `entryPublish` to be created by this mutation. */
export type FakeAppContentEntryPublishForeignKey0EntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** Input for the nested mutation of `user` in the `EntryPublishInput` mutation. */
export type FakeAppContentEntryPublishForeignKey0Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryPublishForeignKey0UserCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `UserInput` mutation. */
export type FakeAppContentEntryPublishForeignKey0InverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey0UsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryPublishForeignKey0EntryPublishCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryPublishForeignKey0UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** The `entryPublish` to be created by this mutation. */
export type FakeAppContentEntryPublishForeignKey1EntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** Input for the nested mutation of `user` in the `EntryPublishInput` mutation. */
export type FakeAppContentEntryPublishForeignKey1Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryPublishForeignKey1UserCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `UserInput` mutation. */
export type FakeAppContentEntryPublishForeignKey1InverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey1UsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryPublishForeignKey1EntryPublishCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryPublishForeignKey1UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FakeAppContentEntryVersionForeignKey0EntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `user` in the `EntryVersionInput` mutation. */
export type FakeAppContentEntryVersionForeignKey0Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryVersionForeignKey0UserCreateInput>;
};

/** Input for the nested mutation of `entryVersion` in the `UserInput` mutation. */
export type FakeAppContentEntryVersionForeignKey0InverseInput = {
  /** Flag indicating whether all other `entryVersion` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionDelete>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexDelete>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0UsingPkEntryVersionUpdate>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0UsingUqIndexUpdate>>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryVersionForeignKey0EntryVersionCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryVersionForeignKey0UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FakeAppContentEntryVersionForeignKey1EntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `user` in the `EntryVersionInput` mutation. */
export type FakeAppContentEntryVersionForeignKey1Input = {
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentEntryVersionForeignKey1UserCreateInput>;
};

/** Input for the nested mutation of `entryVersion` in the `UserInput` mutation. */
export type FakeAppContentEntryVersionForeignKey1InverseInput = {
  /** Flag indicating whether all other `entryVersion` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionDelete>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexDelete>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1UsingPkEntryVersionUpdate>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1UsingUqIndexUpdate>>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentEntryVersionForeignKey1EntryVersionCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FakeAppContentEntryVersionForeignKey1UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** Input for the nested mutation of `model` in the `ModelFieldsInheritedInput` mutation. */
export type FakeAppContentModelFieldAllForeignKey0Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey0UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentModelFieldAllForeignKey0ModelCreateInput>;
};

/** Input for the nested mutation of `modelFieldsInherited` in the `ModelInput` mutation. */
export type FakeAppContentModelFieldAllForeignKey0InverseInput = {
  /** A `ModelFieldsInheritedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentModelFieldAllForeignKey0ModelFieldAllCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentModelFieldAllForeignKey0ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelFieldsInherited` to be created by this mutation. */
export type FakeAppContentModelFieldAllForeignKey0ModelFieldAllCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  sourceModelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  multi?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  sourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0Input>;
  model?: Maybe<FakeAppContentModelFieldAllForeignKey1Input>;
};

/** Input for the nested mutation of `model` in the `ModelFieldsInheritedInput` mutation. */
export type FakeAppContentModelFieldAllForeignKey1Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey1UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentModelFieldAllForeignKey1ModelCreateInput>;
};

/** Input for the nested mutation of `modelFieldsInherited` in the `ModelInput` mutation. */
export type FakeAppContentModelFieldAllForeignKey1InverseInput = {
  /** A `ModelFieldsInheritedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentModelFieldAllForeignKey1ModelFieldAllCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentModelFieldAllForeignKey1ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelFieldsInherited` to be created by this mutation. */
export type FakeAppContentModelFieldAllForeignKey1ModelFieldAllCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  sourceModelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  multi?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  sourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0Input>;
  model?: Maybe<FakeAppContentModelFieldAllForeignKey1Input>;
};

/** Input for the nested mutation of `model` in the `ModelParentsRecursiveInput` mutation. */
export type FakeAppContentModelParentAllForeignKey0Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey0UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentModelParentAllForeignKey0ModelCreateInput>;
};

/** Input for the nested mutation of `modelParentsRecursive` in the `ModelInput` mutation. */
export type FakeAppContentModelParentAllForeignKey0InverseInput = {
  /** A `ModelParentsRecursiveInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentModelParentAllForeignKey0ModelParentAllCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentModelParentAllForeignKey0ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelParentsRecursive` to be created by this mutation. */
export type FakeAppContentModelParentAllForeignKey0ModelParentAllCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  level?: Maybe<Scalars['Int']>;
  model?: Maybe<FakeAppContentModelParentAllForeignKey0Input>;
  parent?: Maybe<FakeAppContentModelParentAllForeignKey1Input>;
};

/** Input for the nested mutation of `model` in the `ModelParentsRecursiveInput` mutation. */
export type FakeAppContentModelParentAllForeignKey1Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey1UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentModelParentAllForeignKey1ModelCreateInput>;
};

/** Input for the nested mutation of `modelParentsRecursive` in the `ModelInput` mutation. */
export type FakeAppContentModelParentAllForeignKey1InverseInput = {
  /** A `ModelParentsRecursiveInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentModelParentAllForeignKey1ModelParentAllCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentModelParentAllForeignKey1ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelParentsRecursive` to be created by this mutation. */
export type FakeAppContentModelParentAllForeignKey1ModelParentAllCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  level?: Maybe<Scalars['Int']>;
  model?: Maybe<FakeAppContentModelParentAllForeignKey0Input>;
  parent?: Maybe<FakeAppContentModelParentAllForeignKey1Input>;
};

/** Input for the nested mutation of `project` in the `PublishTargetInput` mutation. */
export type FakeAppContentPublishTargetForeignKey0Input = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<ProjectFakeAppProjectProjectUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<ProjectOnPublishTargetForFakeAppContentPublishTargetForeignKey0UsingFakeAppProjectProjectUniqueUpdate>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentPublishTargetForeignKey0ProjectCreateInput>;
};

/** Input for the nested mutation of `publishTarget` in the `ProjectInput` mutation. */
export type FakeAppContentPublishTargetForeignKey0InverseInput = {
  /** Flag indicating whether all other `publishTarget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  connectByIdAndProjectId?: Maybe<Array<PublishTargetPkPublishTargetConnect>>;
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  deleteByIdAndProjectId?: Maybe<Array<PublishTargetPkPublishTargetDelete>>;
  /** The primary key(s) and patch data for `publishTarget` for the far side of the relationship. */
  updateByIdAndProjectId?: Maybe<Array<PublishTargetOnPublishTargetForFakeAppContentPublishTargetForeignKey0UsingPkPublishTargetUpdate>>;
  /** A `PublishTargetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentPublishTargetForeignKey0PublishTargetCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppContentPublishTargetForeignKey0ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The `publishTarget` to be created by this mutation. */
export type FakeAppContentPublishTargetForeignKey0PublishTargetCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** The `entry` to be created by this mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey0EntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `entry` in the `TaxonomyCombinedInput` mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey0Input = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey0UsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0EntryCreateInput>;
};

/** Input for the nested mutation of `taxonomyCombined` in the `EntryInput` mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey0InverseInput = {
  /** A `TaxonomyCombinedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyCombinedForeignKey0TaxonomyCombinedCreateInput>>;
};

/** The `taxonomyCombined` to be created by this mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey0TaxonomyCombinedCreateInput = {
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  source?: Maybe<TaxonomySource>;
  targetId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0Input>;
  model?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1Input>;
};

/** Input for the nested mutation of `model` in the `TaxonomyCombinedInput` mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey1Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey1UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1ModelCreateInput>;
};

/** Input for the nested mutation of `taxonomyCombined` in the `ModelInput` mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey1InverseInput = {
  /** A `TaxonomyCombinedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyCombinedForeignKey1TaxonomyCombinedCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey1ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `taxonomyCombined` to be created by this mutation. */
export type FakeAppContentTaxonomyCombinedForeignKey1TaxonomyCombinedCreateInput = {
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  source?: Maybe<TaxonomySource>;
  targetId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0Input>;
  model?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1Input>;
};

/** The `entry` to be created by this mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey0EntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `entry` in the `TaxonomyDynamicInput` mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey0Input = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey0UsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0EntryCreateInput>;
};

/** Input for the nested mutation of `taxonomyDynamic` in the `EntryInput` mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey0InverseInput = {
  /** A `TaxonomyDynamicInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyDynamicForeignKey0TaxonomyDynamicCreateInput>>;
};

/** The `taxonomyDynamic` to be created by this mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey0TaxonomyDynamicCreateInput = {
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0Input>;
  model?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1Input>;
};

/** Input for the nested mutation of `model` in the `TaxonomyDynamicInput` mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey1Input = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey1UsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1ModelCreateInput>;
};

/** Input for the nested mutation of `taxonomyDynamic` in the `ModelInput` mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey1InverseInput = {
  /** A `TaxonomyDynamicInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyDynamicForeignKey1TaxonomyDynamicCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey1ModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `taxonomyDynamic` to be created by this mutation. */
export type FakeAppContentTaxonomyDynamicForeignKey1TaxonomyDynamicCreateInput = {
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0Input>;
  model?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1Input>;
};

/** Input for the nested mutation of `taxonomyListNodesEntry` in the `EntryInput` mutation. */
export type FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput = {
  /** A `TaxonomyListNodesEntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyListNodesEntryForeignKey0TaxonomyListNodesEntryCreateInput>>;
};

/** The `taxonomyListNodesEntry` to be created by this mutation. */
export type FakeAppContentTaxonomyListNodesEntryForeignKey0TaxonomyListNodesEntryCreateInput = {
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `taxonomy` in the `TaxonomyPathInput` mutation. */
export type FakeAppContentTaxonomyPathForeignKey0Input = {
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyConnect>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyDelete>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0UsingTaxonomyPkeyUpdate>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentTaxonomyPathForeignKey0TaxonomyCreateInput>;
};

/** Input for the nested mutation of `taxonomyPath` in the `TaxonomyInput` mutation. */
export type FakeAppContentTaxonomyPathForeignKey0InverseInput = {
  /** Flag indicating whether all other `taxonomyPath` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyPath` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyPathFakeAppContentTaxonomyPathPrimaryKeyConnect>;
  /** The primary key(s) for `taxonomyPath` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyPathFakeAppContentTaxonomyPathPrimaryKeyDelete>;
  /** The primary key(s) and patch data for `taxonomyPath` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyPathOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0UsingFakeAppContentTaxonomyPathPrimaryKeyUpdate>;
  /** A `TaxonomyPathInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentTaxonomyPathForeignKey0TaxonomyPathCreateInput>>;
};

/** The `taxonomy` to be created by this mutation. */
export type FakeAppContentTaxonomyPathForeignKey0TaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** The `taxonomyPath` to be created by this mutation. */
export type FakeAppContentTaxonomyPathForeignKey0TaxonomyPathCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  path?: Maybe<FakeAppContentTaxonomyPathForeignKey0Input>;
};

/** Input for the nested mutation of `project` in the `ValueFieldInput` mutation. */
export type FakeAppContentValueFieldForeignKey0Input = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<ProjectFakeAppProjectProjectUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<ProjectOnValueFieldForFakeAppContentValueFieldForeignKey0UsingFakeAppProjectProjectUniqueUpdate>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentValueFieldForeignKey0ProjectCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `ProjectInput` mutation. */
export type FakeAppContentValueFieldForeignKey0InverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForFakeAppContentValueFieldForeignKey0UsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentValueFieldForeignKey0ValueFieldCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppContentValueFieldForeignKey0ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type FakeAppContentValueFieldForeignKey0ValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** Input for the nested mutation of `project` in the `ValueInput` mutation. */
export type FakeAppContentValueForeignKey0Input = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<ProjectFakeAppProjectProjectUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<ProjectOnValueForFakeAppContentValueForeignKey0UsingFakeAppProjectProjectUniqueUpdate>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppContentValueForeignKey0ProjectCreateInput>;
};

/** Input for the nested mutation of `value` in the `ProjectInput` mutation. */
export type FakeAppContentValueForeignKey0InverseInput = {
  /** Flag indicating whether all other `value` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyConnect>>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyDelete>>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueOnValueForFakeAppContentValueForeignKey0UsingValuePkeyUpdate>>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppContentValueForeignKey0ValueCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppContentValueForeignKey0ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The `value` to be created by this mutation. */
export type FakeAppContentValueForeignKey0ValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** Input for the nested mutation of `project` in the `ProjectEnvironmentInput` mutation. */
export type FakeAppProjectProjectEnvironmentForeignKey0Input = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<ProjectFakeAppProjectProjectUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<ProjectOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingFakeAppProjectProjectUniqueUpdate>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0ProjectCreateInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ProjectInput` mutation. */
export type FakeAppProjectProjectEnvironmentForeignKey0InverseInput = {
  /** Flag indicating whether all other `projectEnvironment` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<Array<ProjectEnvironmentProjectEnvironmentPkeyConnect>>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<Array<ProjectEnvironmentUqProjectEnvironmentKeyConnect>>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProjectEnvironmentProjectEnvironmentPkeyDelete>>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<Array<ProjectEnvironmentUqProjectEnvironmentKeyDelete>>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<Array<ProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingProjectEnvironmentPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<Array<ProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingUqProjectEnvironmentKeyUpdate>>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppProjectProjectEnvironmentForeignKey0ProjectEnvironmentCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppProjectProjectEnvironmentForeignKey0ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type FakeAppProjectProjectEnvironmentForeignKey0ProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `account` to be created by this mutation. */
export type FakeAppProjectProjectForeignKey0AccountCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey0InverseInput>;
};

/** Input for the nested mutation of `account` in the `ProjectInput` mutation. */
export type FakeAppProjectProjectForeignKey0Input = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByKey?: Maybe<AccountFakeAppProjectAccountUniqueConnect>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByKey?: Maybe<AccountOnProjectForFakeAppProjectProjectForeignKey0UsingFakeAppProjectAccountUniqueUpdate>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppProjectProjectForeignKey0AccountCreateInput>;
};

/** Input for the nested mutation of `project` in the `AccountInput` mutation. */
export type FakeAppProjectProjectForeignKey0InverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<Array<ProjectFakeAppProjectProjectUniqueConnect>>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<Array<ProjectOnProjectForFakeAppProjectProjectForeignKey0UsingFakeAppProjectProjectUniqueUpdate>>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppProjectProjectForeignKey0ProjectCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppProjectProjectForeignKey0ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ProjectInput` mutation. */
export type FakeAppProjectProjectForeignKey1Input = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1UsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1UsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<FakeAppProjectProjectForeignKey1ProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `project` in the `ProjectEnvironmentInput` mutation. */
export type FakeAppProjectProjectForeignKey1InverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByAccountIdAndKey?: Maybe<Array<ProjectFakeAppProjectProjectUniqueConnect>>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByAccountIdAndKey?: Maybe<Array<ProjectOnProjectForFakeAppProjectProjectForeignKey1UsingFakeAppProjectProjectUniqueUpdate>>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FakeAppProjectProjectForeignKey1ProjectCreateInput>>;
};

/** The `project` to be created by this mutation. */
export type FakeAppProjectProjectForeignKey1ProjectCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type FakeAppProjectProjectForeignKey1ProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `entry` to be created by this mutation. */
export type FkEntryEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkEntryEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `entry` in the `EntryVersionInput` mutation. */
export type FkEntryInput = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnEntryVersionForFkEntryUsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryEntryCreateInput>;
};

/** Input for the nested mutation of `entryVersion` in the `EntryInput` mutation. */
export type FkEntryInverseInput = {
  /** Flag indicating whether all other `entryVersion` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionDelete>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexDelete>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionOnEntryVersionForFkEntryUsingPkEntryVersionUpdate>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionOnEntryVersionForFkEntryUsingUqIndexUpdate>>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEntryEntryVersionCreateInput>>;
};

/** The `entry` to be created by this mutation. */
export type FkEntryLatestVersionEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkEntryLatestVersionEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `entryVersion` in the `EntryInput` mutation. */
export type FkEntryLatestVersionInput = {
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionDelete>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexDelete>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionOnEntryForFkEntryLatestVersionUsingPkEntryVersionUpdate>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionOnEntryForFkEntryLatestVersionUsingUqIndexUpdate>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryLatestVersionEntryVersionCreateInput>;
};

/** Input for the nested mutation of `entry` in the `EntryVersionInput` mutation. */
export type FkEntryLatestVersionInverseInput = {
  /** Flag indicating whether all other `entry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyConnect>>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryEntryPkeyDelete>>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryOnEntryForFkEntryLatestVersionUsingEntryPkeyUpdate>>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEntryLatestVersionEntryCreateInput>>;
};

/** The `entry` to be created by this mutation. */
export type FkEntryPublishEntryEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** The `entryPublish` to be created by this mutation. */
export type FkEntryPublishEntryEntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** Input for the nested mutation of `entry` in the `EntryPublishInput` mutation. */
export type FkEntryPublishEntryInput = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnEntryPublishForFkEntryPublishEntryUsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryPublishEntryEntryCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `EntryInput` mutation. */
export type FkEntryPublishEntryInverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForFkEntryPublishEntryUsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEntryPublishEntryEntryPublishCreateInput>>;
};

/** The `entryPublish` to be created by this mutation. */
export type FkEntryPublishEntryVersionEntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkEntryPublishEntryVersionEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `entryVersion` in the `EntryPublishInput` mutation. */
export type FkEntryPublishEntryVersionInput = {
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionDelete>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexDelete>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionOnEntryPublishForFkEntryPublishEntryVersionUsingPkEntryVersionUpdate>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionOnEntryPublishForFkEntryPublishEntryVersionUsingUqIndexUpdate>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryPublishEntryVersionEntryVersionCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `EntryVersionInput` mutation. */
export type FkEntryPublishEntryVersionInverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForFkEntryPublishEntryVersionUsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEntryPublishEntryVersionEntryPublishCreateInput>>;
};

/** The `entryPublish` to be created by this mutation. */
export type FkEntryPublishTargetEntryPublishCreateInput = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** Input for the nested mutation of `publishTarget` in the `EntryPublishInput` mutation. */
export type FkEntryPublishTargetInput = {
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  connectByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetConnect>;
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  deleteByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetDelete>;
  /** The primary key(s) and patch data for `publishTarget` for the far side of the relationship. */
  updateByIdAndProjectId?: Maybe<PublishTargetOnEntryPublishForFkEntryPublishTargetUsingPkPublishTargetUpdate>;
  /** A `PublishTargetInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryPublishTargetPublishTargetCreateInput>;
};

/** Input for the nested mutation of `entryPublish` in the `PublishTargetInput` mutation. */
export type FkEntryPublishTargetInverseInput = {
  /** Flag indicating whether all other `entryPublish` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetConnect>>;
  /** The primary key(s) for `entryPublish` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishPkEntryPublishEntryTargetDelete>>;
  /** The primary key(s) and patch data for `entryPublish` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndTargetId?: Maybe<Array<EntryPublishOnEntryPublishForFkEntryPublishTargetUsingPkEntryPublishEntryTargetUpdate>>;
  /** A `EntryPublishInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEntryPublishTargetEntryPublishCreateInput>>;
};

/** The `publishTarget` to be created by this mutation. */
export type FkEntryPublishTargetPublishTargetCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkEntryVersionParentEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `entryVersion` in the `EntryVersionInput` mutation. */
export type FkEntryVersionParentInput = {
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexConnect>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionPkEntryVersionDelete>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionUqIndexDelete>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryVersionOnEntryVersionForFkEntryVersionParentUsingPkEntryVersionUpdate>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<EntryVersionOnEntryVersionForFkEntryVersionParentUsingUqIndexUpdate>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<FkEntryVersionParentEntryVersionCreateInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkEnvironmentEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `EntryVersionInput` mutation. */
export type FkEnvironmentInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnEntryVersionForFkEnvironmentUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnEntryVersionForFkEnvironmentUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<FkEnvironmentProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `entryVersion` in the `ProjectEnvironmentInput` mutation. */
export type FkEnvironmentInverseInput = {
  /** Flag indicating whether all other `entryVersion` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionDelete>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexDelete>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionOnEntryVersionForFkEnvironmentUsingPkEntryVersionUpdate>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionOnEntryVersionForFkEnvironmentUsingUqIndexUpdate>>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkEnvironmentEntryVersionCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type FkEnvironmentProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** Input for the nested mutation of `modelFieldGroup` in the `ModelFieldInput` mutation. */
export type FkFieldGroupInput = {
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldGroupPkModelFieldGroupConnect>;
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldGroupPkModelFieldGroupDelete>;
  /** The primary key(s) and patch data for `modelFieldGroup` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldGroupOnModelFieldForFkFieldGroupUsingPkModelFieldGroupUpdate>;
  /** A `ModelFieldGroupInput` object that will be created and connected to this object. */
  create?: Maybe<FkFieldGroupModelFieldGroupCreateInput>;
};

/** Input for the nested mutation of `modelField` in the `ModelFieldGroupInput` mutation. */
export type FkFieldGroupInverseInput = {
  /** Flag indicating whether all other `modelField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyConnect>>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldOnModelFieldForFkFieldGroupUsingModelFieldPkeyUpdate>>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkFieldGroupModelFieldCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type FkFieldGroupModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `modelFieldGroup` to be created by this mutation. */
export type FkFieldGroupModelFieldGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** The `entry` to be created by this mutation. */
export type FkMenuItemEntryEntryEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `entry` in the `MenuItemEntryInput` mutation. */
export type FkMenuItemEntryEntryInput = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnMenuItemEntryForFkMenuItemEntryEntryUsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemEntryEntryEntryCreateInput>;
};

/** Input for the nested mutation of `menuItemEntry` in the `EntryInput` mutation. */
export type FkMenuItemEntryEntryInverseInput = {
  /** Flag indicating whether all other `menuItemEntry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `menuItemEntry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemEntryPkMenuItemEntryConnect>>;
  /** The primary key(s) for `menuItemEntry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemEntryPkMenuItemEntryDelete>>;
  /** The primary key(s) and patch data for `menuItemEntry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemEntryOnMenuItemEntryForFkMenuItemEntryEntryUsingPkMenuItemEntryUpdate>>;
  /** A `MenuItemEntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkMenuItemEntryEntryMenuItemEntryCreateInput>>;
};

/** The `menuItemEntry` to be created by this mutation. */
export type FkMenuItemEntryEntryMenuItemEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** Input for the nested mutation of `menuItem` in the `MenuItemEntryInput` mutation. */
export type FkMenuItemEntryMenuItemInput = {
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemConnect>;
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemDelete>;
  /** The primary key(s) and patch data for `menuItem` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemOnMenuItemEntryForFkMenuItemEntryMenuItemUsingPkMenuItemUpdate>;
  /** A `MenuItemInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemEntryMenuItemMenuItemCreateInput>;
};

/** Input for the nested mutation of `menuItemEntry` in the `MenuItemInput` mutation. */
export type FkMenuItemEntryMenuItemInverseInput = {
  /** Flag indicating whether all other `menuItemEntry` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `menuItemEntry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemEntryPkMenuItemEntryConnect>;
  /** The primary key(s) for `menuItemEntry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemEntryPkMenuItemEntryDelete>;
  /** The primary key(s) and patch data for `menuItemEntry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemEntryOnMenuItemEntryForFkMenuItemEntryMenuItemUsingPkMenuItemEntryUpdate>;
  /** A `MenuItemEntryInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkMenuItemEntryMenuItemMenuItemEntryCreateInput>>;
};

/** The `menuItem` to be created by this mutation. */
export type FkMenuItemEntryMenuItemMenuItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** The `menuItemEntry` to be created by this mutation. */
export type FkMenuItemEntryMenuItemMenuItemEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `MenuItemInput` mutation. */
export type FkMenuItemEnvironmentInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemEnvironmentProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `menuItem` in the `ProjectEnvironmentInput` mutation. */
export type FkMenuItemEnvironmentInverseInput = {
  /** Flag indicating whether all other `menuItem` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemPkMenuItemConnect>>;
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemPkMenuItemDelete>>;
  /** The primary key(s) and patch data for `menuItem` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemOnMenuItemForFkMenuItemEnvironmentUsingPkMenuItemUpdate>>;
  /** A `MenuItemInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkMenuItemEnvironmentMenuItemCreateInput>>;
};

/** The `menuItem` to be created by this mutation. */
export type FkMenuItemEnvironmentMenuItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type FkMenuItemEnvironmentProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** Input for the nested mutation of `menuItem` in the `MenuItemInput` mutation. */
export type FkMenuItemParentInput = {
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemConnect>;
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemDelete>;
  /** The primary key(s) and patch data for `menuItem` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemOnMenuItemForFkMenuItemParentUsingPkMenuItemUpdate>;
  /** A `MenuItemInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemParentMenuItemCreateInput>;
};

/** The `menuItem` to be created by this mutation. */
export type FkMenuItemParentMenuItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** Input for the nested mutation of `menuItem` in the `MenuItemViewInput` mutation. */
export type FkMenuItemViewMenuItemInput = {
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemConnect>;
  /** The primary key(s) for `menuItem` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemPkMenuItemDelete>;
  /** The primary key(s) and patch data for `menuItem` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemOnMenuItemViewForFkMenuItemViewMenuItemUsingPkMenuItemUpdate>;
  /** A `MenuItemInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemViewMenuItemMenuItemCreateInput>;
};

/** Input for the nested mutation of `menuItemView` in the `MenuItemInput` mutation. */
export type FkMenuItemViewMenuItemInverseInput = {
  /** Flag indicating whether all other `menuItemView` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `menuItemView` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemViewPkMenuItemViewConnect>;
  /** The primary key(s) for `menuItemView` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemViewPkMenuItemViewDelete>;
  /** The primary key(s) and patch data for `menuItemView` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemViewOnMenuItemViewForFkMenuItemViewMenuItemUsingPkMenuItemViewUpdate>;
  /** A `MenuItemViewInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkMenuItemViewMenuItemMenuItemViewCreateInput>>;
};

/** The `menuItem` to be created by this mutation. */
export type FkMenuItemViewMenuItemMenuItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** The `menuItemView` to be created by this mutation. */
export type FkMenuItemViewMenuItemMenuItemViewCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** Input for the nested mutation of `model` in the `MenuItemViewInput` mutation. */
export type FkMenuItemViewModelInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnMenuItemViewForFkMenuItemViewModelUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FkMenuItemViewModelModelCreateInput>;
};

/** Input for the nested mutation of `menuItemView` in the `ModelInput` mutation. */
export type FkMenuItemViewModelInverseInput = {
  /** Flag indicating whether all other `menuItemView` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `menuItemView` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemViewPkMenuItemViewConnect>>;
  /** The primary key(s) for `menuItemView` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemViewPkMenuItemViewDelete>>;
  /** The primary key(s) and patch data for `menuItemView` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<MenuItemViewOnMenuItemViewForFkMenuItemViewModelUsingPkMenuItemViewUpdate>>;
  /** A `MenuItemViewInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkMenuItemViewModelMenuItemViewCreateInput>>;
};

/** The `menuItemView` to be created by this mutation. */
export type FkMenuItemViewModelMenuItemViewCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** The `model` to be created by this mutation. */
export type FkMenuItemViewModelModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ModelInput` mutation. */
export type FkModelDefaultTaxonomyLevelInput = {
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelPkTaxonomyLevelConnect>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelPkTaxonomyLevelDelete>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelOnModelForFkModelDefaultTaxonomyLevelUsingPkTaxonomyLevelUpdate>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<FkModelDefaultTaxonomyLevelTaxonomyLevelCreateInput>;
};

/** Input for the nested mutation of `model` in the `TaxonomyLevelInput` mutation. */
export type FkModelDefaultTaxonomyLevelInverseInput = {
  /** Flag indicating whether all other `model` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyConnect>>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyDelete>>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelOnModelForFkModelDefaultTaxonomyLevelUsingModelPkeyUpdate>>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkModelDefaultTaxonomyLevelModelCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FkModelDefaultTaxonomyLevelModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkModelDefaultTaxonomyLevelTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** Input for the nested mutation of `modelField` in the `ModelFieldInput` mutation. */
export type FkModelFieldAutoFieldInput = {
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyConnect>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyDelete>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldOnModelFieldForFkModelFieldAutoFieldUsingModelFieldPkeyUpdate>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<FkModelFieldAutoFieldModelFieldCreateInput>;
};

/** The `modelField` to be created by this mutation. */
export type FkModelFieldAutoFieldModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** Input for the nested mutation of `model` in the `ModelFieldGroupInput` mutation. */
export type FkModelInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelFieldGroupForFkModelUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FkModelModelCreateInput>;
};

/** Input for the nested mutation of `modelFieldGroup` in the `ModelInput` mutation. */
export type FkModelInverseInput = {
  /** Flag indicating whether all other `modelFieldGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupPkModelFieldGroupConnect>>;
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupPkModelFieldGroupDelete>>;
  /** The primary key(s) and patch data for `modelFieldGroup` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupOnModelFieldGroupForFkModelUsingPkModelFieldGroupUpdate>>;
  /** A `ModelFieldGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkModelModelFieldGroupCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FkModelModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelFieldGroup` to be created by this mutation. */
export type FkModelModelFieldGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** Input for the nested mutation of `publishTarget` in the `ProjectTokenInput` mutation. */
export type FkProjectTokenPublishTargetInput = {
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  connectByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetConnect>;
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  deleteByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetDelete>;
  /** The primary key(s) and patch data for `publishTarget` for the far side of the relationship. */
  updateByIdAndProjectId?: Maybe<PublishTargetOnProjectTokenForFkProjectTokenPublishTargetUsingPkPublishTargetUpdate>;
  /** A `PublishTargetInput` object that will be created and connected to this object. */
  create?: Maybe<FkProjectTokenPublishTargetPublishTargetCreateInput>;
};

/** Input for the nested mutation of `projectToken` in the `PublishTargetInput` mutation. */
export type FkProjectTokenPublishTargetInverseInput = {
  /** Flag indicating whether all other `projectToken` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `projectToken` for the far side of the relationship. */
  connectById?: Maybe<Array<ProjectTokenPkProjectTokenConnect>>;
  /** The primary key(s) for `projectToken` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProjectTokenPkProjectTokenDelete>>;
  /** The primary key(s) and patch data for `projectToken` for the far side of the relationship. */
  updateById?: Maybe<Array<ProjectTokenOnProjectTokenForFkProjectTokenPublishTargetUsingPkProjectTokenUpdate>>;
  /** A `ProjectTokenInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkProjectTokenPublishTargetProjectTokenCreateInput>>;
};

/** The `projectToken` to be created by this mutation. */
export type FkProjectTokenPublishTargetProjectTokenCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  targetId?: Maybe<Scalars['UUID']>;
  target?: Maybe<FkProjectTokenPublishTargetInput>;
};

/** The `publishTarget` to be created by this mutation. */
export type FkProjectTokenPublishTargetPublishTargetCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** Input for the nested mutation of `modelField` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelAliasFieldInput = {
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyConnect>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyDelete>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldOnTaxonomyLevelForFkTaxonomyLevelAliasFieldUsingModelFieldPkeyUpdate>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelAliasFieldModelFieldCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ModelFieldInput` mutation. */
export type FkTaxonomyLevelAliasFieldInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelAliasFieldUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkTaxonomyLevelAliasFieldTaxonomyLevelCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type FkTaxonomyLevelAliasFieldModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelAliasFieldTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** Input for the nested mutation of `modelField` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelFragmentFieldInput = {
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyConnect>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyDelete>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldUsingModelFieldPkeyUpdate>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelFragmentFieldModelFieldCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ModelFieldInput` mutation. */
export type FkTaxonomyLevelFragmentFieldInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkTaxonomyLevelFragmentFieldTaxonomyLevelCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type FkTaxonomyLevelFragmentFieldModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelFragmentFieldTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** Input for the nested mutation of `model` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelModelInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnTaxonomyLevelForFkTaxonomyLevelModelUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelModelModelCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ModelInput` mutation. */
export type FkTaxonomyLevelModelInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelModelUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkTaxonomyLevelModelTaxonomyLevelCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type FkTaxonomyLevelModelModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelModelTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelParentInput = {
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelPkTaxonomyLevelConnect>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelPkTaxonomyLevelDelete>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentUsingPkTaxonomyLevelUpdate>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelParentTaxonomyLevelCreateInput>;
};

/** Input for the nested mutation of `modelField` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelParentReferenceFieldInput = {
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyConnect>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyDelete>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldUsingModelFieldPkeyUpdate>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelParentReferenceFieldModelFieldCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ModelFieldInput` mutation. */
export type FkTaxonomyLevelParentReferenceFieldInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkTaxonomyLevelParentReferenceFieldTaxonomyLevelCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type FkTaxonomyLevelParentReferenceFieldModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelParentReferenceFieldTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelParentTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** Input for the nested mutation of `taxonomy` in the `TaxonomyLevelInput` mutation. */
export type FkTaxonomyLevelTaxonomyInput = {
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyConnect>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyDelete>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyOnTaxonomyLevelForFkTaxonomyLevelTaxonomyUsingTaxonomyPkeyUpdate>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<FkTaxonomyLevelTaxonomyTaxonomyCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `TaxonomyInput` mutation. */
export type FkTaxonomyLevelTaxonomyInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelTaxonomyUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkTaxonomyLevelTaxonomyTaxonomyLevelCreateInput>>;
};

/** The `taxonomy` to be created by this mutation. */
export type FkTaxonomyLevelTaxonomyTaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type FkTaxonomyLevelTaxonomyTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** The `entryVersion` to be created by this mutation. */
export type FkValueEntryVersionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** Input for the nested mutation of `value` in the `EntryVersionInput` mutation. */
export type FkValueInput = {
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyConnect>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyDelete>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ValueOnEntryVersionForFkValueUsingValuePkeyUpdate>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<FkValueValueCreateInput>;
};

/** Input for the nested mutation of `entryVersion` in the `ValueInput` mutation. */
export type FkValueInverseInput = {
  /** Flag indicating whether all other `entryVersion` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  connectByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexConnect>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionPkEntryVersionDelete>>;
  /** The primary key(s) for `entryVersion` for the far side of the relationship. */
  deleteByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionUqIndexDelete>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<EntryVersionOnEntryVersionForFkValueUsingPkEntryVersionUpdate>>;
  /** The primary key(s) and patch data for `entryVersion` for the far side of the relationship. */
  updateByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<Array<EntryVersionOnEntryVersionForFkValueUsingUqIndexUpdate>>;
  /** A `EntryVersionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkValueEntryVersionCreateInput>>;
};

/** The `value` to be created by this mutation. */
export type FkValueValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `WebsiteInput` mutation. */
export type FkWebsiteEnvironmentInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<FkWebsiteEnvironmentProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `website` in the `ProjectEnvironmentInput` mutation. */
export type FkWebsiteEnvironmentInverseInput = {
  /** Flag indicating whether all other `website` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  connectByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteConnect>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  deleteByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteDelete>>;
  /** The primary key(s) and patch data for `website` for the far side of the relationship. */
  updateByProjectIdAndId?: Maybe<Array<WebsiteOnWebsiteForFkWebsiteEnvironmentUsingPkWebsiteUpdate>>;
  /** A `WebsiteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkWebsiteEnvironmentWebsiteCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type FkWebsiteEnvironmentProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `website` to be created by this mutation. */
export type FkWebsiteEnvironmentWebsiteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** Input for the nested mutation of `publishTarget` in the `WebsiteInput` mutation. */
export type FkWebsiteTargetInput = {
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  connectByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetConnect>;
  /** The primary key(s) for `publishTarget` for the far side of the relationship. */
  deleteByIdAndProjectId?: Maybe<PublishTargetPkPublishTargetDelete>;
  /** The primary key(s) and patch data for `publishTarget` for the far side of the relationship. */
  updateByIdAndProjectId?: Maybe<PublishTargetOnWebsiteForFkWebsiteTargetUsingPkPublishTargetUpdate>;
  /** A `PublishTargetInput` object that will be created and connected to this object. */
  create?: Maybe<FkWebsiteTargetPublishTargetCreateInput>;
};

/** Input for the nested mutation of `website` in the `PublishTargetInput` mutation. */
export type FkWebsiteTargetInverseInput = {
  /** Flag indicating whether all other `website` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  connectByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteConnect>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  deleteByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteDelete>>;
  /** The primary key(s) and patch data for `website` for the far side of the relationship. */
  updateByProjectIdAndId?: Maybe<Array<WebsiteOnWebsiteForFkWebsiteTargetUsingPkWebsiteUpdate>>;
  /** A `WebsiteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkWebsiteTargetWebsiteCreateInput>>;
};

/** The `publishTarget` to be created by this mutation. */
export type FkWebsiteTargetPublishTargetCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** The `website` to be created by this mutation. */
export type FkWebsiteTargetWebsiteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** Input for the nested mutation of `taxonomy` in the `WebsiteInput` mutation. */
export type FkWebsiteTaxonomyInput = {
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyConnect>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyDelete>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyOnWebsiteForFkWebsiteTaxonomyUsingTaxonomyPkeyUpdate>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<FkWebsiteTaxonomyTaxonomyCreateInput>;
};

/** Input for the nested mutation of `website` in the `TaxonomyInput` mutation. */
export type FkWebsiteTaxonomyInverseInput = {
  /** Flag indicating whether all other `website` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  connectByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteConnect>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  deleteByProjectIdAndId?: Maybe<Array<WebsitePkWebsiteDelete>>;
  /** The primary key(s) and patch data for `website` for the far side of the relationship. */
  updateByProjectIdAndId?: Maybe<Array<WebsiteOnWebsiteForFkWebsiteTaxonomyUsingPkWebsiteUpdate>>;
  /** A `WebsiteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkWebsiteTaxonomyWebsiteCreateInput>>;
};

/** The `taxonomy` to be created by this mutation. */
export type FkWebsiteTaxonomyTaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** The `website` to be created by this mutation. */
export type FkWebsiteTaxonomyWebsiteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A range of `Int`. */
export type IntRange = {
  __typename?: 'IntRange';
  /** The starting bound of our range. */
  start?: Maybe<IntRangeBound>;
  /** The ending bound of our range. */
  end?: Maybe<IntRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type IntRangeBound = {
  __typename?: 'IntRangeBound';
  /** The value at one end of our range. */
  value: Scalars['Int'];
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type IntRangeBoundInput = {
  /** The value at one end of our range. */
  value: Scalars['Int'];
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
};

/** A filter to be used against IntRange fields. All fields are combined with a logical ‘and.’ */
export type IntRangeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<IntRangeInput>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<IntRangeInput>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<IntRangeInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<IntRangeInput>;
  /** Included in the specified list. */
  in?: Maybe<Array<IntRangeInput>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<IntRangeInput>>;
  /** Less than the specified value. */
  lessThan?: Maybe<IntRangeInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<IntRangeInput>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<IntRangeInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<IntRangeInput>;
  /** Contains the specified range. */
  contains?: Maybe<IntRangeInput>;
  /** Contains the specified value. */
  containsElement?: Maybe<Scalars['Int']>;
  /** Contained by the specified range. */
  containedBy?: Maybe<IntRangeInput>;
  /** Overlaps the specified range. */
  overlaps?: Maybe<IntRangeInput>;
  /** Strictly left of the specified range. */
  strictlyLeftOf?: Maybe<IntRangeInput>;
  /** Strictly right of the specified range. */
  strictlyRightOf?: Maybe<IntRangeInput>;
  /** Does not extend right of the specified range. */
  notExtendsRightOf?: Maybe<IntRangeInput>;
  /** Does not extend left of the specified range. */
  notExtendsLeftOf?: Maybe<IntRangeInput>;
  /** Adjacent to the specified range. */
  adjacentTo?: Maybe<IntRangeInput>;
};

/** A range of `Int`. */
export type IntRangeInput = {
  /** The starting bound of our range. */
  start?: Maybe<IntRangeBoundInput>;
  /** The ending bound of our range. */
  end?: Maybe<IntRangeBoundInput>;
};


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};


/** A filter to be used against KeyValueHash fields. All fields are combined with a logical ‘and.’ */
export type KeyValueHashFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['KeyValueHash']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['KeyValueHash']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['KeyValueHash']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['KeyValueHash']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['KeyValueHash']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['KeyValueHash']>>;
  /** Contains the specified KeyValueHash. */
  contains?: Maybe<Scalars['KeyValueHash']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified KeyValueHash. */
  containedBy?: Maybe<Scalars['KeyValueHash']>;
};

export type MediaFolder = {
  __typename?: 'MediaFolder';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `MediaFolder` that is related to this `MediaFolder`. */
  parent?: Maybe<MediaFolder>;
  /** Reads and enables pagination through a set of `MediaFolder`. */
  children: MediaFoldersConnection;
  /** Reads and enables pagination through a set of `MediaItem`. */
  items: MediaItemsConnection;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MediaFolderChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaFoldersOrderBy>>;
  condition?: Maybe<MediaFolderCondition>;
  filter?: Maybe<MediaFolderFilter>;
};


export type MediaFolderItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaItemsOrderBy>>;
  condition?: Maybe<MediaItemCondition>;
  filter?: Maybe<MediaItemFilter>;
};

/**
 * A condition to be used against `MediaFolder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MediaFolderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `MediaFolder` object types. All fields are combined with a logical ‘and.’ */
export type MediaFolderFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `path` field. */
  path?: Maybe<StringListFilter>;
  /** Filter by the object’s `children` relation. */
  children?: Maybe<MediaFolderToManyMediaFolderFilter>;
  /** Some related `children` exist. */
  childrenExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `items` relation. */
  items?: Maybe<MediaFolderToManyMediaItemFilter>;
  /** Some related `items` exist. */
  itemsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parent` relation. */
  parent?: Maybe<MediaFolderFilter>;
  /** A related `parent` exists. */
  parentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MediaFolderFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MediaFolderFilter>>;
  /** Negates the expression. */
  not?: Maybe<MediaFolderFilter>;
};

/** An input for mutations affecting `MediaFolder` */
export type MediaFolderInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

/** The fields on `mediaFolder` to look up the row to connect. */
export type MediaFolderMediaFolderPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `mediaFolder` to look up the row to delete. */
export type MediaFolderMediaFolderPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `mediaFolder` to look up the row to update. */
export type MediaFolderOnMediaFolderForMediaFolderParentIdFkeyUsingMediaFolderPkeyUpdate = {
  /** An object where the defined keys will be set on the `mediaFolder` being updated. */
  patch: UpdateMediaFolderOnMediaFolderForMediaFolderParentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `mediaFolder` to look up the row to update. */
export type MediaFolderOnMediaItemForMediaItemFolderIdFkeyUsingMediaFolderPkeyUpdate = {
  /** An object where the defined keys will be set on the `mediaFolder` being updated. */
  patch: UpdateMediaFolderOnMediaItemForMediaItemFolderIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `mediaFolder` in the `MediaFolderInput` mutation. */
export type MediaFolderParentIdFkeyInput = {
  /** The primary key(s) for `mediaFolder` for the far side of the relationship. */
  connectById?: Maybe<MediaFolderMediaFolderPkeyConnect>;
  /** The primary key(s) for `mediaFolder` for the far side of the relationship. */
  deleteById?: Maybe<MediaFolderMediaFolderPkeyDelete>;
  /** The primary key(s) and patch data for `mediaFolder` for the far side of the relationship. */
  updateById?: Maybe<MediaFolderOnMediaFolderForMediaFolderParentIdFkeyUsingMediaFolderPkeyUpdate>;
  /** A `MediaFolderInput` object that will be created and connected to this object. */
  create?: Maybe<MediaFolderParentIdFkeyMediaFolderCreateInput>;
};

/** The `mediaFolder` to be created by this mutation. */
export type MediaFolderParentIdFkeyMediaFolderCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

/** Represents an update to a `MediaFolder`. Fields that are set will be updated. */
export type MediaFolderPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

export type MediaFolderPath = {
  __typename?: 'MediaFolderPath';
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  pathName?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

/**
 * A condition to be used against `MediaFolderPath` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MediaFolderPathCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pathName` field. */
  pathName?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `pathId` field. */
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

/** A filter to be used against `MediaFolderPath` object types. All fields are combined with a logical ‘and.’ */
export type MediaFolderPathFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `pathName` field. */
  pathName?: Maybe<StringListFilter>;
  /** Filter by the object’s `pathId` field. */
  pathId?: Maybe<UuidListFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MediaFolderPathFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MediaFolderPathFilter>>;
  /** Negates the expression. */
  not?: Maybe<MediaFolderPathFilter>;
};

/** A connection to a list of `MediaFolderPath` values. */
export type MediaFolderPathsConnection = {
  __typename?: 'MediaFolderPathsConnection';
  /** A list of `MediaFolderPath` objects. */
  nodes: Array<Maybe<MediaFolderPath>>;
  /** A list of edges which contains the `MediaFolderPath` and cursor to aid in pagination. */
  edges: Array<MediaFolderPathsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MediaFolderPath` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MediaFolderPath` edge in the connection. */
export type MediaFolderPathsEdge = {
  __typename?: 'MediaFolderPathsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MediaFolderPath` at the end of the edge. */
  node?: Maybe<MediaFolderPath>;
};

/** Methods to use when ordering `MediaFolderPath`. */
export enum MediaFolderPathsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  PathNameAsc = 'PATH_NAME_ASC',
  PathNameDesc = 'PATH_NAME_DESC',
  PathIdAsc = 'PATH_ID_ASC',
  PathIdDesc = 'PATH_ID_DESC'
}

/** A filter to be used against many `MediaFolder` object types. All fields are combined with a logical ‘and.’ */
export type MediaFolderToManyMediaFolderFilter = {
  /** Every related `MediaFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MediaFolderFilter>;
  /** Some related `MediaFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MediaFolderFilter>;
  /** No related `MediaFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MediaFolderFilter>;
};

/** A filter to be used against many `MediaItem` object types. All fields are combined with a logical ‘and.’ */
export type MediaFolderToManyMediaItemFilter = {
  /** Every related `MediaItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MediaItemFilter>;
  /** Some related `MediaItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MediaItemFilter>;
  /** No related `MediaItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MediaItemFilter>;
};

/** A connection to a list of `MediaFolder` values. */
export type MediaFoldersConnection = {
  __typename?: 'MediaFoldersConnection';
  /** A list of `MediaFolder` objects. */
  nodes: Array<Maybe<MediaFolder>>;
  /** A list of edges which contains the `MediaFolder` and cursor to aid in pagination. */
  edges: Array<MediaFoldersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MediaFolder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MediaFolder` edge in the connection. */
export type MediaFoldersEdge = {
  __typename?: 'MediaFoldersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MediaFolder` at the end of the edge. */
  node?: Maybe<MediaFolder>;
};

/** Methods to use when ordering `MediaFolder`. */
export enum MediaFoldersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  MediaFolderByParentIdIdAsc = 'MEDIA_FOLDER_BY_PARENT_ID__ID_ASC',
  MediaFolderByParentIdIdDesc = 'MEDIA_FOLDER_BY_PARENT_ID__ID_DESC',
  MediaFolderByParentIdProjectIdAsc = 'MEDIA_FOLDER_BY_PARENT_ID__PROJECT_ID_ASC',
  MediaFolderByParentIdProjectIdDesc = 'MEDIA_FOLDER_BY_PARENT_ID__PROJECT_ID_DESC',
  MediaFolderByParentIdNameAsc = 'MEDIA_FOLDER_BY_PARENT_ID__NAME_ASC',
  MediaFolderByParentIdNameDesc = 'MEDIA_FOLDER_BY_PARENT_ID__NAME_DESC',
  MediaFolderByParentIdParentIdAsc = 'MEDIA_FOLDER_BY_PARENT_ID__PARENT_ID_ASC',
  MediaFolderByParentIdParentIdDesc = 'MEDIA_FOLDER_BY_PARENT_ID__PARENT_ID_DESC',
  MediaFolderByParentIdDeletedAtAsc = 'MEDIA_FOLDER_BY_PARENT_ID__DELETED_AT_ASC',
  MediaFolderByParentIdDeletedAtDesc = 'MEDIA_FOLDER_BY_PARENT_ID__DELETED_AT_DESC',
  MediaFoldersByParentIdCountAsc = 'MEDIA_FOLDERS_BY_PARENT_ID__COUNT_ASC',
  MediaFoldersByParentIdCountDesc = 'MEDIA_FOLDERS_BY_PARENT_ID__COUNT_DESC',
  MediaItemsByFolderIdCountAsc = 'MEDIA_ITEMS_BY_FOLDER_ID__COUNT_ASC',
  MediaItemsByFolderIdCountDesc = 'MEDIA_ITEMS_BY_FOLDER_ID__COUNT_DESC'
}

export type MediaItem = {
  __typename?: 'MediaItem';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  folderId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  contentType: Scalars['String'];
  size: Scalars['Int'];
  storage: Scalars['JSON'];
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  mediaInfo: Scalars['JSON'];
  metadata: Scalars['JSON'];
  mediaType: MediaType;
  /** Reads a single `MediaFolder` that is related to this `MediaItem`. */
  folder?: Maybe<MediaFolder>;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueValues: ValueFieldsConnection;
};


export type MediaItemValueValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};

/**
 * A condition to be used against `MediaItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MediaItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `contentType` field. */
  contentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `size` field. */
  size?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `storage` field. */
  storage?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `uploadedAt` field. */
  uploadedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `uploadedIp` field. */
  uploadedIp?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedIp` field. */
  deletedIp?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `mediaInfo` field. */
  mediaInfo?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `mediaType` field. */
  mediaType?: Maybe<MediaType>;
};

/** A filter to be used against `MediaItem` object types. All fields are combined with a logical ‘and.’ */
export type MediaItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `contentType` field. */
  contentType?: Maybe<StringFilter>;
  /** Filter by the object’s `size` field. */
  size?: Maybe<IntFilter>;
  /** Filter by the object’s `storage` field. */
  storage?: Maybe<JsonFilter>;
  /** Filter by the object’s `uploadedAt` field. */
  uploadedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `uploadedIp` field. */
  uploadedIp?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedIp` field. */
  deletedIp?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `mediaInfo` field. */
  mediaInfo?: Maybe<JsonFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: Maybe<JsonFilter>;
  /** Filter by the object’s `mediaType` field. */
  mediaType?: Maybe<MediaTypeFilter>;
  /** Filter by the object’s `valueValues` relation. */
  valueValues?: Maybe<MediaItemToManyValueFieldFilter>;
  /** Some related `valueValues` exist. */
  valueValuesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `folder` relation. */
  folder?: Maybe<MediaFolderFilter>;
  /** A related `folder` exists. */
  folderExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MediaItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MediaItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<MediaItemFilter>;
};

/** Input for the nested mutation of `mediaFolder` in the `MediaItemInput` mutation. */
export type MediaItemFolderIdFkeyInput = {
  /** The primary key(s) for `mediaFolder` for the far side of the relationship. */
  connectById?: Maybe<MediaFolderMediaFolderPkeyConnect>;
  /** The primary key(s) for `mediaFolder` for the far side of the relationship. */
  deleteById?: Maybe<MediaFolderMediaFolderPkeyDelete>;
  /** The primary key(s) and patch data for `mediaFolder` for the far side of the relationship. */
  updateById?: Maybe<MediaFolderOnMediaItemForMediaItemFolderIdFkeyUsingMediaFolderPkeyUpdate>;
  /** A `MediaFolderInput` object that will be created and connected to this object. */
  create?: Maybe<MediaItemFolderIdFkeyMediaFolderCreateInput>;
};

/** Input for the nested mutation of `mediaItem` in the `MediaFolderInput` mutation. */
export type MediaItemFolderIdFkeyInverseInput = {
  /** Flag indicating whether all other `mediaItem` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `mediaItem` for the far side of the relationship. */
  connectById?: Maybe<Array<MediaItemMediaItemPkeyConnect>>;
  /** The primary key(s) for `mediaItem` for the far side of the relationship. */
  deleteById?: Maybe<Array<MediaItemMediaItemPkeyDelete>>;
  /** The primary key(s) and patch data for `mediaItem` for the far side of the relationship. */
  updateById?: Maybe<Array<MediaItemOnMediaItemForMediaItemFolderIdFkeyUsingMediaItemPkeyUpdate>>;
  /** A `MediaItemInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MediaItemFolderIdFkeyMediaItemCreateInput>>;
};

/** The `mediaFolder` to be created by this mutation. */
export type MediaItemFolderIdFkeyMediaFolderCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

/** The `mediaItem` to be created by this mutation. */
export type MediaItemFolderIdFkeyMediaItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  folderId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  contentType: Scalars['String'];
  size: Scalars['Int'];
  storage: Scalars['JSON'];
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** An input for mutations affecting `MediaItem` */
export type MediaItemInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  folderId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  contentType: Scalars['String'];
  size: Scalars['Int'];
  storage: Scalars['JSON'];
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** The fields on `mediaItem` to look up the row to connect. */
export type MediaItemMediaItemPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `mediaItem` to look up the row to delete. */
export type MediaItemMediaItemPkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `mediaItem` to look up the row to update. */
export type MediaItemOnMediaItemForMediaItemFolderIdFkeyUsingMediaItemPkeyUpdate = {
  /** An object where the defined keys will be set on the `mediaItem` being updated. */
  patch: UpdateMediaItemOnMediaItemForMediaItemFolderIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `mediaItem` to look up the row to update. */
export type MediaItemOnValueFieldForValueFieldValueMediaIdFkeyUsingMediaItemPkeyUpdate = {
  /** An object where the defined keys will be set on the `mediaItem` being updated. */
  patch: UpdateMediaItemOnValueFieldForValueFieldValueMediaIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `MediaItem`. Fields that are set will be updated. */
export type MediaItemPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  folderId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  storage?: Maybe<Scalars['JSON']>;
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type MediaItemToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

/** A connection to a list of `MediaItem` values. */
export type MediaItemsConnection = {
  __typename?: 'MediaItemsConnection';
  /** A list of `MediaItem` objects. */
  nodes: Array<Maybe<MediaItem>>;
  /** A list of edges which contains the `MediaItem` and cursor to aid in pagination. */
  edges: Array<MediaItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MediaItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MediaItem` edge in the connection. */
export type MediaItemsEdge = {
  __typename?: 'MediaItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MediaItem` at the end of the edge. */
  node?: Maybe<MediaItem>;
};

/** Methods to use when ordering `MediaItem`. */
export enum MediaItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  FolderIdAsc = 'FOLDER_ID_ASC',
  FolderIdDesc = 'FOLDER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ContentTypeAsc = 'CONTENT_TYPE_ASC',
  ContentTypeDesc = 'CONTENT_TYPE_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  StorageAsc = 'STORAGE_ASC',
  StorageDesc = 'STORAGE_DESC',
  UploadedAtAsc = 'UPLOADED_AT_ASC',
  UploadedAtDesc = 'UPLOADED_AT_DESC',
  UploadedIpAsc = 'UPLOADED_IP_ASC',
  UploadedIpDesc = 'UPLOADED_IP_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DeletedIpAsc = 'DELETED_IP_ASC',
  DeletedIpDesc = 'DELETED_IP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  MediaInfoAsc = 'MEDIA_INFO_ASC',
  MediaInfoDesc = 'MEDIA_INFO_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  MediaTypeAsc = 'MEDIA_TYPE_ASC',
  MediaTypeDesc = 'MEDIA_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  MediaFolderByFolderIdIdAsc = 'MEDIA_FOLDER_BY_FOLDER_ID__ID_ASC',
  MediaFolderByFolderIdIdDesc = 'MEDIA_FOLDER_BY_FOLDER_ID__ID_DESC',
  MediaFolderByFolderIdProjectIdAsc = 'MEDIA_FOLDER_BY_FOLDER_ID__PROJECT_ID_ASC',
  MediaFolderByFolderIdProjectIdDesc = 'MEDIA_FOLDER_BY_FOLDER_ID__PROJECT_ID_DESC',
  MediaFolderByFolderIdNameAsc = 'MEDIA_FOLDER_BY_FOLDER_ID__NAME_ASC',
  MediaFolderByFolderIdNameDesc = 'MEDIA_FOLDER_BY_FOLDER_ID__NAME_DESC',
  MediaFolderByFolderIdParentIdAsc = 'MEDIA_FOLDER_BY_FOLDER_ID__PARENT_ID_ASC',
  MediaFolderByFolderIdParentIdDesc = 'MEDIA_FOLDER_BY_FOLDER_ID__PARENT_ID_DESC',
  MediaFolderByFolderIdDeletedAtAsc = 'MEDIA_FOLDER_BY_FOLDER_ID__DELETED_AT_ASC',
  MediaFolderByFolderIdDeletedAtDesc = 'MEDIA_FOLDER_BY_FOLDER_ID__DELETED_AT_DESC',
  ValueFieldsByValueMediaIdCountAsc = 'VALUE_FIELDS_BY_VALUE_MEDIA_ID__COUNT_ASC',
  ValueFieldsByValueMediaIdCountDesc = 'VALUE_FIELDS_BY_VALUE_MEDIA_ID__COUNT_DESC'
}

export enum MediaType {
  Unknown = 'UNKNOWN',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Document = 'DOCUMENT'
}

/** A filter to be used against MediaType fields. All fields are combined with a logical ‘and.’ */
export type MediaTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<MediaType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<MediaType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<MediaType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<MediaType>;
  /** Included in the specified list. */
  in?: Maybe<Array<MediaType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<MediaType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<MediaType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<MediaType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<MediaType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<MediaType>;
};

export type MediaUploadUrlInput = {
  project: Scalars['String'];
  mediaItem: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};

export type MediaUploadUrlOutput = {
  __typename?: 'MediaUploadUrlOutput';
  url: Scalars['String'];
  headers: Scalars['JSON'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  /** Reads a single `ProjectEnvironment` that is related to this `MenuItem`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `MenuItem` that is related to this `MenuItem`. */
  parentProjectEnvironment?: Maybe<MenuItem>;
  /** Reads and enables pagination through a set of `MenuItem`. */
  menuItemsByParentIdAndProjectIdAndEnvironmentId: MenuItemsConnection;
  /** Reads a single `MenuItemEntry` that is related to this `MenuItem`. */
  entry?: Maybe<MenuItemEntry>;
  /**
   * Reads and enables pagination through a set of `MenuItemEntry`.
   * @deprecated Please use entry instead
   */
  menuItemEntriesByIdAndProjectIdAndEnvironmentId: MenuItemEntriesConnection;
  /** Reads a single `MenuItemView` that is related to this `MenuItem`. */
  view?: Maybe<MenuItemView>;
  /**
   * Reads and enables pagination through a set of `MenuItemView`.
   * @deprecated Please use view instead
   */
  menuItemViewsByIdAndProjectIdAndEnvironmentId: MenuItemViewsConnection;
};


export type MenuItemMenuItemsByParentIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
  condition?: Maybe<MenuItemCondition>;
  filter?: Maybe<MenuItemFilter>;
};


export type MenuItemMenuItemEntriesByIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
  condition?: Maybe<MenuItemEntryCondition>;
  filter?: Maybe<MenuItemEntryFilter>;
};


export type MenuItemMenuItemViewsByIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
  condition?: Maybe<MenuItemViewCondition>;
  filter?: Maybe<MenuItemViewFilter>;
};

/**
 * A condition to be used against `MenuItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MenuItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `MenuItemEntry` values. */
export type MenuItemEntriesConnection = {
  __typename?: 'MenuItemEntriesConnection';
  /** A list of `MenuItemEntry` objects. */
  nodes: Array<Maybe<MenuItemEntry>>;
  /** A list of edges which contains the `MenuItemEntry` and cursor to aid in pagination. */
  edges: Array<MenuItemEntriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuItemEntry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MenuItemEntry` edge in the connection. */
export type MenuItemEntriesEdge = {
  __typename?: 'MenuItemEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuItemEntry` at the end of the edge. */
  node?: Maybe<MenuItemEntry>;
};

/** Methods to use when ordering `MenuItemEntry`. */
export enum MenuItemEntriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdParentIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdParentIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdLabelAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdLabelDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIndexAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIndexDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC'
}

export type MenuItemEntry = {
  __typename?: 'MenuItemEntry';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  entryId: Scalars['UUID'];
  /** Reads a single `MenuItem` that is related to this `MenuItemEntry`. */
  entry?: Maybe<MenuItem>;
  /** Reads a single `Entry` that is related to this `MenuItemEntry`. */
  entryProjectEnvironment?: Maybe<Entry>;
};

/**
 * A condition to be used against `MenuItemEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MenuItemEntryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `MenuItemEntry` object types. All fields are combined with a logical ‘and.’ */
export type MenuItemEntryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<MenuItemFilter>;
  /** Filter by the object’s `entryProjectEnvironment` relation. */
  entryProjectEnvironment?: Maybe<EntryFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MenuItemEntryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MenuItemEntryFilter>>;
  /** Negates the expression. */
  not?: Maybe<MenuItemEntryFilter>;
};

/** An input for mutations affecting `MenuItemEntry` */
export type MenuItemEntryInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** The fields on `menuItemEntry` to look up the row to update. */
export type MenuItemEntryOnMenuItemEntryForFkMenuItemEntryEntryUsingPkMenuItemEntryUpdate = {
  /** An object where the defined keys will be set on the `menuItemEntry` being updated. */
  patch: UpdateMenuItemEntryOnMenuItemEntryForFkMenuItemEntryEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItemEntry` to look up the row to update. */
export type MenuItemEntryOnMenuItemEntryForFkMenuItemEntryMenuItemUsingPkMenuItemEntryUpdate = {
  /** An object where the defined keys will be set on the `menuItemEntry` being updated. */
  patch: UpdateMenuItemEntryOnMenuItemEntryForFkMenuItemEntryMenuItemPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `MenuItemEntry`. Fields that are set will be updated. */
export type MenuItemEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** The fields on `menuItemEntry` to look up the row to connect. */
export type MenuItemEntryPkMenuItemEntryConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItemEntry` to look up the row to delete. */
export type MenuItemEntryPkMenuItemEntryDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against `MenuItem` object types. All fields are combined with a logical ‘and.’ */
export type MenuItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `menuItemsByParentIdAndProjectIdAndEnvironmentId` relation. */
  menuItemsByParentIdAndProjectIdAndEnvironmentId?: Maybe<MenuItemToManyMenuItemFilter>;
  /** Some related `menuItemsByParentIdAndProjectIdAndEnvironmentId` exist. */
  menuItemsByParentIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<MenuItemEntryFilter>;
  /** A related `entry` exists. */
  entryExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `view` relation. */
  view?: Maybe<MenuItemViewFilter>;
  /** A related `view` exists. */
  viewExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `parentProjectEnvironment` relation. */
  parentProjectEnvironment?: Maybe<MenuItemFilter>;
  /** A related `parentProjectEnvironment` exists. */
  parentProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MenuItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MenuItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<MenuItemFilter>;
};

/** An input for mutations affecting `MenuItem` */
export type MenuItemInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** The fields on `menuItem` to look up the row to update. */
export type MenuItemOnMenuItemEntryForFkMenuItemEntryMenuItemUsingPkMenuItemUpdate = {
  /** An object where the defined keys will be set on the `menuItem` being updated. */
  patch: UpdateMenuItemOnMenuItemEntryForFkMenuItemEntryMenuItemPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItem` to look up the row to update. */
export type MenuItemOnMenuItemForFkMenuItemEnvironmentUsingPkMenuItemUpdate = {
  /** An object where the defined keys will be set on the `menuItem` being updated. */
  patch: UpdateMenuItemOnMenuItemForFkMenuItemEnvironmentPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItem` to look up the row to update. */
export type MenuItemOnMenuItemForFkMenuItemParentUsingPkMenuItemUpdate = {
  /** An object where the defined keys will be set on the `menuItem` being updated. */
  patch: UpdateMenuItemOnMenuItemForFkMenuItemParentPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItem` to look up the row to update. */
export type MenuItemOnMenuItemViewForFkMenuItemViewMenuItemUsingPkMenuItemUpdate = {
  /** An object where the defined keys will be set on the `menuItem` being updated. */
  patch: UpdateMenuItemOnMenuItemViewForFkMenuItemViewMenuItemPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `MenuItem`. Fields that are set will be updated. */
export type MenuItemPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** The fields on `menuItem` to look up the row to connect. */
export type MenuItemPkMenuItemConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItem` to look up the row to delete. */
export type MenuItemPkMenuItemDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against many `MenuItem` object types. All fields are combined with a logical ‘and.’ */
export type MenuItemToManyMenuItemFilter = {
  /** Every related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuItemFilter>;
  /** Some related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuItemFilter>;
  /** No related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuItemFilter>;
};

export type MenuItemView = {
  __typename?: 'MenuItemView';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  modelId: Scalars['UUID'];
  /** Reads a single `MenuItem` that is related to this `MenuItemView`. */
  view?: Maybe<MenuItem>;
  /** Reads a single `Model` that is related to this `MenuItemView`. */
  modelProjectEnvironment?: Maybe<Model>;
};

/**
 * A condition to be used against `MenuItemView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MenuItemViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `MenuItemView` object types. All fields are combined with a logical ‘and.’ */
export type MenuItemViewFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `view` relation. */
  view?: Maybe<MenuItemFilter>;
  /** Filter by the object’s `modelProjectEnvironment` relation. */
  modelProjectEnvironment?: Maybe<ModelFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MenuItemViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MenuItemViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<MenuItemViewFilter>;
};

/** An input for mutations affecting `MenuItemView` */
export type MenuItemViewInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** The fields on `menuItemView` to look up the row to update. */
export type MenuItemViewOnMenuItemViewForFkMenuItemViewMenuItemUsingPkMenuItemViewUpdate = {
  /** An object where the defined keys will be set on the `menuItemView` being updated. */
  patch: UpdateMenuItemViewOnMenuItemViewForFkMenuItemViewMenuItemPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItemView` to look up the row to update. */
export type MenuItemViewOnMenuItemViewForFkMenuItemViewModelUsingPkMenuItemViewUpdate = {
  /** An object where the defined keys will be set on the `menuItemView` being updated. */
  patch: UpdateMenuItemViewOnMenuItemViewForFkMenuItemViewModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `MenuItemView`. Fields that are set will be updated. */
export type MenuItemViewPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** The fields on `menuItemView` to look up the row to connect. */
export type MenuItemViewPkMenuItemViewConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `menuItemView` to look up the row to delete. */
export type MenuItemViewPkMenuItemViewDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A connection to a list of `MenuItemView` values. */
export type MenuItemViewsConnection = {
  __typename?: 'MenuItemViewsConnection';
  /** A list of `MenuItemView` objects. */
  nodes: Array<Maybe<MenuItemView>>;
  /** A list of edges which contains the `MenuItemView` and cursor to aid in pagination. */
  edges: Array<MenuItemViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuItemView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MenuItemView` edge in the connection. */
export type MenuItemViewsEdge = {
  __typename?: 'MenuItemViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuItemView` at the end of the edge. */
  node?: Maybe<MenuItemView>;
};

/** Methods to use when ordering `MenuItemView`. */
export enum MenuItemViewsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdParentIdAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdParentIdDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdLabelAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdLabelDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIndexAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdIndexDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  MenuItemByIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  MenuItemByIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MENU_ITEM_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC'
}

/** A connection to a list of `MenuItem` values. */
export type MenuItemsConnection = {
  __typename?: 'MenuItemsConnection';
  /** A list of `MenuItem` objects. */
  nodes: Array<Maybe<MenuItem>>;
  /** A list of edges which contains the `MenuItem` and cursor to aid in pagination. */
  edges: Array<MenuItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MenuItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MenuItem` edge in the connection. */
export type MenuItemsEdge = {
  __typename?: 'MenuItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MenuItem` at the end of the edge. */
  node?: Maybe<MenuItem>;
};

/** Methods to use when ordering `MenuItem`. */
export enum MenuItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdParentIdAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdParentIdDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdLabelAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdLabelDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LABEL_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdIndexAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdIndexDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  MenuItemByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MENU_ITEM_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  MenuItemsByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'MENU_ITEMS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  MenuItemsByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'MENU_ITEMS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdIdAsc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdIdDesc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  MenuItemEntryByIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'MENU_ITEM_ENTRY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdIdAsc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdIdDesc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  MenuItemViewByIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MENU_ITEM_VIEW_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC'
}

export type Model = {
  __typename?: 'Model';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits: Array<Maybe<Scalars['UUID']>>;
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId: Scalars['UUID'];
  usage: ModelUsage;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  /** Reads a single `Value` that is related to this `Model`. */
  valueBase?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `Model`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `TaxonomyLevel` that is related to this `Model`. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** Reads and enables pagination through a set of `Entry`. */
  entries: EntriesConnection;
  /** Reads and enables pagination through a set of `ModelField`. */
  fields: ModelFieldsConnection;
  /** Reads and enables pagination through a set of `ModelParent`. */
  parents: ModelParentsConnection;
  /** Reads and enables pagination through a set of `ModelParent`. */
  children: ModelParentsConnection;
  /** Reads and enables pagination through a set of `Value`. */
  values: ValuesConnection;
  /** Reads and enables pagination through a set of `MenuItemView`. */
  menuItemViewsByModelIdAndProjectIdAndEnvironmentId: MenuItemViewsConnection;
  /** Reads and enables pagination through a set of `ModelFieldGroup`. */
  groups: ModelFieldGroupsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByModelIdAndProjectIdAndEnvironmentId: TaxonomyLevelsConnection;
  /** Reads and enables pagination through a set of `ModelFieldsInherited`. */
  fieldsAllBySourceModel: ModelFieldsInheritedsConnection;
  /** Reads and enables pagination through a set of `ModelFieldsInherited`. */
  fieldsAll: ModelFieldsInheritedsConnection;
  /** Reads and enables pagination through a set of `ModelParentsRecursive`. */
  parentsAll: ModelParentsRecursivesConnection;
  /** Reads and enables pagination through a set of `ModelParentsRecursive`. */
  parentsAllByParent: ModelParentsRecursivesConnection;
  /** Reads and enables pagination through a set of `TaxonomyCombined`. */
  taxonomyCombinedByModel: TaxonomyCombinedsConnection;
  /** Reads and enables pagination through a set of `TaxonomyDynamic`. */
  taxonomyDynamics: TaxonomyDynamicsConnection;
};


export type ModelEntriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


export type ModelFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
  condition?: Maybe<ModelFieldCondition>;
  filter?: Maybe<ModelFieldFilter>;
};


export type ModelParentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
  condition?: Maybe<ModelParentCondition>;
  filter?: Maybe<ModelParentFilter>;
};


export type ModelChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
  condition?: Maybe<ModelParentCondition>;
  filter?: Maybe<ModelParentFilter>;
};


export type ModelValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValuesOrderBy>>;
  condition?: Maybe<ValueCondition>;
  filter?: Maybe<ValueFilter>;
};


export type ModelMenuItemViewsByModelIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
  condition?: Maybe<MenuItemViewCondition>;
  filter?: Maybe<MenuItemViewFilter>;
};


export type ModelGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
  condition?: Maybe<ModelFieldGroupCondition>;
  filter?: Maybe<ModelFieldGroupFilter>;
};


export type ModelTaxonomyLevelsByModelIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


export type ModelFieldsAllBySourceModelArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsInheritedsOrderBy>>;
  condition?: Maybe<ModelFieldsInheritedCondition>;
  filter?: Maybe<ModelFieldsInheritedFilter>;
};


export type ModelFieldsAllArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsInheritedsOrderBy>>;
  condition?: Maybe<ModelFieldsInheritedCondition>;
  filter?: Maybe<ModelFieldsInheritedFilter>;
};


export type ModelParentsAllArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsRecursivesOrderBy>>;
  condition?: Maybe<ModelParentsRecursiveCondition>;
  filter?: Maybe<ModelParentsRecursiveFilter>;
};


export type ModelParentsAllByParentArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsRecursivesOrderBy>>;
  condition?: Maybe<ModelParentsRecursiveCondition>;
  filter?: Maybe<ModelParentsRecursiveFilter>;
};


export type ModelTaxonomyCombinedByModelArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyCombinedsOrderBy>>;
  condition?: Maybe<TaxonomyCombinedCondition>;
  filter?: Maybe<TaxonomyCombinedFilter>;
};


export type ModelTaxonomyDynamicsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyDynamicsOrderBy>>;
  condition?: Maybe<TaxonomyDynamicCondition>;
  filter?: Maybe<TaxonomyDynamicFilter>;
};

/** A condition to be used against `Model` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ModelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `inherits` field. */
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `valueBaseId` field. */
  valueBaseId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `usage` field. */
  usage?: Maybe<ModelUsage>;
  /** Checks for equality with the object’s `defaultTaxonomyLevelId` field. */
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `valueBaseSnapshot` field. */
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ModelInput` mutation. */
export type ModelEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnModelForModelEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnModelForModelEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ModelEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `model` in the `ProjectEnvironmentInput` mutation. */
export type ModelEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `model` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyConnect>>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyDelete>>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelOnModelForModelEnvironmentIdFkeyUsingModelPkeyUpdate>>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelEnvironmentIdFkeyModelCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ModelEnvironmentIdFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ModelEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

export type ModelField = {
  __typename?: 'ModelField';
  id: Scalars['UUID'];
  modelId: Scalars['UUID'];
  name: Scalars['String'];
  alias: Scalars['String'];
  config: Scalars['JSON'];
  projectId: Scalars['UUID'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  environmentId: Scalars['UUID'];
  type: ModelFieldType;
  groupId?: Maybe<Scalars['UUID']>;
  multi: Scalars['Boolean'];
  required: Scalars['Boolean'];
  valueCount?: Maybe<IntRange>;
  index: Scalars['Int'];
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Model` that is related to this `ModelField`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `ModelFieldGroup` that is related to this `ModelField`. */
  group?: Maybe<ModelFieldGroup>;
  /** Reads a single `ModelField` that is related to this `ModelField`. */
  autoFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads and enables pagination through a set of `ModelField`. */
  modelFieldsByAutoFieldIdAndProjectIdAndEnvironmentId: ModelFieldsConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueFields: ValueFieldsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentId: TaxonomyLevelsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentId: TaxonomyLevelsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentId: TaxonomyLevelsConnection;
};


export type ModelFieldModelFieldsByAutoFieldIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
  condition?: Maybe<ModelFieldCondition>;
  filter?: Maybe<ModelFieldFilter>;
};


export type ModelFieldValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


export type ModelFieldTaxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


export type ModelFieldTaxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


export type ModelFieldTaxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};

/**
 * A condition to be used against `ModelField` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ModelFieldCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<ModelFieldType>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `multi` field. */
  multi?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `required` field. */
  required?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `valueCount` field. */
  valueCount?: Maybe<IntRangeInput>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `autoSource` field. */
  autoSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoOverride` field. */
  autoOverride?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `autoTransform` field. */
  autoTransform?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoFieldId` field. */
  autoFieldId?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ModelFieldInput` mutation. */
export type ModelFieldEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ModelFieldEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `modelField` in the `ProjectEnvironmentInput` mutation. */
export type ModelFieldEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `modelField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyConnect>>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldOnModelFieldForModelFieldEnvironmentIdFkeyUsingModelFieldPkeyUpdate>>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelFieldEnvironmentIdFkeyModelFieldCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type ModelFieldEnvironmentIdFkeyModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ModelFieldEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** A filter to be used against `ModelField` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<ModelFieldTypeFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<UuidFilter>;
  /** Filter by the object’s `multi` field. */
  multi?: Maybe<BooleanFilter>;
  /** Filter by the object’s `required` field. */
  required?: Maybe<BooleanFilter>;
  /** Filter by the object’s `valueCount` field. */
  valueCount?: Maybe<IntRangeFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `autoSource` field. */
  autoSource?: Maybe<StringFilter>;
  /** Filter by the object’s `autoOverride` field. */
  autoOverride?: Maybe<BooleanFilter>;
  /** Filter by the object’s `autoTransform` field. */
  autoTransform?: Maybe<StringFilter>;
  /** Filter by the object’s `autoFieldId` field. */
  autoFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelFieldsByAutoFieldIdAndProjectIdAndEnvironmentId` relation. */
  modelFieldsByAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldToManyModelFieldFilter>;
  /** Some related `modelFieldsByAutoFieldIdAndProjectIdAndEnvironmentId` exist. */
  modelFieldsByAutoFieldIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueFields` relation. */
  valueFields?: Maybe<ModelFieldToManyValueFieldFilter>;
  /** Some related `valueFields` exist. */
  valueFieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<ModelFieldGroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `autoFieldProjectEnvironment` relation. */
  autoFieldProjectEnvironment?: Maybe<ModelFieldFilter>;
  /** A related `autoFieldProjectEnvironment` exists. */
  autoFieldProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelFieldFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelFieldFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelFieldFilter>;
};

export type ModelFieldGroup = {
  __typename?: 'ModelFieldGroup';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  modelId: Scalars['UUID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  /** Reads a single `ProjectEnvironment` that is related to this `ModelFieldGroup`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `ModelFieldGroup`. */
  model?: Maybe<Model>;
  /** Reads and enables pagination through a set of `ModelField`. */
  fields: ModelFieldsConnection;
};


export type ModelFieldGroupFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
  condition?: Maybe<ModelFieldCondition>;
  filter?: Maybe<ModelFieldFilter>;
};

/**
 * A condition to be used against `ModelFieldGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ModelFieldGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ModelFieldGroupInput` mutation. */
export type ModelFieldGroupEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ModelFieldGroupEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `modelFieldGroup` in the `ProjectEnvironmentInput` mutation. */
export type ModelFieldGroupEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `modelFieldGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupPkModelFieldGroupConnect>>;
  /** The primary key(s) for `modelFieldGroup` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupPkModelFieldGroupDelete>>;
  /** The primary key(s) and patch data for `modelFieldGroup` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldGroupOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingPkModelFieldGroupUpdate>>;
  /** A `ModelFieldGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelFieldGroupEnvironmentIdFkeyModelFieldGroupCreateInput>>;
};

/** The `modelFieldGroup` to be created by this mutation. */
export type ModelFieldGroupEnvironmentIdFkeyModelFieldGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ModelFieldGroupEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** A filter to be used against `ModelFieldGroup` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldGroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<ModelFieldGroupToManyModelFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelFieldGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelFieldGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelFieldGroupFilter>;
};

/** An input for mutations affecting `ModelFieldGroup` */
export type ModelFieldGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** The fields on `modelFieldGroup` to look up the row to update. */
export type ModelFieldGroupOnModelFieldForFkFieldGroupUsingPkModelFieldGroupUpdate = {
  /** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
  patch: UpdateModelFieldGroupOnModelFieldForFkFieldGroupPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelFieldGroup` to look up the row to update. */
export type ModelFieldGroupOnModelFieldGroupForFkModelUsingPkModelFieldGroupUpdate = {
  /** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
  patch: UpdateModelFieldGroupOnModelFieldGroupForFkModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelFieldGroup` to look up the row to update. */
export type ModelFieldGroupOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingPkModelFieldGroupUpdate = {
  /** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
  patch: UpdateModelFieldGroupOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `ModelFieldGroup`. Fields that are set will be updated. */
export type ModelFieldGroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** The fields on `modelFieldGroup` to look up the row to connect. */
export type ModelFieldGroupPkModelFieldGroupConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelFieldGroup` to look up the row to delete. */
export type ModelFieldGroupPkModelFieldGroupDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against many `ModelField` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldGroupToManyModelFieldFilter = {
  /** Every related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldFilter>;
  /** Some related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldFilter>;
  /** No related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldFilter>;
};

/** A connection to a list of `ModelFieldGroup` values. */
export type ModelFieldGroupsConnection = {
  __typename?: 'ModelFieldGroupsConnection';
  /** A list of `ModelFieldGroup` objects. */
  nodes: Array<Maybe<ModelFieldGroup>>;
  /** A list of edges which contains the `ModelFieldGroup` and cursor to aid in pagination. */
  edges: Array<ModelFieldGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModelFieldGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModelFieldGroup` edge in the connection. */
export type ModelFieldGroupsEdge = {
  __typename?: 'ModelFieldGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModelFieldGroup` at the end of the edge. */
  node?: Maybe<ModelFieldGroup>;
};

/** Methods to use when ordering `ModelFieldGroup`. */
export enum ModelFieldGroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ModelFieldsByGroupIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELDS_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsByGroupIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELDS_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

/** An input for mutations affecting `ModelField` */
export type ModelFieldInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The fields on `modelField` to look up the row to connect. */
export type ModelFieldModelFieldPkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to delete. */
export type ModelFieldModelFieldPkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Input for the nested mutation of `model` in the `ModelFieldInput` mutation. */
export type ModelFieldModelFkeyInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelFieldForModelFieldModelFkeyUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<ModelFieldModelFkeyModelCreateInput>;
};

/** Input for the nested mutation of `modelField` in the `ModelInput` mutation. */
export type ModelFieldModelFkeyInverseInput = {
  /** Flag indicating whether all other `modelField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyConnect>>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldModelFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelFieldOnModelFieldForModelFieldModelFkeyUsingModelFieldPkeyUpdate>>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelFieldModelFkeyModelFieldCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ModelFieldModelFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelField` to be created by this mutation. */
export type ModelFieldModelFkeyModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnModelFieldForFkFieldGroupUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnModelFieldForFkFieldGroupPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnModelFieldForFkModelFieldAutoFieldUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnModelFieldForFkModelFieldAutoFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnModelFieldForModelFieldEnvironmentIdFkeyUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnModelFieldForModelFieldEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnModelFieldForModelFieldModelFkeyUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnModelFieldForModelFieldModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnTaxonomyLevelForFkTaxonomyLevelAliasFieldUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelAliasFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelField` to look up the row to update. */
export type ModelFieldOnValueFieldForValueFieldModelFieldFkeyUsingModelFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelField` being updated. */
  patch: UpdateModelFieldOnValueFieldForValueFieldModelFieldFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `ModelField`. Fields that are set will be updated. */
export type ModelFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** A filter to be used against many `ModelField` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldToManyModelFieldFilter = {
  /** Every related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldFilter>;
  /** Some related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldFilter>;
  /** No related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldFilter>;
};

/** A filter to be used against many `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldToManyTaxonomyLevelFilter = {
  /** Every related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyLevelFilter>;
  /** Some related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyLevelFilter>;
  /** No related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyLevelFilter>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

export enum ModelFieldType {
  Scalar = 'SCALAR',
  Media = 'MEDIA',
  Entry = 'ENTRY',
  Value = 'VALUE'
}

/** A filter to be used against ModelFieldType fields. All fields are combined with a logical ‘and.’ */
export type ModelFieldTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ModelFieldType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ModelFieldType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ModelFieldType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ModelFieldType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ModelFieldType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ModelFieldType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ModelFieldType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ModelFieldType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ModelFieldType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ModelFieldType>;
};

/** A connection to a list of `ModelField` values. */
export type ModelFieldsConnection = {
  __typename?: 'ModelFieldsConnection';
  /** A list of `ModelField` objects. */
  nodes: Array<Maybe<ModelField>>;
  /** A list of edges which contains the `ModelField` and cursor to aid in pagination. */
  edges: Array<ModelFieldsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModelField` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModelField` edge in the connection. */
export type ModelFieldsEdge = {
  __typename?: 'ModelFieldsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModelField` at the end of the edge. */
  node?: Maybe<ModelField>;
};

export type ModelFieldsInherited = {
  __typename?: 'ModelFieldsInherited';
  modelId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  sourceModelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  multi?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRange>;
  index?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Model` that is related to this `ModelFieldsInherited`. */
  sourceModel?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelFieldsInherited`. */
  model?: Maybe<Model>;
};

/**
 * A condition to be used against `ModelFieldsInherited` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ModelFieldsInheritedCondition = {
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `sourceModelId` field. */
  sourceModelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `multi` field. */
  multi?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `valueCount` field. */
  valueCount?: Maybe<IntRangeInput>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `required` field. */
  required?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `autoSource` field. */
  autoSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoOverride` field. */
  autoOverride?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `autoTransform` field. */
  autoTransform?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `autoFieldId` field. */
  autoFieldId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `ModelFieldsInherited` object types. All fields are combined with a logical ‘and.’ */
export type ModelFieldsInheritedFilter = {
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `sourceModelId` field. */
  sourceModelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `multi` field. */
  multi?: Maybe<BooleanFilter>;
  /** Filter by the object’s `valueCount` field. */
  valueCount?: Maybe<IntRangeFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `required` field. */
  required?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `autoSource` field. */
  autoSource?: Maybe<StringFilter>;
  /** Filter by the object’s `autoOverride` field. */
  autoOverride?: Maybe<BooleanFilter>;
  /** Filter by the object’s `autoTransform` field. */
  autoTransform?: Maybe<StringFilter>;
  /** Filter by the object’s `autoFieldId` field. */
  autoFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `sourceModel` relation. */
  sourceModel?: Maybe<ModelFilter>;
  /** A related `sourceModel` exists. */
  sourceModelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** A related `model` exists. */
  modelExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelFieldsInheritedFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelFieldsInheritedFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelFieldsInheritedFilter>;
};

/** A connection to a list of `ModelFieldsInherited` values. */
export type ModelFieldsInheritedsConnection = {
  __typename?: 'ModelFieldsInheritedsConnection';
  /** A list of `ModelFieldsInherited` objects. */
  nodes: Array<Maybe<ModelFieldsInherited>>;
  /** A list of edges which contains the `ModelFieldsInherited` and cursor to aid in pagination. */
  edges: Array<ModelFieldsInheritedsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModelFieldsInherited` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModelFieldsInherited` edge in the connection. */
export type ModelFieldsInheritedsEdge = {
  __typename?: 'ModelFieldsInheritedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModelFieldsInherited` at the end of the edge. */
  node?: Maybe<ModelFieldsInherited>;
};

/** Methods to use when ordering `ModelFieldsInherited`. */
export enum ModelFieldsInheritedsOrderBy {
  Natural = 'NATURAL',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SourceModelIdAsc = 'SOURCE_MODEL_ID_ASC',
  SourceModelIdDesc = 'SOURCE_MODEL_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  MultiAsc = 'MULTI_ASC',
  MultiDesc = 'MULTI_DESC',
  ValueCountAsc = 'VALUE_COUNT_ASC',
  ValueCountDesc = 'VALUE_COUNT_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  RequiredAsc = 'REQUIRED_ASC',
  RequiredDesc = 'REQUIRED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  AutoSourceAsc = 'AUTO_SOURCE_ASC',
  AutoSourceDesc = 'AUTO_SOURCE_DESC',
  AutoOverrideAsc = 'AUTO_OVERRIDE_ASC',
  AutoOverrideDesc = 'AUTO_OVERRIDE_DESC',
  AutoTransformAsc = 'AUTO_TRANSFORM_ASC',
  AutoTransformDesc = 'AUTO_TRANSFORM_DESC',
  AutoFieldIdAsc = 'AUTO_FIELD_ID_ASC',
  AutoFieldIdDesc = 'AUTO_FIELD_ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelBySourceModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC'
}

/** Methods to use when ordering `ModelField`. */
export enum ModelFieldsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  MultiAsc = 'MULTI_ASC',
  MultiDesc = 'MULTI_DESC',
  RequiredAsc = 'REQUIRED_ASC',
  RequiredDesc = 'REQUIRED_DESC',
  ValueCountAsc = 'VALUE_COUNT_ASC',
  ValueCountDesc = 'VALUE_COUNT_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  AutoSourceAsc = 'AUTO_SOURCE_ASC',
  AutoSourceDesc = 'AUTO_SOURCE_DESC',
  AutoOverrideAsc = 'AUTO_OVERRIDE_ASC',
  AutoOverrideDesc = 'AUTO_OVERRIDE_DESC',
  AutoTransformAsc = 'AUTO_TRANSFORM_ASC',
  AutoTransformDesc = 'AUTO_TRANSFORM_DESC',
  AutoFieldIdAsc = 'AUTO_FIELD_ID_ASC',
  AutoFieldIdDesc = 'AUTO_FIELD_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldGroupByGroupIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_GROUP_BY_GROUP_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdConfigAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdConfigDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdTypeAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdTypeDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdGroupIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdGroupIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdMultiAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdMultiDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdRequiredAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdRequiredDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdValueCountAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdValueCountDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoSourceAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoSourceDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoOverrideAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoOverrideDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoTransformAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoTransformDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_DESC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdAsc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_ASC',
  ModelFieldByAutoFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdDesc = 'MODEL_FIELD_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_DESC',
  ModelFieldsByAutoFieldIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELDS_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsByAutoFieldIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELDS_BY_AUTO_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ValueFieldsByModelFieldIdAndProjectIdAndEnvironmentIdCountAsc = 'VALUE_FIELDS_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ValueFieldsByModelFieldIdAndProjectIdAndEnvironmentIdCountDesc = 'VALUE_FIELDS_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByAliasFieldIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByFragmentFieldIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByParentReferenceFieldIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

/** A filter to be used against `Model` object types. All fields are combined with a logical ‘and.’ */
export type ModelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `inherits` field. */
  inherits?: Maybe<UuidListFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `valueBaseId` field. */
  valueBaseId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `usage` field. */
  usage?: Maybe<ModelUsageFilter>;
  /** Filter by the object’s `defaultTaxonomyLevelId` field. */
  defaultTaxonomyLevelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `valueBaseSnapshot` field. */
  valueBaseSnapshot?: Maybe<JsonFilter>;
  /** Filter by the object’s `entries` relation. */
  entries?: Maybe<ModelToManyEntryFilter>;
  /** Some related `entries` exist. */
  entriesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<ModelToManyModelFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parents` relation. */
  parents?: Maybe<ModelToManyModelParentFilter>;
  /** Some related `parents` exist. */
  parentsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `children` relation. */
  children?: Maybe<ModelToManyModelParentFilter>;
  /** Some related `children` exist. */
  childrenExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `values` relation. */
  values?: Maybe<ModelToManyValueFilter>;
  /** Some related `values` exist. */
  valuesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `menuItemViewsByModelIdAndProjectIdAndEnvironmentId` relation. */
  menuItemViewsByModelIdAndProjectIdAndEnvironmentId?: Maybe<ModelToManyMenuItemViewFilter>;
  /** Some related `menuItemViewsByModelIdAndProjectIdAndEnvironmentId` exist. */
  menuItemViewsByModelIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `groups` relation. */
  groups?: Maybe<ModelToManyModelFieldGroupFilter>;
  /** Some related `groups` exist. */
  groupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByModelIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyLevelsByModelIdAndProjectIdAndEnvironmentId?: Maybe<ModelToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByModelIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyLevelsByModelIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldsAllBySourceModel` relation. */
  fieldsAllBySourceModel?: Maybe<ModelToManyModelFieldsInheritedFilter>;
  /** Some related `fieldsAllBySourceModel` exist. */
  fieldsAllBySourceModelExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldsAll` relation. */
  fieldsAll?: Maybe<ModelToManyModelFieldsInheritedFilter>;
  /** Some related `fieldsAll` exist. */
  fieldsAllExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentsAll` relation. */
  parentsAll?: Maybe<ModelToManyModelParentsRecursiveFilter>;
  /** Some related `parentsAll` exist. */
  parentsAllExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentsAllByParent` relation. */
  parentsAllByParent?: Maybe<ModelToManyModelParentsRecursiveFilter>;
  /** Some related `parentsAllByParent` exist. */
  parentsAllByParentExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyCombinedByModel` relation. */
  taxonomyCombinedByModel?: Maybe<ModelToManyTaxonomyCombinedFilter>;
  /** Some related `taxonomyCombinedByModel` exist. */
  taxonomyCombinedByModelExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyDynamics` relation. */
  taxonomyDynamics?: Maybe<ModelToManyTaxonomyDynamicFilter>;
  /** Some related `taxonomyDynamics` exist. */
  taxonomyDynamicsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueBase` relation. */
  valueBase?: Maybe<ValueFilter>;
  /** A related `valueBase` exists. */
  valueBaseExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `defaultTaxonomyLevelProjectEnvironment` relation. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevelFilter>;
  /** A related `defaultTaxonomyLevelProjectEnvironment` exists. */
  defaultTaxonomyLevelProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelFilter>;
};

/** An input for mutations affecting `Model` */
export type ModelInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The fields on `model` to look up the row to connect. */
export type ModelModelPkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to delete. */
export type ModelModelPkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnEntryForEntryModelFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnEntryForEntryModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnMenuItemViewForFkMenuItemViewModelUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnMenuItemViewForFkMenuItemViewModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelFieldForModelFieldModelFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelFieldForModelFieldModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelFieldGroupForFkModelUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelFieldGroupForFkModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey0UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey1UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelForFkModelDefaultTaxonomyLevelUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelForFkModelDefaultTaxonomyLevelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelForModelEnvironmentIdFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelForModelEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelForModelValueFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelForModelValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelParentForModelParentModelFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelParentForModelParentModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelParentForModelParentParentFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelParentForModelParentParentFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey0UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey1UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey1UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey1UsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey1Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnTaxonomyLevelForFkTaxonomyLevelModelUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnTaxonomyLevelForFkTaxonomyLevelModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `model` to look up the row to update. */
export type ModelOnValueForValueModelFkeyUsingModelPkeyUpdate = {
  /** An object where the defined keys will be set on the `model` being updated. */
  patch: UpdateModelOnValueForValueModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

export type ModelParent = {
  __typename?: 'ModelParent';
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  /** Reads a single `Model` that is related to this `ModelParent`. */
  model?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  parent?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelParent`. */
  environment?: Maybe<ProjectEnvironment>;
};

/**
 * A condition to be used against `ModelParent` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ModelParentCondition = {
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ModelParentInput` mutation. */
export type ModelParentEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ModelParentEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `modelParent` in the `ProjectEnvironmentInput` mutation. */
export type ModelParentEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `modelParent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  connectByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyConnect>>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  deleteByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyDelete>>;
  /** The primary key(s) and patch data for `modelParent` for the far side of the relationship. */
  updateByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentOnModelParentForModelParentEnvironmentIdFkeyUsingModelParentPkeyUpdate>>;
  /** A `ModelParentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelParentEnvironmentIdFkeyModelParentCreateInput>>;
};

/** The `modelParent` to be created by this mutation. */
export type ModelParentEnvironmentIdFkeyModelParentCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ModelParentEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** A filter to be used against `ModelParent` object types. All fields are combined with a logical ‘and.’ */
export type ModelParentFilter = {
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** Filter by the object’s `parent` relation. */
  parent?: Maybe<ModelFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelParentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelParentFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelParentFilter>;
};

/** An input for mutations affecting `ModelParent` */
export type ModelParentInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** Input for the nested mutation of `model` in the `ModelParentInput` mutation. */
export type ModelParentModelFkeyInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelParentForModelParentModelFkeyUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<ModelParentModelFkeyModelCreateInput>;
};

/** Input for the nested mutation of `modelParent` in the `ModelInput` mutation. */
export type ModelParentModelFkeyInverseInput = {
  /** Flag indicating whether all other `modelParent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  connectByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyConnect>>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  deleteByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyDelete>>;
  /** The primary key(s) and patch data for `modelParent` for the far side of the relationship. */
  updateByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentOnModelParentForModelParentModelFkeyUsingModelParentPkeyUpdate>>;
  /** A `ModelParentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelParentModelFkeyModelParentCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ModelParentModelFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelParent` to be created by this mutation. */
export type ModelParentModelFkeyModelParentCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** The fields on `modelParent` to look up the row to connect. */
export type ModelParentModelParentPkeyConnect = {
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelParent` to look up the row to delete. */
export type ModelParentModelParentPkeyDelete = {
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelParent` to look up the row to update. */
export type ModelParentOnModelParentForModelParentEnvironmentIdFkeyUsingModelParentPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelParent` being updated. */
  patch: UpdateModelParentOnModelParentForModelParentEnvironmentIdFkeyPatch;
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelParent` to look up the row to update. */
export type ModelParentOnModelParentForModelParentModelFkeyUsingModelParentPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelParent` being updated. */
  patch: UpdateModelParentOnModelParentForModelParentModelFkeyPatch;
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `modelParent` to look up the row to update. */
export type ModelParentOnModelParentForModelParentParentFkeyUsingModelParentPkeyUpdate = {
  /** An object where the defined keys will be set on the `modelParent` being updated. */
  patch: UpdateModelParentOnModelParentForModelParentParentFkeyPatch;
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Input for the nested mutation of `model` in the `ModelParentInput` mutation. */
export type ModelParentParentFkeyInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnModelParentForModelParentParentFkeyUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<ModelParentParentFkeyModelCreateInput>;
};

/** Input for the nested mutation of `modelParent` in the `ModelInput` mutation. */
export type ModelParentParentFkeyInverseInput = {
  /** Flag indicating whether all other `modelParent` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  connectByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyConnect>>;
  /** The primary key(s) for `modelParent` for the far side of the relationship. */
  deleteByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentModelParentPkeyDelete>>;
  /** The primary key(s) and patch data for `modelParent` for the far side of the relationship. */
  updateByModelIdAndParentIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelParentOnModelParentForModelParentParentFkeyUsingModelParentPkeyUpdate>>;
  /** A `ModelParentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelParentParentFkeyModelParentCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ModelParentParentFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `modelParent` to be created by this mutation. */
export type ModelParentParentFkeyModelParentCreateInput = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** Represents an update to a `ModelParent`. Fields that are set will be updated. */
export type ModelParentPatch = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** A connection to a list of `ModelParent` values. */
export type ModelParentsConnection = {
  __typename?: 'ModelParentsConnection';
  /** A list of `ModelParent` objects. */
  nodes: Array<Maybe<ModelParent>>;
  /** A list of edges which contains the `ModelParent` and cursor to aid in pagination. */
  edges: Array<ModelParentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModelParent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModelParent` edge in the connection. */
export type ModelParentsEdge = {
  __typename?: 'ModelParentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModelParent` at the end of the edge. */
  node?: Maybe<ModelParent>;
};

/** Methods to use when ordering `ModelParent`. */
export enum ModelParentsOrderBy {
  Natural = 'NATURAL',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC'
}

export type ModelParentsRecursive = {
  __typename?: 'ModelParentsRecursive';
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  level?: Maybe<Scalars['Int']>;
  /** Reads a single `Model` that is related to this `ModelParentsRecursive`. */
  model?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelParentsRecursive`. */
  parent?: Maybe<Model>;
};

/**
 * A condition to be used against `ModelParentsRecursive` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ModelParentsRecursiveCondition = {
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `path` field. */
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Checks for equality with the object’s `level` field. */
  level?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `ModelParentsRecursive` object types. All fields are combined with a logical ‘and.’ */
export type ModelParentsRecursiveFilter = {
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `path` field. */
  path?: Maybe<UuidListFilter>;
  /** Filter by the object’s `level` field. */
  level?: Maybe<IntFilter>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** A related `model` exists. */
  modelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parent` relation. */
  parent?: Maybe<ModelFilter>;
  /** A related `parent` exists. */
  parentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ModelParentsRecursiveFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ModelParentsRecursiveFilter>>;
  /** Negates the expression. */
  not?: Maybe<ModelParentsRecursiveFilter>;
};

/** A connection to a list of `ModelParentsRecursive` values. */
export type ModelParentsRecursivesConnection = {
  __typename?: 'ModelParentsRecursivesConnection';
  /** A list of `ModelParentsRecursive` objects. */
  nodes: Array<Maybe<ModelParentsRecursive>>;
  /** A list of edges which contains the `ModelParentsRecursive` and cursor to aid in pagination. */
  edges: Array<ModelParentsRecursivesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ModelParentsRecursive` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ModelParentsRecursive` edge in the connection. */
export type ModelParentsRecursivesEdge = {
  __typename?: 'ModelParentsRecursivesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ModelParentsRecursive` at the end of the edge. */
  node?: Maybe<ModelParentsRecursive>;
};

/** Methods to use when ordering `ModelParentsRecursive`. */
export enum ModelParentsRecursivesOrderBy {
  Natural = 'NATURAL',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  LevelAsc = 'LEVEL_ASC',
  LevelDesc = 'LEVEL_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByParentIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC'
}

/** Represents an update to a `Model`. Fields that are set will be updated. */
export type ModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** A filter to be used against many `Entry` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyEntryFilter = {
  /** Every related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryFilter>;
  /** Some related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryFilter>;
  /** No related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryFilter>;
};

/** A filter to be used against many `MenuItemView` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyMenuItemViewFilter = {
  /** Every related `MenuItemView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuItemViewFilter>;
  /** Some related `MenuItemView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuItemViewFilter>;
  /** No related `MenuItemView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuItemViewFilter>;
};

/** A filter to be used against many `ModelField` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyModelFieldFilter = {
  /** Every related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldFilter>;
  /** Some related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldFilter>;
  /** No related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldFilter>;
};

/** A filter to be used against many `ModelFieldGroup` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyModelFieldGroupFilter = {
  /** Every related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldGroupFilter>;
  /** Some related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldGroupFilter>;
  /** No related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldGroupFilter>;
};

/** A filter to be used against many `ModelFieldsInherited` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyModelFieldsInheritedFilter = {
  /** Every related `ModelFieldsInherited` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldsInheritedFilter>;
  /** Some related `ModelFieldsInherited` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldsInheritedFilter>;
  /** No related `ModelFieldsInherited` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldsInheritedFilter>;
};

/** A filter to be used against many `ModelParent` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyModelParentFilter = {
  /** Every related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelParentFilter>;
  /** Some related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelParentFilter>;
  /** No related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelParentFilter>;
};

/** A filter to be used against many `ModelParentsRecursive` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyModelParentsRecursiveFilter = {
  /** Every related `ModelParentsRecursive` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelParentsRecursiveFilter>;
  /** Some related `ModelParentsRecursive` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelParentsRecursiveFilter>;
  /** No related `ModelParentsRecursive` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelParentsRecursiveFilter>;
};

/** A filter to be used against many `TaxonomyCombined` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyTaxonomyCombinedFilter = {
  /** Every related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyCombinedFilter>;
  /** Some related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyCombinedFilter>;
  /** No related `TaxonomyCombined` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyCombinedFilter>;
};

/** A filter to be used against many `TaxonomyDynamic` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyTaxonomyDynamicFilter = {
  /** Every related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyDynamicFilter>;
  /** Some related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyDynamicFilter>;
  /** No related `TaxonomyDynamic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyDynamicFilter>;
};

/** A filter to be used against many `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyTaxonomyLevelFilter = {
  /** Every related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyLevelFilter>;
  /** Some related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyLevelFilter>;
  /** No related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyLevelFilter>;
};

/** A filter to be used against many `Value` object types. All fields are combined with a logical ‘and.’ */
export type ModelToManyValueFilter = {
  /** Every related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFilter>;
  /** Some related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFilter>;
  /** No related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFilter>;
};

export enum ModelUsage {
  Entry = 'ENTRY',
  Module = 'MODULE'
}

/** A filter to be used against ModelUsage fields. All fields are combined with a logical ‘and.’ */
export type ModelUsageFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ModelUsage>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ModelUsage>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ModelUsage>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ModelUsage>;
  /** Included in the specified list. */
  in?: Maybe<Array<ModelUsage>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ModelUsage>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ModelUsage>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ModelUsage>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ModelUsage>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ModelUsage>;
};

/** Input for the nested mutation of `value` in the `ModelInput` mutation. */
export type ModelValueFkeyInput = {
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyConnect>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyDelete>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ValueOnModelForModelValueFkeyUsingValuePkeyUpdate>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<ModelValueFkeyValueCreateInput>;
};

/** Input for the nested mutation of `model` in the `ValueInput` mutation. */
export type ModelValueFkeyInverseInput = {
  /** Flag indicating whether all other `model` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyConnect>>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelModelPkeyDelete>>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ModelOnModelForModelValueFkeyUsingModelPkeyUpdate>>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ModelValueFkeyModelCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ModelValueFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `value` to be created by this mutation. */
export type ModelValueFkeyValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** A connection to a list of `Model` values. */
export type ModelsConnection = {
  __typename?: 'ModelsConnection';
  /** A list of `Model` objects. */
  nodes: Array<Maybe<Model>>;
  /** A list of edges which contains the `Model` and cursor to aid in pagination. */
  edges: Array<ModelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Model` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Model` edge in the connection. */
export type ModelsEdge = {
  __typename?: 'ModelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Model` at the end of the edge. */
  node?: Maybe<Model>;
};

/** Methods to use when ordering `Model`. */
export enum ModelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  InheritsAsc = 'INHERITS_ASC',
  InheritsDesc = 'INHERITS_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  ValueBaseIdAsc = 'VALUE_BASE_ID_ASC',
  ValueBaseIdDesc = 'VALUE_BASE_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  UsageAsc = 'USAGE_ASC',
  UsageDesc = 'USAGE_DESC',
  DefaultTaxonomyLevelIdAsc = 'DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  DefaultTaxonomyLevelIdDesc = 'DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ValueBaseSnapshotAsc = 'VALUE_BASE_SNAPSHOT_ASC',
  ValueBaseSnapshotDesc = 'VALUE_BASE_SNAPSHOT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdIdAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdIdDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdModelIdAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdModelIdDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ValueByValueBaseIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'VALUE_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdTaxonomyIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TAXONOMY_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdTaxonomyIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TAXONOMY_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdModelIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdModelIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdAliasFieldIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_FIELD_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdAliasFieldIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_FIELD_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdFragmentFieldIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_FIELD_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdFragmentFieldIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_FIELD_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdFragmentTypeAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_TYPE_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdFragmentTypeDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_TYPE_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdIsNodeAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__IS_NODE_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdIsNodeDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__IS_NODE_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdParentIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdParentIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdParentReferenceFieldIdAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_REFERENCE_FIELD_ID_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdParentReferenceFieldIdDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_REFERENCE_FIELD_ID_DESC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdInheritAsc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERIT_ASC',
  TaxonomyLevelByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdInheritDesc = 'TAXONOMY_LEVEL_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERIT_DESC',
  EntriesByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRIES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntriesByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRIES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelParentsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_PARENTS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelParentsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_PARENTS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelParentsByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_PARENTS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelParentsByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_PARENTS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ValuesByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'VALUES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ValuesByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'VALUES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  MenuItemViewsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MENU_ITEM_VIEWS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  MenuItemViewsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MENU_ITEM_VIEWS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldGroupsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELD_GROUPS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldGroupsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELD_GROUPS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldsInheritedsBySourceModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELDS_INHERITEDS_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsInheritedsBySourceModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELDS_INHERITEDS_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldsInheritedsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_FIELDS_INHERITEDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsInheritedsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_FIELDS_INHERITEDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelParentsRecursivesByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_PARENTS_RECURSIVES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelParentsRecursivesByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_PARENTS_RECURSIVES_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelParentsRecursivesByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'MODEL_PARENTS_RECURSIVES_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelParentsRecursivesByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'MODEL_PARENTS_RECURSIVES_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyCombinedsByModelIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_COMBINEDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyCombinedsByModelIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_COMBINEDS_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyDynamicsByModelIdCountAsc = 'TAXONOMY_DYNAMICS_BY_MODEL_ID__COUNT_ASC',
  TaxonomyDynamicsByModelIdCountDesc = 'TAXONOMY_DYNAMICS_BY_MODEL_ID__COUNT_DESC'
}

/** All input for the `moveMenuItem` mutation. */
export type MoveMenuItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
  fId?: Maybe<Scalars['UUID']>;
  fParent?: Maybe<Scalars['UUID']>;
  fBefore?: Maybe<Scalars['UUID']>;
};

/** The output of our `moveMenuItem` mutation. */
export type MoveMenuItemPayload = {
  __typename?: 'MoveMenuItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `Entry`. */
  createEntry?: Maybe<CreateEntryPayload>;
  /** Creates a single `EntryPublish`. */
  createEntryPublish?: Maybe<CreateEntryPublishPayload>;
  /** Creates a single `EntryVersion`. */
  createEntryVersion?: Maybe<CreateEntryVersionPayload>;
  /** Creates a single `MenuItem`. */
  createMenuItem?: Maybe<CreateMenuItemPayload>;
  /** Creates a single `MenuItemEntry`. */
  createMenuItemEntry?: Maybe<CreateMenuItemEntryPayload>;
  /** Creates a single `MenuItemView`. */
  createMenuItemView?: Maybe<CreateMenuItemViewPayload>;
  /** Creates a single `Model`. */
  createModel?: Maybe<CreateModelPayload>;
  /** Creates a single `ModelField`. */
  createModelField?: Maybe<CreateModelFieldPayload>;
  /** Creates a single `ModelFieldGroup`. */
  createModelFieldGroup?: Maybe<CreateModelFieldGroupPayload>;
  /** Creates a single `ModelParent`. */
  createModelParent?: Maybe<CreateModelParentPayload>;
  /** Creates a single `PublishTarget`. */
  createPublishTarget?: Maybe<CreatePublishTargetPayload>;
  /** Creates a single `Taxonomy`. */
  createTaxonomy?: Maybe<CreateTaxonomyPayload>;
  /** Creates a single `TaxonomyLevel`. */
  createTaxonomyLevel?: Maybe<CreateTaxonomyLevelPayload>;
  /** Creates a single `Value`. */
  createValue?: Maybe<CreateValuePayload>;
  /** Creates a single `ValueField`. */
  createValueField?: Maybe<CreateValueFieldPayload>;
  /** Creates a single `MediaFolder`. */
  createMediaFolder?: Maybe<CreateMediaFolderPayload>;
  /** Creates a single `MediaItem`. */
  createMediaItem?: Maybe<CreateMediaItemPayload>;
  /** Creates a single `Account`. */
  createAccount?: Maybe<CreateAccountPayload>;
  /** Creates a single `Project`. */
  createProject?: Maybe<CreateProjectPayload>;
  /** Creates a single `ProjectEnvironment`. */
  createProjectEnvironment?: Maybe<CreateProjectEnvironmentPayload>;
  /** Creates a single `ProjectToken`. */
  createProjectToken?: Maybe<CreateProjectTokenPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `Webhook`. */
  createWebhook?: Maybe<CreateWebhookPayload>;
  /** Creates a single `WebhookLog`. */
  createWebhookLog?: Maybe<CreateWebhookLogPayload>;
  /** Creates a single `WebhookRun`. */
  createWebhookRun?: Maybe<CreateWebhookRunPayload>;
  /** Creates a single `Website`. */
  createWebsite?: Maybe<CreateWebsitePayload>;
  /** Updates a single `Entry` using a unique key and a patch. */
  updateEntry?: Maybe<UpdateEntryPayload>;
  /** Updates a single `EntryPublish` using a unique key and a patch. */
  updateEntryPublish?: Maybe<UpdateEntryPublishPayload>;
  /** Updates a single `EntryVersion` using a unique key and a patch. */
  updateEntryVersion?: Maybe<UpdateEntryVersionPayload>;
  /** Updates a single `EntryVersion` using a unique key and a patch. */
  updateEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<UpdateEntryVersionPayload>;
  /** Updates a single `MenuItem` using a unique key and a patch. */
  updateMenuItem?: Maybe<UpdateMenuItemPayload>;
  /** Updates a single `MenuItemEntry` using a unique key and a patch. */
  updateMenuItemEntry?: Maybe<UpdateMenuItemEntryPayload>;
  /** Updates a single `MenuItemView` using a unique key and a patch. */
  updateMenuItemView?: Maybe<UpdateMenuItemViewPayload>;
  /** Updates a single `Model` using a unique key and a patch. */
  updateModel?: Maybe<UpdateModelPayload>;
  /** Updates a single `ModelField` using a unique key and a patch. */
  updateModelField?: Maybe<UpdateModelFieldPayload>;
  /** Updates a single `ModelFieldGroup` using a unique key and a patch. */
  updateModelFieldGroup?: Maybe<UpdateModelFieldGroupPayload>;
  /** Updates a single `ModelParent` using a unique key and a patch. */
  updateModelParent?: Maybe<UpdateModelParentPayload>;
  /** Updates a single `PublishTarget` using a unique key and a patch. */
  updatePublishTarget?: Maybe<UpdatePublishTargetPayload>;
  /** Updates a single `Taxonomy` using a unique key and a patch. */
  updateTaxonomy?: Maybe<UpdateTaxonomyPayload>;
  /** Updates a single `TaxonomyLevel` using a unique key and a patch. */
  updateTaxonomyLevel?: Maybe<UpdateTaxonomyLevelPayload>;
  /** Updates a single `Value` using a unique key and a patch. */
  updateValue?: Maybe<UpdateValuePayload>;
  /** Updates a single `ValueField` using a unique key and a patch. */
  updateValueField?: Maybe<UpdateValueFieldPayload>;
  /** Updates a single `MediaFolder` using a unique key and a patch. */
  updateMediaFolder?: Maybe<UpdateMediaFolderPayload>;
  /** Updates a single `MediaItem` using a unique key and a patch. */
  updateMediaItem?: Maybe<UpdateMediaItemPayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  updateAccountByKey?: Maybe<UpdateAccountPayload>;
  /** Updates a single `Project` using a unique key and a patch. */
  updateProjectByAccountIdAndKey?: Maybe<UpdateProjectPayload>;
  /** Updates a single `ProjectEnvironment` using a unique key and a patch. */
  updateProjectEnvironment?: Maybe<UpdateProjectEnvironmentPayload>;
  /** Updates a single `ProjectEnvironment` using a unique key and a patch. */
  updateProjectEnvironmentByKeyAndProjectId?: Maybe<UpdateProjectEnvironmentPayload>;
  /** Updates a single `ProjectToken` using a unique key and a patch. */
  updateProjectToken?: Maybe<UpdateProjectTokenPayload>;
  /** Updates a single `Webhook` using a unique key and a patch. */
  updateWebhook?: Maybe<UpdateWebhookPayload>;
  /** Updates a single `WebhookLog` using a unique key and a patch. */
  updateWebhookLog?: Maybe<UpdateWebhookLogPayload>;
  /** Updates a single `WebhookRun` using a unique key and a patch. */
  updateWebhookRun?: Maybe<UpdateWebhookRunPayload>;
  /** Updates a single `Website` using a unique key and a patch. */
  updateWebsite?: Maybe<UpdateWebsitePayload>;
  /** Deletes a single `Entry` using a unique key. */
  deleteEntry?: Maybe<DeleteEntryPayload>;
  /** Deletes a single `EntryPublish` using a unique key. */
  deleteEntryPublish?: Maybe<DeleteEntryPublishPayload>;
  /** Deletes a single `EntryVersion` using a unique key. */
  deleteEntryVersion?: Maybe<DeleteEntryVersionPayload>;
  /** Deletes a single `EntryVersion` using a unique key. */
  deleteEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndex?: Maybe<DeleteEntryVersionPayload>;
  /** Deletes a single `MenuItem` using a unique key. */
  deleteMenuItem?: Maybe<DeleteMenuItemPayload>;
  /** Deletes a single `MenuItemEntry` using a unique key. */
  deleteMenuItemEntry?: Maybe<DeleteMenuItemEntryPayload>;
  /** Deletes a single `MenuItemView` using a unique key. */
  deleteMenuItemView?: Maybe<DeleteMenuItemViewPayload>;
  /** Deletes a single `Model` using a unique key. */
  deleteModel?: Maybe<DeleteModelPayload>;
  /** Deletes a single `ModelField` using a unique key. */
  deleteModelField?: Maybe<DeleteModelFieldPayload>;
  /** Deletes a single `ModelFieldGroup` using a unique key. */
  deleteModelFieldGroup?: Maybe<DeleteModelFieldGroupPayload>;
  /** Deletes a single `ModelParent` using a unique key. */
  deleteModelParent?: Maybe<DeleteModelParentPayload>;
  /** Deletes a single `PublishTarget` using a unique key. */
  deletePublishTarget?: Maybe<DeletePublishTargetPayload>;
  /** Deletes a single `Taxonomy` using a unique key. */
  deleteTaxonomy?: Maybe<DeleteTaxonomyPayload>;
  /** Deletes a single `TaxonomyLevel` using a unique key. */
  deleteTaxonomyLevel?: Maybe<DeleteTaxonomyLevelPayload>;
  /** Deletes a single `Value` using a unique key. */
  deleteValue?: Maybe<DeleteValuePayload>;
  /** Deletes a single `ValueField` using a unique key. */
  deleteValueField?: Maybe<DeleteValueFieldPayload>;
  /** Deletes a single `MediaFolder` using a unique key. */
  deleteMediaFolder?: Maybe<DeleteMediaFolderPayload>;
  /** Deletes a single `MediaItem` using a unique key. */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  /** Deletes a single `Account` using a unique key. */
  deleteAccountByKey?: Maybe<DeleteAccountPayload>;
  /** Deletes a single `Project` using a unique key. */
  deleteProjectByAccountIdAndKey?: Maybe<DeleteProjectPayload>;
  /** Deletes a single `ProjectEnvironment` using a unique key. */
  deleteProjectEnvironment?: Maybe<DeleteProjectEnvironmentPayload>;
  /** Deletes a single `ProjectEnvironment` using a unique key. */
  deleteProjectEnvironmentByKeyAndProjectId?: Maybe<DeleteProjectEnvironmentPayload>;
  /** Deletes a single `ProjectToken` using a unique key. */
  deleteProjectToken?: Maybe<DeleteProjectTokenPayload>;
  /** Deletes a single `Webhook` using a unique key. */
  deleteWebhook?: Maybe<DeleteWebhookPayload>;
  /** Deletes a single `WebhookLog` using a unique key. */
  deleteWebhookLog?: Maybe<DeleteWebhookLogPayload>;
  /** Deletes a single `WebhookRun` using a unique key. */
  deleteWebhookRun?: Maybe<DeleteWebhookRunPayload>;
  /** Deletes a single `Website` using a unique key. */
  deleteWebsite?: Maybe<DeleteWebsitePayload>;
  changeCase?: Maybe<ChangeCasePayload>;
  copyValue?: Maybe<CopyValuePayload>;
  copyVersion?: Maybe<CopyVersionPayload>;
  copyVersion2?: Maybe<CopyVersion2Payload>;
  entryPaths?: Maybe<EntryPathsPayload>;
  moveMenuItem?: Maybe<MoveMenuItemPayload>;
  publishVersion?: Maybe<PublishVersionPayload>;
  putModel?: Maybe<PutModelPayload>;
  unpublishVersion2?: Maybe<UnpublishVersion2Payload>;
  updateModelSnapshot?: Maybe<UpdateModelSnapshotPayload>;
  valueFieldSnapshot?: Maybe<ValueFieldSnapshotPayload>;
  backgroundTask?: Maybe<BackgroundTaskPayload>;
  copyEnvironment?: Maybe<CopyEnvironmentPayload>;
  updateProjectDefaultEnvironment?: Maybe<UpdateProjectDefaultEnvironmentPayload>;
  acceptProjectInvite?: Maybe<AcceptProjectInvitePayload>;
  createProjectApiKey?: Maybe<CreateProjectApiKeyPayload>;
  createProjectInvite?: Maybe<CreateProjectInvitePayload>;
  expireProjectApiKey?: Maybe<ExpireProjectApiKeyPayload>;
  updateProjectApiKey?: Maybe<UpdateProjectApiKeyPayload>;
  mediaUploadUrl?: Maybe<MediaUploadUrlOutput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntryArgs = {
  input: CreateEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntryPublishArgs = {
  input: CreateEntryPublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEntryVersionArgs = {
  input: CreateEntryVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMenuItemArgs = {
  input: CreateMenuItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMenuItemEntryArgs = {
  input: CreateMenuItemEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMenuItemViewArgs = {
  input: CreateMenuItemViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModelArgs = {
  input: CreateModelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModelFieldArgs = {
  input: CreateModelFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModelFieldGroupArgs = {
  input: CreateModelFieldGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModelParentArgs = {
  input: CreateModelParentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePublishTargetArgs = {
  input: CreatePublishTargetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaxonomyArgs = {
  input: CreateTaxonomyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaxonomyLevelArgs = {
  input: CreateTaxonomyLevelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateValueArgs = {
  input: CreateValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateValueFieldArgs = {
  input: CreateValueFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMediaFolderArgs = {
  input: CreateMediaFolderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectEnvironmentArgs = {
  input: CreateProjectEnvironmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectTokenArgs = {
  input: CreateProjectTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWebhookLogArgs = {
  input: CreateWebhookLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWebhookRunArgs = {
  input: CreateWebhookRunInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWebsiteArgs = {
  input: CreateWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntryArgs = {
  input: UpdateEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntryPublishArgs = {
  input: UpdateEntryPublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntryVersionArgs = {
  input: UpdateEntryVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexArgs = {
  input: UpdateEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMenuItemArgs = {
  input: UpdateMenuItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMenuItemEntryArgs = {
  input: UpdateMenuItemEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMenuItemViewArgs = {
  input: UpdateMenuItemViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModelArgs = {
  input: UpdateModelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModelFieldArgs = {
  input: UpdateModelFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModelFieldGroupArgs = {
  input: UpdateModelFieldGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModelParentArgs = {
  input: UpdateModelParentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePublishTargetArgs = {
  input: UpdatePublishTargetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaxonomyArgs = {
  input: UpdateTaxonomyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaxonomyLevelArgs = {
  input: UpdateTaxonomyLevelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateValueArgs = {
  input: UpdateValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateValueFieldArgs = {
  input: UpdateValueFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMediaFolderArgs = {
  input: UpdateMediaFolderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountByKeyArgs = {
  input: UpdateAccountByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectByAccountIdAndKeyArgs = {
  input: UpdateProjectByAccountIdAndKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectEnvironmentArgs = {
  input: UpdateProjectEnvironmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectEnvironmentByKeyAndProjectIdArgs = {
  input: UpdateProjectEnvironmentByKeyAndProjectIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectTokenArgs = {
  input: UpdateProjectTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebhookLogArgs = {
  input: UpdateWebhookLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebhookRunArgs = {
  input: UpdateWebhookRunInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebsiteArgs = {
  input: UpdateWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntryArgs = {
  input: DeleteEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntryPublishArgs = {
  input: DeleteEntryPublishInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntryVersionArgs = {
  input: DeleteEntryVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexArgs = {
  input: DeleteEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMenuItemArgs = {
  input: DeleteMenuItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMenuItemEntryArgs = {
  input: DeleteMenuItemEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMenuItemViewArgs = {
  input: DeleteMenuItemViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModelFieldArgs = {
  input: DeleteModelFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModelFieldGroupArgs = {
  input: DeleteModelFieldGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModelParentArgs = {
  input: DeleteModelParentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePublishTargetArgs = {
  input: DeletePublishTargetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaxonomyArgs = {
  input: DeleteTaxonomyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaxonomyLevelArgs = {
  input: DeleteTaxonomyLevelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteValueArgs = {
  input: DeleteValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteValueFieldArgs = {
  input: DeleteValueFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMediaFolderArgs = {
  input: DeleteMediaFolderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountByKeyArgs = {
  input: DeleteAccountByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectByAccountIdAndKeyArgs = {
  input: DeleteProjectByAccountIdAndKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectEnvironmentArgs = {
  input: DeleteProjectEnvironmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectEnvironmentByKeyAndProjectIdArgs = {
  input: DeleteProjectEnvironmentByKeyAndProjectIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectTokenArgs = {
  input: DeleteProjectTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebhookLogArgs = {
  input: DeleteWebhookLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebhookRunArgs = {
  input: DeleteWebhookRunInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebsiteArgs = {
  input: DeleteWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeCaseArgs = {
  input: ChangeCaseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyValueArgs = {
  input: CopyValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyVersionArgs = {
  input: CopyVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyVersion2Args = {
  input: CopyVersion2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEntryPathsArgs = {
  input: EntryPathsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMoveMenuItemArgs = {
  input: MoveMenuItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishVersionArgs = {
  input: PublishVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPutModelArgs = {
  input: PutModelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnpublishVersion2Args = {
  input: UnpublishVersion2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModelSnapshotArgs = {
  input: UpdateModelSnapshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationValueFieldSnapshotArgs = {
  input: ValueFieldSnapshotInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBackgroundTaskArgs = {
  input: BackgroundTaskInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyEnvironmentArgs = {
  input: CopyEnvironmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectDefaultEnvironmentArgs = {
  input: UpdateProjectDefaultEnvironmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptProjectInviteArgs = {
  input: AcceptProjectInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectApiKeyArgs = {
  input: CreateProjectApiKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectInviteArgs = {
  input: CreateProjectInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationExpireProjectApiKeyArgs = {
  input: ExpireProjectApiKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectApiKeyArgs = {
  input: UpdateProjectApiKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMediaUploadUrlArgs = {
  input: MediaUploadUrlInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  /** Reads a single `Account` that is related to this `Project`. */
  account?: Maybe<Account>;
  /** Reads a single `ProjectEnvironment` that is related to this `Project`. */
  defaultEnvironment?: Maybe<ProjectEnvironment>;
  /** Reads and enables pagination through a set of `PublishTarget`. */
  publishTargets: PublishTargetsConnection;
  /** Reads and enables pagination through a set of `Value`. */
  values: ValuesConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueFields: ValueFieldsConnection;
  /** Reads and enables pagination through a set of `ProjectEnvironment`. */
  projectEnvironments: ProjectEnvironmentsConnection;
};


export type ProjectPublishTargetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PublishTargetsOrderBy>>;
  condition?: Maybe<PublishTargetCondition>;
  filter?: Maybe<PublishTargetFilter>;
};


export type ProjectValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValuesOrderBy>>;
  condition?: Maybe<ValueCondition>;
  filter?: Maybe<ValueFilter>;
};


export type ProjectValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


export type ProjectProjectEnvironmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectEnvironmentsOrderBy>>;
  condition?: Maybe<ProjectEnvironmentCondition>;
  filter?: Maybe<ProjectEnvironmentFilter>;
};

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `regionId` field. */
  regionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `defaultEnvironmentId` field. */
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createPayload` field. */
  createPayload?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createStatus` field. */
  createStatus?: Maybe<Scalars['JSON']>;
};

export type ProjectEnvironment = {
  __typename?: 'ProjectEnvironment';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key: Scalars['String'];
  /** Reads a single `Project` that is related to this `ProjectEnvironment`. */
  project?: Maybe<Project>;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersions: EntryVersionsConnection;
  /** Reads and enables pagination through a set of `Entry`. */
  entries: EntriesConnection;
  /** Reads and enables pagination through a set of `Model`. */
  models: ModelsConnection;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishesByEnvironmentId: EntryPublishesConnection;
  /** Reads and enables pagination through a set of `ModelField`. */
  modelFields: ModelFieldsConnection;
  /** Reads and enables pagination through a set of `ModelParent`. */
  modelParents: ModelParentsConnection;
  /** Reads and enables pagination through a set of `Taxonomy`. */
  taxonomies: TaxonomiesConnection;
  /** Reads and enables pagination through a set of `Value`. */
  values: ValuesConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueFields: ValueFieldsConnection;
  /** Reads and enables pagination through a set of `MenuItem`. */
  menuItemsByEnvironmentId: MenuItemsConnection;
  /** Reads and enables pagination through a set of `ModelFieldGroup`. */
  modelFieldGroups: ModelFieldGroupsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByEnvironmentId: TaxonomyLevelsConnection;
  /** Reads and enables pagination through a set of `Website`. */
  websites: WebsitesConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByDefaultEnvironmentId: ProjectsConnection;
};


export type ProjectEnvironmentEntryVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


export type ProjectEnvironmentEntriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


export type ProjectEnvironmentModelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelsOrderBy>>;
  condition?: Maybe<ModelCondition>;
  filter?: Maybe<ModelFilter>;
};


export type ProjectEnvironmentEntryPublishesByEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


export type ProjectEnvironmentModelFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
  condition?: Maybe<ModelFieldCondition>;
  filter?: Maybe<ModelFieldFilter>;
};


export type ProjectEnvironmentModelParentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
  condition?: Maybe<ModelParentCondition>;
  filter?: Maybe<ModelParentFilter>;
};


export type ProjectEnvironmentTaxonomiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
  condition?: Maybe<TaxonomyCondition>;
  filter?: Maybe<TaxonomyFilter>;
};


export type ProjectEnvironmentValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValuesOrderBy>>;
  condition?: Maybe<ValueCondition>;
  filter?: Maybe<ValueFilter>;
};


export type ProjectEnvironmentValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


export type ProjectEnvironmentMenuItemsByEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
  condition?: Maybe<MenuItemCondition>;
  filter?: Maybe<MenuItemFilter>;
};


export type ProjectEnvironmentModelFieldGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
  condition?: Maybe<ModelFieldGroupCondition>;
  filter?: Maybe<ModelFieldGroupFilter>;
};


export type ProjectEnvironmentTaxonomyLevelsByEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


export type ProjectEnvironmentWebsitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
  filter?: Maybe<WebsiteFilter>;
};


export type ProjectEnvironmentProjectsByDefaultEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
  condition?: Maybe<ProjectCondition>;
  filter?: Maybe<ProjectFilter>;
};

/**
 * A condition to be used against `ProjectEnvironment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectEnvironmentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ProjectEnvironment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `entryVersions` relation. */
  entryVersions?: Maybe<ProjectEnvironmentToManyEntryVersionFilter>;
  /** Some related `entryVersions` exist. */
  entryVersionsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entries` relation. */
  entries?: Maybe<ProjectEnvironmentToManyEntryFilter>;
  /** Some related `entries` exist. */
  entriesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `models` relation. */
  models?: Maybe<ProjectEnvironmentToManyModelFilter>;
  /** Some related `models` exist. */
  modelsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryPublishesByEnvironmentId` relation. */
  entryPublishesByEnvironmentId?: Maybe<ProjectEnvironmentToManyEntryPublishFilter>;
  /** Some related `entryPublishesByEnvironmentId` exist. */
  entryPublishesByEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modelFields` relation. */
  modelFields?: Maybe<ProjectEnvironmentToManyModelFieldFilter>;
  /** Some related `modelFields` exist. */
  modelFieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modelParents` relation. */
  modelParents?: Maybe<ProjectEnvironmentToManyModelParentFilter>;
  /** Some related `modelParents` exist. */
  modelParentsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomies` relation. */
  taxonomies?: Maybe<ProjectEnvironmentToManyTaxonomyFilter>;
  /** Some related `taxonomies` exist. */
  taxonomiesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `values` relation. */
  values?: Maybe<ProjectEnvironmentToManyValueFilter>;
  /** Some related `values` exist. */
  valuesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueFields` relation. */
  valueFields?: Maybe<ProjectEnvironmentToManyValueFieldFilter>;
  /** Some related `valueFields` exist. */
  valueFieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `menuItemsByEnvironmentId` relation. */
  menuItemsByEnvironmentId?: Maybe<ProjectEnvironmentToManyMenuItemFilter>;
  /** Some related `menuItemsByEnvironmentId` exist. */
  menuItemsByEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modelFieldGroups` relation. */
  modelFieldGroups?: Maybe<ProjectEnvironmentToManyModelFieldGroupFilter>;
  /** Some related `modelFieldGroups` exist. */
  modelFieldGroupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByEnvironmentId` relation. */
  taxonomyLevelsByEnvironmentId?: Maybe<ProjectEnvironmentToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByEnvironmentId` exist. */
  taxonomyLevelsByEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `websites` relation. */
  websites?: Maybe<ProjectEnvironmentToManyWebsiteFilter>;
  /** Some related `websites` exist. */
  websitesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectsByDefaultEnvironmentId` relation. */
  projectsByDefaultEnvironmentId?: Maybe<ProjectEnvironmentToManyProjectFilter>;
  /** Some related `projectsByDefaultEnvironmentId` exist. */
  projectsByDefaultEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `project` relation. */
  project?: Maybe<ProjectFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProjectEnvironmentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProjectEnvironmentFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProjectEnvironmentFilter>;
};

/** An input for mutations affecting `ProjectEnvironment` */
export type ProjectEnvironmentInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryVersionForFkEnvironmentUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryVersionForFkEnvironmentPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnEntryVersionForFkEnvironmentUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnEntryVersionForFkEnvironmentPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelForModelEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelForModelEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelForModelEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelForModelEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0Patch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0Patch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1UsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1Patch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1UsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1Patch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnValueForValueEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnValueForValueEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnValueForValueEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnValueForValueEnvironmentIdFkeyPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentUsingProjectEnvironmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentPatch;
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to update. */
export type ProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentUsingUqProjectEnvironmentKeyUpdate = {
  /** An object where the defined keys will be set on the `projectEnvironment` being updated. */
  patch: UpdateProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectEnvironment`. Fields that are set will be updated. */
export type ProjectEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The fields on `projectEnvironment` to look up the row to connect. */
export type ProjectEnvironmentProjectEnvironmentPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to delete. */
export type ProjectEnvironmentProjectEnvironmentPkeyDelete = {
  id: Scalars['UUID'];
};

/** A filter to be used against many `Entry` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyEntryFilter = {
  /** Every related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryFilter>;
  /** Some related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryFilter>;
  /** No related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryFilter>;
};

/** A filter to be used against many `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyEntryPublishFilter = {
  /** Every related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryPublishFilter>;
  /** Some related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryPublishFilter>;
  /** No related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryPublishFilter>;
};

/** A filter to be used against many `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyEntryVersionFilter = {
  /** Every related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryVersionFilter>;
  /** Some related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryVersionFilter>;
  /** No related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryVersionFilter>;
};

/** A filter to be used against many `MenuItem` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyMenuItemFilter = {
  /** Every related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<MenuItemFilter>;
  /** Some related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<MenuItemFilter>;
  /** No related `MenuItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<MenuItemFilter>;
};

/** A filter to be used against many `ModelField` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyModelFieldFilter = {
  /** Every related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldFilter>;
  /** Some related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldFilter>;
  /** No related `ModelField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldFilter>;
};

/** A filter to be used against many `ModelFieldGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyModelFieldGroupFilter = {
  /** Every related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFieldGroupFilter>;
  /** Some related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFieldGroupFilter>;
  /** No related `ModelFieldGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFieldGroupFilter>;
};

/** A filter to be used against many `Model` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyModelFilter = {
  /** Every related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFilter>;
  /** Some related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFilter>;
  /** No related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFilter>;
};

/** A filter to be used against many `ModelParent` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyModelParentFilter = {
  /** Every related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelParentFilter>;
  /** Some related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelParentFilter>;
  /** No related `ModelParent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelParentFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProjectFilter>;
};

/** A filter to be used against many `Taxonomy` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyTaxonomyFilter = {
  /** Every related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyFilter>;
  /** Some related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyFilter>;
  /** No related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyFilter>;
};

/** A filter to be used against many `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyTaxonomyLevelFilter = {
  /** Every related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyLevelFilter>;
  /** Some related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyLevelFilter>;
  /** No related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyLevelFilter>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

/** A filter to be used against many `Value` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyValueFilter = {
  /** Every related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFilter>;
  /** Some related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFilter>;
  /** No related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFilter>;
};

/** A filter to be used against many `Website` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEnvironmentToManyWebsiteFilter = {
  /** Every related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebsiteFilter>;
  /** Some related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebsiteFilter>;
  /** No related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebsiteFilter>;
};

/** The fields on `projectEnvironment` to look up the row to connect. */
export type ProjectEnvironmentUqProjectEnvironmentKeyConnect = {
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The fields on `projectEnvironment` to look up the row to delete. */
export type ProjectEnvironmentUqProjectEnvironmentKeyDelete = {
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** A connection to a list of `ProjectEnvironment` values. */
export type ProjectEnvironmentsConnection = {
  __typename?: 'ProjectEnvironmentsConnection';
  /** A list of `ProjectEnvironment` objects. */
  nodes: Array<Maybe<ProjectEnvironment>>;
  /** A list of edges which contains the `ProjectEnvironment` and cursor to aid in pagination. */
  edges: Array<ProjectEnvironmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectEnvironment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectEnvironment` edge in the connection. */
export type ProjectEnvironmentsEdge = {
  __typename?: 'ProjectEnvironmentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProjectEnvironment` at the end of the edge. */
  node?: Maybe<ProjectEnvironment>;
};

/** Methods to use when ordering `ProjectEnvironment`. */
export enum ProjectEnvironmentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectByProjectIdIdAsc = 'PROJECT_BY_PROJECT_ID__ID_ASC',
  ProjectByProjectIdIdDesc = 'PROJECT_BY_PROJECT_ID__ID_DESC',
  ProjectByProjectIdAccountIdAsc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_ASC',
  ProjectByProjectIdAccountIdDesc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_DESC',
  ProjectByProjectIdRegionIdAsc = 'PROJECT_BY_PROJECT_ID__REGION_ID_ASC',
  ProjectByProjectIdRegionIdDesc = 'PROJECT_BY_PROJECT_ID__REGION_ID_DESC',
  ProjectByProjectIdDefaultEnvironmentIdAsc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_ASC',
  ProjectByProjectIdDefaultEnvironmentIdDesc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_DESC',
  ProjectByProjectIdKeyAsc = 'PROJECT_BY_PROJECT_ID__KEY_ASC',
  ProjectByProjectIdKeyDesc = 'PROJECT_BY_PROJECT_ID__KEY_DESC',
  ProjectByProjectIdNameAsc = 'PROJECT_BY_PROJECT_ID__NAME_ASC',
  ProjectByProjectIdNameDesc = 'PROJECT_BY_PROJECT_ID__NAME_DESC',
  ProjectByProjectIdDeletedAtAsc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_ASC',
  ProjectByProjectIdDeletedAtDesc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_DESC',
  ProjectByProjectIdCreatePayloadAsc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_ASC',
  ProjectByProjectIdCreatePayloadDesc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_DESC',
  ProjectByProjectIdCreateStatusAsc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_ASC',
  ProjectByProjectIdCreateStatusDesc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_DESC',
  EntryVersionsByEnvironmentIdCountAsc = 'ENTRY_VERSIONS_BY_ENVIRONMENT_ID__COUNT_ASC',
  EntryVersionsByEnvironmentIdCountDesc = 'ENTRY_VERSIONS_BY_ENVIRONMENT_ID__COUNT_DESC',
  EntriesByEnvironmentIdCountAsc = 'ENTRIES_BY_ENVIRONMENT_ID__COUNT_ASC',
  EntriesByEnvironmentIdCountDesc = 'ENTRIES_BY_ENVIRONMENT_ID__COUNT_DESC',
  ModelsByEnvironmentIdCountAsc = 'MODELS_BY_ENVIRONMENT_ID__COUNT_ASC',
  ModelsByEnvironmentIdCountDesc = 'MODELS_BY_ENVIRONMENT_ID__COUNT_DESC',
  EntryPublishesByEnvironmentIdCountAsc = 'ENTRY_PUBLISHES_BY_ENVIRONMENT_ID__COUNT_ASC',
  EntryPublishesByEnvironmentIdCountDesc = 'ENTRY_PUBLISHES_BY_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldsByEnvironmentIdCountAsc = 'MODEL_FIELDS_BY_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldsByEnvironmentIdCountDesc = 'MODEL_FIELDS_BY_ENVIRONMENT_ID__COUNT_DESC',
  ModelParentsByEnvironmentIdCountAsc = 'MODEL_PARENTS_BY_ENVIRONMENT_ID__COUNT_ASC',
  ModelParentsByEnvironmentIdCountDesc = 'MODEL_PARENTS_BY_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomiesByEnvironmentIdCountAsc = 'TAXONOMIES_BY_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomiesByEnvironmentIdCountDesc = 'TAXONOMIES_BY_ENVIRONMENT_ID__COUNT_DESC',
  ValuesByEnvironmentIdCountAsc = 'VALUES_BY_ENVIRONMENT_ID__COUNT_ASC',
  ValuesByEnvironmentIdCountDesc = 'VALUES_BY_ENVIRONMENT_ID__COUNT_DESC',
  ValueFieldsByEnvironmentIdCountAsc = 'VALUE_FIELDS_BY_ENVIRONMENT_ID__COUNT_ASC',
  ValueFieldsByEnvironmentIdCountDesc = 'VALUE_FIELDS_BY_ENVIRONMENT_ID__COUNT_DESC',
  MenuItemsByEnvironmentIdCountAsc = 'MENU_ITEMS_BY_ENVIRONMENT_ID__COUNT_ASC',
  MenuItemsByEnvironmentIdCountDesc = 'MENU_ITEMS_BY_ENVIRONMENT_ID__COUNT_DESC',
  ModelFieldGroupsByEnvironmentIdCountAsc = 'MODEL_FIELD_GROUPS_BY_ENVIRONMENT_ID__COUNT_ASC',
  ModelFieldGroupsByEnvironmentIdCountDesc = 'MODEL_FIELD_GROUPS_BY_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_ENVIRONMENT_ID__COUNT_DESC',
  WebsitesByEnvironmentIdCountAsc = 'WEBSITES_BY_ENVIRONMENT_ID__COUNT_ASC',
  WebsitesByEnvironmentIdCountDesc = 'WEBSITES_BY_ENVIRONMENT_ID__COUNT_DESC',
  ProjectsByDefaultEnvironmentIdCountAsc = 'PROJECTS_BY_DEFAULT_ENVIRONMENT_ID__COUNT_ASC',
  ProjectsByDefaultEnvironmentIdCountDesc = 'PROJECTS_BY_DEFAULT_ENVIRONMENT_ID__COUNT_DESC'
}

/** The fields on `project` to look up the row to connect. */
export type ProjectFakeAppProjectProjectUniqueConnect = {
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: Maybe<UuidFilter>;
  /** Filter by the object’s `regionId` field. */
  regionId?: Maybe<StringFilter>;
  /** Filter by the object’s `defaultEnvironmentId` field. */
  defaultEnvironmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createPayload` field. */
  createPayload?: Maybe<JsonFilter>;
  /** Filter by the object’s `createStatus` field. */
  createStatus?: Maybe<JsonFilter>;
  /** Filter by the object’s `publishTargets` relation. */
  publishTargets?: Maybe<ProjectToManyPublishTargetFilter>;
  /** Some related `publishTargets` exist. */
  publishTargetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `values` relation. */
  values?: Maybe<ProjectToManyValueFilter>;
  /** Some related `values` exist. */
  valuesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueFields` relation. */
  valueFields?: Maybe<ProjectToManyValueFieldFilter>;
  /** Some related `valueFields` exist. */
  valueFieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectEnvironments` relation. */
  projectEnvironments?: Maybe<ProjectToManyProjectEnvironmentFilter>;
  /** Some related `projectEnvironments` exist. */
  projectEnvironmentsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `account` relation. */
  account?: Maybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `defaultEnvironment` relation. */
  defaultEnvironment?: Maybe<ProjectEnvironmentFilter>;
  /** A related `defaultEnvironment` exists. */
  defaultEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProjectFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProjectFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProjectFilter>;
};

/** An input for mutations affecting `Project` */
export type ProjectInput = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForFakeAppProjectProjectForeignKey0UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForFakeAppProjectProjectForeignKey0Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForFakeAppProjectProjectForeignKey1UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForFakeAppProjectProjectForeignKey1Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnPublishTargetForFakeAppContentPublishTargetForeignKey0UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnPublishTargetForFakeAppContentPublishTargetForeignKey0Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnValueFieldForFakeAppContentValueFieldForeignKey0UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnValueFieldForFakeAppContentValueFieldForeignKey0Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnValueForFakeAppContentValueForeignKey0UsingFakeAppProjectProjectUniqueUpdate = {
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnValueForFakeAppContentValueForeignKey0Patch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** Represents an update to a `Project`. Fields that are set will be updated. */
export type ProjectPatch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** A filter to be used against many `ProjectEnvironment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectEnvironmentFilter = {
  /** Every related `ProjectEnvironment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProjectEnvironmentFilter>;
  /** Some related `ProjectEnvironment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProjectEnvironmentFilter>;
  /** No related `ProjectEnvironment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProjectEnvironmentFilter>;
};

/** A filter to be used against many `PublishTarget` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyPublishTargetFilter = {
  /** Every related `PublishTarget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PublishTargetFilter>;
  /** Some related `PublishTarget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PublishTargetFilter>;
  /** No related `PublishTarget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PublishTargetFilter>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

/** A filter to be used against many `Value` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyValueFilter = {
  /** Every related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFilter>;
  /** Some related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFilter>;
  /** No related `Value` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFilter>;
};

export type ProjectToken = {
  __typename?: 'ProjectToken';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  token: Scalars['String'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  targetId: Scalars['UUID'];
  /** Reads a single `PublishTarget` that is related to this `ProjectToken`. */
  target?: Maybe<PublishTarget>;
};

/**
 * A condition to be used against `ProjectToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectTokenCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `token` field. */
  token?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectToken` object types. All fields are combined with a logical ‘and.’ */
export type ProjectTokenFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `token` field. */
  token?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `target` relation. */
  target?: Maybe<PublishTargetFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProjectTokenFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProjectTokenFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProjectTokenFilter>;
};

/** An input for mutations affecting `ProjectToken` */
export type ProjectTokenInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  targetId?: Maybe<Scalars['UUID']>;
  target?: Maybe<FkProjectTokenPublishTargetInput>;
};

/** The fields on `projectToken` to look up the row to update. */
export type ProjectTokenOnProjectTokenForFkProjectTokenPublishTargetUsingPkProjectTokenUpdate = {
  /** An object where the defined keys will be set on the `projectToken` being updated. */
  patch: UpdateProjectTokenOnProjectTokenForFkProjectTokenPublishTargetPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `ProjectToken`. Fields that are set will be updated. */
export type ProjectTokenPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  targetId?: Maybe<Scalars['UUID']>;
  target?: Maybe<FkProjectTokenPublishTargetInput>;
};

/** The fields on `projectToken` to look up the row to connect. */
export type ProjectTokenPkProjectTokenConnect = {
  id: Scalars['UUID'];
};

/** The fields on `projectToken` to look up the row to delete. */
export type ProjectTokenPkProjectTokenDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `ProjectToken` values. */
export type ProjectTokensConnection = {
  __typename?: 'ProjectTokensConnection';
  /** A list of `ProjectToken` objects. */
  nodes: Array<Maybe<ProjectToken>>;
  /** A list of edges which contains the `ProjectToken` and cursor to aid in pagination. */
  edges: Array<ProjectTokensEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectToken` edge in the connection. */
export type ProjectTokensEdge = {
  __typename?: 'ProjectTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProjectToken` at the end of the edge. */
  node?: Maybe<ProjectToken>;
};

/** Methods to use when ordering `ProjectToken`. */
export enum ProjectTokensOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublishTargetByTargetIdAndProjectIdIdAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__ID_ASC',
  PublishTargetByTargetIdAndProjectIdIdDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__ID_DESC',
  PublishTargetByTargetIdAndProjectIdProjectIdAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__PROJECT_ID_ASC',
  PublishTargetByTargetIdAndProjectIdProjectIdDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__PROJECT_ID_DESC',
  PublishTargetByTargetIdAndProjectIdKeyAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__KEY_ASC',
  PublishTargetByTargetIdAndProjectIdKeyDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__KEY_DESC',
  PublishTargetByTargetIdAndProjectIdNameAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__NAME_ASC',
  PublishTargetByTargetIdAndProjectIdNameDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__NAME_DESC',
  PublishTargetByTargetIdAndProjectIdDeletedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__DELETED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdDeletedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__DELETED_AT_DESC',
  PublishTargetByTargetIdAndProjectIdCreatedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__CREATED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdCreatedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__CREATED_AT_DESC',
  PublishTargetByTargetIdAndProjectIdModifiedAtAsc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__MODIFIED_AT_ASC',
  PublishTargetByTargetIdAndProjectIdModifiedAtDesc = 'PUBLISH_TARGET_BY_TARGET_ID_AND_PROJECT_ID__MODIFIED_AT_DESC'
}

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node?: Maybe<Project>;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  RegionIdAsc = 'REGION_ID_ASC',
  RegionIdDesc = 'REGION_ID_DESC',
  DefaultEnvironmentIdAsc = 'DEFAULT_ENVIRONMENT_ID_ASC',
  DefaultEnvironmentIdDesc = 'DEFAULT_ENVIRONMENT_ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  CreatePayloadAsc = 'CREATE_PAYLOAD_ASC',
  CreatePayloadDesc = 'CREATE_PAYLOAD_DESC',
  CreateStatusAsc = 'CREATE_STATUS_ASC',
  CreateStatusDesc = 'CREATE_STATUS_DESC',
  AccountByAccountIdIdAsc = 'ACCOUNT_BY_ACCOUNT_ID__ID_ASC',
  AccountByAccountIdIdDesc = 'ACCOUNT_BY_ACCOUNT_ID__ID_DESC',
  AccountByAccountIdNameAsc = 'ACCOUNT_BY_ACCOUNT_ID__NAME_ASC',
  AccountByAccountIdNameDesc = 'ACCOUNT_BY_ACCOUNT_ID__NAME_DESC',
  AccountByAccountIdKeyAsc = 'ACCOUNT_BY_ACCOUNT_ID__KEY_ASC',
  AccountByAccountIdKeyDesc = 'ACCOUNT_BY_ACCOUNT_ID__KEY_DESC',
  AccountByAccountIdCreatedAtAsc = 'ACCOUNT_BY_ACCOUNT_ID__CREATED_AT_ASC',
  AccountByAccountIdCreatedAtDesc = 'ACCOUNT_BY_ACCOUNT_ID__CREATED_AT_DESC',
  AccountByAccountIdModifiedAtAsc = 'ACCOUNT_BY_ACCOUNT_ID__MODIFIED_AT_ASC',
  AccountByAccountIdModifiedAtDesc = 'ACCOUNT_BY_ACCOUNT_ID__MODIFIED_AT_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByDefaultEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByDefaultEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_DEFAULT_ENVIRONMENT_ID__KEY_DESC',
  PublishTargetsByProjectIdCountAsc = 'PUBLISH_TARGETS_BY_PROJECT_ID__COUNT_ASC',
  PublishTargetsByProjectIdCountDesc = 'PUBLISH_TARGETS_BY_PROJECT_ID__COUNT_DESC',
  ValuesByProjectIdCountAsc = 'VALUES_BY_PROJECT_ID__COUNT_ASC',
  ValuesByProjectIdCountDesc = 'VALUES_BY_PROJECT_ID__COUNT_DESC',
  ValueFieldsByProjectIdCountAsc = 'VALUE_FIELDS_BY_PROJECT_ID__COUNT_ASC',
  ValueFieldsByProjectIdCountDesc = 'VALUE_FIELDS_BY_PROJECT_ID__COUNT_DESC',
  ProjectEnvironmentsByProjectIdCountAsc = 'PROJECT_ENVIRONMENTS_BY_PROJECT_ID__COUNT_ASC',
  ProjectEnvironmentsByProjectIdCountDesc = 'PROJECT_ENVIRONMENTS_BY_PROJECT_ID__COUNT_DESC'
}

export type PublishTarget = {
  __typename?: 'PublishTarget';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  key: Scalars['String'];
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  /** Reads a single `Project` that is related to this `PublishTarget`. */
  project?: Maybe<Project>;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishes: EntryPublishesConnection;
  /** Reads and enables pagination through a set of `ProjectToken`. */
  projectTokensByTargetIdAndProjectId: ProjectTokensConnection;
  /** Reads and enables pagination through a set of `Website`. */
  websitesByProjectIdAndTargetId: WebsitesConnection;
};


export type PublishTargetEntryPublishesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


export type PublishTargetProjectTokensByTargetIdAndProjectIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectTokensOrderBy>>;
  condition?: Maybe<ProjectTokenCondition>;
  filter?: Maybe<ProjectTokenFilter>;
};


export type PublishTargetWebsitesByProjectIdAndTargetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
  filter?: Maybe<WebsiteFilter>;
};

/**
 * A condition to be used against `PublishTarget` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PublishTargetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `PublishTarget` object types. All fields are combined with a logical ‘and.’ */
export type PublishTargetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `entryPublishes` relation. */
  entryPublishes?: Maybe<PublishTargetToManyEntryPublishFilter>;
  /** Some related `entryPublishes` exist. */
  entryPublishesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectTokensByTargetIdAndProjectId` relation. */
  projectTokensByTargetIdAndProjectId?: Maybe<PublishTargetToManyProjectTokenFilter>;
  /** Some related `projectTokensByTargetIdAndProjectId` exist. */
  projectTokensByTargetIdAndProjectIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `websitesByProjectIdAndTargetId` relation. */
  websitesByProjectIdAndTargetId?: Maybe<PublishTargetToManyWebsiteFilter>;
  /** Some related `websitesByProjectIdAndTargetId` exist. */
  websitesByProjectIdAndTargetIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `project` relation. */
  project?: Maybe<ProjectFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PublishTargetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PublishTargetFilter>>;
  /** Negates the expression. */
  not?: Maybe<PublishTargetFilter>;
};

/** An input for mutations affecting `PublishTarget` */
export type PublishTargetInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** The fields on `publishTarget` to look up the row to update. */
export type PublishTargetOnEntryPublishForFkEntryPublishTargetUsingPkPublishTargetUpdate = {
  /** An object where the defined keys will be set on the `publishTarget` being updated. */
  patch: UpdatePublishTargetOnEntryPublishForFkEntryPublishTargetPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The fields on `publishTarget` to look up the row to update. */
export type PublishTargetOnProjectTokenForFkProjectTokenPublishTargetUsingPkPublishTargetUpdate = {
  /** An object where the defined keys will be set on the `publishTarget` being updated. */
  patch: UpdatePublishTargetOnProjectTokenForFkProjectTokenPublishTargetPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The fields on `publishTarget` to look up the row to update. */
export type PublishTargetOnPublishTargetForFakeAppContentPublishTargetForeignKey0UsingPkPublishTargetUpdate = {
  /** An object where the defined keys will be set on the `publishTarget` being updated. */
  patch: UpdatePublishTargetOnPublishTargetForFakeAppContentPublishTargetForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The fields on `publishTarget` to look up the row to update. */
export type PublishTargetOnWebsiteForFkWebsiteTargetUsingPkPublishTargetUpdate = {
  /** An object where the defined keys will be set on the `publishTarget` being updated. */
  patch: UpdatePublishTargetOnWebsiteForFkWebsiteTargetPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** Represents an update to a `PublishTarget`. Fields that are set will be updated. */
export type PublishTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** The fields on `publishTarget` to look up the row to connect. */
export type PublishTargetPkPublishTargetConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The fields on `publishTarget` to look up the row to delete. */
export type PublishTargetPkPublishTargetDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** A filter to be used against many `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type PublishTargetToManyEntryPublishFilter = {
  /** Every related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryPublishFilter>;
  /** Some related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryPublishFilter>;
  /** No related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryPublishFilter>;
};

/** A filter to be used against many `ProjectToken` object types. All fields are combined with a logical ‘and.’ */
export type PublishTargetToManyProjectTokenFilter = {
  /** Every related `ProjectToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProjectTokenFilter>;
  /** Some related `ProjectToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProjectTokenFilter>;
  /** No related `ProjectToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProjectTokenFilter>;
};

/** A filter to be used against many `Website` object types. All fields are combined with a logical ‘and.’ */
export type PublishTargetToManyWebsiteFilter = {
  /** Every related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebsiteFilter>;
  /** Some related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebsiteFilter>;
  /** No related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebsiteFilter>;
};

/** A connection to a list of `PublishTarget` values. */
export type PublishTargetsConnection = {
  __typename?: 'PublishTargetsConnection';
  /** A list of `PublishTarget` objects. */
  nodes: Array<Maybe<PublishTarget>>;
  /** A list of edges which contains the `PublishTarget` and cursor to aid in pagination. */
  edges: Array<PublishTargetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PublishTarget` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PublishTarget` edge in the connection. */
export type PublishTargetsEdge = {
  __typename?: 'PublishTargetsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PublishTarget` at the end of the edge. */
  node?: Maybe<PublishTarget>;
};

/** Methods to use when ordering `PublishTarget`. */
export enum PublishTargetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectByProjectIdIdAsc = 'PROJECT_BY_PROJECT_ID__ID_ASC',
  ProjectByProjectIdIdDesc = 'PROJECT_BY_PROJECT_ID__ID_DESC',
  ProjectByProjectIdAccountIdAsc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_ASC',
  ProjectByProjectIdAccountIdDesc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_DESC',
  ProjectByProjectIdRegionIdAsc = 'PROJECT_BY_PROJECT_ID__REGION_ID_ASC',
  ProjectByProjectIdRegionIdDesc = 'PROJECT_BY_PROJECT_ID__REGION_ID_DESC',
  ProjectByProjectIdDefaultEnvironmentIdAsc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_ASC',
  ProjectByProjectIdDefaultEnvironmentIdDesc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_DESC',
  ProjectByProjectIdKeyAsc = 'PROJECT_BY_PROJECT_ID__KEY_ASC',
  ProjectByProjectIdKeyDesc = 'PROJECT_BY_PROJECT_ID__KEY_DESC',
  ProjectByProjectIdNameAsc = 'PROJECT_BY_PROJECT_ID__NAME_ASC',
  ProjectByProjectIdNameDesc = 'PROJECT_BY_PROJECT_ID__NAME_DESC',
  ProjectByProjectIdDeletedAtAsc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_ASC',
  ProjectByProjectIdDeletedAtDesc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_DESC',
  ProjectByProjectIdCreatePayloadAsc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_ASC',
  ProjectByProjectIdCreatePayloadDesc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_DESC',
  ProjectByProjectIdCreateStatusAsc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_ASC',
  ProjectByProjectIdCreateStatusDesc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_DESC',
  EntryPublishesByTargetIdAndProjectIdCountAsc = 'ENTRY_PUBLISHES_BY_TARGET_ID_AND_PROJECT_ID__COUNT_ASC',
  EntryPublishesByTargetIdAndProjectIdCountDesc = 'ENTRY_PUBLISHES_BY_TARGET_ID_AND_PROJECT_ID__COUNT_DESC',
  ProjectTokensByTargetIdAndProjectIdCountAsc = 'PROJECT_TOKENS_BY_TARGET_ID_AND_PROJECT_ID__COUNT_ASC',
  ProjectTokensByTargetIdAndProjectIdCountDesc = 'PROJECT_TOKENS_BY_TARGET_ID_AND_PROJECT_ID__COUNT_DESC',
  WebsitesByProjectIdAndTargetIdCountAsc = 'WEBSITES_BY_PROJECT_ID_AND_TARGET_ID__COUNT_ASC',
  WebsitesByProjectIdAndTargetIdCountDesc = 'WEBSITES_BY_PROJECT_ID_AND_TARGET_ID__COUNT_DESC'
}

/** All input for the `publishVersion` mutation. */
export type PublishVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fEntryId?: Maybe<Scalars['UUID']>;
  fVersionId?: Maybe<Scalars['UUID']>;
  fTargetId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

/** The output of our `publishVersion` mutation. */
export type PublishVersionPayload = {
  __typename?: 'PublishVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `putModel` mutation. */
export type PutModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fModel?: Maybe<ModelInput>;
  fUsage?: Maybe<Scalars['String']>;
  fParents?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  fFieldOrder?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

/** The output of our `putModel` mutation. */
export type PutModelPayload = {
  __typename?: 'PutModelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  model?: Maybe<Model>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `Model`. */
  valueBase?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `Model`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `TaxonomyLevel` that is related to this `Model`. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** An edge for our `Model`. May be used by Relay 1. */
  modelEdge?: Maybe<ModelsEdge>;
};


/** The output of our `putModel` mutation. */
export type PutModelPayloadModelEdgeArgs = {
  orderBy?: Maybe<Array<ModelsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `ChangeTracker`. */
  changeTrackers?: Maybe<ChangeTrackersConnection>;
  /** Reads and enables pagination through a set of `Entry`. */
  entries?: Maybe<EntriesConnection>;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishes?: Maybe<EntryPublishesConnection>;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersions?: Maybe<EntryVersionsConnection>;
  /** Reads and enables pagination through a set of `MenuItem`. */
  menuItems?: Maybe<MenuItemsConnection>;
  /** Reads and enables pagination through a set of `MenuItemEntry`. */
  menuItemEntries?: Maybe<MenuItemEntriesConnection>;
  /** Reads and enables pagination through a set of `MenuItemView`. */
  menuItemViews?: Maybe<MenuItemViewsConnection>;
  /** Reads and enables pagination through a set of `Model`. */
  models?: Maybe<ModelsConnection>;
  /** Reads and enables pagination through a set of `ModelField`. */
  modelFields?: Maybe<ModelFieldsConnection>;
  /** Reads and enables pagination through a set of `ModelFieldsInherited`. */
  modelFieldsInheriteds?: Maybe<ModelFieldsInheritedsConnection>;
  /** Reads and enables pagination through a set of `ModelFieldGroup`. */
  modelFieldGroups?: Maybe<ModelFieldGroupsConnection>;
  /** Reads and enables pagination through a set of `ModelParent`. */
  modelParents?: Maybe<ModelParentsConnection>;
  /** Reads and enables pagination through a set of `ModelParentsRecursive`. */
  modelParentsRecursives?: Maybe<ModelParentsRecursivesConnection>;
  /** Reads and enables pagination through a set of `PublishTarget`. */
  publishTargets?: Maybe<PublishTargetsConnection>;
  /** Reads and enables pagination through a set of `Taxonomy`. */
  taxonomies?: Maybe<TaxonomiesConnection>;
  /** Reads and enables pagination through a set of `TaxonomyCombined`. */
  taxonomyCombineds?: Maybe<TaxonomyCombinedsConnection>;
  /** Reads and enables pagination through a set of `TaxonomyDynamic`. */
  taxonomyDynamics?: Maybe<TaxonomyDynamicsConnection>;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevels?: Maybe<TaxonomyLevelsConnection>;
  /** Reads and enables pagination through a set of `TaxonomyLevelItem`. */
  taxonomyLevelItems?: Maybe<TaxonomyLevelItemsConnection>;
  /** Reads and enables pagination through a set of `TaxonomyPath`. */
  taxonomyPaths?: Maybe<TaxonomyPathsConnection>;
  /** Reads and enables pagination through a set of `Value`. */
  values?: Maybe<ValuesConnection>;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueFields?: Maybe<ValueFieldsConnection>;
  /** Reads and enables pagination through a set of `ValueHierarchy`. */
  valueHierarchies?: Maybe<ValueHierarchiesConnection>;
  /** Reads and enables pagination through a set of `MediaFolder`. */
  mediaFolders?: Maybe<MediaFoldersConnection>;
  /** Reads and enables pagination through a set of `MediaFolderPath`. */
  mediaFolderPaths?: Maybe<MediaFolderPathsConnection>;
  /** Reads and enables pagination through a set of `MediaItem`. */
  mediaItems?: Maybe<MediaItemsConnection>;
  /** Reads and enables pagination through a set of `Account`. */
  accounts?: Maybe<AccountsConnection>;
  /** Reads and enables pagination through a set of `Project`. */
  projects?: Maybe<ProjectsConnection>;
  /** Reads and enables pagination through a set of `ProjectEnvironment`. */
  projectEnvironments?: Maybe<ProjectEnvironmentsConnection>;
  /** Reads and enables pagination through a set of `ProjectToken`. */
  projectTokens?: Maybe<ProjectTokensConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `Webhook`. */
  webhooks?: Maybe<WebhooksConnection>;
  /** Reads and enables pagination through a set of `WebhookLog`. */
  webhookLogs?: Maybe<WebhookLogsConnection>;
  /** Reads and enables pagination through a set of `WebhookRun`. */
  webhookRuns?: Maybe<WebhookRunsConnection>;
  /** Reads and enables pagination through a set of `EntryWebsitePath`. */
  entryWebsitePaths?: Maybe<EntryWebsitePathsConnection>;
  /** Reads and enables pagination through a set of `Website`. */
  websites?: Maybe<WebsitesConnection>;
  entry?: Maybe<Entry>;
  entryPublish?: Maybe<EntryPublish>;
  entryVersion?: Maybe<EntryVersion>;
  entryVersionByIndex?: Maybe<EntryVersion>;
  menuItem?: Maybe<MenuItem>;
  menuItemEntry?: Maybe<MenuItemEntry>;
  menuItemView?: Maybe<MenuItemView>;
  model?: Maybe<Model>;
  modelField?: Maybe<ModelField>;
  modelFieldGroup?: Maybe<ModelFieldGroup>;
  modelParent?: Maybe<ModelParent>;
  publishTarget?: Maybe<PublishTarget>;
  taxonomy?: Maybe<Taxonomy>;
  taxonomyLevel?: Maybe<TaxonomyLevel>;
  taxonomyPath?: Maybe<TaxonomyPath>;
  value?: Maybe<Value>;
  valueField?: Maybe<ValueField>;
  mediaFolder?: Maybe<MediaFolder>;
  mediaItem?: Maybe<MediaItem>;
  accountByKey?: Maybe<Account>;
  projectByKey?: Maybe<Project>;
  projectEnvironment?: Maybe<ProjectEnvironment>;
  projectEnvironmentByKey?: Maybe<ProjectEnvironment>;
  projectToken?: Maybe<ProjectToken>;
  webhook?: Maybe<Webhook>;
  webhookLog?: Maybe<WebhookLog>;
  webhookRun?: Maybe<WebhookRun>;
  website?: Maybe<Website>;
  autoValueTransform?: Maybe<Scalars['JSON']>;
  entryByPath?: Maybe<Scalars['UUID']>;
  modelByAlias?: Maybe<Model>;
  taxonomyFragmentPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `TaxonomyListNodesEntry`. */
  taxonomyListNodes?: Maybe<TaxonomyListNodesEntriesConnection>;
  valueSnapshot?: Maybe<Scalars['JSON']>;
  entryWebsites?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChangeTrackersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChangeTrackersOrderBy>>;
  condition?: Maybe<ChangeTrackerCondition>;
  filter?: Maybe<ChangeTrackerFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryPublishesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
  condition?: Maybe<MenuItemCondition>;
  filter?: Maybe<MenuItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemEntriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
  condition?: Maybe<MenuItemEntryCondition>;
  filter?: Maybe<MenuItemEntryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
  condition?: Maybe<MenuItemViewCondition>;
  filter?: Maybe<MenuItemViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelsOrderBy>>;
  condition?: Maybe<ModelCondition>;
  filter?: Maybe<ModelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
  condition?: Maybe<ModelFieldCondition>;
  filter?: Maybe<ModelFieldFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelFieldsInheritedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldsInheritedsOrderBy>>;
  condition?: Maybe<ModelFieldsInheritedCondition>;
  filter?: Maybe<ModelFieldsInheritedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelFieldGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
  condition?: Maybe<ModelFieldGroupCondition>;
  filter?: Maybe<ModelFieldGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelParentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
  condition?: Maybe<ModelParentCondition>;
  filter?: Maybe<ModelParentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelParentsRecursivesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelParentsRecursivesOrderBy>>;
  condition?: Maybe<ModelParentsRecursiveCondition>;
  filter?: Maybe<ModelParentsRecursiveFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPublishTargetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PublishTargetsOrderBy>>;
  condition?: Maybe<PublishTargetCondition>;
  filter?: Maybe<PublishTargetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
  condition?: Maybe<TaxonomyCondition>;
  filter?: Maybe<TaxonomyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyCombinedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyCombinedsOrderBy>>;
  condition?: Maybe<TaxonomyCombinedCondition>;
  filter?: Maybe<TaxonomyCombinedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyDynamicsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyDynamicsOrderBy>>;
  condition?: Maybe<TaxonomyDynamicCondition>;
  filter?: Maybe<TaxonomyDynamicFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyLevelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyLevelItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelItemsOrderBy>>;
  condition?: Maybe<TaxonomyLevelItemCondition>;
  filter?: Maybe<TaxonomyLevelItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyPathsOrderBy>>;
  condition?: Maybe<TaxonomyPathCondition>;
  filter?: Maybe<TaxonomyPathFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValuesOrderBy>>;
  condition?: Maybe<ValueCondition>;
  filter?: Maybe<ValueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValueHierarchiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueHierarchiesOrderBy>>;
  condition?: Maybe<ValueHierarchyCondition>;
  filter?: Maybe<ValueHierarchyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaFoldersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaFoldersOrderBy>>;
  condition?: Maybe<MediaFolderCondition>;
  filter?: Maybe<MediaFolderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaFolderPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaFolderPathsOrderBy>>;
  condition?: Maybe<MediaFolderPathCondition>;
  filter?: Maybe<MediaFolderPathFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MediaItemsOrderBy>>;
  condition?: Maybe<MediaItemCondition>;
  filter?: Maybe<MediaItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AccountsOrderBy>>;
  condition?: Maybe<AccountCondition>;
  filter?: Maybe<AccountFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
  condition?: Maybe<ProjectCondition>;
  filter?: Maybe<ProjectFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEnvironmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectEnvironmentsOrderBy>>;
  condition?: Maybe<ProjectEnvironmentCondition>;
  filter?: Maybe<ProjectEnvironmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectTokensArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProjectTokensOrderBy>>;
  condition?: Maybe<ProjectTokenCondition>;
  filter?: Maybe<ProjectTokenFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhooksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhooksOrderBy>>;
  condition?: Maybe<WebhookCondition>;
  filter?: Maybe<WebhookFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhookLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
  condition?: Maybe<WebhookLogCondition>;
  filter?: Maybe<WebhookLogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhookRunsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhookRunsOrderBy>>;
  condition?: Maybe<WebhookRunCondition>;
  filter?: Maybe<WebhookRunFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryWebsitePathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryWebsitePathsOrderBy>>;
  condition?: Maybe<EntryWebsitePathCondition>;
  filter?: Maybe<EntryWebsitePathFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
  filter?: Maybe<WebsiteFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryPublishArgs = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryVersionArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryVersionByIndexArgs = {
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemEntryArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMenuItemViewArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModelArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModelFieldArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModelFieldGroupArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryModelParentArgs = {
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPublishTargetArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyLevelArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyPathArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValueArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValueFieldArgs = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaFolderArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaItemArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountByKeyArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectByKeyArgs = {
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEnvironmentArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEnvironmentByKeyArgs = {
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectTokenArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhookArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhookLogArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebhookRunArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsiteArgs = {
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAutoValueTransformArgs = {
  fValue?: Maybe<Scalars['JSON']>;
  fTransform?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryByPathArgs = {
  fPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
  fTargetId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryModelByAliasArgs = {
  inAlias?: Maybe<Scalars['String']>;
  inProject?: Maybe<Scalars['UUID']>;
  inEnvironment?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyFragmentPathArgs = {
  fSource?: Maybe<Scalars['String']>;
  fFragmentType?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxonomyListNodesArgs = {
  fParent?: Maybe<Array<Maybe<Scalars['String']>>>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
  fTarget?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<TaxonomyListNodesEntryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValueSnapshotArgs = {
  fEntryId?: Maybe<Scalars['UUID']>;
  fValueId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEntryWebsitesArgs = {
  fEntryId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

export type RecordChangedPayload = {
  __typename?: 'RecordChangedPayload';
  event?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  contentChanged?: Maybe<RecordChangedPayload>;
  modelChanged?: Maybe<RecordChangedPayload>;
  mediaChanged?: Maybe<RecordChangedPayload>;
  taxonomyChanged?: Maybe<RecordChangedPayload>;
  menuChanged?: Maybe<RecordChangedPayload>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionContentChangedArgs = {
  project: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionModelChangedArgs = {
  project: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionMediaChangedArgs = {
  project: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionTaxonomyChangedArgs = {
  project: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionMenuChangedArgs = {
  project: Scalars['UUID'];
};

/** A connection to a list of `Taxonomy` values. */
export type TaxonomiesConnection = {
  __typename?: 'TaxonomiesConnection';
  /** A list of `Taxonomy` objects. */
  nodes: Array<Maybe<Taxonomy>>;
  /** A list of edges which contains the `Taxonomy` and cursor to aid in pagination. */
  edges: Array<TaxonomiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Taxonomy` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Taxonomy` edge in the connection. */
export type TaxonomiesEdge = {
  __typename?: 'TaxonomiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Taxonomy` at the end of the edge. */
  node?: Maybe<Taxonomy>;
};

/** Methods to use when ordering `Taxonomy`. */
export enum TaxonomiesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdParentIdAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdParentIdDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdAliasAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdAliasDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdTypeAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdTypeDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdConfigAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdConfigDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  TaxonomyByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'TAXONOMY_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  TaxonomiesByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMIES_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomiesByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMIES_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByTaxonomyIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByTaxonomyIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  WebsitesByProjectIdAndEnvironmentIdAndTaxonomyIdCountAsc = 'WEBSITES_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__COUNT_ASC',
  WebsitesByProjectIdAndEnvironmentIdAndTaxonomyIdCountDesc = 'WEBSITES_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__COUNT_DESC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdPathAliasAsc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PATH_ALIAS_ASC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdPathAliasDesc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PATH_ALIAS_DESC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdPathIdAsc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PATH_ID_ASC',
  TaxonomyPathByIdAndProjectIdAndEnvironmentIdPathIdDesc = 'TAXONOMY_PATH_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PATH_ID_DESC'
}

export type Taxonomy = {
  __typename?: 'Taxonomy';
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  projectId: Scalars['UUID'];
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type: TaxonomyType;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt: Scalars['Datetime'];
  environmentId: Scalars['UUID'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Taxonomy` that is related to this `Taxonomy`. */
  parent?: Maybe<Taxonomy>;
  /** Reads a single `Entry` that is related to this `Taxonomy`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `Taxonomy`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads and enables pagination through a set of `Taxonomy`. */
  children: TaxonomiesConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  levels: TaxonomyLevelsConnection;
  /** Reads and enables pagination through a set of `Website`. */
  websitesByProjectIdAndEnvironmentIdAndTaxonomyId: WebsitesConnection;
  /** Reads a single `TaxonomyPath` that is related to this `Taxonomy`. */
  path?: Maybe<TaxonomyPath>;
  /**
   * Reads and enables pagination through a set of `TaxonomyPath`.
   * @deprecated Please use path instead
   */
  taxonomyPathsByIdAndProjectIdAndEnvironmentId: TaxonomyPathsConnection;
};


export type TaxonomyChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
  condition?: Maybe<TaxonomyCondition>;
  filter?: Maybe<TaxonomyFilter>;
};


export type TaxonomyLevelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};


export type TaxonomyWebsitesByProjectIdAndEnvironmentIdAndTaxonomyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
  filter?: Maybe<WebsiteFilter>;
};


export type TaxonomyTaxonomyPathsByIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyPathsOrderBy>>;
  condition?: Maybe<TaxonomyPathCondition>;
  filter?: Maybe<TaxonomyPathFilter>;
};

export type TaxonomyCombined = {
  __typename?: 'TaxonomyCombined';
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  source?: Maybe<TaxonomySource>;
  targetId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Entry` that is related to this `TaxonomyCombined`. */
  entry?: Maybe<Entry>;
  /** Reads a single `Model` that is related to this `TaxonomyCombined`. */
  model?: Maybe<Model>;
};

/**
 * A condition to be used against `TaxonomyCombined` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxonomyCombinedCondition = {
  /** Checks for equality with the object’s `pathAlias` field. */
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `pathId` field. */
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelAlias` field. */
  modelAlias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `source` field. */
  source?: Maybe<TaxonomySource>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `TaxonomyCombined` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyCombinedFilter = {
  /** Filter by the object’s `pathAlias` field. */
  pathAlias?: Maybe<StringListFilter>;
  /** Filter by the object’s `pathId` field. */
  pathId?: Maybe<UuidListFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelAlias` field. */
  modelAlias?: Maybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source?: Maybe<TaxonomySourceFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** A related `entry` exists. */
  entryExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** A related `model` exists. */
  modelExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyCombinedFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyCombinedFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyCombinedFilter>;
};

/** A connection to a list of `TaxonomyCombined` values. */
export type TaxonomyCombinedsConnection = {
  __typename?: 'TaxonomyCombinedsConnection';
  /** A list of `TaxonomyCombined` objects. */
  nodes: Array<Maybe<TaxonomyCombined>>;
  /** A list of edges which contains the `TaxonomyCombined` and cursor to aid in pagination. */
  edges: Array<TaxonomyCombinedsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyCombined` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyCombined` edge in the connection. */
export type TaxonomyCombinedsEdge = {
  __typename?: 'TaxonomyCombinedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyCombined` at the end of the edge. */
  node?: Maybe<TaxonomyCombined>;
};

/** Methods to use when ordering `TaxonomyCombined`. */
export enum TaxonomyCombinedsOrderBy {
  Natural = 'NATURAL',
  PathAliasAsc = 'PATH_ALIAS_ASC',
  PathAliasDesc = 'PATH_ALIAS_DESC',
  PathIdAsc = 'PATH_ID_ASC',
  PathIdDesc = 'PATH_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ModelAliasAsc = 'MODEL_ALIAS_ASC',
  ModelAliasDesc = 'MODEL_ALIAS_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC'
}

/**
 * A condition to be used against `Taxonomy` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaxonomyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<TaxonomyType>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
};

export type TaxonomyDynamic = {
  __typename?: 'TaxonomyDynamic';
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Entry` that is related to this `TaxonomyDynamic`. */
  entry?: Maybe<Entry>;
  /** Reads a single `Model` that is related to this `TaxonomyDynamic`. */
  model?: Maybe<Model>;
};

/**
 * A condition to be used against `TaxonomyDynamic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxonomyDynamicCondition = {
  /** Checks for equality with the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pathAlias` field. */
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `pathId` field. */
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelAlias` field. */
  modelAlias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `TaxonomyDynamic` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyDynamicFilter = {
  /** Filter by the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `pathAlias` field. */
  pathAlias?: Maybe<StringListFilter>;
  /** Filter by the object’s `pathId` field. */
  pathId?: Maybe<UuidListFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelAlias` field. */
  modelAlias?: Maybe<StringFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** A related `entry` exists. */
  entryExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** A related `model` exists. */
  modelExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyDynamicFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyDynamicFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyDynamicFilter>;
};

/** A connection to a list of `TaxonomyDynamic` values. */
export type TaxonomyDynamicsConnection = {
  __typename?: 'TaxonomyDynamicsConnection';
  /** A list of `TaxonomyDynamic` objects. */
  nodes: Array<Maybe<TaxonomyDynamic>>;
  /** A list of edges which contains the `TaxonomyDynamic` and cursor to aid in pagination. */
  edges: Array<TaxonomyDynamicsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyDynamic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyDynamic` edge in the connection. */
export type TaxonomyDynamicsEdge = {
  __typename?: 'TaxonomyDynamicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyDynamic` at the end of the edge. */
  node?: Maybe<TaxonomyDynamic>;
};

/** Methods to use when ordering `TaxonomyDynamic`. */
export enum TaxonomyDynamicsOrderBy {
  Natural = 'NATURAL',
  TaxonomyIdAsc = 'TAXONOMY_ID_ASC',
  TaxonomyIdDesc = 'TAXONOMY_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  PathAliasAsc = 'PATH_ALIAS_ASC',
  PathAliasDesc = 'PATH_ALIAS_DESC',
  PathIdAsc = 'PATH_ID_ASC',
  PathIdDesc = 'PATH_ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ModelAliasAsc = 'MODEL_ALIAS_ASC',
  ModelAliasDesc = 'MODEL_ALIAS_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  EntryByEntryIdIdAsc = 'ENTRY_BY_ENTRY_ID__ID_ASC',
  EntryByEntryIdIdDesc = 'ENTRY_BY_ENTRY_ID__ID_DESC',
  EntryByEntryIdProjectIdAsc = 'ENTRY_BY_ENTRY_ID__PROJECT_ID_ASC',
  EntryByEntryIdProjectIdDesc = 'ENTRY_BY_ENTRY_ID__PROJECT_ID_DESC',
  EntryByEntryIdCreatedAtAsc = 'ENTRY_BY_ENTRY_ID__CREATED_AT_ASC',
  EntryByEntryIdCreatedAtDesc = 'ENTRY_BY_ENTRY_ID__CREATED_AT_DESC',
  EntryByEntryIdModelIdAsc = 'ENTRY_BY_ENTRY_ID__MODEL_ID_ASC',
  EntryByEntryIdModelIdDesc = 'ENTRY_BY_ENTRY_ID__MODEL_ID_DESC',
  EntryByEntryIdNameAsc = 'ENTRY_BY_ENTRY_ID__NAME_ASC',
  EntryByEntryIdNameDesc = 'ENTRY_BY_ENTRY_ID__NAME_DESC',
  EntryByEntryIdModifiedAtAsc = 'ENTRY_BY_ENTRY_ID__MODIFIED_AT_ASC',
  EntryByEntryIdModifiedAtDesc = 'ENTRY_BY_ENTRY_ID__MODIFIED_AT_DESC',
  EntryByEntryIdDeletedAtAsc = 'ENTRY_BY_ENTRY_ID__DELETED_AT_ASC',
  EntryByEntryIdDeletedAtDesc = 'ENTRY_BY_ENTRY_ID__DELETED_AT_DESC',
  EntryByEntryIdEnvironmentIdAsc = 'ENTRY_BY_ENTRY_ID__ENVIRONMENT_ID_ASC',
  EntryByEntryIdEnvironmentIdDesc = 'ENTRY_BY_ENTRY_ID__ENVIRONMENT_ID_DESC',
  EntryByEntryIdLatestVersionIdAsc = 'ENTRY_BY_ENTRY_ID__LATEST_VERSION_ID_ASC',
  EntryByEntryIdLatestVersionIdDesc = 'ENTRY_BY_ENTRY_ID__LATEST_VERSION_ID_DESC',
  EntryByEntryIdCreatedByIdAsc = 'ENTRY_BY_ENTRY_ID__CREATED_BY_ID_ASC',
  EntryByEntryIdCreatedByIdDesc = 'ENTRY_BY_ENTRY_ID__CREATED_BY_ID_DESC',
  EntryByEntryIdModifiedByIdAsc = 'ENTRY_BY_ENTRY_ID__MODIFIED_BY_ID_ASC',
  EntryByEntryIdModifiedByIdDesc = 'ENTRY_BY_ENTRY_ID__MODIFIED_BY_ID_DESC',
  ModelByModelIdIdAsc = 'MODEL_BY_MODEL_ID__ID_ASC',
  ModelByModelIdIdDesc = 'MODEL_BY_MODEL_ID__ID_DESC',
  ModelByModelIdProjectIdAsc = 'MODEL_BY_MODEL_ID__PROJECT_ID_ASC',
  ModelByModelIdProjectIdDesc = 'MODEL_BY_MODEL_ID__PROJECT_ID_DESC',
  ModelByModelIdCreatedAtAsc = 'MODEL_BY_MODEL_ID__CREATED_AT_ASC',
  ModelByModelIdCreatedAtDesc = 'MODEL_BY_MODEL_ID__CREATED_AT_DESC',
  ModelByModelIdNameAsc = 'MODEL_BY_MODEL_ID__NAME_ASC',
  ModelByModelIdNameDesc = 'MODEL_BY_MODEL_ID__NAME_DESC',
  ModelByModelIdAliasAsc = 'MODEL_BY_MODEL_ID__ALIAS_ASC',
  ModelByModelIdAliasDesc = 'MODEL_BY_MODEL_ID__ALIAS_DESC',
  ModelByModelIdInheritsAsc = 'MODEL_BY_MODEL_ID__INHERITS_ASC',
  ModelByModelIdInheritsDesc = 'MODEL_BY_MODEL_ID__INHERITS_DESC',
  ModelByModelIdModifiedAtAsc = 'MODEL_BY_MODEL_ID__MODIFIED_AT_ASC',
  ModelByModelIdModifiedAtDesc = 'MODEL_BY_MODEL_ID__MODIFIED_AT_DESC',
  ModelByModelIdDeletedAtAsc = 'MODEL_BY_MODEL_ID__DELETED_AT_ASC',
  ModelByModelIdDeletedAtDesc = 'MODEL_BY_MODEL_ID__DELETED_AT_DESC',
  ModelByModelIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdUsageAsc = 'MODEL_BY_MODEL_ID__USAGE_ASC',
  ModelByModelIdUsageDesc = 'MODEL_BY_MODEL_ID__USAGE_DESC',
  ModelByModelIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID__VALUE_BASE_SNAPSHOT_DESC'
}

/** The `entry` to be created by this mutation. */
export type TaxonomyEntryFkeyEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `entry` in the `TaxonomyInput` mutation. */
export type TaxonomyEntryFkeyInput = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnTaxonomyForTaxonomyEntryFkeyUsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<TaxonomyEntryFkeyEntryCreateInput>;
};

/** Input for the nested mutation of `taxonomy` in the `EntryInput` mutation. */
export type TaxonomyEntryFkeyInverseInput = {
  /** Flag indicating whether all other `taxonomy` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyTaxonomyPkeyConnect>>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyTaxonomyPkeyDelete>>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyOnTaxonomyForTaxonomyEntryFkeyUsingTaxonomyPkeyUpdate>>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TaxonomyEntryFkeyTaxonomyCreateInput>>;
};

/** The `taxonomy` to be created by this mutation. */
export type TaxonomyEntryFkeyTaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** Input for the nested mutation of `projectEnvironment` in the `TaxonomyInput` mutation. */
export type TaxonomyEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<TaxonomyEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `taxonomy` in the `ProjectEnvironmentInput` mutation. */
export type TaxonomyEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `taxonomy` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyTaxonomyPkeyConnect>>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyTaxonomyPkeyDelete>>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingTaxonomyPkeyUpdate>>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TaxonomyEnvironmentIdFkeyTaxonomyCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type TaxonomyEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `taxonomy` to be created by this mutation. */
export type TaxonomyEnvironmentIdFkeyTaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** A filter to be used against `Taxonomy` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<TaxonomyTypeFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `children` relation. */
  children?: Maybe<TaxonomyToManyTaxonomyFilter>;
  /** Some related `children` exist. */
  childrenExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `levels` relation. */
  levels?: Maybe<TaxonomyToManyTaxonomyLevelFilter>;
  /** Some related `levels` exist. */
  levelsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `websitesByProjectIdAndEnvironmentIdAndTaxonomyId` relation. */
  websitesByProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<TaxonomyToManyWebsiteFilter>;
  /** Some related `websitesByProjectIdAndEnvironmentIdAndTaxonomyId` exist. */
  websitesByProjectIdAndEnvironmentIdAndTaxonomyIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `path` relation. */
  path?: Maybe<TaxonomyPathFilter>;
  /** A related `path` exists. */
  pathExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parent` relation. */
  parent?: Maybe<TaxonomyFilter>;
  /** A related `parent` exists. */
  parentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** A related `entry` exists. */
  entryExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyFilter>;
};

/** An input for mutations affecting `Taxonomy` */
export type TaxonomyInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

export type TaxonomyLevel = {
  __typename?: 'TaxonomyLevel';
  id: Scalars['UUID'];
  taxonomyId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId: Scalars['UUID'];
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode: Scalars['Boolean'];
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Taxonomy` that is related to this `TaxonomyLevel`. */
  taxonomy?: Maybe<Taxonomy>;
  /** Reads a single `ProjectEnvironment` that is related to this `TaxonomyLevel`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `TaxonomyLevel`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  aliasFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  fragmentFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `TaxonomyLevel` that is related to this `TaxonomyLevel`. */
  parentProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  parentReferenceFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads and enables pagination through a set of `Model`. */
  modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId: ModelsConnection;
  /** Reads and enables pagination through a set of `TaxonomyLevel`. */
  taxonomyLevelsByParentIdAndProjectIdAndEnvironmentId: TaxonomyLevelsConnection;
};


export type TaxonomyLevelModelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelsOrderBy>>;
  condition?: Maybe<ModelCondition>;
  filter?: Maybe<ModelFilter>;
};


export type TaxonomyLevelTaxonomyLevelsByParentIdAndProjectIdAndEnvironmentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
  condition?: Maybe<TaxonomyLevelCondition>;
  filter?: Maybe<TaxonomyLevelFilter>;
};

/**
 * A condition to be used against `TaxonomyLevel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxonomyLevelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `aliasFieldId` field. */
  aliasFieldId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fragmentFieldId` field. */
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fragmentType` field. */
  fragmentType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isNode` field. */
  isNode?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentReferenceFieldId` field. */
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `inherit` field. */
  inherit?: Maybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `TaxonomyLevelInput` mutation. */
export type TaxonomyLevelEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<TaxonomyLevelEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `taxonomyLevel` in the `ProjectEnvironmentInput` mutation. */
export type TaxonomyLevelEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `taxonomyLevel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelConnect>>;
  /** The primary key(s) for `taxonomyLevel` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelPkTaxonomyLevelDelete>>;
  /** The primary key(s) and patch data for `taxonomyLevel` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<TaxonomyLevelOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingPkTaxonomyLevelUpdate>>;
  /** A `TaxonomyLevelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TaxonomyLevelEnvironmentIdFkeyTaxonomyLevelCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type TaxonomyLevelEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `taxonomyLevel` to be created by this mutation. */
export type TaxonomyLevelEnvironmentIdFkeyTaxonomyLevelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** A filter to be used against `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyLevelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `aliasFieldId` field. */
  aliasFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fragmentFieldId` field. */
  fragmentFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `fragmentType` field. */
  fragmentType?: Maybe<StringFilter>;
  /** Filter by the object’s `isNode` field. */
  isNode?: Maybe<BooleanFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentReferenceFieldId` field. */
  parentReferenceFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `inherit` field. */
  inherit?: Maybe<BooleanFilter>;
  /** Filter by the object’s `modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId` relation. */
  modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelToManyModelFilter>;
  /** Some related `modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId` exist. */
  modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomyLevelsByParentIdAndProjectIdAndEnvironmentId` relation. */
  taxonomyLevelsByParentIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyLevelToManyTaxonomyLevelFilter>;
  /** Some related `taxonomyLevelsByParentIdAndProjectIdAndEnvironmentId` exist. */
  taxonomyLevelsByParentIdAndProjectIdAndEnvironmentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `taxonomy` relation. */
  taxonomy?: Maybe<TaxonomyFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `modelProjectEnvironment` relation. */
  modelProjectEnvironment?: Maybe<ModelFilter>;
  /** Filter by the object’s `aliasFieldProjectEnvironment` relation. */
  aliasFieldProjectEnvironment?: Maybe<ModelFieldFilter>;
  /** A related `aliasFieldProjectEnvironment` exists. */
  aliasFieldProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fragmentFieldProjectEnvironment` relation. */
  fragmentFieldProjectEnvironment?: Maybe<ModelFieldFilter>;
  /** A related `fragmentFieldProjectEnvironment` exists. */
  fragmentFieldProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentProjectEnvironment` relation. */
  parentProjectEnvironment?: Maybe<TaxonomyLevelFilter>;
  /** A related `parentProjectEnvironment` exists. */
  parentProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentReferenceFieldProjectEnvironment` relation. */
  parentReferenceFieldProjectEnvironment?: Maybe<ModelFieldFilter>;
  /** A related `parentReferenceFieldProjectEnvironment` exists. */
  parentReferenceFieldProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyLevelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyLevelFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyLevelFilter>;
};

/** An input for mutations affecting `TaxonomyLevel` */
export type TaxonomyLevelInput = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

export type TaxonomyLevelItem = {
  __typename?: 'TaxonomyLevelItem';
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  taxonomyLevelId?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  modelAlias?: Maybe<Scalars['String']>;
  parentTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  parentEntryId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  fragment?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `TaxonomyLevelItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxonomyLevelItemCondition = {
  /** Checks for equality with the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `taxonomyLevelId` field. */
  taxonomyLevelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `entryId` field. */
  entryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelAlias` field. */
  modelAlias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentTaxonomyLevelId` field. */
  parentTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `parentEntryId` field. */
  parentEntryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `alias` field. */
  alias?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fragment` field. */
  fragment?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `TaxonomyLevelItem` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyLevelItemFilter = {
  /** Filter by the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `taxonomyLevelId` field. */
  taxonomyLevelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelAlias` field. */
  modelAlias?: Maybe<StringFilter>;
  /** Filter by the object’s `parentTaxonomyLevelId` field. */
  parentTaxonomyLevelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `parentEntryId` field. */
  parentEntryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `alias` field. */
  alias?: Maybe<StringFilter>;
  /** Filter by the object’s `fragment` field. */
  fragment?: Maybe<StringListFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyLevelItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyLevelItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyLevelItemFilter>;
};

/** A connection to a list of `TaxonomyLevelItem` values. */
export type TaxonomyLevelItemsConnection = {
  __typename?: 'TaxonomyLevelItemsConnection';
  /** A list of `TaxonomyLevelItem` objects. */
  nodes: Array<Maybe<TaxonomyLevelItem>>;
  /** A list of edges which contains the `TaxonomyLevelItem` and cursor to aid in pagination. */
  edges: Array<TaxonomyLevelItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyLevelItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyLevelItem` edge in the connection. */
export type TaxonomyLevelItemsEdge = {
  __typename?: 'TaxonomyLevelItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyLevelItem` at the end of the edge. */
  node?: Maybe<TaxonomyLevelItem>;
};

/** Methods to use when ordering `TaxonomyLevelItem`. */
export enum TaxonomyLevelItemsOrderBy {
  Natural = 'NATURAL',
  TaxonomyIdAsc = 'TAXONOMY_ID_ASC',
  TaxonomyIdDesc = 'TAXONOMY_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  TaxonomyLevelIdAsc = 'TAXONOMY_LEVEL_ID_ASC',
  TaxonomyLevelIdDesc = 'TAXONOMY_LEVEL_ID_DESC',
  EntryIdAsc = 'ENTRY_ID_ASC',
  EntryIdDesc = 'ENTRY_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  ModelAliasAsc = 'MODEL_ALIAS_ASC',
  ModelAliasDesc = 'MODEL_ALIAS_DESC',
  ParentTaxonomyLevelIdAsc = 'PARENT_TAXONOMY_LEVEL_ID_ASC',
  ParentTaxonomyLevelIdDesc = 'PARENT_TAXONOMY_LEVEL_ID_DESC',
  ParentEntryIdAsc = 'PARENT_ENTRY_ID_ASC',
  ParentEntryIdDesc = 'PARENT_ENTRY_ID_DESC',
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  FragmentAsc = 'FRAGMENT_ASC',
  FragmentDesc = 'FRAGMENT_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC'
}

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnModelForFkModelDefaultTaxonomyLevelUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnModelForFkModelDefaultTaxonomyLevelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelAliasFieldUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelAliasFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelModelUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelTaxonomyUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelTaxonomyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to update. */
export type TaxonomyLevelOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyUsingPkTaxonomyLevelUpdate = {
  /** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
  patch: UpdateTaxonomyLevelOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `TaxonomyLevel`. Fields that are set will be updated. */
export type TaxonomyLevelPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** The fields on `taxonomyLevel` to look up the row to connect. */
export type TaxonomyLevelPkTaxonomyLevelConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyLevel` to look up the row to delete. */
export type TaxonomyLevelPkTaxonomyLevelDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against many `Model` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyLevelToManyModelFilter = {
  /** Every related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFilter>;
  /** Some related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFilter>;
  /** No related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFilter>;
};

/** A filter to be used against many `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyLevelToManyTaxonomyLevelFilter = {
  /** Every related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyLevelFilter>;
  /** Some related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyLevelFilter>;
  /** No related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyLevelFilter>;
};

/** A connection to a list of `TaxonomyLevel` values. */
export type TaxonomyLevelsConnection = {
  __typename?: 'TaxonomyLevelsConnection';
  /** A list of `TaxonomyLevel` objects. */
  nodes: Array<Maybe<TaxonomyLevel>>;
  /** A list of edges which contains the `TaxonomyLevel` and cursor to aid in pagination. */
  edges: Array<TaxonomyLevelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyLevel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyLevel` edge in the connection. */
export type TaxonomyLevelsEdge = {
  __typename?: 'TaxonomyLevelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyLevel` at the end of the edge. */
  node?: Maybe<TaxonomyLevel>;
};

/** Methods to use when ordering `TaxonomyLevel`. */
export enum TaxonomyLevelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TaxonomyIdAsc = 'TAXONOMY_ID_ASC',
  TaxonomyIdDesc = 'TAXONOMY_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  AliasFieldIdAsc = 'ALIAS_FIELD_ID_ASC',
  AliasFieldIdDesc = 'ALIAS_FIELD_ID_DESC',
  FragmentFieldIdAsc = 'FRAGMENT_FIELD_ID_ASC',
  FragmentFieldIdDesc = 'FRAGMENT_FIELD_ID_DESC',
  FragmentTypeAsc = 'FRAGMENT_TYPE_ASC',
  FragmentTypeDesc = 'FRAGMENT_TYPE_DESC',
  IsNodeAsc = 'IS_NODE_ASC',
  IsNodeDesc = 'IS_NODE_DESC',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  ParentReferenceFieldIdAsc = 'PARENT_REFERENCE_FIELD_ID_ASC',
  ParentReferenceFieldIdDesc = 'PARENT_REFERENCE_FIELD_ID_DESC',
  InheritAsc = 'INHERIT_ASC',
  InheritDesc = 'INHERIT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdParentIdAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdParentIdDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdAliasAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdAliasDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdTypeAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdTypeDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdConfigAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdConfigDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  TaxonomyByTaxonomyIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'TAXONOMY_BY_TAXONOMY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdConfigAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdConfigDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdTypeAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdTypeDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdGroupIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdGroupIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdMultiAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdMultiDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdRequiredAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdRequiredDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdValueCountAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdValueCountDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoSourceAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoSourceDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoOverrideAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoOverrideDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoTransformAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoTransformDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_DESC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdAsc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_ASC',
  ModelFieldByAliasFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdDesc = 'MODEL_FIELD_BY_ALIAS_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdConfigAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdConfigDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdTypeAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdTypeDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdGroupIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdGroupIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdMultiAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdMultiDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdRequiredAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdRequiredDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdValueCountAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdValueCountDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoSourceAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoSourceDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoOverrideAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoOverrideDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoTransformAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoTransformDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_DESC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdAsc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_ASC',
  ModelFieldByFragmentFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdDesc = 'MODEL_FIELD_BY_FRAGMENT_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdTaxonomyIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TAXONOMY_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdTaxonomyIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TAXONOMY_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdModelIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdModelIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdAliasFieldIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_FIELD_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdAliasFieldIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_FIELD_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdFragmentFieldIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_FIELD_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdFragmentFieldIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_FIELD_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdFragmentTypeAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_TYPE_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdFragmentTypeDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__FRAGMENT_TYPE_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdIsNodeAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__IS_NODE_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdIsNodeDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__IS_NODE_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdParentIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdParentIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdParentReferenceFieldIdAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_REFERENCE_FIELD_ID_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdParentReferenceFieldIdDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_REFERENCE_FIELD_ID_DESC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdInheritAsc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERIT_ASC',
  TaxonomyLevelByParentIdAndProjectIdAndEnvironmentIdInheritDesc = 'TAXONOMY_LEVEL_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERIT_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdConfigAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdConfigDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdTypeAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdTypeDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdGroupIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdGroupIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdMultiAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdMultiDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdRequiredAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdRequiredDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdValueCountAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdValueCountDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoSourceAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoSourceDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoOverrideAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoOverrideDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoTransformAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoTransformDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_DESC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdAsc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_ASC',
  ModelFieldByParentReferenceFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdDesc = 'MODEL_FIELD_BY_PARENT_REFERENCE_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_DESC',
  ModelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdCountAsc = 'MODELS_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentIdCountDesc = 'MODELS_BY_DEFAULT_TAXONOMY_LEVEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  TaxonomyLevelsByParentIdAndProjectIdAndEnvironmentIdCountAsc = 'TAXONOMY_LEVELS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  TaxonomyLevelsByParentIdAndProjectIdAndEnvironmentIdCountDesc = 'TAXONOMY_LEVELS_BY_PARENT_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

/** A connection to a list of `TaxonomyListNodesEntry` values. */
export type TaxonomyListNodesEntriesConnection = {
  __typename?: 'TaxonomyListNodesEntriesConnection';
  /** A list of `TaxonomyListNodesEntry` objects. */
  nodes: Array<Maybe<TaxonomyListNodesEntry>>;
  /** A list of edges which contains the `TaxonomyListNodesEntry` and cursor to aid in pagination. */
  edges: Array<TaxonomyListNodesEntriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyListNodesEntry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyListNodesEntry` edge in the connection. */
export type TaxonomyListNodesEntriesEdge = {
  __typename?: 'TaxonomyListNodesEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyListNodesEntry` at the end of the edge. */
  node?: Maybe<TaxonomyListNodesEntry>;
};

export type TaxonomyListNodesEntry = {
  __typename?: 'TaxonomyListNodesEntry';
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Entry` that is related to this `TaxonomyListNodesEntry`. */
  entry?: Maybe<Entry>;
};

/** A filter to be used against `TaxonomyListNodesEntry` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyListNodesEntryFilter = {
  /** Filter by the object’s `path` field. */
  path?: Maybe<StringListFilter>;
  /** Filter by the object’s `entryId` field. */
  entryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entry` relation. */
  entry?: Maybe<EntryFilter>;
  /** A related `entry` exists. */
  entryExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyListNodesEntryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyListNodesEntryFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyListNodesEntryFilter>;
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnTaxonomyForTaxonomyEntryFkeyUsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnTaxonomyForTaxonomyEntryFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnTaxonomyForTaxonomyEnvironmentIdFkeyUsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnTaxonomyForTaxonomyEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnTaxonomyForTaxonomyParentFkeyUsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnTaxonomyForTaxonomyParentFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnTaxonomyLevelForFkTaxonomyLevelTaxonomyUsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnTaxonomyLevelForFkTaxonomyLevelTaxonomyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0UsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to update. */
export type TaxonomyOnWebsiteForFkWebsiteTaxonomyUsingTaxonomyPkeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomy` being updated. */
  patch: UpdateTaxonomyOnWebsiteForFkWebsiteTaxonomyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Input for the nested mutation of `taxonomy` in the `TaxonomyInput` mutation. */
export type TaxonomyParentFkeyInput = {
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyConnect>;
  /** The primary key(s) for `taxonomy` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyTaxonomyPkeyDelete>;
  /** The primary key(s) and patch data for `taxonomy` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<TaxonomyOnTaxonomyForTaxonomyParentFkeyUsingTaxonomyPkeyUpdate>;
  /** A `TaxonomyInput` object that will be created and connected to this object. */
  create?: Maybe<TaxonomyParentFkeyTaxonomyCreateInput>;
};

/** The `taxonomy` to be created by this mutation. */
export type TaxonomyParentFkeyTaxonomyCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias: Scalars['String'];
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** Represents an update to a `Taxonomy`. Fields that are set will be updated. */
export type TaxonomyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

export type TaxonomyPath = {
  __typename?: 'TaxonomyPath';
  id: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Reads a single `Taxonomy` that is related to this `TaxonomyPath`. */
  path?: Maybe<Taxonomy>;
};

/**
 * A condition to be used against `TaxonomyPath` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxonomyPathCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `pathAlias` field. */
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `pathId` field. */
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

/** The fields on `taxonomyPath` to look up the row to connect. */
export type TaxonomyPathFakeAppContentTaxonomyPathPrimaryKeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomyPath` to look up the row to delete. */
export type TaxonomyPathFakeAppContentTaxonomyPathPrimaryKeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against `TaxonomyPath` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyPathFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `pathAlias` field. */
  pathAlias?: Maybe<StringListFilter>;
  /** Filter by the object’s `pathId` field. */
  pathId?: Maybe<UuidListFilter>;
  /** Filter by the object’s `path` relation. */
  path?: Maybe<TaxonomyFilter>;
  /** A related `path` exists. */
  pathExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TaxonomyPathFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TaxonomyPathFilter>>;
  /** Negates the expression. */
  not?: Maybe<TaxonomyPathFilter>;
};

/** The fields on `taxonomyPath` to look up the row to update. */
export type TaxonomyPathOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0UsingFakeAppContentTaxonomyPathPrimaryKeyUpdate = {
  /** An object where the defined keys will be set on the `taxonomyPath` being updated. */
  patch: UpdateTaxonomyPathOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A connection to a list of `TaxonomyPath` values. */
export type TaxonomyPathsConnection = {
  __typename?: 'TaxonomyPathsConnection';
  /** A list of `TaxonomyPath` objects. */
  nodes: Array<Maybe<TaxonomyPath>>;
  /** A list of edges which contains the `TaxonomyPath` and cursor to aid in pagination. */
  edges: Array<TaxonomyPathsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxonomyPath` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxonomyPath` edge in the connection. */
export type TaxonomyPathsEdge = {
  __typename?: 'TaxonomyPathsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxonomyPath` at the end of the edge. */
  node?: Maybe<TaxonomyPath>;
};

/** Methods to use when ordering `TaxonomyPath`. */
export enum TaxonomyPathsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  PathAliasAsc = 'PATH_ALIAS_ASC',
  PathAliasDesc = 'PATH_ALIAS_DESC',
  PathIdAsc = 'PATH_ID_ASC',
  PathIdDesc = 'PATH_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdIdAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdIdDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdParentIdAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdParentIdDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PARENT_ID_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdAliasAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdAliasDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdTypeAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdTypeDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdEntryIdAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdEntryIdDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENTRY_ID_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdConfigAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdConfigDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  TaxonomyByIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'TAXONOMY_BY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC'
}

export enum TaxonomySource {
  Dynamic = 'DYNAMIC',
  Specific = 'SPECIFIC'
}

/** A filter to be used against TaxonomySource fields. All fields are combined with a logical ‘and.’ */
export type TaxonomySourceFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<TaxonomySource>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<TaxonomySource>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<TaxonomySource>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<TaxonomySource>;
  /** Included in the specified list. */
  in?: Maybe<Array<TaxonomySource>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<TaxonomySource>>;
  /** Less than the specified value. */
  lessThan?: Maybe<TaxonomySource>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<TaxonomySource>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<TaxonomySource>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<TaxonomySource>;
};

/** The fields on `taxonomy` to look up the row to connect. */
export type TaxonomyTaxonomyPkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `taxonomy` to look up the row to delete. */
export type TaxonomyTaxonomyPkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A filter to be used against many `Taxonomy` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyToManyTaxonomyFilter = {
  /** Every related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyFilter>;
  /** Some related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyFilter>;
  /** No related `Taxonomy` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyFilter>;
};

/** A filter to be used against many `TaxonomyLevel` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyToManyTaxonomyLevelFilter = {
  /** Every related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TaxonomyLevelFilter>;
  /** Some related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TaxonomyLevelFilter>;
  /** No related `TaxonomyLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TaxonomyLevelFilter>;
};

/** A filter to be used against many `Website` object types. All fields are combined with a logical ‘and.’ */
export type TaxonomyToManyWebsiteFilter = {
  /** Every related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebsiteFilter>;
  /** Some related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebsiteFilter>;
  /** No related `Website` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebsiteFilter>;
};

export enum TaxonomyType {
  Entry = 'ENTRY',
  Redirect = 'REDIRECT',
  Dynamic = 'DYNAMIC',
  External = 'EXTERNAL'
}

/** A filter to be used against TaxonomyType fields. All fields are combined with a logical ‘and.’ */
export type TaxonomyTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<TaxonomyType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<TaxonomyType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<TaxonomyType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<TaxonomyType>;
  /** Included in the specified list. */
  in?: Maybe<Array<TaxonomyType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<TaxonomyType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<TaxonomyType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<TaxonomyType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<TaxonomyType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<TaxonomyType>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against UUID List fields. All fields are combined with a logical ‘and.’ */
export type UuidListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['UUID']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['UUID']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** All input for the `unpublishVersion2` mutation. */
export type UnpublishVersion2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fEntryId?: Maybe<Scalars['UUID']>;
  fTargetId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

/** The output of our `unpublishVersion2` mutation. */
export type UnpublishVersion2Payload = {
  __typename?: 'UnpublishVersion2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAccountByKey` mutation. */
export type UpdateAccountByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Account` being updated. */
  patch: AccountPatch;
  key: Scalars['String'];
};

/** The output of our update `Account` mutation. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Account` that was updated by this mutation. */
  account?: Maybe<Account>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge?: Maybe<AccountsEdge>;
};


/** The output of our update `Account` mutation. */
export type UpdateAccountPayloadAccountEdgeArgs = {
  orderBy?: Maybe<Array<AccountsOrderBy>>;
};

/** All input for the `updateEntry` mutation. */
export type UpdateEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Entry` being updated. */
  patch: EntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `Entry` mutation. */
export type UpdateEntryPayload = {
  __typename?: 'UpdateEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Entry` that was updated by this mutation. */
  entry?: Maybe<Entry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Entry`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Entry`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `EntryVersion` that is related to this `Entry`. */
  latestVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `Entry`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Entry`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `Entry`. May be used by Relay 1. */
  entryEdge?: Maybe<EntriesEdge>;
};


/** The output of our update `Entry` mutation. */
export type UpdateEntryPayloadEntryEdgeArgs = {
  orderBy?: Maybe<Array<EntriesOrderBy>>;
};

/** All input for the `updateEntryPublish` mutation. */
export type UpdateEntryPublishInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EntryPublish` being updated. */
  patch: EntryPublishPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId: Scalars['UUID'];
};

/** The output of our update `EntryPublish` mutation. */
export type UpdateEntryPublishPayload = {
  __typename?: 'UpdateEntryPublishPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryPublish` that was updated by this mutation. */
  entryPublish?: Maybe<EntryPublish>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryPublish`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryPublish`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `PublishTarget` that is related to this `EntryPublish`. */
  target?: Maybe<PublishTarget>;
  /** Reads a single `EntryVersion` that is related to this `EntryPublish`. */
  entryVersion?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryPublish`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryPublish`. May be used by Relay 1. */
  entryPublishEdge?: Maybe<EntryPublishesEdge>;
};


/** The output of our update `EntryPublish` mutation. */
export type UpdateEntryPublishPayloadEntryPublishEdgeArgs = {
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
};

/** All input for the `updateEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndex` mutation. */
export type UpdateEntryVersionByEntryIdAndProjectIdAndEnvironmentIdAndIndexInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EntryVersion` being updated. */
  patch: EntryVersionPatch;
  entryId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  index: Scalars['Int'];
};

/** All input for the `updateEntryVersion` mutation. */
export type UpdateEntryVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EntryVersion` being updated. */
  patch: EntryVersionPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `EntryVersion` mutation. */
export type UpdateEntryVersionPayload = {
  __typename?: 'UpdateEntryVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EntryVersion` that was updated by this mutation. */
  entryVersion?: Maybe<EntryVersion>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Entry` that is related to this `EntryVersion`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `EntryVersion`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Value` that is related to this `EntryVersion`. */
  value?: Maybe<Value>;
  /** Reads a single `EntryVersion` that is related to this `EntryVersion`. */
  parentProjectEnvironment?: Maybe<EntryVersion>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  createdBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `EntryVersion`. */
  modifiedBy?: Maybe<User>;
  /** An edge for our `EntryVersion`. May be used by Relay 1. */
  entryVersionEdge?: Maybe<EntryVersionsEdge>;
};


/** The output of our update `EntryVersion` mutation. */
export type UpdateEntryVersionPayloadEntryVersionEdgeArgs = {
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
};

/** All input for the `updateMediaFolder` mutation. */
export type UpdateMediaFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MediaFolder` being updated. */
  patch: MediaFolderPatch;
  id: Scalars['UUID'];
};

/** The output of our update `MediaFolder` mutation. */
export type UpdateMediaFolderPayload = {
  __typename?: 'UpdateMediaFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaFolder` that was updated by this mutation. */
  mediaFolder?: Maybe<MediaFolder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaFolder`. */
  parent?: Maybe<MediaFolder>;
  /** An edge for our `MediaFolder`. May be used by Relay 1. */
  mediaFolderEdge?: Maybe<MediaFoldersEdge>;
};


/** The output of our update `MediaFolder` mutation. */
export type UpdateMediaFolderPayloadMediaFolderEdgeArgs = {
  orderBy?: Maybe<Array<MediaFoldersOrderBy>>;
};

/** All input for the `updateMediaItem` mutation. */
export type UpdateMediaItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MediaItem` being updated. */
  patch: MediaItemPatch;
  id: Scalars['UUID'];
};

/** The output of our update `MediaItem` mutation. */
export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MediaItem` that was updated by this mutation. */
  mediaItem?: Maybe<MediaItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MediaFolder` that is related to this `MediaItem`. */
  folder?: Maybe<MediaFolder>;
  /** An edge for our `MediaItem`. May be used by Relay 1. */
  mediaItemEdge?: Maybe<MediaItemsEdge>;
};


/** The output of our update `MediaItem` mutation. */
export type UpdateMediaItemPayloadMediaItemEdgeArgs = {
  orderBy?: Maybe<Array<MediaItemsOrderBy>>;
};

/** All input for the `updateMenuItemEntry` mutation. */
export type UpdateMenuItemEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MenuItemEntry` being updated. */
  patch: MenuItemEntryPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `MenuItemEntry` mutation. */
export type UpdateMenuItemEntryPayload = {
  __typename?: 'UpdateMenuItemEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemEntry` that was updated by this mutation. */
  menuItemEntry?: Maybe<MenuItemEntry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemEntry`. */
  entry?: Maybe<MenuItem>;
  /** Reads a single `Entry` that is related to this `MenuItemEntry`. */
  entryProjectEnvironment?: Maybe<Entry>;
  /** An edge for our `MenuItemEntry`. May be used by Relay 1. */
  menuItemEntryEdge?: Maybe<MenuItemEntriesEdge>;
};


/** The output of our update `MenuItemEntry` mutation. */
export type UpdateMenuItemEntryPayloadMenuItemEntryEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemEntriesOrderBy>>;
};

/** All input for the `updateMenuItem` mutation. */
export type UpdateMenuItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MenuItem` being updated. */
  patch: MenuItemPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `MenuItem` mutation. */
export type UpdateMenuItemPayload = {
  __typename?: 'UpdateMenuItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItem` that was updated by this mutation. */
  menuItem?: Maybe<MenuItem>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `MenuItem`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `MenuItem` that is related to this `MenuItem`. */
  parentProjectEnvironment?: Maybe<MenuItem>;
  /** An edge for our `MenuItem`. May be used by Relay 1. */
  menuItemEdge?: Maybe<MenuItemsEdge>;
};


/** The output of our update `MenuItem` mutation. */
export type UpdateMenuItemPayloadMenuItemEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemsOrderBy>>;
};

/** All input for the `updateMenuItemView` mutation. */
export type UpdateMenuItemViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MenuItemView` being updated. */
  patch: MenuItemViewPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `MenuItemView` mutation. */
export type UpdateMenuItemViewPayload = {
  __typename?: 'UpdateMenuItemViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MenuItemView` that was updated by this mutation. */
  menuItemView?: Maybe<MenuItemView>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `MenuItem` that is related to this `MenuItemView`. */
  view?: Maybe<MenuItem>;
  /** Reads a single `Model` that is related to this `MenuItemView`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** An edge for our `MenuItemView`. May be used by Relay 1. */
  menuItemViewEdge?: Maybe<MenuItemViewsEdge>;
};


/** The output of our update `MenuItemView` mutation. */
export type UpdateMenuItemViewPayloadMenuItemViewEdgeArgs = {
  orderBy?: Maybe<Array<MenuItemViewsOrderBy>>;
};

/** All input for the `updateModelFieldGroup` mutation. */
export type UpdateModelFieldGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ModelFieldGroup` being updated. */
  patch: ModelFieldGroupPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `ModelFieldGroup` mutation. */
export type UpdateModelFieldGroupPayload = {
  __typename?: 'UpdateModelFieldGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelFieldGroup` that was updated by this mutation. */
  modelFieldGroup?: Maybe<ModelFieldGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelFieldGroup`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `ModelFieldGroup`. */
  model?: Maybe<Model>;
  /** An edge for our `ModelFieldGroup`. May be used by Relay 1. */
  modelFieldGroupEdge?: Maybe<ModelFieldGroupsEdge>;
};


/** The output of our update `ModelFieldGroup` mutation. */
export type UpdateModelFieldGroupPayloadModelFieldGroupEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldGroupsOrderBy>>;
};

/** All input for the `updateModelField` mutation. */
export type UpdateModelFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ModelField` being updated. */
  patch: ModelFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `ModelField` mutation. */
export type UpdateModelFieldPayload = {
  __typename?: 'UpdateModelFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelField` that was updated by this mutation. */
  modelField?: Maybe<ModelField>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelField`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `ModelFieldGroup` that is related to this `ModelField`. */
  group?: Maybe<ModelFieldGroup>;
  /** Reads a single `ModelField` that is related to this `ModelField`. */
  autoFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `ModelField`. May be used by Relay 1. */
  modelFieldEdge?: Maybe<ModelFieldsEdge>;
};


/** The output of our update `ModelField` mutation. */
export type UpdateModelFieldPayloadModelFieldEdgeArgs = {
  orderBy?: Maybe<Array<ModelFieldsOrderBy>>;
};

/** All input for the `updateModel` mutation. */
export type UpdateModelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Model` being updated. */
  patch: ModelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** All input for the `updateModelParent` mutation. */
export type UpdateModelParentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ModelParent` being updated. */
  patch: ModelParentPatch;
  modelId: Scalars['UUID'];
  parentId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `ModelParent` mutation. */
export type UpdateModelParentPayload = {
  __typename?: 'UpdateModelParentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ModelParent` that was updated by this mutation. */
  modelParent?: Maybe<ModelParent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  model?: Maybe<Model>;
  /** Reads a single `Model` that is related to this `ModelParent`. */
  parent?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `ModelParent`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `ModelParent`. May be used by Relay 1. */
  modelParentEdge?: Maybe<ModelParentsEdge>;
};


/** The output of our update `ModelParent` mutation. */
export type UpdateModelParentPayloadModelParentEdgeArgs = {
  orderBy?: Maybe<Array<ModelParentsOrderBy>>;
};

/** The output of our update `Model` mutation. */
export type UpdateModelPayload = {
  __typename?: 'UpdateModelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Model` that was updated by this mutation. */
  model?: Maybe<Model>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `Model`. */
  valueBase?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `Model`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `TaxonomyLevel` that is related to this `Model`. */
  defaultTaxonomyLevelProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** An edge for our `Model`. May be used by Relay 1. */
  modelEdge?: Maybe<ModelsEdge>;
};


/** The output of our update `Model` mutation. */
export type UpdateModelPayloadModelEdgeArgs = {
  orderBy?: Maybe<Array<ModelsOrderBy>>;
};

/** All input for the `updateModelSnapshot` mutation. */
export type UpdateModelSnapshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fModelId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
};

/** The output of our `updateModelSnapshot` mutation. */
export type UpdateModelSnapshotPayload = {
  __typename?: 'UpdateModelSnapshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProjectApiKey` mutation. */
export type UpdateProjectApiKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fId?: Maybe<Scalars['UUID']>;
  fTarget?: Maybe<Scalars['UUID']>;
};

/** The output of our `updateProjectApiKey` mutation. */
export type UpdateProjectApiKeyPayload = {
  __typename?: 'UpdateProjectApiKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProjectByAccountIdAndKey` mutation. */
export type UpdateProjectByAccountIdAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch;
  accountId: Scalars['UUID'];
  key: Scalars['String'];
};

/** All input for the `updateProjectDefaultEnvironment` mutation. */
export type UpdateProjectDefaultEnvironmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fProject?: Maybe<Scalars['UUID']>;
  fEnvironment?: Maybe<Scalars['UUID']>;
};

/** The output of our `updateProjectDefaultEnvironment` mutation. */
export type UpdateProjectDefaultEnvironmentPayload = {
  __typename?: 'UpdateProjectDefaultEnvironmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProjectEnvironmentByKeyAndProjectId` mutation. */
export type UpdateProjectEnvironmentByKeyAndProjectIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectEnvironment` being updated. */
  patch: ProjectEnvironmentPatch;
  key: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** All input for the `updateProjectEnvironment` mutation. */
export type UpdateProjectEnvironmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectEnvironment` being updated. */
  patch: ProjectEnvironmentPatch;
  id: Scalars['UUID'];
};

/** The output of our update `ProjectEnvironment` mutation. */
export type UpdateProjectEnvironmentPayload = {
  __typename?: 'UpdateProjectEnvironmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectEnvironment` that was updated by this mutation. */
  projectEnvironment?: Maybe<ProjectEnvironment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `ProjectEnvironment`. */
  project?: Maybe<Project>;
  /** An edge for our `ProjectEnvironment`. May be used by Relay 1. */
  projectEnvironmentEdge?: Maybe<ProjectEnvironmentsEdge>;
};


/** The output of our update `ProjectEnvironment` mutation. */
export type UpdateProjectEnvironmentPayloadProjectEnvironmentEdgeArgs = {
  orderBy?: Maybe<Array<ProjectEnvironmentsOrderBy>>;
};

/** The output of our update `Project` mutation. */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Project` that was updated by this mutation. */
  project?: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Account` that is related to this `Project`. */
  account?: Maybe<Account>;
  /** Reads a single `ProjectEnvironment` that is related to this `Project`. */
  defaultEnvironment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
};


/** The output of our update `Project` mutation. */
export type UpdateProjectPayloadProjectEdgeArgs = {
  orderBy?: Maybe<Array<ProjectsOrderBy>>;
};

/** All input for the `updateProjectToken` mutation. */
export type UpdateProjectTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectToken` being updated. */
  patch: ProjectTokenPatch;
  id: Scalars['UUID'];
};

/** The output of our update `ProjectToken` mutation. */
export type UpdateProjectTokenPayload = {
  __typename?: 'UpdateProjectTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProjectToken` that was updated by this mutation. */
  projectToken?: Maybe<ProjectToken>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `PublishTarget` that is related to this `ProjectToken`. */
  target?: Maybe<PublishTarget>;
  /** An edge for our `ProjectToken`. May be used by Relay 1. */
  projectTokenEdge?: Maybe<ProjectTokensEdge>;
};


/** The output of our update `ProjectToken` mutation. */
export type UpdateProjectTokenPayloadProjectTokenEdgeArgs = {
  orderBy?: Maybe<Array<ProjectTokensOrderBy>>;
};

/** All input for the `updatePublishTarget` mutation. */
export type UpdatePublishTargetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PublishTarget` being updated. */
  patch: PublishTargetPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The output of our update `PublishTarget` mutation. */
export type UpdatePublishTargetPayload = {
  __typename?: 'UpdatePublishTargetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PublishTarget` that was updated by this mutation. */
  publishTarget?: Maybe<PublishTarget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Project` that is related to this `PublishTarget`. */
  project?: Maybe<Project>;
  /** An edge for our `PublishTarget`. May be used by Relay 1. */
  publishTargetEdge?: Maybe<PublishTargetsEdge>;
};


/** The output of our update `PublishTarget` mutation. */
export type UpdatePublishTargetPayloadPublishTargetEdgeArgs = {
  orderBy?: Maybe<Array<PublishTargetsOrderBy>>;
};

/** All input for the `updateTaxonomy` mutation. */
export type UpdateTaxonomyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Taxonomy` being updated. */
  patch: TaxonomyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** All input for the `updateTaxonomyLevel` mutation. */
export type UpdateTaxonomyLevelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TaxonomyLevel` being updated. */
  patch: TaxonomyLevelPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `TaxonomyLevel` mutation. */
export type UpdateTaxonomyLevelPayload = {
  __typename?: 'UpdateTaxonomyLevelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TaxonomyLevel` that was updated by this mutation. */
  taxonomyLevel?: Maybe<TaxonomyLevel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `TaxonomyLevel`. */
  taxonomy?: Maybe<Taxonomy>;
  /** Reads a single `ProjectEnvironment` that is related to this `TaxonomyLevel`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Model` that is related to this `TaxonomyLevel`. */
  modelProjectEnvironment?: Maybe<Model>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  aliasFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  fragmentFieldProjectEnvironment?: Maybe<ModelField>;
  /** Reads a single `TaxonomyLevel` that is related to this `TaxonomyLevel`. */
  parentProjectEnvironment?: Maybe<TaxonomyLevel>;
  /** Reads a single `ModelField` that is related to this `TaxonomyLevel`. */
  parentReferenceFieldProjectEnvironment?: Maybe<ModelField>;
  /** An edge for our `TaxonomyLevel`. May be used by Relay 1. */
  taxonomyLevelEdge?: Maybe<TaxonomyLevelsEdge>;
};


/** The output of our update `TaxonomyLevel` mutation. */
export type UpdateTaxonomyLevelPayloadTaxonomyLevelEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomyLevelsOrderBy>>;
};

/** The output of our update `Taxonomy` mutation. */
export type UpdateTaxonomyPayload = {
  __typename?: 'UpdateTaxonomyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Taxonomy` that was updated by this mutation. */
  taxonomy?: Maybe<Taxonomy>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Taxonomy`. */
  parent?: Maybe<Taxonomy>;
  /** Reads a single `Entry` that is related to this `Taxonomy`. */
  entry?: Maybe<Entry>;
  /** Reads a single `ProjectEnvironment` that is related to this `Taxonomy`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Taxonomy`. May be used by Relay 1. */
  taxonomyEdge?: Maybe<TaxonomiesEdge>;
};


/** The output of our update `Taxonomy` mutation. */
export type UpdateTaxonomyPayloadTaxonomyEdgeArgs = {
  orderBy?: Maybe<Array<TaxonomiesOrderBy>>;
};

/** All input for the `updateValueField` mutation. */
export type UpdateValueFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ValueField` being updated. */
  patch: ValueFieldPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `ValueField` mutation. */
export type UpdateValueFieldPayload = {
  __typename?: 'UpdateValueFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ValueField` that was updated by this mutation. */
  valueField?: Maybe<ValueField>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  value?: Maybe<Value>;
  /** Reads a single `ModelField` that is related to this `ValueField`. */
  modelField?: Maybe<ModelField>;
  /** Reads a single `MediaItem` that is related to this `ValueField`. */
  valueMedia?: Maybe<MediaItem>;
  /** Reads a single `Entry` that is related to this `ValueField`. */
  valueEntryProjectEnvironment?: Maybe<Entry>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  valueValue?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `ValueField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `ValueField`. */
  project?: Maybe<Project>;
  /** An edge for our `ValueField`. May be used by Relay 1. */
  valueFieldEdge?: Maybe<ValueFieldsEdge>;
};


/** The output of our update `ValueField` mutation. */
export type UpdateValueFieldPayloadValueFieldEdgeArgs = {
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
};

/** All input for the `updateValue` mutation. */
export type UpdateValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Value` being updated. */
  patch: ValuePatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The output of our update `Value` mutation. */
export type UpdateValuePayload = {
  __typename?: 'UpdateValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Value` that was updated by this mutation. */
  value?: Maybe<Value>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Model` that is related to this `Value`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Value`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `Value`. */
  project?: Maybe<Project>;
  /** An edge for our `Value`. May be used by Relay 1. */
  valueEdge?: Maybe<ValuesEdge>;
};


/** The output of our update `Value` mutation. */
export type UpdateValuePayloadValueEdgeArgs = {
  orderBy?: Maybe<Array<ValuesOrderBy>>;
};

/** All input for the `updateWebhook` mutation. */
export type UpdateWebhookInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Webhook` being updated. */
  patch: WebhookPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateWebhookLog` mutation. */
export type UpdateWebhookLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `WebhookLog` being updated. */
  patch: WebhookLogPatch;
  id: Scalars['UUID'];
};

/** The output of our update `WebhookLog` mutation. */
export type UpdateWebhookLogPayload = {
  __typename?: 'UpdateWebhookLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookLog` that was updated by this mutation. */
  webhookLog?: Maybe<WebhookLog>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookLog`. */
  webhook?: Maybe<Webhook>;
  /** Reads a single `WebhookRun` that is related to this `WebhookLog`. */
  webhookRun?: Maybe<WebhookRun>;
  /** An edge for our `WebhookLog`. May be used by Relay 1. */
  webhookLogEdge?: Maybe<WebhookLogsEdge>;
};


/** The output of our update `WebhookLog` mutation. */
export type UpdateWebhookLogPayloadWebhookLogEdgeArgs = {
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
};

/** The output of our update `Webhook` mutation. */
export type UpdateWebhookPayload = {
  __typename?: 'UpdateWebhookPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Webhook` that was updated by this mutation. */
  webhook?: Maybe<Webhook>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Webhook`. May be used by Relay 1. */
  webhookEdge?: Maybe<WebhooksEdge>;
};


/** The output of our update `Webhook` mutation. */
export type UpdateWebhookPayloadWebhookEdgeArgs = {
  orderBy?: Maybe<Array<WebhooksOrderBy>>;
};

/** All input for the `updateWebhookRun` mutation. */
export type UpdateWebhookRunInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `WebhookRun` being updated. */
  patch: WebhookRunPatch;
  id: Scalars['UUID'];
};

/** The output of our update `WebhookRun` mutation. */
export type UpdateWebhookRunPayload = {
  __typename?: 'UpdateWebhookRunPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WebhookRun` that was updated by this mutation. */
  webhookRun?: Maybe<WebhookRun>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Webhook` that is related to this `WebhookRun`. */
  webhook?: Maybe<Webhook>;
  /** An edge for our `WebhookRun`. May be used by Relay 1. */
  webhookRunEdge?: Maybe<WebhookRunsEdge>;
};


/** The output of our update `WebhookRun` mutation. */
export type UpdateWebhookRunPayloadWebhookRunEdgeArgs = {
  orderBy?: Maybe<Array<WebhookRunsOrderBy>>;
};

/** All input for the `updateWebsite` mutation. */
export type UpdateWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Website` being updated. */
  patch: WebsitePatch;
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** The output of our update `Website` mutation. */
export type UpdateWebsitePayload = {
  __typename?: 'UpdateWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was updated by this mutation. */
  website?: Maybe<Website>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Taxonomy` that is related to this `Website`. */
  projectEnvironmentTaxonomy?: Maybe<Taxonomy>;
  /** Reads a single `PublishTarget` that is related to this `Website`. */
  projectTarget?: Maybe<PublishTarget>;
  /** Reads a single `ProjectEnvironment` that is related to this `Website`. */
  environment?: Maybe<ProjectEnvironment>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our update `Website` mutation. */
export type UpdateWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Entry`. */
  entriesByCreatedById: EntriesConnection;
  /** Reads and enables pagination through a set of `Entry`. */
  entriesByModifiedById: EntriesConnection;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishesByCreatedById: EntryPublishesConnection;
  /** Reads and enables pagination through a set of `EntryPublish`. */
  entryPublishesByModifiedById: EntryPublishesConnection;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersionsByCreatedById: EntryVersionsConnection;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersionsByModifiedById: EntryVersionsConnection;
};


export type UserEntriesByCreatedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


export type UserEntriesByModifiedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntriesOrderBy>>;
  condition?: Maybe<EntryCondition>;
  filter?: Maybe<EntryFilter>;
};


export type UserEntryPublishesByCreatedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


export type UserEntryPublishesByModifiedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryPublishesOrderBy>>;
  condition?: Maybe<EntryPublishCondition>;
  filter?: Maybe<EntryPublishFilter>;
};


export type UserEntryVersionsByCreatedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


export type UserEntryVersionsByModifiedByIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `entriesByCreatedById` relation. */
  entriesByCreatedById?: Maybe<UserToManyEntryFilter>;
  /** Some related `entriesByCreatedById` exist. */
  entriesByCreatedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entriesByModifiedById` relation. */
  entriesByModifiedById?: Maybe<UserToManyEntryFilter>;
  /** Some related `entriesByModifiedById` exist. */
  entriesByModifiedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryPublishesByCreatedById` relation. */
  entryPublishesByCreatedById?: Maybe<UserToManyEntryPublishFilter>;
  /** Some related `entryPublishesByCreatedById` exist. */
  entryPublishesByCreatedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryPublishesByModifiedById` relation. */
  entryPublishesByModifiedById?: Maybe<UserToManyEntryPublishFilter>;
  /** Some related `entryPublishesByModifiedById` exist. */
  entryPublishesByModifiedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryVersionsByCreatedById` relation. */
  entryVersionsByCreatedById?: Maybe<UserToManyEntryVersionFilter>;
  /** Some related `entryVersionsByCreatedById` exist. */
  entryVersionsByCreatedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `entryVersionsByModifiedById` relation. */
  entryVersionsByModifiedById?: Maybe<UserToManyEntryVersionFilter>;
  /** Some related `entryVersionsByModifiedById` exist. */
  entryVersionsByModifiedByIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  entriesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey0InverseInput>;
  entriesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryForeignKey1InverseInput>;
  entryPublishesToCreatedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey0InverseInput>;
  entryPublishesToModifiedByIdUsingId?: Maybe<FakeAppContentEntryPublishForeignKey1InverseInput>;
  entryVersionsToCreatedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey0InverseInput>;
  entryVersionsToModifiedByIdUsingId?: Maybe<FakeAppContentEntryVersionForeignKey1InverseInput>;
};

/** A filter to be used against many `Entry` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyEntryFilter = {
  /** Every related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryFilter>;
  /** Some related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryFilter>;
  /** No related `Entry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryFilter>;
};

/** A filter to be used against many `EntryPublish` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyEntryPublishFilter = {
  /** Every related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryPublishFilter>;
  /** Some related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryPublishFilter>;
  /** No related `EntryPublish` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryPublishFilter>;
};

/** A filter to be used against many `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyEntryVersionFilter = {
  /** Every related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryVersionFilter>;
  /** Some related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryVersionFilter>;
  /** No related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryVersionFilter>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EntriesByCreatedByIdCountAsc = 'ENTRIES_BY_CREATED_BY_ID__COUNT_ASC',
  EntriesByCreatedByIdCountDesc = 'ENTRIES_BY_CREATED_BY_ID__COUNT_DESC',
  EntriesByModifiedByIdCountAsc = 'ENTRIES_BY_MODIFIED_BY_ID__COUNT_ASC',
  EntriesByModifiedByIdCountDesc = 'ENTRIES_BY_MODIFIED_BY_ID__COUNT_DESC',
  EntryPublishesByCreatedByIdCountAsc = 'ENTRY_PUBLISHES_BY_CREATED_BY_ID__COUNT_ASC',
  EntryPublishesByCreatedByIdCountDesc = 'ENTRY_PUBLISHES_BY_CREATED_BY_ID__COUNT_DESC',
  EntryPublishesByModifiedByIdCountAsc = 'ENTRY_PUBLISHES_BY_MODIFIED_BY_ID__COUNT_ASC',
  EntryPublishesByModifiedByIdCountDesc = 'ENTRY_PUBLISHES_BY_MODIFIED_BY_ID__COUNT_DESC',
  EntryVersionsByCreatedByIdCountAsc = 'ENTRY_VERSIONS_BY_CREATED_BY_ID__COUNT_ASC',
  EntryVersionsByCreatedByIdCountDesc = 'ENTRY_VERSIONS_BY_CREATED_BY_ID__COUNT_DESC',
  EntryVersionsByModifiedByIdCountAsc = 'ENTRY_VERSIONS_BY_MODIFIED_BY_ID__COUNT_ASC',
  EntryVersionsByModifiedByIdCountDesc = 'ENTRY_VERSIONS_BY_MODIFIED_BY_ID__COUNT_DESC'
}

export type Value = {
  __typename?: 'Value';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  modelId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId: Scalars['UUID'];
  /** Reads a single `Model` that is related to this `Value`. */
  model?: Maybe<Model>;
  /** Reads a single `ProjectEnvironment` that is related to this `Value`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `Value`. */
  project?: Maybe<Project>;
  /** Reads and enables pagination through a set of `EntryVersion`. */
  entryVersions: EntryVersionsConnection;
  /** Reads and enables pagination through a set of `Model`. */
  modelValueBase: ModelsConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  fields: ValueFieldsConnection;
  /** Reads and enables pagination through a set of `ValueField`. */
  valueValues: ValueFieldsConnection;
};


export type ValueEntryVersionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EntryVersionsOrderBy>>;
  condition?: Maybe<EntryVersionCondition>;
  filter?: Maybe<EntryVersionFilter>;
};


export type ValueModelValueBaseArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ModelsOrderBy>>;
  condition?: Maybe<ModelCondition>;
  filter?: Maybe<ModelFilter>;
};


export type ValueFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};


export type ValueValueValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ValueFieldsOrderBy>>;
  condition?: Maybe<ValueFieldCondition>;
  filter?: Maybe<ValueFieldFilter>;
};

/** A condition to be used against `Value` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ValueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelId` field. */
  modelId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ValueInput` mutation. */
export type ValueEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnValueForValueEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnValueForValueEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ValueEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `value` in the `ProjectEnvironmentInput` mutation. */
export type ValueEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `value` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyConnect>>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyDelete>>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueOnValueForValueEnvironmentIdFkeyUsingValuePkeyUpdate>>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueEnvironmentIdFkeyValueCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ValueEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `value` to be created by this mutation. */
export type ValueEnvironmentIdFkeyValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

export type ValueField = {
  __typename?: 'ValueField';
  id: Scalars['UUID'];
  valueId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  modelFieldId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  modifiedAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId: Scalars['UUID'];
  /** Reads a single `Value` that is related to this `ValueField`. */
  value?: Maybe<Value>;
  /** Reads a single `ModelField` that is related to this `ValueField`. */
  modelField?: Maybe<ModelField>;
  /** Reads a single `MediaItem` that is related to this `ValueField`. */
  valueMedia?: Maybe<MediaItem>;
  /** Reads a single `Entry` that is related to this `ValueField`. */
  valueEntryProjectEnvironment?: Maybe<Entry>;
  /** Reads a single `Value` that is related to this `ValueField`. */
  valueValue?: Maybe<Value>;
  /** Reads a single `ProjectEnvironment` that is related to this `ValueField`. */
  environment?: Maybe<ProjectEnvironment>;
  /** Reads a single `Project` that is related to this `ValueField`. */
  project?: Maybe<Project>;
};

/**
 * A condition to be used against `ValueField` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ValueFieldCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `valueId` field. */
  valueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `modelFieldId` field. */
  modelFieldId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `index` field. */
  index?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `hint` field. */
  hint?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `valueScalar` field. */
  valueScalar?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `valueMediaId` field. */
  valueMediaId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `valueEntryId` field. */
  valueEntryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `valueValueId` field. */
  valueValueId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
};

/** The `entry` to be created by this mutation. */
export type ValueFieldEntryFkeyEntryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** Input for the nested mutation of `entry` in the `ValueFieldInput` mutation. */
export type ValueFieldEntryFkeyInput = {
  /** The primary key(s) for `entry` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyConnect>;
  /** The primary key(s) for `entry` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<EntryEntryPkeyDelete>;
  /** The primary key(s) and patch data for `entry` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<EntryOnValueFieldForValueFieldEntryFkeyUsingEntryPkeyUpdate>;
  /** A `EntryInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldEntryFkeyEntryCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `EntryInput` mutation. */
export type ValueFieldEntryFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldEntryFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldEntryFkeyValueFieldCreateInput>>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldEntryFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** Input for the nested mutation of `projectEnvironment` in the `ValueFieldInput` mutation. */
export type ValueFieldEnvironmentIdFkeyInput = {
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  connectByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyConnect>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteById?: Maybe<ProjectEnvironmentProjectEnvironmentPkeyDelete>;
  /** The primary key(s) for `projectEnvironment` for the far side of the relationship. */
  deleteByKeyAndProjectId?: Maybe<ProjectEnvironmentUqProjectEnvironmentKeyDelete>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateById?: Maybe<ProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyUsingProjectEnvironmentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEnvironment` for the far side of the relationship. */
  updateByKeyAndProjectId?: Maybe<ProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyUsingUqProjectEnvironmentKeyUpdate>;
  /** A `ProjectEnvironmentInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldEnvironmentIdFkeyProjectEnvironmentCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `ProjectEnvironmentInput` mutation. */
export type ValueFieldEnvironmentIdFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldEnvironmentIdFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldEnvironmentIdFkeyValueFieldCreateInput>>;
};

/** The `projectEnvironment` to be created by this mutation. */
export type ValueFieldEnvironmentIdFkeyProjectEnvironmentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldEnvironmentIdFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** A filter to be used against `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type ValueFieldFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `valueId` field. */
  valueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelFieldId` field. */
  modelFieldId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `index` field. */
  index?: Maybe<IntFilter>;
  /** Filter by the object’s `hint` field. */
  hint?: Maybe<StringFilter>;
  /** Filter by the object’s `valueScalar` field. */
  valueScalar?: Maybe<JsonFilter>;
  /** Filter by the object’s `valueMediaId` field. */
  valueMediaId?: Maybe<UuidFilter>;
  /** Filter by the object’s `valueEntryId` field. */
  valueEntryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `valueValueId` field. */
  valueValueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `value` relation. */
  value?: Maybe<ValueFilter>;
  /** Filter by the object’s `modelField` relation. */
  modelField?: Maybe<ModelFieldFilter>;
  /** Filter by the object’s `valueMedia` relation. */
  valueMedia?: Maybe<MediaItemFilter>;
  /** A related `valueMedia` exists. */
  valueMediaExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueEntryProjectEnvironment` relation. */
  valueEntryProjectEnvironment?: Maybe<EntryFilter>;
  /** A related `valueEntryProjectEnvironment` exists. */
  valueEntryProjectEnvironmentExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueValue` relation. */
  valueValue?: Maybe<ValueFilter>;
  /** A related `valueValue` exists. */
  valueValueExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `project` relation. */
  project?: Maybe<ProjectFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ValueFieldFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ValueFieldFilter>>;
  /** Negates the expression. */
  not?: Maybe<ValueFieldFilter>;
};

/** An input for mutations affecting `ValueField` */
export type ValueFieldInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** Input for the nested mutation of `modelField` in the `ValueFieldInput` mutation. */
export type ValueFieldModelFieldFkeyInput = {
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyConnect>;
  /** The primary key(s) for `modelField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldModelFieldPkeyDelete>;
  /** The primary key(s) and patch data for `modelField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelFieldOnValueFieldForValueFieldModelFieldFkeyUsingModelFieldPkeyUpdate>;
  /** A `ModelFieldInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldModelFieldFkeyModelFieldCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `ModelFieldInput` mutation. */
export type ValueFieldModelFieldFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldModelFieldFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldModelFieldFkeyValueFieldCreateInput>>;
};

/** The `modelField` to be created by this mutation. */
export type ValueFieldModelFieldFkeyModelFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldModelFieldFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForFakeAppContentValueFieldForeignKey0UsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForFakeAppContentValueFieldForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldEntryFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldEntryFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldEnvironmentIdFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldModelFieldFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldModelFieldFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldValueMediaIdFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldValueMediaIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to update. */
export type ValueFieldOnValueFieldForValueFieldValueValueFkeyUsingValueFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `valueField` being updated. */
  patch: UpdateValueFieldOnValueFieldForValueFieldValueValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `ValueField`. Fields that are set will be updated. */
export type ValueFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** All input for the `valueFieldSnapshot` mutation. */
export type ValueFieldSnapshotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fEntryId?: Maybe<Scalars['UUID']>;
  fValueId?: Maybe<Scalars['UUID']>;
  fProjectId?: Maybe<Scalars['UUID']>;
  fEnvironmentId?: Maybe<Scalars['UUID']>;
  fFieldId?: Maybe<Scalars['UUID']>;
};

/** The output of our `valueFieldSnapshot` mutation. */
export type ValueFieldSnapshotPayload = {
  __typename?: 'ValueFieldSnapshotPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['JSON']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The fields on `valueField` to look up the row to connect. */
export type ValueFieldValueFieldPkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `valueField` to look up the row to delete. */
export type ValueFieldValueFieldPkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Input for the nested mutation of `value` in the `ValueFieldInput` mutation. */
export type ValueFieldValueFkeyInput = {
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyConnect>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyDelete>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ValueOnValueFieldForValueFieldValueFkeyUsingValuePkeyUpdate>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldValueFkeyValueCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `ValueInput` mutation. */
export type ValueFieldValueFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldValueFkeyValueFieldCreateInput>>;
};

/** The `value` to be created by this mutation. */
export type ValueFieldValueFkeyValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldValueFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** Input for the nested mutation of `mediaItem` in the `ValueFieldInput` mutation. */
export type ValueFieldValueMediaIdFkeyInput = {
  /** The primary key(s) for `mediaItem` for the far side of the relationship. */
  connectById?: Maybe<MediaItemMediaItemPkeyConnect>;
  /** The primary key(s) for `mediaItem` for the far side of the relationship. */
  deleteById?: Maybe<MediaItemMediaItemPkeyDelete>;
  /** The primary key(s) and patch data for `mediaItem` for the far side of the relationship. */
  updateById?: Maybe<MediaItemOnValueFieldForValueFieldValueMediaIdFkeyUsingMediaItemPkeyUpdate>;
  /** A `MediaItemInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldValueMediaIdFkeyMediaItemCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `MediaItemInput` mutation. */
export type ValueFieldValueMediaIdFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldValueMediaIdFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldValueMediaIdFkeyValueFieldCreateInput>>;
};

/** The `mediaItem` to be created by this mutation. */
export type ValueFieldValueMediaIdFkeyMediaItemCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  folderId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  contentType: Scalars['String'];
  size: Scalars['Int'];
  storage: Scalars['JSON'];
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldValueMediaIdFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** Input for the nested mutation of `value` in the `ValueFieldInput` mutation. */
export type ValueFieldValueValueFkeyInput = {
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyConnect>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ValueValuePkeyDelete>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ValueOnValueFieldForValueFieldValueValueFkeyUsingValuePkeyUpdate>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<ValueFieldValueValueFkeyValueCreateInput>;
};

/** Input for the nested mutation of `valueField` in the `ValueInput` mutation. */
export type ValueFieldValueValueFkeyInverseInput = {
  /** Flag indicating whether all other `valueField` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyConnect>>;
  /** The primary key(s) for `valueField` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldValueFieldPkeyDelete>>;
  /** The primary key(s) and patch data for `valueField` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueFieldOnValueFieldForValueFieldValueValueFkeyUsingValueFieldPkeyUpdate>>;
  /** A `ValueFieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueFieldValueValueFkeyValueFieldCreateInput>>;
};

/** The `value` to be created by this mutation. */
export type ValueFieldValueValueFkeyValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** The `valueField` to be created by this mutation. */
export type ValueFieldValueValueFkeyValueFieldCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index: Scalars['Int'];
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** A connection to a list of `ValueField` values. */
export type ValueFieldsConnection = {
  __typename?: 'ValueFieldsConnection';
  /** A list of `ValueField` objects. */
  nodes: Array<Maybe<ValueField>>;
  /** A list of edges which contains the `ValueField` and cursor to aid in pagination. */
  edges: Array<ValueFieldsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ValueField` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ValueField` edge in the connection. */
export type ValueFieldsEdge = {
  __typename?: 'ValueFieldsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ValueField` at the end of the edge. */
  node?: Maybe<ValueField>;
};

/** Methods to use when ordering `ValueField`. */
export enum ValueFieldsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ValueIdAsc = 'VALUE_ID_ASC',
  ValueIdDesc = 'VALUE_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ModelFieldIdAsc = 'MODEL_FIELD_ID_ASC',
  ModelFieldIdDesc = 'MODEL_FIELD_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IndexAsc = 'INDEX_ASC',
  IndexDesc = 'INDEX_DESC',
  HintAsc = 'HINT_ASC',
  HintDesc = 'HINT_DESC',
  ValueScalarAsc = 'VALUE_SCALAR_ASC',
  ValueScalarDesc = 'VALUE_SCALAR_DESC',
  ValueMediaIdAsc = 'VALUE_MEDIA_ID_ASC',
  ValueMediaIdDesc = 'VALUE_MEDIA_ID_DESC',
  ValueEntryIdAsc = 'VALUE_ENTRY_ID_ASC',
  ValueEntryIdDesc = 'VALUE_ENTRY_ID_DESC',
  ValueValueIdAsc = 'VALUE_VALUE_ID_ASC',
  ValueValueIdDesc = 'VALUE_VALUE_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModelIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModelIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ValueByValueIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ValueByValueIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'VALUE_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdModelIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdModelIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdConfigAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdConfigDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CONFIG_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdTypeAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdTypeDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__TYPE_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdGroupIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdGroupIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__GROUP_ID_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdMultiAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdMultiDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MULTI_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdRequiredAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdRequiredDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__REQUIRED_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdValueCountAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdValueCountDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_COUNT_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdIndexAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdIndexDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INDEX_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoSourceAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoSourceDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_SOURCE_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoOverrideAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoOverrideDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_OVERRIDE_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoTransformAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoTransformDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_TRANSFORM_DESC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdAsc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_ASC',
  ModelFieldByModelFieldIdAndProjectIdAndEnvironmentIdAutoFieldIdDesc = 'MODEL_FIELD_BY_MODEL_FIELD_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__AUTO_FIELD_ID_DESC',
  MediaItemByValueMediaIdIdAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__ID_ASC',
  MediaItemByValueMediaIdIdDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__ID_DESC',
  MediaItemByValueMediaIdProjectIdAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__PROJECT_ID_ASC',
  MediaItemByValueMediaIdProjectIdDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__PROJECT_ID_DESC',
  MediaItemByValueMediaIdFolderIdAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__FOLDER_ID_ASC',
  MediaItemByValueMediaIdFolderIdDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__FOLDER_ID_DESC',
  MediaItemByValueMediaIdNameAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__NAME_ASC',
  MediaItemByValueMediaIdNameDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__NAME_DESC',
  MediaItemByValueMediaIdContentTypeAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__CONTENT_TYPE_ASC',
  MediaItemByValueMediaIdContentTypeDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__CONTENT_TYPE_DESC',
  MediaItemByValueMediaIdSizeAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__SIZE_ASC',
  MediaItemByValueMediaIdSizeDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__SIZE_DESC',
  MediaItemByValueMediaIdStorageAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__STORAGE_ASC',
  MediaItemByValueMediaIdStorageDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__STORAGE_DESC',
  MediaItemByValueMediaIdUploadedAtAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__UPLOADED_AT_ASC',
  MediaItemByValueMediaIdUploadedAtDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__UPLOADED_AT_DESC',
  MediaItemByValueMediaIdUploadedIpAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__UPLOADED_IP_ASC',
  MediaItemByValueMediaIdUploadedIpDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__UPLOADED_IP_DESC',
  MediaItemByValueMediaIdDeletedAtAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__DELETED_AT_ASC',
  MediaItemByValueMediaIdDeletedAtDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__DELETED_AT_DESC',
  MediaItemByValueMediaIdDeletedIpAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__DELETED_IP_ASC',
  MediaItemByValueMediaIdDeletedIpDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__DELETED_IP_DESC',
  MediaItemByValueMediaIdCreatedAtAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__CREATED_AT_ASC',
  MediaItemByValueMediaIdCreatedAtDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__CREATED_AT_DESC',
  MediaItemByValueMediaIdModifiedAtAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MODIFIED_AT_ASC',
  MediaItemByValueMediaIdModifiedAtDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MODIFIED_AT_DESC',
  MediaItemByValueMediaIdMediaInfoAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MEDIA_INFO_ASC',
  MediaItemByValueMediaIdMediaInfoDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MEDIA_INFO_DESC',
  MediaItemByValueMediaIdMetadataAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__METADATA_ASC',
  MediaItemByValueMediaIdMetadataDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__METADATA_DESC',
  MediaItemByValueMediaIdMediaTypeAsc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MEDIA_TYPE_ASC',
  MediaItemByValueMediaIdMediaTypeDesc = 'MEDIA_ITEM_BY_VALUE_MEDIA_ID__MEDIA_TYPE_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModelIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModelIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdNameAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdNameDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdLatestVersionIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__LATEST_VERSION_ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdCreatedByIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdCreatedByIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_BY_ID_DESC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModifiedByIdAsc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_ASC',
  EntryByValueEntryIdAndProjectIdAndEnvironmentIdModifiedByIdDesc = 'ENTRY_BY_VALUE_ENTRY_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_BY_ID_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdIdAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdIdDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdModelIdAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdModelIdDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODEL_ID_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ValueByValueValueIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'VALUE_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ProjectByProjectIdIdAsc = 'PROJECT_BY_PROJECT_ID__ID_ASC',
  ProjectByProjectIdIdDesc = 'PROJECT_BY_PROJECT_ID__ID_DESC',
  ProjectByProjectIdAccountIdAsc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_ASC',
  ProjectByProjectIdAccountIdDesc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_DESC',
  ProjectByProjectIdRegionIdAsc = 'PROJECT_BY_PROJECT_ID__REGION_ID_ASC',
  ProjectByProjectIdRegionIdDesc = 'PROJECT_BY_PROJECT_ID__REGION_ID_DESC',
  ProjectByProjectIdDefaultEnvironmentIdAsc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_ASC',
  ProjectByProjectIdDefaultEnvironmentIdDesc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_DESC',
  ProjectByProjectIdKeyAsc = 'PROJECT_BY_PROJECT_ID__KEY_ASC',
  ProjectByProjectIdKeyDesc = 'PROJECT_BY_PROJECT_ID__KEY_DESC',
  ProjectByProjectIdNameAsc = 'PROJECT_BY_PROJECT_ID__NAME_ASC',
  ProjectByProjectIdNameDesc = 'PROJECT_BY_PROJECT_ID__NAME_DESC',
  ProjectByProjectIdDeletedAtAsc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_ASC',
  ProjectByProjectIdDeletedAtDesc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_DESC',
  ProjectByProjectIdCreatePayloadAsc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_ASC',
  ProjectByProjectIdCreatePayloadDesc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_DESC',
  ProjectByProjectIdCreateStatusAsc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_ASC',
  ProjectByProjectIdCreateStatusDesc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_DESC'
}

/** A filter to be used against `Value` object types. All fields are combined with a logical ‘and.’ */
export type ValueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `modelId` field. */
  modelId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `entryVersions` relation. */
  entryVersions?: Maybe<ValueToManyEntryVersionFilter>;
  /** Some related `entryVersions` exist. */
  entryVersionsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `modelValueBase` relation. */
  modelValueBase?: Maybe<ValueToManyModelFilter>;
  /** Some related `modelValueBase` exist. */
  modelValueBaseExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<ValueToManyValueFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `valueValues` relation. */
  valueValues?: Maybe<ValueToManyValueFieldFilter>;
  /** Some related `valueValues` exist. */
  valueValuesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `model` relation. */
  model?: Maybe<ModelFilter>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Filter by the object’s `project` relation. */
  project?: Maybe<ProjectFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ValueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ValueFilter>>;
  /** Negates the expression. */
  not?: Maybe<ValueFilter>;
};

/** A connection to a list of `ValueHierarchy` values. */
export type ValueHierarchiesConnection = {
  __typename?: 'ValueHierarchiesConnection';
  /** A list of `ValueHierarchy` objects. */
  nodes: Array<Maybe<ValueHierarchy>>;
  /** A list of edges which contains the `ValueHierarchy` and cursor to aid in pagination. */
  edges: Array<ValueHierarchiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ValueHierarchy` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ValueHierarchy` edge in the connection. */
export type ValueHierarchiesEdge = {
  __typename?: 'ValueHierarchiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ValueHierarchy` at the end of the edge. */
  node?: Maybe<ValueHierarchy>;
};

/** Methods to use when ordering `ValueHierarchy`. */
export enum ValueHierarchiesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC'
}

export type ValueHierarchy = {
  __typename?: 'ValueHierarchy';
  id?: Maybe<Scalars['UUID']>;
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `ValueHierarchy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ValueHierarchyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `path` field. */
  path?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `ValueHierarchy` object types. All fields are combined with a logical ‘and.’ */
export type ValueHierarchyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `path` field. */
  path?: Maybe<UuidListFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ValueHierarchyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ValueHierarchyFilter>>;
  /** Negates the expression. */
  not?: Maybe<ValueHierarchyFilter>;
};

/** An input for mutations affecting `Value` */
export type ValueInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** Input for the nested mutation of `model` in the `ValueInput` mutation. */
export type ValueModelFkeyInput = {
  /** The primary key(s) for `model` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyConnect>;
  /** The primary key(s) for `model` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<ModelModelPkeyDelete>;
  /** The primary key(s) and patch data for `model` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<ModelOnValueForValueModelFkeyUsingModelPkeyUpdate>;
  /** A `ModelInput` object that will be created and connected to this object. */
  create?: Maybe<ValueModelFkeyModelCreateInput>;
};

/** Input for the nested mutation of `value` in the `ModelInput` mutation. */
export type ValueModelFkeyInverseInput = {
  /** Flag indicating whether all other `value` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  connectByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyConnect>>;
  /** The primary key(s) for `value` for the far side of the relationship. */
  deleteByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueValuePkeyDelete>>;
  /** The primary key(s) and patch data for `value` for the far side of the relationship. */
  updateByIdAndProjectIdAndEnvironmentId?: Maybe<Array<ValueOnValueForValueModelFkeyUsingValuePkeyUpdate>>;
  /** A `ValueInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ValueModelFkeyValueCreateInput>>;
};

/** The `model` to be created by this mutation. */
export type ValueModelFkeyModelCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  alias: Scalars['String'];
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** The `value` to be created by this mutation. */
export type ValueModelFkeyValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** The fields on `value` to look up the row to update. */
export type ValueOnEntryVersionForFkValueUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnEntryVersionForFkValuePatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnModelForModelValueFkeyUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnModelForModelValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnValueFieldForValueFieldValueFkeyUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnValueFieldForValueFieldValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnValueFieldForValueFieldValueValueFkeyUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnValueFieldForValueFieldValueValueFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnValueForFakeAppContentValueForeignKey0UsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnValueForFakeAppContentValueForeignKey0Patch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnValueForValueEnvironmentIdFkeyUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnValueForValueEnvironmentIdFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to update. */
export type ValueOnValueForValueModelFkeyUsingValuePkeyUpdate = {
  /** An object where the defined keys will be set on the `value` being updated. */
  patch: UpdateValueOnValueForValueModelFkeyPatch;
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** Represents an update to a `Value`. Fields that are set will be updated. */
export type ValuePatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** A filter to be used against many `EntryVersion` object types. All fields are combined with a logical ‘and.’ */
export type ValueToManyEntryVersionFilter = {
  /** Every related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EntryVersionFilter>;
  /** Some related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EntryVersionFilter>;
  /** No related `EntryVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EntryVersionFilter>;
};

/** A filter to be used against many `Model` object types. All fields are combined with a logical ‘and.’ */
export type ValueToManyModelFilter = {
  /** Every related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ModelFilter>;
  /** Some related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ModelFilter>;
  /** No related `Model` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ModelFilter>;
};

/** A filter to be used against many `ValueField` object types. All fields are combined with a logical ‘and.’ */
export type ValueToManyValueFieldFilter = {
  /** Every related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ValueFieldFilter>;
  /** Some related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ValueFieldFilter>;
  /** No related `ValueField` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ValueFieldFilter>;
};

/** The fields on `value` to look up the row to connect. */
export type ValueValuePkeyConnect = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** The fields on `value` to look up the row to delete. */
export type ValueValuePkeyDelete = {
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
};

/** A connection to a list of `Value` values. */
export type ValuesConnection = {
  __typename?: 'ValuesConnection';
  /** A list of `Value` objects. */
  nodes: Array<Maybe<Value>>;
  /** A list of edges which contains the `Value` and cursor to aid in pagination. */
  edges: Array<ValuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Value` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Value` edge in the connection. */
export type ValuesEdge = {
  __typename?: 'ValuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Value` at the end of the edge. */
  node?: Maybe<Value>;
};

/** Methods to use when ordering `Value`. */
export enum ValuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ModelIdAsc = 'MODEL_ID_ASC',
  ModelIdDesc = 'MODEL_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdProjectIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdCreatedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__CREATED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdNameDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__NAME_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdAliasDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdInheritsDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__INHERITS_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdModifiedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__MODIFIED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDeletedAtDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DELETED_AT_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdEnvironmentIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ENVIRONMENT_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdUsageDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__USAGE_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdDefaultTaxonomyLevelIdDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__DEFAULT_TAXONOMY_LEVEL_ID_DESC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotAsc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_ASC',
  ModelByModelIdAndProjectIdAndEnvironmentIdValueBaseSnapshotDesc = 'MODEL_BY_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__VALUE_BASE_SNAPSHOT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC',
  ProjectByProjectIdIdAsc = 'PROJECT_BY_PROJECT_ID__ID_ASC',
  ProjectByProjectIdIdDesc = 'PROJECT_BY_PROJECT_ID__ID_DESC',
  ProjectByProjectIdAccountIdAsc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_ASC',
  ProjectByProjectIdAccountIdDesc = 'PROJECT_BY_PROJECT_ID__ACCOUNT_ID_DESC',
  ProjectByProjectIdRegionIdAsc = 'PROJECT_BY_PROJECT_ID__REGION_ID_ASC',
  ProjectByProjectIdRegionIdDesc = 'PROJECT_BY_PROJECT_ID__REGION_ID_DESC',
  ProjectByProjectIdDefaultEnvironmentIdAsc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_ASC',
  ProjectByProjectIdDefaultEnvironmentIdDesc = 'PROJECT_BY_PROJECT_ID__DEFAULT_ENVIRONMENT_ID_DESC',
  ProjectByProjectIdKeyAsc = 'PROJECT_BY_PROJECT_ID__KEY_ASC',
  ProjectByProjectIdKeyDesc = 'PROJECT_BY_PROJECT_ID__KEY_DESC',
  ProjectByProjectIdNameAsc = 'PROJECT_BY_PROJECT_ID__NAME_ASC',
  ProjectByProjectIdNameDesc = 'PROJECT_BY_PROJECT_ID__NAME_DESC',
  ProjectByProjectIdDeletedAtAsc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_ASC',
  ProjectByProjectIdDeletedAtDesc = 'PROJECT_BY_PROJECT_ID__DELETED_AT_DESC',
  ProjectByProjectIdCreatePayloadAsc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_ASC',
  ProjectByProjectIdCreatePayloadDesc = 'PROJECT_BY_PROJECT_ID__CREATE_PAYLOAD_DESC',
  ProjectByProjectIdCreateStatusAsc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_ASC',
  ProjectByProjectIdCreateStatusDesc = 'PROJECT_BY_PROJECT_ID__CREATE_STATUS_DESC',
  EntryVersionsByValueIdAndProjectIdAndEnvironmentIdCountAsc = 'ENTRY_VERSIONS_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  EntryVersionsByValueIdAndProjectIdAndEnvironmentIdCountDesc = 'ENTRY_VERSIONS_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ModelsByValueBaseIdAndProjectIdAndEnvironmentIdCountAsc = 'MODELS_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ModelsByValueBaseIdAndProjectIdAndEnvironmentIdCountDesc = 'MODELS_BY_VALUE_BASE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ValueFieldsByValueIdAndProjectIdAndEnvironmentIdCountAsc = 'VALUE_FIELDS_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ValueFieldsByValueIdAndProjectIdAndEnvironmentIdCountDesc = 'VALUE_FIELDS_BY_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC',
  ValueFieldsByValueValueIdAndProjectIdAndEnvironmentIdCountAsc = 'VALUE_FIELDS_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_ASC',
  ValueFieldsByValueValueIdAndProjectIdAndEnvironmentIdCountDesc = 'VALUE_FIELDS_BY_VALUE_VALUE_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__COUNT_DESC'
}

export type Webhook = {
  __typename?: 'Webhook';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  method: Scalars['String'];
  url: Scalars['String'];
  /** Reads and enables pagination through a set of `WebhookLog`. */
  webhookLogs: WebhookLogsConnection;
  /** Reads and enables pagination through a set of `WebhookRun`. */
  webhookRuns: WebhookRunsConnection;
};


export type WebhookWebhookLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
  condition?: Maybe<WebhookLogCondition>;
  filter?: Maybe<WebhookLogFilter>;
};


export type WebhookWebhookRunsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhookRunsOrderBy>>;
  condition?: Maybe<WebhookRunCondition>;
  filter?: Maybe<WebhookRunFilter>;
};

/** A condition to be used against `Webhook` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WebhookCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `headers` field. */
  headers?: Maybe<Scalars['KeyValueHash']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `method` field. */
  method?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `url` field. */
  url?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Webhook` object types. All fields are combined with a logical ‘and.’ */
export type WebhookFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `headers` field. */
  headers?: Maybe<KeyValueHashFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `method` field. */
  method?: Maybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: Maybe<StringFilter>;
  /** Filter by the object’s `webhookLogs` relation. */
  webhookLogs?: Maybe<WebhookToManyWebhookLogFilter>;
  /** Some related `webhookLogs` exist. */
  webhookLogsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `webhookRuns` relation. */
  webhookRuns?: Maybe<WebhookToManyWebhookRunFilter>;
  /** Some related `webhookRuns` exist. */
  webhookRunsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WebhookFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WebhookFilter>>;
  /** Negates the expression. */
  not?: Maybe<WebhookFilter>;
};

/** An input for mutations affecting `Webhook` */
export type WebhookInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  method: Scalars['String'];
  url: Scalars['String'];
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

export type WebhookLog = {
  __typename?: 'WebhookLog';
  id: Scalars['UUID'];
  webhookId: Scalars['UUID'];
  webhookRunId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  level: WebhookLogLevel;
  payload?: Maybe<Scalars['String']>;
  /** Reads a single `Webhook` that is related to this `WebhookLog`. */
  webhook?: Maybe<Webhook>;
  /** Reads a single `WebhookRun` that is related to this `WebhookLog`. */
  webhookRun?: Maybe<WebhookRun>;
};

/**
 * A condition to be used against `WebhookLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WebhookLogCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `webhookId` field. */
  webhookId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `webhookRunId` field. */
  webhookRunId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `level` field. */
  level?: Maybe<WebhookLogLevel>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['String']>;
};

/** A filter to be used against `WebhookLog` object types. All fields are combined with a logical ‘and.’ */
export type WebhookLogFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `webhookId` field. */
  webhookId?: Maybe<UuidFilter>;
  /** Filter by the object’s `webhookRunId` field. */
  webhookRunId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `level` field. */
  level?: Maybe<WebhookLogLevelFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<StringFilter>;
  /** Filter by the object’s `webhook` relation. */
  webhook?: Maybe<WebhookFilter>;
  /** Filter by the object’s `webhookRun` relation. */
  webhookRun?: Maybe<WebhookRunFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WebhookLogFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WebhookLogFilter>>;
  /** Negates the expression. */
  not?: Maybe<WebhookLogFilter>;
};

/** An input for mutations affecting `WebhookLog` */
export type WebhookLogInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  webhookRunId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

export enum WebhookLogLevel {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

/** A filter to be used against WebhookLogLevel fields. All fields are combined with a logical ‘and.’ */
export type WebhookLogLevelFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<WebhookLogLevel>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<WebhookLogLevel>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<WebhookLogLevel>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<WebhookLogLevel>;
  /** Included in the specified list. */
  in?: Maybe<Array<WebhookLogLevel>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<WebhookLogLevel>>;
  /** Less than the specified value. */
  lessThan?: Maybe<WebhookLogLevel>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<WebhookLogLevel>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<WebhookLogLevel>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<WebhookLogLevel>;
};

/** The fields on `webhookLog` to look up the row to update. */
export type WebhookLogOnWebhookLogForWebhookLogWebhookIdFkeyUsingWebhookLogPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhookLog` being updated. */
  patch: UpdateWebhookLogOnWebhookLogForWebhookLogWebhookIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `webhookLog` to look up the row to update. */
export type WebhookLogOnWebhookLogForWebhookLogWebhookRunFkeyUsingWebhookLogPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhookLog` being updated. */
  patch: UpdateWebhookLogOnWebhookLogForWebhookLogWebhookRunFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `WebhookLog`. Fields that are set will be updated. */
export type WebhookLogPatch = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  webhookRunId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

/** Input for the nested mutation of `webhook` in the `WebhookLogInput` mutation. */
export type WebhookLogWebhookIdFkeyInput = {
  /** The primary key(s) for `webhook` for the far side of the relationship. */
  connectById?: Maybe<WebhookWebhookPkeyConnect>;
  /** The primary key(s) for `webhook` for the far side of the relationship. */
  deleteById?: Maybe<WebhookWebhookPkeyDelete>;
  /** The primary key(s) and patch data for `webhook` for the far side of the relationship. */
  updateById?: Maybe<WebhookOnWebhookLogForWebhookLogWebhookIdFkeyUsingWebhookPkeyUpdate>;
  /** A `WebhookInput` object that will be created and connected to this object. */
  create?: Maybe<WebhookLogWebhookIdFkeyWebhookCreateInput>;
};

/** Input for the nested mutation of `webhookLog` in the `WebhookInput` mutation. */
export type WebhookLogWebhookIdFkeyInverseInput = {
  /** Flag indicating whether all other `webhookLog` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `webhookLog` for the far side of the relationship. */
  connectById?: Maybe<Array<WebhookLogWebhookLogPkeyConnect>>;
  /** The primary key(s) for `webhookLog` for the far side of the relationship. */
  deleteById?: Maybe<Array<WebhookLogWebhookLogPkeyDelete>>;
  /** The primary key(s) and patch data for `webhookLog` for the far side of the relationship. */
  updateById?: Maybe<Array<WebhookLogOnWebhookLogForWebhookLogWebhookIdFkeyUsingWebhookLogPkeyUpdate>>;
  /** A `WebhookLogInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WebhookLogWebhookIdFkeyWebhookLogCreateInput>>;
};

/** The `webhook` to be created by this mutation. */
export type WebhookLogWebhookIdFkeyWebhookCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  method: Scalars['String'];
  url: Scalars['String'];
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

/** The `webhookLog` to be created by this mutation. */
export type WebhookLogWebhookIdFkeyWebhookLogCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  webhookRunId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

/** The fields on `webhookLog` to look up the row to connect. */
export type WebhookLogWebhookLogPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `webhookLog` to look up the row to delete. */
export type WebhookLogWebhookLogPkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `webhookRun` in the `WebhookLogInput` mutation. */
export type WebhookLogWebhookRunFkeyInput = {
  /** The primary key(s) for `webhookRun` for the far side of the relationship. */
  connectById?: Maybe<WebhookRunWebhookRunPkeyConnect>;
  /** The primary key(s) for `webhookRun` for the far side of the relationship. */
  deleteById?: Maybe<WebhookRunWebhookRunPkeyDelete>;
  /** The primary key(s) and patch data for `webhookRun` for the far side of the relationship. */
  updateById?: Maybe<WebhookRunOnWebhookLogForWebhookLogWebhookRunFkeyUsingWebhookRunPkeyUpdate>;
  /** A `WebhookRunInput` object that will be created and connected to this object. */
  create?: Maybe<WebhookLogWebhookRunFkeyWebhookRunCreateInput>;
};

/** Input for the nested mutation of `webhookLog` in the `WebhookRunInput` mutation. */
export type WebhookLogWebhookRunFkeyInverseInput = {
  /** Flag indicating whether all other `webhookLog` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `webhookLog` for the far side of the relationship. */
  connectById?: Maybe<Array<WebhookLogWebhookLogPkeyConnect>>;
  /** The primary key(s) for `webhookLog` for the far side of the relationship. */
  deleteById?: Maybe<Array<WebhookLogWebhookLogPkeyDelete>>;
  /** The primary key(s) and patch data for `webhookLog` for the far side of the relationship. */
  updateById?: Maybe<Array<WebhookLogOnWebhookLogForWebhookLogWebhookRunFkeyUsingWebhookLogPkeyUpdate>>;
  /** A `WebhookLogInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WebhookLogWebhookRunFkeyWebhookLogCreateInput>>;
};

/** The `webhookLog` to be created by this mutation. */
export type WebhookLogWebhookRunFkeyWebhookLogCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  webhookRunId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

/** The `webhookRun` to be created by this mutation. */
export type WebhookLogWebhookRunFkeyWebhookRunCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

/** A connection to a list of `WebhookLog` values. */
export type WebhookLogsConnection = {
  __typename?: 'WebhookLogsConnection';
  /** A list of `WebhookLog` objects. */
  nodes: Array<Maybe<WebhookLog>>;
  /** A list of edges which contains the `WebhookLog` and cursor to aid in pagination. */
  edges: Array<WebhookLogsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WebhookLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `WebhookLog` edge in the connection. */
export type WebhookLogsEdge = {
  __typename?: 'WebhookLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `WebhookLog` at the end of the edge. */
  node?: Maybe<WebhookLog>;
};

/** Methods to use when ordering `WebhookLog`. */
export enum WebhookLogsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WebhookIdAsc = 'WEBHOOK_ID_ASC',
  WebhookIdDesc = 'WEBHOOK_ID_DESC',
  WebhookRunIdAsc = 'WEBHOOK_RUN_ID_ASC',
  WebhookRunIdDesc = 'WEBHOOK_RUN_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LevelAsc = 'LEVEL_ASC',
  LevelDesc = 'LEVEL_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WebhookByWebhookIdIdAsc = 'WEBHOOK_BY_WEBHOOK_ID__ID_ASC',
  WebhookByWebhookIdIdDesc = 'WEBHOOK_BY_WEBHOOK_ID__ID_DESC',
  WebhookByWebhookIdProjectIdAsc = 'WEBHOOK_BY_WEBHOOK_ID__PROJECT_ID_ASC',
  WebhookByWebhookIdProjectIdDesc = 'WEBHOOK_BY_WEBHOOK_ID__PROJECT_ID_DESC',
  WebhookByWebhookIdHeadersAsc = 'WEBHOOK_BY_WEBHOOK_ID__HEADERS_ASC',
  WebhookByWebhookIdHeadersDesc = 'WEBHOOK_BY_WEBHOOK_ID__HEADERS_DESC',
  WebhookByWebhookIdPayloadAsc = 'WEBHOOK_BY_WEBHOOK_ID__PAYLOAD_ASC',
  WebhookByWebhookIdPayloadDesc = 'WEBHOOK_BY_WEBHOOK_ID__PAYLOAD_DESC',
  WebhookByWebhookIdCreatedAtAsc = 'WEBHOOK_BY_WEBHOOK_ID__CREATED_AT_ASC',
  WebhookByWebhookIdCreatedAtDesc = 'WEBHOOK_BY_WEBHOOK_ID__CREATED_AT_DESC',
  WebhookByWebhookIdNameAsc = 'WEBHOOK_BY_WEBHOOK_ID__NAME_ASC',
  WebhookByWebhookIdNameDesc = 'WEBHOOK_BY_WEBHOOK_ID__NAME_DESC',
  WebhookByWebhookIdMethodAsc = 'WEBHOOK_BY_WEBHOOK_ID__METHOD_ASC',
  WebhookByWebhookIdMethodDesc = 'WEBHOOK_BY_WEBHOOK_ID__METHOD_DESC',
  WebhookByWebhookIdUrlAsc = 'WEBHOOK_BY_WEBHOOK_ID__URL_ASC',
  WebhookByWebhookIdUrlDesc = 'WEBHOOK_BY_WEBHOOK_ID__URL_DESC',
  WebhookRunByWebhookRunIdIdAsc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__ID_ASC',
  WebhookRunByWebhookRunIdIdDesc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__ID_DESC',
  WebhookRunByWebhookRunIdWebhookIdAsc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__WEBHOOK_ID_ASC',
  WebhookRunByWebhookRunIdWebhookIdDesc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__WEBHOOK_ID_DESC',
  WebhookRunByWebhookRunIdCreatedAtAsc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__CREATED_AT_ASC',
  WebhookRunByWebhookRunIdCreatedAtDesc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__CREATED_AT_DESC',
  WebhookRunByWebhookRunIdFinishedAtAsc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__FINISHED_AT_ASC',
  WebhookRunByWebhookRunIdFinishedAtDesc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__FINISHED_AT_DESC',
  WebhookRunByWebhookRunIdStatusAsc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__STATUS_ASC',
  WebhookRunByWebhookRunIdStatusDesc = 'WEBHOOK_RUN_BY_WEBHOOK_RUN_ID__STATUS_DESC'
}

/** The fields on `webhook` to look up the row to update. */
export type WebhookOnWebhookLogForWebhookLogWebhookIdFkeyUsingWebhookPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhook` being updated. */
  patch: UpdateWebhookOnWebhookLogForWebhookLogWebhookIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `webhook` to look up the row to update. */
export type WebhookOnWebhookRunForWebhookRunWebhookIdFkeyUsingWebhookPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhook` being updated. */
  patch: UpdateWebhookOnWebhookRunForWebhookRunWebhookIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Webhook`. Fields that are set will be updated. */
export type WebhookPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

export type WebhookRun = {
  __typename?: 'WebhookRun';
  id: Scalars['UUID'];
  webhookId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  finishedAt?: Maybe<Scalars['Datetime']>;
  status: WebhookRunStatus;
  /** Reads a single `Webhook` that is related to this `WebhookRun`. */
  webhook?: Maybe<Webhook>;
  /** Reads and enables pagination through a set of `WebhookLog`. */
  webhookLogs: WebhookLogsConnection;
};


export type WebhookRunWebhookLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebhookLogsOrderBy>>;
  condition?: Maybe<WebhookLogCondition>;
  filter?: Maybe<WebhookLogFilter>;
};

/**
 * A condition to be used against `WebhookRun` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WebhookRunCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `webhookId` field. */
  webhookId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `finishedAt` field. */
  finishedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<WebhookRunStatus>;
};

/** A filter to be used against `WebhookRun` object types. All fields are combined with a logical ‘and.’ */
export type WebhookRunFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `webhookId` field. */
  webhookId?: Maybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `finishedAt` field. */
  finishedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<WebhookRunStatusFilter>;
  /** Filter by the object’s `webhookLogs` relation. */
  webhookLogs?: Maybe<WebhookRunToManyWebhookLogFilter>;
  /** Some related `webhookLogs` exist. */
  webhookLogsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `webhook` relation. */
  webhook?: Maybe<WebhookFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WebhookRunFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WebhookRunFilter>>;
  /** Negates the expression. */
  not?: Maybe<WebhookRunFilter>;
};

/** An input for mutations affecting `WebhookRun` */
export type WebhookRunInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

/** The fields on `webhookRun` to look up the row to update. */
export type WebhookRunOnWebhookLogForWebhookLogWebhookRunFkeyUsingWebhookRunPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhookRun` being updated. */
  patch: UpdateWebhookRunOnWebhookLogForWebhookLogWebhookRunFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `webhookRun` to look up the row to update. */
export type WebhookRunOnWebhookRunForWebhookRunWebhookIdFkeyUsingWebhookRunPkeyUpdate = {
  /** An object where the defined keys will be set on the `webhookRun` being updated. */
  patch: UpdateWebhookRunOnWebhookRunForWebhookRunWebhookIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `WebhookRun`. Fields that are set will be updated. */
export type WebhookRunPatch = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

export enum WebhookRunStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

/** A filter to be used against WebhookRunStatus fields. All fields are combined with a logical ‘and.’ */
export type WebhookRunStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<WebhookRunStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<WebhookRunStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<WebhookRunStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<WebhookRunStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<WebhookRunStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<WebhookRunStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<WebhookRunStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<WebhookRunStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<WebhookRunStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<WebhookRunStatus>;
};

/** A filter to be used against many `WebhookLog` object types. All fields are combined with a logical ‘and.’ */
export type WebhookRunToManyWebhookLogFilter = {
  /** Every related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebhookLogFilter>;
  /** Some related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebhookLogFilter>;
  /** No related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebhookLogFilter>;
};

/** Input for the nested mutation of `webhook` in the `WebhookRunInput` mutation. */
export type WebhookRunWebhookIdFkeyInput = {
  /** The primary key(s) for `webhook` for the far side of the relationship. */
  connectById?: Maybe<WebhookWebhookPkeyConnect>;
  /** The primary key(s) for `webhook` for the far side of the relationship. */
  deleteById?: Maybe<WebhookWebhookPkeyDelete>;
  /** The primary key(s) and patch data for `webhook` for the far side of the relationship. */
  updateById?: Maybe<WebhookOnWebhookRunForWebhookRunWebhookIdFkeyUsingWebhookPkeyUpdate>;
  /** A `WebhookInput` object that will be created and connected to this object. */
  create?: Maybe<WebhookRunWebhookIdFkeyWebhookCreateInput>;
};

/** Input for the nested mutation of `webhookRun` in the `WebhookInput` mutation. */
export type WebhookRunWebhookIdFkeyInverseInput = {
  /** Flag indicating whether all other `webhookRun` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `webhookRun` for the far side of the relationship. */
  connectById?: Maybe<Array<WebhookRunWebhookRunPkeyConnect>>;
  /** The primary key(s) for `webhookRun` for the far side of the relationship. */
  deleteById?: Maybe<Array<WebhookRunWebhookRunPkeyDelete>>;
  /** The primary key(s) and patch data for `webhookRun` for the far side of the relationship. */
  updateById?: Maybe<Array<WebhookRunOnWebhookRunForWebhookRunWebhookIdFkeyUsingWebhookRunPkeyUpdate>>;
  /** A `WebhookRunInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WebhookRunWebhookIdFkeyWebhookRunCreateInput>>;
};

/** The `webhook` to be created by this mutation. */
export type WebhookRunWebhookIdFkeyWebhookCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  method: Scalars['String'];
  url: Scalars['String'];
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

/** The `webhookRun` to be created by this mutation. */
export type WebhookRunWebhookIdFkeyWebhookRunCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

/** The fields on `webhookRun` to look up the row to connect. */
export type WebhookRunWebhookRunPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `webhookRun` to look up the row to delete. */
export type WebhookRunWebhookRunPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `WebhookRun` values. */
export type WebhookRunsConnection = {
  __typename?: 'WebhookRunsConnection';
  /** A list of `WebhookRun` objects. */
  nodes: Array<Maybe<WebhookRun>>;
  /** A list of edges which contains the `WebhookRun` and cursor to aid in pagination. */
  edges: Array<WebhookRunsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WebhookRun` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `WebhookRun` edge in the connection. */
export type WebhookRunsEdge = {
  __typename?: 'WebhookRunsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `WebhookRun` at the end of the edge. */
  node?: Maybe<WebhookRun>;
};

/** Methods to use when ordering `WebhookRun`. */
export enum WebhookRunsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WebhookIdAsc = 'WEBHOOK_ID_ASC',
  WebhookIdDesc = 'WEBHOOK_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FinishedAtAsc = 'FINISHED_AT_ASC',
  FinishedAtDesc = 'FINISHED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WebhookByWebhookIdIdAsc = 'WEBHOOK_BY_WEBHOOK_ID__ID_ASC',
  WebhookByWebhookIdIdDesc = 'WEBHOOK_BY_WEBHOOK_ID__ID_DESC',
  WebhookByWebhookIdProjectIdAsc = 'WEBHOOK_BY_WEBHOOK_ID__PROJECT_ID_ASC',
  WebhookByWebhookIdProjectIdDesc = 'WEBHOOK_BY_WEBHOOK_ID__PROJECT_ID_DESC',
  WebhookByWebhookIdHeadersAsc = 'WEBHOOK_BY_WEBHOOK_ID__HEADERS_ASC',
  WebhookByWebhookIdHeadersDesc = 'WEBHOOK_BY_WEBHOOK_ID__HEADERS_DESC',
  WebhookByWebhookIdPayloadAsc = 'WEBHOOK_BY_WEBHOOK_ID__PAYLOAD_ASC',
  WebhookByWebhookIdPayloadDesc = 'WEBHOOK_BY_WEBHOOK_ID__PAYLOAD_DESC',
  WebhookByWebhookIdCreatedAtAsc = 'WEBHOOK_BY_WEBHOOK_ID__CREATED_AT_ASC',
  WebhookByWebhookIdCreatedAtDesc = 'WEBHOOK_BY_WEBHOOK_ID__CREATED_AT_DESC',
  WebhookByWebhookIdNameAsc = 'WEBHOOK_BY_WEBHOOK_ID__NAME_ASC',
  WebhookByWebhookIdNameDesc = 'WEBHOOK_BY_WEBHOOK_ID__NAME_DESC',
  WebhookByWebhookIdMethodAsc = 'WEBHOOK_BY_WEBHOOK_ID__METHOD_ASC',
  WebhookByWebhookIdMethodDesc = 'WEBHOOK_BY_WEBHOOK_ID__METHOD_DESC',
  WebhookByWebhookIdUrlAsc = 'WEBHOOK_BY_WEBHOOK_ID__URL_ASC',
  WebhookByWebhookIdUrlDesc = 'WEBHOOK_BY_WEBHOOK_ID__URL_DESC',
  WebhookLogsByWebhookRunIdCountAsc = 'WEBHOOK_LOGS_BY_WEBHOOK_RUN_ID__COUNT_ASC',
  WebhookLogsByWebhookRunIdCountDesc = 'WEBHOOK_LOGS_BY_WEBHOOK_RUN_ID__COUNT_DESC'
}

/** A filter to be used against many `WebhookLog` object types. All fields are combined with a logical ‘and.’ */
export type WebhookToManyWebhookLogFilter = {
  /** Every related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebhookLogFilter>;
  /** Some related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebhookLogFilter>;
  /** No related `WebhookLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebhookLogFilter>;
};

/** A filter to be used against many `WebhookRun` object types. All fields are combined with a logical ‘and.’ */
export type WebhookToManyWebhookRunFilter = {
  /** Every related `WebhookRun` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WebhookRunFilter>;
  /** Some related `WebhookRun` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WebhookRunFilter>;
  /** No related `WebhookRun` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WebhookRunFilter>;
};

/** The fields on `webhook` to look up the row to connect. */
export type WebhookWebhookPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `webhook` to look up the row to delete. */
export type WebhookWebhookPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `Webhook` values. */
export type WebhooksConnection = {
  __typename?: 'WebhooksConnection';
  /** A list of `Webhook` objects. */
  nodes: Array<Maybe<Webhook>>;
  /** A list of edges which contains the `Webhook` and cursor to aid in pagination. */
  edges: Array<WebhooksEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Webhook` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Webhook` edge in the connection. */
export type WebhooksEdge = {
  __typename?: 'WebhooksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Webhook` at the end of the edge. */
  node?: Maybe<Webhook>;
};

/** Methods to use when ordering `Webhook`. */
export enum WebhooksOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  HeadersAsc = 'HEADERS_ASC',
  HeadersDesc = 'HEADERS_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MethodAsc = 'METHOD_ASC',
  MethodDesc = 'METHOD_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WebhookLogsByWebhookIdCountAsc = 'WEBHOOK_LOGS_BY_WEBHOOK_ID__COUNT_ASC',
  WebhookLogsByWebhookIdCountDesc = 'WEBHOOK_LOGS_BY_WEBHOOK_ID__COUNT_DESC',
  WebhookRunsByWebhookIdCountAsc = 'WEBHOOK_RUNS_BY_WEBHOOK_ID__COUNT_ASC',
  WebhookRunsByWebhookIdCountDesc = 'WEBHOOK_RUNS_BY_WEBHOOK_ID__COUNT_DESC'
}

export type Website = {
  __typename?: 'Website';
  id: Scalars['UUID'];
  projectId: Scalars['UUID'];
  environmentId: Scalars['UUID'];
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  /** Reads a single `Taxonomy` that is related to this `Website`. */
  projectEnvironmentTaxonomy?: Maybe<Taxonomy>;
  /** Reads a single `PublishTarget` that is related to this `Website`. */
  projectTarget?: Maybe<PublishTarget>;
  /** Reads a single `ProjectEnvironment` that is related to this `Website`. */
  environment?: Maybe<ProjectEnvironment>;
};

/** A condition to be used against `Website` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WebsiteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `environmentId` field. */
  environmentId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `targetId` field. */
  targetId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `urlFormat` field. */
  urlFormat?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Website` object types. All fields are combined with a logical ‘and.’ */
export type WebsiteFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: Maybe<UuidFilter>;
  /** Filter by the object’s `environmentId` field. */
  environmentId?: Maybe<UuidFilter>;
  /** Filter by the object’s `targetId` field. */
  targetId?: Maybe<UuidFilter>;
  /** Filter by the object’s `taxonomyId` field. */
  taxonomyId?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `urlFormat` field. */
  urlFormat?: Maybe<StringFilter>;
  /** Filter by the object’s `projectEnvironmentTaxonomy` relation. */
  projectEnvironmentTaxonomy?: Maybe<TaxonomyFilter>;
  /** A related `projectEnvironmentTaxonomy` exists. */
  projectEnvironmentTaxonomyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectTarget` relation. */
  projectTarget?: Maybe<PublishTargetFilter>;
  /** A related `projectTarget` exists. */
  projectTargetExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `environment` relation. */
  environment?: Maybe<ProjectEnvironmentFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WebsiteFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WebsiteFilter>>;
  /** Negates the expression. */
  not?: Maybe<WebsiteFilter>;
};

/** An input for mutations affecting `Website` */
export type WebsiteInput = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** The fields on `website` to look up the row to update. */
export type WebsiteOnWebsiteForFkWebsiteEnvironmentUsingPkWebsiteUpdate = {
  /** An object where the defined keys will be set on the `website` being updated. */
  patch: UpdateWebsiteOnWebsiteForFkWebsiteEnvironmentPatch;
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** The fields on `website` to look up the row to update. */
export type WebsiteOnWebsiteForFkWebsiteTargetUsingPkWebsiteUpdate = {
  /** An object where the defined keys will be set on the `website` being updated. */
  patch: UpdateWebsiteOnWebsiteForFkWebsiteTargetPatch;
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** The fields on `website` to look up the row to update. */
export type WebsiteOnWebsiteForFkWebsiteTaxonomyUsingPkWebsiteUpdate = {
  /** An object where the defined keys will be set on the `website` being updated. */
  patch: UpdateWebsiteOnWebsiteForFkWebsiteTaxonomyPatch;
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** Represents an update to a `Website`. Fields that are set will be updated. */
export type WebsitePatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** The fields on `website` to look up the row to connect. */
export type WebsitePkWebsiteConnect = {
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** The fields on `website` to look up the row to delete. */
export type WebsitePkWebsiteDelete = {
  projectId: Scalars['UUID'];
  id: Scalars['UUID'];
};

/** A connection to a list of `Website` values. */
export type WebsitesConnection = {
  __typename?: 'WebsitesConnection';
  /** A list of `Website` objects. */
  nodes: Array<Maybe<Website>>;
  /** A list of edges which contains the `Website` and cursor to aid in pagination. */
  edges: Array<WebsitesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Website` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Website` edge in the connection. */
export type WebsitesEdge = {
  __typename?: 'WebsitesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Website` at the end of the edge. */
  node?: Maybe<Website>;
};

/** Methods to use when ordering `Website`. */
export enum WebsitesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  EnvironmentIdAsc = 'ENVIRONMENT_ID_ASC',
  EnvironmentIdDesc = 'ENVIRONMENT_ID_DESC',
  TargetIdAsc = 'TARGET_ID_ASC',
  TargetIdDesc = 'TARGET_ID_DESC',
  TaxonomyIdAsc = 'TAXONOMY_ID_ASC',
  TaxonomyIdDesc = 'TAXONOMY_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UrlFormatAsc = 'URL_FORMAT_ASC',
  UrlFormatDesc = 'URL_FORMAT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdIdAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ID_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdIdDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ID_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdCreatedAtAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__CREATED_AT_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdCreatedAtDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__CREATED_AT_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdProjectIdAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__PROJECT_ID_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdProjectIdDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__PROJECT_ID_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdParentIdAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__PARENT_ID_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdParentIdDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__PARENT_ID_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdAliasAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ALIAS_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdAliasDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ALIAS_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdTypeAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__TYPE_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdTypeDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__TYPE_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdEntryIdAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ENTRY_ID_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdEntryIdDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ENTRY_ID_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdConfigAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__CONFIG_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdConfigDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__CONFIG_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdModifiedAtAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__MODIFIED_AT_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdModifiedAtDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__MODIFIED_AT_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdEnvironmentIdAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ENVIRONMENT_ID_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdEnvironmentIdDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__ENVIRONMENT_ID_DESC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdDeletedAtAsc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__DELETED_AT_ASC',
  TaxonomyByProjectIdAndEnvironmentIdAndTaxonomyIdDeletedAtDesc = 'TAXONOMY_BY_PROJECT_ID_AND_ENVIRONMENT_ID_AND_TAXONOMY_ID__DELETED_AT_DESC',
  PublishTargetByProjectIdAndTargetIdIdAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__ID_ASC',
  PublishTargetByProjectIdAndTargetIdIdDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__ID_DESC',
  PublishTargetByProjectIdAndTargetIdProjectIdAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__PROJECT_ID_ASC',
  PublishTargetByProjectIdAndTargetIdProjectIdDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__PROJECT_ID_DESC',
  PublishTargetByProjectIdAndTargetIdKeyAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__KEY_ASC',
  PublishTargetByProjectIdAndTargetIdKeyDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__KEY_DESC',
  PublishTargetByProjectIdAndTargetIdNameAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__NAME_ASC',
  PublishTargetByProjectIdAndTargetIdNameDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__NAME_DESC',
  PublishTargetByProjectIdAndTargetIdDeletedAtAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__DELETED_AT_ASC',
  PublishTargetByProjectIdAndTargetIdDeletedAtDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__DELETED_AT_DESC',
  PublishTargetByProjectIdAndTargetIdCreatedAtAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__CREATED_AT_ASC',
  PublishTargetByProjectIdAndTargetIdCreatedAtDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__CREATED_AT_DESC',
  PublishTargetByProjectIdAndTargetIdModifiedAtAsc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__MODIFIED_AT_ASC',
  PublishTargetByProjectIdAndTargetIdModifiedAtDesc = 'PUBLISH_TARGET_BY_PROJECT_ID_AND_TARGET_ID__MODIFIED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_ASC',
  ProjectEnvironmentByEnvironmentIdIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ID_DESC',
  ProjectEnvironmentByEnvironmentIdProjectIdAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_ASC',
  ProjectEnvironmentByEnvironmentIdProjectIdDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__PROJECT_ID_DESC',
  ProjectEnvironmentByEnvironmentIdNameAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_ASC',
  ProjectEnvironmentByEnvironmentIdNameDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__NAME_DESC',
  ProjectEnvironmentByEnvironmentIdAliasAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_ASC',
  ProjectEnvironmentByEnvironmentIdAliasDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__ALIAS_DESC',
  ProjectEnvironmentByEnvironmentIdDeletedAtAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_ASC',
  ProjectEnvironmentByEnvironmentIdDeletedAtDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__DELETED_AT_DESC',
  ProjectEnvironmentByEnvironmentIdKeyAsc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_ASC',
  ProjectEnvironmentByEnvironmentIdKeyDesc = 'PROJECT_ENVIRONMENT_BY_ENVIRONMENT_ID__KEY_DESC'
}

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnProjectForFakeAppProjectProjectForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryForEntryEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryForEntryModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryForFakeAppContentEntryForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryForFakeAppContentEntryForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryForFkEntryLatestVersionPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryPublishForFkEntryPublishEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnEntryVersionForFkEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnMenuItemEntryForFkMenuItemEntryEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnTaxonomyForTaxonomyEntryFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entry` being updated. */
export type UpdateEntryOnValueFieldForValueFieldEntryFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  latestVersionId?: Maybe<Scalars['UUID']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  model?: Maybe<EntryModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryEnvironmentIdFkeyInput>;
  latestVersion?: Maybe<FkEntryLatestVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryForeignKey1Input>;
  versions?: Maybe<FkEntryInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryInverseInput>;
  taxonomy?: Maybe<TaxonomyEntryFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEntryFkeyInverseInput>;
  menuItemEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInverseInput>;
  taxonomies?: Maybe<FakeAppContentTaxonomyCombinedForeignKey0InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey0InverseInput>;
  taxonomyListNodesEntriesUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyListNodesEntryForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForEntryPublishEnvironmentIdFkeyPatch = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey0Patch = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForFakeAppContentEntryPublishForeignKey1Patch = {
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForFkEntryPublishEntryPatch = {
  targetId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForFkEntryPublishEntryVersionPatch = {
  entryId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryPublish` being updated. */
export type UpdateEntryPublishOnEntryPublishForFkEntryPublishTargetPatch = {
  entryId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  snapshot?: Maybe<Scalars['JSON']>;
  paths?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryPublishEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<EntryPublishEnvironmentIdFkeyInput>;
  target?: Maybe<FkEntryPublishTargetInput>;
  entryVersion?: Maybe<FkEntryPublishEntryVersionInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryPublishForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryPublishForeignKey1Input>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryForFkEntryLatestVersionPatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryPublishForFkEntryPublishEntryVersionPatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFakeAppContentEntryVersionForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFkEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFkEntryVersionParentPatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFkEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `entryVersion` being updated. */
export type UpdateEntryVersionOnEntryVersionForFkValuePatch = {
  id?: Maybe<Scalars['UUID']>;
  entryId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdById?: Maybe<Scalars['UUID']>;
  modifiedById?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkEntryInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkEnvironmentInput>;
  value?: Maybe<FkValueInput>;
  entryVersionToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkEntryVersionParentInput>;
  userToCreatedById?: Maybe<FakeAppContentEntryVersionForeignKey0Input>;
  userToModifiedById?: Maybe<FakeAppContentEntryVersionForeignKey1Input>;
  entriesByLatest?: Maybe<FkEntryLatestVersionInverseInput>;
  entryPublishes?: Maybe<FkEntryPublishEntryVersionInverseInput>;
};

/** An object where the defined keys will be set on the `mediaFolder` being updated. */
export type UpdateMediaFolderOnMediaFolderForMediaFolderParentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `mediaFolder` being updated. */
export type UpdateMediaFolderOnMediaItemForMediaItemFolderIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  mediaFolderToParentId?: Maybe<MediaFolderParentIdFkeyInput>;
  items?: Maybe<MediaItemFolderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `mediaItem` being updated. */
export type UpdateMediaItemOnMediaItemForMediaItemFolderIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  storage?: Maybe<Scalars['JSON']>;
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `mediaItem` being updated. */
export type UpdateMediaItemOnValueFieldForValueFieldValueMediaIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  folderId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  storage?: Maybe<Scalars['JSON']>;
  uploadedAt?: Maybe<Scalars['Datetime']>;
  uploadedIp?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  deletedIp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  mediaInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  mediaType?: Maybe<MediaType>;
  mediaFolderToFolderId?: Maybe<MediaItemFolderIdFkeyInput>;
  valueValues?: Maybe<ValueFieldValueMediaIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `menuItemEntry` being updated. */
export type UpdateMenuItemEntryOnMenuItemEntryForFkMenuItemEntryEntryPatch = {
  id?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** An object where the defined keys will be set on the `menuItemEntry` being updated. */
export type UpdateMenuItemEntryOnMenuItemEntryForFkMenuItemEntryMenuItemPatch = {
  entryId?: Maybe<Scalars['UUID']>;
  entry?: Maybe<FkMenuItemEntryMenuItemInput>;
  entryToEntryIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryEntryInput>;
};

/** An object where the defined keys will be set on the `menuItem` being updated. */
export type UpdateMenuItemOnMenuItemEntryForFkMenuItemEntryMenuItemPatch = {
  parentId?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** An object where the defined keys will be set on the `menuItem` being updated. */
export type UpdateMenuItemOnMenuItemForFkMenuItemEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** An object where the defined keys will be set on the `menuItem` being updated. */
export type UpdateMenuItemOnMenuItemForFkMenuItemParentPatch = {
  id?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** An object where the defined keys will be set on the `menuItem` being updated. */
export type UpdateMenuItemOnMenuItemViewForFkMenuItemViewMenuItemPatch = {
  parentId?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectEnvironmentToEnvironmentId?: Maybe<FkMenuItemEnvironmentInput>;
  menuItemToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemParentInput>;
  menuItemEntryUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemEntryMenuItemInverseInput>;
  menuItemViewUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewMenuItemInverseInput>;
};

/** An object where the defined keys will be set on the `menuItemView` being updated. */
export type UpdateMenuItemViewOnMenuItemViewForFkMenuItemViewMenuItemPatch = {
  modelId?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** An object where the defined keys will be set on the `menuItemView` being updated. */
export type UpdateMenuItemViewOnMenuItemViewForFkMenuItemViewModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  view?: Maybe<FkMenuItemViewMenuItemInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInput>;
};

/** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
export type UpdateModelFieldGroupOnModelFieldForFkFieldGroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
export type UpdateModelFieldGroupOnModelFieldGroupForFkModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** An object where the defined keys will be set on the `modelFieldGroup` being updated. */
export type UpdateModelFieldGroupOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldGroupEnvironmentIdFkeyInput>;
  model?: Maybe<FkModelInput>;
  fields?: Maybe<FkFieldGroupInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnModelFieldForFkFieldGroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnModelFieldForFkModelFieldAutoFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnModelFieldForModelFieldEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  projectId?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnModelFieldForModelFieldModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelAliasFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `modelField` being updated. */
export type UpdateModelFieldOnValueFieldForValueFieldModelFieldFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<ModelFieldType>;
  groupId?: Maybe<Scalars['UUID']>;
  multi?: Maybe<Scalars['Boolean']>;
  required?: Maybe<Scalars['Boolean']>;
  valueCount?: Maybe<IntRangeInput>;
  index?: Maybe<Scalars['Int']>;
  autoSource?: Maybe<Scalars['String']>;
  autoOverride?: Maybe<Scalars['Boolean']>;
  autoTransform?: Maybe<Scalars['String']>;
  autoFieldId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelFieldModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelFieldEnvironmentIdFkeyInput>;
  group?: Maybe<FkFieldGroupInput>;
  modelFieldToAutoFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkModelFieldAutoFieldInput>;
  valueFields?: Maybe<ValueFieldModelFieldFkeyInverseInput>;
  taxonomyLevelsToAliasFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInverseInput>;
  taxonomyLevelsToFragmentFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInverseInput>;
  taxonomyLevelsToParentReferenceFieldIdAndProjectIdAndEnvironmentIdUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnEntryForEntryModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnMenuItemViewForFkMenuItemViewModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelFieldForModelFieldModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelFieldGroupForFkModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelFieldsInheritedForFakeAppContentModelFieldAllForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelForFkModelDefaultTaxonomyLevelPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelForModelEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelForModelValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelParentForModelParentModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelParentForModelParentParentFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnModelParentsRecursiveForFakeAppContentModelParentAllForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnTaxonomyCombinedForFakeAppContentTaxonomyCombinedForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnTaxonomyDynamicForFakeAppContentTaxonomyDynamicForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnTaxonomyLevelForFkTaxonomyLevelModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `model` being updated. */
export type UpdateModelOnValueForValueModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  inherits?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  valueBaseId?: Maybe<Scalars['UUID']>;
  usage?: Maybe<ModelUsage>;
  defaultTaxonomyLevelId?: Maybe<Scalars['UUID']>;
  valueBaseSnapshot?: Maybe<Scalars['JSON']>;
  valueBase?: Maybe<ModelValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelEnvironmentIdFkeyInput>;
  taxonomyLevelToDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInput>;
  entries?: Maybe<EntryModelFkeyInverseInput>;
  fields?: Maybe<ModelFieldModelFkeyInverseInput>;
  parents?: Maybe<ModelParentModelFkeyInverseInput>;
  children?: Maybe<ModelParentParentFkeyInverseInput>;
  values?: Maybe<ValueModelFkeyInverseInput>;
  menuItemViewsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkMenuItemViewModelInverseInput>;
  groups?: Maybe<FkModelInverseInput>;
  taxonomyLevelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInverseInput>;
  fieldsAllBySourceModel?: Maybe<FakeAppContentModelFieldAllForeignKey0InverseInput>;
  fieldsAll?: Maybe<FakeAppContentModelFieldAllForeignKey1InverseInput>;
  parentsAll?: Maybe<FakeAppContentModelParentAllForeignKey0InverseInput>;
  parentsAllByParent?: Maybe<FakeAppContentModelParentAllForeignKey1InverseInput>;
  taxonomyCombinedByModel?: Maybe<FakeAppContentTaxonomyCombinedForeignKey1InverseInput>;
  taxonomyDynamicsUsingId?: Maybe<FakeAppContentTaxonomyDynamicForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `modelParent` being updated. */
export type UpdateModelParentOnModelParentForModelParentEnvironmentIdFkeyPatch = {
  modelId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** An object where the defined keys will be set on the `modelParent` being updated. */
export type UpdateModelParentOnModelParentForModelParentModelFkeyPatch = {
  parentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** An object where the defined keys will be set on the `modelParent` being updated. */
export type UpdateModelParentOnModelParentForModelParentParentFkeyPatch = {
  modelId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ModelParentModelFkeyInput>;
  parent?: Maybe<ModelParentParentFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ModelParentEnvironmentIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnEntryForEntryEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnEntryPublishForEntryPublishEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnEntryVersionForFkEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnMenuItemForFkMenuItemEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnModelFieldForModelFieldEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnModelFieldGroupForModelFieldGroupEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnModelForModelEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnModelParentForModelParentEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnProjectForFakeAppProjectProjectForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnTaxonomyForTaxonomyEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnValueFieldForValueFieldEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnValueForValueEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `projectEnvironment` being updated. */
export type UpdateProjectEnvironmentOnWebsiteForFkWebsiteEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  projectToProjectId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0Input>;
  entryVersions?: Maybe<FkEnvironmentInverseInput>;
  entries?: Maybe<EntryEnvironmentIdFkeyInverseInput>;
  models?: Maybe<ModelEnvironmentIdFkeyInverseInput>;
  entryPublishesUsingId?: Maybe<EntryPublishEnvironmentIdFkeyInverseInput>;
  modelFields?: Maybe<ModelFieldEnvironmentIdFkeyInverseInput>;
  modelParents?: Maybe<ModelParentEnvironmentIdFkeyInverseInput>;
  taxonomies?: Maybe<TaxonomyEnvironmentIdFkeyInverseInput>;
  values?: Maybe<ValueEnvironmentIdFkeyInverseInput>;
  valueFields?: Maybe<ValueFieldEnvironmentIdFkeyInverseInput>;
  menuItemsUsingId?: Maybe<FkMenuItemEnvironmentInverseInput>;
  modelFieldGroups?: Maybe<ModelFieldGroupEnvironmentIdFkeyInverseInput>;
  taxonomyLevelsUsingId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInverseInput>;
  websites?: Maybe<FkWebsiteEnvironmentInverseInput>;
  projectsUsingId?: Maybe<FakeAppProjectProjectForeignKey1InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectEnvironmentForFakeAppProjectProjectEnvironmentForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForFakeAppProjectProjectForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForFakeAppProjectProjectForeignKey1Patch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnPublishTargetForFakeAppContentPublishTargetForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnValueFieldForFakeAppContentValueFieldForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnValueForFakeAppContentValueForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  accountId?: Maybe<Scalars['UUID']>;
  regionId?: Maybe<Scalars['String']>;
  defaultEnvironmentId?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createPayload?: Maybe<Scalars['JSON']>;
  createStatus?: Maybe<Scalars['JSON']>;
  accountToAccountId?: Maybe<FakeAppProjectProjectForeignKey0Input>;
  projectEnvironmentToDefaultEnvironmentId?: Maybe<FakeAppProjectProjectForeignKey1Input>;
  publishTargetsUsingId?: Maybe<FakeAppContentPublishTargetForeignKey0InverseInput>;
  valuesUsingId?: Maybe<FakeAppContentValueForeignKey0InverseInput>;
  valueFieldsUsingId?: Maybe<FakeAppContentValueFieldForeignKey0InverseInput>;
  projectEnvironmentsUsingId?: Maybe<FakeAppProjectProjectEnvironmentForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `projectToken` being updated. */
export type UpdateProjectTokenOnProjectTokenForFkProjectTokenPublishTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  target?: Maybe<FkProjectTokenPublishTargetInput>;
};

/** An object where the defined keys will be set on the `publishTarget` being updated. */
export type UpdatePublishTargetOnEntryPublishForFkEntryPublishTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** An object where the defined keys will be set on the `publishTarget` being updated. */
export type UpdatePublishTargetOnProjectTokenForFkProjectTokenPublishTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** An object where the defined keys will be set on the `publishTarget` being updated. */
export type UpdatePublishTargetOnPublishTargetForFakeAppContentPublishTargetForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** An object where the defined keys will be set on the `publishTarget` being updated. */
export type UpdatePublishTargetOnWebsiteForFkWebsiteTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  projectToProjectId?: Maybe<FakeAppContentPublishTargetForeignKey0Input>;
  entryPublishes?: Maybe<FkEntryPublishTargetInverseInput>;
  projectTokensUsingIdAndProjectId?: Maybe<FkProjectTokenPublishTargetInverseInput>;
  websitesUsingProjectIdAndId?: Maybe<FkWebsiteTargetInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnModelForFkModelDefaultTaxonomyLevelPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelAliasFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelFragmentFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelModelPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelParentReferenceFieldPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForFkTaxonomyLevelTaxonomyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyLevel` being updated. */
export type UpdateTaxonomyLevelOnTaxonomyLevelForTaxonomyLevelEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  modelId?: Maybe<Scalars['UUID']>;
  aliasFieldId?: Maybe<Scalars['UUID']>;
  fragmentFieldId?: Maybe<Scalars['UUID']>;
  fragmentType?: Maybe<Scalars['String']>;
  isNode?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentReferenceFieldId?: Maybe<Scalars['UUID']>;
  inherit?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<FkTaxonomyLevelTaxonomyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyLevelEnvironmentIdFkeyInput>;
  modelToModelIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelModelInput>;
  modelFieldToAliasFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelAliasFieldInput>;
  modelFieldToFragmentFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelFragmentFieldInput>;
  taxonomyLevelToParentIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentInput>;
  modelFieldToParentReferenceFieldIdAndProjectIdAndEnvironmentId?: Maybe<FkTaxonomyLevelParentReferenceFieldInput>;
  modelsUsingIdAndProjectIdAndEnvironmentId?: Maybe<FkModelDefaultTaxonomyLevelInverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnTaxonomyForTaxonomyEntryFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnTaxonomyForTaxonomyEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  projectId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnTaxonomyForTaxonomyParentFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnTaxonomyLevelForFkTaxonomyLevelTaxonomyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0Patch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomy` being updated. */
export type UpdateTaxonomyOnWebsiteForFkWebsiteTaxonomyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  parentId?: Maybe<Scalars['UUID']>;
  alias?: Maybe<Scalars['String']>;
  type?: Maybe<TaxonomyType>;
  entryId?: Maybe<Scalars['UUID']>;
  config?: Maybe<Scalars['JSON']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  parent?: Maybe<TaxonomyParentFkeyInput>;
  entry?: Maybe<TaxonomyEntryFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<TaxonomyEnvironmentIdFkeyInput>;
  levels?: Maybe<FkTaxonomyLevelTaxonomyInverseInput>;
  websitesUsingProjectIdAndEnvironmentIdAndId?: Maybe<FkWebsiteTaxonomyInverseInput>;
  taxonomyPathUsingIdAndProjectIdAndEnvironmentId?: Maybe<FakeAppContentTaxonomyPathForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `taxonomyPath` being updated. */
export type UpdateTaxonomyPathOnTaxonomyPathForFakeAppContentTaxonomyPathForeignKey0Patch = {
  pathAlias?: Maybe<Array<Maybe<Scalars['String']>>>;
  pathId?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  path?: Maybe<FakeAppContentTaxonomyPathForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForFakeAppContentValueFieldForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldEntryFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldModelFieldFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldValueMediaIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  valueValueId?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `valueField` being updated. */
export type UpdateValueFieldOnValueFieldForValueFieldValueValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  valueId?: Maybe<Scalars['UUID']>;
  modelFieldId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  index?: Maybe<Scalars['Int']>;
  hint?: Maybe<Scalars['String']>;
  valueScalar?: Maybe<Scalars['JSON']>;
  valueMediaId?: Maybe<Scalars['UUID']>;
  valueEntryId?: Maybe<Scalars['UUID']>;
  value?: Maybe<ValueFieldValueFkeyInput>;
  modelField?: Maybe<ValueFieldModelFieldFkeyInput>;
  valueMedia?: Maybe<ValueFieldValueMediaIdFkeyInput>;
  entryToValueEntryIdAndProjectIdAndEnvironmentId?: Maybe<ValueFieldEntryFkeyInput>;
  valueValue?: Maybe<ValueFieldValueValueFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueFieldEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueFieldForeignKey0Input>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnEntryVersionForFkValuePatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnModelForModelValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnValueFieldForValueFieldValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnValueFieldForValueFieldValueValueFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnValueForFakeAppContentValueForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  environmentId?: Maybe<Scalars['UUID']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnValueForValueEnvironmentIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  modelId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `value` being updated. */
export type UpdateValueOnValueForValueModelFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  model?: Maybe<ValueModelFkeyInput>;
  projectEnvironmentToEnvironmentId?: Maybe<ValueEnvironmentIdFkeyInput>;
  projectToProjectId?: Maybe<FakeAppContentValueForeignKey0Input>;
  entryVersions?: Maybe<FkValueInverseInput>;
  modelValueBase?: Maybe<ModelValueFkeyInverseInput>;
  fields?: Maybe<ValueFieldValueFkeyInverseInput>;
  valueValues?: Maybe<ValueFieldValueValueFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `webhookLog` being updated. */
export type UpdateWebhookLogOnWebhookLogForWebhookLogWebhookIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  webhookRunId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

/** An object where the defined keys will be set on the `webhookLog` being updated. */
export type UpdateWebhookLogOnWebhookLogForWebhookLogWebhookRunFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  level?: Maybe<WebhookLogLevel>;
  payload?: Maybe<Scalars['String']>;
  webhookToWebhookId?: Maybe<WebhookLogWebhookIdFkeyInput>;
  webhookRunToWebhookRunId?: Maybe<WebhookLogWebhookRunFkeyInput>;
};

/** An object where the defined keys will be set on the `webhook` being updated. */
export type UpdateWebhookOnWebhookLogForWebhookLogWebhookIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `webhook` being updated. */
export type UpdateWebhookOnWebhookRunForWebhookRunWebhookIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  headers?: Maybe<Scalars['KeyValueHash']>;
  payload?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookIdFkeyInverseInput>;
  webhookRunsUsingId?: Maybe<WebhookRunWebhookIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `webhookRun` being updated. */
export type UpdateWebhookRunOnWebhookLogForWebhookLogWebhookRunFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  webhookId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `webhookRun` being updated. */
export type UpdateWebhookRunOnWebhookRunForWebhookRunWebhookIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  finishedAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<WebhookRunStatus>;
  webhookToWebhookId?: Maybe<WebhookRunWebhookIdFkeyInput>;
  webhookLogsUsingId?: Maybe<WebhookLogWebhookRunFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `website` being updated. */
export type UpdateWebsiteOnWebsiteForFkWebsiteEnvironmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  projectId?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** An object where the defined keys will be set on the `website` being updated. */
export type UpdateWebsiteOnWebsiteForFkWebsiteTargetPatch = {
  id?: Maybe<Scalars['UUID']>;
  environmentId?: Maybe<Scalars['UUID']>;
  taxonomyId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

/** An object where the defined keys will be set on the `website` being updated. */
export type UpdateWebsiteOnWebsiteForFkWebsiteTaxonomyPatch = {
  id?: Maybe<Scalars['UUID']>;
  targetId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  urlFormat?: Maybe<Scalars['String']>;
  taxonomyToProjectIdAndEnvironmentIdAndTaxonomyId?: Maybe<FkWebsiteTaxonomyInput>;
  publishTargetToProjectIdAndTargetId?: Maybe<FkWebsiteTargetInput>;
  projectEnvironmentToEnvironmentId?: Maybe<FkWebsiteEnvironmentInput>;
};

export type ListAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAccountsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'AccountsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'key' | 'name'>
      & { projects: (
        { __typename?: 'ProjectsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'key' | 'name'>
        )>> }
      ) }
    )>> }
  )> }
);

export type GetAccountQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'key' | 'name'>
    & { projects: (
      { __typename?: 'ProjectsConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'key' | 'name' | 'deletedAt'>
        & { projectEnvironments: (
          { __typename?: 'ProjectEnvironmentsConnection' }
          & { nodes: Array<Maybe<(
            { __typename?: 'ProjectEnvironment' }
            & Pick<ProjectEnvironment, 'id' | 'key' | 'name' | 'alias' | 'deletedAt'>
          )>> }
        ) }
      )>> }
    ) }
  )> }
);

export type ListChangesQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  dateFrom: Scalars['Datetime'];
  dateTo: Scalars['Datetime'];
}>;


export type ListChangesQuery = (
  { __typename?: 'Query' }
  & { entries?: Maybe<(
    { __typename?: 'EntriesConnection' }
    & Pick<EntriesConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'name' | 'createdAt' | 'modifiedAt' | 'deletedAt'>
    )>> }
  )>, models?: Maybe<(
    { __typename?: 'ModelsConnection' }
    & Pick<ModelsConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'createdAt' | 'modifiedAt' | 'deletedAt'>
    )>> }
  )>, modelFields?: Maybe<(
    { __typename?: 'ModelFieldsConnection' }
    & Pick<ModelFieldsConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelField' }
      & Pick<ModelField, 'id' | 'name' | 'createdAt' | 'modifiedAt' | 'deletedAt'>
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type ListEnvDiffSummaryQueryVariables = Exact<{
  project: Scalars['UUID'];
  environments: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type ListEnvDiffSummaryQuery = (
  { __typename?: 'Query' }
  & { models?: Maybe<(
    { __typename?: 'ModelsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'environmentId' | 'name' | 'alias' | 'createdAt' | 'modifiedAt' | 'deletedAt'>
    )>> }
  )>, modelFields?: Maybe<(
    { __typename?: 'ModelFieldsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelField' }
      & Pick<ModelField, 'id' | 'environmentId' | 'name' | 'alias' | 'createdAt' | 'modifiedAt' | 'deletedAt'>
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type WatchContentSubscriptionVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type WatchContentSubscription = (
  { __typename?: 'Subscription' }
  & { contentChanged?: Maybe<(
    { __typename?: 'RecordChangedPayload' }
    & Pick<RecordChangedPayload, 'event' | 'table' | 'id' | 'parent'>
  )> }
);

export type CreateEntryMutationVariables = Exact<{
  input: EntryInput;
}>;


export type CreateEntryMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateEntryPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id'>
    )> }
  )> }
);

export type ListEntriesQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  filter?: Maybe<EntryFilter>;
  sort?: Maybe<Array<EntriesOrderBy> | EntriesOrderBy>;
}>;


export type ListEntriesQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'EntriesConnection' }
    & Pick<EntriesConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'name' | 'modelId' | 'modifiedAt' | 'createdAt' | 'latestVersionId'>
      & { entryPublishes: (
        { __typename?: 'EntryPublishesConnection' }
        & Pick<EntryPublishesConnection, 'totalCount'>
      ) }
    )>> }
  )> }
);

export type GetEntryQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetEntryQuery = (
  { __typename?: 'Query' }
  & { links: Query['entryWebsites'] }
  & { entity?: Maybe<(
    { __typename?: 'Entry' }
    & EntryDetailFragment
  )> }
);

export type GetEntrySummaryQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetEntrySummaryQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Entry' }
    & Pick<Entry, 'id' | 'modelId' | 'name' | 'modifiedAt'>
    & { entryPublishes: (
      { __typename?: 'EntryPublishesConnection' }
      & Pick<EntryPublishesConnection, 'totalCount'>
    ) }
  )> }
);

export type UpdateEntryMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: EntryPatch;
}>;


export type UpdateEntryMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateEntryPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Entry' }
      & EntryDetailFragment
    )> }
  )> }
);

export type PublishVersion2MutationVariables = Exact<{
  version: Scalars['UUID'];
  entry: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  target: Scalars['UUID'];
}>;


export type PublishVersion2Mutation = (
  { __typename?: 'Mutation' }
  & { publish?: Maybe<(
    { __typename?: 'PublishVersionPayload' }
    & Pick<PublishVersionPayload, 'clientMutationId'>
  )> }
);

export type UnpublishVersion2MutationVariables = Exact<{
  entry: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  target: Scalars['UUID'];
}>;


export type UnpublishVersion2Mutation = (
  { __typename?: 'Mutation' }
  & { publish?: Maybe<(
    { __typename?: 'UnpublishVersion2Payload' }
    & Pick<UnpublishVersion2Payload, 'clientMutationId'>
  )> }
);

export type CopyVersionMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type CopyVersionMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CopyVersionPayload' }
    & { entity?: Maybe<(
      { __typename?: 'EntryVersion' }
      & Pick<EntryVersion, 'id'>
    )> }
  )> }
);

export type CopyVersionToNewEntryMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type CopyVersionToNewEntryMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CopyVersion2Payload' }
    & { entity?: Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'latestVersionId'>
    )> }
  )> }
);

export type UpdateEntryVersionMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: EntryVersionPatch;
}>;


export type UpdateEntryVersionMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateEntryVersionPayload' }
    & { entity?: Maybe<(
      { __typename?: 'EntryVersion' }
      & EntryVersionDetailFragment
    )> }
  )> }
);

export type EntryDetailFragment = (
  { __typename?: 'Entry' }
  & Pick<Entry, 'id' | 'createdAt' | 'modifiedAt' | 'deletedAt' | 'name' | 'modelId' | 'projectId'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, modifiedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, versions: (
    { __typename?: 'EntryVersionsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'EntryVersion' }
      & EntryVersionDetailFragment
    )>> }
  ), entryPublishes: (
    { __typename?: 'EntryPublishesConnection' }
    & Pick<EntryPublishesConnection, 'totalCount'>
  ), taxonomies: (
    { __typename?: 'TaxonomyCombinedsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'TaxonomyCombined' }
      & Pick<TaxonomyCombined, 'pathAlias'>
    )>> }
  ) }
);

export type EntryVersionDetailFragment = (
  { __typename?: 'EntryVersion' }
  & Pick<EntryVersion, 'id' | 'index' | 'valueId' | 'createdAt' | 'modifiedAt'>
  & { entryPublishes: (
    { __typename?: 'EntryPublishesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'EntryPublish' }
      & Pick<EntryPublish, 'modifiedAt'>
      & { target?: Maybe<(
        { __typename?: 'PublishTarget' }
        & Pick<PublishTarget, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type ListEntriesExportQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type ListEntriesExportQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'EntriesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'name' | 'modelId' | 'modifiedAt' | 'createdAt'>
      & { versions: (
        { __typename?: 'EntryVersionsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'EntryVersion' }
          & Pick<EntryVersion, 'id' | 'valueId' | 'index' | 'createdAt' | 'modifiedAt'>
        )>> }
      ) }
    )>> }
  )> }
);

export type ListValuesHierarchyQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type ListValuesHierarchyQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ValueHierarchiesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ValueHierarchy' }
      & Pick<ValueHierarchy, 'id'>
    )>> }
  )> }
);

export type ListValuesQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  ids: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type ListValuesQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ValuesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Value' }
      & Pick<Value, 'id' | 'modelId'>
      & { fields: (
        { __typename?: 'ValueFieldsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'ValueField' }
          & Pick<ValueField, 'id' | 'modelFieldId' | 'index' | 'hint' | 'valueScalar' | 'valueMediaId' | 'valueEntryId' | 'valueValueId'>
        )>> }
      ) }
    )>> }
  )> }
);

export type MenuListNodesQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  parent?: Maybe<Scalars['UUID']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
}>;


export type MenuListNodesQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'MenuItemsConnection' }
    & Pick<MenuItemsConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'label' | 'index'>
      & { entry?: Maybe<(
        { __typename?: 'MenuItemEntry' }
        & Pick<MenuItemEntry, 'id' | 'entryId'>
      )>, view?: Maybe<(
        { __typename?: 'MenuItemView' }
        & Pick<MenuItemView, 'id' | 'modelId'>
      )>, children: (
        { __typename?: 'MenuItemsConnection' }
        & Pick<MenuItemsConnection, 'totalCount'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ) }
  )> }
);

export type CreateMenuItemMutationVariables = Exact<{
  input: MenuItemInput;
}>;


export type CreateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateMenuItemPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id'>
    )> }
  )> }
);

export type UpdateMenuItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: MenuItemPatch;
}>;


export type UpdateMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateMenuItemPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id'>
    )> }
  )> }
);

export type GetMenuViewQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetMenuViewQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'MenuItemView' }
    & Pick<MenuItemView, 'id' | 'modelId'>
  )> }
);

export type GetMenuItemQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetMenuItemQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'id' | 'label'>
    & { entry?: Maybe<(
      { __typename?: 'MenuItemEntry' }
      & Pick<MenuItemEntry, 'id' | 'entryId'>
    )>, view?: Maybe<(
      { __typename?: 'MenuItemView' }
      & Pick<MenuItemView, 'id' | 'modelId'>
    )> }
  )> }
);

export type WatchMenuSubscriptionVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type WatchMenuSubscription = (
  { __typename?: 'Subscription' }
  & { menuChanged?: Maybe<(
    { __typename?: 'RecordChangedPayload' }
    & Pick<RecordChangedPayload, 'event' | 'table' | 'id' | 'parent' | 'payload'>
  )> }
);

export type MoveMenuItemMutationVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  id: Scalars['UUID'];
  parent?: Maybe<Scalars['UUID']>;
  before?: Maybe<Scalars['UUID']>;
}>;


export type MoveMenuItemMutation = (
  { __typename?: 'Mutation' }
  & { moveMenuItem?: Maybe<(
    { __typename?: 'MoveMenuItemPayload' }
    & Pick<MoveMenuItemPayload, 'clientMutationId'>
  )> }
);

export type WatchModelsSubscriptionVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type WatchModelsSubscription = (
  { __typename?: 'Subscription' }
  & { modelChanged?: Maybe<(
    { __typename?: 'RecordChangedPayload' }
    & Pick<RecordChangedPayload, 'event' | 'table' | 'id' | 'parent'>
  )> }
);

export type ListModelsQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  filter?: Maybe<ModelFilter>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
}>;


export type ListModelsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ModelsConnection' }
    & Pick<ModelsConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'Model' }
      & { entries: (
        { __typename?: 'EntriesConnection' }
        & Pick<EntriesConnection, 'totalCount'>
      ) }
      & ModelDetailFragment
    )>> }
  )> }
);

export type CreateModelMutationVariables = Exact<{
  input: ModelInput;
}>;


export type CreateModelMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateModelPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id'>
    )> }
  )> }
);

export type CreateModelParentMutationVariables = Exact<{
  input: ModelParentInput;
}>;


export type CreateModelParentMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateModelParentPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ModelParent' }
      & Pick<ModelParent, 'modelId' | 'parentId' | 'projectId'>
    )> }
  )> }
);

export type GetModelQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetModelQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Model' }
    & ModelDetailFragment
  )> }
);

export type GetModelByAliasQueryVariables = Exact<{
  alias: Scalars['String'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetModelByAliasQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Model' }
    & ModelDetailFragment
  )> }
);

export type UpdateModelMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: ModelPatch;
}>;


export type UpdateModelMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateModelPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Model' }
      & ModelDetailFragment
    )> }
  )> }
);

export type PutModelMutationVariables = Exact<{
  model?: Maybe<ModelInput>;
  usage: Scalars['String'];
  parents?: Maybe<Array<Scalars['UUID']> | Scalars['UUID']>;
  fieldOrder?: Maybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type PutModelMutation = (
  { __typename?: 'Mutation' }
  & { putModel?: Maybe<(
    { __typename?: 'PutModelPayload' }
    & { model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'alias'>
    )> }
  )> }
);

export type ListModelFieldsAllQueryVariables = Exact<{
  model: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type ListModelFieldsAllQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ModelFieldsInheritedsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelFieldsInherited' }
      & ModelFieldAllDetailFragment
    )>> }
  )> }
);

export type CreateModelFieldMutationVariables = Exact<{
  input: ModelFieldInput;
}>;


export type CreateModelFieldMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateModelFieldPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ModelField' }
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & ModelDetailFragment
      )> }
    )> }
  )> }
);

export type GetModelFieldQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetModelFieldQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'ModelField' }
    & ModelFieldDetailFragment
  )> }
);

export type UpdateModelFieldMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: ModelFieldPatch;
}>;


export type UpdateModelFieldMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateModelFieldPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ModelField' }
      & ModelFieldDetailFragment
    )> }
  )> }
);

export type UpdateModelSnapshotMutationVariables = Exact<{
  model: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type UpdateModelSnapshotMutation = (
  { __typename?: 'Mutation' }
  & { updateModelSnapshot?: Maybe<(
    { __typename?: 'UpdateModelSnapshotPayload' }
    & Pick<UpdateModelSnapshotPayload, 'clientMutationId'>
  )> }
);

export type ModelDetailFragment = (
  { __typename?: 'Model' }
  & Pick<Model, 'id' | 'projectId' | 'createdAt' | 'name' | 'alias' | 'usage' | 'inherits' | 'deletedAt' | 'valueBaseId' | 'defaultTaxonomyLevelId'>
  & { fieldsAll: (
    { __typename?: 'ModelFieldsInheritedsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelFieldsInherited' }
      & ModelFieldAllDetailFragment
    )>> }
  ), parents: (
    { __typename?: 'ModelParentsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelParent' }
      & Pick<ModelParent, 'parentId'>
      & { parent?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'name' | 'alias'>
      )> }
    )>> }
  ), parentsAll: (
    { __typename?: 'ModelParentsRecursivesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ModelParentsRecursive' }
      & Pick<ModelParentsRecursive, 'parentId'>
      & { parent?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'name' | 'alias'>
      )> }
    )>> }
  ) }
);

export type ModelFieldDetailFragment = (
  { __typename?: 'ModelField' }
  & Pick<ModelField, 'id' | 'projectId' | 'name' | 'alias' | 'type' | 'config' | 'deletedAt' | 'index'>
);

export type ModelFieldAllDetailFragment = (
  { __typename?: 'ModelFieldsInherited' }
  & Pick<ModelFieldsInherited, 'id' | 'projectId' | 'name' | 'alias' | 'type' | 'config' | 'required' | 'deletedAt' | 'multi' | 'index' | 'autoSource' | 'autoOverride' | 'autoTransform' | 'autoFieldId'>
  & { sourceModel?: Maybe<(
    { __typename?: 'Model' }
    & Pick<Model, 'id' | 'name' | 'alias'>
  )>, valueCount?: Maybe<(
    { __typename?: 'IntRange' }
    & { start?: Maybe<(
      { __typename?: 'IntRangeBound' }
      & Pick<IntRangeBound, 'value' | 'inclusive'>
    )>, end?: Maybe<(
      { __typename?: 'IntRangeBound' }
      & Pick<IntRangeBound, 'value' | 'inclusive'>
    )> }
  )> }
);

export type ListTaxonomyQueryVariables = Exact<{
  environment: Scalars['UUID'];
  project: Scalars['UUID'];
  parent?: Maybe<Scalars['UUID']>;
}>;


export type ListTaxonomyQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'TaxonomiesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Taxonomy' }
      & Pick<Taxonomy, 'id' | 'alias' | 'type' | 'parentId'>
    )>> }
  )> }
);

export type ListTaxonomyItemsQueryVariables = Exact<{
  environment: Scalars['UUID'];
  project: Scalars['UUID'];
  taxonomy?: Maybe<Scalars['UUID']>;
}>;


export type ListTaxonomyItemsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'TaxonomyLevelItemsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'TaxonomyLevelItem' }
      & Pick<TaxonomyLevelItem, 'alias' | 'fragment' | 'entryId' | 'targetId'>
    )>> }
  )> }
);

export type GetTaxonomyQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type GetTaxonomyQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Taxonomy' }
    & Pick<Taxonomy, 'alias' | 'type' | 'deletedAt'>
    & { entry: Taxonomy['entryId'] }
    & { path?: Maybe<(
      { __typename?: 'TaxonomyPath' }
      & Pick<TaxonomyPath, 'pathAlias'>
    )>, websitesByProjectIdAndEnvironmentIdAndTaxonomyId: (
      { __typename?: 'WebsitesConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'Website' }
        & Pick<Website, 'id' | 'name'>
      )>> }
    ), levels: (
      { __typename?: 'TaxonomyLevelsConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'TaxonomyLevel' }
        & Pick<TaxonomyLevel, 'fragmentType' | 'isNode' | 'inherit'>
        & { levelId: TaxonomyLevel['id'], parent: TaxonomyLevel['parentId'], aliasField: TaxonomyLevel['aliasFieldId'], fragmentField: TaxonomyLevel['fragmentFieldId'], model: TaxonomyLevel['modelId'] }
        & { modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId: (
          { __typename?: 'ModelsConnection' }
          & { nodes: Array<Maybe<(
            { __typename?: 'Model' }
            & Pick<Model, 'id'>
          )>> }
        ) }
      )>> }
    ) }
  )> }
);

export type CreateTaxonomyMutationVariables = Exact<{
  input: TaxonomyInput;
}>;


export type CreateTaxonomyMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateTaxonomyPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Taxonomy' }
      & Pick<Taxonomy, 'id'>
    )> }
  )> }
);

export type UpdateTaxonomyMutationVariables = Exact<{
  project: Scalars['UUID'];
  id: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: TaxonomyPatch;
}>;


export type UpdateTaxonomyMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateTaxonomyPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Taxonomy' }
      & Pick<Taxonomy, 'id'>
    )> }
  )> }
);

export type DeleteTaxonomyMutationVariables = Exact<{
  project: Scalars['UUID'];
  id: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type DeleteTaxonomyMutation = (
  { __typename?: 'Mutation' }
  & { del?: Maybe<(
    { __typename?: 'DeleteTaxonomyPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Taxonomy' }
      & Pick<Taxonomy, 'id'>
    )> }
  )> }
);

export type WatchTaxonomySubscriptionVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type WatchTaxonomySubscription = (
  { __typename?: 'Subscription' }
  & { taxonomyChanged?: Maybe<(
    { __typename?: 'RecordChangedPayload' }
    & Pick<RecordChangedPayload, 'id' | 'parent' | 'event'>
  )> }
);

export type TaxonomyListNodesQueryVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  target: Scalars['UUID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  parent: Array<Scalars['String']> | Scalars['String'];
}>;


export type TaxonomyListNodesQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'TaxonomyListNodesEntriesConnection' }
    & Pick<TaxonomyListNodesEntriesConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'TaxonomyListNodesEntry' }
      & Pick<TaxonomyListNodesEntry, 'path' | 'entryId' | 'taxonomyId' | 'type'>
      & { entry?: Maybe<(
        { __typename?: 'Entry' }
        & Pick<Entry, 'id' | 'name'>
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ) }
  )> }
);

export type GetValueQueryVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetValueQuery = (
  { __typename?: 'Query' }
  & { value?: Maybe<(
    { __typename?: 'Value' }
    & ValueDetailFragment
  )> }
);

export type CreateValueMutationVariables = Exact<{
  model: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type CreateValueMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateValuePayload' }
    & { entity?: Maybe<(
      { __typename?: 'Value' }
      & ValueDetailFragment
    )> }
  )> }
);

export type GetValueFieldQueryVariables = Exact<{
  field: Scalars['UUID'];
  value: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type GetValueFieldQuery = (
  { __typename?: 'Query' }
  & { valueFields?: Maybe<(
    { __typename?: 'ValueFieldsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ValueField' }
      & ValueFieldDetailFragment
    )>> }
  )> }
);

export type UpdateValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  create?: Maybe<Array<ValueFieldValueFkeyValueFieldCreateInput> | ValueFieldValueFkeyValueFieldCreateInput>;
  updates?: Maybe<Array<ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate> | ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate>;
  deletes?: Maybe<Array<ValueFieldValueFieldPkeyDelete> | ValueFieldValueFieldPkeyDelete>;
}>;


export type UpdateValueMutation = (
  { __typename?: 'Mutation' }
  & { updateValue?: Maybe<(
    { __typename?: 'UpdateValuePayload' }
    & { value?: Maybe<(
      { __typename?: 'Value' }
      & { entryVersions: (
        { __typename?: 'EntryVersionsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'EntryVersion' }
          & Pick<EntryVersion, 'id' | 'modifiedAt'>
          & { modifiedBy?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      ), fields: (
        { __typename?: 'ValueFieldsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'ValueField' }
          & ValueFieldDetailFragment
        )>> }
      ) }
    )> }
  )> }
);

export type ValueDetailFragment = (
  { __typename?: 'Value' }
  & Pick<Value, 'id' | 'modifiedAt' | 'modelId'>
  & { fields: (
    { __typename?: 'ValueFieldsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ValueField' }
      & ValueFieldDetailFragment
    )>> }
  ) }
);

export type ValueFieldDetailFragment = (
  { __typename?: 'ValueField' }
  & Pick<ValueField, 'id' | 'hint' | 'index' | 'valueScalar' | 'valueMediaId' | 'valueValueId' | 'valueEntryId' | 'modelFieldId'>
  & { valueValue?: Maybe<(
    { __typename?: 'Value' }
    & Pick<Value, 'id' | 'modelId'>
  )>, valueEntryProjectEnvironment?: Maybe<(
    { __typename?: 'Entry' }
    & Pick<Entry, 'id' | 'name' | 'modifiedAt' | 'modelId'>
    & { entryPublishes: (
      { __typename?: 'EntryPublishesConnection' }
      & Pick<EntryPublishesConnection, 'totalCount'>
    ) }
  )> }
);

export type UpdateValueFieldMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
  patch: ValueFieldPatch;
}>;


export type UpdateValueFieldMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateValueFieldPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ValueField' }
      & Pick<ValueField, 'id'>
    )> }
  )> }
);

export type CreateValueFieldMutationVariables = Exact<{
  value: ValueFieldInput;
}>;


export type CreateValueFieldMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateValueFieldPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ValueField' }
      & Pick<ValueField, 'id'>
    )> }
  )> }
);

export type CreateValueRawMutationVariables = Exact<{
  input: ValueInput;
}>;


export type CreateValueRawMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateValuePayload' }
    & { entity?: Maybe<(
      { __typename?: 'Value' }
      & Pick<Value, 'id'>
    )> }
  )> }
);

export type WatchMediaSubscriptionVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type WatchMediaSubscription = (
  { __typename?: 'Subscription' }
  & { mediaChanged?: Maybe<(
    { __typename?: 'RecordChangedPayload' }
    & Pick<RecordChangedPayload, 'event' | 'table' | 'id' | 'parent'>
  )> }
);

export type ListMediaItemsQueryVariables = Exact<{
  project: Scalars['UUID'];
  folder?: Maybe<Scalars['UUID']>;
  filter?: Maybe<MediaItemFilter>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
}>;


export type ListMediaItemsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'MediaItemsConnection' }
    & Pick<MediaItemsConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'MediaItem' }
      & MediaItemDetailFragment
    )>> }
  )> }
);

export type ListMediaFoldersQueryVariables = Exact<{
  project: Scalars['UUID'];
  parent?: Maybe<Scalars['UUID']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
}>;


export type ListMediaFoldersQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'MediaFoldersConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'MediaFolder' }
      & MediaFolderDetailFragment
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ) }
  )> }
);

export type GetMediaFolderQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetMediaFolderQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'MediaFolder' }
    & MediaFolderDetailFragment
  )> }
);

export type MediaFolderContentsQueryVariables = Exact<{
  project: Scalars['UUID'];
  folder?: Maybe<Scalars['UUID']>;
}>;


export type MediaFolderContentsQuery = (
  { __typename?: 'Query' }
  & { items?: Maybe<(
    { __typename?: 'MediaItemsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'MediaItem' }
      & MediaItemDetailFragment
    )>> }
  )>, folders?: Maybe<(
    { __typename?: 'MediaFoldersConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'MediaFolder' }
      & MediaFolderDetailFragment
    )>> }
  )> }
);

export type GetMediaItemQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetMediaItemQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'MediaItem' }
    & MediaItemDetailFragment
  )> }
);

export type UpdateMediaItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: MediaItemPatch;
}>;


export type UpdateMediaItemMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateMediaItemPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MediaItem' }
      & MediaItemDetailFragment
    )> }
  )> }
);

export type UpdateMediaFolderMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: MediaFolderPatch;
}>;


export type UpdateMediaFolderMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateMediaFolderPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MediaFolder' }
      & MediaFolderDetailFragment
    )> }
  )> }
);

export type GenerateMediaUploadUrlMutationVariables = Exact<{
  input: MediaUploadUrlInput;
}>;


export type GenerateMediaUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { mediaUploadUrl?: Maybe<(
    { __typename?: 'MediaUploadUrlOutput' }
    & Pick<MediaUploadUrlOutput, 'url' | 'headers'>
  )> }
);

export type CreateMediaItemMutationVariables = Exact<{
  input: MediaItemInput;
}>;


export type CreateMediaItemMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateMediaItemPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MediaItem' }
      & MediaItemDetailFragment
    )> }
  )> }
);

export type CreateMediaFolderMutationVariables = Exact<{
  input: MediaFolderInput;
}>;


export type CreateMediaFolderMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateMediaFolderPayload' }
    & { entity?: Maybe<(
      { __typename?: 'MediaFolder' }
      & Pick<MediaFolder, 'id' | 'name'>
    )> }
  )> }
);

export type MediaItemDetailFragment = (
  { __typename?: 'MediaItem' }
  & Pick<MediaItem, 'id' | 'projectId' | 'name' | 'contentType' | 'size' | 'storage' | 'createdAt' | 'uploadedAt' | 'folderId' | 'mediaInfo' | 'mediaType'>
);

export type MediaFolderDetailFragment = (
  { __typename?: 'MediaFolder' }
  & Pick<MediaFolder, 'id' | 'projectId' | 'name' | 'parentId' | 'path'>
  & { parent?: Maybe<(
    { __typename?: 'MediaFolder' }
    & Pick<MediaFolder, 'id' | 'name'>
  )>, children: (
    { __typename?: 'MediaFoldersConnection' }
    & Pick<MediaFoldersConnection, 'totalCount'>
  ) }
);

export type ListProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListProjectsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ProjectsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'key' | 'name' | 'deletedAt'>
      & { defaultEnvironment?: Maybe<(
        { __typename?: 'ProjectEnvironment' }
        & Pick<ProjectEnvironment, 'id' | 'key'>
      )>, account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type ListProjectTokensQueryVariables = Exact<{
  project: Scalars['UUID'];
}>;


export type ListProjectTokensQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'ProjectTokensConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'ProjectToken' }
      & Pick<ProjectToken, 'id' | 'token' | 'createdAt' | 'targetId'>
    )>> }
  )> }
);

export type CreateProjectTokenMutationVariables = Exact<{
  project: Scalars['UUID'];
  target: Scalars['UUID'];
}>;


export type CreateProjectTokenMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateProjectTokenPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ProjectToken' }
      & Pick<ProjectToken, 'id' | 'token' | 'createdAt'>
    )> }
  )> }
);

export type UpdateProjectTokenMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: ProjectTokenPatch;
}>;


export type UpdateProjectTokenMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateProjectTokenPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ProjectToken' }
      & Pick<ProjectToken, 'id' | 'token' | 'createdAt'>
    )> }
  )> }
);

export type DeleteProjectTokenMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteProjectTokenMutation = (
  { __typename?: 'Mutation' }
  & { del?: Maybe<(
    { __typename?: 'DeleteProjectTokenPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ProjectToken' }
      & Pick<ProjectToken, 'id' | 'token' | 'createdAt'>
    )> }
  )> }
);

export type UpdateProjectDefaultEnvironmentMutationVariables = Exact<{
  project: Scalars['UUID'];
  environment: Scalars['UUID'];
}>;


export type UpdateProjectDefaultEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectDefaultEnvironment?: Maybe<(
    { __typename?: 'UpdateProjectDefaultEnvironmentPayload' }
    & Pick<UpdateProjectDefaultEnvironmentPayload, 'clientMutationId'>
  )> }
);

export type UpdateEnvironmentMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: ProjectEnvironmentPatch;
}>;


export type UpdateEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateProjectEnvironmentPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ProjectEnvironment' }
      & Pick<ProjectEnvironment, 'id' | 'name'>
    )> }
  )> }
);

export type CreateEnvironmentMutationVariables = Exact<{
  input: ProjectEnvironmentInput;
}>;


export type CreateEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateProjectEnvironmentPayload' }
    & { entity?: Maybe<(
      { __typename?: 'ProjectEnvironment' }
      & Pick<ProjectEnvironment, 'id' | 'name'>
    )> }
  )> }
);

export type CreatePublishTargetMutationVariables = Exact<{
  input: PublishTargetInput;
}>;


export type CreatePublishTargetMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreatePublishTargetPayload' }
    & { entity?: Maybe<(
      { __typename?: 'PublishTarget' }
      & Pick<PublishTarget, 'id' | 'name' | 'key'>
    )> }
  )> }
);

export type UpdatePublishTargetMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  patch: PublishTargetPatch;
}>;


export type UpdatePublishTargetMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdatePublishTargetPayload' }
    & { entity?: Maybe<(
      { __typename?: 'PublishTarget' }
      & Pick<PublishTarget, 'id' | 'name' | 'key'>
    )> }
  )> }
);

export type CopyEnvironmentMutationVariables = Exact<{
  input: CopyEnvironmentInput;
}>;


export type CopyEnvironmentMutation = (
  { __typename?: 'Mutation' }
  & { copyEnvironment?: Maybe<(
    { __typename?: 'CopyEnvironmentPayload' }
    & Pick<CopyEnvironmentPayload, 'clientMutationId'>
  )> }
);

export type GetProjectByKeyQueryVariables = Exact<{
  account: Scalars['UUID'];
  key: Scalars['String'];
}>;


export type GetProjectByKeyQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'key' | 'name' | 'deletedAt'>
    & { defaultEnvironment?: Maybe<(
      { __typename?: 'ProjectEnvironment' }
      & Pick<ProjectEnvironment, 'id' | 'key'>
    )>, projectEnvironments: (
      { __typename?: 'ProjectEnvironmentsConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'ProjectEnvironment' }
        & Pick<ProjectEnvironment, 'id' | 'key' | 'name' | 'alias' | 'deletedAt'>
        & { models: (
          { __typename?: 'ModelsConnection' }
          & Pick<ModelsConnection, 'totalCount'>
        ), websites: (
          { __typename?: 'WebsitesConnection' }
          & { nodes: Array<Maybe<(
            { __typename?: 'Website' }
            & Pick<Website, 'id' | 'name' | 'urlFormat' | 'targetId' | 'projectId' | 'environmentId'>
            & { projectEnvironmentTaxonomy?: Maybe<(
              { __typename?: 'Taxonomy' }
              & { path?: Maybe<(
                { __typename?: 'TaxonomyPath' }
                & Pick<TaxonomyPath, 'pathAlias'>
              )> }
            )> }
          )>> }
        ) }
      )>> }
    ), publishTargets: (
      { __typename?: 'PublishTargetsConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'PublishTarget' }
        & Pick<PublishTarget, 'id' | 'projectId' | 'key' | 'name'>
      )>> }
    ) }
  )> }
);

export type BackgroundTaskMutationVariables = Exact<{
  task: Scalars['String'];
  project: Scalars['UUID'];
  environment?: Maybe<Scalars['UUID']>;
  target?: Maybe<Scalars['UUID']>;
}>;


export type BackgroundTaskMutation = (
  { __typename?: 'Mutation' }
  & { backgroundTask?: Maybe<(
    { __typename?: 'BackgroundTaskPayload' }
    & Pick<BackgroundTaskPayload, 'clientMutationId'>
  )> }
);

export type ProjectSearchQueryVariables = Exact<{
  project: Scalars['UUID'];
  input: Scalars['String'];
}>;


export type ProjectSearchQuery = (
  { __typename?: 'Query' }
  & { entries?: Maybe<(
    { __typename?: 'EntriesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'name'>
    )>> }
  )>, models?: Maybe<(
    { __typename?: 'ModelsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name'>
    )>> }
  )>, mediaItems?: Maybe<(
    { __typename?: 'MediaItemsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'MediaItem' }
      & Pick<MediaItem, 'id' | 'name' | 'folderId'>
    )>> }
  )> }
);

export type ListWebhooksQueryVariables = Exact<{
  project: Scalars['UUID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  filter?: Maybe<WebhookFilter>;
}>;


export type ListWebhooksQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'WebhooksConnection' }
    & Pick<WebhooksConnection, 'totalCount'>
    & { nodes: Array<Maybe<(
      { __typename?: 'Webhook' }
      & WebhookDetailFragment
    )>> }
  )> }
);

export type CreateWebhookMutationVariables = Exact<{
  input: WebhookInput;
}>;


export type CreateWebhookMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateWebhookPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Webhook' }
      & Pick<Webhook, 'id'>
    )> }
  )> }
);

export type GetWebhookQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetWebhookQuery = (
  { __typename?: 'Query' }
  & { entity?: Maybe<(
    { __typename?: 'Webhook' }
    & WebhookDetailFragment
  )> }
);

export type ListWebhookLogsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListWebhookLogsQuery = (
  { __typename?: 'Query' }
  & { list?: Maybe<(
    { __typename?: 'WebhookLogsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'WebhookLog' }
      & Pick<WebhookLog, 'id' | 'level' | 'payload' | 'createdAt'>
    )>> }
  )> }
);

export type UpdateWebhookMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: WebhookPatch;
}>;


export type UpdateWebhookMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateWebhookPayload' }
    & { entity?: Maybe<(
      { __typename?: 'Webhook' }
      & WebhookDetailFragment
    )> }
  )> }
);

export type UpdateWebhookRunMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: WebhookRunPatch;
}>;


export type UpdateWebhookRunMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateWebhookRunPayload' }
    & { entity?: Maybe<(
      { __typename?: 'WebhookRun' }
      & Pick<WebhookRun, 'id' | 'createdAt' | 'finishedAt' | 'status'>
    )> }
  )> }
);

export type CreateWebhookRunMutationVariables = Exact<{
  webhook: Scalars['UUID'];
}>;


export type CreateWebhookRunMutation = (
  { __typename?: 'Mutation' }
  & { createWebhookRun?: Maybe<(
    { __typename?: 'CreateWebhookRunPayload' }
    & { webhook?: Maybe<(
      { __typename?: 'Webhook' }
      & Pick<Webhook, 'id'>
      & { webhookRuns: (
        { __typename?: 'WebhookRunsConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'WebhookRun' }
          & Pick<WebhookRun, 'id' | 'createdAt' | 'finishedAt' | 'status'>
        )>> }
      ) }
    )> }
  )> }
);

export type WebhookDetailFragment = (
  { __typename?: 'Webhook' }
  & Pick<Webhook, 'id' | 'projectId' | 'createdAt' | 'name' | 'payload' | 'headers' | 'method' | 'url'>
  & { webhookRuns: (
    { __typename?: 'WebhookRunsConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'WebhookRun' }
      & Pick<WebhookRun, 'id' | 'createdAt' | 'finishedAt' | 'status'>
    )>> }
  ) }
);

export type CreateWebsiteMutationVariables = Exact<{
  input: WebsiteInput;
}>;


export type CreateWebsiteMutation = (
  { __typename?: 'Mutation' }
  & { create?: Maybe<(
    { __typename?: 'CreateWebsitePayload' }
    & { environment?: Maybe<(
      { __typename?: 'ProjectEnvironment' }
      & { websites: (
        { __typename?: 'WebsitesConnection' }
        & { nodes: Array<Maybe<(
          { __typename?: 'Website' }
          & Pick<Website, 'id' | 'name' | 'urlFormat' | 'targetId' | 'projectId' | 'environmentId'>
          & { projectEnvironmentTaxonomy?: Maybe<(
            { __typename?: 'Taxonomy' }
            & { path?: Maybe<(
              { __typename?: 'TaxonomyPath' }
              & Pick<TaxonomyPath, 'pathAlias'>
            )> }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type UpdateWebsiteMutationVariables = Exact<{
  id: Scalars['UUID'];
  project: Scalars['UUID'];
  patch: WebsitePatch;
}>;


export type UpdateWebsiteMutation = (
  { __typename?: 'Mutation' }
  & { update?: Maybe<(
    { __typename?: 'UpdateWebsitePayload' }
    & { entity?: Maybe<(
      { __typename?: 'Website' }
      & Pick<Website, 'id' | 'name'>
    )> }
  )> }
);

export const EntryVersionDetailFragmentDoc = gql`
    fragment EntryVersionDetail on EntryVersion {
  id
  index
  valueId
  createdAt
  modifiedAt
  entryPublishes(first: 25, condition: {deletedAt: null}) {
    nodes {
      modifiedAt
      target {
        id
        name
      }
    }
  }
}
    `;
export const EntryDetailFragmentDoc = gql`
    fragment EntryDetail on Entry {
  id
  createdAt
  createdBy {
    id
    name
  }
  modifiedAt
  modifiedBy {
    id
    name
  }
  deletedAt
  name
  modelId
  projectId
  versions(first: 25, condition: {deletedAt: null}, orderBy: INDEX_DESC) {
    nodes {
      ...EntryVersionDetail
    }
  }
  entryPublishes(first: 1, condition: {deletedAt: null}) {
    totalCount
  }
  taxonomies(first: 5) {
    nodes {
      pathAlias
    }
  }
}
    ${EntryVersionDetailFragmentDoc}`;
export const ModelFieldAllDetailFragmentDoc = gql`
    fragment ModelFieldAllDetail on ModelFieldsInherited {
  id
  projectId
  name
  alias
  type
  config
  required
  deletedAt
  multi
  index
  autoSource
  autoOverride
  autoTransform
  autoFieldId
  sourceModel {
    id
    name
    alias
  }
  valueCount {
    start {
      value
      inclusive
    }
    end {
      value
      inclusive
    }
  }
}
    `;
export const ModelDetailFragmentDoc = gql`
    fragment ModelDetail on Model {
  id
  projectId
  createdAt
  name
  alias
  usage
  inherits
  deletedAt
  fieldsAll(
    first: 75
    orderBy: [MODEL_BY_SOURCE_MODEL_ID_AND_PROJECT_ID_AND_ENVIRONMENT_ID__ALIAS_ASC, INDEX_ASC]
  ) {
    nodes {
      ...ModelFieldAllDetail
    }
  }
  parents(first: 25) {
    nodes {
      parentId
      parent {
        name
        alias
      }
    }
  }
  parentsAll(first: 50) {
    nodes {
      parentId
      parent {
        name
        alias
      }
    }
  }
  valueBaseId
  defaultTaxonomyLevelId
}
    ${ModelFieldAllDetailFragmentDoc}`;
export const ModelFieldDetailFragmentDoc = gql`
    fragment ModelFieldDetail on ModelField {
  id
  projectId
  name
  alias
  type
  config
  deletedAt
  index
}
    `;
export const ValueFieldDetailFragmentDoc = gql`
    fragment ValueFieldDetail on ValueField {
  id
  hint
  index
  valueScalar
  valueMediaId
  valueValueId
  valueValue {
    id
    modelId
  }
  valueEntryId
  modelFieldId
  valueEntryProjectEnvironment {
    id
    name
    modifiedAt
    modelId
    entryPublishes(first: 1) {
      totalCount
    }
  }
}
    `;
export const ValueDetailFragmentDoc = gql`
    fragment ValueDetail on Value {
  id
  modifiedAt
  modelId
  fields(first: 200) {
    nodes {
      ...ValueFieldDetail
    }
  }
}
    ${ValueFieldDetailFragmentDoc}`;
export const MediaItemDetailFragmentDoc = gql`
    fragment MediaItemDetail on MediaItem {
  id
  projectId
  name
  contentType
  size
  storage
  createdAt
  uploadedAt
  folderId
  mediaInfo
  mediaType
}
    `;
export const MediaFolderDetailFragmentDoc = gql`
    fragment MediaFolderDetail on MediaFolder {
  id
  projectId
  name
  parentId
  path
  parent {
    id
    name
  }
  children(first: 1, condition: {deletedAt: null}) {
    totalCount
  }
}
    `;
export const WebhookDetailFragmentDoc = gql`
    fragment WebhookDetail on Webhook {
  id
  projectId
  createdAt
  name
  payload
  headers
  method
  url
  webhookRuns(orderBy: CREATED_AT_DESC, first: 10) {
    nodes {
      id
      createdAt
      finishedAt
      status
    }
  }
}
    `;
export const ListAccountsDocument = gql`
    query ListAccounts {
  list: accounts(first: 25, orderBy: NAME_ASC) {
    nodes {
      id
      key
      name
      projects(first: 25, orderBy: NAME_ASC) {
        nodes {
          id
          key
          name
        }
      }
    }
  }
}
    `;

/**
 * __useListAccountsQuery__
 *
 * To run a query within a React component, call `useListAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAccountsQuery, ListAccountsQueryVariables>(ListAccountsDocument, options);
      }
export function useListAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAccountsQuery, ListAccountsQueryVariables>(ListAccountsDocument, options);
        }
export type ListAccountsQueryHookResult = ReturnType<typeof useListAccountsQuery>;
export type ListAccountsLazyQueryHookResult = ReturnType<typeof useListAccountsLazyQuery>;
export type ListAccountsQueryResult = Apollo.QueryResult<ListAccountsQuery, ListAccountsQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($key: String!) {
  account: accountByKey(key: $key) {
    id
    key
    name
    projects(first: 25, orderBy: NAME_ASC) {
      nodes {
        id
        key
        name
        deletedAt
        projectEnvironments(first: 25, orderBy: [DELETED_AT_DESC, NAME_ASC]) {
          nodes {
            id
            key
            name
            alias
            deletedAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const ListChangesDocument = gql`
    query ListChanges($project: UUID!, $environment: UUID!, $dateFrom: Datetime!, $dateTo: Datetime!) {
  entries(
    first: 20
    condition: {projectId: $project, environmentId: $environment}
    filter: {and: [{modifiedAt: {greaterThanOrEqualTo: $dateFrom}}, {modifiedAt: {lessThanOrEqualTo: $dateTo}}]}
    orderBy: MODIFIED_AT_DESC
  ) {
    nodes {
      id
      name
      createdAt
      modifiedAt
      deletedAt
    }
    totalCount
  }
  models(
    first: 20
    condition: {projectId: $project, environmentId: $environment}
    filter: {and: [{modifiedAt: {greaterThanOrEqualTo: $dateFrom}}, {modifiedAt: {lessThanOrEqualTo: $dateTo}}]}
    orderBy: MODIFIED_AT_DESC
  ) {
    nodes {
      id
      name
      createdAt
      modifiedAt
      deletedAt
    }
    totalCount
  }
  modelFields(
    first: 20
    condition: {projectId: $project, environmentId: $environment}
    filter: {and: [{modifiedAt: {greaterThanOrEqualTo: $dateFrom}}, {modifiedAt: {lessThanOrEqualTo: $dateTo}}]}
    orderBy: MODIFIED_AT_DESC
  ) {
    nodes {
      id
      name
      model {
        id
        name
      }
      createdAt
      modifiedAt
      deletedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListChangesQuery__
 *
 * To run a query within a React component, call `useListChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChangesQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useListChangesQuery(baseOptions: Apollo.QueryHookOptions<ListChangesQuery, ListChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChangesQuery, ListChangesQueryVariables>(ListChangesDocument, options);
      }
export function useListChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChangesQuery, ListChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChangesQuery, ListChangesQueryVariables>(ListChangesDocument, options);
        }
export type ListChangesQueryHookResult = ReturnType<typeof useListChangesQuery>;
export type ListChangesLazyQueryHookResult = ReturnType<typeof useListChangesLazyQuery>;
export type ListChangesQueryResult = Apollo.QueryResult<ListChangesQuery, ListChangesQueryVariables>;
export const ListEnvDiffSummaryDocument = gql`
    query ListEnvDiffSummary($project: UUID!, $environments: [UUID!]!) {
  models(
    first: 25
    condition: {projectId: $project}
    filter: {environment: {id: {in: $environments}}}
  ) {
    nodes {
      id
      environmentId
      name
      alias
      createdAt
      modifiedAt
      deletedAt
    }
  }
  modelFields(
    first: 50
    condition: {projectId: $project}
    filter: {environment: {id: {in: $environments}}}
  ) {
    nodes {
      id
      environmentId
      name
      alias
      model {
        id
        name
      }
      createdAt
      modifiedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useListEnvDiffSummaryQuery__
 *
 * To run a query within a React component, call `useListEnvDiffSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEnvDiffSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEnvDiffSummaryQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environments: // value for 'environments'
 *   },
 * });
 */
export function useListEnvDiffSummaryQuery(baseOptions: Apollo.QueryHookOptions<ListEnvDiffSummaryQuery, ListEnvDiffSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEnvDiffSummaryQuery, ListEnvDiffSummaryQueryVariables>(ListEnvDiffSummaryDocument, options);
      }
export function useListEnvDiffSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEnvDiffSummaryQuery, ListEnvDiffSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEnvDiffSummaryQuery, ListEnvDiffSummaryQueryVariables>(ListEnvDiffSummaryDocument, options);
        }
export type ListEnvDiffSummaryQueryHookResult = ReturnType<typeof useListEnvDiffSummaryQuery>;
export type ListEnvDiffSummaryLazyQueryHookResult = ReturnType<typeof useListEnvDiffSummaryLazyQuery>;
export type ListEnvDiffSummaryQueryResult = Apollo.QueryResult<ListEnvDiffSummaryQuery, ListEnvDiffSummaryQueryVariables>;
export const WatchContentDocument = gql`
    subscription WatchContent($project: UUID!) {
  contentChanged(project: $project) {
    event
    table
    id
    parent
  }
}
    `;

/**
 * __useWatchContentSubscription__
 *
 * To run a query within a React component, call `useWatchContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchContentSubscription({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWatchContentSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchContentSubscription, WatchContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchContentSubscription, WatchContentSubscriptionVariables>(WatchContentDocument, options);
      }
export type WatchContentSubscriptionHookResult = ReturnType<typeof useWatchContentSubscription>;
export type WatchContentSubscriptionResult = Apollo.SubscriptionResult<WatchContentSubscription>;
export const CreateEntryDocument = gql`
    mutation CreateEntry($input: EntryInput!) {
  create: createEntry(input: {entry: $input}) {
    entity: entry {
      id
    }
  }
}
    `;
export type CreateEntryMutationFn = Apollo.MutationFunction<CreateEntryMutation, CreateEntryMutationVariables>;

/**
 * __useCreateEntryMutation__
 *
 * To run a mutation, you first call `useCreateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryMutation, { data, loading, error }] = useCreateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryMutation, CreateEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryMutation, CreateEntryMutationVariables>(CreateEntryDocument, options);
      }
export type CreateEntryMutationHookResult = ReturnType<typeof useCreateEntryMutation>;
export type CreateEntryMutationResult = Apollo.MutationResult<CreateEntryMutation>;
export type CreateEntryMutationOptions = Apollo.BaseMutationOptions<CreateEntryMutation, CreateEntryMutationVariables>;
export const ListEntriesDocument = gql`
    query ListEntries($project: UUID!, $environment: UUID!, $skip: Int! = 0, $take: Int! = 25, $filter: EntryFilter, $sort: [EntriesOrderBy!] = [NAME_ASC]) {
  list: entries(
    condition: {projectId: $project, environmentId: $environment, deletedAt: null}
    filter: $filter
    offset: $skip
    first: $take
    orderBy: $sort
  ) {
    nodes {
      id
      name
      modelId
      modifiedAt
      createdAt
      latestVersionId
      entryPublishes(first: 1, condition: {deletedAt: null}) {
        totalCount
      }
    }
    totalCount
  }
}
    `;

/**
 * __useListEntriesQuery__
 *
 * To run a query within a React component, call `useListEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntriesQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListEntriesQuery(baseOptions: Apollo.QueryHookOptions<ListEntriesQuery, ListEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntriesQuery, ListEntriesQueryVariables>(ListEntriesDocument, options);
      }
export function useListEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntriesQuery, ListEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntriesQuery, ListEntriesQueryVariables>(ListEntriesDocument, options);
        }
export type ListEntriesQueryHookResult = ReturnType<typeof useListEntriesQuery>;
export type ListEntriesLazyQueryHookResult = ReturnType<typeof useListEntriesLazyQuery>;
export type ListEntriesQueryResult = Apollo.QueryResult<ListEntriesQuery, ListEntriesQueryVariables>;
export const GetEntryDocument = gql`
    query GetEntry($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: entry(id: $id, projectId: $project, environmentId: $environment) {
    ...EntryDetail
  }
  links: entryWebsites(
    fEntryId: $id
    fProjectId: $project
    fEnvironmentId: $environment
  )
}
    ${EntryDetailFragmentDoc}`;

/**
 * __useGetEntryQuery__
 *
 * To run a query within a React component, call `useGetEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetEntryQuery(baseOptions: Apollo.QueryHookOptions<GetEntryQuery, GetEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntryQuery, GetEntryQueryVariables>(GetEntryDocument, options);
      }
export function useGetEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntryQuery, GetEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntryQuery, GetEntryQueryVariables>(GetEntryDocument, options);
        }
export type GetEntryQueryHookResult = ReturnType<typeof useGetEntryQuery>;
export type GetEntryLazyQueryHookResult = ReturnType<typeof useGetEntryLazyQuery>;
export type GetEntryQueryResult = Apollo.QueryResult<GetEntryQuery, GetEntryQueryVariables>;
export const GetEntrySummaryDocument = gql`
    query GetEntrySummary($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: entry(id: $id, projectId: $project, environmentId: $environment) {
    id
    modelId
    name
    modifiedAt
    entryPublishes(first: 1, condition: {deletedAt: null}) {
      totalCount
    }
  }
}
    `;

/**
 * __useGetEntrySummaryQuery__
 *
 * To run a query within a React component, call `useGetEntrySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntrySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntrySummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetEntrySummaryQuery(baseOptions: Apollo.QueryHookOptions<GetEntrySummaryQuery, GetEntrySummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntrySummaryQuery, GetEntrySummaryQueryVariables>(GetEntrySummaryDocument, options);
      }
export function useGetEntrySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntrySummaryQuery, GetEntrySummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntrySummaryQuery, GetEntrySummaryQueryVariables>(GetEntrySummaryDocument, options);
        }
export type GetEntrySummaryQueryHookResult = ReturnType<typeof useGetEntrySummaryQuery>;
export type GetEntrySummaryLazyQueryHookResult = ReturnType<typeof useGetEntrySummaryLazyQuery>;
export type GetEntrySummaryQueryResult = Apollo.QueryResult<GetEntrySummaryQuery, GetEntrySummaryQueryVariables>;
export const UpdateEntryDocument = gql`
    mutation UpdateEntry($id: UUID!, $project: UUID!, $environment: UUID!, $patch: EntryPatch!) {
  update: updateEntry(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: entry {
      ...EntryDetail
    }
  }
}
    ${EntryDetailFragmentDoc}`;
export type UpdateEntryMutationFn = Apollo.MutationFunction<UpdateEntryMutation, UpdateEntryMutationVariables>;

/**
 * __useUpdateEntryMutation__
 *
 * To run a mutation, you first call `useUpdateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryMutation, { data, loading, error }] = useUpdateEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryMutation, UpdateEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryMutation, UpdateEntryMutationVariables>(UpdateEntryDocument, options);
      }
export type UpdateEntryMutationHookResult = ReturnType<typeof useUpdateEntryMutation>;
export type UpdateEntryMutationResult = Apollo.MutationResult<UpdateEntryMutation>;
export type UpdateEntryMutationOptions = Apollo.BaseMutationOptions<UpdateEntryMutation, UpdateEntryMutationVariables>;
export const PublishVersion2Document = gql`
    mutation PublishVersion2($version: UUID!, $entry: UUID!, $project: UUID!, $environment: UUID!, $target: UUID!) {
  publish: publishVersion(
    input: {fVersionId: $version, fProjectId: $project, fEnvironmentId: $environment, fTargetId: $target, fEntryId: $entry}
  ) {
    clientMutationId
  }
}
    `;
export type PublishVersion2MutationFn = Apollo.MutationFunction<PublishVersion2Mutation, PublishVersion2MutationVariables>;

/**
 * __usePublishVersion2Mutation__
 *
 * To run a mutation, you first call `usePublishVersion2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishVersion2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishVersion2Mutation, { data, loading, error }] = usePublishVersion2Mutation({
 *   variables: {
 *      version: // value for 'version'
 *      entry: // value for 'entry'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      target: // value for 'target'
 *   },
 * });
 */
export function usePublishVersion2Mutation(baseOptions?: Apollo.MutationHookOptions<PublishVersion2Mutation, PublishVersion2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishVersion2Mutation, PublishVersion2MutationVariables>(PublishVersion2Document, options);
      }
export type PublishVersion2MutationHookResult = ReturnType<typeof usePublishVersion2Mutation>;
export type PublishVersion2MutationResult = Apollo.MutationResult<PublishVersion2Mutation>;
export type PublishVersion2MutationOptions = Apollo.BaseMutationOptions<PublishVersion2Mutation, PublishVersion2MutationVariables>;
export const UnpublishVersion2Document = gql`
    mutation UnpublishVersion2($entry: UUID!, $project: UUID!, $environment: UUID!, $target: UUID!) {
  publish: unpublishVersion2(
    input: {fEntryId: $entry, fProjectId: $project, fEnvironmentId: $environment, fTargetId: $target}
  ) {
    clientMutationId
  }
}
    `;
export type UnpublishVersion2MutationFn = Apollo.MutationFunction<UnpublishVersion2Mutation, UnpublishVersion2MutationVariables>;

/**
 * __useUnpublishVersion2Mutation__
 *
 * To run a mutation, you first call `useUnpublishVersion2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishVersion2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishVersion2Mutation, { data, loading, error }] = useUnpublishVersion2Mutation({
 *   variables: {
 *      entry: // value for 'entry'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useUnpublishVersion2Mutation(baseOptions?: Apollo.MutationHookOptions<UnpublishVersion2Mutation, UnpublishVersion2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishVersion2Mutation, UnpublishVersion2MutationVariables>(UnpublishVersion2Document, options);
      }
export type UnpublishVersion2MutationHookResult = ReturnType<typeof useUnpublishVersion2Mutation>;
export type UnpublishVersion2MutationResult = Apollo.MutationResult<UnpublishVersion2Mutation>;
export type UnpublishVersion2MutationOptions = Apollo.BaseMutationOptions<UnpublishVersion2Mutation, UnpublishVersion2MutationVariables>;
export const CopyVersionDocument = gql`
    mutation CopyVersion($id: UUID!, $project: UUID!, $environment: UUID!) {
  create: copyVersion(
    input: {fVersionId: $id, fProjectId: $project, fEnvironmentId: $environment}
  ) {
    entity: entryVersion {
      id
    }
  }
}
    `;
export type CopyVersionMutationFn = Apollo.MutationFunction<CopyVersionMutation, CopyVersionMutationVariables>;

/**
 * __useCopyVersionMutation__
 *
 * To run a mutation, you first call `useCopyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyVersionMutation, { data, loading, error }] = useCopyVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useCopyVersionMutation(baseOptions?: Apollo.MutationHookOptions<CopyVersionMutation, CopyVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyVersionMutation, CopyVersionMutationVariables>(CopyVersionDocument, options);
      }
export type CopyVersionMutationHookResult = ReturnType<typeof useCopyVersionMutation>;
export type CopyVersionMutationResult = Apollo.MutationResult<CopyVersionMutation>;
export type CopyVersionMutationOptions = Apollo.BaseMutationOptions<CopyVersionMutation, CopyVersionMutationVariables>;
export const CopyVersionToNewEntryDocument = gql`
    mutation CopyVersionToNewEntry($id: UUID!, $project: UUID!, $environment: UUID!) {
  create: copyVersion2(
    input: {fVersionId: $id, fProjectId: $project, fEnvironmentId: $environment}
  ) {
    entity: entry {
      id
      latestVersionId
    }
  }
}
    `;
export type CopyVersionToNewEntryMutationFn = Apollo.MutationFunction<CopyVersionToNewEntryMutation, CopyVersionToNewEntryMutationVariables>;

/**
 * __useCopyVersionToNewEntryMutation__
 *
 * To run a mutation, you first call `useCopyVersionToNewEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyVersionToNewEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyVersionToNewEntryMutation, { data, loading, error }] = useCopyVersionToNewEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useCopyVersionToNewEntryMutation(baseOptions?: Apollo.MutationHookOptions<CopyVersionToNewEntryMutation, CopyVersionToNewEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyVersionToNewEntryMutation, CopyVersionToNewEntryMutationVariables>(CopyVersionToNewEntryDocument, options);
      }
export type CopyVersionToNewEntryMutationHookResult = ReturnType<typeof useCopyVersionToNewEntryMutation>;
export type CopyVersionToNewEntryMutationResult = Apollo.MutationResult<CopyVersionToNewEntryMutation>;
export type CopyVersionToNewEntryMutationOptions = Apollo.BaseMutationOptions<CopyVersionToNewEntryMutation, CopyVersionToNewEntryMutationVariables>;
export const UpdateEntryVersionDocument = gql`
    mutation UpdateEntryVersion($id: UUID!, $project: UUID!, $environment: UUID!, $patch: EntryVersionPatch!) {
  update: updateEntryVersion(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: entryVersion {
      ...EntryVersionDetail
    }
  }
}
    ${EntryVersionDetailFragmentDoc}`;
export type UpdateEntryVersionMutationFn = Apollo.MutationFunction<UpdateEntryVersionMutation, UpdateEntryVersionMutationVariables>;

/**
 * __useUpdateEntryVersionMutation__
 *
 * To run a mutation, you first call `useUpdateEntryVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryVersionMutation, { data, loading, error }] = useUpdateEntryVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateEntryVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryVersionMutation, UpdateEntryVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryVersionMutation, UpdateEntryVersionMutationVariables>(UpdateEntryVersionDocument, options);
      }
export type UpdateEntryVersionMutationHookResult = ReturnType<typeof useUpdateEntryVersionMutation>;
export type UpdateEntryVersionMutationResult = Apollo.MutationResult<UpdateEntryVersionMutation>;
export type UpdateEntryVersionMutationOptions = Apollo.BaseMutationOptions<UpdateEntryVersionMutation, UpdateEntryVersionMutationVariables>;
export const ListEntriesExportDocument = gql`
    query ListEntriesExport($project: UUID!, $environment: UUID!, $skip: Int! = 0, $take: Int! = 25, $ids: [UUID!]!) {
  list: entries(
    condition: {projectId: $project, environmentId: $environment, deletedAt: null}
    filter: {id: {in: $ids}}
    offset: $skip
    first: $take
  ) {
    nodes {
      id
      name
      modelId
      modifiedAt
      createdAt
      versions(first: 20) {
        nodes {
          id
          valueId
          index
          createdAt
          modifiedAt
        }
      }
    }
  }
}
    `;

/**
 * __useListEntriesExportQuery__
 *
 * To run a query within a React component, call `useListEntriesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntriesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntriesExportQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListEntriesExportQuery(baseOptions: Apollo.QueryHookOptions<ListEntriesExportQuery, ListEntriesExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntriesExportQuery, ListEntriesExportQueryVariables>(ListEntriesExportDocument, options);
      }
export function useListEntriesExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntriesExportQuery, ListEntriesExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntriesExportQuery, ListEntriesExportQueryVariables>(ListEntriesExportDocument, options);
        }
export type ListEntriesExportQueryHookResult = ReturnType<typeof useListEntriesExportQuery>;
export type ListEntriesExportLazyQueryHookResult = ReturnType<typeof useListEntriesExportLazyQuery>;
export type ListEntriesExportQueryResult = Apollo.QueryResult<ListEntriesExportQuery, ListEntriesExportQueryVariables>;
export const ListValuesHierarchyDocument = gql`
    query ListValuesHierarchy($project: UUID!, $environment: UUID!, $ids: [UUID!]!) {
  list: valueHierarchies(
    first: 200
    condition: {projectId: $project, environmentId: $environment}
    filter: {path: {overlaps: $ids}}
  ) {
    nodes {
      id
    }
  }
}
    `;

/**
 * __useListValuesHierarchyQuery__
 *
 * To run a query within a React component, call `useListValuesHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListValuesHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListValuesHierarchyQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListValuesHierarchyQuery(baseOptions: Apollo.QueryHookOptions<ListValuesHierarchyQuery, ListValuesHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListValuesHierarchyQuery, ListValuesHierarchyQueryVariables>(ListValuesHierarchyDocument, options);
      }
export function useListValuesHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListValuesHierarchyQuery, ListValuesHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListValuesHierarchyQuery, ListValuesHierarchyQueryVariables>(ListValuesHierarchyDocument, options);
        }
export type ListValuesHierarchyQueryHookResult = ReturnType<typeof useListValuesHierarchyQuery>;
export type ListValuesHierarchyLazyQueryHookResult = ReturnType<typeof useListValuesHierarchyLazyQuery>;
export type ListValuesHierarchyQueryResult = Apollo.QueryResult<ListValuesHierarchyQuery, ListValuesHierarchyQueryVariables>;
export const ListValuesDocument = gql`
    query ListValues($project: UUID!, $environment: UUID!, $ids: [UUID!]!) {
  list: values(
    first: 200
    condition: {projectId: $project, environmentId: $environment}
    filter: {id: {in: $ids}}
  ) {
    nodes {
      id
      modelId
      fields(first: 200) {
        nodes {
          id
          modelFieldId
          index
          hint
          valueScalar
          valueMediaId
          valueEntryId
          valueValueId
        }
      }
    }
  }
}
    `;

/**
 * __useListValuesQuery__
 *
 * To run a query within a React component, call `useListValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListValuesQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListValuesQuery(baseOptions: Apollo.QueryHookOptions<ListValuesQuery, ListValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListValuesQuery, ListValuesQueryVariables>(ListValuesDocument, options);
      }
export function useListValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListValuesQuery, ListValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListValuesQuery, ListValuesQueryVariables>(ListValuesDocument, options);
        }
export type ListValuesQueryHookResult = ReturnType<typeof useListValuesQuery>;
export type ListValuesLazyQueryHookResult = ReturnType<typeof useListValuesLazyQuery>;
export type ListValuesQueryResult = Apollo.QueryResult<ListValuesQuery, ListValuesQueryVariables>;
export const MenuListNodesDocument = gql`
    query MenuListNodes($project: UUID!, $environment: UUID!, $parent: UUID, $skip: Int! = 0, $take: Int! = 25) {
  list: menuItems(
    condition: {projectId: $project, environmentId: $environment, parentId: $parent, deletedAt: null}
    offset: $skip
    first: $take
    orderBy: [INDEX_ASC]
  ) {
    nodes {
      id
      label
      index
      entry {
        id
        entryId
      }
      view {
        id
        modelId
      }
      children: menuItemsByParentIdAndProjectIdAndEnvironmentId(first: 1) {
        totalCount
      }
    }
    totalCount
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useMenuListNodesQuery__
 *
 * To run a query within a React component, call `useMenuListNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuListNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuListNodesQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      parent: // value for 'parent'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMenuListNodesQuery(baseOptions: Apollo.QueryHookOptions<MenuListNodesQuery, MenuListNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuListNodesQuery, MenuListNodesQueryVariables>(MenuListNodesDocument, options);
      }
export function useMenuListNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuListNodesQuery, MenuListNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuListNodesQuery, MenuListNodesQueryVariables>(MenuListNodesDocument, options);
        }
export type MenuListNodesQueryHookResult = ReturnType<typeof useMenuListNodesQuery>;
export type MenuListNodesLazyQueryHookResult = ReturnType<typeof useMenuListNodesLazyQuery>;
export type MenuListNodesQueryResult = Apollo.QueryResult<MenuListNodesQuery, MenuListNodesQueryVariables>;
export const CreateMenuItemDocument = gql`
    mutation CreateMenuItem($input: MenuItemInput!) {
  create: createMenuItem(input: {menuItem: $input}) {
    entity: menuItem {
      id
    }
  }
}
    `;
export type CreateMenuItemMutationFn = Apollo.MutationFunction<CreateMenuItemMutation, CreateMenuItemMutationVariables>;

/**
 * __useCreateMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuItemMutation, { data, loading, error }] = useCreateMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuItemMutation, CreateMenuItemMutationVariables>(CreateMenuItemDocument, options);
      }
export type CreateMenuItemMutationHookResult = ReturnType<typeof useCreateMenuItemMutation>;
export type CreateMenuItemMutationResult = Apollo.MutationResult<CreateMenuItemMutation>;
export type CreateMenuItemMutationOptions = Apollo.BaseMutationOptions<CreateMenuItemMutation, CreateMenuItemMutationVariables>;
export const UpdateMenuItemDocument = gql`
    mutation UpdateMenuItem($id: UUID!, $project: UUID!, $environment: UUID!, $patch: MenuItemPatch!) {
  update: updateMenuItem(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: menuItem {
      id
    }
  }
}
    `;
export type UpdateMenuItemMutationFn = Apollo.MutationFunction<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;

/**
 * __useUpdateMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuItemMutation, { data, loading, error }] = useUpdateMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>(UpdateMenuItemDocument, options);
      }
export type UpdateMenuItemMutationHookResult = ReturnType<typeof useUpdateMenuItemMutation>;
export type UpdateMenuItemMutationResult = Apollo.MutationResult<UpdateMenuItemMutation>;
export type UpdateMenuItemMutationOptions = Apollo.BaseMutationOptions<UpdateMenuItemMutation, UpdateMenuItemMutationVariables>;
export const GetMenuViewDocument = gql`
    query GetMenuView($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: menuItemView(id: $id, projectId: $project, environmentId: $environment) {
    id
    modelId
  }
}
    `;

/**
 * __useGetMenuViewQuery__
 *
 * To run a query within a React component, call `useGetMenuViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetMenuViewQuery(baseOptions: Apollo.QueryHookOptions<GetMenuViewQuery, GetMenuViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuViewQuery, GetMenuViewQueryVariables>(GetMenuViewDocument, options);
      }
export function useGetMenuViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuViewQuery, GetMenuViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuViewQuery, GetMenuViewQueryVariables>(GetMenuViewDocument, options);
        }
export type GetMenuViewQueryHookResult = ReturnType<typeof useGetMenuViewQuery>;
export type GetMenuViewLazyQueryHookResult = ReturnType<typeof useGetMenuViewLazyQuery>;
export type GetMenuViewQueryResult = Apollo.QueryResult<GetMenuViewQuery, GetMenuViewQueryVariables>;
export const GetMenuItemDocument = gql`
    query GetMenuItem($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: menuItem(id: $id, projectId: $project, environmentId: $environment) {
    id
    label
    entry {
      id
      entryId
    }
    view {
      id
      modelId
    }
  }
}
    `;

/**
 * __useGetMenuItemQuery__
 *
 * To run a query within a React component, call `useGetMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetMenuItemQuery(baseOptions: Apollo.QueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, options);
      }
export function useGetMenuItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuItemQuery, GetMenuItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuItemQuery, GetMenuItemQueryVariables>(GetMenuItemDocument, options);
        }
export type GetMenuItemQueryHookResult = ReturnType<typeof useGetMenuItemQuery>;
export type GetMenuItemLazyQueryHookResult = ReturnType<typeof useGetMenuItemLazyQuery>;
export type GetMenuItemQueryResult = Apollo.QueryResult<GetMenuItemQuery, GetMenuItemQueryVariables>;
export const WatchMenuDocument = gql`
    subscription WatchMenu($project: UUID!) {
  menuChanged(project: $project) {
    event
    table
    id
    parent
    payload
  }
}
    `;

/**
 * __useWatchMenuSubscription__
 *
 * To run a query within a React component, call `useWatchMenuSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchMenuSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchMenuSubscription({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWatchMenuSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchMenuSubscription, WatchMenuSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchMenuSubscription, WatchMenuSubscriptionVariables>(WatchMenuDocument, options);
      }
export type WatchMenuSubscriptionHookResult = ReturnType<typeof useWatchMenuSubscription>;
export type WatchMenuSubscriptionResult = Apollo.SubscriptionResult<WatchMenuSubscription>;
export const MoveMenuItemDocument = gql`
    mutation MoveMenuItem($project: UUID!, $environment: UUID!, $id: UUID!, $parent: UUID, $before: UUID) {
  moveMenuItem(
    input: {fProjectId: $project, fEnvironmentId: $environment, fId: $id, fParent: $parent, fBefore: $before}
  ) {
    clientMutationId
  }
}
    `;
export type MoveMenuItemMutationFn = Apollo.MutationFunction<MoveMenuItemMutation, MoveMenuItemMutationVariables>;

/**
 * __useMoveMenuItemMutation__
 *
 * To run a mutation, you first call `useMoveMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMenuItemMutation, { data, loading, error }] = useMoveMenuItemMutation({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      id: // value for 'id'
 *      parent: // value for 'parent'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMoveMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<MoveMenuItemMutation, MoveMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMenuItemMutation, MoveMenuItemMutationVariables>(MoveMenuItemDocument, options);
      }
export type MoveMenuItemMutationHookResult = ReturnType<typeof useMoveMenuItemMutation>;
export type MoveMenuItemMutationResult = Apollo.MutationResult<MoveMenuItemMutation>;
export type MoveMenuItemMutationOptions = Apollo.BaseMutationOptions<MoveMenuItemMutation, MoveMenuItemMutationVariables>;
export const WatchModelsDocument = gql`
    subscription WatchModels($project: UUID!) {
  modelChanged(project: $project) {
    event
    table
    id
    parent
  }
}
    `;

/**
 * __useWatchModelsSubscription__
 *
 * To run a query within a React component, call `useWatchModelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchModelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchModelsSubscription({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWatchModelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchModelsSubscription, WatchModelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchModelsSubscription, WatchModelsSubscriptionVariables>(WatchModelsDocument, options);
      }
export type WatchModelsSubscriptionHookResult = ReturnType<typeof useWatchModelsSubscription>;
export type WatchModelsSubscriptionResult = Apollo.SubscriptionResult<WatchModelsSubscription>;
export const ListModelsDocument = gql`
    query ListModels($project: UUID!, $environment: UUID!, $filter: ModelFilter, $skip: Int! = 0, $take: Int! = 100) {
  list: models(
    condition: {projectId: $project, environmentId: $environment}
    filter: $filter
    offset: $skip
    first: $take
    orderBy: NAME_ASC
  ) {
    nodes {
      ...ModelDetail
      entries(first: 1) {
        totalCount
      }
    }
    totalCount
  }
}
    ${ModelDetailFragmentDoc}`;

/**
 * __useListModelsQuery__
 *
 * To run a query within a React component, call `useListModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListModelsQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListModelsQuery(baseOptions: Apollo.QueryHookOptions<ListModelsQuery, ListModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListModelsQuery, ListModelsQueryVariables>(ListModelsDocument, options);
      }
export function useListModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListModelsQuery, ListModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListModelsQuery, ListModelsQueryVariables>(ListModelsDocument, options);
        }
export type ListModelsQueryHookResult = ReturnType<typeof useListModelsQuery>;
export type ListModelsLazyQueryHookResult = ReturnType<typeof useListModelsLazyQuery>;
export type ListModelsQueryResult = Apollo.QueryResult<ListModelsQuery, ListModelsQueryVariables>;
export const CreateModelDocument = gql`
    mutation CreateModel($input: ModelInput!) {
  create: createModel(input: {model: $input}) {
    entity: model {
      id
    }
  }
}
    `;
export type CreateModelMutationFn = Apollo.MutationFunction<CreateModelMutation, CreateModelMutationVariables>;

/**
 * __useCreateModelMutation__
 *
 * To run a mutation, you first call `useCreateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelMutation, { data, loading, error }] = useCreateModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateModelMutation, CreateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModelMutation, CreateModelMutationVariables>(CreateModelDocument, options);
      }
export type CreateModelMutationHookResult = ReturnType<typeof useCreateModelMutation>;
export type CreateModelMutationResult = Apollo.MutationResult<CreateModelMutation>;
export type CreateModelMutationOptions = Apollo.BaseMutationOptions<CreateModelMutation, CreateModelMutationVariables>;
export const CreateModelParentDocument = gql`
    mutation CreateModelParent($input: ModelParentInput!) {
  create: createModelParent(input: {modelParent: $input}) {
    entity: modelParent {
      modelId
      parentId
      projectId
    }
  }
}
    `;
export type CreateModelParentMutationFn = Apollo.MutationFunction<CreateModelParentMutation, CreateModelParentMutationVariables>;

/**
 * __useCreateModelParentMutation__
 *
 * To run a mutation, you first call `useCreateModelParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelParentMutation, { data, loading, error }] = useCreateModelParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelParentMutation(baseOptions?: Apollo.MutationHookOptions<CreateModelParentMutation, CreateModelParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModelParentMutation, CreateModelParentMutationVariables>(CreateModelParentDocument, options);
      }
export type CreateModelParentMutationHookResult = ReturnType<typeof useCreateModelParentMutation>;
export type CreateModelParentMutationResult = Apollo.MutationResult<CreateModelParentMutation>;
export type CreateModelParentMutationOptions = Apollo.BaseMutationOptions<CreateModelParentMutation, CreateModelParentMutationVariables>;
export const GetModelDocument = gql`
    query GetModel($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: model(id: $id, projectId: $project, environmentId: $environment) {
    ...ModelDetail
  }
}
    ${ModelDetailFragmentDoc}`;

/**
 * __useGetModelQuery__
 *
 * To run a query within a React component, call `useGetModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetModelQuery(baseOptions: Apollo.QueryHookOptions<GetModelQuery, GetModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelQuery, GetModelQueryVariables>(GetModelDocument, options);
      }
export function useGetModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelQuery, GetModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelQuery, GetModelQueryVariables>(GetModelDocument, options);
        }
export type GetModelQueryHookResult = ReturnType<typeof useGetModelQuery>;
export type GetModelLazyQueryHookResult = ReturnType<typeof useGetModelLazyQuery>;
export type GetModelQueryResult = Apollo.QueryResult<GetModelQuery, GetModelQueryVariables>;
export const GetModelByAliasDocument = gql`
    query GetModelByAlias($alias: String!, $project: UUID!, $environment: UUID!) {
  entity: modelByAlias(
    inAlias: $alias
    inProject: $project
    inEnvironment: $environment
  ) {
    ...ModelDetail
  }
}
    ${ModelDetailFragmentDoc}`;

/**
 * __useGetModelByAliasQuery__
 *
 * To run a query within a React component, call `useGetModelByAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelByAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelByAliasQuery({
 *   variables: {
 *      alias: // value for 'alias'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetModelByAliasQuery(baseOptions: Apollo.QueryHookOptions<GetModelByAliasQuery, GetModelByAliasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelByAliasQuery, GetModelByAliasQueryVariables>(GetModelByAliasDocument, options);
      }
export function useGetModelByAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelByAliasQuery, GetModelByAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelByAliasQuery, GetModelByAliasQueryVariables>(GetModelByAliasDocument, options);
        }
export type GetModelByAliasQueryHookResult = ReturnType<typeof useGetModelByAliasQuery>;
export type GetModelByAliasLazyQueryHookResult = ReturnType<typeof useGetModelByAliasLazyQuery>;
export type GetModelByAliasQueryResult = Apollo.QueryResult<GetModelByAliasQuery, GetModelByAliasQueryVariables>;
export const UpdateModelDocument = gql`
    mutation UpdateModel($id: UUID!, $project: UUID!, $environment: UUID!, $patch: ModelPatch!) {
  update: updateModel(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: model {
      ...ModelDetail
    }
  }
}
    ${ModelDetailFragmentDoc}`;
export type UpdateModelMutationFn = Apollo.MutationFunction<UpdateModelMutation, UpdateModelMutationVariables>;

/**
 * __useUpdateModelMutation__
 *
 * To run a mutation, you first call `useUpdateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelMutation, { data, loading, error }] = useUpdateModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelMutation, UpdateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelMutation, UpdateModelMutationVariables>(UpdateModelDocument, options);
      }
export type UpdateModelMutationHookResult = ReturnType<typeof useUpdateModelMutation>;
export type UpdateModelMutationResult = Apollo.MutationResult<UpdateModelMutation>;
export type UpdateModelMutationOptions = Apollo.BaseMutationOptions<UpdateModelMutation, UpdateModelMutationVariables>;
export const PutModelDocument = gql`
    mutation PutModel($model: ModelInput, $usage: String!, $parents: [UUID!], $fieldOrder: [UUID!]) {
  putModel(
    input: {fModel: $model, fUsage: $usage, fParents: $parents, fFieldOrder: $fieldOrder}
  ) {
    model {
      id
      alias
    }
  }
}
    `;
export type PutModelMutationFn = Apollo.MutationFunction<PutModelMutation, PutModelMutationVariables>;

/**
 * __usePutModelMutation__
 *
 * To run a mutation, you first call `usePutModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putModelMutation, { data, loading, error }] = usePutModelMutation({
 *   variables: {
 *      model: // value for 'model'
 *      usage: // value for 'usage'
 *      parents: // value for 'parents'
 *      fieldOrder: // value for 'fieldOrder'
 *   },
 * });
 */
export function usePutModelMutation(baseOptions?: Apollo.MutationHookOptions<PutModelMutation, PutModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutModelMutation, PutModelMutationVariables>(PutModelDocument, options);
      }
export type PutModelMutationHookResult = ReturnType<typeof usePutModelMutation>;
export type PutModelMutationResult = Apollo.MutationResult<PutModelMutation>;
export type PutModelMutationOptions = Apollo.BaseMutationOptions<PutModelMutation, PutModelMutationVariables>;
export const ListModelFieldsAllDocument = gql`
    query ListModelFieldsAll($model: UUID!, $project: UUID!, $environment: UUID!) {
  list: modelFieldsInheriteds(
    first: 50
    condition: {modelId: $model, projectId: $project, environmentId: $environment, deletedAt: null}
  ) {
    nodes {
      ...ModelFieldAllDetail
    }
  }
}
    ${ModelFieldAllDetailFragmentDoc}`;

/**
 * __useListModelFieldsAllQuery__
 *
 * To run a query within a React component, call `useListModelFieldsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useListModelFieldsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListModelFieldsAllQuery({
 *   variables: {
 *      model: // value for 'model'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useListModelFieldsAllQuery(baseOptions: Apollo.QueryHookOptions<ListModelFieldsAllQuery, ListModelFieldsAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListModelFieldsAllQuery, ListModelFieldsAllQueryVariables>(ListModelFieldsAllDocument, options);
      }
export function useListModelFieldsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListModelFieldsAllQuery, ListModelFieldsAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListModelFieldsAllQuery, ListModelFieldsAllQueryVariables>(ListModelFieldsAllDocument, options);
        }
export type ListModelFieldsAllQueryHookResult = ReturnType<typeof useListModelFieldsAllQuery>;
export type ListModelFieldsAllLazyQueryHookResult = ReturnType<typeof useListModelFieldsAllLazyQuery>;
export type ListModelFieldsAllQueryResult = Apollo.QueryResult<ListModelFieldsAllQuery, ListModelFieldsAllQueryVariables>;
export const CreateModelFieldDocument = gql`
    mutation CreateModelField($input: ModelFieldInput!) {
  create: createModelField(input: {modelField: $input}) {
    entity: modelField {
      model {
        ...ModelDetail
      }
    }
  }
}
    ${ModelDetailFragmentDoc}`;
export type CreateModelFieldMutationFn = Apollo.MutationFunction<CreateModelFieldMutation, CreateModelFieldMutationVariables>;

/**
 * __useCreateModelFieldMutation__
 *
 * To run a mutation, you first call `useCreateModelFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModelFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModelFieldMutation, { data, loading, error }] = useCreateModelFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModelFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateModelFieldMutation, CreateModelFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModelFieldMutation, CreateModelFieldMutationVariables>(CreateModelFieldDocument, options);
      }
export type CreateModelFieldMutationHookResult = ReturnType<typeof useCreateModelFieldMutation>;
export type CreateModelFieldMutationResult = Apollo.MutationResult<CreateModelFieldMutation>;
export type CreateModelFieldMutationOptions = Apollo.BaseMutationOptions<CreateModelFieldMutation, CreateModelFieldMutationVariables>;
export const GetModelFieldDocument = gql`
    query GetModelField($id: UUID!, $project: UUID!, $environment: UUID!) {
  entity: modelField(id: $id, projectId: $project, environmentId: $environment) {
    ...ModelFieldDetail
  }
}
    ${ModelFieldDetailFragmentDoc}`;

/**
 * __useGetModelFieldQuery__
 *
 * To run a query within a React component, call `useGetModelFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetModelFieldQuery(baseOptions: Apollo.QueryHookOptions<GetModelFieldQuery, GetModelFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelFieldQuery, GetModelFieldQueryVariables>(GetModelFieldDocument, options);
      }
export function useGetModelFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelFieldQuery, GetModelFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelFieldQuery, GetModelFieldQueryVariables>(GetModelFieldDocument, options);
        }
export type GetModelFieldQueryHookResult = ReturnType<typeof useGetModelFieldQuery>;
export type GetModelFieldLazyQueryHookResult = ReturnType<typeof useGetModelFieldLazyQuery>;
export type GetModelFieldQueryResult = Apollo.QueryResult<GetModelFieldQuery, GetModelFieldQueryVariables>;
export const UpdateModelFieldDocument = gql`
    mutation UpdateModelField($id: UUID!, $project: UUID!, $environment: UUID!, $patch: ModelFieldPatch!) {
  update: updateModelField(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: modelField {
      ...ModelFieldDetail
    }
  }
}
    ${ModelFieldDetailFragmentDoc}`;
export type UpdateModelFieldMutationFn = Apollo.MutationFunction<UpdateModelFieldMutation, UpdateModelFieldMutationVariables>;

/**
 * __useUpdateModelFieldMutation__
 *
 * To run a mutation, you first call `useUpdateModelFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelFieldMutation, { data, loading, error }] = useUpdateModelFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateModelFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelFieldMutation, UpdateModelFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelFieldMutation, UpdateModelFieldMutationVariables>(UpdateModelFieldDocument, options);
      }
export type UpdateModelFieldMutationHookResult = ReturnType<typeof useUpdateModelFieldMutation>;
export type UpdateModelFieldMutationResult = Apollo.MutationResult<UpdateModelFieldMutation>;
export type UpdateModelFieldMutationOptions = Apollo.BaseMutationOptions<UpdateModelFieldMutation, UpdateModelFieldMutationVariables>;
export const UpdateModelSnapshotDocument = gql`
    mutation UpdateModelSnapshot($model: UUID!, $project: UUID!, $environment: UUID!) {
  updateModelSnapshot(
    input: {fModelId: $model, fProjectId: $project, fEnvironmentId: $environment}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateModelSnapshotMutationFn = Apollo.MutationFunction<UpdateModelSnapshotMutation, UpdateModelSnapshotMutationVariables>;

/**
 * __useUpdateModelSnapshotMutation__
 *
 * To run a mutation, you first call `useUpdateModelSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelSnapshotMutation, { data, loading, error }] = useUpdateModelSnapshotMutation({
 *   variables: {
 *      model: // value for 'model'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useUpdateModelSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelSnapshotMutation, UpdateModelSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelSnapshotMutation, UpdateModelSnapshotMutationVariables>(UpdateModelSnapshotDocument, options);
      }
export type UpdateModelSnapshotMutationHookResult = ReturnType<typeof useUpdateModelSnapshotMutation>;
export type UpdateModelSnapshotMutationResult = Apollo.MutationResult<UpdateModelSnapshotMutation>;
export type UpdateModelSnapshotMutationOptions = Apollo.BaseMutationOptions<UpdateModelSnapshotMutation, UpdateModelSnapshotMutationVariables>;
export const ListTaxonomyDocument = gql`
    query ListTaxonomy($environment: UUID!, $project: UUID!, $parent: UUID) {
  list: taxonomies(
    first: 100
    condition: {environmentId: $environment, projectId: $project, parentId: $parent, deletedAt: null}
    orderBy: [ALIAS_ASC]
  ) {
    nodes {
      id
      alias
      type
      parentId
    }
  }
}
    `;

/**
 * __useListTaxonomyQuery__
 *
 * To run a query within a React component, call `useListTaxonomyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaxonomyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaxonomyQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *      project: // value for 'project'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useListTaxonomyQuery(baseOptions: Apollo.QueryHookOptions<ListTaxonomyQuery, ListTaxonomyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTaxonomyQuery, ListTaxonomyQueryVariables>(ListTaxonomyDocument, options);
      }
export function useListTaxonomyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTaxonomyQuery, ListTaxonomyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTaxonomyQuery, ListTaxonomyQueryVariables>(ListTaxonomyDocument, options);
        }
export type ListTaxonomyQueryHookResult = ReturnType<typeof useListTaxonomyQuery>;
export type ListTaxonomyLazyQueryHookResult = ReturnType<typeof useListTaxonomyLazyQuery>;
export type ListTaxonomyQueryResult = Apollo.QueryResult<ListTaxonomyQuery, ListTaxonomyQueryVariables>;
export const ListTaxonomyItemsDocument = gql`
    query ListTaxonomyItems($environment: UUID!, $project: UUID!, $taxonomy: UUID) {
  list: taxonomyLevelItems(
    condition: {environmentId: $environment, projectId: $project, taxonomyId: $taxonomy}
    first: 50
    orderBy: [FRAGMENT_ASC, ALIAS_ASC]
  ) {
    nodes {
      alias
      fragment
      entryId
      targetId
    }
  }
}
    `;

/**
 * __useListTaxonomyItemsQuery__
 *
 * To run a query within a React component, call `useListTaxonomyItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaxonomyItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaxonomyItemsQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *      project: // value for 'project'
 *      taxonomy: // value for 'taxonomy'
 *   },
 * });
 */
export function useListTaxonomyItemsQuery(baseOptions: Apollo.QueryHookOptions<ListTaxonomyItemsQuery, ListTaxonomyItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTaxonomyItemsQuery, ListTaxonomyItemsQueryVariables>(ListTaxonomyItemsDocument, options);
      }
export function useListTaxonomyItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTaxonomyItemsQuery, ListTaxonomyItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTaxonomyItemsQuery, ListTaxonomyItemsQueryVariables>(ListTaxonomyItemsDocument, options);
        }
export type ListTaxonomyItemsQueryHookResult = ReturnType<typeof useListTaxonomyItemsQuery>;
export type ListTaxonomyItemsLazyQueryHookResult = ReturnType<typeof useListTaxonomyItemsLazyQuery>;
export type ListTaxonomyItemsQueryResult = Apollo.QueryResult<ListTaxonomyItemsQuery, ListTaxonomyItemsQueryVariables>;
export const GetTaxonomyDocument = gql`
    query GetTaxonomy($project: UUID!, $environment: UUID!, $id: UUID!) {
  entity: taxonomy(id: $id, projectId: $project, environmentId: $environment) {
    alias
    type
    deletedAt
    entry: entryId
    path {
      pathAlias
    }
    websitesByProjectIdAndEnvironmentIdAndTaxonomyId(first: 10) {
      nodes {
        id
        name
      }
    }
    levels(first: 20) {
      nodes {
        levelId: id
        parent: parentId
        aliasField: aliasFieldId
        fragmentField: fragmentFieldId
        fragmentType
        isNode
        inherit
        model: modelId
        modelsByDefaultTaxonomyLevelIdAndProjectIdAndEnvironmentId(first: 10) {
          nodes {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTaxonomyQuery__
 *
 * To run a query within a React component, call `useGetTaxonomyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxonomyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxonomyQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaxonomyQuery(baseOptions: Apollo.QueryHookOptions<GetTaxonomyQuery, GetTaxonomyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaxonomyQuery, GetTaxonomyQueryVariables>(GetTaxonomyDocument, options);
      }
export function useGetTaxonomyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaxonomyQuery, GetTaxonomyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaxonomyQuery, GetTaxonomyQueryVariables>(GetTaxonomyDocument, options);
        }
export type GetTaxonomyQueryHookResult = ReturnType<typeof useGetTaxonomyQuery>;
export type GetTaxonomyLazyQueryHookResult = ReturnType<typeof useGetTaxonomyLazyQuery>;
export type GetTaxonomyQueryResult = Apollo.QueryResult<GetTaxonomyQuery, GetTaxonomyQueryVariables>;
export const CreateTaxonomyDocument = gql`
    mutation CreateTaxonomy($input: TaxonomyInput!) {
  create: createTaxonomy(input: {taxonomy: $input}) {
    entity: taxonomy {
      id
    }
  }
}
    `;
export type CreateTaxonomyMutationFn = Apollo.MutationFunction<CreateTaxonomyMutation, CreateTaxonomyMutationVariables>;

/**
 * __useCreateTaxonomyMutation__
 *
 * To run a mutation, you first call `useCreateTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxonomyMutation, { data, loading, error }] = useCreateTaxonomyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxonomyMutation, CreateTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxonomyMutation, CreateTaxonomyMutationVariables>(CreateTaxonomyDocument, options);
      }
export type CreateTaxonomyMutationHookResult = ReturnType<typeof useCreateTaxonomyMutation>;
export type CreateTaxonomyMutationResult = Apollo.MutationResult<CreateTaxonomyMutation>;
export type CreateTaxonomyMutationOptions = Apollo.BaseMutationOptions<CreateTaxonomyMutation, CreateTaxonomyMutationVariables>;
export const UpdateTaxonomyDocument = gql`
    mutation UpdateTaxonomy($project: UUID!, $id: UUID!, $environment: UUID!, $patch: TaxonomyPatch!) {
  update: updateTaxonomy(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: taxonomy {
      id
    }
  }
}
    `;
export type UpdateTaxonomyMutationFn = Apollo.MutationFunction<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>;

/**
 * __useUpdateTaxonomyMutation__
 *
 * To run a mutation, you first call `useUpdateTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxonomyMutation, { data, loading, error }] = useUpdateTaxonomyMutation({
 *   variables: {
 *      project: // value for 'project'
 *      id: // value for 'id'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>(UpdateTaxonomyDocument, options);
      }
export type UpdateTaxonomyMutationHookResult = ReturnType<typeof useUpdateTaxonomyMutation>;
export type UpdateTaxonomyMutationResult = Apollo.MutationResult<UpdateTaxonomyMutation>;
export type UpdateTaxonomyMutationOptions = Apollo.BaseMutationOptions<UpdateTaxonomyMutation, UpdateTaxonomyMutationVariables>;
export const DeleteTaxonomyDocument = gql`
    mutation DeleteTaxonomy($project: UUID!, $id: UUID!, $environment: UUID!) {
  del: deleteTaxonomy(
    input: {id: $id, projectId: $project, environmentId: $environment}
  ) {
    entity: taxonomy {
      id
    }
  }
}
    `;
export type DeleteTaxonomyMutationFn = Apollo.MutationFunction<DeleteTaxonomyMutation, DeleteTaxonomyMutationVariables>;

/**
 * __useDeleteTaxonomyMutation__
 *
 * To run a mutation, you first call `useDeleteTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxonomyMutation, { data, loading, error }] = useDeleteTaxonomyMutation({
 *   variables: {
 *      project: // value for 'project'
 *      id: // value for 'id'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useDeleteTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxonomyMutation, DeleteTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxonomyMutation, DeleteTaxonomyMutationVariables>(DeleteTaxonomyDocument, options);
      }
export type DeleteTaxonomyMutationHookResult = ReturnType<typeof useDeleteTaxonomyMutation>;
export type DeleteTaxonomyMutationResult = Apollo.MutationResult<DeleteTaxonomyMutation>;
export type DeleteTaxonomyMutationOptions = Apollo.BaseMutationOptions<DeleteTaxonomyMutation, DeleteTaxonomyMutationVariables>;
export const WatchTaxonomyDocument = gql`
    subscription WatchTaxonomy($project: UUID!) {
  taxonomyChanged(project: $project) {
    id
    parent
    event
  }
}
    `;

/**
 * __useWatchTaxonomySubscription__
 *
 * To run a query within a React component, call `useWatchTaxonomySubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaxonomySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaxonomySubscription({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWatchTaxonomySubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchTaxonomySubscription, WatchTaxonomySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchTaxonomySubscription, WatchTaxonomySubscriptionVariables>(WatchTaxonomyDocument, options);
      }
export type WatchTaxonomySubscriptionHookResult = ReturnType<typeof useWatchTaxonomySubscription>;
export type WatchTaxonomySubscriptionResult = Apollo.SubscriptionResult<WatchTaxonomySubscription>;
export const TaxonomyListNodesDocument = gql`
    query TaxonomyListNodes($project: UUID!, $environment: UUID!, $target: UUID!, $skip: Int!, $take: Int!, $parent: [String!]!) {
  list: taxonomyListNodes(
    fProjectId: $project
    fEnvironmentId: $environment
    fTarget: $target
    fParent: $parent
    offset: $skip
    first: $take
  ) {
    nodes {
      path
      entryId
      taxonomyId
      type
      entry {
        id
        name
      }
    }
    totalCount
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useTaxonomyListNodesQuery__
 *
 * To run a query within a React component, call `useTaxonomyListNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxonomyListNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxonomyListNodesQuery({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      target: // value for 'target'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useTaxonomyListNodesQuery(baseOptions: Apollo.QueryHookOptions<TaxonomyListNodesQuery, TaxonomyListNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxonomyListNodesQuery, TaxonomyListNodesQueryVariables>(TaxonomyListNodesDocument, options);
      }
export function useTaxonomyListNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxonomyListNodesQuery, TaxonomyListNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxonomyListNodesQuery, TaxonomyListNodesQueryVariables>(TaxonomyListNodesDocument, options);
        }
export type TaxonomyListNodesQueryHookResult = ReturnType<typeof useTaxonomyListNodesQuery>;
export type TaxonomyListNodesLazyQueryHookResult = ReturnType<typeof useTaxonomyListNodesLazyQuery>;
export type TaxonomyListNodesQueryResult = Apollo.QueryResult<TaxonomyListNodesQuery, TaxonomyListNodesQueryVariables>;
export const GetValueDocument = gql`
    query GetValue($id: UUID!, $project: UUID!, $environment: UUID!) {
  value(id: $id, environmentId: $environment, projectId: $project) {
    ...ValueDetail
  }
}
    ${ValueDetailFragmentDoc}`;

/**
 * __useGetValueQuery__
 *
 * To run a query within a React component, call `useGetValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetValueQuery(baseOptions: Apollo.QueryHookOptions<GetValueQuery, GetValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValueQuery, GetValueQueryVariables>(GetValueDocument, options);
      }
export function useGetValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValueQuery, GetValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValueQuery, GetValueQueryVariables>(GetValueDocument, options);
        }
export type GetValueQueryHookResult = ReturnType<typeof useGetValueQuery>;
export type GetValueLazyQueryHookResult = ReturnType<typeof useGetValueLazyQuery>;
export type GetValueQueryResult = Apollo.QueryResult<GetValueQuery, GetValueQueryVariables>;
export const CreateValueDocument = gql`
    mutation CreateValue($model: UUID!, $project: UUID!, $environment: UUID!) {
  create: createValue(
    input: {value: {modelId: $model, environmentId: $environment, projectId: $project}}
  ) {
    entity: value {
      ...ValueDetail
    }
  }
}
    ${ValueDetailFragmentDoc}`;
export type CreateValueMutationFn = Apollo.MutationFunction<CreateValueMutation, CreateValueMutationVariables>;

/**
 * __useCreateValueMutation__
 *
 * To run a mutation, you first call `useCreateValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValueMutation, { data, loading, error }] = useCreateValueMutation({
 *   variables: {
 *      model: // value for 'model'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useCreateValueMutation(baseOptions?: Apollo.MutationHookOptions<CreateValueMutation, CreateValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateValueMutation, CreateValueMutationVariables>(CreateValueDocument, options);
      }
export type CreateValueMutationHookResult = ReturnType<typeof useCreateValueMutation>;
export type CreateValueMutationResult = Apollo.MutationResult<CreateValueMutation>;
export type CreateValueMutationOptions = Apollo.BaseMutationOptions<CreateValueMutation, CreateValueMutationVariables>;
export const GetValueFieldDocument = gql`
    query GetValueField($field: UUID!, $value: UUID!, $project: UUID!, $environment: UUID!) {
  valueFields(
    first: 100
    condition: {modelFieldId: $field, valueId: $value, environmentId: $environment, projectId: $project}
    orderBy: INDEX_ASC
  ) {
    nodes {
      ...ValueFieldDetail
    }
  }
}
    ${ValueFieldDetailFragmentDoc}`;

/**
 * __useGetValueFieldQuery__
 *
 * To run a query within a React component, call `useGetValueFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueFieldQuery({
 *   variables: {
 *      field: // value for 'field'
 *      value: // value for 'value'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useGetValueFieldQuery(baseOptions: Apollo.QueryHookOptions<GetValueFieldQuery, GetValueFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValueFieldQuery, GetValueFieldQueryVariables>(GetValueFieldDocument, options);
      }
export function useGetValueFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValueFieldQuery, GetValueFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValueFieldQuery, GetValueFieldQueryVariables>(GetValueFieldDocument, options);
        }
export type GetValueFieldQueryHookResult = ReturnType<typeof useGetValueFieldQuery>;
export type GetValueFieldLazyQueryHookResult = ReturnType<typeof useGetValueFieldLazyQuery>;
export type GetValueFieldQueryResult = Apollo.QueryResult<GetValueFieldQuery, GetValueFieldQueryVariables>;
export const UpdateValueDocument = gql`
    mutation UpdateValue($id: UUID!, $project: UUID!, $environment: UUID!, $create: [ValueFieldValueFkeyValueFieldCreateInput!], $updates: [ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate!], $deletes: [ValueFieldValueFieldPkeyDelete!]) {
  updateValue(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: {fields: {create: $create, updateByIdAndProjectIdAndEnvironmentId: $updates, deleteByIdAndProjectIdAndEnvironmentId: $deletes}}}
  ) {
    value {
      entryVersions(first: 1) {
        nodes {
          id
          modifiedAt
          modifiedBy {
            id
            name
          }
        }
      }
      fields(
        first: 100
        condition: {valueId: $id, environmentId: $environment, projectId: $project}
        orderBy: INDEX_ASC
      ) {
        nodes {
          ...ValueFieldDetail
        }
      }
    }
  }
}
    ${ValueFieldDetailFragmentDoc}`;
export type UpdateValueMutationFn = Apollo.MutationFunction<UpdateValueMutation, UpdateValueMutationVariables>;

/**
 * __useUpdateValueMutation__
 *
 * To run a mutation, you first call `useUpdateValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateValueMutation, { data, loading, error }] = useUpdateValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      create: // value for 'create'
 *      updates: // value for 'updates'
 *      deletes: // value for 'deletes'
 *   },
 * });
 */
export function useUpdateValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateValueMutation, UpdateValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateValueMutation, UpdateValueMutationVariables>(UpdateValueDocument, options);
      }
export type UpdateValueMutationHookResult = ReturnType<typeof useUpdateValueMutation>;
export type UpdateValueMutationResult = Apollo.MutationResult<UpdateValueMutation>;
export type UpdateValueMutationOptions = Apollo.BaseMutationOptions<UpdateValueMutation, UpdateValueMutationVariables>;
export const UpdateValueFieldDocument = gql`
    mutation UpdateValueField($id: UUID!, $project: UUID!, $environment: UUID!, $patch: ValueFieldPatch!) {
  update: updateValueField(
    input: {id: $id, projectId: $project, environmentId: $environment, patch: $patch}
  ) {
    entity: valueField {
      id
    }
  }
}
    `;
export type UpdateValueFieldMutationFn = Apollo.MutationFunction<UpdateValueFieldMutation, UpdateValueFieldMutationVariables>;

/**
 * __useUpdateValueFieldMutation__
 *
 * To run a mutation, you first call `useUpdateValueFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateValueFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateValueFieldMutation, { data, loading, error }] = useUpdateValueFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateValueFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateValueFieldMutation, UpdateValueFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateValueFieldMutation, UpdateValueFieldMutationVariables>(UpdateValueFieldDocument, options);
      }
export type UpdateValueFieldMutationHookResult = ReturnType<typeof useUpdateValueFieldMutation>;
export type UpdateValueFieldMutationResult = Apollo.MutationResult<UpdateValueFieldMutation>;
export type UpdateValueFieldMutationOptions = Apollo.BaseMutationOptions<UpdateValueFieldMutation, UpdateValueFieldMutationVariables>;
export const CreateValueFieldDocument = gql`
    mutation CreateValueField($value: ValueFieldInput!) {
  create: createValueField(input: {valueField: $value}) {
    entity: valueField {
      id
    }
  }
}
    `;
export type CreateValueFieldMutationFn = Apollo.MutationFunction<CreateValueFieldMutation, CreateValueFieldMutationVariables>;

/**
 * __useCreateValueFieldMutation__
 *
 * To run a mutation, you first call `useCreateValueFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValueFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValueFieldMutation, { data, loading, error }] = useCreateValueFieldMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateValueFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateValueFieldMutation, CreateValueFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateValueFieldMutation, CreateValueFieldMutationVariables>(CreateValueFieldDocument, options);
      }
export type CreateValueFieldMutationHookResult = ReturnType<typeof useCreateValueFieldMutation>;
export type CreateValueFieldMutationResult = Apollo.MutationResult<CreateValueFieldMutation>;
export type CreateValueFieldMutationOptions = Apollo.BaseMutationOptions<CreateValueFieldMutation, CreateValueFieldMutationVariables>;
export const CreateValueRawDocument = gql`
    mutation CreateValueRaw($input: ValueInput!) {
  create: createValue(input: {value: $input}) {
    entity: value {
      id
    }
  }
}
    `;
export type CreateValueRawMutationFn = Apollo.MutationFunction<CreateValueRawMutation, CreateValueRawMutationVariables>;

/**
 * __useCreateValueRawMutation__
 *
 * To run a mutation, you first call `useCreateValueRawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValueRawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValueRawMutation, { data, loading, error }] = useCreateValueRawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateValueRawMutation(baseOptions?: Apollo.MutationHookOptions<CreateValueRawMutation, CreateValueRawMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateValueRawMutation, CreateValueRawMutationVariables>(CreateValueRawDocument, options);
      }
export type CreateValueRawMutationHookResult = ReturnType<typeof useCreateValueRawMutation>;
export type CreateValueRawMutationResult = Apollo.MutationResult<CreateValueRawMutation>;
export type CreateValueRawMutationOptions = Apollo.BaseMutationOptions<CreateValueRawMutation, CreateValueRawMutationVariables>;
export const WatchMediaDocument = gql`
    subscription WatchMedia($project: UUID!) {
  mediaChanged(project: $project) {
    event
    table
    id
    parent
  }
}
    `;

/**
 * __useWatchMediaSubscription__
 *
 * To run a query within a React component, call `useWatchMediaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchMediaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchMediaSubscription({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWatchMediaSubscription(baseOptions: Apollo.SubscriptionHookOptions<WatchMediaSubscription, WatchMediaSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WatchMediaSubscription, WatchMediaSubscriptionVariables>(WatchMediaDocument, options);
      }
export type WatchMediaSubscriptionHookResult = ReturnType<typeof useWatchMediaSubscription>;
export type WatchMediaSubscriptionResult = Apollo.SubscriptionResult<WatchMediaSubscription>;
export const ListMediaItemsDocument = gql`
    query ListMediaItems($project: UUID!, $folder: UUID, $filter: MediaItemFilter, $skip: Int! = 0, $take: Int! = 25) {
  list: mediaItems(
    offset: $skip
    first: $take
    condition: {projectId: $project, folderId: $folder}
    filter: $filter
    orderBy: NAME_ASC
  ) {
    nodes {
      ...MediaItemDetail
    }
    totalCount
  }
}
    ${MediaItemDetailFragmentDoc}`;

/**
 * __useListMediaItemsQuery__
 *
 * To run a query within a React component, call `useListMediaItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMediaItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMediaItemsQuery({
 *   variables: {
 *      project: // value for 'project'
 *      folder: // value for 'folder'
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListMediaItemsQuery(baseOptions: Apollo.QueryHookOptions<ListMediaItemsQuery, ListMediaItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMediaItemsQuery, ListMediaItemsQueryVariables>(ListMediaItemsDocument, options);
      }
export function useListMediaItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMediaItemsQuery, ListMediaItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMediaItemsQuery, ListMediaItemsQueryVariables>(ListMediaItemsDocument, options);
        }
export type ListMediaItemsQueryHookResult = ReturnType<typeof useListMediaItemsQuery>;
export type ListMediaItemsLazyQueryHookResult = ReturnType<typeof useListMediaItemsLazyQuery>;
export type ListMediaItemsQueryResult = Apollo.QueryResult<ListMediaItemsQuery, ListMediaItemsQueryVariables>;
export const ListMediaFoldersDocument = gql`
    query ListMediaFolders($project: UUID!, $parent: UUID, $skip: Int! = 0, $take: Int! = 25) {
  list: mediaFolders(
    first: $take
    offset: $skip
    condition: {projectId: $project, deletedAt: null, parentId: $parent}
    orderBy: NAME_ASC
  ) {
    nodes {
      ...MediaFolderDetail
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${MediaFolderDetailFragmentDoc}`;

/**
 * __useListMediaFoldersQuery__
 *
 * To run a query within a React component, call `useListMediaFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMediaFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMediaFoldersQuery({
 *   variables: {
 *      project: // value for 'project'
 *      parent: // value for 'parent'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListMediaFoldersQuery(baseOptions: Apollo.QueryHookOptions<ListMediaFoldersQuery, ListMediaFoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMediaFoldersQuery, ListMediaFoldersQueryVariables>(ListMediaFoldersDocument, options);
      }
export function useListMediaFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMediaFoldersQuery, ListMediaFoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMediaFoldersQuery, ListMediaFoldersQueryVariables>(ListMediaFoldersDocument, options);
        }
export type ListMediaFoldersQueryHookResult = ReturnType<typeof useListMediaFoldersQuery>;
export type ListMediaFoldersLazyQueryHookResult = ReturnType<typeof useListMediaFoldersLazyQuery>;
export type ListMediaFoldersQueryResult = Apollo.QueryResult<ListMediaFoldersQuery, ListMediaFoldersQueryVariables>;
export const GetMediaFolderDocument = gql`
    query GetMediaFolder($id: UUID!) {
  entity: mediaFolder(id: $id) {
    ...MediaFolderDetail
  }
}
    ${MediaFolderDetailFragmentDoc}`;

/**
 * __useGetMediaFolderQuery__
 *
 * To run a query within a React component, call `useGetMediaFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaFolderQuery(baseOptions: Apollo.QueryHookOptions<GetMediaFolderQuery, GetMediaFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaFolderQuery, GetMediaFolderQueryVariables>(GetMediaFolderDocument, options);
      }
export function useGetMediaFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaFolderQuery, GetMediaFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaFolderQuery, GetMediaFolderQueryVariables>(GetMediaFolderDocument, options);
        }
export type GetMediaFolderQueryHookResult = ReturnType<typeof useGetMediaFolderQuery>;
export type GetMediaFolderLazyQueryHookResult = ReturnType<typeof useGetMediaFolderLazyQuery>;
export type GetMediaFolderQueryResult = Apollo.QueryResult<GetMediaFolderQuery, GetMediaFolderQueryVariables>;
export const MediaFolderContentsDocument = gql`
    query MediaFolderContents($project: UUID!, $folder: UUID) {
  items: mediaItems(
    first: 250
    orderBy: NAME_ASC
    condition: {projectId: $project, folderId: $folder, deletedAt: null}
  ) {
    nodes {
      ...MediaItemDetail
    }
  }
  folders: mediaFolders(
    first: 250
    orderBy: NAME_ASC
    condition: {projectId: $project, parentId: $folder, deletedAt: null}
  ) {
    nodes {
      ...MediaFolderDetail
    }
  }
}
    ${MediaItemDetailFragmentDoc}
${MediaFolderDetailFragmentDoc}`;

/**
 * __useMediaFolderContentsQuery__
 *
 * To run a query within a React component, call `useMediaFolderContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaFolderContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaFolderContentsQuery({
 *   variables: {
 *      project: // value for 'project'
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useMediaFolderContentsQuery(baseOptions: Apollo.QueryHookOptions<MediaFolderContentsQuery, MediaFolderContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaFolderContentsQuery, MediaFolderContentsQueryVariables>(MediaFolderContentsDocument, options);
      }
export function useMediaFolderContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaFolderContentsQuery, MediaFolderContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaFolderContentsQuery, MediaFolderContentsQueryVariables>(MediaFolderContentsDocument, options);
        }
export type MediaFolderContentsQueryHookResult = ReturnType<typeof useMediaFolderContentsQuery>;
export type MediaFolderContentsLazyQueryHookResult = ReturnType<typeof useMediaFolderContentsLazyQuery>;
export type MediaFolderContentsQueryResult = Apollo.QueryResult<MediaFolderContentsQuery, MediaFolderContentsQueryVariables>;
export const GetMediaItemDocument = gql`
    query GetMediaItem($id: UUID!) {
  entity: mediaItem(id: $id) {
    ...MediaItemDetail
  }
}
    ${MediaItemDetailFragmentDoc}`;

/**
 * __useGetMediaItemQuery__
 *
 * To run a query within a React component, call `useGetMediaItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaItemQuery(baseOptions: Apollo.QueryHookOptions<GetMediaItemQuery, GetMediaItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaItemQuery, GetMediaItemQueryVariables>(GetMediaItemDocument, options);
      }
export function useGetMediaItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaItemQuery, GetMediaItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaItemQuery, GetMediaItemQueryVariables>(GetMediaItemDocument, options);
        }
export type GetMediaItemQueryHookResult = ReturnType<typeof useGetMediaItemQuery>;
export type GetMediaItemLazyQueryHookResult = ReturnType<typeof useGetMediaItemLazyQuery>;
export type GetMediaItemQueryResult = Apollo.QueryResult<GetMediaItemQuery, GetMediaItemQueryVariables>;
export const UpdateMediaItemDocument = gql`
    mutation UpdateMediaItem($id: UUID!, $patch: MediaItemPatch!) {
  update: updateMediaItem(input: {id: $id, patch: $patch}) {
    entity: mediaItem {
      ...MediaItemDetail
    }
  }
}
    ${MediaItemDetailFragmentDoc}`;
export type UpdateMediaItemMutationFn = Apollo.MutationFunction<UpdateMediaItemMutation, UpdateMediaItemMutationVariables>;

/**
 * __useUpdateMediaItemMutation__
 *
 * To run a mutation, you first call `useUpdateMediaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaItemMutation, { data, loading, error }] = useUpdateMediaItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateMediaItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaItemMutation, UpdateMediaItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaItemMutation, UpdateMediaItemMutationVariables>(UpdateMediaItemDocument, options);
      }
export type UpdateMediaItemMutationHookResult = ReturnType<typeof useUpdateMediaItemMutation>;
export type UpdateMediaItemMutationResult = Apollo.MutationResult<UpdateMediaItemMutation>;
export type UpdateMediaItemMutationOptions = Apollo.BaseMutationOptions<UpdateMediaItemMutation, UpdateMediaItemMutationVariables>;
export const UpdateMediaFolderDocument = gql`
    mutation UpdateMediaFolder($id: UUID!, $patch: MediaFolderPatch!) {
  update: updateMediaFolder(input: {id: $id, patch: $patch}) {
    entity: mediaFolder {
      ...MediaFolderDetail
    }
  }
}
    ${MediaFolderDetailFragmentDoc}`;
export type UpdateMediaFolderMutationFn = Apollo.MutationFunction<UpdateMediaFolderMutation, UpdateMediaFolderMutationVariables>;

/**
 * __useUpdateMediaFolderMutation__
 *
 * To run a mutation, you first call `useUpdateMediaFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaFolderMutation, { data, loading, error }] = useUpdateMediaFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateMediaFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaFolderMutation, UpdateMediaFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaFolderMutation, UpdateMediaFolderMutationVariables>(UpdateMediaFolderDocument, options);
      }
export type UpdateMediaFolderMutationHookResult = ReturnType<typeof useUpdateMediaFolderMutation>;
export type UpdateMediaFolderMutationResult = Apollo.MutationResult<UpdateMediaFolderMutation>;
export type UpdateMediaFolderMutationOptions = Apollo.BaseMutationOptions<UpdateMediaFolderMutation, UpdateMediaFolderMutationVariables>;
export const GenerateMediaUploadUrlDocument = gql`
    mutation GenerateMediaUploadUrl($input: MediaUploadUrlInput!) {
  mediaUploadUrl(input: $input) {
    url
    headers
  }
}
    `;
export type GenerateMediaUploadUrlMutationFn = Apollo.MutationFunction<GenerateMediaUploadUrlMutation, GenerateMediaUploadUrlMutationVariables>;

/**
 * __useGenerateMediaUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateMediaUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMediaUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMediaUploadUrlMutation, { data, loading, error }] = useGenerateMediaUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMediaUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMediaUploadUrlMutation, GenerateMediaUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMediaUploadUrlMutation, GenerateMediaUploadUrlMutationVariables>(GenerateMediaUploadUrlDocument, options);
      }
export type GenerateMediaUploadUrlMutationHookResult = ReturnType<typeof useGenerateMediaUploadUrlMutation>;
export type GenerateMediaUploadUrlMutationResult = Apollo.MutationResult<GenerateMediaUploadUrlMutation>;
export type GenerateMediaUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateMediaUploadUrlMutation, GenerateMediaUploadUrlMutationVariables>;
export const CreateMediaItemDocument = gql`
    mutation CreateMediaItem($input: MediaItemInput!) {
  create: createMediaItem(input: {mediaItem: $input}) {
    entity: mediaItem {
      ...MediaItemDetail
    }
  }
}
    ${MediaItemDetailFragmentDoc}`;
export type CreateMediaItemMutationFn = Apollo.MutationFunction<CreateMediaItemMutation, CreateMediaItemMutationVariables>;

/**
 * __useCreateMediaItemMutation__
 *
 * To run a mutation, you first call `useCreateMediaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaItemMutation, { data, loading, error }] = useCreateMediaItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaItemMutation, CreateMediaItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaItemMutation, CreateMediaItemMutationVariables>(CreateMediaItemDocument, options);
      }
export type CreateMediaItemMutationHookResult = ReturnType<typeof useCreateMediaItemMutation>;
export type CreateMediaItemMutationResult = Apollo.MutationResult<CreateMediaItemMutation>;
export type CreateMediaItemMutationOptions = Apollo.BaseMutationOptions<CreateMediaItemMutation, CreateMediaItemMutationVariables>;
export const CreateMediaFolderDocument = gql`
    mutation CreateMediaFolder($input: MediaFolderInput!) {
  create: createMediaFolder(input: {mediaFolder: $input}) {
    entity: mediaFolder {
      id
      name
    }
  }
}
    `;
export type CreateMediaFolderMutationFn = Apollo.MutationFunction<CreateMediaFolderMutation, CreateMediaFolderMutationVariables>;

/**
 * __useCreateMediaFolderMutation__
 *
 * To run a mutation, you first call `useCreateMediaFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaFolderMutation, { data, loading, error }] = useCreateMediaFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaFolderMutation, CreateMediaFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaFolderMutation, CreateMediaFolderMutationVariables>(CreateMediaFolderDocument, options);
      }
export type CreateMediaFolderMutationHookResult = ReturnType<typeof useCreateMediaFolderMutation>;
export type CreateMediaFolderMutationResult = Apollo.MutationResult<CreateMediaFolderMutation>;
export type CreateMediaFolderMutationOptions = Apollo.BaseMutationOptions<CreateMediaFolderMutation, CreateMediaFolderMutationVariables>;
export const ListProjectsDocument = gql`
    query ListProjects {
  list: projects(first: 25, orderBy: NAME_ASC) {
    nodes {
      id
      key
      name
      deletedAt
      defaultEnvironment {
        id
        key
      }
      account {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, options);
      }
export function useListProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, options);
        }
export type ListProjectsQueryHookResult = ReturnType<typeof useListProjectsQuery>;
export type ListProjectsLazyQueryHookResult = ReturnType<typeof useListProjectsLazyQuery>;
export type ListProjectsQueryResult = Apollo.QueryResult<ListProjectsQuery, ListProjectsQueryVariables>;
export const ListProjectTokensDocument = gql`
    query ListProjectTokens($project: UUID!) {
  list: projectTokens(first: 25, condition: {projectId: $project}) {
    nodes {
      id
      token
      createdAt
      targetId
    }
  }
}
    `;

/**
 * __useListProjectTokensQuery__
 *
 * To run a query within a React component, call `useListProjectTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectTokensQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useListProjectTokensQuery(baseOptions: Apollo.QueryHookOptions<ListProjectTokensQuery, ListProjectTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProjectTokensQuery, ListProjectTokensQueryVariables>(ListProjectTokensDocument, options);
      }
export function useListProjectTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProjectTokensQuery, ListProjectTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProjectTokensQuery, ListProjectTokensQueryVariables>(ListProjectTokensDocument, options);
        }
export type ListProjectTokensQueryHookResult = ReturnType<typeof useListProjectTokensQuery>;
export type ListProjectTokensLazyQueryHookResult = ReturnType<typeof useListProjectTokensLazyQuery>;
export type ListProjectTokensQueryResult = Apollo.QueryResult<ListProjectTokensQuery, ListProjectTokensQueryVariables>;
export const CreateProjectTokenDocument = gql`
    mutation CreateProjectToken($project: UUID!, $target: UUID!) {
  create: createProjectToken(
    input: {projectToken: {projectId: $project, targetId: $target}}
  ) {
    entity: projectToken {
      id
      token
      createdAt
    }
  }
}
    `;
export type CreateProjectTokenMutationFn = Apollo.MutationFunction<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>;

/**
 * __useCreateProjectTokenMutation__
 *
 * To run a mutation, you first call `useCreateProjectTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTokenMutation, { data, loading, error }] = useCreateProjectTokenMutation({
 *   variables: {
 *      project: // value for 'project'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCreateProjectTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>(CreateProjectTokenDocument, options);
      }
export type CreateProjectTokenMutationHookResult = ReturnType<typeof useCreateProjectTokenMutation>;
export type CreateProjectTokenMutationResult = Apollo.MutationResult<CreateProjectTokenMutation>;
export type CreateProjectTokenMutationOptions = Apollo.BaseMutationOptions<CreateProjectTokenMutation, CreateProjectTokenMutationVariables>;
export const UpdateProjectTokenDocument = gql`
    mutation UpdateProjectToken($id: UUID!, $patch: ProjectTokenPatch!) {
  update: updateProjectToken(input: {id: $id, patch: $patch}) {
    entity: projectToken {
      id
      token
      createdAt
    }
  }
}
    `;
export type UpdateProjectTokenMutationFn = Apollo.MutationFunction<UpdateProjectTokenMutation, UpdateProjectTokenMutationVariables>;

/**
 * __useUpdateProjectTokenMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTokenMutation, { data, loading, error }] = useUpdateProjectTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateProjectTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTokenMutation, UpdateProjectTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTokenMutation, UpdateProjectTokenMutationVariables>(UpdateProjectTokenDocument, options);
      }
export type UpdateProjectTokenMutationHookResult = ReturnType<typeof useUpdateProjectTokenMutation>;
export type UpdateProjectTokenMutationResult = Apollo.MutationResult<UpdateProjectTokenMutation>;
export type UpdateProjectTokenMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTokenMutation, UpdateProjectTokenMutationVariables>;
export const DeleteProjectTokenDocument = gql`
    mutation DeleteProjectToken($id: UUID!) {
  del: deleteProjectToken(input: {id: $id}) {
    entity: projectToken {
      id
      token
      createdAt
    }
  }
}
    `;
export type DeleteProjectTokenMutationFn = Apollo.MutationFunction<DeleteProjectTokenMutation, DeleteProjectTokenMutationVariables>;

/**
 * __useDeleteProjectTokenMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTokenMutation, { data, loading, error }] = useDeleteProjectTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectTokenMutation, DeleteProjectTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectTokenMutation, DeleteProjectTokenMutationVariables>(DeleteProjectTokenDocument, options);
      }
export type DeleteProjectTokenMutationHookResult = ReturnType<typeof useDeleteProjectTokenMutation>;
export type DeleteProjectTokenMutationResult = Apollo.MutationResult<DeleteProjectTokenMutation>;
export type DeleteProjectTokenMutationOptions = Apollo.BaseMutationOptions<DeleteProjectTokenMutation, DeleteProjectTokenMutationVariables>;
export const UpdateProjectDefaultEnvironmentDocument = gql`
    mutation UpdateProjectDefaultEnvironment($project: UUID!, $environment: UUID!) {
  updateProjectDefaultEnvironment(
    input: {fProject: $project, fEnvironment: $environment}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateProjectDefaultEnvironmentMutationFn = Apollo.MutationFunction<UpdateProjectDefaultEnvironmentMutation, UpdateProjectDefaultEnvironmentMutationVariables>;

/**
 * __useUpdateProjectDefaultEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateProjectDefaultEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectDefaultEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectDefaultEnvironmentMutation, { data, loading, error }] = useUpdateProjectDefaultEnvironmentMutation({
 *   variables: {
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useUpdateProjectDefaultEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectDefaultEnvironmentMutation, UpdateProjectDefaultEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectDefaultEnvironmentMutation, UpdateProjectDefaultEnvironmentMutationVariables>(UpdateProjectDefaultEnvironmentDocument, options);
      }
export type UpdateProjectDefaultEnvironmentMutationHookResult = ReturnType<typeof useUpdateProjectDefaultEnvironmentMutation>;
export type UpdateProjectDefaultEnvironmentMutationResult = Apollo.MutationResult<UpdateProjectDefaultEnvironmentMutation>;
export type UpdateProjectDefaultEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateProjectDefaultEnvironmentMutation, UpdateProjectDefaultEnvironmentMutationVariables>;
export const UpdateEnvironmentDocument = gql`
    mutation UpdateEnvironment($id: UUID!, $patch: ProjectEnvironmentPatch!) {
  update: updateProjectEnvironment(input: {id: $id, patch: $patch}) {
    entity: projectEnvironment {
      id
      name
    }
  }
}
    `;
export type UpdateEnvironmentMutationFn = Apollo.MutationFunction<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument, options);
      }
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationResult = Apollo.MutationResult<UpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;
export const CreateEnvironmentDocument = gql`
    mutation CreateEnvironment($input: ProjectEnvironmentInput!) {
  create: createProjectEnvironment(input: {projectEnvironment: $input}) {
    entity: projectEnvironment {
      id
      name
    }
  }
}
    `;
export type CreateEnvironmentMutationFn = Apollo.MutationFunction<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;

/**
 * __useCreateEnvironmentMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentMutation, { data, loading, error }] = useCreateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>(CreateEnvironmentDocument, options);
      }
export type CreateEnvironmentMutationHookResult = ReturnType<typeof useCreateEnvironmentMutation>;
export type CreateEnvironmentMutationResult = Apollo.MutationResult<CreateEnvironmentMutation>;
export type CreateEnvironmentMutationOptions = Apollo.BaseMutationOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;
export const CreatePublishTargetDocument = gql`
    mutation CreatePublishTarget($input: PublishTargetInput!) {
  create: createPublishTarget(input: {publishTarget: $input}) {
    entity: publishTarget {
      id
      name
      key
    }
  }
}
    `;
export type CreatePublishTargetMutationFn = Apollo.MutationFunction<CreatePublishTargetMutation, CreatePublishTargetMutationVariables>;

/**
 * __useCreatePublishTargetMutation__
 *
 * To run a mutation, you first call `useCreatePublishTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublishTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublishTargetMutation, { data, loading, error }] = useCreatePublishTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublishTargetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublishTargetMutation, CreatePublishTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublishTargetMutation, CreatePublishTargetMutationVariables>(CreatePublishTargetDocument, options);
      }
export type CreatePublishTargetMutationHookResult = ReturnType<typeof useCreatePublishTargetMutation>;
export type CreatePublishTargetMutationResult = Apollo.MutationResult<CreatePublishTargetMutation>;
export type CreatePublishTargetMutationOptions = Apollo.BaseMutationOptions<CreatePublishTargetMutation, CreatePublishTargetMutationVariables>;
export const UpdatePublishTargetDocument = gql`
    mutation UpdatePublishTarget($id: UUID!, $project: UUID!, $patch: PublishTargetPatch!) {
  update: updatePublishTarget(
    input: {id: $id, projectId: $project, patch: $patch}
  ) {
    entity: publishTarget {
      id
      name
      key
    }
  }
}
    `;
export type UpdatePublishTargetMutationFn = Apollo.MutationFunction<UpdatePublishTargetMutation, UpdatePublishTargetMutationVariables>;

/**
 * __useUpdatePublishTargetMutation__
 *
 * To run a mutation, you first call `useUpdatePublishTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishTargetMutation, { data, loading, error }] = useUpdatePublishTargetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdatePublishTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublishTargetMutation, UpdatePublishTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublishTargetMutation, UpdatePublishTargetMutationVariables>(UpdatePublishTargetDocument, options);
      }
export type UpdatePublishTargetMutationHookResult = ReturnType<typeof useUpdatePublishTargetMutation>;
export type UpdatePublishTargetMutationResult = Apollo.MutationResult<UpdatePublishTargetMutation>;
export type UpdatePublishTargetMutationOptions = Apollo.BaseMutationOptions<UpdatePublishTargetMutation, UpdatePublishTargetMutationVariables>;
export const CopyEnvironmentDocument = gql`
    mutation CopyEnvironment($input: CopyEnvironmentInput!) {
  copyEnvironment(input: $input) {
    clientMutationId
  }
}
    `;
export type CopyEnvironmentMutationFn = Apollo.MutationFunction<CopyEnvironmentMutation, CopyEnvironmentMutationVariables>;

/**
 * __useCopyEnvironmentMutation__
 *
 * To run a mutation, you first call `useCopyEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEnvironmentMutation, { data, loading, error }] = useCopyEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<CopyEnvironmentMutation, CopyEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyEnvironmentMutation, CopyEnvironmentMutationVariables>(CopyEnvironmentDocument, options);
      }
export type CopyEnvironmentMutationHookResult = ReturnType<typeof useCopyEnvironmentMutation>;
export type CopyEnvironmentMutationResult = Apollo.MutationResult<CopyEnvironmentMutation>;
export type CopyEnvironmentMutationOptions = Apollo.BaseMutationOptions<CopyEnvironmentMutation, CopyEnvironmentMutationVariables>;
export const GetProjectByKeyDocument = gql`
    query GetProjectByKey($account: UUID!, $key: String!) {
  project: projectByKey(key: $key, accountId: $account) {
    id
    key
    name
    deletedAt
    defaultEnvironment {
      id
      key
    }
    projectEnvironments(first: 25, orderBy: [DELETED_AT_DESC, NAME_ASC]) {
      nodes {
        id
        key
        name
        alias
        deletedAt
        models(first: 1) {
          totalCount
        }
        websites(first: 25, orderBy: [NAME_ASC]) {
          nodes {
            id
            name
            urlFormat
            targetId
            projectId
            environmentId
            projectEnvironmentTaxonomy {
              path {
                pathAlias
              }
            }
          }
        }
      }
    }
    publishTargets(first: 25, orderBy: [DELETED_AT_DESC, NAME_ASC]) {
      nodes {
        id
        projectId
        key
        name
      }
    }
  }
}
    `;

/**
 * __useGetProjectByKeyQuery__
 *
 * To run a query within a React component, call `useGetProjectByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByKeyQuery({
 *   variables: {
 *      account: // value for 'account'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetProjectByKeyQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByKeyQuery, GetProjectByKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByKeyQuery, GetProjectByKeyQueryVariables>(GetProjectByKeyDocument, options);
      }
export function useGetProjectByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByKeyQuery, GetProjectByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByKeyQuery, GetProjectByKeyQueryVariables>(GetProjectByKeyDocument, options);
        }
export type GetProjectByKeyQueryHookResult = ReturnType<typeof useGetProjectByKeyQuery>;
export type GetProjectByKeyLazyQueryHookResult = ReturnType<typeof useGetProjectByKeyLazyQuery>;
export type GetProjectByKeyQueryResult = Apollo.QueryResult<GetProjectByKeyQuery, GetProjectByKeyQueryVariables>;
export const BackgroundTaskDocument = gql`
    mutation BackgroundTask($task: String!, $project: UUID!, $environment: UUID, $target: UUID) {
  backgroundTask(
    input: {fTask: $task, fProjectId: $project, fEnvironmentId: $environment, fTargetId: $target}
  ) {
    clientMutationId
  }
}
    `;
export type BackgroundTaskMutationFn = Apollo.MutationFunction<BackgroundTaskMutation, BackgroundTaskMutationVariables>;

/**
 * __useBackgroundTaskMutation__
 *
 * To run a mutation, you first call `useBackgroundTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackgroundTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backgroundTaskMutation, { data, loading, error }] = useBackgroundTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *      project: // value for 'project'
 *      environment: // value for 'environment'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useBackgroundTaskMutation(baseOptions?: Apollo.MutationHookOptions<BackgroundTaskMutation, BackgroundTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackgroundTaskMutation, BackgroundTaskMutationVariables>(BackgroundTaskDocument, options);
      }
export type BackgroundTaskMutationHookResult = ReturnType<typeof useBackgroundTaskMutation>;
export type BackgroundTaskMutationResult = Apollo.MutationResult<BackgroundTaskMutation>;
export type BackgroundTaskMutationOptions = Apollo.BaseMutationOptions<BackgroundTaskMutation, BackgroundTaskMutationVariables>;
export const ProjectSearchDocument = gql`
    query ProjectSearch($project: UUID!, $input: String!) {
  entries(
    first: 5
    condition: {projectId: $project, deletedAt: null}
    filter: {name: {likeInsensitive: $input}}
  ) {
    nodes {
      id
      name
    }
  }
  models(
    first: 5
    condition: {projectId: $project, deletedAt: null}
    filter: {name: {likeInsensitive: $input}}
  ) {
    nodes {
      id
      name
    }
  }
  mediaItems(
    first: 5
    condition: {projectId: $project, deletedAt: null}
    filter: {name: {likeInsensitive: $input}}
  ) {
    nodes {
      id
      name
      folderId
    }
  }
}
    `;

/**
 * __useProjectSearchQuery__
 *
 * To run a query within a React component, call `useProjectSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSearchQuery({
 *   variables: {
 *      project: // value for 'project'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectSearchQuery(baseOptions: Apollo.QueryHookOptions<ProjectSearchQuery, ProjectSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSearchQuery, ProjectSearchQueryVariables>(ProjectSearchDocument, options);
      }
export function useProjectSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSearchQuery, ProjectSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSearchQuery, ProjectSearchQueryVariables>(ProjectSearchDocument, options);
        }
export type ProjectSearchQueryHookResult = ReturnType<typeof useProjectSearchQuery>;
export type ProjectSearchLazyQueryHookResult = ReturnType<typeof useProjectSearchLazyQuery>;
export type ProjectSearchQueryResult = Apollo.QueryResult<ProjectSearchQuery, ProjectSearchQueryVariables>;
export const ListWebhooksDocument = gql`
    query ListWebhooks($project: UUID!, $skip: Int! = 0, $take: Int! = 25, $filter: WebhookFilter) {
  list: webhooks(
    condition: {projectId: $project}
    filter: $filter
    offset: $skip
    first: $take
    orderBy: NAME_ASC
  ) {
    nodes {
      ...WebhookDetail
    }
    totalCount
  }
}
    ${WebhookDetailFragmentDoc}`;

/**
 * __useListWebhooksQuery__
 *
 * To run a query within a React component, call `useListWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhooksQuery({
 *   variables: {
 *      project: // value for 'project'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListWebhooksQuery(baseOptions: Apollo.QueryHookOptions<ListWebhooksQuery, ListWebhooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWebhooksQuery, ListWebhooksQueryVariables>(ListWebhooksDocument, options);
      }
export function useListWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWebhooksQuery, ListWebhooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWebhooksQuery, ListWebhooksQueryVariables>(ListWebhooksDocument, options);
        }
export type ListWebhooksQueryHookResult = ReturnType<typeof useListWebhooksQuery>;
export type ListWebhooksLazyQueryHookResult = ReturnType<typeof useListWebhooksLazyQuery>;
export type ListWebhooksQueryResult = Apollo.QueryResult<ListWebhooksQuery, ListWebhooksQueryVariables>;
export const CreateWebhookDocument = gql`
    mutation CreateWebhook($input: WebhookInput!) {
  create: createWebhook(input: {webhook: $input}) {
    entity: webhook {
      id
    }
  }
}
    `;
export type CreateWebhookMutationFn = Apollo.MutationFunction<CreateWebhookMutation, CreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebhookMutation, CreateWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebhookMutation, CreateWebhookMutationVariables>(CreateWebhookDocument, options);
      }
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const GetWebhookDocument = gql`
    query GetWebhook($id: UUID!) {
  entity: webhook(id: $id) {
    ...WebhookDetail
  }
}
    ${WebhookDetailFragmentDoc}`;

/**
 * __useGetWebhookQuery__
 *
 * To run a query within a React component, call `useGetWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWebhookQuery(baseOptions: Apollo.QueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options);
      }
export function useGetWebhookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options);
        }
export type GetWebhookQueryHookResult = ReturnType<typeof useGetWebhookQuery>;
export type GetWebhookLazyQueryHookResult = ReturnType<typeof useGetWebhookLazyQuery>;
export type GetWebhookQueryResult = Apollo.QueryResult<GetWebhookQuery, GetWebhookQueryVariables>;
export const ListWebhookLogsDocument = gql`
    query ListWebhookLogs($id: UUID!) {
  list: webhookLogs(
    first: 25
    condition: {webhookRunId: $id}
    orderBy: CREATED_AT_ASC
  ) {
    nodes {
      id
      level
      payload
      createdAt
    }
  }
}
    `;

/**
 * __useListWebhookLogsQuery__
 *
 * To run a query within a React component, call `useListWebhookLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhookLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhookLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListWebhookLogsQuery(baseOptions: Apollo.QueryHookOptions<ListWebhookLogsQuery, ListWebhookLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWebhookLogsQuery, ListWebhookLogsQueryVariables>(ListWebhookLogsDocument, options);
      }
export function useListWebhookLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWebhookLogsQuery, ListWebhookLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWebhookLogsQuery, ListWebhookLogsQueryVariables>(ListWebhookLogsDocument, options);
        }
export type ListWebhookLogsQueryHookResult = ReturnType<typeof useListWebhookLogsQuery>;
export type ListWebhookLogsLazyQueryHookResult = ReturnType<typeof useListWebhookLogsLazyQuery>;
export type ListWebhookLogsQueryResult = Apollo.QueryResult<ListWebhookLogsQuery, ListWebhookLogsQueryVariables>;
export const UpdateWebhookDocument = gql`
    mutation UpdateWebhook($id: UUID!, $patch: WebhookPatch!) {
  update: updateWebhook(input: {id: $id, patch: $patch}) {
    entity: webhook {
      ...WebhookDetail
    }
  }
}
    ${WebhookDetailFragmentDoc}`;
export type UpdateWebhookMutationFn = Apollo.MutationFunction<UpdateWebhookMutation, UpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateWebhookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookMutation, UpdateWebhookMutationVariables>(UpdateWebhookDocument, options);
      }
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>;
export const UpdateWebhookRunDocument = gql`
    mutation UpdateWebhookRun($id: UUID!, $patch: WebhookRunPatch!) {
  update: updateWebhookRun(input: {id: $id, patch: $patch}) {
    entity: webhookRun {
      id
      createdAt
      finishedAt
      status
    }
  }
}
    `;
export type UpdateWebhookRunMutationFn = Apollo.MutationFunction<UpdateWebhookRunMutation, UpdateWebhookRunMutationVariables>;

/**
 * __useUpdateWebhookRunMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookRunMutation, { data, loading, error }] = useUpdateWebhookRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateWebhookRunMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookRunMutation, UpdateWebhookRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookRunMutation, UpdateWebhookRunMutationVariables>(UpdateWebhookRunDocument, options);
      }
export type UpdateWebhookRunMutationHookResult = ReturnType<typeof useUpdateWebhookRunMutation>;
export type UpdateWebhookRunMutationResult = Apollo.MutationResult<UpdateWebhookRunMutation>;
export type UpdateWebhookRunMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookRunMutation, UpdateWebhookRunMutationVariables>;
export const CreateWebhookRunDocument = gql`
    mutation CreateWebhookRun($webhook: UUID!) {
  createWebhookRun(input: {webhookRun: {webhookId: $webhook}}) {
    webhook {
      id
      webhookRuns(orderBy: CREATED_AT_DESC, first: 10) {
        nodes {
          id
          createdAt
          finishedAt
          status
        }
      }
    }
  }
}
    `;
export type CreateWebhookRunMutationFn = Apollo.MutationFunction<CreateWebhookRunMutation, CreateWebhookRunMutationVariables>;

/**
 * __useCreateWebhookRunMutation__
 *
 * To run a mutation, you first call `useCreateWebhookRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookRunMutation, { data, loading, error }] = useCreateWebhookRunMutation({
 *   variables: {
 *      webhook: // value for 'webhook'
 *   },
 * });
 */
export function useCreateWebhookRunMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebhookRunMutation, CreateWebhookRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebhookRunMutation, CreateWebhookRunMutationVariables>(CreateWebhookRunDocument, options);
      }
export type CreateWebhookRunMutationHookResult = ReturnType<typeof useCreateWebhookRunMutation>;
export type CreateWebhookRunMutationResult = Apollo.MutationResult<CreateWebhookRunMutation>;
export type CreateWebhookRunMutationOptions = Apollo.BaseMutationOptions<CreateWebhookRunMutation, CreateWebhookRunMutationVariables>;
export const CreateWebsiteDocument = gql`
    mutation CreateWebsite($input: WebsiteInput!) {
  create: createWebsite(input: {website: $input}) {
    environment {
      websites(first: 25, orderBy: [NAME_ASC]) {
        nodes {
          id
          name
          urlFormat
          targetId
          projectId
          environmentId
          projectEnvironmentTaxonomy {
            path {
              pathAlias
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>;

/**
 * __useCreateWebsiteMutation__
 *
 * To run a mutation, you first call `useCreateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebsiteMutation, { data, loading, error }] = useCreateWebsiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, options);
      }
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>;
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>;
export type CreateWebsiteMutationOptions = Apollo.BaseMutationOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>;
export const UpdateWebsiteDocument = gql`
    mutation UpdateWebsite($id: UUID!, $project: UUID!, $patch: WebsitePatch!) {
  update: updateWebsite(input: {id: $id, projectId: $project, patch: $patch}) {
    entity: website {
      id
      name
    }
  }
}
    `;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;

/**
 * __useUpdateWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMutation, { data, loading, error }] = useUpdateWebsiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      project: // value for 'project'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument, options);
      }
export type UpdateWebsiteMutationHookResult = ReturnType<typeof useUpdateWebsiteMutation>;
export type UpdateWebsiteMutationResult = Apollo.MutationResult<UpdateWebsiteMutation>;
export type UpdateWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;