import * as React from "react";
import { Input } from "pokko-shared";
import { FieldInputProps } from "../../types";

export const Date: React.FC<FieldInputProps> = ({
  value,
  onChange,
  error,
  disabled,
  readOnly,
  help,
}) => (
  <>
    <Input
      value={(value.valueScalar?.date ?? "").substr(0, 10)}
      onChangeText={(date: string) =>
        onChange({
          ...value,
          valueScalar: {
            date,
          },
        })
      }
      state={error ? "danger" : undefined}
      disabled={disabled}
      readOnly={readOnly}
      type="date"
      help={help}
    />
  </>
);
