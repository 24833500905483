import {
  ValueFieldValueFkeyValueFieldCreateInput,
  ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate,
  ValueFieldValueFieldPkeyDelete,
  ValueField,
  UpdateValueFieldOnValueFieldForValueFieldValueFkeyPatch,
  ModelFieldsInherited,
  ModelFieldType,
} from "api/graphql";

type Diff = {
  create: ValueFieldValueFkeyValueFieldCreateInput[];
  updates: ValueFieldOnValueFieldForValueFieldValueFkeyUsingValueFieldPkeyUpdate[];
  deletes: ValueFieldValueFieldPkeyDelete[];
};
export const buildDiff = (
  newValue: ValueField[],
  oldValue: ValueField[],
  project: string,
  environment: string
): Diff => {
  const create = newValue
    .filter((ent) => ent.id === undefined)
    .map(
      (ent) =>
        ({
          ...ent,
          valueValue: undefined,
          valueEntryProjectEnvironment: undefined,
          modelFieldId: ent.modelFieldId,
          projectEnvironmentToEnvironmentId: {
            connectById: { id: environment },
          },
        } as ValueFieldValueFkeyValueFieldCreateInput)
    );

  const deletes = oldValue
    .filter((ent) => !newValue.find((ent2) => ent2.id === ent?.id))
    .map((ent) => ({
      id: ent!.id,
      environmentId: environment,
      projectId: project,
    }));

  const updates = newValue
    .filter((ent) => !!ent.id && !deletes.find((del) => del.id === ent.id))
    .filter((ent) => ent !== oldValue.find((val) => val?.id === ent.id))
    .map((ent) => {
      const {
        __typename,
        id,
        valueValue,
        valueEntryProjectEnvironment,
        environmentId,
        projectId,
        ...patch
      } = ent;
      return {
        id: ent.id,
        environmentId: environment,
        projectId: project,
        patch: patch as UpdateValueFieldOnValueFieldForValueFieldValueFkeyPatch,
      };
    });

  return {
    create,
    deletes,
    updates,
  };
};

export const valueEmpty = (
  field: ModelFieldsInherited,
  values: ValueField[]
): boolean => {
  if (values.length === 0) {
    return true;
  }

  switch (field.type as ModelFieldType) {
    case ModelFieldType.Scalar:
      switch (field.config?.type) {
        case "date":
          return values.some((val) => !Boolean(val.valueScalar?.date));
        case "richtext":
          return values.some(
            (val) =>
              val.valueScalar?.richtext?.[0]?.children.length === 1 &&
              val.valueScalar?.richtext?.[0]?.children?.[0]?.text === ""
          );
        case "text":
        case "number":
        default:
          return values.some((val) => !Boolean(val.valueScalar?.text));
      }
    case ModelFieldType.Media:
      return values.some((val) => !Boolean(val.valueMediaId));
    case ModelFieldType.Entry:
      return values.some((val) => !Boolean(val.valueEntryId));
    case ModelFieldType.Value:
      return values.some((val) => !Boolean(val.valueValueId));
  }
};

export const valueValid = (
  field: ModelFieldsInherited,
  values: ValueField[]
): boolean => {
  if (values.length === 0) {
    return true;
  }

  switch (field.type as ModelFieldType) {
    case ModelFieldType.Scalar:
      switch (field.config?.type) {
        case "text": {
          if (field.config.validation) {
            try {
              const re = new RegExp(field.config.validation);

              return values.every((val) => re.test(val.valueScalar?.text));
            } catch (ex) {
              console.warn(
                `Invalid regular expression ${field.config.validation}`
              );
            }
          }
          return true;
        }
        default:
          return true;
      }
    case ModelFieldType.Media:
      return true;
    case ModelFieldType.Entry:
      return true;
    case ModelFieldType.Value:
      return true;
  }
};
