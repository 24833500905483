import * as React from "react";
import { useUser } from "providers/user";
import { Link } from "react-router-dom";
import { Button, Icons } from "pokko-shared";

export const SimpleNavbar: React.FC = () => {
  const { user } = useUser();

  const logout = () => {};

  return (
    <nav className="navbar-simple">
      <Link to="/">
        <Icons.PokkoType />
      </Link>
      {user ? <Button onClick={logout}>Logout</Button> : null}
    </nav>
  );
};
