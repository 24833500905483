import * as React from "react";
import { useForm } from "react-hook-form";
import { InputField, SelectField, Button } from "pokko-shared";
import { strings } from "strings";
import { Model, ModelUsage } from "api/graphql";

export type EntryCreateInput = {
  name: string;
  model: string;
};

export type EntryCreateProps = {
  defaultValues?: EntryCreateInput;
  loading: boolean;
  models: Model[];
  onSave: (value: EntryCreateInput) => void;
};

export const EntryCreate: React.FC<EntryCreateProps> = ({
  defaultValues,
  loading,
  models,
  onSave,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EntryCreateInput>({
    defaultValues,
  });

  const groupedModels = {
    module: models.filter((ent) => ent.usage === ModelUsage.Module),
    entry: models.filter((ent) => ent.usage === ModelUsage.Entry),
  };

  return (
    <form onSubmit={handleSubmit(onSave)} className="entry-create__container">
      <div className="entry-detail__settings">
        <div className="entry-detail__settings-name">
          <InputField
            label="Name"
            error={errors.name}
            autoFocus
            {...register("name", { required: strings.required })}
          />
        </div>
        <div className="entry-detail__settings-model">
          <SelectField
            label="Model"
            error={errors.model}
            {...register("model", { required: strings.required })}
          >
            <option value="">Select...</option>
            {groupedModels.entry.length > 0 ? (
              <optgroup label="Entries">
                {groupedModels.entry.map((ent) => (
                  <option key={ent.id} value={ent.id}>
                    {ent.name}
                  </option>
                ))}
              </optgroup>
            ) : null}
            {groupedModels.module.length > 0 ? (
              <optgroup label="Modules">
                {groupedModels.module.map((ent) => (
                  <option key={ent.id} value={ent.id}>
                    {ent.name}
                  </option>
                ))}
              </optgroup>
            ) : null}
          </SelectField>
        </div>
        <div className="entry-detail__settings-actions">
          <Button kind="primary" type="submit" loading={loading}>
            {strings.create}
          </Button>
        </div>
      </div>
    </form>
  );
};
